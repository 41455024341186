import { format, subDays } from 'date-fns'
import { useMemo } from 'react'

import { useGetAppMonitorStatusQuery } from '../../../app/apiSlice'
import { CHART_NEUTRAL_HSLA, CHART_PRIMARY_HSLA } from '../../../app/theme'
import { useQueryFilters } from '../../../common/hooks'
import { GLOBAL_DATE_FORMAT } from '../../../common/types/datetime'
import { AdminMainPathEnum } from '../../../common/types/routing'
import type { WindowUsageData } from '../../../features/BusinessApps/types'
import StatusCard from './StatusCard'

const AppStatus = () => {
  const { tags_of_interest } = useQueryFilters()
  const { data: appData, isLoading } = useGetAppMonitorStatusQuery({
    body: {
      tags_of_interest: tags_of_interest['application'],
      sort_by_field: 'time_h',
      max_date: format(new Date(), GLOBAL_DATE_FORMAT),
      min_date: format(subDays(new Date(), 6), GLOBAL_DATE_FORMAT),
      team_ids: [],
    },
  })

  const appChartData = useMemo(() => {
    if (!appData || !Array.isArray(appData) || appData.length === 0) {
      return {
        titleValue: 0,
        descriptionValue: '0 hours',
        data: {
          labels: ['No application work in the last 7 days'],
          datasets: [{ data: [1], backgroundColor: CHART_NEUTRAL_HSLA }],
        },
      }
    }

    return {
      titleValue: appData.length, // Application amount
      descriptionValue: `${Math.round(
        appData.reduce((acc: number, item: WindowUsageData) => acc + item.time_h, 0),
      )} hours`, // Application used in hours during 7 days
      data: {
        labels: appData.map((a) => a.name),
        datasets: [
          {
            data: appData.map((a) => Math.round(a.time_h)),
            backgroundColor: CHART_PRIMARY_HSLA,
          },
        ],
      },
    }
  }, [appData])

  return (
    <StatusCard
      chartData={appChartData.data}
      titleProps={{ label: 'Applications detected', value: appChartData.titleValue }}
      descriptionProps={{
        label: 'Work from last 7 days',
        value: appChartData.descriptionValue,
      }}
      navProps={{
        label: 'Select business apps for analysis',
        link: AdminMainPathEnum.DATA_COLLECTION,
      }}
      isLoading={isLoading}
    />
  )
}

export default AppStatus
