import { useRoutes } from 'react-router-dom'

import { AdvancedSetupRouting } from '../ConfigureDataCollectionAdvancedAdminPanelPage'
import AddCustomApp from './AddCustomApp'
import AppTemplates from './AppTemplates'
import ConfigureApp from './ConfigureApp'
import ConfigureDataCollection from './ConfigureDataCollection'
import { ConfigureDataCollectionSubPathEnum } from './types'

const ConfigureDataCollectionRouting = () => {
  return useRoutes([
    { index: true, element: <ConfigureDataCollection />, id: 'config_portal' },
    {
      path: `${ConfigureDataCollectionSubPathEnum.CONFIG_PORTAL}/*`,
      element: <AdvancedSetupRouting />,
      id: 'admin_advanced_setup',
    },
    {
      path: ConfigureDataCollectionSubPathEnum.APP_CATALOG,
      element: <AppTemplates />,
      id: 'admin_app_catalog',
    },
    {
      path: ConfigureDataCollectionSubPathEnum.ADD_CUSTOM_APP,
      element: <AddCustomApp />,
      id: 'admin_add_custom_app',
    },
    {
      path: `${ConfigureDataCollectionSubPathEnum.APPS}/:encodedAppName`,
      element: <ConfigureApp />,
      id: 'admin_configure_app',
    },
  ])
}

export default ConfigureDataCollectionRouting
