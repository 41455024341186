import { useMemo } from 'react'
import { DataFlowEdge, DataFlowNode } from 'react-graph-vis'

import { useGetNetworkDataFlowsQuery } from '../../app/apiSlice'
import { useQueryFilters } from '../../common/hooks'
import type { ApplicationCategory } from '../../common/types/dataCollectionsConfigs'
import DataFlowVisualization from './DataFlowVisualization'
import useOptions from './useOptions'

const Network = () => {
  const { min_date, max_date, team_ids } = useQueryFilters()
  const { tagsOfInterest, nodeAmount, matchAnyTagFilters } = useOptions()

  const { data } = useGetNetworkDataFlowsQuery({
    min_date,
    max_date,
    team_ids,
    tags_of_interest: tagsOfInterest,
    node_amount: nodeAmount,
    tag_filters: [],
    match_any_tag_filters: matchAnyTagFilters,
  })

  const { nodes, edges } = useMemo(() => {
    if (!data) return { nodes: null, edges: null }

    const nodes: DataFlowNode[] = Object.values(data.nodes).map(
      ({ id, label, value, x, y, group, properties: { copies, pastes, team_ids, tags } }) => {
        return {
          id,
          x,
          y,
          value,
          group,
          properties: {
            nodeName: label,
            applicationCategory: group as ApplicationCategory,
            copyCount: copies,
            pasteCount: pastes,
            tags,
            teamIds: team_ids,
            eventCount: value,
          },
        }
      },
    )

    const edges: DataFlowEdge[] = Object.values(data.edges).map(
      ({
        id,
        value,
        from,
        to,
        properties: { source_label, source_tags, target_label, target_tags, team_ids },
      }) => ({
        id,
        value,
        from,
        to,
        properties: {
          id,
          sourceNodeId: from,
          sourceNodeTags: source_tags,
          sourceNodeName: source_label,
          targetNodeId: to,
          targetNodeName: target_label,
          targetNodeTags: target_tags,
          teamIds: team_ids,
          value,
        },
      }),
    )

    return { nodes, edges }
  }, [data])

  return (
    <div className='relative size-full'>
      <DataFlowVisualization nodes={nodes} edges={edges} />
    </div>
  )
}

export default Network
