import { useEffect } from 'react'
import { Outlet, useRoutes } from 'react-router-dom'

import { useGetKanbanQuery, useGetUsersQuery } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { Drawer } from '../../common/designs'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { SubPathEnum } from '../../common/types/routing'
import { selectActiveCaseId, setActiveCaseId, setUsers, setupKanban } from './casesSlice'
import { CaseDetails, Opportunities, Roadmap } from './components'

const CasesRouting = () => {
  const dispatch = useAppDispatch()
  const activeCaseId = useAppSelector(selectActiveCaseId)

  const getKanbanQuery = useGetKanbanQuery()
  const getUsersQuery = useGetUsersQuery(null)

  useEffect(() => {
    if (getUsersQuery.data) dispatch(setUsers(getUsersQuery.data))
  }, [getUsersQuery.data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getKanbanQuery.data) dispatch(setupKanban(getKanbanQuery.data))
  }, [getKanbanQuery.data]) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = getUsersQuery.isLoading || getKanbanQuery.isLoading

  return useRoutes([
    {
      element: (
        <>
          <Drawer
            variant='white'
            size='2xl'
            label={`Opportunity #${activeCaseId}`}
            open={Boolean(activeCaseId)}
            onClose={() => dispatch(setActiveCaseId(null))}
          >
            {activeCaseId ? <CaseDetails /> : null}
          </Drawer>

          <Outlet />
        </>
      ),
      children: [
        { index: true, element: isLoading ? <Loader /> : <Opportunities />, id: 'cases_index' },
        {
          path: SubPathEnum.ROADMAP,
          element: isLoading ? <Loader /> : <Roadmap />,
          id: 'cases_roadmap',
        },
      ],
    },
  ])
}

export default CasesRouting

