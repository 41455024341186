import { useCallback, useEffect, useState } from 'react'
import ReactFlow, { Controls, applyNodeChanges } from 'reactflow'
import type { Edge, Node, NodeTypes } from 'reactflow'
import 'reactflow/dist/style.css'

import DefaultEdge from './DefaultEdge'
import { INITIAL_EDGE, INITIAL_NODE_END, INITIAL_NODE_START } from './constants'
import type { ServerEdge, ServerNodeTypes } from './types'

interface Props {
  nodes: Array<ServerNodeTypes>
  edges: ServerEdge[]
  nodeTypes: NodeTypes
  serverNodesToChartNodes: (serverNodes: Array<ServerNodeTypes>) => Node[]
  serverEdgesToChartEdges: (serverEdges: ServerEdge[]) => Edge[]
}

const EDGE_TYPES = { defaultEdge: DefaultEdge }

const FlowChart = ({
  nodes,
  edges,
  nodeTypes,
  serverNodesToChartNodes,
  serverEdgesToChartEdges,
}: Props) => {
  const [chartNodes, setChartNodes] = useState<Node[]>(
    serverNodesToChartNodes([INITIAL_NODE_START, INITIAL_NODE_END]),
  )
  const [chartEdges, setChartEdges] = useState<Edge[]>(serverEdgesToChartEdges([INITIAL_EDGE]))

  const onNodesChange = useCallback(
    (changes: any) => setChartNodes((nds) => applyNodeChanges(changes, nds)),
    [setChartNodes],
  )

  useEffect(() => {
    const dataNodes = [...nodes]
    dataNodes.sort((a, b) => b.transactions - a.transactions)
    const filteredNodes = dataNodes.slice(0, 50)
    setChartNodes(serverNodesToChartNodes(filteredNodes))
    setChartEdges(serverEdgesToChartEdges(edges))
  }, [nodes, edges, serverNodesToChartNodes, serverEdgesToChartEdges])

  return (
    <ReactFlow
      nodes={chartNodes}
      edges={chartEdges}
      nodeTypes={nodeTypes}
      edgeTypes={EDGE_TYPES}
      onNodesChange={onNodesChange}
      minZoom={0.1}
    >
      <Controls />
    </ReactFlow>
  )
}

export default FlowChart
