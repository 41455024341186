import { Droppable } from 'react-beautiful-dnd'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { LaneItem } from '.'
import { Text } from '../../../../../../common/designs'
import { useAppSelector, useCostUtils } from '../../../../../../common/hooks'
import {
  selectIsLaneEmptyById,
  selectLaneById,
  selectLaneCaseIdsByLaneId,
  selectLaneCost,
} from '../../../../casesSlice'

interface Props {
  laneId: string
}

const Lane = ({ laneId }: Props) => {
  const { formatCost } = useCostUtils()
  const laneCost = useAppSelector((state) => selectLaneCost(state, laneId)) // eslint-disable-line react-redux/useSelector-prefer-selectors
  const lane = useAppSelector((state) => selectLaneById(state, laneId)) // eslint-disable-line react-redux/useSelector-prefer-selectors
  const isLaneEmpty = useAppSelector((state) => selectIsLaneEmptyById(state, laneId)) // eslint-disable-line react-redux/useSelector-prefer-selectors
  const laneCaseIds = useAppSelector((state) => selectLaneCaseIdsByLaneId(state, laneId)) // eslint-disable-line react-redux/useSelector-prefer-selectors

  if (!lane) return null

  return (
    <div className='flex flex-col items-start' key={`lane-${laneId}`}>
      <div className='mb-2 mr-4 flex flex-col'>
        <div className='flex w-[232px] justify-between'>
          <Text variant='h5' size='xl' weight={500} className='mb-1'>
            {lane.title}
          </Text>
        </div>

        <Text color='lightGray' weight={500} className='text-left'>
          {formatCost(laneCost)}
        </Text>
      </div>

      <div
        style={{
          maxHeight: `calc(100vh - 64px)`,
        }}
      >
        <PerfectScrollbar>
          <div className='pr-2'>
            <Droppable droppableId={laneId} key={laneId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  {...{ ref: provided.innerRef }}
                  className='min-h-[calc(100vh-300px)] w-[232px] rounded-md'
                  style={{
                    // Only show background color if lane is empty - Different bg color when dragging case over it
                    background: snapshot.isDraggingOver
                      ? isLaneEmpty
                        ? 'linear-gradient(180deg, hsla(205, 70%, 90%, 1) 0%, rgba(221, 228, 233, 0) 100%)'
                        : 'none'
                      : isLaneEmpty
                        ? 'linear-gradient(180deg, hsla(205, 21%, 89%, 1) 0%, rgba(221, 228, 233, 0) 100%)'
                        : 'none',
                  }}
                >
                  {!isLaneEmpty ? (
                    laneCaseIds.map((id, i) => <LaneItem id={id} index={i} key={id} />)
                  ) : (
                    <div className='pt-5'>
                      <Text size='xs' weight={600} color='lightGray'>
                        Drag an opportunity here
                      </Text>
                    </div>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default Lane

