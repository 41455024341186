import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { useGetCompanySettingsQuery, useUpdateCompanySettingsMutation } from '../../app/apiSlice'
import { currencyDropdownNew, currencyMapper } from '../../app/constants'
import { Button, InputField, Select, Text } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import { companyValidator } from '../../common/types/validators'
import CompanyCreation from './CompanyCreation'

const formSchema = yup
  .object({
    cost_per_hour: companyValidator.cost_per_hour,
    currency: companyValidator.currency,
    agent_installation_target: companyValidator.agent_installation_target,
  })
  .required()

type Inputs = {
  cost_per_hour: string
  currency: string
  agent_installation_target: number
}

const CompanySettings = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: { cost_per_hour: '0', currency: 'EUR', agent_installation_target: 0 },
  })
  const { user } = useDashboard()

  const { data } = useGetCompanySettingsQuery(null)
  const [updateCompanySettings] = useUpdateCompanySettingsMutation()

  useEffect(() => {
    if (data) {
      reset({
        cost_per_hour: data.cost_per_hour.toString(),
        currency: data.currency,
        agent_installation_target: data.agent_installation_target,
      })
    }
  }, [data, reset])

  const onSave: SubmitHandler<Inputs> = ({
    cost_per_hour,
    currency,
    agent_installation_target,
  }: Inputs) => {
    const body = {
      cost_per_hour: Number(cost_per_hour),
      currency,
      currency_symbol_UTF8: currencyMapper[currency].symbolUTF8,
      agent_installation_target,
    }

    updateCompanySettings({ body })
      .unwrap()
      .then(() => toast.success('Company settings updated successfully'))
      .catch(() => toast.error('Error updating company settings'))
  }

  return (
    <>
      <Text size='xl' weight={600} className='mb-3'>
        Company Settings
      </Text>

      <form onSubmit={handleSubmit(onSave)} className='flex flex-col gap-4'>
        <div className='flex gap-4'>
          <Controller
            name='cost_per_hour'
            control={control}
            render={({ field }) => (
              <InputField
                label='Cost per hour'
                type='number'
                error={errors.cost_per_hour?.message}
                className='w-36'
                min={0}
                step={0.01}
                {...field}
              />
            )}
          />

          <Controller
            name='currency'
            control={control}
            render={({ field }) => (
              <Select
                label='Currency'
                options={currencyDropdownNew}
                error={errors.currency?.message}
                className='w-36'
                {...field}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name='agent_installation_target'
            control={control}
            render={({ field }) => (
              <InputField
                label='Agent installation target'
                type='number'
                min={0}
                error={errors.agent_installation_target?.message}
                className='w-76'
                {...field}
              />
            )}
          />
        </div>

        <Button type='submit' className='w-76'>
          Save
        </Button>
      </form>

      {user.isSuperuser ? <CompanyCreation /> : null}
    </>
  )
}

export default CompanySettings
