import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'

const sizeStyles: { [key in Size]: string } = {
  xs: 'max-w-sm w-[384px]',
  s: 'max-w-md w-[448px]',
  m: 'max-w-lg w-[512px]',
  l: 'max-w-xl w-[576px]',
  xl: 'max-w-2xl w-[672px]',
  '2xl': 'max-w-3xl w-[768px]',
  '3xl': 'max-w-4xl w-[896px]',
  '4xl': 'max-w-5xl w-[1024px]',
  '5xl': 'max-w-6xl w-[1152px]',
  '6xl': 'max-w-7xl w-[1280px]',
}

type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl'

interface Props {
  open: boolean
  label: string
  onClose: () => void
  children: ReactNode

  size?: Size
  className?: string
}

const Modal = (props: Props) => {
  const {
    open,
    label,
    onClose,
    children,

    size = 'm',
    className,
  } = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className={clsx('relative z-20 w-152', className)} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500/60 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-20 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 scale-95'
              enterTo='opacity-100 translate-y-0 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 scale-100'
              leaveTo='opacity-0 translate-y-4 scale-95'
            >
              <Dialog.Panel
                className={clsx(
                  'relative my-8 overflow-visible rounded-lg bg-white p-6 text-left shadow-xl transition-all',
                  sizeStyles[size],
                )}
              >
                <div className='absolute right-0 top-0 block pr-6 pt-6'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={onClose}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='size-6' aria-hidden='true' />
                  </button>
                </div>

                <div>
                  <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                    {label}
                  </Dialog.Title>

                  <div className='mt-6'>{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
