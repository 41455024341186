import { add } from 'date-fns'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useGetInitializeDashboardQuery } from '../../../app/apiSlice'
import { ENVIRONMENT } from '../../../app/configs'
import { Loader } from '../../../common/components'
import { EnvEnum, UserRoleMappingEnum } from '../../../common/types/common'
import AppError from '../../../features/AppError/AppError'
import useAuthentication, {
  getAccessToken,
} from '../../../features/Authentication/useAuthentication'
import { useDashboard, useLocalStorage } from '../../hooks'
import { GlobalPathEnum } from '../../types/routing'
import { StorageNameEnum } from '../../types/storage'
import { toCamelCase } from '../dataFormatting'
import './chartJsSetup'

interface Props {
  children: React.ReactNode
  requireAuth?: boolean
  requireFileStoreToken?: boolean
  restrictAccessToRoleIds?: UserRoleMappingEnum[]
}

const UtilityWrapper = (props: Props) => {
  const { children, requireAuth, requireFileStoreToken, restrictAccessToRoleIds } = props
  const { onSignOut, onFileStoreTokenExpiry } = useAuthentication()
  const storage = useLocalStorage()
  const dashboard = useDashboard()

  const { data: dashboardSetup, error: dashboardSetupError } = useGetInitializeDashboardQuery(
    null,
    { skip: Boolean(!requireAuth) },
  )

  useEffect(() => {
    if (requireFileStoreToken && dashboard.fileStore?.expiry) {
      // Check that filestore token won't expire in next 2 hour
      const tokenExpiryTime = new Date(dashboard.fileStore.expiry)
      const currentTimeAfterTwoHours = add(new Date(), { hours: 2 })
      const isGoingToExpire = currentTimeAfterTwoHours > tokenExpiryTime

      // Refetch token if it is about to expire.
      if (isGoingToExpire) onFileStoreTokenExpiry()
    }
  }, [requireFileStoreToken, dashboard.fileStore?.expiry]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!getAccessToken()) onSignOut()
    if (dashboardSetup) storage.setItem(StorageNameEnum.DASHBOARD, toCamelCase(dashboardSetup))
  }, [dashboardSetup]) // eslint-disable-line react-hooks/exhaustive-deps

  // Redirection to App error page is annoying when doing development work, so user is only directed on stg and prod.
  if (dashboardSetupError && ENVIRONMENT === EnvEnum.DEV) return <AppError />
  if (dashboardSetupError) return <Navigate to={GlobalPathEnum.APP_ERROR} />
  if (!dashboard.isInitialized) return <Loader />

  // If restrictAccessToRoleIds is not empty, then check the permissions of the user match the rules
  const isAuthorized =
    !restrictAccessToRoleIds || restrictAccessToRoleIds.includes(dashboard.user.roleId)

  return isAuthorized ? <>{children}</> : <Navigate to={GlobalPathEnum.NOT_FOUND} />
}

export default UtilityWrapper

