import { useState } from 'react'

import { useGetNormalDistributionDataQuery } from '../../../../app/apiSlice'
import { InlineLoader } from '../../../../common/components'
import { Button, Text } from '../../../../common/designs'
import { useQueryFilters } from '../../../../common/hooks'
import { getBestTimeFormatForSeconds } from '../../../../common/utils/numberFormatting'
import TransactionNormalDistributionChart from './TransactionNormalDistributionChart'
import TransactionNormalDistributionModal from './TransactionNormalDistributionModal'
import TransactionStandardDeviationTrend from './TransactionStandardDeviationTrend'

export const TransactionNormalDistributionAnalysis = () => {
  const { processId, processFilters: filters } = useQueryFilters()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { data: normalDistributionData, isLoading: isLoadingNormalDistributionData } =
    useGetNormalDistributionDataQuery({
      processId,
      filters,
    })

  const longCasesThresholdSec = normalDistributionData
    ? normalDistributionData.avg_sec + normalDistributionData.std_dev_sec
    : 0
  const longCasesThreshold = getBestTimeFormatForSeconds(longCasesThresholdSec)

  return (
    <>
      <div>
        <Text size='xl' weight={500} className='my-3'>
          Average Case Duration and Standard Deviation Trends
        </Text>
        <TransactionStandardDeviationTrend />

        <Text size='xl' weight={500} className='mb-3 mt-6'>
          Normal Distribution of Case Durations
        </Text>
        {isLoadingNormalDistributionData ? (
          <InlineLoader />
        ) : (
          normalDistributionData && (
            <div className='space-y-3'>
              <TransactionNormalDistributionChart data={normalDistributionData} />
              <Button onClick={() => setIsModalOpen(true)}>
                Show cases longer than {longCasesThreshold} (+1 σ)
              </Button>
            </div>
          )
        )}
      </div>
      <TransactionNormalDistributionModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        longCasesThresholdSec={longCasesThresholdSec}
      />
    </>
  )
}

export default TransactionNormalDistributionAnalysis
