import { teamBgColorMap } from '../../app/theme'
import { useDashboard } from '../../common/hooks'
import { formatNumber } from '../../common/utils/numberFormatting'
import DistributionChart from './DistributionChart'
import type { TeamDistributionData } from './types'

interface Props {
  teamData: TeamDistributionData[]
}

const TeamDistribution = ({ teamData }: Props) => {
  const totalTime = teamData.reduce((total, item) => total + item.duration_sec, 0)
  const { teams } = useDashboard()

  const visualizationData = teamData.map(({ team_id, duration_sec }) => {
    const teamObject = teams[team_id]
    return {
      value: `${formatNumber(duration_sec / 60 / 60)} h`,
      relativeValue: Math.round((duration_sec / totalTime) * 100),
      label: teamObject?.name ?? '',
      bgColor: teamBgColorMap[teamObject?.colorIndex ?? 1],
    }
  })

  return <DistributionChart data={visualizationData} />
}

export default TeamDistribution
