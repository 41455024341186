import type { Location } from 'react-router-dom'

import { VariantViewRoutesEnum } from './ProcessMining/Variants/enums'

export const getProcessIdFromLocation = (location: Location) => {
  const processId = location.pathname.split('/')?.[2]

  if (processId === undefined) {
    throw new Error('Process ID not found')
  }

  return processId
}

export const getVariantViewRoutesEnumFromLocation = (location: Location) => {
  if (location.pathname.split('/')?.[4] === undefined || location.pathname.split('/')?.[4] === '') {
    return VariantViewRoutesEnum.STEP_FLOWS
  }

  if (location.pathname.split('/')?.[4] === 'state-flows') return VariantViewRoutesEnum.STATE_FLOWS

  return VariantViewRoutesEnum.TEAM_FLOWS
}
