import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { useGetTeamUsernameListQuery, useUpdateTeamUsernameListMutation } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { Button, Modal, TextArea } from '../../common/designs'
import { isStringArray } from '../../common/types/typeGuards'

interface Inputs {
  usernameList: string
}

interface Props {
  invitationToken: string | undefined
  open: boolean
  onClose: () => void
}

const schema = yup.object({
  usernameList: yup
    .string()
    .required(
      'Usernames list is required. If you want to provide no users, insert [] into the text field.',
    )
    .test('is-json', 'Usernames list must be valid JSON and can contain only strings.', (value) => {
      try {
        const parsedValue = JSON.parse(value ?? '[]')
        return isStringArray(parsedValue) ? true : false
      } catch (error) {
        return false // If JSON.parse throws an error, it's not valid JSON
      }
    }),
})

const EditUsernamesModal = ({ invitationToken, open, onClose }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: { usernameList: '[]' },
  })

  const { data: usernameList, isFetching: isFetchingUsernameList } = useGetTeamUsernameListQuery(
    invitationToken as string,
    { skip: !invitationToken },
  )
  const [updateTeamUsernameList, updateTeamUsernameListQuery] = useUpdateTeamUsernameListMutation()

  useEffect(() => {
    if (usernameList) {
      try {
        const jsonString = JSON.stringify(usernameList)
        reset({ usernameList: jsonString })
      } catch (error) {
        toast.error('Username list data is invalid')
      }
    }
  }, [usernameList, reset])

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    if (!invitationToken) return

    updateTeamUsernameList({ invitationToken, usernameList: data.usernameList })
      .unwrap()
      .then(() => onClose())
  }

  return (
    <Modal label='Map users' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isFetchingUsernameList ? (
          <Loader />
        ) : (
          <>
            <Controller
              name='usernameList'
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  label='Provide the usernames as a list in JSON format below'
                  description='e.g. ["user1@gmail.com", "user2@gmail.com"]'
                  error={errors.usernameList?.message}
                />
              )}
            />

            <div className='mt-2 flex justify-end gap-2'>
              <Button variant='white' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' loading={updateTeamUsernameListQuery.isLoading}>
                Save
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

export default EditUsernamesModal
