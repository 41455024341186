import { AZ_STORAGE_ACCOUNT_URL_DASHBOARD } from '../../app/configs'
import { Button, Modal, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import InsightTypes from './InsightTypes'
import type { InsightType } from './types'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  types: InsightType[]
  imageUrl: string
  imageAlt: string
  description: string
  solution: string
  href: string
}

const InsightModal = (props: Props) => {
  const { open, onClose, title, types, imageUrl, imageAlt, description, solution, href } = props

  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <Modal label={title} open={open} onClose={onClose} size='2xl'>
      <div className='flex flex-col gap-2'>
        <InsightTypes insightTypes={types} />

        <div>
          <img src={`${AZ_STORAGE_ACCOUNT_URL_DASHBOARD}/${imageUrl}`} alt={imageAlt} />
        </div>

        <Text variant='h6' weight={500} className='mt-3'>
          Description
        </Text>
        <Text size='s'>{description}</Text>
        <Text variant='h6' weight={500} className='mt-3'>
          Solution
        </Text>
        <Text size='s' className='mb-3'>
          {solution}
        </Text>

        <Button onClick={() => navigate(href)}>Show me</Button>
      </div>
    </Modal>
  )
}

export default InsightModal
