import { ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useState } from 'react'

import { Button, Text } from '../../designs'
import { maxRowLengthSizesMd } from '../../designs/constants'
import type { MaxRowLength, Tag } from '../../types/common'
import StatSaveModal from './StatSaveModal'

interface FavoriteQueryOptions {
  tags_of_interest: string[]
  tag_filters: Tag[]
  group_by_date: boolean
}

export interface StatDetails {
  label: string
  value: string | number
  description?: string
  params?: {
    label: string
    unit: string
    dataKey: string
    url: string
    queryOptions: FavoriteQueryOptions
  }
}

interface Props {
  data: StatDetails[]
  maxRowLength?: MaxRowLength
  title?: string
  description?: string
  isLoading?: boolean
}

const Stats = ({ data, maxRowLength, title, description, isLoading = false }: Props) => {
  const rows = maxRowLength ?? ((title ? data.length + 2 : data.length) as MaxRowLength)

  const [statDetails, setStatDetails] = useState<StatDetails | null>(null)

  return (
    <>
      <dl
        className={clsx(
          'grid min-h-[108px] divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md ring-2 ring-black/5 md:divide-x md:divide-y-0',
          maxRowLengthSizesMd[rows],
        )}
      >
        {title ? (
          <div className='col-start-1 col-end-3 px-4 py-5 sm:p-6'>
            <Text size='l' weight={600}>
              {title}
            </Text>
            {description && <Text size='s'>{description}</Text>}
          </div>
        ) : null}

        {data.map((item) => (
          <div key={item.label} className='group relative px-4 py-5 sm:p-6'>
            <dt className='text-base font-normal text-gray-900'>{item.label}</dt>
            <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
              <div className='flex items-baseline text-2xl font-semibold text-primary-600'>
                {isLoading ? (
                  <div className='mt-1 inline-block size-6 animate-pulse rounded-full bg-gray-200' />
                ) : (
                  item.value
                )}
              </div>
            </dd>
            {item.params ? (
              <Button
                size='xs'
                rounded
                variant='secondary'
                iconEnd={<ChevronDownIcon aria-hidden='true' />}
                className='absolute right-4 top-4 hidden group-hover:flex'
                onClick={() => setStatDetails(item)}
              >
                Save
              </Button>
            ) : null}
          </div>
        ))}
      </dl>

      <StatSaveModal
        open={Boolean(statDetails)}
        onClose={() => setStatDetails(null)}
        details={statDetails}
      />
    </>
  )
}

export default Stats
