import { ActivityInWindow, DataflowData } from '../../types'

export const parseDataflow = (
  dataflow: DataflowData[] | undefined,
  nameKey: 'source_name' | 'destination_name',
  tagsKey: 'source_tags' | 'destination_tags',
) => {
  const mapper: { [key: string]: ActivityInWindow } = {} //

  dataflow?.forEach((d) => {
    if (d[nameKey] in mapper) {
      mapper[d[nameKey]].total += d.event_count
    } else {
      mapper[d[nameKey]] = {
        window_tags: d[tagsKey],
        window_name: d[nameKey],
        total: d.event_count,
      }
    }
  })

  return Object.values(mapper).sort((a, b) => b.total - a.total)
}
