import { useMemo } from 'react'

import { useGetMultiSystemFlowTaskDataQuery } from '../../../app/apiSlice'
import { BarChartAndTableDisplay, InlineLoader } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import { Text } from '../../../common/designs'
import { useQueryFilters } from '../../../common/hooks'

interface Props {
  stepName: string
  transactionIds: string[]
}

const StepActivityTaskBarChart = ({ stepName, transactionIds }: Props) => {
  const { processId, processFilters } = useQueryFilters()

  const { data, isFetching } = useGetMultiSystemFlowTaskDataQuery({
    filters: { ...processFilters, transaction_id_filters: transactionIds },
    processId,
    step_name: stepName,
  })

  const chartData: ChartDataEntry[] = useMemo(() => {
    if (!data) return []

    return data.map((item) => ({
      label: stepName === item.window_name ? `${item.window_name} (Other case)` : item.window_name,
      value: item.total_count,
    }))
  }, [data, stepName])

  if (isFetching)
    return (
      <div className='mt-2'>
        <Text size='xl' weight={600}>
          Most common tasks in Multi-system workflows
        </Text>
        <InlineLoader />
        <Text size='s'>Generating task report</Text>
      </div>
    )

  return (
    <div className='mt-4'>
      <BarChartAndTableDisplay
        label='Most common tasks in Multi-system workflows'
        data={chartData}
        tableHeaderValues={['Task name', 'Visits']}
        maxXLabelLength={33}
        unit=' total visits'
      />
    </div>
  )
}

export default StepActivityTaskBarChart

