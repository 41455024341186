import { useOutletContext } from 'react-router-dom'

import type { Tag } from '../../../common/types/common'
import TimeActivities from '../TimeLayout/TimeActivities'
import TimeStats from './TimeStats'

const Time = () => {
  const { tagFilters } = useOutletContext<{
    tagFilters: Tag[]
    appName: string
    contentName: string
    label: string
  }>()

  return (
    <div className='flex flex-col gap-3'>
      <TimeStats />

      <TimeActivities
        queryOptions={{
          tagsOfInterestSource: 'window',
          tagFilters,
          use_exact_tag_filter_match: true,
        }}
      />
    </div>
  )
}

export default Time
