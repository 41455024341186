// All available imports for chartJS plugins
// https://www.chartjs.org/docs/latest/getting-started/integration.html#bundlers-webpack-rollup-etc
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Chart as ChartJS,
  ChartType,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  TooltipPositionerFunction,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import enGB from 'date-fns/locale/en-GB'
import { registerLocale } from 'react-datepicker'

// Register chartjs-plugin-annotation
Chart.register(annotationPlugin)

// Extend tooltip positioner map
declare module 'chart.js' {
  interface TooltipPositionerMap {
    nearestAndAbove: TooltipPositionerFunction<ChartType>
  }
}

Tooltip.positioners.nearestAndAbove = function (items, eventPosition) {
  const nearest = Tooltip.positioners.nearest.bind(this)
  const pos = nearest(items, eventPosition)
  if (!pos) return false

  const pointWhiteSpace = (items?.[0]?.element?.options?.radius as number | undefined) ?? 0

  return {
    x: pos.x,
    y: pos.y - pointWhiteSpace,
    xAlign: 'center',
    yAlign: 'bottom',
  }
}

// Setting up global plugins for chartJS
// https://www.chartjs.org/docs/latest/developers/plugins.html#global-plugins
ChartJS.register(
  PointElement,
  BarElement,
  LineElement,
  LinearScale,
  CategoryScale,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
)

// Register locale from date-fns for react-datepicker (So week is from Mon to Sun).
registerLocale('en-GB', enGB)
