import { useRoutes } from 'react-router-dom'

import { SubPathEnum } from '../../common/types/routing'
import GetStarted from './GetStarted'
import Recommendations from './Recommendations'

const OverviewRouting = () => {
  return useRoutes([
    { index: true, element: <GetStarted />, id: 'start_get_started' },
    {
      path: SubPathEnum.RECOMMENDATIONS,
      element: <Recommendations />,
      id: 'start_recommendations',
    },
    {
      path: `${SubPathEnum.RECOMMENDATIONS}/:recommendationName`,
      element: <Recommendations />,
      id: 'start_recommendations_name',
    },
  ])
}

export default OverviewRouting
