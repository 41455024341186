import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import { sub } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import type { URLSearchParams } from 'url'

import { IconButton, InputCalendar, LegacySelect, Popover } from '../../../common/designs'
import type { SelectOption } from '../../../common/designs/types'
import { useDashboard, useEnhancedSearchParams } from '../../../common/hooks'
import type { SimpleDate } from '../../../common/types/datetime'
import { GlobalSearchParamEnum, TimePeriodValueEnum } from '../../../common/types/searchParams'
import { generateMinDate, toDateString } from '../../../common/utils/dateTimeUtils'

interface CalendarDate {
  start: Date | null
  end: Date | null
}

const timePeriodOptions = [
  { label: 'Current month', value: TimePeriodValueEnum.CURRENT_MONTH },
  { label: 'Current week', value: TimePeriodValueEnum.CURRENT_WEEK },
  { label: 'Last 7 days', value: TimePeriodValueEnum.LAST_7_DAYS },
  { label: 'Last 30 days', value: TimePeriodValueEnum.LAST_30_DAYS },
  { label: 'Last 90 days', value: TimePeriodValueEnum.LAST_90_DAYS },
  { label: 'Last 180 days', value: TimePeriodValueEnum.LAST_180_DAYS },
]
const DEFAULT_TIME_PERIOD = timePeriodOptions[2]

const generateCustomOption = (startDate: string | null, endDate: string | null) => {
  return { label: `${startDate} to ${endDate}`, value: 'Custom' }
}

const initializeTimePeriodValue = (params: URLSearchParams) => {
  const timePeriod = params.get(GlobalSearchParamEnum.TIME_PERIOD)
  const endDate = params.get(GlobalSearchParamEnum.END_DATE)
  const startDate = params.get(GlobalSearchParamEnum.START_DATE)

  return timePeriod
    ? timePeriodOptions.find((o) => o.value === timePeriod) ?? DEFAULT_TIME_PERIOD
    : startDate && endDate
      ? generateCustomOption(startDate, endDate)
      : DEFAULT_TIME_PERIOD
}

interface Props {
  disabled: boolean
}

const TimePeriodSelect = ({ disabled }: Props) => {
  const [searchParams] = useSearchParams()
  const { bulkUpdateSearchParams } = useEnhancedSearchParams()
  const { dataDate } = useDashboard()

  // Default time is set based on the search parameter timePeriod.
  const [timePeriod, setTimePeriod] = useState<SelectOption>(
    initializeTimePeriodValue(searchParams),
  )
  const [calendarDate, setCalendarDate] = useState<CalendarDate>({ start: null, end: null })

  useEffect(() => {
    onTimePeriodChange()
  }, [timePeriod]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSearchParamChange()
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchParamChange = () => {
    const startDate = searchParams.get(GlobalSearchParamEnum.START_DATE)
    const endDate = searchParams.get(GlobalSearchParamEnum.END_DATE)

    if (!startDate || !endDate) return
    setTimePeriod(generateCustomOption(startDate, endDate))
  }

  const onTimePeriodChange = () => {
    const isPredefinedDateTime = Object.values(TimePeriodValueEnum).includes(
      timePeriod.value as TimePeriodValueEnum,
    )

    // If the time period is not a predefined date time, then it is a custom date time.
    if (!isPredefinedDateTime) {
      return setCalendarDate({
        start: new Date(searchParams.get(GlobalSearchParamEnum.START_DATE) as string),
        end: new Date(searchParams.get(GlobalSearchParamEnum.END_DATE) as string),
      })
    }

    // Default time period is set to last 7 days, which should not be visible in search params.
    if (timePeriod.value === DEFAULT_TIME_PERIOD.value) {
      bulkUpdateSearchParams([
        [GlobalSearchParamEnum.TIME_PERIOD, null],
        [GlobalSearchParamEnum.START_DATE, null],
        [GlobalSearchParamEnum.END_DATE, null],
      ])

      const dateObj = {
        start: sub(new Date(dataDate.max), { days: 6 }),
        end: new Date(dataDate.max),
      }

      setCalendarDate(dateObj)
    } else if (timePeriod.value) {
      let startDate: Date | SimpleDate = new Date(dataDate.min)
      const endDate: Date | SimpleDate = new Date(dataDate.max)

      startDate = generateMinDate(timePeriod.value as TimePeriodValueEnum, startDate, endDate)

      bulkUpdateSearchParams([
        [GlobalSearchParamEnum.TIME_PERIOD, timePeriod.value as string],
        [GlobalSearchParamEnum.START_DATE, null],
        [GlobalSearchParamEnum.END_DATE, null],
      ])
      setCalendarDate({ start: startDate as Date, end: endDate })
    }
  }

  const onCalendarDateChange = (dates: any) => {
    const [start, end] = dates
    setCalendarDate({ start, end })

    if (!end) return
    const activeStartDate = toDateString(start)
    const activeEndDate = toDateString(end)

    bulkUpdateSearchParams([
      [GlobalSearchParamEnum.TIME_PERIOD, null],
      [GlobalSearchParamEnum.START_DATE, activeStartDate],
      [GlobalSearchParamEnum.END_DATE, activeEndDate],
    ])
  }

  return (
    <>
      <LegacySelect
        size='xs'
        value={timePeriod}
        onChange={setTimePeriod}
        options={timePeriodOptions}
        className='w-60'
        disabled={disabled}
      />

      <Popover
        origin='r'
        actionAs='div'
        action={
          <IconButton variant='white' size='xs' icon={<CalendarDaysIcon />} disabled={disabled} />
        }
        disabled={disabled}
      >
        <div className='m-2 mb-1 w-[244px]'>
          <InputCalendar
            selected={calendarDate.start}
            onChange={onCalendarDateChange}
            startDate={calendarDate.start}
            endDate={calendarDate.end}
            includeDateIntervals={[
              {
                // Subtract 1 day is a workaround to the fact start seems to be lacking the initial 1 day
                start: sub(new Date(dataDate.min), { days: 1 }),
                end: new Date(dataDate.max),
              },
            ]}
            selectsRange={true as any} // Override broken package typing.
            inline
          />
        </div>
      </Popover>
    </>
  )
}

export default TimePeriodSelect
