import { useEffect, useState } from 'react'

import { GroupedChartView } from '.'
import { Loader } from '../../../common/components'
import { GroupedBarChartDataEntry } from '../../../common/components/Charts/types'
import { useDashboard, useFilteredWindowUsageQuery } from '../../../common/hooks'
import { TagOfInterestSource } from '../../../common/hooks/useFilteredWindowUsageQuery'
import type { Tag } from '../../../common/types/common'
import type { GroupedWindowUsageData } from '../types'
import {
  convertTeamDefaultDataToRelativeData,
  convertTeamServerDataToChartData,
} from './serverDataToViewData'

interface Props {
  tagsOfInterestSource: TagOfInterestSource
  tagFilters?: Tag[] | undefined
  chartTitle: string
  relativeChartTitle: string
  onChartClick: (compressedAndEncodedDataTags: string) => void
  useExactTagFilterMatch?: boolean
}

const TeamsChartView = ({
  tagsOfInterestSource,
  tagFilters,
  chartTitle,
  relativeChartTitle,
  onChartClick,
  useExactTagFilterMatch = false,
}: Props) => {
  const { teams } = useDashboard()

  const [chartDataDefault, setChartDataDefault] = useState<GroupedBarChartDataEntry[]>([])
  const [chartDataRelative, setChartDataRelative] = useState<GroupedBarChartDataEntry[]>([])

  const teamIds = Object.values(teams).map(({ id }) => id)
  const { data, isLoading } = useFilteredWindowUsageQuery({
    tagsOfInterestSource,
    variables: {
      team_comparison_data: teamIds,
      tag_filters: tagFilters,
      use_exact_tag_filter_match: useExactTagFilterMatch,
    },
  })

  // Set chart data after it is fetched from the server
  useEffect(() => {
    const defaultData = convertTeamServerDataToChartData(data as GroupedWindowUsageData, teams)
    setChartDataDefault(defaultData)
    setChartDataRelative(convertTeamDefaultDataToRelativeData(defaultData))
  }, [data, teams])

  if (isLoading) return <Loader />

  return (
    <GroupedChartView
      data={data as GroupedWindowUsageData}
      chartDataDefault={chartDataDefault}
      chartDataRelative={chartDataRelative}
      chartTitle={chartTitle}
      relativeChartTitle={relativeChartTitle}
      onChartClick={onChartClick}
    />
  )
}

export default TeamsChartView
