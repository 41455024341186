import type { NavigationEdge } from 'react-graph-vis'

import type { BadgeVariant } from '../../common/designs'
import { Tag } from '../../common/types/common'
import type { ApplicationCategory } from '../../common/types/dataCollectionsConfigs'

export const badgeVariantMapper: { [key in ApplicationCategory]: BadgeVariant } = {
  'Core business system': 'indigo',
  Document: 'purple',
  Communication: 'orange',
  Other: 'gray',
}

export const generateAppnameTagsFromArray = (array: string[]) => {
  return array.map((app) => ({ key: 'appname', value: app }))
}

export const removeWhiteSpaces = (str: string) => str.replace(/\s/g, '')
export const formatTagsToId = (tags: Tag[]) => removeWhiteSpaces(JSON.stringify(tags))
export const formatToAndFromIntoId = (to: string, from: string) => `${to}..${from}`

export const calculateOriginVisits = (
  edges: { [key: string]: NavigationEdge },
  id1: string,
  id2: string,
) => {
  let contextVisits = 0
  if (edges[formatToAndFromIntoId(id1, id2)]) {
    contextVisits += edges[formatToAndFromIntoId(id1, id2)].value
  }
  if (edges[formatToAndFromIntoId(id2, id1)]) {
    contextVisits += edges[formatToAndFromIntoId(id2, id1)].value
  }
  return contextVisits
}
