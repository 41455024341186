import { RecommendationCategoryEnum, RecommendationSortOptionsEnum } from '../enums'
import { RecommendationData } from '../types'

export const filterByRecommendationCategory = (
  data: RecommendationData[],
  category: RecommendationCategoryEnum,
) => {
  if (category === RecommendationCategoryEnum.ALL) {
    return [...data]
  }
  return [...data].filter((r) => r.recommendation_category === category)
}

export const sortKeyMapper = {
  [RecommendationSortOptionsEnum.IMPROVEMENT_POTENTIAL]: 'business_case_time_sec',
  [RecommendationSortOptionsEnum.IMPACT_SCORE]: 'impact_score',
  [RecommendationSortOptionsEnum.THROUGHPUT_TIME]: 'throughput_time_sec',
  [RecommendationSortOptionsEnum.EVENT_COUNT]: 'business_case_event_count',
}

export const sortByOption = (
  data: RecommendationData[],
  sortOption: RecommendationSortOptionsEnum,
) => {
  const dataCopy = [...data]
  const sortKey = sortKeyMapper[sortOption]
  dataCopy.sort((r1, r2) => {
    return (
      (r2[sortKey as keyof RecommendationData] as number) -
      (r1[sortKey as keyof RecommendationData] as number)
    )
  })
  return dataCopy
}
