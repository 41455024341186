import {
  BG_CYAN_300_HEX,
  BG_GREEN_300_HEX,
  BG_INDIGO_300_HEX,
  BG_ORANGE_300_HEX,
  BG_PINK_300_HEX,
  BG_PRIMARY_600_HEX,
  BG_YELLOW_300_HEX,
} from '../../../app/theme'
import { Text, Tooltip } from '../../designs'
import { formatNumber } from '../../utils/numberFormatting'
import type { ChartDataEntry } from './types'

interface Props {
  data: ChartDataEntry[]
  unit: string
  maxVisualizedValues?: number
  showLabels?: boolean
  isLoading?: boolean
}

const visualizationColors: { [key in number]: string } = {
  0: BG_PRIMARY_600_HEX,
  1: BG_PINK_300_HEX,
  2: BG_YELLOW_300_HEX,
  3: BG_CYAN_300_HEX,
  4: BG_GREEN_300_HEX,
  5: BG_INDIGO_300_HEX,
  6: BG_ORANGE_300_HEX,
}

const HorizontalBar = ({
  data,
  unit,
  maxVisualizedValues = 7,
  showLabels = false,
  isLoading = false,
}: Props) => {
  if (isLoading) {
    return <div className='inline-block h-8 w-full animate-pulse bg-gray-300' />
  }

  const totalTime = data.reduce((total, item) => total + item.value, 0)

  const visualizationData = data.map(({ value, label, color }) => ({
    value,
    label,
    relativeValue: Math.round((value / totalTime) * 100),
    color,
  }))

  let relativeValueCounter = 0
  const visualizedValues = visualizationData.filter(({ relativeValue }, i) => {
    const isNotRestValue =
      i < maxVisualizedValues || relativeValueCounter < 80 || i === visualizationData.length - 1
    relativeValueCounter += relativeValue
    return isNotRestValue
  })

  let restValues = null
  if (visualizedValues.length !== visualizationData.length) {
    const remainingValue = visualizationData
      .slice(visualizedValues.length)
      .reduce((total, item) => total + item.value, 0)
    const relativeValue =
      100 - visualizedValues.reduce((total, item) => total + item.relativeValue, 0)

    restValues = (
      <div className='flex h-full grow flex-col gap-0.5'>
        <Tooltip
          label='Rest'
          text={`${formatNumber(remainingValue, 10)} ${unit} (${formatNumber(relativeValue, 10)}%)`}
        >
          <div className='h-8 rounded-r bg-gray-300' />
        </Tooltip>
        {showLabels ? (
          <Text size='xs' className='w-full truncate text-center'>
            Rest
          </Text>
        ) : null}
      </div>
    )
  }

  return (
    <div className='flex w-full align-middle'>
      {visualizedValues.map(({ color, relativeValue, value, label }, i) => {
        const roundedStart = i === 0 ? 'rounded-l' : ''
        const roundedEnd = i === visualizationData.length - 1 ? 'rounded-r' : ''

        return (
          <div
            className='flex h-full flex-col gap-0.5'
            style={{ width: `${relativeValue}%` }}
            key={label}
          >
            <Tooltip
              label={label}
              text={`${formatNumber(value, 10)} ${unit} (${formatNumber(relativeValue, 10)}%)`}
            >
              <div
                className={`${color ?? visualizationColors[i]} h-8 ${roundedStart} ${roundedEnd}`}
              />
            </Tooltip>
            {showLabels ? (
              <Text size='xs' className='w-full truncate text-center'>
                {label}
              </Text>
            ) : null}
          </div>
        )
      })}
      {restValues}
    </div>
  )
}

export default HorizontalBar
