import { Outlet, useLocation } from 'react-router-dom'

import { Tabs, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { ProcessSubPathEnum } from './types'

const navTabs = [
  { label: 'CSV Export', value: ProcessSubPathEnum.DATA_INTEGRATION },
  { label: 'SAP Signavio Ingestion API', value: ProcessSubPathEnum.INGESTION_API },
]

const DataIntegrationLayout = () => {
  const location = useLocation()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const activeTabValue = location.pathname.includes(ProcessSubPathEnum.INGESTION_API)
    ? ProcessSubPathEnum.INGESTION_API
    : ProcessSubPathEnum.DATA_INTEGRATION

  const onTabSelected = (val: string) => {
    navigate(val)
  }

  return (
    <div>
      <Text size='xl' weight={600}>
        Process Data Integration
      </Text>
      <Tabs size='s' options={navTabs} value={activeTabValue} onChange={onTabSelected} />
      <Outlet />
    </div>
  )
}

export default DataIntegrationLayout
