import { ArrowDownTrayIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'

import {
  useGetDashboardAppDataQuery,
  useGetTeamsQuery,
  useGetToolingLinkQuery,
  useGetTrackedProcessesListQuery,
} from '../../app/apiSlice'
import { LINK_DEVELOPER_DOCUMENTATION } from '../../app/constants'
import { Breadcrumbs, Button, Divider, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { downloadFile } from '../../common/utils/utils'
import ConfigFileManagementSection from './ConfigFileManagementSection'
import ConfigFileManagementUseMainDisplay from './ConfigFileManagementUseMainDisplay'
import ShadowContainer from './ShadowContainer'

const ConfigFileManagement = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const [showTeams, setShowTeams] = useState<boolean>(false)

  const { data: processData } = useGetTrackedProcessesListQuery()
  const { data: applicationData } = useGetDashboardAppDataQuery()
  const { data: teamData } = useGetTeamsQuery()
  const { data: toolingLink } = useGetToolingLinkQuery()

  const allApplications =
    applicationData?.map(({ name }) => ({
      name,
      hasTrainingData: false,
    })) ?? []
  const allProcesses = processData?.map((p) => p.name) ?? []

  useEffect(() => {
    if (teamData?.some((team) => team.configuration_source === 'Team')) {
      setShowTeams(true)
    }
  }, [teamData])

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('../..'),
          },
          {
            label: 'Advanced Setup',
            onClick: () => navigate('..'),
          },
          {
            label: 'Configuration File Management',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />
      <div className='px-2 pt-3 lg:pl-5 lg:pr-7 lg:pt-5  xl:pl-7 xl:pr-9 xl:pt-6'>
        <ShadowContainer className='mb-5'>
          <div className='grid grid-cols-2'>
            <div className='relative border-r border-r-gray-300 px-10 py-6'>
              <Text size='l' weight={700} className='mb-2'>
                Tooling
              </Text>
              <Text className='mb-2'>
                Download Work API tooling to configure data collection in detail.
              </Text>
              <div>
                <Button
                  className='shrink-0'
                  iconStart={<ArrowDownTrayIcon />}
                  onClick={() => {
                    downloadFile(toolingLink || '', 'workfellow-tool-bundle.zip')
                  }}
                  disabled={!toolingLink}
                >
                  Download Tool Bundle
                </Button>
              </div>
            </div>

            <div className='px-10 py-6'>
              <Text size='l' weight={700} className='mb-2'>
                Instructions
              </Text>
              <Text className='mb-2'>
                Download the documentation to learn Work API configuration syntax and tooling.
              </Text>
              <Button
                iconStart={<InformationCircleIcon />}
                onClick={() => window.open(LINK_DEVELOPER_DOCUMENTATION, '_blank', 'rel=noopener')}
              >
                Documentation
              </Button>
            </div>
          </div>
        </ShadowContainer>

        <ConfigFileManagementSection
          title='Main config'
          allApplications={allApplications}
          allProcesses={allProcesses}
        />

        {!showTeams ? (
          <Button onClick={() => setShowTeams(true)}>Show team configs</Button>
        ) : (
          teamData?.map((team) => {
            return team.configuration_source === 'Company' ? (
              <ConfigFileManagementUseMainDisplay key={team.team_id} team={team} />
            ) : (
              <ConfigFileManagementSection
                key={team.team_id}
                title={team.team_name}
                allApplications={allApplications}
                allProcesses={allProcesses}
                team={team}
              />
            )
          }) ?? []
        )}
      </div>
    </>
  )
}

export default ConfigFileManagement
