import { v4 as uuid4 } from 'uuid'

import type { RawPatternType, RuleIn } from './types'

export const addReactionToRule = (
  rules: RuleIn[],
  reactionCode: string,
  elementName: string,
): RuleIn[] => {
  const reaction = JSON.parse(reactionCode) as RawPatternType
  const extractStep = reaction[reaction.length - 1]
  if ('Extract' in extractStep && 'As' in extractStep.Extract) {
    extractStep.Extract.As = elementName
  }

  const fullReaction = {
    on: ['SwitchWindow'],
    react: reaction,
  }

  return rules.map((rule) => ({
    ...rule,
    salvage_fields: [
      ...new Set([...rule.salvage_fields, 'reaction_execution_logs', 'interacted_elements']),
    ],
    reactions: [...rule.reactions, fullReaction],
    extract_identifiers: [
      ...rule.extract_identifiers,
      {
        id: uuid4(),
        identifier_name: elementName,
        key: elementName,
        hash_identifier: false,
        from_fields: ['interacted_elements'],
      },
    ],
  }))
}
