import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useState } from 'react'

type Variant = 'primary' | 'info' | 'question'
type Placement = 't' | 'r' | 'b' | 'l' | 'ts' | 'te' | 'rs' | 're' | 'bs' | 'be' | 'ls' | 'le'
type Color = 'darkGray' | 'gray' | 'lightGray' | 'lightestGray' | 'white' | 'primary'
type Size =
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '8xl'
  | '9xl'

const sizeStyles: { [key in Size]: string } = {
  xs: 'w-[32px]',
  s: 'w-[64px]',
  m: 'w-[128px]',
  l: 'w-[192px]',
  xl: 'w-[320px]',
  '2xl': 'w-[384px]',
  '3xl': 'w-[448px]',
  '4xl': 'w-[512px]',
  '5xl': 'w-[576px]',
  '6xl': 'w-[640px]',
  '7xl': 'w-[704px]',
  '8xl': 'w-[768px]',
  '9xl': 'w-[832px]',
}

const colorStyles: { [key in Color]: string } = {
  darkGray: 'bg-gray-900 text-white',
  gray: 'bg-gray-700 text-white',
  lightGray: 'bg-gray-500 text-white',
  lightestGray: 'bg-gray-300 text-white',
  white: 'bg-white text-gray-900',
  primary: 'bg-primary-600 text-white',
}

const tooltipPosition: { [key in Placement]: string } = {
  t: 'bottom-[125%] left-1/2 transform -translate-x-1/2',
  r: 'top-1/2 left-full ml-2 transform -translate-y-1/2',
  b: 'top-[125%] left-1/2 transform -translate-x-1/2',
  l: 'top-1/2 right-full mr-2 transform -translate-y-1/2',
  ts: 'bottom-[125%] left-0',
  te: 'bottom-[125%] right-0',
  rs: 'top-0 left-full ml-2',
  re: 'bottom-0 left-full ml-2',
  bs: 'top-[125%] left-0',
  be: 'top-[125%] right-0',
  ls: 'top-0 right-full mr-2',
  le: 'bottom-0 right-full mr-2',
}

type Props = {
  text: string

  placement?: Placement
  variant?: Variant
  size?: Size
  color?: Color
  children?: React.ReactNode
  label?: string
  className?: string
  style?: React.CSSProperties
}

const JTooltip = ({
  variant = 'primary',
  size,
  color = 'darkGray',
  placement = 't',
  text,
  label,
  children,
  className,
  style,
}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)

  return (
    <div
      className={clsx('relative inline-block', className)}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
      style={style}
    >
      {variant === 'primary' && children}
      {variant === 'info' && <InformationCircleIcon className='size-6' />}
      {variant === 'question' && <QuestionMarkCircleIcon className='size-6' />}
      <div
        className={clsx(
          'absolute z-10 rounded-md bg-gray-500 px-2 py-1 text-center transition-opacity duration-300',
          isTooltipVisible ? 'visible opacity-90' : 'invisible opacity-0',
          size && sizeStyles[size],
          colorStyles[color],
          tooltipPosition[placement],
        )}
      >
        {label && <p className='mb-2 text-base font-semibold'>{label}</p>}
        <p className='text-xs font-medium'>{text}</p>
      </div>
    </div>
  )
}

export default JTooltip
