import _ from 'lodash'
import { useMemo, useState } from 'react'
import type { BaseNode, Data, GraphEvents, GraphEventsSelectParams } from 'react-graph-vis'
import { useOutletContext } from 'react-router-dom'

import { useGetNetworkNavigationsQuery } from '../../../app/apiSlice'
import { appCategoryColorMap } from '../../../app/theme'
import { useCostUtils, useQueryFilters } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import type { ApplicationCategory } from '../../../common/types/dataCollectionsConfigs'
import NavigationsFlyoutStats from '../../DataFlows/NavigationsFlyoutStats'
import Legend from '../../Network/Legend'
import NetworkGraph from '../../Network/NetworkGraph'
import { DetailLevelEnum } from '../../Network/types'
import type {
  NavigationInsight,
  NavigationInsightEdge,
  NavigationInsightNode,
  NetworkNavigationsResponse,
} from '../../Network/types'
import useClusterLegend from '../../Network/useClusterLegend'
import { calculateOriginVisits, formatTagsToId, removeWhiteSpaces } from '../../Network/utils'

const Navigations = () => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()
  const { annualizedCostMultiplier, formatCost, generateAnnualizedNavigationsCost } = useCostUtils()
  const [clustersVisible, setClustersVisible] = useClusterLegend()

  const { tagFilters } = useOutletContext<{ tagFilters: Tag[]; appName: string }>()

  const filters = tagFilters ? [tagFilters[0]] : []

  const [insights, setInsights] = useState<NavigationInsight>({ node: null, edge: null })
  const [enhancedData, setEnhancedData] = useState<NetworkNavigationsResponse | null>(null)

  const { data } = useGetNetworkNavigationsQuery(
    {
      min_date,
      max_date,
      team_ids,
      tags_of_interest: tags_of_interest.application,
      node_amount: 1000,
      tag_filters: filters,
      match_any_tag_filters: [],
    },
    {
      skip: !tagFilters.length,
    },
  )

  const isSelfLoopsVisible = true // TODO: Consider implementing visibility toggle for self-loops.

  const graphData = useMemo(() => {
    if (!data) return null

    const filteredNodeId = formatTagsToId(tagFilters)
    const enhancedNodesObj = _.cloneDeep(data.nodes)

    const nodes: BaseNode[] = Object.values(data.nodes)
      .filter(({ group }) => clustersVisible[group as ApplicationCategory])
      .map((node) => {
        const { id, label, x, y, group, properties } = node

        const originVisits = calculateOriginVisits(data.edges, id, filteredNodeId)
        enhancedNodesObj[id].value = originVisits

        return {
          id,
          label,
          value: originVisits,
          x,
          y,
          group,
          color: appCategoryColorMap[group],
          properties: { ...properties, originVisits },
        }
      })

    const edges = Object.values(data.edges).filter(({ from, to }) => {
      return isSelfLoopsVisible || from !== to
    })

    setEnhancedData({ nodes: enhancedNodesObj, edges: data.edges })

    return { nodes, edges } as Data
  }, [data, isSelfLoopsVisible, clustersVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  const events: GraphEvents = {
    select: ({ nodes, edges }: GraphEventsSelectParams) => {
      if (!enhancedData) return

      let nodeInsight: NavigationInsightNode | null = null
      let edgeInsight: NavigationInsightEdge | null = null

      if (nodes.length) {
        const nodeId = removeWhiteSpaces(nodes[0])
        const node = enhancedData.nodes[nodeId]

        let originVisits: number | null = node.value
        if (nodeId === formatTagsToId(tagFilters)) originVisits = null
        const visits = node.properties.visits
        const totalH = node.properties.time_h
        const annualizedCost = formatCost(totalH * annualizedCostMultiplier)

        nodeInsight = { entity: node, originVisits, visits, totalH, annualizedCost }
      }

      if (edges.length) {
        const edgeId = edges[0]
        const edge = enhancedData.edges[edgeId]

        const navigations = edge.value
        const annualizedCost = generateAnnualizedNavigationsCost(navigations)

        edgeInsight = { entity: edge, navigations, annualizedCost }
      }

      setInsights({ node: nodeInsight, edge: edgeInsight })
    },
  }

  return (
    <div className='relative size-full'>
      <NavigationsFlyoutStats insights={insights} detailLevel={DetailLevelEnum.APP} />

      <div className='absolute right-0 z-[1] space-y-2'>
        <Legend clustersVisible={clustersVisible} setClustersVisible={setClustersVisible} />
      </div>

      <NetworkGraph data={graphData} events={events} />
    </div>
  )
}

export default Navigations
