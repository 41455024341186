import clsx from 'clsx'
import { ReactNode, cloneElement } from 'react'

const btnVariantStyles: { [key in Variant]: string } = {
  gray: 'hover:bg-gray-500/20',
  red: 'hover:bg-red-600/20',
  yellow: 'hover:bg-yellow-600/20',
  orange: 'hover:bg-orange-600/20',
  green: 'hover:bg-green-600/20',
  lime: 'hover:bg-lime-600/20',
  blue: 'hover:bg-blue-600/20',
  cyan: 'hover:bg-cyan-600/20',
  indigo: 'hover:bg-indigo-600/20',
  purple: 'hover:bg-purple-600/20',
  pink: 'hover:bg-pink-600/20',
}

const btnSvgVariantStyles: { [key in Variant]: string } = {
  gray: 'stroke-gray-600/50 group-hover:stroke-gray-600/75',
  red: 'stroke-red-600/50 group-hover:stroke-red-600/75',
  yellow: 'stroke-yellow-700/50 group-hover:stroke-yellow-700/75',
  orange: 'stroke-orange-700/50 group-hover:stroke-orange-700/75',
  green: 'stroke-green-700/50 group-hover:stroke-green-700/75',
  lime: 'stroke-lime-700/50 group-hover:stroke-lime-700/75',
  blue: 'stroke-blue-700/50 group-hover:stroke-blue-700/75',
  cyan: 'stroke-cyan-700/50 group-hover:stroke-cyan-700/75',
  indigo: 'stroke-indigo-600/50 group-hover:stroke-indigo-600/75',
  purple: 'stroke-purple-600/50 group-hover:stroke-purple-600/75',
  pink: 'stroke-pink-700/50 group-hover:stroke-pink-700/75',
}

const variantStyles: { [key in Variant]: string } = {
  gray: 'bg-gray-50 text-gray-600 ring-gray-500/10',
  red: 'bg-red-50 text-red-700 ring-red-600/10',
  yellow: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
  orange: 'bg-orange-50 text-orange-700 ring-orange-600/20',
  green: 'bg-green-50 text-green-700 ring-green-600/20',
  lime: 'bg-lime-50 text-lime-700 ring-lime-600/20',
  blue: 'bg-blue-50 text-blue-700 ring-blue-700/10',
  cyan: 'bg-cyan-50 text-cyan-700 ring-cyan-700/20',
  indigo: 'bg-indigo-50 text-indigo-700 ring-indigo-700/10',
  purple: 'bg-purple-50 text-purple-700 ring-purple-700/10',
  pink: 'bg-pink-50 text-pink-700 ring-pink-700/10',
}

const spanGapStyles: { [key in Size]: string } = {
  s: 'ml-0.5',
  m: 'ml-1',
  l: 'ml-1.5',
}

const iconSizeStyles: { [key in Size]: string } = {
  s: 'size-2.5 mr-0.5',
  m: 'size-3 mr-1',
  l: 'size-3.5 mr-1.5',
}

const sizeStyles: { [key in Size]: string } = {
  s: 'px-2 py-0.5 text-[0.688rem]',
  m: 'px-2 py-1 text-xs',
  l: 'px-2.5 py-1 text-sm',
}

export type Variant =
  | 'gray'
  | 'red'
  | 'yellow'
  | 'orange'
  | 'green'
  | 'lime'
  | 'blue'
  | 'cyan'
  | 'indigo'
  | 'purple'
  | 'pink'

type Size = 's' | 'm' | 'l'

interface Props {
  variant: Variant
  children: ReactNode

  size?: Size
  icon?: React.ReactElement
  onClick?: () => void
  className?: string
  rounded?: boolean
}

const Badge = ({ variant, children, size = 'm', icon, onClick, className, rounded }: Props) => {
  return (
    <span
      className={clsx(
        'inline-flex items-center font-medium ring-1 ring-inset',
        rounded ? 'rounded-full' : 'rounded-md',
        variantStyles[variant],
        sizeStyles[size],
        className,
      )}
    >
      {icon && cloneElement(icon, { className: clsx(iconSizeStyles[size]) })}
      {children}

      {onClick && (
        <button
          type='button'
          className={clsx(
            'group relative -mr-1 size-3.5',
            btnVariantStyles[variant],
            spanGapStyles[size],
            rounded ? 'rounded-full' : 'rounded',
          )}
          onClick={onClick}
        >
          <span className='sr-only'>Remove</span>
          <svg viewBox='0 0 14 14' className={clsx('size-3.5', btnSvgVariantStyles[variant])}>
            <path d='M4 4l6 6m0-6l-6 6' />
          </svg>
          <span className='absolute -inset-1' />
        </button>
      )}
    </span>
  )
}

export default Badge
