import { ActiveElement, ChartEvent, ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'
import { isArray } from 'lodash'

import { formatNumber } from '../../utils/numberFormatting'

interface BarchartOptionParams {
  unit: string
  yMaxValue: number
  onClick: ((el: any) => void) | undefined

  tooltipCallback:
    | ((tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => string | string[])
    | undefined
    | null
  yTicksCallback: ((value: string | number) => string) | undefined
  tickAmount: number | undefined
  hideGrid: boolean | undefined | EventTarget
  xStacked?: boolean | undefined
  maxXLabelLength?: number | undefined
}

export const getBarchartOptions = ({
  unit,
  onClick,
  yMaxValue,
  yTicksCallback,
  tooltipCallback,
  tickAmount,
  hideGrid,
  xStacked = true,
  maxXLabelLength,
}: BarchartOptionParams) => {
  const defaultYTicksCallback = (value: number) => `${formatNumber(value)} ${unit}`
  const defaultTooltipCallback = (tooltipItems: any) =>
    ` ${formatNumber(tooltipItems.raw, 10)} ${unit}`

  return {
    scales: {
      y: {
        suggestedMax: yMaxValue,
        ticks: {
          callback: yTicksCallback ?? defaultYTicksCallback,
          maxTicksLimit: tickAmount ?? 15,
          font: {
            family: 'Inter',
          },
        },
        grid: {
          display: !hideGrid,
        },
      },
      x: {
        stacked: xStacked,
        grid: {
          display: !hideGrid,
        },
        ticks: {
          callback: function (val: number) {
            let label = this.getLabelForValue(val)
            if (isArray(label)) label = label.join(', ')
            if (!maxXLabelLength) return label

            return label.length > maxXLabelLength
              ? label.slice(0, maxXLabelLength - 3) + '...'
              : label
          },
          font: {
            family: 'Inter',
          },
        },
      },
    },
    maintainAspectRatio: false,

    onClick: (_event: ChartEvent, elements: ActiveElement[]) => {
      const el = elements?.[0]
      if (el && onClick) onClick(el)
    },
    onHover: function (event: ChartEvent) {
      // @ts-ignore:next-line
      const points = this.getElementsAtEventForMode(
        event,
        'index',
        { axis: 'x', intersect: true },
        false,
      )

      const target = event?.native?.target as HTMLElement
      if (points.length && onClick) target.style.cursor = 'pointer'
      else target.style.cursor = 'default'
    },
    plugins: {
      legend: {
        display: false, // Custom LegendContainer already in use
      },
      tooltip: {
        callbacks: {
          label: tooltipCallback ?? defaultTooltipCallback,
        },
      },
    },
  } as ChartOptions<'bar'>
}
