import { useMemo } from 'react'

import { useGetApplicationCategoriesQuery } from '../../../app/apiSlice'
import { Stats } from '../../../common/components'
import { useFilteredWindowUsageQuery } from '../../../common/hooks'
import { applicationCategories } from '../../../common/types/dataCollectionsConfigs'
import { formatNumber } from '../../../common/utils/numberFormatting'
import type { WindowUsageData } from '../types'

const ApplicationCategoriesStats = () => {
  const { data: windowUsage } = useFilteredWindowUsageQuery({ tagsOfInterestSource: 'application' })
  const { data: applicationCategorization } = useGetApplicationCategoriesQuery()

  const categorizationTime = useMemo(() => {
    const timeUsage = Object.fromEntries(
      Object.values(applicationCategories).map((applicationCategory) => [applicationCategory, 0]),
    )
    const applicationCategoryUsage: {
      label: string
      value: string | number
    }[] = []
    let total = 0

    if (windowUsage && applicationCategorization) {
      ;(windowUsage as WindowUsageData[])?.forEach((windowEvent) => {
        total += windowEvent?.time_h ?? 0

        if (timeUsage[applicationCategorization[windowEvent.name]] !== undefined) {
          timeUsage[applicationCategorization[windowEvent.name]] += windowEvent?.time_h ?? 0
        } else {
          timeUsage['Other'] += windowEvent?.time_h ?? 0
        }
      })
    }

    Object.entries(timeUsage).forEach(([key, value]) =>
      applicationCategoryUsage.push({
        value: `${formatNumber((100 * value) / total, 1)}%`,
        label: key,
      }),
    )

    return applicationCategoryUsage
  }, [applicationCategorization, windowUsage])

  return <Stats data={categorizationTime} />
}

export default ApplicationCategoriesStats
