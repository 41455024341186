import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

import { Button, Text } from '../../designs'

interface Props {
  value: number
  options: number[]
  onChange: (amount: number) => void
  totalAmount: number
}

const ChartDataSelector = ({ value, options, onChange, totalAmount }: Props) => {
  return (
    <Menu as='div' className='relative inline-block w-full text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-3 py-1 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100'>
          {value >= totalAmount ? 'All' : `Top ${value}`}
          <ChevronDownIcon className='-mr-2 ml-2 size-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <div className='absolute right-0 z-10 mt-2 w-72 origin-top-right flex-col gap-2 rounded-md bg-white px-3 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <Text variant='h5' size='l' className=''>
            Select data range
          </Text>

          <div className='flex gap-3'>
            {options.map((o) => (
              <Button key={o} variant='white' size='xs' onClick={() => onChange(o)}>
                Top {o}
              </Button>
            ))}
            <Button variant='white' size='xs' onClick={() => onChange(totalAmount)}>
              All
            </Button>
          </div>
        </div>
      </Transition>
    </Menu>
  )
}

export default ChartDataSelector
