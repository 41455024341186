import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import type { DataCollectionTestCaseFormData } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { InputField, Link } from '../../designs'

interface Props {
  control: Control<DataCollectionTestCaseFormData, any>
}

const DataCollectionTestForm = ({ control }: Props) => {
  const [isIdentifierTestsOpen, setIsIdentifierTestsOpen] = useState<boolean>(false)

  return (
    <div className='space-y-2'>
      <Controller
        name='url'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputField
            size='s'
            label='URL'
            autoComplete='off'
            placeholder='e.g. sap.com'
            error={error?.message}
            {...field}
          />
        )}
      />

      <Controller
        name='processName'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputField
            size='s'
            label='Process Name'
            autoComplete='off'
            placeholder='e.g. excel.exe'
            error={error?.message}
            {...field}
          />
        )}
      />

      <Controller
        name='title'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputField
            size='s'
            label='Window/Tab Title'
            autoComplete='off'
            placeholder='e.g. SAP - Google Chrome'
            error={error?.message}
            {...field}
          />
        )}
      />

      <div className='pt-4'>
        {isIdentifierTestsOpen ? (
          <>
            <Link onClick={() => setIsIdentifierTestsOpen(false)}>Hide Process Tests</Link>
            <div className='space-y-2'>
              <Controller
                name='processIdentifierName'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputField
                    size='s'
                    label='Expected Process Name'
                    autoComplete='off'
                    placeholder='e.g. Salesforce Case'
                    error={error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name='processIdentifierValue'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputField
                    size='s'
                    label='Expected Process ID'
                    autoComplete='off'
                    placeholder='123456'
                    error={error?.message}
                    {...field}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <Link onClick={() => setIsIdentifierTestsOpen(true)}>Show Process Tests</Link>
        )}
      </div>
    </div>
  )
}

export default DataCollectionTestForm
