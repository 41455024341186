import { useGetCompanyTrendsQuery } from '../../app/dataTrendsApiSlice'
import FavoritesDisplay, {
  FavoriteTypeEnum,
} from '../../common/components/displays/FavoritesDisplay/FavoritesDisplay'

const CompanyGoals = () => {
  const { data } = useGetCompanyTrendsQuery()

  return <FavoritesDisplay type={FavoriteTypeEnum.COMPANY} queries={data} />
}
export default CompanyGoals
