import { ExclamationTriangleIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import { IconButton } from '../../common/designs'

export interface ItemAccordionData {
  label: string
  key: number | string
  hasError?: boolean
  hasWarning?: boolean
  component?: React.ReactNode | null
}

interface Props {
  data: ItemAccordionData[]
}

const ItemAccordion = ({ data }: Props) => {
  const [openAccordions, setOpenAccordions] = useState<boolean[]>(data.map((_) => false))

  const updateOpenAccordions = (index: number) => {
    const openAccordionsCopy = [...openAccordions]
    openAccordionsCopy[index] = !openAccordionsCopy[index]
    setOpenAccordions(openAccordionsCopy)
  }

  return (
    <dd className='mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
      <ul className='divide-y divide-gray-100 rounded-md border border-gray-200'>
        {data.map(({ label, key, hasError, hasWarning, component }, i) => (
          <li className='py-1 pl-4 pr-5 text-sm leading-6' key={key}>
            <div className='flex items-center justify-between'>
              <div className='flex w-0 flex-1 items-center'>
                <div className='ml-4 flex min-w-0 flex-1 items-center gap-2'>
                  <span className='truncate font-medium'>{label}</span>
                  {hasError && <ExclamationTriangleIcon className='size-5 text-red-700' />}
                  {hasWarning && <ExclamationTriangleIcon className='size-5 text-yellow-600' />}
                </div>
              </div>
              <div className='ml-4 shrink-0'>
                <IconButton
                  onClick={() => updateOpenAccordions(i)}
                  icon={openAccordions[i] ? <MinusIcon /> : <PlusIcon />}
                  variant='ghost'
                />
              </div>
            </div>
            <div className={openAccordions[i] ? 'mt-3 rounded-md' : 'hidden'}>{component}</div>
          </li>
        ))}
      </ul>
    </dd>
  )
}

export default ItemAccordion
