import { useSearchParams } from 'react-router-dom'

import { useQueryFilters } from '../../common/hooks'
import type { TaskFilters } from './types'
import { TaskFilterNameEnum } from './types'

const searchParamToStringArray = (value: string | null) => {
  return value ? (JSON.parse(value) as string[]) : null
}

export const useGetTaskFiltersFromSearch = (): TaskFilters => {
  const [searchParams] = useSearchParams()
  const { team_ids } = useQueryFilters()

  const windowFilters = {
    start_app: searchParams.get(TaskFilterNameEnum.START_APP),
    start_window: searchParams.get(TaskFilterNameEnum.START_WINDOW),
    end_app: searchParams.get(TaskFilterNameEnum.END_APP),
    end_window: searchParams.get(TaskFilterNameEnum.END_WINDOW),
    apps_in_task: searchParamToStringArray(searchParams.get(TaskFilterNameEnum.APPS_IN_TASK)),
    windows_in_task: searchParamToStringArray(searchParams.get(TaskFilterNameEnum.WINDOWS_IN_TASK)),
    main_app: searchParams.get(TaskFilterNameEnum.MAIN_APP),
  }
  const dataTransferFilters = {
    data_transfer_source_app: searchParams.get(TaskFilterNameEnum.DATA_TRANSFER_SOURCE_APP),
    data_transfer_source_window: searchParams.get(TaskFilterNameEnum.DATA_TRANSFER_SOURCE_WINDOW),
    data_transfer_target_app: searchParams.get(TaskFilterNameEnum.DATA_TRANSFER_TARGET_APP),
    data_transfer_target_window: searchParams.get(TaskFilterNameEnum.DATA_TRANSFER_TARGET_WINDOW),
  }
  const relatedProcessFilters = {
    process_name: searchParams.get(TaskFilterNameEnum.PROCESS_NAME),
    process_step_name: searchParams.get(TaskFilterNameEnum.PROCESS_STEP_NAME),
  }

  const minTaskStepAmount = searchParams.get(TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT)

  return {
    team_ids,
    min_task_step_amount: minTaskStepAmount ? parseInt(minTaskStepAmount) : null,
    window_filters: windowFilters,
    data_transfer_filters: dataTransferFilters,
    related_process_filters: relatedProcessFilters,
  }
}
