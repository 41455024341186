import { useState } from 'react'

import { useChangeUserCompanyMutation, useGetUserCompaniesQuery } from '../../../app/apiSlice'
import { Select } from '../../../common/designs'
import { useDashboard, useUpdateEffect } from '../../../common/hooks'

const CompanySelect = () => {
  const { company } = useDashboard()

  const { data } = useGetUserCompaniesQuery()
  const [changeCompany] = useChangeUserCompanyMutation()

  const [selectedCompany, setSelectedCompany] = useState<string>(company.id)

  useUpdateEffect(() => {
    setSelectedCompany(company.id)
  }, [company])

  return (
    <Select
      variant='ghost'
      size='s'
      value={selectedCompany}
      onChange={(companyUUID: string) => {
        if (companyUUID === company.id) return
        const companyId = data?.find((com) => com.company_uuid === companyUUID)?.company_id
        if (!companyId) return

        setSelectedCompany(companyUUID)
        changeCompany(companyId)
          .unwrap()
          .then(() => window.location.reload()) // Force refresh the page to update the active company and user profile
      }}
      options={
        data?.map((c) => {
          return {
            label: c.company_name,
            value: c.company_uuid,
          }
        }) ?? []
      }
      btnClassName='font-semibold'
      iconClassName='text-gray-900'
      rounded
    />
  )
}

export default CompanySelect
