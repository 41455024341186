import { TrendTimeGranularityEnum } from '../types'

export const TREND_DATE_FORMAT_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as Intl.DateTimeFormatOptions

export const TREND_TIME_GRANUARITY = [
  { value: TrendTimeGranularityEnum.DATE, label: 'Daily' },
  { value: TrendTimeGranularityEnum.WEEK, label: 'Weekly' },
  { value: TrendTimeGranularityEnum.MONTH, label: 'Monthly' },
]
