import { Modal, Text } from '../../designs'

interface Props {
  open: boolean
  onClose: () => void
}

export const IdentifierFromUrlPathHelpModal = ({ open, onClose }: Props) => {
  return (
    <Modal
      label='Instructions for Defining a Process ID from a URL Path'
      open={open}
      size='4xl'
      onClose={onClose}
    >
      <Text>
        Purpose: To help you specify where we can find the unique process ID within a URL.
      </Text>

      <Text className='mt-4' weight={500}>
        How to Define the Process ID:{' '}
      </Text>
      <Text>
        1. Identify the Keyword: Look at the URL path and find the keyword that appears just before
        the ID you want to capture.
      </Text>
      <Text>2. Enter the Keyword: Provide the keyword in the form so we can locate the ID.</Text>

      <Text className='mt-4' weight={500}>
        Example 1
      </Text>
      <Text>URL: salesforce.com/cases/12345</Text>
      <Text>
        Keyword:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          cases/
        </Text>{' '}
        because the ID (12345) follows the keyword cases/.
      </Text>

      <Text className='mt-4' weight={500}>
        Example 2
      </Text>
      <Text>URL: myapp.com/portal/orders/98765/details</Text>
      <Text>
        keyword:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          orders/
        </Text>{' '}
        because the ID (98765) follows the keyword orders/. Also the full path{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          portal/orders/
        </Text>{' '}
        works.
      </Text>
    </Modal>
  )
}

export const IdentifierFromUrlQueryHelpModal = ({ open, onClose }: Props) => {
  return (
    <Modal
      label='Instructions for Defining a Process ID from a URL Query Parameter'
      open={open}
      size='4xl'
      onClose={onClose}
    >
      <Text>
        Purpose: To help you specify where we can find the unique process ID within a URL.
      </Text>

      <Text className='mt-4' weight={500}>
        How to Define the Process ID:{' '}
      </Text>
      <Text>
        1. Identify the Query Parameter: Look at the URL and find the query parameter name that
        contains the ID you want to capture.
      </Text>
      <Text>
        2. Enter the Query Parameter Name: Provide the query parameter name in the form so we can
        locate the ID.
      </Text>

      <Text className='mt-4' weight={500}>
        Example 1
      </Text>
      <Text>URL: myapp.com/order?order_id=12345</Text>
      <Text>
        Query Parameter Name:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          order_id
        </Text>{' '}
        because the ID (12345) is defined in the query parameter order_id.
      </Text>

      <Text className='mt-4' weight={500}>
        Example 2
      </Text>
      <Text>URL: myapp.com/details?invoice_id=98765&session_id=abc123</Text>
      <Text>
        Query Parameter Name:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          invoice_id
        </Text>{' '}
        because the ID (98765) is defined in the query parameter invoice_id.
      </Text>
    </Modal>
  )
}

export const IdentifierFromTitleHelpModal = ({ open, onClose }: Props) => {
  return (
    <Modal
      label='Instructions for Defining a Process ID from the window title'
      open={open}
      size='4xl'
      onClose={onClose}
    >
      <Text>
        Purpose: To help you specify where we can find the unique process ID within a window title.
      </Text>

      <Text className='mt-4' weight={500}>
        How to Define the Process ID:{' '}
      </Text>
      <Text>
        1. Identify the Keyword: Look at the window title and find the keyword that appears just
        before the ID you want to capture.
      </Text>
      <Text>2. Enter the Keyword: Provide the keyword in the form so we can locate the ID.</Text>
      <Text>
        Note: The ID will be captured from the keyword until the next whitespace is detected or the
        title ends.
      </Text>

      <Text className='mt-4' weight={500}>
        Example 1
      </Text>
      <Text>Window Title: Salesforce Case 12345 - Customer Support</Text>
      <Text>
        Keyword:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          Case
        </Text>{' '}
        because the ID (12345) follows the keyword Cases and is captured until the next whitespace.
      </Text>

      <Text className='mt-4' weight={500}>
        Example 2
      </Text>
      <Text>Window Title: MyApp Order 98765</Text>
      <Text>
        Keyword:{' '}
        <Text variant='span' size='s' className='rounded-md bg-gray-300 px-1 py-0.5 text-red-800'>
          Order
        </Text>{' '}
        because the ID (98765) follows the keyword Order and is captured until the next whitespace.
      </Text>
    </Modal>
  )
}
