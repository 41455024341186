import NodeDistribution from './NodeDistribution'
import { useNodeHighlightData } from './useNodeHighlightData'

interface Props {
  stepName: string
}

const NodeTeamDistribution = ({ stepName }: Props) => {
  const { isTeamDistributionOpen, teamData } = useNodeHighlightData()
  if (!isTeamDistributionOpen || !teamData || !teamData[stepName]) return null

  return <NodeDistribution distributionData={teamData[stepName]} title='Teams' />
}

export default NodeTeamDistribution
