import { parse } from 'date-fns'

import { DEFAULT_DATE_FORMATTING_LOCALE } from '../../../app/constants'
import { type TrendData, TrendTimeGranularityEnum, TrendVariableEnum } from '../types'
import { TREND_DATE_FORMAT_OPTIONS } from './TrendConstants'

export const getLabelForTrend = (
  item: TrendData,
  selectedTredGranularity: TrendTimeGranularityEnum,
  divider: number,
  selectedVariable: TrendVariableEnum,
) => {
  let label = ''

  switch (selectedTredGranularity) {
    case TrendTimeGranularityEnum.DATE:
      label = new Date(
        item.year as number,
        // Server month numbers are from 1-12, Date-object expects them to be 0-11, hence the -1 here
        (item.monthNumber as number) - 1,
        item.dayNumber as number,
      ).toLocaleString(DEFAULT_DATE_FORMATTING_LOCALE, TREND_DATE_FORMAT_OPTIONS)
      break

    case TrendTimeGranularityEnum.WEEK:
      label = `W-${item.weekNumber}/${item.weekYear}`
      break

    case TrendTimeGranularityEnum.MONTH:
      label = `${item.monthNumber}/${item.year}`
      break
    default:
      break
  }
  return {
    label,
    value: item[selectedVariable] / divider,
  }
}

export const weekToStartAndEndDate = (weekNumber: number, year: number) => {
  const startDate = parse(`${weekNumber}-${year}`, 'I-R', new Date())
  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + 6)
  return { startDate, endDate }
}

export const weekToDateString = (weekNumber: number, year: number) => {
  const { startDate, endDate } = weekToStartAndEndDate(weekNumber, year)
  return `${startDate.toLocaleString(
    DEFAULT_DATE_FORMATTING_LOCALE,
    TREND_DATE_FORMAT_OPTIONS,
  )} - ${endDate.toLocaleString(DEFAULT_DATE_FORMATTING_LOCALE, TREND_DATE_FORMAT_OPTIONS)}`
}

const METRICS_FILTERED_BY_START_DATE = new Set([
  TrendVariableEnum.startedTransaction,
  TrendVariableEnum.startedTransactionAvgDuration,
  TrendVariableEnum.startedTransactionMedianDuration,
])
const METRICS_FILTERED_BY_END_DATE = new Set([
  TrendVariableEnum.closedTransactions,
  TrendVariableEnum.endedTransactionAvgDuration,
  TrendVariableEnum.endedTransactionMedianDuration,
])
export const TREND_METRICS_THAT_CAN_BE_FILTERED = new Set([
  ...METRICS_FILTERED_BY_START_DATE,
  ...METRICS_FILTERED_BY_END_DATE,
])

export const parseDateFiltersFromTrendPeriod = (
  data: TrendData,
  selectedTrendGranularity: TrendTimeGranularityEnum,
  selectedVariable: TrendVariableEnum,
) => {
  if (!data) return

  let startDate
  let endDate

  switch (selectedTrendGranularity) {
    case TrendTimeGranularityEnum.DATE:
      startDate = new Date(
        data.year as number,
        // Server month numbers are from 1-12, Date-object expects them to be 0-11, hence the -1 here
        (data.monthNumber as number) - 1,
        data.dayNumber as number,
      )
      endDate = new Date(
        data.year as number,
        // Server month numbers are from 1-12, Date-object expects them to be 0-11, hence the -1 here
        (data.monthNumber as number) - 1,
        data.dayNumber as number,
      )
      break

    case TrendTimeGranularityEnum.WEEK:
      if (!data.weekNumber || !data.weekYear) return
      ;({ startDate, endDate } = weekToStartAndEndDate(data.weekNumber, data.weekYear))
      break

    case TrendTimeGranularityEnum.MONTH:
      if (!data.monthNumber || !data.year) return

      startDate = new Date(data.year, data.monthNumber - 1, 1)
      endDate = new Date(data.year, data.monthNumber, 0)
      break

    default:
      return
  }

  let dateFilters = {}

  if (METRICS_FILTERED_BY_START_DATE.has(selectedVariable)) {
    dateFilters = {
      min_start_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`,
      max_start_date: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
    }
  } else if (METRICS_FILTERED_BY_END_DATE.has(selectedVariable)) {
    dateFilters = {
      min_end_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
      max_end_date: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
    }
  }

  return dateFilters
}
