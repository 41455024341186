import { useEffect, useState } from 'react'

import {
  useCreateTrackedProcessMutation,
  useDeleteTrackedProcessMutation,
  useGetTrackedProcessesListQuery,
  useUpdateTrackedProcessMutation,
} from '../../../app/apiSlice'
import { generateFormDataFromRule } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/configInputTransformations'
import type {
  DataCollectionRuleFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { ProcessGeneralInfo } from '../../../features/Processes/types'
import { Text } from '../../designs'
import { InlineLoader } from '../Loader'
import { ContentDisplay } from '../displays'
import DataCollectionRules from './DataCollectionRules'
import type { AppTestCaseGroup } from './types'

interface Props {
  appTestCasesGroup: AppTestCaseGroup
  onAddRuleIn: (formValues: DataCollectionRuleFormData) => Promise<void>
  onUpdateRuleIn: (formData: DataCollectionRuleFormData, originalRule: RuleIn) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

const ProcessLevelTestAndRules = ({
  appTestCasesGroup,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: Props) => {
  const { data: processList, isLoading: isLoadingProcessData } = useGetTrackedProcessesListQuery()

  const [createTrackedProcess, { isLoading: isLoadingCreateProcess }] =
    useCreateTrackedProcessMutation()
  const [updateTrackedProcess, { isLoading: isLoadingUpdateProcess }] =
    useUpdateTrackedProcessMutation()
  const [deleteTrackedProcess, { isLoading: isLoadingDeleteProcess }] =
    useDeleteTrackedProcessMutation()

  const [processNameToDataMapper, setProcessNameToDataMapper] = useState<{
    [key: string]: ProcessGeneralInfo
  }>({})

  useEffect(() => {
    const mapper = (processList ?? []).reduce(
      (mapper, process) => {
        mapper[process.process_identifier_name] = process
        return mapper
      },
      {} as { [key: string]: ProcessGeneralInfo },
    )

    setProcessNameToDataMapper(mapper)
  }, [processList])

  if (
    isLoadingProcessData ||
    isLoadingCreateProcess ||
    isLoadingUpdateProcess ||
    isLoadingDeleteProcess
  ) {
    return <InlineLoader />
  }

  if (!appTestCasesGroup.rules.length && !appTestCasesGroup.processRules.length) {
    return null
  }

  const onAddProcess = async (formValues: DataCollectionRuleFormData) => {
    if (!formValues.processName) {
      return
    }

    await createTrackedProcess({
      name: formValues.processName,
      process_identifier_name: formValues.processName,
      process_category: '',
      initial_visible_variant_amount: 5,
      state_tag_key: '',
    })

    onAddRuleIn(formValues)
  }

  const onUpdateProcess = async (formData: DataCollectionRuleFormData, originalRule: RuleIn) => {
    const originalProcessName = originalRule.dashboard_context?.process_name ?? ''
    if (originalProcessName in processNameToDataMapper && formData.processName) {
      const originalProcessdata = processNameToDataMapper[originalProcessName]
      updateTrackedProcess({
        processId: originalProcessdata.id,
        body: {
          ...originalProcessdata,
          name: formData.processName,
          process_identifier_name: formData.processName,
        },
      })
    }

    onUpdateRuleIn(formData, originalRule)
  }
  const onDeleteProcess = async (rule: RuleIn) => {
    const originalProcessName = rule.dashboard_context?.process_name ?? ''
    if (originalProcessName in processNameToDataMapper) {
      const originalProcessdata = processNameToDataMapper[originalProcessName]
      deleteTrackedProcess(originalProcessdata.id)
    }

    onDeleteRuleIn(rule)
  }

  return (
    <div className='space-y-8'>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Business Processes
        </Text>
        <ContentDisplay className='p-4'>
          <DataCollectionRules
            rulesForTestCases={appTestCasesGroup.processRules.filter(
              ({ dashboard_context }) =>
                (dashboard_context?.process_name ?? '') in processNameToDataMapper,
            )}
            onAddRuleIn={onAddProcess}
            onUpdateRuleIn={onUpdateProcess}
            onUpdateRuleInCode={onUpdateRuleInCode}
            onDeleteRuleIn={onDeleteProcess}
            isForProcessConfigs
            formInitialValues={generateFormDataFromRule(
              appTestCasesGroup.rules.length
                ? appTestCasesGroup.rules[0]
                : appTestCasesGroup.processRules[0],
            )}
          />
        </ContentDisplay>
      </div>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Process Dimensions
        </Text>
        <ContentDisplay className='p-4'>
          <DataCollectionRules
            rulesForTestCases={appTestCasesGroup.processRules.filter(
              ({ dashboard_context }) =>
                !((dashboard_context?.process_name ?? '') in processNameToDataMapper),
            )}
            onAddRuleIn={onAddRuleIn}
            onUpdateRuleIn={onUpdateRuleIn}
            onUpdateRuleInCode={onUpdateRuleInCode}
            onDeleteRuleIn={onDeleteRuleIn}
            isForProcessConfigs
            formInitialValues={generateFormDataFromRule(
              appTestCasesGroup.rules.length
                ? appTestCasesGroup.rules[0]
                : appTestCasesGroup.processRules[0],
            )}
          />
        </ContentDisplay>
      </div>
    </div>
  )
}

export default ProcessLevelTestAndRules
