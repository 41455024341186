import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useUpdateTeamsMutation } from '../../app/apiSlice'
import { currencyDropdown, currencyMapper } from '../../app/constants'
import type { BasicColorIndex } from '../../app/theme'
import { Button, ColorPicker, InputField, Modal, Select } from '../../common/designs'
import type { Team } from '../../common/types/common'
import { teamValidator } from '../../common/types/validators'

interface Inputs {
  cost_per_hour?: string | null | undefined // Small cost_per_hour hack because the text field converts it to string
  team_name: string
  color_index: number
  currency_option: string
  id: number
}

const formSchema = yup
  .object({
    id: yup.number().required(),
    team_name: teamValidator.team_name,
    color_index: teamValidator.color_index,
    cost_per_hour: teamValidator.cost_per_hour,
    currency_option: teamValidator.currency_option,
  })
  .required()

interface Props {
  activeTeam: Team | null
  open: boolean
  onClose: () => void
}

const EditTeamModal = ({ activeTeam, open, onClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm<Inputs>({ resolver: yupResolver(formSchema) })

  const [updateTeams, { isLoading }] = useUpdateTeamsMutation()

  useEffect(() => {
    if (!activeTeam) return

    reset({
      ...activeTeam,
      currency_option:
        currencyDropdown.find((o) => o.value === activeTeam.currency)?.value ??
        currencyDropdown[0].value,
    } as Inputs)
  }, [activeTeam, reset])

  const onEdit: SubmitHandler<Inputs> = (data: Inputs) => {
    updateTeams({
      teamId: data.id,
      body: {
        team_name: data.team_name,
        color_index: data.color_index as BasicColorIndex,
        cost_per_hour: data.cost_per_hour === '' ? null : Number(data.cost_per_hour),
        currency: data.currency_option,
        currency_symbol_UTF8: currencyMapper[data.currency_option].symbolUTF8,
      },
    })
      .unwrap()
      .then(() => onClose())
  }

  return (
    <Modal label='Edit a team' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onEdit)} className='flex flex-col gap-3'>
        <Controller
          name='team_name'
          control={control}
          render={({ field }) => (
            <InputField
              autoFocus
              label='Team name'
              autoComplete='off'
              error={errors.team_name?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='cost_per_hour'
          control={control}
          render={({ field }) => (
            <InputField
              type='number'
              label='Cost per hour'
              error={errors.cost_per_hour?.message}
              description='If team cost per hour is not set, the application will use company cost per hour for calculations.'
              min={0}
              step={0.01}
              {...(field as any)}
            />
          )}
        />

        <Controller
          name='currency_option'
          control={control}
          render={({ field }) => {
            return (
              <Select
                label='Currency'
                options={currencyDropdown}
                error={errors.currency_option?.message}
                {...field}
              />
            )
          }}
        />

        <ColorPicker
          size='s'
          label='Color'
          value={watch('color_index') as BasicColorIndex}
          onChange={(i) => setValue('color_index', i)}
        />

        <div className='mt-2 flex justify-end gap-2'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditTeamModal
