import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Dispatch, Fragment, SetStateAction } from 'react'

import type { SelectOption } from './types'

const variantStyles: { [key in Variant]: string } = {
  primary: 'focus:ring-1 bg-white border',
  ghost: '',
}

const sizeStyles: { [key in Size]: string } = {
  xs: 'pl-2 py-1',
  s: 'pl-3 py-1.5',
  m: 'pl-3 py-2',
}

type Variant = 'primary' | 'ghost'
type Size = 'xs' | 's' | 'm'
interface Props {
  options: SelectOption[]
  value: SelectOption
  onChange: Dispatch<SetStateAction<SelectOption>> | ((value: SelectOption) => void)

  variant?: Variant
  size?: Size
  className?: string
  btnClassName?: string
  iconClassName?: string
  label?: string
  description?: string
  error?: string | undefined
  disabled?: boolean
  rounded?: boolean
  optional?: boolean
}

// ! ----------------------------------! //
// ! Do not use this component anymore ! //
// ! ----------------------------------! //
const LegacySelect = (props: Props) => {
  const {
    options,
    value,
    onChange,

    variant = 'primary',
    size = 'm',
    className,
    btnClassName,
    iconClassName,
    label,
    description,
    error,
    disabled,
    rounded,
    optional,
  } = props

  return (
    <Listbox value={value} onChange={onChange} disabled={Boolean(disabled)}>
      {({ open }) => (
        <div className={className}>
          <div
            className={clsx(
              !label && !optional ? '' : 'flex',
              !label && optional ? 'flex-row-reverse' : 'justify-between',
            )}
          >
            {label && (
              <Listbox.Label className='block text-sm font-medium text-gray-700'>
                {label}
              </Listbox.Label>
            )}

            {optional && <span className='text-sm text-gray-400'>Optional</span>}
          </div>

          <div className={clsx('relative cursor-pointer', (label || optional) && 'mt-1')}>
            <Listbox.Button
              className={clsx(
                'relative w-full pl-3 pr-10 text-left text-sm focus:outline-none disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
                rounded ? 'rounded-full' : 'rounded-md',
                error
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                sizeStyles[size],
                variantStyles[variant],
                btnClassName,
              )}
            >
              {value?.prefix ? (
                <div className='flex'>
                  <div className='mr-3 flex items-center justify-center'>{value?.prefix}</div>
                  <span className={clsx(error ? 'text-red-700' : '')}>{value?.label}</span>
                </div>
              ) : (
                <span className='block truncate'>{value?.label}</span>
              )}

              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon
                  className={clsx(
                    'size-5',
                    error ? 'text-red-400' : iconClassName ? iconClassName : 'text-gray-400',
                  )}
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-50 mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none'>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      clsx(
                        'relative select-none py-2 pl-3 pr-9',
                        active ? 'bg-primary-600 text-white' : 'text-gray-900',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className='flex items-center'>
                        {option.prefix && (
                          <div className='mr-3 flex items-center justify-center'>
                            {option.prefix}
                          </div>
                        )}

                        <span
                          className={clsx(
                            'block truncate',
                            selected ? 'font-semibold' : 'font-normal',
                          )}
                        >
                          {option.label}
                        </span>

                        {selected && (
                          <span
                            className={clsx(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-primary-600',
                            )}
                          >
                            <CheckIcon className='size-5' aria-hidden='true' />
                          </span>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>

          {(description || error) && (
            <p className={clsx(error ? 'text-red-600' : 'text-gray-500', 'mt-2 text-sm')}>
              {error ? error : description}
            </p>
          )}
        </div>
      )}
    </Listbox>
  )
}

export default LegacySelect
