import { useEffect, useState } from 'react'

import { InlineLoader } from '../../common/components'
import DataCollectionRuleDisplay from '../../common/components/ApplicationConfigPage/DataCollectionRuleDisplay'
import { updateRuleIn } from '../../common/components/ApplicationConfigPage/dataHelpers'
import { Text } from '../../common/designs'
import type {
  DataCollectionRuleFormData,
  RuleIn,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import ItemAccordion, { ItemAccordionData } from './ItemAccordion'
import useManageServerTestCasesAndConfigs from './useManageServerTestCasesAndConfigs'

interface Props {
  rulesWithoutAppInfo: RuleIn[]
}

const RulesWithoutTestCases = ({ rulesWithoutAppInfo }: Props) => {
  const [rulesWithoutTestCases, setRulesWithoutTestCases] = useState<ItemAccordionData[]>([])

  const { deleteRule, updateRules, isUploadingRules, isRunningConfigTests } =
    useManageServerTestCasesAndConfigs()

  useEffect(() => {
    const onUpdateRuleIn = async (formData: DataCollectionRuleFormData, originalRule: RuleIn) => {
      await updateRules([updateRuleIn(formData, originalRule)])
    }

    const onDeleteRuleIn = async (rule: RuleIn) => {
      await deleteRule(rule.id)
    }

    setRulesWithoutTestCases(
      rulesWithoutAppInfo.map((ruleData, i) => {
        return {
          label: (ruleData?.description as string) ?? `Rule ${i}`,
          key: (ruleData?.description as string) ?? `Rule ${i}`,
          component: (
            <DataCollectionRuleDisplay
              isEditableFromForm={Boolean(ruleData.is_dashboard_generated)}
              ruleJson={ruleData ?? ({} as RuleIn)}
              onUpdateRuleIn={onUpdateRuleIn}
              onDeleteRuleIn={onDeleteRuleIn}
            />
          ),
        }
      }),
    )
  }, [deleteRule, updateRules, rulesWithoutAppInfo])

  if (isUploadingRules || isRunningConfigTests) return <InlineLoader />

  if (!rulesWithoutTestCases) return null

  return (
    <div className='space-y-2 p-4'>
      <Text size='l' weight={500} color='gray'>
        Rules not associated with applications
      </Text>
      <ItemAccordion data={rulesWithoutTestCases} />
    </div>
  )
}

export default RulesWithoutTestCases
