export const replaceWhitespace = (text: string, toValue: string): string => {
  return text.replace(/ /g, toValue)
}

export const convertPathToCaseName = (path: string): string => {
  const splitPath = path.split('/')
  // Remove first index (empty)
  splitPath.shift()

  // Format path start name
  path = splitPath[0]
  path = capitalizeFirstLetter(path)
  return path.replace(/-/g, ' ')
}

export const getAnnualizedCost = (): number => {
  const cost = document.querySelector('#annualized-cost-value')?.textContent

  if (!cost) return 0

  const costArr = cost.split(' ')
  costArr.pop()

  let formattedCost = ''
  costArr.forEach((c) => (formattedCost += c))

  return Number(formattedCost)
}

export const changeHslaColorAlpha = (color: string, alpha: number): string => {
  const colorString = color?.split(',')

  return `${colorString?.[0]},${colorString?.[1]},${colorString?.[2]}, ${alpha})`
}

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const createInitials = (name: string) => {
  return name.split(' ').reduce((accumulator, currentValue) => {
    return accumulator + currentValue.charAt(0)
  }, '')
}

// This can be used to do css calculations when calc(...) syntax is not allowed in the component.
type CssValue = string | number
type Operator = '+' | '-' | '*' | '/'
export const cssCalcPixels = (value1: CssValue, value2: CssValue, operator: Operator): string => {
  // Convert values to numbers if they are in pixels
  const convertToNumber = (value: CssValue): number => {
    if (typeof value === 'string' && value.endsWith('px')) {
      return parseFloat(value)
    }
    return parseFloat(value as string)
  }

  // Convert both values to numbers
  const num1 = convertToNumber(value1)
  const num2 = convertToNumber(value2)

  // Perform the operation based on the operator
  let result: number
  switch (operator) {
    case '+':
      result = num1 + num2
      break
    case '-':
      result = num1 - num2
      break
    case '*':
      result = num1 * num2
      break
    case '/':
      result = num1 / num2
      break
    default:
      throw new Error('Invalid operator')
  }

  return result + 'px'
}
