import { useState } from 'react'
import { toast } from 'react-toastify'

import { useCreateCompanyMutation } from '../../app/apiSlice'
import { Button, InputField, Select, Text } from '../../common/designs'

const companyTypeList = [
  { label: 'Customer', value: 'Customer', key: 'Customer' },
  { label: 'Demo', value: 'Demo', key: 'Demo' },
  { label: 'POC', value: 'POC', key: 'POC' },
  { label: 'Free trial', value: 'Free trial', key: 'Free trial' },
]

type CustomerType = 'Customer' | 'Demo' | 'Poc' | 'Free trial'

const CompanyCreation = () => {
  const [createCompany, { isLoading }] = useCreateCompanyMutation()

  const [company, setCompany] = useState<{
    company_name: string
    customer_status: CustomerType
    agent_installation_target: number
  }>({
    company_name: '',
    customer_status: 'Customer',
    agent_installation_target: 25,
  })

  const onClick = () => {
    createCompany({
      company_name: company.company_name,
      customer_status: company.customer_status as string,
      agent_installation_target: company.agent_installation_target,
    })
      .unwrap()
      .then(() => toast.success('Company created successfully'))
  }

  return (
    <div className='mt-8 w-76 rounded-md bg-slate-100 p-4'>
      <Text variant='h6' className='mb-3'>
        Create a new company
      </Text>

      <Text size='xs'>
        NOTE: This feature is only visible for Workfellow employees who are super users!
      </Text>

      <div className='mt-4 flex flex-col gap-4'>
        <Select
          label='Type'
          options={companyTypeList}
          value={company.customer_status}
          onChange={(value: any) =>
            setCompany({ ...company, customer_status: value as CustomerType })
          }
          description={`Company type won't affect the configurations`}
        />

        <InputField
          label='Name'
          value={company.company_name}
          onChange={(e: any) => setCompany({ ...company, company_name: e.target.value })}
        />

        <InputField
          label='Agent installation target'
          type='number'
          min={0}
          value={company.agent_installation_target}
          onChange={(e: any) =>
            setCompany({ ...company, agent_installation_target: e.target.value })
          }
        />

        <Button onClick={onClick} disabled={company.company_name.length < 2} loading={isLoading}>
          Create
        </Button>
      </div>
    </div>
  )
}

export default CompanyCreation
