import { useOutletContext } from 'react-router-dom'

import {
  useGetWindowDataflowsDestinationQuery,
  useGetWindowDataflowsSourceQuery,
} from '../../../app/apiSlice'
import { Loader } from '../../../common/components'
import { useQueryFilters } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import SankeyChart from './SankeyChart'

const CopyPastes = () => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()
  const { tagFilters } = useOutletContext<{ tagFilters: Tag[] }>()

  const queryBody = {
    min_date,
    max_date,
    team_ids,
    tags_of_interest: tags_of_interest.window,
    tag_filters: tagFilters,
    use_exact_tag_filter_match: true,
  }
  const { data: copiesToWindow, isFetching: isFetchingCopies } =
    useGetWindowDataflowsDestinationQuery(queryBody)
  const { data: copiesFromWindow, isFetching: isFetchingPastes } =
    useGetWindowDataflowsSourceQuery(queryBody)

  if (!copiesToWindow || !copiesFromWindow || isFetchingCopies || isFetchingPastes)
    return <Loader />

  return (
    <div className='flex gap-4'>
      <SankeyChart label='Copy Pastes To' data={copiesToWindow} />

      <SankeyChart label='Copy Pastes From' data={copiesFromWindow} />
    </div>
  )
}

export default CopyPastes
