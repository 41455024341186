import * as yup from 'yup'

const currency_option = yup.string().min(0).required('Currency is required')
const currency = yup.string().required('Currency is required')
const cost_per_hour = yup.string().required('Cost per hour is required')
const color_index = yup.number().min(0).max(10).required('Color index is a required field')
const agent_installation_target = yup
  .number()
  .min(0)
  .required('Agent installation target is a required field')

const team_name = yup
  .string()
  .max(256, 'Team name is over 256 characters long')
  .required('Team name is a required field')

export const teamValidator = {
  team_name,
  color_index,
  cost_per_hour: yup.string().nullable(),
  currency_option,
}

export const caseValidator = {
  color_index,
  case_category_name: yup
    .string()
    .min(2, 'Type name must be at least 2 characters long')
    .max(32, 'Type name must be less least 33 characters long')
    .required('Type name is required'),
}

export const companyValidator = {
  cost_per_hour,
  currency,
  agent_installation_target,
}
