import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Tabs } from '../../designs'

enum TabOptionEnum {
  FORM_VIEW = 'FORM_VIEW',
  CODE_VIEW = 'CODE_VIEW',
}

interface Props {
  formComponent?: React.ReactNode | null | undefined
  codeComponent?: React.ReactNode | null | undefined
  className?: string
}

const FormAndCodeDisplay = ({ formComponent, codeComponent, className = '' }: Props) => {
  const [activeTab, setActiveTab] = useState<TabOptionEnum>(
    formComponent ? TabOptionEnum.FORM_VIEW : TabOptionEnum.CODE_VIEW,
  )

  useEffect(() => {
    if (!formComponent) setActiveTab(TabOptionEnum.CODE_VIEW)
  }, [formComponent])

  const tabOptions = [
    { label: 'Form', value: TabOptionEnum.FORM_VIEW, disabled: !formComponent },
    { label: 'Code', value: TabOptionEnum.CODE_VIEW, disabled: !codeComponent },
  ]

  return (
    <>
      <Tabs
        variant='secondary'
        size='xs'
        className={clsx('w-48', className)}
        options={tabOptions}
        value={activeTab}
        onChange={setActiveTab as (value: string) => void}
      />
      {activeTab === TabOptionEnum.FORM_VIEW ? formComponent : codeComponent}
    </>
  )
}

export default FormAndCodeDisplay
