import React, { useState } from 'react'

import { Button, Text } from '../../designs'

interface Props {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  children: React.ReactNode
  label: string
}

const AddNewFormDisplay = ({ children, onSubmit, label }: Props) => {
  const [isAddNewVisible, setIsAddNewVisible] = useState<boolean>(false)

  return (
    <>
      {isAddNewVisible ? (
        <div className='overflow-hidden bg-white px-4 py-2 shadow sm:rounded-lg'>
          <div className='space-y-3'>
            <Text className='mb-2' variant='h4' size='l' weight={600} color='darkGray'>
              {label}
            </Text>
            <form onSubmit={onSubmit} className='space-y-4'>
              {children}
              <div className='space-x-2'>
                <Button size='s' type='submit'>
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Button onClick={() => setIsAddNewVisible(true)}>Add new</Button>
      )}
    </>
  )
}

export default AddNewFormDisplay
