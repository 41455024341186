import { useMemo } from 'react'

import {
  useGetTotalAppUsageTrendQuery,
  useGetTrackedProcessesListQuery,
} from '../../../app/apiSlice'
import { Loader } from '../../../common/components'
import { sortArrayOfObjectsBasedOnKeyStringValue } from '../../../common/utils/dataFormatting'
import type { ProcessGeneralInfo } from '../types'
import ProcessSection from './ProcessSection'
import TrackedProcessCard from './TrackedProcessCard'
import { yearAndWeekToKey } from './utils'

const UNDEFINED_CATEGORY = 'Other'

const ProcessList = () => {
  const { data, isFetching } = useGetTrackedProcessesListQuery()
  const { data: totalAppUsageTrend, isFetching: isFetchingtotalAppUsageTrend } =
    useGetTotalAppUsageTrendQuery()

  const { categorizedProcesses, unCategorizedProcesses } = useMemo(() => {
    const categorizedProcesses = {} as { [key: string]: ProcessGeneralInfo[] }
    const unCategorizedProcesses = [] as ProcessGeneralInfo[]
    data?.forEach((process: ProcessGeneralInfo) => {
      if (!process.process_category) unCategorizedProcesses.push(process)
      else if (!(process.process_category in categorizedProcesses)) {
        categorizedProcesses[process.process_category] = [process]
      } else {
        categorizedProcesses[process.process_category].push(process)
      }
    })
    return {
      categorizedProcesses,
      unCategorizedProcesses,
    }
  }, [data])

  const totalAppUsageTrendMapper = useMemo(() => {
    if (!totalAppUsageTrend) return {}
    const mapper = {} as { [key: string]: number }
    totalAppUsageTrend.forEach(({ year, isoWeekNumber, total_duration_sec }) => {
      mapper[yearAndWeekToKey(year, isoWeekNumber)] = total_duration_sec
    })
    return mapper
  }, [totalAppUsageTrend])

  if (isFetching || isFetchingtotalAppUsageTrend) return <Loader />

  const hasUncategorizedProcesses = unCategorizedProcesses.length > 0

  const categorizedProcessComponents = Object.entries(categorizedProcesses)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([category, processes]) => {
      return (
        <ProcessSection
          key={category}
          category={category}
          processes={sortArrayOfObjectsBasedOnKeyStringValue(processes, 'name')}
          totalAppUsageTrendMapper={totalAppUsageTrendMapper}
        />
      )
    })

  return (
    <>
      {Object.keys(categorizedProcesses).length === 0 ? (
        <div className='flex flex-wrap gap-4 p-2'>
          {[
            ...unCategorizedProcesses.map((process) => (
              <TrackedProcessCard
                key={process.id}
                process={process}
                totalAppUsageTrendMapper={totalAppUsageTrendMapper}
              />
            )),
          ]}
        </div>
      ) : (
        [
          categorizedProcessComponents,
          hasUncategorizedProcesses ? (
            <ProcessSection
              key={UNDEFINED_CATEGORY}
              category={UNDEFINED_CATEGORY}
              processes={sortArrayOfObjectsBasedOnKeyStringValue(unCategorizedProcesses, 'name')}
              totalAppUsageTrendMapper={totalAppUsageTrendMapper}
            />
          ) : null,
        ]
      )}
    </>
  )
}

export default ProcessList
