import { useCallback } from 'react'

import { useCreateAttachmentMutation } from '../../app/apiSlice'
import { STORAGE_ACCOUNT } from '../../app/constants'
import { uploadFile } from '../utils/azureBlobStorageUtils'
import { renameFile } from '../utils/dataFormatting'
import { useDashboard } from './'

const useAddAttachmentToCase = () => {
  const { company, fileStore } = useDashboard()

  const [createCaseAttachment] = useCreateAttachmentMutation()

  const handleAddAttachmentToCase = useCallback(
    async (caseId: string | number, file: File) => {
      const randomizedUrlPath = crypto.randomUUID()
      const blobStorageImgPath = `${STORAGE_ACCOUNT.OPPORTUNITIES.FOLDER_PATH}/${randomizedUrlPath}`
      const blobStorageImgData = renameFile(file, blobStorageImgPath)

      // Uploads image to Azure Storage Account
      // If image already exist for same content category it replaces existing image
      await uploadFile(
        blobStorageImgData,
        STORAGE_ACCOUNT.OPPORTUNITIES.CONTAINER_NAME,
        company.id,
        fileStore.token,
      )

      return createCaseAttachment({
        caseId,
        body: {
          name: '',
          type: file?.type || 'unknown',
          path: randomizedUrlPath,
        },
      })
    },
    [createCaseAttachment, fileStore.token, company.id],
  )

  return [handleAddAttachmentToCase]
}

export default useAddAttachmentToCase

