import { MsalProvider } from '@azure/msal-react'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import packageJson from '../package.json'
import App from './app/App'
import { ENVIRONMENT, SENTRY_DSN } from './app/configs'
import store from './app/store'
import './app/theme/index.css'
import { EnvEnum } from './common/types/common'
import { msalInstance } from './features/Authentication/aadConfig'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT ?? EnvEnum.DEV,
    release: packageJson.version,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>,
)

