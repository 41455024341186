import { Link, Text } from '../../common/designs'

interface Props {
  title: string
  description: string
  orderString: string
  instructionsLink: string
}

const GetStartedSection = ({ title, description, orderString, instructionsLink }: Props) => {
  return (
    <div className='rounded-xl bg-white p-2 shadow-md lg:p-6'>
      <Text size='xl'>{orderString}</Text>
      <Text size='xl' weight={600} className='mb-1.5 mt-0.5 !text-blue-800'>
        {title}
      </Text>
      <Text className='mb-1.5'>{description}</Text>

      <Link size='l' href={instructionsLink} target='_blank'>
        Instructions
      </Link>
    </div>
  )
}

export default GetStartedSection
