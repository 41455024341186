import { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { Text } from '../../../common/designs'
import { DataflowData } from '../types'
import type { WindowCopyPasteEvent, WindowNavigationEvent } from './types'

const isWindowNavigationEvent = (event: any): event is WindowNavigationEvent => {
  return (event as WindowNavigationEvent).navigations !== undefined
}

const isWindowCopyPasteEvent = (event: any): event is WindowCopyPasteEvent => {
  return (event as WindowCopyPasteEvent).event_count !== undefined
}

interface Props {
  data: WindowNavigationEvent[] | DataflowData[]
  label: string
}

const SankeyChart = ({ data, label }: Props) => {
  const [chartData, sum] = useMemo(() => {
    const sankeyChartData: Array<(string | number)[]> = [['From', 'To', 'Navigations']]
    let sum = 0

    data.forEach((dp) => {
      let count = 0
      let targetName = ''
      const sourceName = dp.source_name

      if (isWindowNavigationEvent(dp)) {
        count = dp.navigations
        targetName = dp.target_name
      } else if (isWindowCopyPasteEvent(dp)) {
        count = dp.event_count
        targetName = dp.destination_name
      }

      // For some reason the data consists of 0 value datapoints as well, so purge those from the visualizations.
      if (count === 0) return
      sum += count

      // targetName includes a whitespace on purpose to prevent circular dependency (window links to exactly same window, meaning self loops)
      sankeyChartData.push([sourceName, `${targetName} `, count])
    })

    return [sankeyChartData, sum]
  }, [data])

  if (!chartData.length) return null

  return (
    <div className='w-full'>
      <Text weight={500} className='text-center'>
        {sum} {label}
      </Text>

      {chartData.length === 1 ? null : (
        <Chart
          chartType='Sankey'
          data={chartData}
          options={
            {
              width: '100%',
              height: chartData.length * 25,
              sankey: {
                link: { color: { fill: '#C7D2FE' } },
                node: {
                  colors: ['#4F46E5'],
                  nodePadding: 16,
                  label: { fontName: 'Inter', color: '#111827', fontSize: 13 },
                },
              },
            } as any
          }
        />
      )}
    </div>
  )
}

export default SankeyChart
