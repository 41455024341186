import type { PerformanceReadingTypeEnum } from '../../features/PerformanceMonitoringAdminPanelPage/types'

export interface DateRange {
  start: Date | null
  end: Date | null
  isSelecting: boolean
}

interface PluginPing {
  company_id: string | null
  team_id: string | null
  grouping_timestamp: string
  computer_count: number
  plugin_version: null
}

export interface PluginPingsParams {
  team_id?: string
  start_timestamp: string
  end_timestamp: string
  granularity: GranularityEnum
  organization_level?: OrganizationLevelEnum
}

export type PluginPingsPayload = PluginPing[]

export interface IdentifierCount {
  company_id: string | null
  team_id: string | null
  grouping_timestamp: string
  identifier_name: string
  unique_identifier_count: number
  count: number
}

export interface IdentifierCountParams {
  team_id?: string
  start_timestamp: string
  end_timestamp: string
  organization_level?: OrganizationLevelEnum
}

export type IdentifierCountPayload = IdentifierCount[]

export interface PerformanceReadingsParams {
  start_timestamp: string
  end_timestamp: string
  reading_type: PerformanceReadingTypeEnum
}

export interface PerformanceReadingsPercentile {
  company_id: string | null
  team_id: string | null
  grouping_timestamp: string
  plugin_version: null
  reading_type: PerformanceReadingTypeEnum
  reading: string
  percentile: number
  value: number
}

export interface PerformanceReadingsPercentilesParams extends PerformanceReadingsParams {
  granularity?: GranularityEnum
  percentiles?: number[]
}

export type PerformanceReadingsPercentilesPayload = PerformanceReadingsPercentile[]

export enum GranularityEnum {
  DAY = 'day',
  HOUR = 'hour',
}

export enum OrganizationLevelEnum {
  COMPANY = 'company',
  TEAM = 'team',
}
