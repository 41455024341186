import { forwardRef } from 'react'

import { Text } from '../../designs'
import type { LegendData } from './types'

interface Props {
  data: LegendData[]
}

const ChartLegend = forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
  return (
    <div ref={ref} className='ml-9 flex flex-wrap gap-4 py-1'>
      {data.map((d) => (
        <div className='flex items-center' key={d.label}>
          <div className='mr-1 h-3 w-6 rounded-sm' style={{ background: d.color }} />
          <Text variant='p' size='s' color='lightGray'>
            {d.label}
          </Text>
        </div>
      ))}
    </div>
  )
})

ChartLegend.displayName = 'ChartLegend'
export default ChartLegend
