import { useMemo, useState } from 'react'

import { Tabs, Text } from '../../../common/designs'
import UserActivitiesActivity from './UserActivitiesActivity'
import UserActivitiesCopyPastes from './UserActivitiesCopyPastes'
import UserActivitiesDataFlows from './UserActivitiesDataFlows'
import UserActivitiesFieldChange from './UserActivitiesFieldChange'
import { UserActivityTabEnum } from './types'

const TAB_OPTIONS = [
  {
    label: 'Data Flows',
    value: UserActivityTabEnum.DATA_FLOWS,
  },
  {
    label: 'Copy-Paste Analysis',
    value: UserActivityTabEnum.COPY_PASTE_ANALYSIS,
  },
  {
    label: 'Activity Analysis',
    value: UserActivityTabEnum.ACTIVITY_ANALYSIS,
  },
  {
    label: 'Field Change Analysis',
    value: UserActivityTabEnum.FIELD_CHANGE_ANALYSIS,
  },
]

const UserActivities = () => {
  const [activeTab, setActiveTab] = useState<UserActivityTabEnum>(UserActivityTabEnum.DATA_FLOWS)

  const activeView = useMemo(() => {
    switch (activeTab) {
      case UserActivityTabEnum.DATA_FLOWS:
        return <UserActivitiesDataFlows />

      case UserActivityTabEnum.COPY_PASTE_ANALYSIS:
        return <UserActivitiesCopyPastes />

      case UserActivityTabEnum.ACTIVITY_ANALYSIS:
        return <UserActivitiesActivity />

      case UserActivityTabEnum.FIELD_CHANGE_ANALYSIS:
        return <UserActivitiesFieldChange />

      default:
        return <UserActivitiesDataFlows />
    }
  }, [activeTab])

  return (
    <div className='size-full space-y-3 px-1 lg:px-2 xl:px-3'>
      <div className='flex items-start justify-between'>
        <Text size='2xl' weight={700}>
          User Activities
        </Text>
      </div>

      <Text>
        Gain an in-depth understanding of the manual activities performed in the process. You can
        analyze manual procedures, like copy-paste workflows, user interactions such as button
        clicks, and field changes.
      </Text>

      <Tabs
        options={TAB_OPTIONS}
        value={activeTab}
        onChange={setActiveTab as React.Dispatch<React.SetStateAction<string>>}
      />

      {activeView}
    </div>
  )
}

export default UserActivities
