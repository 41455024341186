import { useOutletContext } from 'react-router-dom'

import { useEnhancedNavigate } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import { MainPathEnum } from '../../../common/types/routing'
import { compressAndEncodeJSON } from '../../../common/utils/jsonCompression'
import TimeLayout from '../TimeLayout'
import TimeStats from './TimeStats'

const Time = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const { tagFilters } = useOutletContext<{
    tagFilters: Tag[]
    appName: string
  }>()

  const onNavigate = (tags: any) => {
    navigate(`/${MainPathEnum.BUSINESS_APPS}/window/${compressAndEncodeJSON(tags)}`)
  }

  return (
    <div className='flex flex-col gap-3'>
      <TimeStats />

      <TimeLayout
        queryOptionsApplications={{
          tagsOfInterestSource: 'window',
          variables: { tag_filters: tagFilters },
        }}
        queryOptionsActivities={{ tagsOfInterestSource: 'window', tagFilters }}
        onNavigate={onNavigate}
      />
    </div>
  )
}

export default Time
