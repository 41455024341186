import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useLazyDownloadTrainingDataQuery } from '../../../app/apiSlice'
import { Button, Toggle } from '../../designs'
import { downloadFile } from '../../utils/utils'
import { dataCollectionGranularityFormSchema } from '../DataCollectionForms/formSchemas'
import { ContentDisplay } from '../displays'
import { DataCollectionGranularityForm } from './types'

interface Props {
  formData: DataCollectionGranularityForm
  onUpdateGranularityData: (formData: DataCollectionGranularityForm) => Promise<void>
  appName: string
}

const DataCollectionGranularity = ({ formData, onUpdateGranularityData, appName }: Props) => {
  const { control, handleSubmit, reset } = useForm<DataCollectionGranularityForm>({
    resolver: yupResolver(
      dataCollectionGranularityFormSchema,
    ) as Resolver<DataCollectionGranularityForm>,
    defaultValues: formData,
  })
  const [downloadTrainingData, { isLoading: isTrainingDataLoading }] =
    useLazyDownloadTrainingDataQuery()

  useEffect(() => {
    reset(formData)
  }, [reset, formData])

  const onDownloadTrainingData = async () => {
    toast.info(`Started application data loading for ${appName}`)
    const trainingCsv = await downloadTrainingData(appName).unwrap()
    downloadFile(window.URL.createObjectURL(new Blob([trainingCsv])), `${appName}.csv`)
  }

  return (
    <ContentDisplay className='p-4'>
      <form
        onSubmit={handleSubmit(onUpdateGranularityData)}
        className='space-y-2'
        id='config-wizard-form'
      >
        <Controller
          name='isUrlCaptured'
          control={control}
          render={({ field }) => <Toggle size='xs' label='Capture full URLs' {...field} />}
        />

        <Controller
          name='isTitleCaptured'
          control={control}
          render={({ field }) => <Toggle size='xs' label='Capture full titles' {...field} />}
        />

        <Controller
          name='isTypingCaptured'
          control={control}
          render={({ field }) => <Toggle size='xs' label='Record typed text' {...field} />}
        />

        <Controller
          name='isClipboardContentCaptured'
          control={control}
          render={({ field }) => <Toggle size='xs' label='Record clipboard content' {...field} />}
        />

        <Controller
          name='isKeyboardShortcutsCaptured'
          control={control}
          render={({ field }) => (
            <Toggle size='xs' label='Record used keyboard shortcuts' {...field} />
          )}
        />
        <Controller
          name='isInteractedElementsCaptured'
          control={control}
          render={({ field }) => (
            <Toggle size='xs' label='Record copy-paste field names' {...field} />
          )}
        />
        <div className='flex gap-5'>
          <Button type='submit'>Save</Button>
          <Button
            variant='white'
            iconStart={<ArrowDownTrayIcon />}
            loading={isTrainingDataLoading}
            onClick={() => onDownloadTrainingData()}
          >
            Download Training Data csv
          </Button>
        </div>
      </form>
    </ContentDisplay>
  )
}

export default DataCollectionGranularity
