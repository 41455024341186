export enum ActiveTabEnum {
  ANALYSIS = 0,
  TEAMS = 1,
  NAVIGATIONS = 2,
}

export enum ActiveLayerEnum {
  FIRST_LAYER = 0,
  SECOND_LAYER = 1,
  THIRD_LAYER = 2,
}

export enum ActiveTimeTabEnum {
  APPLICATIONS = 'Applications',
  ACTIVITIES = 'Activities',
}
