import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useUploadCompanyConfigMutation, useUploadTeamConfigMutation } from '../../app/apiSlice'
import { JSON_FILE_TYPE } from '../../app/constants'
import { ActionModal, Button, FileSelector, Modal } from '../../common/designs'
import type { HTTPException } from '../../common/types/api'
import type { JsonObject, Team } from '../../common/types/common'
import { readUploadedFileAsText, validateFile } from '../../common/utils/fileUtils'
import useManageServerTestCasesAndConfigs from '../ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import type { RuleIn } from './types'

interface Props {
  isOpen: boolean
  onClose: () => void
  team: Team | undefined
}

const ConfigFileUploadModal = ({ isOpen, onClose, team }: Props) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false)
  const [fileInputError, setFileInputError] = useState<string | undefined>(undefined)
  const [fileSelectorKey, setFileSelectorKey] = useState<number>(0)

  const { setRules } = useManageServerTestCasesAndConfigs()
  const [uploadCompanyConfig, uploadCompanyConfigRes] = useUploadCompanyConfigMutation()
  const [uploadTeamConfig, uploadTeamConfigRes] = useUploadTeamConfigMutation()

  useEffect(() => {
    setUploadedFile(null)
  }, [setUploadedFile])

  const onFileChange = (files: File[]): number | string | void => {
    const { file, error } = validateFile(files[0], JSON_FILE_TYPE)
    error ? toast.warning(error) : setUploadedFile(file)
  }

  const onUpload = async () => {
    if (!uploadedFile) return
    setFileInputError(undefined)

    const fileContent = (await readUploadedFileAsText(uploadedFile)) as JsonObject
    const rules: RuleIn[] = fileContent.applications_rules ?? []

    let res
    if (team) {
      res = await uploadTeamConfig({ invitationToken: team.invitation_token, body: fileContent })
    } else {
      await setRules(rules)
      res = await uploadCompanyConfig()
    }

    if ('error' in res) {
      const errorData = (res as HTTPException).error.data
      setFileInputError(errorData.detail ?? errorData.message)
      // Changes the file selector's key, which resets the active file.
      // This is a bit hacky, but it seems to be the only viable option.
      setFileSelectorKey((curVal) => curVal + 1)
    } else {
      toast.success('Config uploaded successfully')
      setIsNotificationModalOpen(true)
      onClose()
    }
  }

  return (
    <>
      <Modal
        label={`Upload config to the ${team?.team_name ?? 'Company'}`}
        open={isOpen}
        onClose={onClose}
      >
        <div className='space-y-4'>
          <FileSelector
            onChange={onFileChange}
            accept={JSON_FILE_TYPE}
            error={fileInputError}
            key={fileSelectorKey}
          />

          <div className='flex justify-end'>
            <Button
              iconStart={<ArrowDownTrayIcon />}
              disabled={
                !uploadedFile || uploadCompanyConfigRes.isLoading || uploadTeamConfigRes.isLoading
              }
              onClick={onUpload}
              loading={uploadCompanyConfigRes.isLoading || uploadTeamConfigRes.isLoading}
            >
              Upload
            </Button>
          </div>
        </div>
      </Modal>

      <ActionModal
        label='Configuration Upload Successful'
        actionLabel='Confirm'
        open={isNotificationModalOpen}
        onAction={() => {
          setIsNotificationModalOpen(false)
          onClose()
        }}
        description='The configuration changes will take effect shortly.'
      />
    </>
  )
}

export default ConfigFileUploadModal
