import { applicationCategories } from '../common/types/dataCollectionsConfigs'

export type BasicColorIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export const BG_GRAY_300_HEX = 'bg-gray-300'
export const BG_RED_300_HEX = 'bg-red-300'
export const BG_YELLOW_300_HEX = 'bg-yellow-300'
export const BG_ORANGE_300_HEX = 'bg-orange-300'
export const BG_GREEN_300_HEX = 'bg-green-300'
export const BG_LIME_300_HEX = 'bg-lime-300'
export const BG_BLUE_300_HEX = 'bg-blue-300'
export const BG_CYAN_300_HEX = 'bg-cyan-300'
export const BG_INDIGO_300_HEX = 'bg-indigo-300'
export const BG_PURPLE_300_HEX = 'bg-purple-300'
export const BG_PINK_300_HEX = 'bg-pink-300'

export const GRAY_300_HEX = '#D1D5DB'
const RED_300_HEX = '#FCA5A5'
const YELLOW_300_HEX = '#FCD34D'
export const ORANGE_300_HEX = '#FDBA74'
const GREEN_300_HEX = '#6EE7B7'
const LIME_300_HEX = '#BEF264'
const BLUE_300_HEX = '#93C5FD'
const CYAN_300_HEX = '#67E8F9'
const INDIGO_300_HEX = '#A5B4FC'
const INDIGO_600_HEX = '#5850EC'
const PURPLE_300_HEX = '#C4B5FD'
const PINK_300_HEX = '#F9A8D4'

export const FONT_FAMILY = 'Inter'
export const WHITE = '#FFFFFF'
export const PRIMARY_300_HEX = INDIGO_300_HEX
export const PRIMARY_600_HEX = INDIGO_600_HEX
export const BG_PRIMARY_600_HEX = 'bg-indigo-600'

export const CHART_PRIMARY_HSLA = 'hsla(243, 80%, 62%, 1)'
export const CHART_NEUTRAL_HSLA = 'hsla(220, 13%, 91%, 1)'
export const CHART_NEUTRAL_HEX = '#E5E7EB'

export const ANNOTATION_PRIMARY_HSLA = 'hsla(239, 84%, 67%, 1)'

export const basicColorMap: { [key in BasicColorIndex]: string } = {
  0: BG_GRAY_300_HEX,
  1: BG_RED_300_HEX,
  2: BG_YELLOW_300_HEX,
  3: BG_ORANGE_300_HEX,
  4: BG_GREEN_300_HEX,
  5: BG_LIME_300_HEX,
  6: BG_BLUE_300_HEX,
  7: BG_CYAN_300_HEX,
  8: BG_INDIGO_300_HEX,
  9: BG_PURPLE_300_HEX,
  10: BG_PINK_300_HEX,
}

export const basicBgColorMap: { [key in BasicColorIndex]: string } = {
  0: BG_GRAY_300_HEX,
  1: BG_RED_300_HEX,
  2: BG_YELLOW_300_HEX,
  3: BG_ORANGE_300_HEX,
  4: BG_GREEN_300_HEX,
  5: BG_LIME_300_HEX,
  6: BG_BLUE_300_HEX,
  7: BG_CYAN_300_HEX,
  8: BG_INDIGO_300_HEX,
  9: BG_PURPLE_300_HEX,
  10: BG_PINK_300_HEX,
}

export const teamColorMap: { [key in number]: string } = {
  0: GRAY_300_HEX,
  1: RED_300_HEX,
  2: YELLOW_300_HEX,
  3: ORANGE_300_HEX,
  4: GREEN_300_HEX,
  5: LIME_300_HEX,
  6: BLUE_300_HEX,
  7: CYAN_300_HEX,
  8: INDIGO_300_HEX,
  9: PURPLE_300_HEX,
  10: PINK_300_HEX,
}

export const teamBgColorMap: { [key in number]: string } = {
  0: BG_GRAY_300_HEX,
  1: BG_RED_300_HEX,
  2: BG_YELLOW_300_HEX,
  3: BG_ORANGE_300_HEX,
  4: BG_GREEN_300_HEX,
  5: BG_LIME_300_HEX,
  6: BG_BLUE_300_HEX,
  7: BG_CYAN_300_HEX,
  8: BG_INDIGO_300_HEX,
  9: BG_PURPLE_300_HEX,
  10: BG_PINK_300_HEX,
}

export const appCategoryColorMap: { [key in string]: string } = {
  [applicationCategories.document]: ORANGE_300_HEX,
  [applicationCategories.communication]: PURPLE_300_HEX,
  [applicationCategories.coreBusinessSystem]: PRIMARY_600_HEX,
  [applicationCategories.other]: GRAY_300_HEX,
}

export const appCategoryBgColorMap: { [key in string]: string } = {
  [applicationCategories.coreBusinessSystem]: BG_PRIMARY_600_HEX,
  [applicationCategories.communication]: BG_PURPLE_300_HEX,
  [applicationCategories.document]: BG_ORANGE_300_HEX,
  [applicationCategories.other]: BG_GRAY_300_HEX,
}

export const favoriteVisColorArr = [
  'hsla(246, 75%, 51%, 1)', // indigo-600
  'hsla(145, 92%, 35%, 1)', // emerald-600
  'hsla(35, 94%, 43%, 1)', // amber-600
  'hsla(283, 68%, 45%, 1)', // purple-600
  'hsla(348, 78%, 46%, 1)', // rose-600
]

export const trendColorArr = [
  '#6366f1', // indigo-500
  '#06b6d4', // cyan-500
  '#14b8a6', // teal-500
  '#22c55e', // green-500
  '#eab308', // yellow-500
  '#f97316', // orange-500
]

export const laneColorMap = {
  default: 'hsla(202, 83%, 21%, 1)',
  lead: 'hsla(40, 85%, 69%, 1)',
  validation: 'hsla(315, 41%, 36%, 1)',
  specification: 'hsla(198, 83%, 36%, 1)',
  implementation: 'hsla(198, 74%, 60%, 1)',
  done: 'hsla(155, 45%, 65%, 1)',
  neutral: 'hsla(204, 22%, 90%, 1)',
}
