import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useGetTransactionBucketGroupQuery,
  useGetTransactionGroupQuery,
  useLazyGetSearchedTransactionIdQuery,
} from '../../../../app/apiSlice'
import { ContentDisplay } from '../../../../common/components'
import {
  IconButton,
  InputField,
  Select,
  SelectOption,
  Toggle,
  Tooltip,
} from '../../../../common/designs'
import {
  useDashboard,
  useEnhancedNavigate,
  useLocalStorage,
  useQueryFilters,
} from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import { GroupByFieldTypeEnum, MetricNamesEnum, MetricTypeEnum } from '../../types'
import type { AnalysisChartConfigurationData, TransactionGroupResult } from '../../types'
import AnalysisChart from './AnalysisChart'
import AnalysisFilterPopupModal from './AnalysisFilterPopupModal'
import type { ModalContent } from './AnalysisFilterPopupModal'
import TransactionLog from './TransactionLog'
import { getFilterValueForChartClick, setUpChartDataAndMetric } from './chartDataHelpers'
import { metricNameExtraDataMapper, metricNameExtraDataMapperPlatformViewer } from './constants'

export const TransactionMetricsAnalysis = () => {
  const { processId, processFilters: filters } = useQueryFilters()
  const storage = useLocalStorage()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { teams, user } = useDashboard()

  const [chartConfiguration, setChartConfiguration] =
    useState<AnalysisChartConfigurationData | null>(null)
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const [selectedMetric, setSelectedMetric] = useState<MetricNamesEnum>(
    user.isPlatformViewer ? MetricNamesEnum.AVG_THROUGHPUT_TIME : MetricNamesEnum.ACTIVE_WORK,
  )
  const [searchString, setSearchString] = useState<string>('')
  const [shouldUseDistribution, setShouldUseDistribution] = useState<boolean>(false)
  const [metricOptions, setMetricOptions] = useState<SelectOption[]>([])

  const [searchTransaction] = useLazyGetSearchedTransactionIdQuery()
  const { data: distributionRequestData } = useGetTransactionBucketGroupQuery(
    {
      processId,
      filters,
      metric: selectedMetric as MetricNamesEnum.THROUGHPUT_TIME | MetricNamesEnum.ACTIVE_WORK,
    },
    { skip: !selectedMetric || !shouldUseDistribution },
  )
  const { data: transactionRequestData } = useGetTransactionGroupQuery(
    {
      processId,
      filters,
      group_by: [{ field_type: GroupByFieldTypeEnum.TRANSACTION_ID as GroupByFieldTypeEnum }],
      metrics: [selectedMetric],
    },
    { skip: !selectedMetric || shouldUseDistribution },
  )

  useEffect(() => {
    setChartData(transactionRequestData)
  }, [transactionRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDistributionChartData(distributionRequestData)
  }, [distributionRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  const metricOptionsDataMapper = user.isPlatformViewer
    ? metricNameExtraDataMapperPlatformViewer
    : metricNameExtraDataMapper

  const metricOptionsNormal = [
    ...Object.entries(metricOptionsDataMapper)
      .filter(([_, { metric_type }]) => metric_type !== MetricTypeEnum.RELATED_SYSTEM_SPECIFIC)
      .map(([value, { metric_name }]) => ({
        label: metric_name,
        value,
      })),
  ]
  const metricOptionsDistribution = [
    { label: 'Case duration', value: MetricNamesEnum.THROUGHPUT_TIME },
    { label: 'Case active work', value: MetricNamesEnum.ACTIVE_WORK },
  ]

  useEffect(() => {
    if (shouldUseDistribution) {
      if (!metricOptionsDistribution.map((o) => o.value).includes(selectedMetric)) {
        setSelectedMetric(MetricNamesEnum.THROUGHPUT_TIME)
      }
      setMetricOptions(metricOptionsDistribution)
    } else {
      setMetricOptions(metricOptionsNormal)
    }
  }, [shouldUseDistribution]) // eslint-disable-line react-hooks/exhaustive-deps

  const setChartData = (data: TransactionGroupResult | undefined) => {
    if (!data || !selectedMetric) return

    const { chartData, unit } = setUpChartDataAndMetric(
      data[selectedMetric],
      selectedMetric,
      GroupByFieldTypeEnum.TRANSACTION_ID,
      teams,
    )

    setChartConfiguration({
      label: 'Case analysis',
      chartData,
      unit,
      onBarClick: async (label, i) => {
        const chartDataPoint = chartData[i]
        const filterValue = getFilterValueForChartClick(
          label,
          GroupByFieldTypeEnum.TRANSACTION_ID,
          {},
          {},
        )
        setModalContent({
          label: chartDataPoint.label,
          value: chartDataPoint.value,
          unit,
          filterValue,
        })
      },
    })
  }

  const onFilter = async () => {
    if (!modalContent) return

    storage.updateNestedItem(
      StorageNameEnum.PROCESS_FILTERS,
      ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
      { transaction_id_filters: [modalContent.filterValue] },
    )

    onCloseModal()
  }

  const onFilterAndNavigate = () => {
    onFilter()
    navigate('../../variants')
    onCloseModal()
  }

  const onCloseModal = () => setModalContent(null)

  const setDistributionChartData = (data: TransactionGroupResult | undefined) => {
    if (!data || !selectedMetric) return

    const unit = selectedMetric === MetricNamesEnum.THROUGHPUT_TIME ? 'h' : 'min'
    const divider = selectedMetric === MetricNamesEnum.THROUGHPUT_TIME ? 3600 : 60
    const chartData = data[selectedMetric].map(({ label, value }) => ({
      label: label as string,
      value: value / divider,
    }))

    setChartConfiguration({
      label: 'Transaction analysis',
      chartData,
      unit,
    })
  }

  const onSearch = async () => {
    if (!searchString) {
      return storage.removeDeeplyNestedItem(
        StorageNameEnum.PROCESS_FILTERS,
        ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
        'transaction_id_filters',
      )
    }

    const transactionId = await searchTransaction({
      processId,
      filters: {},
      transaction_search_string: searchString,
    }).unwrap()

    if (transactionId) {
      return storage.updateNestedItem(
        StorageNameEnum.PROCESS_FILTERS,
        ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
        { transaction_id_filters: [transactionId] },
      )
    } else {
      return toast.warning('No results found')
    }
  }

  return (
    <>
      <div className='flex flex-col gap-2'>
        <ContentDisplay className='px-5 py-4'>
          <div className='mb-3 flex items-end gap-2'>
            <InputField
              label={`Search for case`}
              iconStart={<MagnifyingGlassIcon />}
              className='w-full'
              size='s'
              disabled={!chartConfiguration}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value as string)}
            />
            <IconButton size='s' icon={<MagnifyingGlassIcon />} onClick={onSearch} />
          </div>
          <div className='flex gap-6'>
            <div className=' w-96'>
              <Select
                options={metricOptions}
                value={selectedMetric}
                onChange={(val: string) => setSelectedMetric(val as MetricNamesEnum)}
                label='Metric'
              />
            </div>
            <div className='mt-3 flex items-center gap-2'>
              <Toggle
                value={shouldUseDistribution}
                onChange={setShouldUseDistribution}
                size='xs'
                label='Show full distribution'
              />
              <Tooltip
                size='2xl'
                text='By default, the analysis view is limited to displaying a maximum of 1000 results. Showing full distribution divides cases into 100 equal-sized buckets, and presents the average value for each bucket.'
                variant='info'
              />
            </div>
          </div>
        </ContentDisplay>
        {chartConfiguration ? (
          <AnalysisChart configurations={chartConfiguration} isLoading={false} />
        ) : null}

        <TransactionLog />
      </div>

      <AnalysisFilterPopupModal
        content={modalContent}
        onClose={onCloseModal}
        onFilter={onFilter}
        onFilterAndNavigate={onFilterAndNavigate}
      />
    </>
  )
}

export default TransactionMetricsAnalysis
