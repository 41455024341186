import wrap from 'word-wrap'

import { VisitActivityEnum } from '../../../common/types/visitActivities'

const textWidth = 80

export enum VisitActivityDescriptionEnum {
  MANUAL_TEXT_ENTRY = 'Quick data entry',
  TEXT_CREATION = 'Text creation',
  DATA_HUB = 'Data moving',
  INTERACTIVE_WORK = 'Interactive work',
  PEEKING_DATA = 'Short visits',
  BROWSING_INFO = 'Viewing',
}

export const activityNames = {
  [VisitActivityEnum.MANUAL_TEXT_ENTRY]: VisitActivityDescriptionEnum.MANUAL_TEXT_ENTRY,
  [VisitActivityEnum.TEXT_CREATION]: VisitActivityDescriptionEnum.TEXT_CREATION,
  [VisitActivityEnum.DATA_HUB]: VisitActivityDescriptionEnum.DATA_HUB,
  [VisitActivityEnum.INTERACTIVE_WORK]: VisitActivityDescriptionEnum.INTERACTIVE_WORK,
  [VisitActivityEnum.PEEKING_DATA]: VisitActivityDescriptionEnum.PEEKING_DATA,
  [VisitActivityEnum.BROWSING_INFO]: VisitActivityDescriptionEnum.BROWSING_INFO,
}

export const activityDescriptions = {
  [VisitActivityDescriptionEnum.MANUAL_TEXT_ENTRY]: wrap(
    'Quick data entries are short text typing activities under 40 seconds. ' +
      'Tip: Check if this is a master data problem or consider automation and auto-populating data.',
    { width: textWidth },
  ).split('\n'),
  [VisitActivityDescriptionEnum.TEXT_CREATION]: wrap(
    'Text creation is a more extended text production session that takes over 40 seconds. ' +
      'Tip: Consider using text/email templates or new software boosting the typing process, e.g., Grammarly or ChatGPT.',
    { width: textWidth },
  ).split('\n'),
  [VisitActivityDescriptionEnum.DATA_HUB]: wrap(
    'Data moving is copying or pasting between applications using a clipboard. ' +
      'Tip: Consider looking into data integration or API sync opportunities.',
    { width: textWidth },
  ).split('\n'),
  [VisitActivityDescriptionEnum.INTERACTIVE_WORK]: wrap(
    'Interactive work requires user to perform mouse clicks frequently.',
    { width: textWidth },
  ).split('\n'),
  [VisitActivityDescriptionEnum.PEEKING_DATA]: wrap(
    'Short visits are under 40-second non-typing visits on applications or documents. ' +
      'These activities are typically part of manual data transfers, checking instructions, or refreshing for updates. ' +
      'Tip: Consider data flow automation or implement a low code application to make finding data more straightforward. ' +
      'Data peeking activities could be improved with push or email notifications.',
    { width: textWidth },
  ).split('\n'),
  [VisitActivityDescriptionEnum.BROWSING_INFO]: wrap(
    'A viewing activity is browsing and interacting with an application for over 40 seconds. ' +
      'Often, viewing is part of activities containing lots of reading or participating in Meet and Teams calls. ' +
      'Tip: Consider making information available more quickly or in a better structure. ' +
      'Tools like ChatGPT can also help with structuring and summarizing the content.',
    { width: textWidth },
  ).split('\n'),
}

export const getActivityName = (value: string | VisitActivityDescriptionEnum) => {
  return value in activityNames ? activityNames[value as keyof typeof activityNames] : value
}
