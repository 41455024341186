import { useMemo, useState } from 'react'
import type { DataFlowEdge, DataFlowNode } from 'react-graph-vis'

import { Badge, Link, Table, Tabs, Text } from '../../common/designs'
import { useCostUtils, useEnhancedNavigate } from '../../common/hooks'
import type { Tag } from '../../common/types/common'
import type { ApplicationCategory } from '../../common/types/dataCollectionsConfigs'
import { MainPathEnum } from '../../common/types/routing'
import { compressAndEncodeJSON } from '../../common/utils/jsonCompression'
import { DetailLevelEnum, NetworkEntityEnum } from '../Network/types'
import { badgeVariantMapper } from '../Network/utils'
import DetailLevelSelector from './DetailLevelSelector'
import VisualizationModeSelector from './VisualizationModeSelector'

interface Props {
  nodes: DataFlowNode[]
  edges: DataFlowEdge[]
  detailLevel: DetailLevelEnum
}

const TableVis = ({ nodes, edges, detailLevel }: Props) => {
  const { generateAnnualizedCopyPasteCost } = useCostUtils()

  const [entity, setEntity] = useState<NetworkEntityEnum>(NetworkEntityEnum.NODE)

  const headVariants = {
    node: [
      `${detailLevel} name`,
      'Category',
      `Copies from the ${detailLevel}`,
      `Pastes to the ${detailLevel}`,
      `Copy-pastes within the ${detailLevel}`,
      'Annualized cost',
    ],
    edge: ['Source', 'Target', 'Copies from source to target', 'Annualized cost'],
  }

  const tableData = useMemo(() => {
    if (entity === NetworkEntityEnum.NODE) {
      return Object.values(nodes).map((n) => {
        const selfLoopEdgeId = `${n.id}..${n.id}`
        const selfLoopEdge = edges.find(({ id }) => id === selfLoopEdgeId)
        const selfLoopCopyPasteSum = selfLoopEdge?.value ?? 0
        const copiesSum = n.properties.copyCount - selfLoopCopyPasteSum
        const pastesSum = n.properties.pasteCount - selfLoopCopyPasteSum
        const combinedSum = copiesSum + pastesSum + selfLoopCopyPasteSum * 2

        return [
          <LabelLink label={n.label} tags={n.properties.tags} key={n.label} />,
          <Badge variant={badgeVariantMapper[n.group as ApplicationCategory]} key='label'>
            {n.group}
          </Badge>,
          copiesSum,
          pastesSum,
          selfLoopCopyPasteSum,
          generateAnnualizedCopyPasteCost(combinedSum),
        ]
      })
    }

    if (entity === NetworkEntityEnum.EDGE) {
      return Object.values(edges).map((e) => {
        const source = { label: e.properties.sourceNodeName, tags: e.properties.sourceNodeTags }
        const target = { label: e.properties.targetNodeName, tags: e.properties.targetNodeTags }

        return [
          <LabelLink label={source.label} tags={source.tags} key={source.label} />,
          <LabelLink label={target.label} tags={target.tags} key={target.label} />,
          e.value,
          generateAnnualizedCopyPasteCost(e.value),
        ]
      })
    }

    return []
  }, [nodes, edges, entity, generateAnnualizedCopyPasteCost])

  return (
    <Table
      label='Data Flows Table'
      size='s'
      head={headVariants[entity]}
      body={tableData}
      action={
        <div className='flex gap-4'>
          <Tabs
            size='xs'
            variant='secondary'
            value={entity}
            options={[
              { label: 'Points', value: NetworkEntityEnum.NODE },
              { label: 'Links', value: NetworkEntityEnum.EDGE },
            ]}
            onChange={setEntity as React.Dispatch<React.SetStateAction<string>>}
          />

          <DetailLevelSelector />
          <VisualizationModeSelector />
        </div>
      }
    />
  )
}

const LabelLink = ({ label, tags }: { label: string | undefined; tags: Tag[] }) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  if (!tags.length) return <Text>{label}</Text>

  const onNavigate = (tags: Tag[]) => {
    navigate(`/${MainPathEnum.BUSINESS_APPS}/${compressAndEncodeJSON(tags)}`)
  }

  return <Link onClick={() => onNavigate(tags)}>{label}</Link>
}

export default TableVis
