import { Switch } from '@headlessui/react'
import clsx from 'clsx'

type Size = 'xs' | 's' | 'm' | 'l'
interface Props {
  value: boolean
  onChange: (value: boolean) => void

  size?: Size
  label?: string
  className?: string
  disabled?: boolean
}

const switchBgSizeStyles: { [key in Size]: string } = {
  xs: 'h-5 w-10',
  s: 'h-6 w-11',
  m: 'h-7 w-12',
  l: 'h-8 w-13',
}

const switchDotSizeStyles: { [key in Size]: string } = {
  xs: 'size-4',
  s: 'size-5',
  m: 'size-6',
  l: 'size-7',
}

const Toggle = ({ value, onChange, size = 'm', label, className, disabled }: Props) => {
  return (
    <Switch.Group as='div' className={clsx('flex items-center', className)}>
      <Switch
        disabled={Boolean(disabled)}
        checked={value}
        onChange={onChange}
        className={clsx(
          'relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
          value
            ? 'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-200'
            : 'bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100',
          switchBgSizeStyles[size],
          disabled && 'disabled:cursor-not-allowed',
        )}
      >
        <span
          aria-hidden='true'
          className={clsx(
            'pointer-events-none inline-block rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            value ? 'translate-x-5' : 'translate-x-0',
            switchDotSizeStyles[size],
          )}
        />
      </Switch>

      {label && (
        <Switch.Label as='span' className='ml-3  whitespace-nowrap'>
          <span className='text-sm font-medium text-gray-700'>{label}</span>
        </Switch.Label>
      )}
    </Switch.Group>
  )
}

export default Toggle
