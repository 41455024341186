import DOMPurify from 'dompurify'
import { isObject } from 'lodash'
import { useCallback } from 'react'

import { useLocalStorageContext } from '../../app/LocalStorageContext'
import { StorageNameEnum, storageVersionHistory } from '../types/storage'
import { DashboardInitialization } from '../utils/UtilityWrapper/types'
import { hasOwnProperty } from '../utils/utils'

export type Key = StorageNameEnum
export type Item = object

const useLocalStorage = () => {
  const {
    processFilters,
    setProcessFilters,
    handleProcessFilterChange,
    handleIsDashboardInitializedChange,
  } = useLocalStorageContext()

  const getItem = useCallback(<T = any>(key: Key): T | null => {
    // Core logic.
    const item = localStorage.getItem(key)
    if (item === null) return null

    // Sanitizing the data is necessary for security purposes.
    const sanitizedItem = DOMPurify.sanitize(item)
    const { content } = JSON.parse(sanitizedItem)
    return isObject(content) ? content : JSON.parse(content)
  }, [])

  const setItem = useCallback(
    async (key: Key, item: Item) => {
      // Core logic.
      await localStorage.setItem(
        key,
        JSON.stringify({
          version: storageVersionHistory[key],
          content: item,
        }),
      )

      // Additional logic.
      handleIsDashboardInitializedChange(key, item as DashboardInitialization)
      handleProcessFilterChange(key, item)
    },
    [handleIsDashboardInitializedChange, handleProcessFilterChange],
  )

  const updateItem = useCallback(
    async (key: Key, item: Item) => {
      // Core logic.
      const existingItem = getItem(key)
      const updatedItem = existingItem ? { ...existingItem, ...item } : item

      await setItem(key, updatedItem)

      // Additional logic.
      handleProcessFilterChange(key, updatedItem)
    },
    [handleProcessFilterChange, setItem, getItem],
  )

  const updateNestedItem = useCallback(
    async (key: Key, nestedKey: string, item: Item) => {
      // Core logic.
      const existingData = await getItem(key)
      const existingNestedData = existingData?.[nestedKey] || {}
      const updatedNestedData = { ...existingNestedData, ...item }
      const updatedItem = { ...existingData, [nestedKey]: updatedNestedData }

      await setItem(key, updatedItem)

      // Additional logic.
      handleProcessFilterChange(key, updatedItem)
    },
    [getItem, setItem, handleProcessFilterChange],
  )

  const removeItem = useCallback(
    async (key: Key) => {
      // Core logic.
      await localStorage.removeItem(key)

      // Additional logic.
      handleProcessFilterChange(key, {})
    },
    [handleProcessFilterChange],
  )

  const removeNestedItem = useCallback(
    async (key: Key, nestedKey: string) => {
      // Core logic.
      const item = getItem(key)

      if (item && hasOwnProperty(item, nestedKey)) {
        delete item[nestedKey]
        await setItem(key, item)

        // Additional logic.
        handleProcessFilterChange(key, item)
      }
    },
    [getItem, setItem, handleProcessFilterChange],
  )

  const removeDeeplyNestedItem = useCallback(
    async (key: Key, parentKey: string, nestedKey: string) => {
      // Core logic.
      const item = await getItem(key)

      if (item && hasOwnProperty(item, parentKey) && hasOwnProperty(item, nestedKey)) {
        delete item[parentKey][nestedKey]
        await setItem(key, item)

        // Additional logic.
        handleProcessFilterChange(key, item)
      }
    },
    [getItem, setItem, handleProcessFilterChange],
  )

  const clearItems = useCallback(async () => {
    // Core logic.
    await localStorage.clear()

    // Additional logic.
    setProcessFilters({})
  }, [setProcessFilters])

  return {
    getItem,
    setItem,
    updateItem,
    updateNestedItem,
    removeItem,
    removeNestedItem,
    removeDeeplyNestedItem,
    clearItems,
    processFilters,
  }
}

export default useLocalStorage

export type UseLocalStorageType = ReturnType<typeof useLocalStorage>
