import { Bubble } from 'react-chartjs-2'

import { useAppDispatch, useAppSelector, useCostUtils } from '../../../../../common/hooks'
import { selectRoadmapEntitiesAsArray, setActiveCaseId } from '../../../casesSlice'
import type { BubbleChartData } from '../../../types'

interface Props {
  chartData: BubbleChartData | undefined
}

const BubbleChart = ({ chartData }: Props) => {
  const dispatch = useAppDispatch()
  const { formatCost } = useCostUtils()
  const roadmapEntitiesAsArray = useAppSelector(selectRoadmapEntitiesAsArray)

  const axisTitle = {
    size: 16,
    weight: 'bold',
    family: 'Inter',
    lineHeight: '200%',
  }

  const getAxisOptions = (axisText: string, isReversed: boolean) => {
    return {
      title: {
        display: true,
        text: axisText,
        font: axisTitle,
      },
      ticks: {
        callback: function (value: any) {
          switch (value) {
            case 1:
              return 'Low'

            case 2:
              return 'Medium'

            case 3:
              return 'High'

            default:
              return null
          }
        },
      },
      min: 0.5,
      max: 3.5,
      grid: {
        offset: true,
      },
      reverse: isReversed,
    }
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: getAxisOptions('Priority', false),
      x: getAxisOptions('Complexity', true),
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItems: any) {
            const _case = roadmapEntitiesAsArray[tooltipItems.dataIndex]
            const { title, business_case_cost, implementation_cost } = _case
            const buzCase = formatCost(business_case_cost - implementation_cost)

            return ` ${title}${'     '}(${buzCase})`
          },
        },
      },
    },
    onClick: function (_e: any, elem: any) {
      const el = elem?.[0]
      if (!el) return

      const selectedCaseId = roadmapEntitiesAsArray[el.index]?.id
      if (selectedCaseId) dispatch(setActiveCaseId(selectedCaseId))
    },
    // Adds pointer cursor on bubble hover
    onHover: function (e: any) {
      // @ts-ignore:next-line
      const points = this.getElementsAtEventForMode(
        e,
        'index',
        { axis: 'x', intersect: true },
        false,
      )

      if (points.length) e.native.target.style.cursor = 'pointer'
      else e.native.target.style.cursor = 'default'
    },
  }

  if (!chartData) return null

  return (
    <div className='h-[calc(100vh-228px)]'>
      <Bubble data={chartData} options={options as any} />
    </div>
  )
}

export default BubbleChart

