import { memo } from 'react'

import { ActivityItem } from '.'
import { useGetCaseActivityQuery } from '../../../../../app/apiSlice'
import { Loader } from '../../../../../common/components'

interface Props {
  caseId: number
}

const ActivityFeedSection = ({ caseId }: Props) => {
  const { data, isLoading } = useGetCaseActivityQuery(caseId)

  if (isLoading) return <Loader />

  return (
    <div className='mb-4 flex flex-col bg-gray-100'>
      {data?.map((a) => (
        <ActivityItem
          userId={a.user_id}
          activityMessage={a.activity_message}
          dateTime={a.created_date_time}
          key={`activity-item-${a.activity_message}-${a.created_date_time}`}
        />
      ))}
    </div>
  )
}

export default memo(ActivityFeedSection)
