import { useCallback, useEffect, useRef, useState } from 'react'

import {
  useGetProcessCustomGroupByDimensionsQuery,
  useGetStepAnalysisQuery,
} from '../../../../../app/apiSlice'
import { ContentDisplay, Loader } from '../../../../../common/components'
import { Select, Text } from '../../../../../common/designs'
import { useDashboard, useQueryFilters } from '../../../../../common/hooks'
import {
  type AnalysisChartConfigurationData,
  type DimensionExtraDataMapper,
  GroupByDimensionTypeEnum,
} from '../../../types'
import AnalysisChart from '../../Analysis/AnalysisChart'
import {
  defaultGroupByDimensionsExtraDataMapper,
  setUpStepChartDataAndMetric,
} from './analysisConfigs'
import { generateGroupByField } from './generateGroupByField'
import { StepGroupByFieldTypeEnum, StepMetricNamesEnum } from './types'

interface Props {
  metricOptionMapper: {
    [key: string]: {
      metric_unit: string
      metric_name: string
    }
  }
}

const StepAnalysis = ({ metricOptionMapper }: Props) => {
  const { processFiltersWithZooming, processId } = useQueryFilters()
  const { teams } = useDashboard()
  const [chartConfiguration, setChartConfiguration] = useState<AnalysisChartConfigurationData>({
    label: '',
    chartData: [],
    unit: '',
  })
  const [selectedGroupByField, setSelectedGroupByField] = useState<string>(
    StepGroupByFieldTypeEnum.TRANSACTION_ID,
  )
  const [selectedMetric, setSelectedMetric] = useState<StepMetricNamesEnum>(
    StepMetricNamesEnum.AVG_DURATION_SEC,
  )

  const groupByDimensionsExtraDataMapper = useRef<DimensionExtraDataMapper>(
    defaultGroupByDimensionsExtraDataMapper,
  )
  // Fetch custom group by dimensions from server. After data fetching
  // update dimensions to groupByDimensionsExtraDataMapper
  const { data: customDimensions, isFetching: isFetchingDimensions } =
    useGetProcessCustomGroupByDimensionsQuery(processId)

  const groupBy = generateGroupByField(
    selectedGroupByField,
    groupByDimensionsExtraDataMapper.current,
  )
  const { data: analysisData, isFetching: isFetchingAnalysisData } = useGetStepAnalysisQuery({
    processId,
    filters: processFiltersWithZooming,
    group_by: groupBy,
    metrics: [selectedMetric],
  })

  // Add data meta data for custom dimensions
  useEffect(() => {
    if (!customDimensions) return
    const customDimensionMapper = {} as DimensionExtraDataMapper
    customDimensions.forEach(({ dimension_key, dimension_name, is_tag_dimension }) => {
      customDimensionMapper[dimension_key] = {
        dimension_name,
        group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
        is_tag_dimension,
        filter_key: dimension_key,
      }
    })

    groupByDimensionsExtraDataMapper.current = {
      ...groupByDimensionsExtraDataMapper.current,
      ...customDimensionMapper,
    }
  }, [customDimensions]) // eslint-disable-line react-hooks/exhaustive-deps

  // Server data to chart data logic
  useEffect(() => setChartData(), [analysisData]) // eslint-disable-line react-hooks/exhaustive-deps

  const setChartData = () => {
    if (!analysisData) return
    const { chartData, unit } = setUpStepChartDataAndMetric(
      analysisData[selectedMetric],
      selectedMetric,
      selectedGroupByField,
      teams,
    )

    const groupByMetadata = groupByDimensionsExtraDataMapper.current[selectedGroupByField]
    const metricMetaData = metricOptionMapper[selectedMetric as keyof typeof metricOptionMapper]

    setChartConfiguration({
      label: `${groupByMetadata.dimension_name} - ${metricMetaData.metric_name}`,
      description: '', //getDescription(groupByMetadata, metricMetaData),
      chartData,
      unit,
    })
  }

  const onGroupByValueChange = useCallback((val: string) => setSelectedGroupByField(val), [])
  const onMetricOptionChange = useCallback(
    (val: string) => setSelectedMetric(val as StepMetricNamesEnum),
    [],
  )

  if (isFetchingDimensions) return <Loader />

  const groupByOptions = [
    ...Object.entries(groupByDimensionsExtraDataMapper.current).map(
      ([value, { dimension_name }]) => ({
        label: dimension_name,
        value,
      }),
    ),
  ]

  const metricOptions = [
    ...Object.entries(metricOptionMapper).map(([value, { metric_name }]) => ({
      label: metric_name,
      value,
    })),
  ]

  return (
    <div className='flex flex-col gap-2  py-4'>
      <ContentDisplay className='px-5 pb-3 pt-4'>
        <div className='flex gap-6'>
          <div className='w-96'>
            <Select
              options={groupByOptions}
              value={selectedGroupByField}
              onChange={onGroupByValueChange}
              label='Group by dimensions'
            />
          </div>
          <div className=' w-96'>
            <Select
              options={metricOptions}
              value={selectedMetric}
              onChange={onMetricOptionChange}
              label='Metric'
            />
          </div>
        </div>
        <Text className='mt-2' size='xs'>
          The analysis view shows only a maximum of 2000 results
        </Text>
      </ContentDisplay>
      <AnalysisChart configurations={chartConfiguration} isLoading={isFetchingAnalysisData} />
    </div>
  )
}

export default StepAnalysis
