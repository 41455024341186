import {
  add,
  addHours,
  endOfDay,
  endOfWeek,
  format,
  formatISO,
  min,
  parse,
  parseISO,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'

import { DEFAULT_WEEK_START_ON } from '../../app/constants'
import type { SimpleDate } from '../types/datetime'
import { GLOBAL_DATE_FORMAT } from '../types/datetime'
import { GranularityEnum } from '../types/monitoring'
import { TimePeriodValueEnum } from '../types/searchParams'

export const toDateString = (date: Date): SimpleDate =>
  format(date, GLOBAL_DATE_FORMAT) as SimpleDate

export const fromDateString = (dateString: string) =>
  parse(dateString, GLOBAL_DATE_FORMAT, new Date(0, 0, 0, 0, 0, 0))

export const parseIsoDateToSimpleDate = (dateString: string) => {
  return format(parseISO(dateString), GLOBAL_DATE_FORMAT)
}

export const getWeekInterval = (weeksFromStart = 0, startDate: Date = new Date()) => {
  const targetDate = add(startDate, {
    weeks: weeksFromStart,
  })

  return {
    start: toDateString(startOfWeek(targetDate, { weekStartsOn: 1 })),
    end: toDateString(endOfWeek(targetDate, { weekStartsOn: 1 })),
  }
}

const generateCorrectStartDate = (val: any, startDate: Date | SimpleDate) => {
  return val > startDate ? val : startDate
}

export const generateMinDate = (timePeriod: TimePeriodValueEnum, minDate: Date, maxDate: Date) => {
  switch (timePeriod) {
    case TimePeriodValueEnum.CURRENT_WEEK:
      return generateCorrectStartDate(
        startOfWeek(maxDate, { weekStartsOn: DEFAULT_WEEK_START_ON }),
        minDate,
      )

    case TimePeriodValueEnum.CURRENT_MONTH:
      return generateCorrectStartDate(startOfMonth(maxDate), minDate)

    case TimePeriodValueEnum.LAST_7_DAYS:
      return generateCorrectStartDate(sub(maxDate, { days: 6 }), minDate)

    case TimePeriodValueEnum.LAST_30_DAYS:
      return generateCorrectStartDate(sub(maxDate, { days: 29 }), minDate)

    case TimePeriodValueEnum.LAST_90_DAYS:
      return generateCorrectStartDate(sub(maxDate, { days: 89 }), minDate)

    case TimePeriodValueEnum.LAST_180_DAYS:
      return generateCorrectStartDate(sub(maxDate, { days: 179 }), minDate)

    default:
      throw new Error('Invalid time period detected')
  }
}

// Generate a list of dates
export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime()) // Prevent side effects by recreating the date
  const arr = []

  while (date <= endDate) {
    arr.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return arr
}

export const generateUCTTimestamps = (
  dateRange: { start: Date | null; end: Date | null },
  granularity: GranularityEnum,
) => {
  if (!dateRange.start || !dateRange.end) return []

  const timestamps = []
  let startDate = startOfDay(new Date(dateRange.start))
  // Ensure the end date covers the entire day, but if date is today exclude future.
  const endDate = min([endOfDay(new Date(dateRange.end)), new Date()])

  while (startDate <= endDate) {
    // Reset minutes, seconds, and milliseconds based on granularity
    const resetDate =
      granularity === GranularityEnum.DAY ? startOfDay(startDate) : startOfHour(startDate)

    timestamps.push(formatISO(resetDate, { representation: 'complete' }).substring(0, 19))

    // Increment by hour or day
    startDate = add(resetDate, granularity === GranularityEnum.DAY ? { days: 1 } : { hours: 1 })
  }

  return timestamps
}

export const roundToNextHour = (date: Date) => {
  date = setMinutes(date, 0)
  date = setSeconds(date, 0)
  date = setMilliseconds(date, 0)

  // If the time was exactly at the hour mark, it should not add an hour
  if (date.getTime() !== new Date().getTime()) date = addHours(date, 1)

  return date
}
