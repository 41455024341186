import { useEnhancedNavigate } from '../../../common/hooks'
import TeamsChartView from '../trendAndTeamViews/TeamsChartView'

const Teams = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const onChartClick = (compressedAppTags: string) => {
    navigate(`../${compressedAppTags}/teams`)
  }

  return (
    <TeamsChartView
      chartTitle='Business apps - Team comparison'
      relativeChartTitle='Business apps - Team comparison - Relative'
      tagsOfInterestSource='application'
      onChartClick={onChartClick}
    />
  )
}

export default Teams
