import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector, useEnhancedSearchParams } from '../../../../common/hooks'
import { SubSearchParamEnum } from '../../../../common/types/searchParams'
import { generateIncrementalIndexesArrayUntil } from '../../../../common/utils/utils'
import {
  selectHighlightSelections,
  selectInitialVisibleVariantAmount,
  setHighlightSelections,
} from '../../processesSlice'
import { VariantVisualizationLevelEnum } from './types'

export const useSelectedVariantsIndicies = () => {
  const dispatch = useAppDispatch()
  const highlightSelections = useAppSelector(selectHighlightSelections)
  const [searchParams] = useSearchParams()
  const { updateSearchParam, deleteSearchParam } = useEnhancedSearchParams()
  const initialVisibleVariantAmount = useAppSelector(selectInitialVisibleVariantAmount)

  const getSearchParamByKey = useCallback(
    (paramKey: SubSearchParamEnum) => {
      const searchIndices = searchParams.get(paramKey)
      if (!searchIndices) return generateIncrementalIndexesArrayUntil(initialVisibleVariantAmount)
      return searchIndices.split(',')?.map((i) => Number(i)) ?? []
    },
    [searchParams, initialVisibleVariantAmount],
  )

  const stepLevelVariantIndices = useMemo(
    () => getSearchParamByKey(SubSearchParamEnum.SHOWN_VARIANTS_STEPS),
    [getSearchParamByKey],
  )

  const stateLevelVariantIndices = useMemo(
    () => getSearchParamByKey(SubSearchParamEnum.SHOWN_VARIANTS_STATES),
    [getSearchParamByKey],
  )

  const teamLevelVariantIndices = useMemo(
    () => getSearchParamByKey(SubSearchParamEnum.SHOWN_VARIANTS_STATES),
    [getSearchParamByKey],
  )

  const windowLevelVariantIndices = useMemo(
    () => getSearchParamByKey(SubSearchParamEnum.SHOWN_VARIANTS_WINDOWS),
    [getSearchParamByKey],
  )

  const updateSelectedVariantIndicies = useCallback(
    (selectedVariants: number[], visualizationLevel: VariantVisualizationLevelEnum) => {
      const paramKey = getSearchParamKey(visualizationLevel)
      const defaultVariants = generateIncrementalIndexesArrayUntil(initialVisibleVariantAmount)
      const isDefaultSelected = JSON.stringify(defaultVariants) === JSON.stringify(selectedVariants)
      if (selectedVariants.length === 0 || isDefaultSelected) {
        deleteSearchParam(paramKey)
      } else {
        updateSearchParam(paramKey, selectedVariants.toString())
      }

      if (
        VariantVisualizationLevelEnum.STEP &&
        selectedVariants.length > 1 &&
        highlightSelections.isUnwindModeActive
      ) {
        dispatch(setHighlightSelections({ ...highlightSelections, isUnwindModeActive: false }))
      }
    },
    [
      deleteSearchParam,
      updateSearchParam,
      initialVisibleVariantAmount,
      dispatch,
      highlightSelections,
    ],
  )

  return {
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
    updateSelectedVariantIndicies,
  }
}

const getSearchParamKey = (visualizationLevel: VariantVisualizationLevelEnum) => {
  switch (visualizationLevel) {
    case VariantVisualizationLevelEnum.WINDOW:
      return SubSearchParamEnum.SHOWN_VARIANTS_WINDOWS

    case VariantVisualizationLevelEnum.STEP:
      return SubSearchParamEnum.SHOWN_VARIANTS_STEPS

    case VariantVisualizationLevelEnum.STATE:
    case VariantVisualizationLevelEnum.TEAM:
      return SubSearchParamEnum.SHOWN_VARIANTS_STATES

    default:
      return SubSearchParamEnum.SHOWN_VARIANTS_WINDOWS
  }
}
