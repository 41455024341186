import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Resolver, SubmitHandler, useForm } from 'react-hook-form'

import { ContentDisplay } from '..'
import type { AppMetaFormData } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import { Button } from '../../designs'
import AppCommonInfoForm from '../DataCollectionForms/AppCommonInfoForm'
import { appMetaFormSchema } from '../DataCollectionForms/formSchemas'

interface Props {
  initialAppFormData: AppMetaFormData
  onUpdateAppData: (data: AppMetaFormData) => Promise<void>
  isLoading: boolean
}

const DataCollectionAppForm = ({ initialAppFormData, onUpdateAppData, isLoading }: Props) => {
  const { control, handleSubmit, reset } = useForm<AppMetaFormData>({
    resolver: yupResolver(appMetaFormSchema) as Resolver<AppMetaFormData>,
    defaultValues: initialAppFormData,
  })

  useEffect(() => {
    reset(initialAppFormData)
  }, [reset, initialAppFormData])

  const onFormSubmit: SubmitHandler<AppMetaFormData> = async (data: AppMetaFormData) => {
    await onUpdateAppData(data)
  }

  return (
    <ContentDisplay className='p-4'>
      <form onSubmit={handleSubmit(onFormSubmit)} className='space-y-4' id='config-wizard-form'>
        <AppCommonInfoForm control={control} isLoading={isLoading} />
        <Button type='submit'>Save</Button>
      </form>
    </ContentDisplay>
  )
}

export default DataCollectionAppForm
