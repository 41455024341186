import { useEnhancedNavigate } from '../../../common/hooks'
import TrendsChartView from '../trendAndTeamViews/TrendsChartView'

const Trends = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <TrendsChartView
      tagsOfInterestSource='application'
      chartTitle='Business apps trends'
      relativeChartTitle='Business apps trends - Relative'
      onChartClick={(compressedAndEncodedTags) => navigate(`../${compressedAndEncodedTags}/trends`)}
    />
  )
}

export default Trends
