import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  ChartBarSquareIcon,
  ChevronLeftIcon,
  ComputerDesktopIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Button, Divider, Text } from '../../../common/designs'
import { useDashboard } from '../../../common/hooks'
import { AdminMainPathEnum, MainPathEnum } from '../../../common/types/routing'
import CompanySelect from '../MainNavigation/CompanySelect'

interface NavBranch {
  name: string
  href?: string
  icon: React.ElementType
  active: boolean
  children?: NavTwig[]
}

interface NavTwig {
  name: string
  href: string
  active: boolean
  isHidden?: boolean
}

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useDashboard()
  const [isOpen, setIsOpen] = useState<Record<number, boolean>>({})

  useEffect(() => {
    const newIsOpen: Record<number, boolean> = {}
    updatedNav.forEach((item, i) => {
      if (item.children) newIsOpen[i] = item.children.some((child) => child.active)
    })
    setIsOpen(newIsOpen)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateActiveNav = (navItems: NavBranch[]): NavBranch[] => {
    return navItems.map((item) => {
      const updatedItem: NavBranch = {
        ...item,
        active: item.href ? location.pathname.includes(item.href) : false,
      }

      if (item.children) {
        updatedItem.children = item.children.map((child) => ({
          ...child,
          active: child.href ? location.pathname.includes(child.href) : false,
        }))
      }

      return updatedItem
    })
  }

  const navigation: NavBranch[] = [
    {
      name: 'Service Setup',
      icon: WrenchIcon,
      active: true,
      children: [
        { name: 'Get Started', href: AdminMainPathEnum.GET_STARTED, active: true },
        { name: 'Agent Deployment', href: AdminMainPathEnum.INSTALLATION_CENTER, active: false },
        {
          name: 'Configure Data Collection',
          href: AdminMainPathEnum.DATA_COLLECTION,
          active: false,
        },
        { name: 'Structured Processes', href: AdminMainPathEnum.PROCESS, active: false },
      ],
    },
    {
      name: 'Dashboard Settings',
      icon: ComputerDesktopIcon,
      active: false,
      children: [
        { name: 'Company Information', href: AdminMainPathEnum.COMPANY, active: false },
        { name: 'User Management', href: AdminMainPathEnum.USERS, active: false },
        { name: 'Development Opportunities', href: AdminMainPathEnum.OPPORTUNITIES, active: false },
        { name: 'Team Grouping', href: AdminMainPathEnum.TEAM_GROUPS, active: false },
        { name: 'Data Deletion', href: AdminMainPathEnum.DATA_DELETION, active: false },
      ],
    },
    {
      name: 'Monitoring',
      icon: ChartBarSquareIcon,
      active: false,
      children: [
        { name: 'Overview', href: AdminMainPathEnum.MONITORING_OVERVIEW, active: false },
        { name: 'Computer Connectivity', href: AdminMainPathEnum.AGENTS, active: false },
        {
          name: 'Performance Readings',
          href: AdminMainPathEnum.PERFORMANCE,
          active: false,
          isHidden: !user.isSuperuser,
        },
      ],
    },
  ]

  const updatedNav = useMemo(() => updateActiveNav(navigation), [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex h-full w-72 grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-6'>
      <Link
        to={MainPathEnum.DEFAULT}
        className='flex h-16 shrink-0 items-center hover:cursor-pointer'
      >
        <img className='size-6' alt='Process Intelligence logo' src='/images/pi-logo-color.png' />

        <Text variant='h1' size='xl' weight={500} className='ml-2'>
          Admin Panel
        </Text>
      </Link>

      <CompanySelect />

      <Divider className='my-2' color='lightGray' />

      <nav className='flex flex-1 flex-col'>
        <ul className='flex flex-1 flex-col gap-y-7'>
          <li>
            <ul className='-mx-2 space-y-1'>
              {updatedNav.map((item, i: number) => {
                return (
                  <li key={item.name}>
                    {!item.children ? (
                      <Link
                        to={item.href as string}
                        className={clsx(
                          item.active
                            ? 'bg-gray-100/80 text-primary-600'
                            : 'hover:bg-gray-50 hover:text-primary-600',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700',
                        )}
                      >
                        <item.icon
                          className={clsx(
                            'size-6 shrink-0 text-gray-400 group-hover:text-primary-600',
                            item.active && 'text-primary-600',
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure as='div'>
                        {({ open: statelessOpen, close }) => {
                          const isTabOpen = isOpen[i]

                          return (
                            <>
                              <Disclosure.Button
                                className={clsx(
                                  item.active
                                    ? 'bg-gray-100/80'
                                    : 'hover:bg-gray-50 hover:text-primary-600',
                                  'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700',
                                )}
                              >
                                <item.icon
                                  className='size-6 shrink-0 text-gray-400 group-hover:text-primary-600'
                                  aria-hidden='true'
                                />
                                {item.name}
                                <ChevronRightIcon
                                  className={clsx(
                                    statelessOpen || isTabOpen
                                      ? 'rotate-90 text-gray-500 '
                                      : 'text-gray-400',
                                    'ml-auto size-5 shrink-0 group-hover:text-primary-600',
                                  )}
                                  aria-hidden='true'
                                />
                              </Disclosure.Button>

                              <Disclosure.Panel static={isTabOpen} as='ul' className='mt-1 px-2'>
                                {item.children?.map((subItem) => {
                                  if (subItem?.isHidden) return null

                                  return (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={clsx(
                                          subItem.active
                                            ? 'bg-gray-100/80 text-primary-600'
                                            : 'hover:bg-gray-50 hover:text-primary-600',
                                          'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700',
                                        )}
                                        onClick={() => close()}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  )
                                })}
                              </Disclosure.Panel>
                            </>
                          )
                        }}
                      </Disclosure>
                    )}
                  </li>
                )
              })}
            </ul>
          </li>

          <li className='-mx-6 mt-auto'>
            <Button
              size='xl'
              variant='ghost'
              iconStart={<ChevronLeftIcon />}
              onClick={() => navigate(MainPathEnum.DEFAULT)}
              wFull
            >
              Back to Dashboard
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar

