import { GroupByFieldTypeEnum } from '../../types'
import ConstantDimensionAnalysis from './ConstantDimensionAnalysis'

export const TeamAnalysis = () => {
  return (
    <ConstantDimensionAnalysis
      groupByField={GroupByFieldTypeEnum.TEAM_ID}
      groupFilterKey='team_id_filters'
      label='Team analysis'
    />
  )
}

export default TeamAnalysis
