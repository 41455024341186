import { formatISO } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { TAGS_OF_INTEREST } from '../../app/constants'
import type { SimpleDate } from '../types/datetime'
import { GlobalSearchParamEnum, TimePeriodValueEnum } from '../types/searchParams'
import { generateMinDate, toDateString } from '../utils/dateTimeUtils'
import { decompressAndDecodeJSON } from '../utils/jsonCompression'
import { flattenNestedObject } from '../utils/utils'
import { useDashboard, useLocalStorage } from './'

const getTeamIdsDefault = (searchParams: URLSearchParams) => {
  const encodedTeamIds = searchParams.get(GlobalSearchParamEnum.TEAM_IDS)
  return encodedTeamIds ? decompressAndDecodeJSON<string[]>(encodedTeamIds) : []
}

const useQueryFilters = () => {
  const [searchParams] = useSearchParams()
  const { processFilters: storageProcessFIlters } = useLocalStorage()
  const { dataDate } = useDashboard()
  const { trackedProcessId: processId = '' } = useParams()

  const [teamIdsFilter, setTeamIdsFilter] = useState<string[]>(getTeamIdsDefault(searchParams))

  // Initialize team_ids.
  useEffect(() => {
    setTeamIdsFilter(getTeamIdsDefault(searchParams))
  }, [searchParams.get(GlobalSearchParamEnum.TEAM_IDS)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize min_date and max_date.
  const startDate = searchParams.get(GlobalSearchParamEnum.START_DATE)
  const endDate = searchParams.get(GlobalSearchParamEnum.END_DATE)
  const timePeriod = searchParams.get(
    GlobalSearchParamEnum.TIME_PERIOD,
  ) as TimePeriodValueEnum | null

  let minDate: Date | SimpleDate = new Date(dataDate.min)
  let maxDate: Date | SimpleDate = new Date(dataDate.max)

  if (!startDate || !endDate) {
    minDate = generateMinDate(timePeriod ?? TimePeriodValueEnum.LAST_7_DAYS, minDate, maxDate)
  } else {
    minDate = new Date(startDate)
    maxDate = new Date(endDate)
  }

  // Initialize process filters.
  const strignProcessFilters = JSON.stringify(storageProcessFIlters)
  const { processFilters, processFiltersWithZooming } = useMemo(() => {
    const jsonProcessFilters = JSON.parse(strignProcessFilters)
    const processFiltersWithZooming = flattenNestedObject(jsonProcessFilters)
    return {
      processFiltersWithZooming,
      processFilters: _.omit({ ...processFiltersWithZooming }, 'zooming_filters'),
    }
  }, [strignProcessFilters])

  return {
    team_ids: teamIdsFilter,
    tags_of_interest: TAGS_OF_INTEREST,
    min_date: toDateString(minDate as Date),
    max_date: toDateString(maxDate as Date),
    min_date_iso: formatISO(new Date(minDate).setHours(0, 0, 0, 0)),
    max_date_iso: formatISO(new Date(maxDate).setHours(0, 0, 0, 0)),
    processFilters,
    processFiltersWithZooming,
    processId,
  }
}

export default useQueryFilters
