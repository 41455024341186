import type { Options } from 'react-graph-vis'

import { GRAY_300_HEX, PRIMARY_600_HEX } from '../../app/theme'

const options: Options = {
  nodes: {
    shape: 'dot',
    scaling: {
      min: 5,
      max: 13,
      label: {
        min: 3.5,
        max: 9,
        drawThreshold: 6,
        maxVisible: 120,
      },
    },
    borderWidthSelected: 3,
  },
  edges: {
    scaling: {
      min: 0.01,
      max: 4,
    },
    selectionWidth: 0.015, // width * 1.5
    width: 0.01,
    color: { color: GRAY_300_HEX, highlight: PRIMARY_600_HEX },
    smooth: {
      enabled: true,
      type: 'continuous',
      roundness: 0.5,
    },
    arrows: {
      to: false,
      from: false,
    },
  },
  physics: false,
  interaction: {
    tooltipDelay: 200,
    hideEdgesOnDrag: true,
    hideEdgesOnZoom: false,
  },
}

export default options
