// import { LogLevel } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'

import { MICROSOFT_ENTRA_CLIENT_ID, WEB_APP_URL_DASHBOARD_FRONTEND } from '../../app/configs'

/**
 * Configuration object to be passed to MSAL instance on creation
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_ENTRA_CLIENT_ID, // App registration's client id
    /**
     * Definining the authority
     *  https://github.com/MicrosoftDocs/SupportArticles-docs/blob/main/support/azure/active-directory/error-code-AADSTS50020-user-account-identity-provider-does-not-exist.md#solution-use-the-correct-sign-in-url
     */
    authority: 'https://login.microsoftonline.com/organizations', // Multitenant applications
    redirectUri: `${WEB_APP_URL_DASHBOARD_FRONTEND}/blank.html`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  /**
   * Logger configuration
   * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-logging-js
   */
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level: LogLevel, msg: string, containsPii: boolean) => {
  //       if (containsPii) return

  //       switch (level) {
  //         case LogLevel.Error:
  //           return console.error(msg)

  //         case LogLevel.Info:
  //           return console.info(msg)

  //         case LogLevel.Verbose:
  //           return console.debug(msg)

  //         case LogLevel.Warning:
  //           return console.warn(msg)
  //       }
  //     },
  //   },
  // },
}

export const loginRequest = {
  scopes: [],
  /**
   * -- Prompt behavior with MSAL.js -- Interrupts single sign-on by providing an account selection experience
   * listing all the accounts in session or an option to choose a different account altogether.
   * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-prompt-behavior
   */
  prompt: 'select_account',
}

export const msalInstance = new PublicClientApplication(msalConfig)

