import { useMemo, useState } from 'react'
import type { BaseNode, Data, GraphEvents, GraphEventsSelectParams } from 'react-graph-vis'

import { useGetNetworkNavigationsQuery } from '../../../app/apiSlice'
import { appCategoryColorMap } from '../../../app/theme'
import { useCostUtils, useQueryFilters } from '../../../common/hooks'
import type { ApplicationCategory } from '../../../common/types/dataCollectionsConfigs'
import NavigationsFlyoutStats from '../../DataFlows/NavigationsFlyoutStats'
import Legend from '../../Network/Legend'
import NetworkGraph from '../../Network/NetworkGraph'
import type {
  NavigationInsight,
  NavigationInsightEdge,
  NavigationInsightNode,
} from '../../Network/types'
import useClusterLegend from '../../Network/useClusterLegend'
import { removeWhiteSpaces } from '../../Network/utils'

const Navigations = () => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()
  const { formatCost, annualizedCostMultiplier, generateAnnualizedNavigationsCost } = useCostUtils()

  const [insights, setInsights] = useState<NavigationInsight>({ node: null, edge: null })
  const [clustersVisible, setClustersVisible] = useClusterLegend()

  const { data } = useGetNetworkNavigationsQuery({
    min_date,
    max_date,
    team_ids,
    tags_of_interest: tags_of_interest.application,
    node_amount: 1000,
    tag_filters: [],
    match_any_tag_filters: [],
  })

  const isSelfLoopsVisible = true // TODO: Consider implementing visibility toggle for self-loops.

  const graphData = useMemo(() => {
    if (!data) return null

    const nodes: BaseNode[] = Object.values(data.nodes)
      .filter(({ group }) => clustersVisible[group as ApplicationCategory])
      .map(({ id, label, value, x, y, group }) => {
        return { id, label, value, x, y, group, color: appCategoryColorMap[group] }
      })

    const edges = Object.values(data.edges).filter(({ from, to }) => {
      return isSelfLoopsVisible || from !== to
    })

    return { nodes, edges } as Data
  }, [data, isSelfLoopsVisible, clustersVisible])

  const events: GraphEvents = {
    select: ({ nodes, edges }: GraphEventsSelectParams) => {
      if (!data) return

      let nodeInsight: NavigationInsightNode | null = null
      let edgeInsight: NavigationInsightEdge | null = null

      if (nodes.length) {
        const nodeId = removeWhiteSpaces(nodes[0])
        const node = data.nodes[nodeId]

        const visits = node.value
        const totalH = node.properties.time_h
        const annualizedCost = formatCost(totalH * annualizedCostMultiplier)

        nodeInsight = { entity: node, visits, totalH, annualizedCost }
      }

      if (edges.length) {
        const edgeId = edges[0]
        const edge = data.edges[edgeId]

        const navigations = edge.value
        const annualizedCost = generateAnnualizedNavigationsCost(navigations)

        edgeInsight = { entity: edge, navigations, annualizedCost }
      }

      setInsights({ node: nodeInsight, edge: edgeInsight })
    },
  }

  return (
    <div className='relative size-full'>
      <NavigationsFlyoutStats insights={insights} />

      <div className='absolute right-0 z-[1] space-y-2'>
        <Legend clustersVisible={clustersVisible} setClustersVisible={setClustersVisible} />
      </div>

      <NetworkGraph data={graphData} events={events} />
    </div>
  )
}

export default Navigations
