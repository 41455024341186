import { differenceInDays } from 'date-fns'
import { useMemo } from 'react'

import { useGetComputerCountDataQuery } from '../../../app/apiSlice'
import { Stats } from '../../../common/components'
import { useCostUtils, useFilteredWindowUsageQuery, useQueryFilters } from '../../../common/hooks'
import { formatBuszAppData } from '../../../common/utils/dataFormatting'
import { formatNumber } from '../../../common/utils/numberFormatting'
import type { WindowUsageData } from '../types'

const TimeStats = () => {
  const { formatCost } = useCostUtils()
  const { min_date, max_date, min_date_iso, max_date_iso, team_ids } = useQueryFilters()

  const { data } = useFilteredWindowUsageQuery({ tagsOfInterestSource: 'application' })
  const { data: dailyComputerCountSum } = useGetComputerCountDataQuery({
    team_ids,
    start_timestamp: min_date_iso,
    end_timestamp: max_date_iso,
  })

  const statData = useMemo(() => {
    const dataList = data as WindowUsageData[]
    const timeH = dataList?.reduce((sum: number, val) => sum + val.time_h, 0) ?? 0
    const [teams, annualCost, visits] = dataList ? formatBuszAppData(dataList) : []

    const dailyHours = dailyComputerCountSum ? timeH / dailyComputerCountSum : 0
    const selectedDateCount = differenceInDays(new Date(max_date), new Date(min_date)) + 1

    return [
      [
        {
          value: formatCost(annualCost),
          label: 'Annualized cost',
        },
        {
          value: formatNumber(timeH, 1),
          label: 'Total hours',
        },
        {
          value: formatNumber(dailyHours),
          label: 'Daily hours per computer',
        },
      ],
      [
        {
          value: formatNumber(visits, 1),
          label: 'Total visits',
        },
        {
          value: teams ?? '',
          label: 'Teams',
        },
        {
          value: formatNumber((dailyComputerCountSum ?? 0) / selectedDateCount, 1),
          label: 'Average daily computers',
        },
      ],
    ]
  }, [formatCost, data, dailyComputerCountSum, min_date, max_date])

  return (
    <div className='flex flex-col gap-2'>
      <Stats data={statData[0]} />
      <Stats data={statData[1]} />
    </div>
  )
}

export default TimeStats
