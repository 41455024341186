import _ from 'lodash'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

import { Lane } from '.'
import { useUpdateKanbanOrderMutation } from '../../../../../../app/apiSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks'
import {
  changeCaseOrder,
  loadSnapshot,
  saveSnapshot,
  selectKanbanFiltered,
} from '../../../../casesSlice'

const Kanban = () => {
  const dispatch = useAppDispatch()
  const kanban = useAppSelector(selectKanbanFiltered)

  const [updateKanbanOrderMutation] = useUpdateKanbanOrderMutation()

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || _.isEqual(result.source, result.destination)) return

    dispatch(saveSnapshot())
    dispatch(changeCaseOrder(result))

    updateKanbanOrderMutation({
      caseId: Number(result.draggableId),
      body: {
        source: {
          lane_id: Number(result.source.droppableId),
          case_index_in_lane: result.source.index,
        },
        destination: {
          lane_id: Number(result.destination.droppableId),
          case_index_in_lane: result.destination.index,
        },
      },
    })
      .unwrap()
      .catch(() => {
        dispatch(loadSnapshot())
      })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='flex min-h-[calc(100vh-222px)]'>
        {Object.keys(kanban)?.map((laneId) => <Lane laneId={laneId} key={laneId} />)}
      </div>
    </DragDropContext>
  )
}

export default Kanban
