export const formatNumber = (num: number | string, round: number | string = 100): string => {
  try {
    const roundAsNumber = Number(round)
    const roundedNum = Math.round(Number(num) * roundAsNumber) / roundAsNumber

    return roundedNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  } catch (err) {
    throw new Error('Incorrect or missing values given to formatNumber function.')
  }
}

export const fractionToPercentage = (fraction: number | string): string => {
  try {
    fraction = Number(fraction)
  } catch (err) {
    throw new Error('Incorrect or missing values given to fractionToPercentage function.')
  }

  return `${Math.round(fraction * 100)}%`
}

export const getBestTimeFormatForSeconds = (seconds: number): string => {
  const abs_seconds = Math.abs(seconds)

  const secondsInAMinute = 60
  const secondsInAnHour = 60 * secondsInAMinute
  const secondsInADay = 24 * secondsInAnHour

  // Days
  if (abs_seconds > secondsInADay) {
    return `${formatNumber(seconds / secondsInADay, 10)} days`
  }

  // Hours
  if (abs_seconds > secondsInAnHour) {
    return `${formatNumber(seconds / secondsInAnHour, 10)} h`
  }

  // Minutes
  if (abs_seconds > secondsInAMinute) {
    return `${formatNumber(seconds / secondsInAMinute, 10)} min`
  }

  // Seconds
  return `${formatNumber(seconds, 1)} sec`
}

export enum TimeUnitEnum {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}

export const getBestTimeFormatTransformationForSeconds = (
  seconds: number,
  maxUnit: TimeUnitEnum = TimeUnitEnum.DAYS,
): { divider: number; unit: string } => {
  const abs_seconds = Math.abs(seconds)

  const secondsInAMinute = 60
  const secondsInAnHour = 60 * secondsInAMinute
  const secondsInADay = 24 * secondsInAnHour

  // Days
  if (abs_seconds > secondsInADay && maxUnit === TimeUnitEnum.DAYS) {
    return { divider: secondsInADay, unit: 'days' }
  }

  // Hours
  if (abs_seconds > secondsInAnHour && maxUnit !== TimeUnitEnum.MINUTES) {
    return { divider: secondsInAnHour, unit: 'h' }
  }
  // Minutes
  if (abs_seconds > secondsInAMinute) {
    return { divider: secondsInAMinute, unit: 'min' }
  }

  // Seconds
  return { divider: 1, unit: 'sec' }
}
