import { EyeSlashIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import { appCategoryBgColorMap } from '../../app/theme'
import { Text } from '../../common/designs'
import { applicationCategories } from '../../common/types/dataCollectionsConfigs'
import { ClusterVisible } from './types'

interface Props {
  clustersVisible: ClusterVisible
  setClustersVisible: React.Dispatch<ClusterVisible>
}

const Legend = ({ clustersVisible, setClustersVisible }: Props) => {
  return (
    <div className='rounded-md bg-white/80 p-1 shadow-md ring-1 ring-gray-300'>
      {Object.values(applicationCategories).map((category) => (
        <div
          className='flex cursor-pointer items-center gap-1 rounded-md p-1 hover:bg-gray-200'
          onClick={() =>
            setClustersVisible({ ...clustersVisible, [category]: !clustersVisible[category] })
          }
          key={category}
        >
          {clustersVisible[category] ? (
            <div className={clsx('size-4 rounded-full', appCategoryBgColorMap[category])} />
          ) : (
            <EyeSlashIcon className='size-4' />
          )}
          <Text size='xs' weight={500}>
            {category}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default Legend
