import { Kanban as KanbanBoard } from './components'

const Opportunities = () => {
  return (
    <div className='h-[calc(100vh-190px)] text-center'>
      <KanbanBoard />
    </div>
  )
}

export default Opportunities
