import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useCallback } from 'react'

import { useGetProcessIdentifierMapperQuery } from '../../../app/apiSlice'
import { ContentDisplay, Stats } from '../../../common/components'
import { Button, Text } from '../../../common/designs'
import { useEnhancedNavigate } from '../../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import {
  getNameComponent,
  getNavigationInfo,
  getRecommendationDescription,
} from '../recommendationFieldUtils'
import type { RecommendationData } from '../types'

interface Props {
  recommendation: RecommendationData
}

const RecommendationMetricGrid = ({ recommendation }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  const { business_case_time_sec, business_case_event_count, throughput_time_sec, impact_score } =
    recommendation

  const metricsData = [
    { label: 'Active time', value: getBestTimeFormatForSeconds(business_case_time_sec) },
    { label: 'Event count', value: formatNumber(business_case_event_count) },
    { label: 'Duration', value: getBestTimeFormatForSeconds(throughput_time_sec) },
    { label: 'Impact score', value: formatNumber(impact_score, 1) },
  ]

  const { path, title } = getNavigationInfo(recommendation, processMapper)

  const onNavigate = useCallback(() => {
    navigate(`../../${path}`)
  }, [path, navigate])

  return (
    <div className='flex flex-col gap-3'>
      <ContentDisplay>
        <div className='flex w-full justify-between p-3'>
          <div>
            {getNameComponent(recommendation, processMapper)}
            {getRecommendationDescription(recommendation).map((row) => (
              <Text size='s' key={row}>
                {row}
              </Text>
            ))}
          </div>
          <div className='shrink-0'>
            <Button size='s' iconEnd={<ArrowRightIcon />} onClick={onNavigate}>
              {title}
            </Button>
          </div>
        </div>
      </ContentDisplay>
      <Stats data={metricsData} />
    </div>
  )
}

export default RecommendationMetricGrid

