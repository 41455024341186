import { useMemo, useRef, useState } from 'react'

import { BarChartAndTableDisplay } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import { Select, Text } from '../../../common/designs'
import { getBestTimeFormatTransformationForSeconds } from '../../../common/utils/numberFormatting'
import type { ConformanceTrendData } from '../types'

type trendMetricKeys =
  | 'conformance_percentage'
  | 'avg_throughput_time_sec'
  | 'avg_duration_sec'
  | 'avg_touch_count'

const TREND_SELECT_OPTIONS = [
  { value: 'conformance_percentage', label: 'Conformance %' },
  { value: 'avg_throughput_time_sec', label: 'Average duration' },
  { value: 'avg_duration_sec', label: 'Work per case' },
  { value: 'avg_touch_count', label: 'Touches per case' },
]

const VARIABLE_UNIT_MAPPER = {
  conformance_percentage: '%',
  avg_touch_count: 'Touches',
}

interface Props {
  data: ConformanceTrendData[]
}

const ConformanceTrends = ({ data }: Props) => {
  const [selectedVariable, setSelectedVariable] =
    useState<trendMetricKeys>('conformance_percentage')
  const chartUnit = useRef<string>('%')

  const chartData: ChartDataEntry[] = useMemo(() => {
    if (!data) return []

    let divider = 1
    let unit = null
    if (selectedVariable === 'avg_throughput_time_sec' || selectedVariable === 'avg_duration_sec') {
      const maxValue = Math.max(...(data.map((week) => week.metrics[selectedVariable]) || []))
      ;({ divider, unit } = getBestTimeFormatTransformationForSeconds(maxValue))
    } else {
      unit = VARIABLE_UNIT_MAPPER[selectedVariable as keyof typeof VARIABLE_UNIT_MAPPER]
    }

    chartUnit.current = unit
    return data.map((week) => ({
      label: `Week ${week.iso_week_number}`,
      value: week.metrics[selectedVariable] / divider,
    }))
  }, [data, selectedVariable])

  if (!data) return <Text variant='h2'>No data found</Text>

  return (
    <BarChartAndTableDisplay
      className='w-full'
      data={chartData}
      unit={chartUnit.current}
      label='Trends'
      tableHeaderValues={['Week', 'value']}
      selectActions={[
        <Select
          size='xs'
          options={TREND_SELECT_OPTIONS}
          value={selectedVariable}
          onChange={setSelectedVariable as React.Dispatch<React.SetStateAction<string>>}
          key={String(chartData)}
        />,
      ]}
      useReversedDataOrder
    />
  )
}

export default ConformanceTrends

