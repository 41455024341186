import type { PercentageValue } from '../../common/types/common'

export const shortLorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium velit sit amet lorem vehicula aliquet. Ut in vehicula dui. Integer dictum faucibus efficitur. In nec faucibus turpis, ac mattis sem.'
export const lorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium velit sit amet lorem vehicula aliquet. Ut in vehicula dui. Integer dictum faucibus efficitur. In nec faucibus turpis, ac mattis sem. Integer condimentum quam sit amet diam sollicitudin tincidunt. Curabitur in venenatis massa. Aliquam eu diam hendrerit libero vulputate vestibulum non non nisi. Curabitur eu consequat felis. Ut pulvinar egestas nunc a hendrerit. In hac habitasse platea dictumst. Aliquam a molestie orci. Curabitur eget lobortis erat, at mattis orci. Fusce id enim fermentum, iaculis ipsum ac, bibendum nibh.'
export const confirmLabel = 'Are you sure you want to do this action?'

export const tabsOptions = [
  {
    label: 'Get started',
    value: 'get-started',
  },
  {
    label: 'Recommendations',
    value: 'recommendations',
  },
  {
    label: 'Business apps',
    value: 'business-apps',
    disabled: true,
  },
  {
    label: 'Opportunities',
    value: 'opportunities',
  },
]

export const radiosOptions = [
  {
    name: 'First',
    value: 'first',
  },
  {
    name: 'Second',
    value: 'second',
  },
  {
    name: 'Third',
    value: 'third',
    disabled: true,
  },
  {
    name: 'Fourth',
    value: 'fourth',
  },
]

export const radiosOptions2 = [
  {
    name: 'Radio',
    value: 'radio1',
  },
  {
    name: 'Radio 2',
    value: 'radio2',
  },
  {
    name: 'Radio 3',
    value: 'radio3',
    disabled: true,
  },
  {
    name: 'Radio 4',
    value: 'radio4',
  },
]

export const checkboxOptions = [
  {
    label: 'Get started',
    value: 'get-started',
  },
  {
    label: 'Recommendations',
    value: 'recommendations',
  },
  {
    label: 'Business apps',
    value: 'business-apps',
    disabled: true,
  },
  {
    label: 'Opportunities',
    value: 'opportunities',
  },
]

export const tableData = [
  {
    first_name: 'Dianemarie',
    last_name: 'Glanfield',
    email: 'dglanfield0@illinois.edu',
    gender: 'Female',
  },
  {
    first_name: 'Huntington',
    last_name: 'Brimmicombe',
    email: 'hbrimmicombe1@icio.us',
    gender: 'Male',
  },
  {
    first_name: 'Dolores',
    last_name: 'Lorenzetti',
    email: 'dlorenzetti2@oracle.com',
    gender: 'Female',
  },
  {
    first_name: 'Diahann',
    last_name: 'Tomblin',
    email: 'dtomblin3@ucla.edu',
    gender: 'Female',
  },
  {
    first_name: 'Phaedra',
    last_name: 'Pipworth',
    email: 'ppipworth4@icio.us',
    gender: 'Female',
  },
  {
    first_name: 'Tam',
    last_name: 'Vondrasek',
    email: 'tvondrasek5@delicious.com',
    gender: 'Bigender',
  },
  {
    first_name: 'Emmerich',
    last_name: 'Gainsford',
    email: 'egainsford6@msu.edu',
    gender: 'Male',
  },
  {
    first_name: 'Rich',
    last_name: 'Kerwick',
    email: 'rkerwick7@samsung.com',
    gender: 'Male',
  },
  {
    first_name: 'Randi',
    last_name: 'Stienton',
    email: 'rstienton8@usatoday.com',
    gender: 'Female',
  },
  {
    first_name: 'Araldo',
    last_name: 'Stickney',
    email: 'astickney9@walmart.com',
    gender: 'Male',
  },
  {
    first_name: 'Karoly',
    last_name: 'Stamps',
    email: 'kstampsa@sciencedaily.com',
    gender: 'Bigender',
  },
  {
    first_name: 'Wynne',
    last_name: 'Chasney',
    email: 'wchasneyb@digg.com',
    gender: 'Female',
  },
  {
    first_name: 'Isabel',
    last_name: 'Smythe',
    email: 'ismythec@umich.edu',
    gender: 'Female',
  },
  {
    first_name: 'Meade',
    last_name: 'Gethins',
    email: 'mgethinsd@mapquest.com',
    gender: 'Female',
  },
  {
    first_name: 'Damien',
    last_name: 'McInility',
    email: 'dmcinilitye@apache.org',
    gender: 'Male',
  },
  {
    first_name: 'Odele',
    last_name: 'Tuny',
    email: 'otunyf@comcast.net',
    gender: 'Female',
  },
  {
    first_name: 'Charmine',
    last_name: 'Harrhy',
    email: 'charrhyg@epa.gov',
    gender: 'Non-binary',
  },
  {
    first_name: 'Sydney',
    last_name: 'Freddi',
    email: 'sfreddih@independent.co.uk',
    gender: 'Male',
  },
  {
    first_name: 'Town',
    last_name: 'Miller',
    email: 'tmilleri@1und1.de',
    gender: 'Male',
  },
  {
    first_name: 'Franzen',
    last_name: 'Gier',
    email: 'fgierj@webmd.com',
    gender: 'Male',
  },
  {
    first_name: 'Michal',
    last_name: 'McLarnon',
    email: 'mmclarnonk@tumblr.com',
    gender: 'Female',
  },
  {
    first_name: 'Dimitri',
    last_name: 'Brandham',
    email: 'dbrandhaml@noaa.gov',
    gender: 'Male',
  },
  {
    first_name: 'Thebault',
    last_name: 'Kermitt',
    email: 'tkermittm@nba.com',
    gender: 'Male',
  },
  {
    first_name: 'Lance',
    last_name: 'Aitcheson',
    email: 'laitchesonn@weebly.com',
    gender: 'Male',
  },
  {
    first_name: 'Donavon',
    last_name: 'Pilbeam',
    email: 'dpilbeamo@usnews.com',
    gender: 'Male',
  },
  {
    first_name: 'Khalil',
    last_name: 'Towle',
    email: 'ktowlep@flavors.me',
    gender: 'Male',
  },
  {
    first_name: 'Betty',
    last_name: 'Erdely',
    email: 'berdelyq@hugedomains.com',
    gender: 'Female',
  },
  {
    first_name: 'Salomon',
    last_name: 'Macartney',
    email: 'smacartneyr@ucoz.com',
    gender: 'Male',
  },
  {
    first_name: 'Yancey',
    last_name: 'Birtle',
    email: 'ybirtles@sbwire.com',
    gender: 'Male',
  },
  {
    first_name: 'Faythe',
    last_name: 'Cutbush',
    email: 'fcutbusht@smugmug.com',
    gender: 'Female',
  },
  {
    first_name: 'Tiertza',
    last_name: 'Koeppe',
    email: 'tkoeppeu@nhs.uk',
    gender: 'Female',
  },
  {
    first_name: 'Fidelity',
    last_name: 'Prando',
    email: 'fprandov@webmd.com',
    gender: 'Bigender',
  },
  {
    first_name: 'Sloane',
    last_name: 'Oldfield',
    email: 'soldfieldw@accuweather.com',
    gender: 'Male',
  },
  {
    first_name: 'Arnaldo',
    last_name: 'Heater',
    email: 'aheaterx@booking.com',
    gender: 'Male',
  },
  {
    first_name: 'Terry',
    last_name: 'Cundy',
    email: 'tcundyy@engadget.com',
    gender: 'Genderqueer',
  },
  {
    first_name: 'Faina',
    last_name: 'Christaeas',
    email: 'fchristaeasz@creativecommons.org',
    gender: 'Female',
  },
  {
    first_name: 'Odella',
    last_name: 'Yate',
    email: 'oyate10@example.com',
    gender: 'Female',
  },
  {
    first_name: 'Marietta',
    last_name: 'Garey',
    email: 'mgarey11@goodreads.com',
    gender: 'Male',
  },
  {
    first_name: 'Michail',
    last_name: 'Chasles',
    email: 'mchasles12@umich.edu',
    gender: 'Male',
  },
  {
    first_name: 'Claire',
    last_name: 'Goalley',
    email: 'cgoalley13@eepurl.com',
    gender: 'Male',
  },
  {
    first_name: 'Charlotta',
    last_name: 'Unstead',
    email: 'cunstead14@sourceforge.net',
    gender: 'Female',
  },
  {
    first_name: 'Lauren',
    last_name: 'Pottberry',
    email: 'lpottberry15@tinypic.com',
    gender: 'Female',
  },
  {
    first_name: 'Persis',
    last_name: 'Shatliffe',
    email: 'pshatliffe16@archive.org',
    gender: 'Female',
  },
  {
    first_name: 'Doralia',
    last_name: 'Slingsby',
    email: 'dslingsby17@dedecms.com',
    gender: 'Female',
  },
  {
    first_name: 'Puff',
    last_name: 'Fellona',
    email: 'pfellona18@ucla.edu',
    gender: 'Male',
  },
  {
    first_name: 'Artur',
    last_name: 'Mothersole',
    email: 'amothersole19@google.co.uk',
    gender: 'Male',
  },
  {
    first_name: 'Garreth',
    last_name: 'Wakenshaw',
    email: 'gwakenshaw1a@skyrock.com',
    gender: 'Bigender',
  },
  {
    first_name: 'Opalina',
    last_name: 'Tremathick',
    email: 'otremathick1b@ovh.net',
    gender: 'Female',
  },
  {
    first_name: 'Alfredo',
    last_name: 'Easun',
    email: 'aeasun1c@un.org',
    gender: 'Male',
  },
  {
    first_name: 'Fran',
    last_name: 'Beldan',
    email: 'fbeldan1d@noaa.gov',
    gender: 'Male',
  },
  {
    first_name: 'Udall',
    last_name: 'Renault',
    email: 'urenault1e@state.gov',
    gender: 'Male',
  },
  {
    first_name: 'Revkah',
    last_name: 'Gathwaite',
    email: 'rgathwaite1f@last.fm',
    gender: 'Female',
  },
  {
    first_name: 'Borden',
    last_name: 'Borg',
    email: 'bborg1g@pcworld.com',
    gender: 'Male',
  },
  {
    first_name: 'Kippy',
    last_name: 'Loader',
    email: 'kloader1h@wordpress.com',
    gender: 'Female',
  },
  {
    first_name: 'Mikey',
    last_name: 'Iskowitz',
    email: 'miskowitz1i@slate.com',
    gender: 'Male',
  },
  {
    first_name: 'Sherill',
    last_name: 'Cracie',
    email: 'scracie1j@addtoany.com',
    gender: 'Female',
  },
  {
    first_name: 'Charmion',
    last_name: 'Gurdon',
    email: 'cgurdon1k@so-net.ne.jp',
    gender: 'Female',
  },
  {
    first_name: 'Meara',
    last_name: 'Guerrier',
    email: 'mguerrier1l@blogs.com',
    gender: 'Female',
  },
  {
    first_name: 'Zsazsa',
    last_name: 'Mattocks',
    email: 'zmattocks1m@columbia.edu',
    gender: 'Female',
  },
  {
    first_name: 'Rafaello',
    last_name: 'Fitzsimons',
    email: 'rfitzsimons1n@forbes.com',
    gender: 'Male',
  },
  {
    first_name: 'Horatio',
    last_name: 'Keston',
    email: 'hkeston1o@dailymotion.com',
    gender: 'Male',
  },
  {
    first_name: 'Theodoric',
    last_name: 'Crut',
    email: 'tcrut1p@meetup.com',
    gender: 'Male',
  },
  {
    first_name: 'Luke',
    last_name: 'Jackalin',
    email: 'ljackalin1q@infoseek.co.jp',
    gender: 'Male',
  },
  {
    first_name: 'Quentin',
    last_name: 'Jephson',
    email: 'qjephson1r@cnn.com',
    gender: 'Male',
  },
  {
    first_name: 'Anna-maria',
    last_name: 'Growy',
    email: 'agrowy1s@plala.or.jp',
    gender: 'Female',
  },
  {
    first_name: 'Mycah',
    last_name: 'Hadlow',
    email: 'mhadlow1t@blinklist.com',
    gender: 'Male',
  },
  {
    first_name: 'Loralie',
    last_name: 'Bangham',
    email: 'lbangham1u@dmoz.org',
    gender: 'Female',
  },
  {
    first_name: 'Hephzibah',
    last_name: 'Cohrs',
    email: 'hcohrs1v@shop-pro.jp',
    gender: 'Female',
  },
  {
    first_name: 'Madlin',
    last_name: 'Rosenauer',
    email: 'mrosenauer1w@pcworld.com',
    gender: 'Female',
  },
  {
    first_name: 'Stinky',
    last_name: 'Dackombe',
    email: 'sdackombe1x@economist.com',
    gender: 'Male',
  },
  {
    first_name: 'Celia',
    last_name: 'Gilfoyle',
    email: 'cgilfoyle1y@gmpg.org',
    gender: 'Female',
  },
  {
    first_name: 'Andrus',
    last_name: 'Penelli',
    email: 'apenelli1z@jiathis.com',
    gender: 'Polygender',
  },
  {
    first_name: 'Rona',
    last_name: 'Grealey',
    email: 'rgrealey20@independent.co.uk',
    gender: 'Female',
  },
  {
    first_name: 'Huntlee',
    last_name: 'Ferier',
    email: 'hferier21@wp.com',
    gender: 'Male',
  },
  {
    first_name: 'Lemmy',
    last_name: 'Pringle',
    email: 'lpringle22@phoca.cz',
    gender: 'Male',
  },
  {
    first_name: 'Devora',
    last_name: 'Fairbourn',
    email: 'dfairbourn23@usgs.gov',
    gender: 'Female',
  },
  {
    first_name: 'Halette',
    last_name: 'Micah',
    email: 'hmicah24@squidoo.com',
    gender: 'Bigender',
  },
  {
    first_name: 'Miguela',
    last_name: 'Garey',
    email: 'mgarey25@upenn.edu',
    gender: 'Female',
  },
  {
    first_name: "D'arcy",
    last_name: 'Hammersley',
    email: 'dhammersley26@blinklist.com',
    gender: 'Polygender',
  },
  {
    first_name: 'Bondie',
    last_name: 'Grgic',
    email: 'bgrgic27@dagondesign.com',
    gender: 'Male',
  },
  {
    first_name: 'Neel',
    last_name: 'Huccaby',
    email: 'nhuccaby28@t.co',
    gender: 'Male',
  },
  {
    first_name: 'Ciro',
    last_name: 'McLeish',
    email: 'cmcleish29@marriott.com',
    gender: 'Male',
  },
  {
    first_name: 'Christye',
    last_name: 'Pickworth',
    email: 'cpickworth2a@buzzfeed.com',
    gender: 'Female',
  },
  {
    first_name: 'Hashim',
    last_name: 'Richard',
    email: 'hrichard2b@google.co.uk',
    gender: 'Male',
  },
  {
    first_name: 'Ceil',
    last_name: 'Lipprose',
    email: 'clipprose2c@hexun.com',
    gender: 'Female',
  },
  {
    first_name: 'Izabel',
    last_name: 'Veysey',
    email: 'iveysey2d@slashdot.org',
    gender: 'Female',
  },
  {
    first_name: 'Axel',
    last_name: 'Kendle',
    email: 'akendle2e@java.com',
    gender: 'Male',
  },
  {
    first_name: 'Cindelyn',
    last_name: 'McNiff',
    email: 'cmcniff2f@illinois.edu',
    gender: 'Female',
  },
  {
    first_name: 'Dyanne',
    last_name: 'Guillotin',
    email: 'dguillotin2g@bbc.co.uk',
    gender: 'Female',
  },
  {
    first_name: 'Jenelle',
    last_name: 'Wintringham',
    email: 'jwintringham2h@japanpost.jp',
    gender: 'Female',
  },
  {
    first_name: 'Guthry',
    last_name: 'Exter',
    email: 'gexter2i@youku.com',
    gender: 'Male',
  },
  {
    first_name: 'Martica',
    last_name: 'Tulley',
    email: 'mtulley2j@woothemes.com',
    gender: 'Female',
  },
  {
    first_name: 'Yanaton',
    last_name: 'Reddel',
    email: 'yreddel2k@infoseek.co.jp',
    gender: 'Male',
  },
  {
    first_name: 'Darbee',
    last_name: 'Grinyov',
    email: 'dgrinyov2l@hp.com',
    gender: 'Agender',
  },
  {
    first_name: 'Dom',
    last_name: 'Higgonet',
    email: 'dhiggonet2m@princeton.edu',
    gender: 'Male',
  },
  {
    first_name: 'Belita',
    last_name: 'Tate',
    email: 'btate2n@tamu.edu',
    gender: 'Female',
  },
  {
    first_name: 'Jacky',
    last_name: 'Long',
    email: 'jlong2o@wix.com',
    gender: 'Female',
  },
  {
    first_name: 'Juan',
    last_name: 'Angood',
    email: 'jangood2p@soundcloud.com',
    gender: 'Male',
  },
  {
    first_name: 'Adelind',
    last_name: 'Hoys',
    email: 'ahoys2q@telegraph.co.uk',
    gender: 'Female',
  },
  {
    first_name: 'Anthia',
    last_name: 'McCardle',
    email: 'amccardle2r@wix.com',
    gender: 'Female',
  },
]

export const chartData = [
  {
    label: 'SAP',
    value: 200,
    description:
      'SAP is the best ERP system out there. It has bunch windows and cool stuff you can do. You can also customize SAP to your needs.',
  },
  {
    label: 'Outlook',
    value: 160,
    description:
      'Outlook is creat emailing app. You can receive and send messages. That is so cool. Also you can delete the junk mail.',
  },
  {
    label: 'Excel',
    value: 146,
    description:
      'Excel, the king of spreadsheet application. Have you tried pivot table? Maybe we should do all our data analysis in Excel.',
  },
  {
    label: 'Salesforce',
    value: 122,
    description: "Salesforce has tickets. I don't know much about Salesforce.",
  },
  {
    label: 'Calendar',
    value: 105,
    description:
      'Calendar is for all your time management needs. Have a meeting? Add it to the calendar! Are you out of the office? Add it to the calendar!',
  },
  {
    label: 'PDF',
    value: 98,
    description: 'Okey I give up with unique descriptions',
  },
  {
    label: 'Koho',
    value: 80,
    description: 'Okey I give up with unique descriptions',
  },
  {
    label: 'Jira',
    value: 70,
    description: 'Okey I give up with unique descriptions',
  },
]

export const chartDataColors = [
  {
    label: 'SAP',
    value: 17.73,
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Outlook',
    value: 15.22,
    color: '#9061F9',
  },
  {
    label: 'Excel',
    value: 12.34,
    color: '#F8B4D9',
  },
  {
    label: 'Salesforce',
    value: 11.95,
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Calendar',
    value: 11.24,
    color: '#9061F9',
  },
  {
    label: 'PDF',
    value: 10.23,
    color: '#F8B4D9',
  },
  {
    label: 'Koho',
    value: 8.71,
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Jira',
    value: 7.23,
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Word',
    value: 5.83,
    color: '#F8B4D9',
  },
]

export const chartDataColorsLegend = [
  {
    label: 'Business apps',
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Office apps',
    color: '#9061F9',
  },
  {
    label: 'Documents',
    color: '#F8B4D9',
  },
]

export const chartLongLegend = [
  {
    label: 'Business apps',
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Office apps',
    color: '#9061F9',
  },
  {
    label: 'Documents',
    color: '#F8B4D9',
  },
  {
    label: 'Business apps 1',
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Business apps 2',
    color: '#9061F9',
  },
  {
    label: 'Business apps 3',
    color: '#F8B4D9',
  },
  {
    label: 'Business apps 4',
    color: 'hsla(243, 75%, 59%, 1)',
  },
  {
    label: 'Business apps 5',
    color: '#9061F9',
  },
  {
    label: 'Business apps 6',
    color: '#F8B4D9',
  },
]

export const groupedChartData = [
  {
    groupName: 'Week 46',
    data: [
      {
        label: 'SAP',
        value: 100,
      },
      {
        label: 'Outlook',
        value: 80,
      },
      {
        label: 'Excel',
        value: 73,
      },
      {
        label: 'Salesforce',
        value: 61,
      },
      {
        label: 'Calendar',
        value: 52,
      },
      {
        label: 'PDF',
        value: 49,
      },
      {
        label: 'Koho',
        value: 40,
      },
      {
        label: 'Jira',
        value: 35,
      },
    ],
  },
  {
    groupName: 'Week 47',
    data: [
      {
        label: 'SAP',
        value: 80,
      },
      {
        label: 'Outlook',
        value: 43,
      },
      {
        label: 'Excel',
        value: 50,
      },
      {
        label: 'Salesforce',
        value: 80,
      },
      {
        label: 'Calendar',
        value: 11,
      },
      {
        label: 'PDF',
        value: 46,
      },
      {
        label: 'Koho',
        value: 20,
      },
      {
        label: 'Jira',
        value: 30,
      },
    ],
  },
  {
    groupName: 'Week 48',
    data: [
      {
        label: 'SAP',
        value: 80,
      },
      {
        label: 'Outlook',
        value: 90,
      },
      {
        label: 'Excel',
        value: 65,
      },
      {
        label: 'Salesforce',
        value: 63,
      },
      {
        label: 'Calendar',
        value: 55,
      },
      {
        label: 'PDF',
        value: 44,
      },
      {
        label: 'Koho',
        value: 44,
      },
      {
        label: 'Jira',
        value: 39,
      },
    ],
  },
  {
    groupName: 'Week 49',
    data: [
      {
        label: 'SAP',
        value: 95,
      },
      {
        label: 'Outlook',
        value: 82,
      },
      {
        label: 'Excel',
        value: 80,
      },
      {
        label: 'Salesforce',
        value: 53,
      },
      {
        label: 'Calendar',
        value: 52,
      },
      {
        label: 'PDF',
        value: 55,
      },
      {
        label: 'Koho',
        value: 40,
      },
      {
        label: 'Jira',
        value: 38,
      },
    ],
  },
]

export const visualizationTableHead = ['Name', 'Category', 'Improvement potential', 'Work time']

export const visualizationTableBody = [
  ['Browsing • Procountor', 'Task', '686 814 €', '18.3 days'],
  ['Peeking • Procountor', 'Task', '549 763 €', '14.6 days'],
  ['Browsing • Koho', 'Task', '329 629 €', '8.8 days'],
  ['Browsing • Microsoft Excel', 'Task', '144 742 €', '3.9 days'],
  ['Manual text entry • Procountor', 'Task', '134 893 €', '3.6 days'],
  ['Text creation • Procountor', 'Task', '132 532 €', '3.5 days'],
]

export const visualizationTablePercentageValues = [91, 75, 42, 20, 18, 17] as PercentageValue[]

export const chartTableTabOptions = [
  { label: 'Chart', value: 'Chart' },
  { label: 'Table', value: 'Table' },
]
