import { DependencyList, useEffect, useRef } from 'react'

const useUpdateEffect = (callback: () => void, dependencies: DependencyList) => {
  const firstRenderRef = useRef(true)

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return undefined
    }

    return callback()
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useUpdateEffect
