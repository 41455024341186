import clsx from 'clsx'
import React from 'react'

type Size = 'xs' | 's' | 'm'

const sizeStyles: { [key in Size]: string } = {
  xs: 'py-1 text-xs text-gray-500',
  s: 'py-2 text-sm text-gray-500',
  m: 'py-4 text-sm text-gray-500',
}

interface Props {
  head: string[]
  body: Array<(string | number | React.ReactNode)[]>

  size?: Size
  className?: string
  label?: string
  description?: string | undefined
  action?: React.ReactNode | null
}

const Table = (props: Props) => {
  const {
    head,
    body,

    size = 'm',
    className,
    label,
    description,
    action,
  } = props

  return (
    <div className={className}>
      {(label || description || action) && (
        <div className='mx-8 my-4 flex items-center'>
          {(label || description) && (
            <div className='flex-auto'>
              {label && <h3 className='text-xl font-semibold text-gray-900'>{label}</h3>}

              {description && <p className='mt-2 text-sm text-gray-700'>{description}</p>}
            </div>
          )}

          {action && <div className='flex-none'>{action}</div>}
        </div>
      )}

      <div className='flex flex-col'>
        <div className='inline-block min-w-full px-4 py-2 align-middle'>
          <div className='rounded-md shadow ring-1 ring-black/5'>
            <table className='min-w-full border-separate' style={{ borderSpacing: 0 }}>
              <thead className='bg-gray-50'>
                <tr>
                  {head.map((name, i) => {
                    const isFirstCell = i === 0
                    const isLastCell = i === head.length - 1

                    return (
                      <th
                        key={name}
                        scope='col'
                        className={clsx(
                          isFirstCell
                            ? ' pl-4 pr-3 text-left lg:pl-8'
                            : isLastCell
                              ? 'pl-3 pr-4 text-right lg:pr-8'
                              : 'px-3 text-left',
                          'sticky top-0 z-10 whitespace-nowrap border-b border-gray-300 bg-gray-50/75 py-3.5 text-sm font-medium uppercase tracking-wide text-gray-500 backdrop-blur',
                        )}
                      >
                        {name}
                      </th>
                    )
                  })}
                </tr>
              </thead>

              <tbody className='bg-white'>
                {body.map((row, i) => {
                  const isEvenRow = i % 2 === 1
                  const isLastRow = i !== body.length - 1

                  return (
                    <tr key={`${row[0]}-${i}`} className={clsx(isEvenRow && 'bg-gray-50')}>
                      {row?.map((cell, j) => {
                        const isFirstCell = j === 0
                        const isLastCell = j === row.length - 1

                        return (
                          <td
                            key={`${cell}-${j}`}
                            className={clsx(
                              isLastRow && 'border-b border-gray-200',
                              isFirstCell
                                ? 'pl-4 pr-3 sm:pl-4 md:pl-6 lg:pl-8'
                                : isLastCell
                                  ? 'pl-3 pr-4 text-right sm:pr-4 md:pr-6 lg:pr-8'
                                  : 'px-3',
                              sizeStyles[size],
                              ' whitespace-nowrap text-gray-500',
                            )}
                          >
                            {cell}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table
