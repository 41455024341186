import { CaseView, MetricsView } from '.'
import { useAppSelector } from '../../../../common/hooks'
import { selectIsMetricOpen } from '../../casesSlice'

const CaseDetails = () => {
  const isMetricOpen = useAppSelector(selectIsMetricOpen)

  return (
    <div className='flex flex-col text-center'>{isMetricOpen ? <MetricsView /> : <CaseView />}</div>
  )
}

export default CaseDetails
