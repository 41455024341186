export enum GlobalPathEnum {
  ADMIN_PANEL = 'admin',
  SIGN_IN = 'sign-in',
  AUTOMATIC_SIGN_IN = 'automatic-sign-in',
  NOT_FOUND = 'not-found',
  APP_ERROR = 'app-error',
  SANDBOX = 'sandbox',
}

export enum MainPathEnum {
  START = 'start',
  DATA_FLOWS = 'data-flows',
  BUSINESS_APPS = 'business-apps',
  PROCESSES = 'processes',
  OPPORTUNITIES = 'opportunities',
  FAVORITES = 'favorites',

  DEFAULT = 'start',
}

export enum SubPathEnum {
  RECOMMENDATIONS = 'recommendations',
  TRENDS = 'trends',
  TEAMS = 'teams',
  ACTIVITIES = 'activities',
  NAVIGATIONS_SANKEY = 'navigations-sankey', // ! DELETE
  NAVIGATIONS = 'navigations',
  COPY_PASTES = 'copy-pastes',
  OVERVIEW = 'overview',
  TASK_DISCOVERY = 'task-discovery',
  PREDEFINED_TASKS = 'predefined-tasks',
  VARIANTS = 'variants',
  INSIGHTS = 'insights',
  ANALYSIS = 'analysis',
  CONFORMANCE = 'conformance',
  USER_ACTIVITIES = 'user-activities',
  TASK_VARIANTS = 'task-variants',
  ROADMAP = 'roadmap',
  PRIVATE = 'private',
}

export enum AdminMainPathEnum {
  ROOT = '/admin',
  GET_STARTED = '/admin/get-started',

  INSTALLATION_CENTER = '/admin/installation-center',

  DATA_COLLECTION = '/admin/data-collection',
  PROCESS = '/admin/process',
  APP_CATALOG = '/admin/config-studio/app-catalog',
  DATA_DELETION = '/admin/data-deletion',

  OPPORTUNITIES = '/admin/opportunities',

  COMPANY = '/admin/company',
  USERS = '/admin/users',

  TEAM_GROUPS = '/admin/team-groups',

  MONITORING_OVERVIEW = '/admin/monitoring-overview',
  AGENTS = '/admin/agents',
  PERFORMANCE = '/admin/performance',
}

export enum MainPathTitleEnum {
  BUSINESS_APPS = 'Business apps',
}

