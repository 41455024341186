import { useMemo } from 'react'
import { useParams } from 'react-router'

import { useGetComputerCountDataQuery } from '../../../app/apiSlice'
import { USE_CURRENCY_SYMBOL_UNIT } from '../../../app/constants'
import { Stats } from '../../../common/components'
import {
  useCostUtils,
  useDashboard,
  useFilteredWindowUsageQuery,
  useQueryFilters,
} from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import { formatBuszAppData } from '../../../common/utils/dataFormatting'
import { decompressAndDecodeJSON } from '../../../common/utils/jsonCompression'
import {
  formatNumber,
  fractionToPercentage,
  getBestTimeFormatForSeconds,
} from '../../../common/utils/numberFormatting'
import type { WindowUsageData } from '../types'
import { getAppNameFromContentTags, getWindowNameFromContentTags } from '../utils'

const TimeStats = () => {
  const { tags_of_interest, min_date_iso, max_date_iso, team_ids } = useQueryFilters()
  const { encodedAppTags, encodedWindowTags } = useParams()
  const { company } = useDashboard()
  const { formatCost } = useCostUtils()

  const { tagFilters, appName, windowName } = useMemo(() => {
    const tagFilters = decompressAndDecodeJSON<Tag[]>(
      (encodedWindowTags ?? encodedAppTags) as string,
    )
    const appName = getAppNameFromContentTags(tagFilters)
    const windowName = tagFilters && getWindowNameFromContentTags(tagFilters)
    return { tagFilters, appName, windowName }
  }, [encodedWindowTags, encodedAppTags])

  const { data } = useFilteredWindowUsageQuery({
    tagsOfInterestSource: 'window',
    variables: { tag_filters: tagFilters, use_exact_tag_filter_match: true },
  })
  const { data: dailyComputerCountSum } = useGetComputerCountDataQuery({
    team_ids,
    start_timestamp: min_date_iso,
    end_timestamp: max_date_iso,
  })

  const statData = useMemo(() => {
    const dataList = data as WindowUsageData[]
    const timeH = dataList?.reduce((sum: number, val: any) => sum + val.time_h, 0) ?? 0
    const [teams, annualCost, visits] = dataList ? formatBuszAppData(dataList) : []

    const favoriteUrl = '/business-apps/window-usage'
    const favoriteQueryOptions = {
      tags_of_interest: tags_of_interest.application,
      tag_filters: tagFilters,
      group_by_date: true,
    }
    const dailyHours = dailyComputerCountSum ? timeH / dailyComputerCountSum : 0

    return [
      [
        {
          value: formatCost(annualCost),
          label: 'Annualized cost',
          params: {
            url: favoriteUrl,
            dataKey: 'annual_cost',
            queryOptions: favoriteQueryOptions,
            label: `${appName} | ${windowName} annualized cost`,
            unit: USE_CURRENCY_SYMBOL_UNIT,
          },
        },
        {
          value: formatNumber(timeH, 1),
          label: 'Total hours',
          params: {
            url: favoriteUrl,
            dataKey: 'time_h',
            queryOptions: favoriteQueryOptions,
            label: `Hours in ${appName} | ${windowName}`,
            unit: ' hours',
          },
        },
        {
          value: formatNumber(dailyHours, 10),
          label: 'Daily hours per computer',
        },
      ],
      [
        {
          value: formatNumber(visits, 1) ?? '',
          label: 'Total visits',
          params: {
            url: favoriteUrl,
            dataKey: 'visits',
            queryOptions: favoriteQueryOptions,
            label: `Visits in ${appName} | ${windowName}`,
            unit: ' visits',
          },
        },
        {
          value: getBestTimeFormatForSeconds((timeH * 3600) / visits) ?? '',
          label: 'Avg. visit time',
        },
        {
          value: teams ?? '',
          label: 'Teams',
          description: `${fractionToPercentage(teams / company.teamCount)} of total`,
          params: {
            url: favoriteUrl,
            dataKey: 'team_count',
            queryOptions: favoriteQueryOptions,
            label: `Teams using ${appName} | ${windowName}`,
            unit: ' teams',
          },
        },
      ],
    ]
  }, [
    formatCost,
    data,
    appName,
    windowName,
    tags_of_interest.application,
    tagFilters,
    company.teamCount,
    dailyComputerCountSum,
  ])

  return (
    <div className='flex flex-col gap-2'>
      <Stats data={statData[0]} />
      <Stats data={statData[1]} />
    </div>
  )
}

export default TimeStats
