import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { GroupedBarChartDataEntry } from '../../../common/components/Charts/types'
import GroupedBarChartAndTableDisplay from '../../../common/components/displays/GroupedBarChartAndTableDisplay'
import { Select } from '../../../common/designs'
import { useEnhancedSearchParams } from '../../../common/hooks'
import { SubSearchParamEnum } from '../../../common/types/searchParams'
import { compressAndEncodeJSON } from '../../../common/utils/jsonCompression'
import type { GroupedWindowUsageData } from '../types'

enum DataVariant {
  DEFAULT = 'default',
  RELATIVE = 'relative',
}

const dataVariantOptions = [
  { label: 'Total data', value: DataVariant.DEFAULT },
  { label: 'Relative data', value: DataVariant.RELATIVE },
]

interface Props {
  data: GroupedWindowUsageData | undefined
  chartDataDefault: GroupedBarChartDataEntry[]
  chartDataRelative: GroupedBarChartDataEntry[]
  chartTitle: string
  relativeChartTitle: string
  onChartClick: (compressedAndEncodedTags: string) => void
}

const TrendsChartView = (props: Props) => {
  const {
    data,
    chartDataDefault,
    chartDataRelative,
    chartTitle,
    relativeChartTitle,
    onChartClick,
  } = props
  const { updateSearchParam } = useEnhancedSearchParams()
  const [searchParams] = useSearchParams()

  const [activeDataVariant, setActiveDataVariant] = useState<DataVariant>(DataVariant.DEFAULT)

  // Update state when URL search params change.
  useEffect(() => {
    const mode = searchParams.get(SubSearchParamEnum.DATA_VARIANT) as DataVariant
    setActiveDataVariant(mode ?? DataVariant.DEFAULT)
  }, [searchParams, setActiveDataVariant])

  // Update URL search params when visualization mode is changed.
  const onChangeActiveView = useCallback(
    (val: string) => updateSearchParam(SubSearchParamEnum.DATA_VARIANT, val),
    [updateSearchParam],
  )

  const onClick = (_: string, label: string) => {
    const totalsData = data?.totals
    if (!totalsData) return

    const clickedEntry = totalsData.find((d) => d.name === label)
    if (!clickedEntry) return

    const compressedAndEncodedDataTags = compressAndEncodeJSON(clickedEntry.tags)
    onChartClick(compressedAndEncodedDataTags)
  }

  return (
    <GroupedBarChartAndTableDisplay
      label={activeDataVariant === DataVariant.RELATIVE ? relativeChartTitle : chartTitle}
      data={activeDataVariant === DataVariant.RELATIVE ? chartDataRelative : chartDataDefault}
      unit={activeDataVariant === DataVariant.RELATIVE ? '%' : 'h'}
      onClick={onClick}
      selectActions={[
        <Select
          key='app-activity-select'
          size='xs'
          options={dataVariantOptions}
          value={
            activeDataVariant === DataVariant.RELATIVE
              ? dataVariantOptions[1].value
              : dataVariantOptions[0].value
          }
          onChange={onChangeActiveView}
        />,
      ]}
    />
  )
}

export default TrendsChartView
