import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useEnhancedSearchParams } from '../../../../common/hooks'
import { SubSearchParamEnum } from '../../../../common/types/searchParams'

export const useModalConfigurations = (): {
  selectedStepName: string | null
  isStepModalOpen: boolean
  closeStepModal: () => void
  setStepModalStepName: (stepName: string) => void

  selectedStateName: string | null
  isStateModalOpen: boolean
  closeStateModal: () => void
  setStateModalStateName: (stepName: string) => void
} => {
  const [searchParams] = useSearchParams()
  const { updateSearchParam, deleteSearchParam } = useEnhancedSearchParams()

  const selectedStepName = searchParams.get(SubSearchParamEnum.SELECTED_STEP)
  const isStepModalOpen = Boolean(selectedStepName)

  const closeStepModal = useCallback(() => {
    deleteSearchParam(SubSearchParamEnum.SELECTED_STEP)
  }, [deleteSearchParam])

  const setStepModalStepName = useCallback(
    (stepName: string) => {
      updateSearchParam(SubSearchParamEnum.SELECTED_STEP, stepName)
    },
    [updateSearchParam],
  )

  const selectedStateName = searchParams.get(SubSearchParamEnum.SELECTED_STATE)
  const isStateModalOpen = Boolean(selectedStateName)

  const closeStateModal = useCallback(() => {
    deleteSearchParam(SubSearchParamEnum.SELECTED_STATE)
  }, [deleteSearchParam])

  const setStateModalStateName = useCallback(
    (stateName: string) => {
      updateSearchParam(SubSearchParamEnum.SELECTED_STATE, stateName)
    },
    [updateSearchParam],
  )

  return {
    selectedStepName,
    isStepModalOpen,
    closeStepModal,
    setStepModalStepName,
    selectedStateName,
    isStateModalOpen,
    closeStateModal,
    setStateModalStateName,
  }
}
