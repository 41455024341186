import { useGetStepAnalysisQuery, useGetStepMetricsQuery } from '../../../../../app/apiSlice'
import { ContentDisplay, HorizontalBar, Stat } from '../../../../../common/components'
import { Text } from '../../../../../common/designs'
import { useQueryFilters } from '../../../../../common/hooks'
import {
  formatNumber,
  getBestTimeFormatForSeconds,
} from '../../../../../common/utils/numberFormatting'
import { StepGroupByFieldTypeEnum, StepMetricNamesEnum } from './types'

const StepMetrics = () => {
  const { processFiltersWithZooming, processId } = useQueryFilters()

  const { data: metricData, isFetching: isFetchingMetricData } = useGetStepMetricsQuery(
    {
      processId,
      filters: processFiltersWithZooming,
      metrics: [
        StepMetricNamesEnum.TOTAL_TRANSACTIONS,
        StepMetricNamesEnum.TOTAL_STEP_VISITS,
        StepMetricNamesEnum.STEP_VISITS_PER_TRANSACTION,
        StepMetricNamesEnum.AVG_DURATION_SEC,
        StepMetricNamesEnum.AVG_TIME_FROM_PREV_STEP_SEC,
        StepMetricNamesEnum.AVG_COPY_COUNT,
        StepMetricNamesEnum.AVG_PASTE_COUNT,
        StepMetricNamesEnum.AVG_KEYBOARD_PRESS_COUNT,
        StepMetricNamesEnum.AVG_MOUSE_CLICK_COUNT,
      ],
    },
    { skip: !processFiltersWithZooming.zooming_filters },
  )
  const { data: workTypeData, isFetching: isFetchingWorkType } = useGetStepAnalysisQuery(
    {
      processId,
      filters: processFiltersWithZooming,
      group_by: { field_type: StepGroupByFieldTypeEnum.STEP_WORK_TYPE },
      metrics: [StepMetricNamesEnum.DURATION_SEC, StepMetricNamesEnum.TOTAL_STEP_VISITS],
    },
    { skip: !processFiltersWithZooming.zooming_filters },
  )

  const usageStatData = [
    {
      label: 'Total cases',
      value: formatNumber(metricData?.[StepMetricNamesEnum.TOTAL_TRANSACTIONS] ?? 0),
    },
    {
      label: 'Visits to task',
      value: formatNumber(metricData?.[StepMetricNamesEnum.TOTAL_STEP_VISITS] ?? 0),
    },
    {
      label: 'Task visits per case',
      value: formatNumber(metricData?.[StepMetricNamesEnum.STEP_VISITS_PER_TRANSACTION] ?? 0, 10),
    },
  ]

  const timeStatData = [
    {
      label: 'Avg. visit time',
      value: getBestTimeFormatForSeconds(metricData?.[StepMetricNamesEnum.AVG_DURATION_SEC] ?? 0),
    },
    {
      label: 'Avg. lead time to task',
      value: getBestTimeFormatForSeconds(
        metricData?.[StepMetricNamesEnum.AVG_TIME_FROM_PREV_STEP_SEC] ?? 0,
      ),
    },
  ]

  const workTypeVisualizationDataTime = workTypeData?.[StepMetricNamesEnum.DURATION_SEC]
    ?.map(({ value, label }) => ({
      value: value / 3600,
      label: label as string,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
  const workTypeVisualizationDataVisits = workTypeData?.[StepMetricNamesEnum.TOTAL_STEP_VISITS]
    ?.map(({ value, label }) => ({
      value: value,
      label: label as string,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const activityStatData = [
    {
      label: 'Avg. copies',
      value: formatNumber(metricData?.[StepMetricNamesEnum.AVG_COPY_COUNT] ?? 0),
    },
    {
      label: 'Avg. pastes',
      value: formatNumber(metricData?.[StepMetricNamesEnum.AVG_PASTE_COUNT] ?? 0),
    },
    {
      label: 'Avg. key presses',
      value: formatNumber(metricData?.[StepMetricNamesEnum.AVG_KEYBOARD_PRESS_COUNT] ?? 0),
    },
    {
      label: 'Avg. mouse clicks',
      value: formatNumber(metricData?.[StepMetricNamesEnum.AVG_MOUSE_CLICK_COUNT] ?? 0),
    },
  ]

  return (
    <div className='flex flex-col gap-6 py-4'>
      <div className='flex flex-col gap-2'>
        <Text size='l' weight={600} className='ml-1'>
          Task visits
        </Text>
        <div className='grid grid-cols-3 gap-6'>
          {usageStatData.map((data) => (
            <Stat
              data={data}
              isLoading={isFetchingMetricData}
              key={`${data.label}-${data.value}`}
            />
          ))}
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <Text size='l' weight={600} className='ml-1'>
          Time
        </Text>
        <div className='grid grid-cols-3 gap-6'>
          {timeStatData.map((data) => (
            <Stat
              data={data}
              isLoading={isFetchingMetricData}
              key={`${data.label}-${data.value}`}
            />
          ))}
        </div>
      </div>

      <Text size='l' weight={600} className='ml-1'>
        Task work types
      </Text>
      <ContentDisplay className='flex w-full flex-col gap-4 px-4 pb-3 pt-6'>
        <div>
          <Text weight={500} className='ml-1'>
            Time by work type
          </Text>
          <HorizontalBar
            data={workTypeVisualizationDataTime || []}
            unit='hours'
            showLabels
            isLoading={isFetchingWorkType}
          />
        </div>
        <div>
          <Text weight={500} className='ml-1'>
            Total task visits by work type
          </Text>
          <HorizontalBar
            data={workTypeVisualizationDataVisits || []}
            unit='visits'
            showLabels
            isLoading={isFetchingWorkType}
          />
        </div>
      </ContentDisplay>

      <div className='flex flex-col gap-2'>
        <Text size='l' weight={600} className='ml-1'>
          Activities
        </Text>
        <div className='grid grid-cols-4 gap-6'>
          {activityStatData.map((data) => (
            <Stat
              data={data}
              isLoading={isFetchingMetricData}
              key={`${data.label}-${data.value}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepMetrics

