import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'

interface Props {
  children: React.ReactNode
}

export const DefaultTransistion = ({ children }: Props) => {
  return (
    <Transition
      as={Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      {children}
    </Transition>
  )
}

interface DrawerFocusTransitionProps {
  hasNoMainNavigationOverlap?: boolean | undefined // Adds margin to the top of the focus to avoid overlap with the main navigation and lower opacity.
}

export const DrawerFocusTransition = (props: DrawerFocusTransitionProps) => {
  const { hasNoMainNavigationOverlap } = props

  return (
    <Transition.Child
      as={Fragment}
      enter='ease-in-out duration-500'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='ease-in-out duration-500'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div
        className={clsx(
          hasNoMainNavigationOverlap ? 'mt-52 bg-gray-500/40 lg:mt-40' : 'bg-gray-500/60',
          'fixed inset-0 transition-opacity',
        )}
      />
    </Transition.Child>
  )
}

export const DrawerTransition = ({ children }: Props) => {
  return (
    <Transition.Child
      as={Fragment}
      enter='transform transition ease-in-out duration-500'
      enterFrom='translate-x-full'
      enterTo='translate-x-0'
      leave='transform transition ease-in-out duration-500'
      leaveFrom='translate-x-0'
      leaveTo='translate-x-full'
    >
      {children}
    </Transition.Child>
  )
}
