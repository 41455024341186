import { useGetWindowNamesForAppsQuery } from '../../app/apiSlice'
import { useQueryFilters } from './'

const useGetWindowNamesForApps = () => {
  const { min_date, max_date, team_ids } = useQueryFilters()

  return useGetWindowNamesForAppsQuery({ min_date, max_date, team_ids })
}

export default useGetWindowNamesForApps
