import { memo } from 'react'
import { BaseEdge, EdgeLabelRenderer, MarkerType, getBezierPath } from 'reactflow'
import type { EdgeMarker, EdgeProps } from 'reactflow'

import { PRIMARY_600_HEX } from '../../../../app/theme'
import { Text } from '../../../../common/designs'
import { useAppSelector } from '../../../../common/hooks'
import { selectHighlightSelections } from '../../processesSlice'
import { HighlightFieldEnum } from '../../types'
import { ServerEdge } from './types'

const defaultEdgeStyle = { stroke: PRIMARY_600_HEX }

export const defaultEdgeArrowStyle: EdgeMarker = {
  type: MarkerType.ArrowClosed,
  color: PRIMARY_600_HEX,
}

const DefaultEdge = ({ data, ...rest }: EdgeProps<ServerEdge>) => {
  const { activeHighlighField } = useAppSelector(selectHighlightSelections)
  const relativeField =
    activeHighlighField === HighlightFieldEnum.TRANSACTIONS
      ? 'relative_share_of_transactions'
      : 'relative_average_time_sec'
  const edgeWidthMultiplier = data?.[relativeField] || 0
  const styleProps = {
    labelBgPadding: [8, 4] as [number, number],
    labelBgBorderRadius: 8,
    style: { ...defaultEdgeStyle, strokeWidth: `${1 + edgeWidthMultiplier * 8}px` },
  }

  const [edgePath, labelX, labelY] = getBezierPath({
    ...rest,
    curvature: 2,
  })

  const props = { ...rest, ...styleProps } as EdgeProps

  return (
    <>
      <BaseEdge {...props} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className='rounded bg-primary-600 px-2 py-0.5'
        >
          <Text color='white' size='s'>
            {rest.label}
          </Text>
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default memo(DefaultEdge)

// FilterDrawer.displayName = 'DefaultEdge'
