import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetConfigTestReportQuery,
  useGetDashboardAppDataQuery,
  useGetDataCollectionConfigsQuery,
  useGetTestCasesQuery,
} from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { ApplicationConfigPage } from '../../common/components/ApplicationConfigPage'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Breadcrumbs, Divider } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { getAppListFromTestCases } from './dataHelpers'

const ConfigureApp = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { encodedAppName } = useParams()

  const [appConfigs, setAppConfigs] = useState<AppTestCaseGroup | null>(null)

  const { data: ruleData, isLoading: isLoadingConfigFile } = useGetDataCollectionConfigsQuery()
  const { data: configFileTestReport, isLoading: isLoadingConfigFileReport } =
    useGetConfigTestReportQuery()
  const { data: requirementsData } = useGetTestCasesQuery()
  const { data: applicationMetaData } = useGetDashboardAppDataQuery()

  useEffect(() => {
    if (!encodedAppName || !requirementsData || !applicationMetaData || !configFileTestReport)
      return

    const { appData } = getAppListFromTestCases(
      requirementsData?.test_cases ?? [],
      applicationMetaData ?? [],
      ruleData?.rules ?? [],
      configFileTestReport,
    )
    const dataForThisApp = appData.find((app) => app.appName === encodedAppName)
    if (dataForThisApp) setAppConfigs(dataForThisApp)
  }, [requirementsData, ruleData, applicationMetaData, encodedAppName, configFileTestReport])

  const onAddNewWindow = (windowName: string) => {
    if (!appConfigs) {
      return
    }

    const appConfigsCopy = { ...appConfigs }
    const windowLevelData = [
      {
        windowName,
        testCases: [],
        rulesForTestCases: [],
        failingTestCaseIds: [],
      },
      ...appConfigsCopy.windowLevelData,
    ]
    appConfigsCopy.windowLevelData = windowLevelData
    setAppConfigs(appConfigsCopy)
  }

  if (isLoadingConfigFileReport || isLoadingConfigFile || !appConfigs) return <Loader />

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('..'),
          },
          { label: appConfigs.appName },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      <ApplicationConfigPage
        isLoading={isLoadingConfigFileReport || isLoadingConfigFile}
        appTestCasesGroup={appConfigs}
        onAddNewWindow={onAddNewWindow}
      />
    </>
  )
}

export default ConfigureApp
