// import { useParams } from 'react-router-dom'
import { Button, InputField, Text } from '../../common/designs'

const IngestionAPI = () => {
  // const { processId } = useParams()

  return (
    <div className='space-y-2 p-4'>
      <Text size='l' weight={500}>
        SAP Signavio Ingestion API
      </Text>

      <form className='space-y-3'>
        <InputField label='Ingestion API endpoint' />
        <InputField label='Ingestion API Token' />
        <Button disabled>Send Process Data</Button>
      </form>
    </div>
  )
}

export default IngestionAPI
