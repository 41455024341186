import { formatISO, sub } from 'date-fns'

import { useGetPluginPingsStatusQuery } from '../../../app/apiSlice'
import { CHART_NEUTRAL_HSLA, CHART_PRIMARY_HSLA } from '../../../app/theme'
import { useDashboard } from '../../../common/hooks'
import { GranularityEnum, OrganizationLevelEnum } from '../../../common/types/monitoring'
import { AdminMainPathEnum } from '../../../common/types/routing'
import { fractionToPercentage } from '../../../common/utils/numberFormatting'
import { generateCustomIndexesArrayUntil } from '../../../common/utils/utils'
import StatusCard from './StatusCard'

const TeamStatus = () => {
  const { teams } = useDashboard()

  const { data: rawData, isLoading } = useGetPluginPingsStatusQuery({
    start_timestamp: formatISO(sub(new Date().setHours(0, 0, 0, 0), { days: 6 })),
    end_timestamp: formatISO(new Date().setHours(0, 0, 0, 0)),
    granularity: GranularityEnum.DAY,
    organization_level: OrganizationLevelEnum.TEAM,
  })

  const uniqueTeamIds = [...new Set(rawData?.map((ping) => ping.team_id))] as string[]
  const currentTeamCount = uniqueTeamIds.length
  const maxTeamCount = Object.keys(teams).length
  const areTeamsRemaining = currentTeamCount !== maxTeamCount

  const labels = uniqueTeamIds.map((tId) => teams[tId]?.name ?? '')
  if (areTeamsRemaining) labels.push('Teams remaining')

  const chartData = {
    labels,
    datasets: [
      {
        data: [
          ...generateCustomIndexesArrayUntil(currentTeamCount, 1),
          areTeamsRemaining ? maxTeamCount - currentTeamCount : 0,
        ],
        backgroundColor: [
          ...uniqueTeamIds.map(() => CHART_PRIMARY_HSLA),
          areTeamsRemaining ? CHART_NEUTRAL_HSLA : '',
        ],
      },
    ],
  }

  return (
    <StatusCard
      chartData={chartData}
      titleProps={{
        label: 'Connected teams',
        value: fractionToPercentage(currentTeamCount / maxTeamCount),
      }}
      descriptionProps={{ label: 'Last 7 days', value: `${currentTeamCount}/${maxTeamCount}` }}
      navProps={{
        label: 'Add new teams in Admin panel',
        link: AdminMainPathEnum.INSTALLATION_CENTER,
      }}
      isLoading={isLoading}
    />
  )
}

export default TeamStatus
