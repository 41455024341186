import clsx from 'clsx'

const sizeStyles: { [key in Size]: string } = {
  xs: 'text-xs',
  s: 'text-sm',
  m: 'text-base',
  l: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  '6xl': 'text-6xl',
}

const defaultStyles: { [key in Variant]: string } = {
  p: 'text-base', //
  span: 'text-base',
  h6: 'text-xl',
  h5: 'text-2xl',
  h4: 'text-3xl',
  h3: 'text-4xl',
  h2: 'text-5xl',
  h1: 'text-6xl',
}

const colorStyles: { [key in Color]: string } = {
  darkGray: 'text-gray-900',
  gray: 'text-gray-700',
  lightGray: 'text-gray-500',
  lighterGray: 'text-gray-400',
  lightestGray: 'text-gray-300',
  white: 'text-white',
  primary: 'text-primary-600',
  error: 'text-red-600',
}

const weightStyles: { [key in Weight]: string } = {
  400: '!font-normal',
  500: '!font-medium',
  600: '!font-semibold',
  700: '!font-bold',
  800: '!font-extrabold',
  900: '!font-black',
}

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl'
type Color =
  | 'darkGray'
  | 'gray'
  | 'lightGray'
  | 'lighterGray'
  | 'lightestGray'
  | 'white'
  | 'primary'
  | 'error'
type Weight = 400 | 500 | 600 | 700 | 800 | 900

interface Props {
  variant?: Variant
  size?: Size
  children?: React.ReactNode
  className?: string
  color?: Color
  weight?: Weight
}

const Text = (props: Props) => {
  const { variant = 'p', size, children, className, color = 'darkGray', weight = 400 } = props

  const classes = clsx(
    'font-inter',
    size ? sizeStyles[size] : defaultStyles[variant],
    colorStyles[color],
    weightStyles[weight],
    className,
  )

  switch (variant) {
    case 'h1':
      return <h1 className={classes}>{children}</h1>

    case 'h2':
      return <h2 className={classes}>{children}</h2>

    case 'h3':
      return <h3 className={classes}>{children}</h3>

    case 'h4':
      return <h4 className={classes}>{children}</h4>

    case 'h5':
      return <h5 className={classes}>{children}</h5>

    case 'h6':
      return <h6 className={classes}>{children}</h6>

    case 'p':
      return <p className={classes}>{children}</p>

    case 'span':
      return <span className={classes}>{children}</span>
  }
}

export default Text
