import { useRoutes } from 'react-router-dom'

import { useDashboard } from '../../common/hooks'
import { SubPathEnum } from '../../common/types/routing'
import { PredefinedTasks, TaskDiscoveryLayout, TaskListView, TaskVariants } from '../TaskDiscovery'
import ProcessList from './ProcessList/ProcessList'
import {
  Analysis,
  Conformance,
  Insights,
  ProcessOverview,
  ProcessOverviewPlatformViewer,
  TrackedProcessLayout,
  Trends,
  UserActivities,
  Variants,
} from './ProcessMining'
import { AnalysisRoutes } from './ProcessMining/Analysis/constants'
import { VariantViewRoutesEnum } from './ProcessMining/Variants/enums'

const ProcessesRouting = () => {
  const {
    user: { isPlatformViewer },
  } = useDashboard()

  const viewMapper = {
    [SubPathEnum.OVERVIEW]: isPlatformViewer ? (
      <ProcessOverviewPlatformViewer />
    ) : (
      <ProcessOverview />
    ),
  }

  return useRoutes([
    {
      children: [
        {
          index: true,
          element: <ProcessList />,
          id: 'processes_index',
        },
        {
          path: SubPathEnum.TASK_DISCOVERY,
          element: <TaskDiscoveryLayout />,
          id: 'task_discovery',
          children: [
            {
              index: true,
              element: <TaskListView />,
              id: 'task_discovery_index',
            },
            {
              path: SubPathEnum.PREDEFINED_TASKS,
              element: <PredefinedTasks />,
              id: 'task_discovery_predefined_tasks',
            },
          ],
        },
      ],
    },
    {
      path: `${SubPathEnum.TASK_DISCOVERY}/:taskName`,
      children: [
        {
          path: SubPathEnum.TASK_VARIANTS,
          element: <TaskVariants />,
          id: 'task_discovery_variants',
        },
      ],
    },
    {
      path: ':trackedProcessId',
      element: <TrackedProcessLayout />,
      children: [
        {
          path: SubPathEnum.OVERVIEW,
          element: viewMapper[SubPathEnum.OVERVIEW],
          id: 'processes_tracked_overview',
        },
        {
          path: SubPathEnum.VARIANTS,
          element: <Variants.SecondLayerVariantsLayout />,
          children: [
            {
              index: true,
              element: (
                <Variants.VariantVisualization activeRoute={VariantViewRoutesEnum.STEP_FLOWS} />
              ),
              id: 'processes_tracked_variants_index',
            },
            {
              path: VariantViewRoutesEnum.TEAM_FLOWS,
              element: (
                <Variants.VariantVisualization activeRoute={VariantViewRoutesEnum.TEAM_FLOWS} />
              ),
              id: 'processes_tracked_variants_team_flows',
            },
            {
              path: VariantViewRoutesEnum.STATE_FLOWS,
              element: (
                <Variants.VariantVisualization activeRoute={VariantViewRoutesEnum.STATE_FLOWS} />
              ),
              id: 'processes_tracked_variants_state_flows',
            },
          ],
        },
        { path: SubPathEnum.INSIGHTS, element: <Insights />, id: 'processes_tracked_insights' },
        {
          path: SubPathEnum.ANALYSIS,
          element: <Analysis.SecondLayerAnalysisLayout />,
          children: [
            {
              path: AnalysisRoutes.TEAMS,
              element: <Analysis.TeamAnalysis />,
              id: 'processes_tracked_analysis_teams',
            },
            {
              path: AnalysisRoutes.STEPS,
              element: <Analysis.StepAnalysis />,
              id: 'processes_tracked_analysis_steps',
            },
            {
              path: AnalysisRoutes.TRANSACTIONS,
              element: <Analysis.TransactionAnalysis />,
              id: 'processes_tracked_analysis_transactions',
            },
            {
              path: AnalysisRoutes.CUSTOM,
              element: <Analysis.CustomAnalysis />,
              id: 'processes_tracked_analysis_custom',
            },
          ],
        },
        {
          path: SubPathEnum.CONFORMANCE,
          element: <Conformance />,
          id: 'processes_tracked_conformance',
        },
        { path: SubPathEnum.TRENDS, element: <Trends />, id: 'processes_tracked_trends' },
        {
          path: SubPathEnum.USER_ACTIVITIES,
          element: <UserActivities />,
          id: 'processes_tracked_user_activities',
        },
      ],
    },
  ])
}

export default ProcessesRouting
