import { formatISO } from 'date-fns'
import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { useGetPluginPingsStatusQuery } from '../../app/apiSlice'
import { ANNOTATION_PRIMARY_HSLA, teamColorMap } from '../../app/theme'
import { InlineLoader, Loader } from '../../common/components'
import { Tabs, Text } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import {
  type DateRange,
  GranularityEnum,
  OrganizationLevelEnum,
} from '../../common/types/monitoring'
import { generateUCTTimestamps } from '../../common/utils/dateTimeUtils'
import { capitalizeFirstLetter } from '../../common/utils/stringFormatting'

interface Props {
  selectedDates: DateRange
}

const ConnectionsBarchart = ({ selectedDates }: Props) => {
  const { company, teams } = useDashboard()

  const [granularity, setGranularity] = useState(GranularityEnum.HOUR)

  const { data: rawData, isFetching } = useGetPluginPingsStatusQuery(
    {
      start_timestamp: formatISO(selectedDates.start ?? new Date()),
      end_timestamp: formatISO(selectedDates.end ?? new Date()),
      organization_level: OrganizationLevelEnum.TEAM,
      granularity,
    },
    { skip: selectedDates.isSelecting },
  )

  const { chartData, maxConnectedComputers } = useMemo(() => {
    if (!rawData) return { chartData: null, maxConnectedComputers: 0 }

    // Generate all possible timestamps by granularity in order to show 0s in graphs as well
    const uniqueTimestamps = generateUCTTimestamps(selectedDates, granularity)

    // Initialize map with empty objects for each timestamp
    const dateToDataMap = new Map()
    uniqueTimestamps.forEach((timestamp) => dateToDataMap.set(timestamp, {}))

    // Populate map with all available data points, grouped by timestamp
    rawData.forEach((dp) => {
      if (!dp.team_id) return
      const existingData = dateToDataMap.get(dp.grouping_timestamp) || {}
      dateToDataMap.set(dp.grouping_timestamp, { ...existingData, [dp.team_id]: dp })
    })

    let maxConnectedComputers = 0
    // Generate chart data and find maximum connected computer count
    const chartData = {
      labels: uniqueTimestamps.map((ts) => {
        return granularity === GranularityEnum.DAY ? ts.split('T')[0] : ts.replace('T', ' | ')
      }),
      datasets: Object.values(teams).map((team) => {
        const teamData = uniqueTimestamps.map((timestamp) => {
          const dataForTimestamp = dateToDataMap.get(timestamp) ?? {}
          const computerCount = dataForTimestamp[team.id]?.computer_count ?? 0
          maxConnectedComputers = Math.max(maxConnectedComputers, computerCount)
          return computerCount
        })

        return {
          label: team.name,
          data: teamData,
          backgroundColor: teamColorMap[team.colorIndex],
        }
      }),
    }

    return { chartData, maxConnectedComputers }
  }, [rawData, teams, granularity, selectedDates])

  if (!chartData) return <Loader />

  const expectedAgentAmount = company.agentInstallationTarget ?? null

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <div className='mb-2 mr-2 flex justify-between gap-4'>
        <Text>All Connected Computers (UTC Time Zone)</Text>

        {isFetching ? <InlineLoader /> : null}

        <Tabs
          options={Object.entries(GranularityEnum).map(([key, value]) => ({
            label: capitalizeFirstLetter(key),
            value,
          }))}
          value={granularity}
          onChange={(val: string) => setGranularity(val as GranularityEnum)}
        />
      </div>

      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                font: {
                  family: 'Inter',
                },
                stepSize: 1,
              },
              suggestedMax:
                maxConnectedComputers > (expectedAgentAmount ?? 0)
                  ? maxConnectedComputers + 1
                  : (expectedAgentAmount ?? 0) + 1,
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            annotation: {
              annotations: expectedAgentAmount
                ? {
                    line1: {
                      type: 'line',
                      yMin: expectedAgentAmount,
                      yMax: expectedAgentAmount,
                      borderColor: ANNOTATION_PRIMARY_HSLA,
                      borderWidth: 1,
                      label: {
                        content: 'Expected computer amount',
                        backgroundColor: ANNOTATION_PRIMARY_HSLA,
                        display: true,
                      },
                    },
                  }
                : {},
            },
          },
        }}
        height='100px'
      />
    </div>
  )
}

export default ConnectionsBarchart
