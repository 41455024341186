import { PauseCircleIcon } from '@heroicons/react/24/outline'
import { Handle, Position } from 'reactflow'
import type { NodeProps } from 'reactflow'

import { Text } from '../../../../common/designs'
import { ServerStepFlowNode } from './types'

const EndNode = ({ data }: NodeProps<ServerStepFlowNode>) => {
  const { top_ports: topPorts } = data

  const topSeparationSize = 80 / (topPorts.length - 1)

  return (
    <>
      {topPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (topSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Top}
            id={id}
            key={id}
            className='bg-blue-700'
            style={{ left: `${50 + direction * shift}%`, width: '13px', height: '13px' }}
          />
        )
      })}
      <div>
        <div className='flex w-[500px] items-center justify-center gap-2 rounded-md bg-blue-600 p-2'>
          <PauseCircleIcon className='size-10 text-white' />

          <Text size='2xl' weight={500} color='white'>
            End
          </Text>
        </div>
      </div>
    </>
  )
}

export default EndNode
