import { useOutletContext } from 'react-router-dom'

import { useGetWindowNavigationsQuery } from '../../../app/apiSlice'
import { Loader } from '../../../common/components'
import { useQueryFilters } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import SankeyChart from './SankeyChart'

const Navigations = () => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()
  const { tagFilters } = useOutletContext<{ tagFilters: Tag[] | null }>()

  const { data: navigationsData, isFetching } = useGetWindowNavigationsQuery(
    {
      body: {
        min_date,
        max_date,
        team_ids,
        tags_of_interest: tags_of_interest.window,
        tag_filters: tagFilters,
        use_exact_tag_filter_match: true,
      },
    },
    {
      skip: !tagFilters, // Tag filters might be null for brief amount of time.
    },
  )

  if (isFetching || !navigationsData) return <Loader />

  return (
    <div className='flex gap-4'>
      <SankeyChart label='Navigations To' data={navigationsData.navigationsTo} />

      <SankeyChart label='Navigations From' data={navigationsData.navigationsFrom} />
    </div>
  )
}

export default Navigations
