import type { Tag } from '../../common/types/common'

// Tags can contain multiple app names
// If there are multiple app names, combine them
export const getAppNameFromContentTags = (activeTagFilters: Tag[]) =>
  activeTagFilters
    .filter(({ key }) => key === 'appname')
    .map(({ value }) => value)
    .join(', ')

export const getWindowNameFromContentTags = (activeTagFilters: Tag[]) =>
  activeTagFilters
    .filter(({ key }) => key === 'content-category')
    .map(({ value }) => value)
    .join(', ')
