import clsx from 'clsx'
import { useLottie } from 'lottie-react'

import './LoadingOverlay.css'
import loaderAnimationData from './loaderData.json'

export const LoaderSpinner = () => {
  const spinnerLottieOptions = {
    animationData: loaderAnimationData,
    loop: true,
    autoplay: true,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  }

  const { View } = useLottie(spinnerLottieOptions, { height: 100, width: 100 })

  return View
}

export const LoaderContainer = () => {
  return (
    <div className='flex size-full flex-col items-center justify-center'>
      <div className='flex grow items-center justify-center'>
        <LoaderSpinner />
      </div>

      <div className='pb-10'>
        <h3 className='text-sm text-gray-700'>Fetching data</h3>
      </div>
    </div>
  )
}

interface Props {
  className?: string
}

export const Loader = ({ className = '' }: Props) => {
  return (
    <div className={clsx('z-50 size-full bg-transparent', className)}>
      <LoaderContainer />
    </div>
  )
}
