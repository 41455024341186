import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useCreateTeamGroupMutation, useGetTeamsQuery } from '../../app/apiSlice'
import { teamBgColorMap } from '../../app/theme'
import { Button, InputField, Modal, SelectMultiple } from '../../common/designs'

interface Inputs {
  group_name: string
  group_members: string[]
}

const formSchema = yup
  .object({
    group_name: yup
      .string()
      .max(256, 'Group name is over 256 characters long')
      .required('Group name is a required field'),
    group_members: yup
      .array()
      .of(yup.string().required())
      .min(1, 'At least one group member is required')
      .required(),
  })
  .required()

interface Props {
  open: boolean
  onClose: () => void
}

const AddTeamGroupModal = ({ open, onClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      group_name: '',
      group_members: [],
    },
  })

  const { data: teams } = useGetTeamsQuery()

  const [createTeamGroup] = useCreateTeamGroupMutation()

  const onCreate: SubmitHandler<Inputs> = async (data) => {
    createTeamGroup({
      body: {
        group_name: data.group_name,
        group_members: data.group_members,
      },
    })
      .unwrap()
      .then(() => onClose())
  }

  return (
    <Modal label='Add a new team group' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onCreate)} className='flex flex-col gap-3'>
        <Controller
          name='group_name'
          control={control}
          render={({ field }) => (
            <InputField
              autoFocus
              label='Group name'
              autoComplete='off'
              error={errors.group_name?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='group_members'
          control={control}
          render={({ field }) => (
            <SelectMultiple
              label='Group members'
              options={
                teams?.map((t) => {
                  return {
                    label: t.team_name,
                    value: t.team_id,
                    prefix: (
                      <div
                        className={clsx('size-3 rounded', teamBgColorMap[t.color_index])}
                        key={t.id}
                      />
                    ),
                  }
                }) ?? []
              }
              error={errors.group_members?.message}
              {...field}
            />
          )}
        />

        <div className='mt-2 flex justify-end gap-2'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit'>Save</Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddTeamGroupModal
