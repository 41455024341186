import { Tabs } from '../../common/designs'
import { useEnhancedSearchParams } from '../../common/hooks'
import { SubSearchParamEnum } from '../../common/types/searchParams'
import { VisualizationModeEnum } from '../Network/types'
import useOptions from './useOptions'

const VisualizationModeSelector = () => {
  const { visualizationMode } = useOptions()
  const { updateSearchParam, deleteSearchParam } = useEnhancedSearchParams()

  const onChangeVisualizationMode = (value: string) => {
    if (value === VisualizationModeEnum.NETWORK) {
      deleteSearchParam(SubSearchParamEnum.VISUALIZATION_MODE)
    } else {
      updateSearchParam(SubSearchParamEnum.VISUALIZATION_MODE, VisualizationModeEnum.TABLE)
    }
  }

  return (
    <Tabs
      size='xs'
      variant='secondary'
      value={visualizationMode}
      options={[
        { label: 'Network', value: VisualizationModeEnum.NETWORK },
        { label: 'Table', value: VisualizationModeEnum.TABLE },
      ]}
      onChange={onChangeVisualizationMode}
    />
  )
}

export default VisualizationModeSelector
