import { TIME_UNIT } from '../../constants'
import {
  DimensionExtraDataMapper,
  GroupByDimensionTypeEnum,
  GroupByFieldTypeEnum,
  MetricNamesEnum,
  MetricTypeEnum,
} from '../../types'

export enum AnalysisRoutes {
  STEPS = '',
  TEAMS = 'teams',
  TRANSACTIONS = 'cases',
  CUSTOM = 'custom',
}

export enum AnalysisRouteTitles {
  STEPS = 'By tasks',
  TEAMS = 'By team',
  TRANSACTIONS = 'By case',
  CUSTOM = 'Custom analysis',
  TASKS = 'Task analysis',
}

export const metricNameExtraDataMapper = {
  [MetricNamesEnum.TOTAL_TRANSACTIONS]: {
    metric_unit: 'cases',
    metric_name: 'Total cases',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.THROUGHPUT_TIME]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Total duration time',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_THROUGHPUT_TIME]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Avg. duration',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_TEAM_COUNT]: {
    metric_unit: 'teams',
    metric_name: 'Teams per case',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_STEP_AMOUNT]: {
    metric_unit: 'tasks',
    metric_name: 'Tasks per case',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_SELF_LOOPS]: {
    metric_unit: 'loops',
    metric_name: 'Self loops per case',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },

  [MetricNamesEnum.ACTIVE_WORK]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Total active work',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_ACTIVE_WORK]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Active work per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.TOUCH_COUNT]: {
    metric_unit: 'touches',
    metric_name: 'Total touches',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_TOUCH_COUNT]: {
    metric_unit: 'touches',
    metric_name: 'Touches per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.COPY_COUNT]: {
    metric_unit: 'copies',
    metric_name: 'Total copies',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_COPY_COUNT]: {
    metric_unit: 'copies',
    metric_name: 'Copies per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.PASTE_COUNT]: {
    metric_unit: 'pastes',
    metric_name: 'Total pastes',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_PASTE_COUNT]: {
    metric_unit: 'pastes',
    metric_name: 'Pastes per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.KEYBOARD_PRESS_COUNT]: {
    metric_unit: 'key presses',
    metric_name: 'Total key presses',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_KEYBOARD_PRESS_COUNT]: {
    metric_unit: 'key presses',
    metric_name: 'Key presses per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.MOUSE_CLICK_COUNT]: {
    metric_unit: 'mouse clicks',
    metric_name: 'Total mouse clicks',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_MOUSE_CLICK_COUNT]: {
    metric_unit: 'mouse clicks',
    metric_name: 'Mouse clicks per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.RELATED_SYSTEM_TIME]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Time',
    metric_type: MetricTypeEnum.RELATED_SYSTEM_SPECIFIC,
  },
  [MetricNamesEnum.RELATED_SYSTEM_VISITS]: {
    metric_unit: 'Visits',
    metric_name: 'Total visits',
    metric_type: MetricTypeEnum.RELATED_SYSTEM_SPECIFIC,
  },
}

export const metricNameExtraDataMapperPlatformViewer = {
  [MetricNamesEnum.TOTAL_TRANSACTIONS]: {
    metric_unit: 'cases',
    metric_name: 'Total cases',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.THROUGHPUT_TIME]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Total duration time',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_THROUGHPUT_TIME]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Avg. duration time',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_STEP_AMOUNT]: {
    metric_unit: 'tasks',
    metric_name: 'Tasks per case',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },
  [MetricNamesEnum.AVG_SELF_LOOPS]: {
    metric_unit: 'loops',
    metric_name: 'Self loops per case',
    metric_type: MetricTypeEnum.TRANSACTION_SPECIFIC,
  },

  [MetricNamesEnum.TOUCH_COUNT]: {
    metric_unit: 'touches',
    metric_name: 'Total touches',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
  [MetricNamesEnum.AVG_TOUCH_COUNT]: {
    metric_unit: 'touches',
    metric_name: 'Touches per case',
    metric_type: MetricTypeEnum.ACTIVE_WORK_SPECIFIC,
  },
}

export const defaultGroupByDimensionsExtraDataMapper = {
  [GroupByFieldTypeEnum.TEAM_COUNT]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Team amount',
    is_tag_dimension: false,
    filter_key: 'team_count_filters',
  },
  [GroupByFieldTypeEnum.TEAM_IDS]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Teams involved',
    is_tag_dimension: false,
    filter_key: 'team_list_filters',
  },
  [GroupByFieldTypeEnum.WINDOW_STEP_VARIANT]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Variant',
    is_tag_dimension: false,
    filter_key: 'window_step_variant_filters',
  },
  [GroupByFieldTypeEnum.STEP_AMOUNT]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Task amount',
    is_tag_dimension: false,
    filter_key: 'step_count_filters',
  },
  [GroupByFieldTypeEnum.TOUCH_COUNT]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Touch amount',
    is_tag_dimension: false,
    filter_key: 'touch_count_filters',
  },
  [GroupByFieldTypeEnum.SELF_LOOP_COUNT]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Self-loop amount',
    is_tag_dimension: false,
    filter_key: 'self_loop_count_filters',
  },
  [GroupByFieldTypeEnum.TRANSACTION_ID]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Case id',
    is_tag_dimension: false,
    filter_key: 'transaction_id_filters',
  },

  [GroupByFieldTypeEnum.TEAM_ID]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
    dimension_name: 'Team',
    is_tag_dimension: false,
    filter_key: 'team_id_filters',
  },
  [GroupByFieldTypeEnum.STEP_NAMES]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
    dimension_name: 'Task name',
    is_tag_dimension: false,
    filter_key: 'step_name_filters',
  },

  [GroupByFieldTypeEnum.RELATED_WINDOW_NAMES]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC,
    dimension_name: 'Related window name',
    is_tag_dimension: false,
    filter_key: 'related_window_filters',
  },
  [GroupByFieldTypeEnum.PROCESS_APPLICATION_NAMES]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC,
    dimension_name: 'Process application name',
    is_tag_dimension: false,
    filter_key: 'process_application_filters',
  },
} as DimensionExtraDataMapper

// Use all other entries from defaultGroupByDimensionsExtraDataMapper
// except those that are RELATED_SYSTEM_SPECIFIC, also remove TEAM_IDS
// from the options as mapping team_ids to team_names would be very hard.
export const stepAndTransactionGroupByDimensionsExtraDataMapper = Object.entries(
  defaultGroupByDimensionsExtraDataMapper,
)
  .filter(
    ([key, value]) =>
      value.group_by_dimension_type !== GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC &&
      key !== GroupByFieldTypeEnum.TEAM_IDS,
  )
  .reduce((res, [key, value]) => {
    res[key] = value
    return res
  }, {} as DimensionExtraDataMapper)
