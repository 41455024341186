import clsx from 'clsx'

import { Avatar, Text } from '../../../../../common/designs'
import { useAppSelector } from '../../../../../common/hooks'
import { createInitials } from '../../../../../common/utils/stringFormatting'
import { selectUsers } from '../../../casesSlice'
import type { Collaborator } from '../../../types'

interface Props {
  assigneeId: number
  collaborators: Collaborator[]
}

const CollaboratorRow = ({ assigneeId, collaborators }: Props) => {
  const users = useAppSelector(selectUsers)

  const assignee = users.find((user) => user.id === assigneeId)
  const collabsAmountRemaining = collaborators.length - 2

  if (!assignee) return null

  return (
    <div className='flex cursor-grab items-center'>
      <div className='z-[3]'>
        <Avatar size='xs' variant={assignee.color_index} rounded>
          {createInitials(assignee.username)}
        </Avatar>
      </div>
      {collaborators.slice(0, 2).map((c, i) => {
        const user = users.find((u) => u.id === c.user_id)
        if (!user) return null

        return (
          <Avatar
            size='xs'
            variant={user.color_index}
            rounded
            className={clsx('relative', i === 0 ? '-left-1 z-[2]' : '-left-2 z-[1]')}
            key={user.id}
          >
            {createInitials(user.username)}
          </Avatar>
        )
      })}

      {collabsAmountRemaining > 0 && (
        <Text size='xs' className='relative -ml-1.5'>
          +{collabsAmountRemaining}
        </Text>
      )}
    </div>
  )
}

export default CollaboratorRow
