import { XCircleIcon } from '@heroicons/react/24/outline'

import { Badge } from '../../common/designs'
import { isStringArray } from '../../common/types/typeGuards'
import type { ProcessIdentifierMapper } from '../Overview/types'
import type { SelectedFilter } from './types'
import { TaskFilterNameEnum } from './types'

interface Props {
  data: SelectedFilter
  onDelete: (filterKey: string) => void
  processMapper: ProcessIdentifierMapper | undefined
}

const ActiveFilterDisplay = ({ data, onDelete, processMapper }: Props) => {
  let value = isStringArray(data.value) ? `${data.value.length} selected` : data.value

  if (
    data.filterKey === TaskFilterNameEnum.PROCESS_NAME &&
    processMapper &&
    value in processMapper
  ) {
    value = processMapper[value].name
  }
  return (
    <Badge variant='indigo' rounded size='l'>
      <div className='flex gap-1'>
        <div>
          {data.label}: {value}
        </div>
        <XCircleIcon className='size-5 cursor-pointer' onClick={() => onDelete(data.filterKey)} />
      </div>
    </Badge>
  )
}

export default ActiveFilterDisplay
