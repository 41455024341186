import * as yup from 'yup'

import { TagFilteringLogicEnum } from './ProcessMining/Analysis/types'

const validator = {
  trackedProcessName: yup
    .string()
    .max(128, 'Name cannot be more than 128 characters long')
    .required('Name is a required field'),
  trackedProcessIdentifierName: yup
    .string()
    .max(256, 'Process identifier name cannot be more than 256 characters long')
    .required('Process identifier name is a required field'),
  trackedProcessCategory: yup
    .string()
    .nullable()
    .max(256, 'Process identifier name cannot be more than 256 characters long'),
  trackedProcessStateTagKey: yup
    .string()
    .nullable()
    .max(128, 'State tag key cannot be more than 256 characters long'),
  trackedProcessInitialVisibleVariantAmount: yup
    .number()
    .typeError('Must be a number value')
    .min(1, 'Initial visible variant amount cannot be less than 1')
    .required('Initial visible variant is a required field'),
  customDimensionName: yup
    .string()
    .max(128, 'Name cannot be more than 128 characters long')
    .required('Name is a required field'),
  customDimensionKey: yup
    .string()
    .max(128, 'Key cannot be more than 128 characters long')
    .required('Key is a required field'),
  customDimensionFilteringLogic: yup
    .string()
    .required('Type is a required field')
    .oneOf(Object.values(TagFilteringLogicEnum), 'Invalid type'),
}

export default validator
