import {
  useGetApplicationCategoriesQuery,
  useGetTotalAppUsageTrendQuery,
  useGetTransactionActiveWorkTrendQuery,
  useGetTransactionGroupQuery,
  useGetTransactionMetricsQuery,
  useGetTransactionRelatedSystemGroupQuery,
  useGetTransactionStepGroupQuery,
} from '../../../app/apiSlice'
import { appCategoryBgColorMap, teamBgColorMap } from '../../../app/theme'
import { ContentDisplay, HorizontalBar, Stats } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import { Text } from '../../../common/designs'
import { useCostUtils, useDashboard, useQueryFilters } from '../../../common/hooks'
import { applicationCategories } from '../../../common/types/dataCollectionsConfigs'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { GroupByFieldTypeEnum, MetricNamesEnum, TrendTimeGranularityEnum } from '../types'
import OverviewHarmonization from './OverviewHarmonization'

const Overview = () => {
  const { company, teams } = useDashboard()
  const { currency } = useCostUtils()

  const { processFilters: filters, processId } = useQueryFilters()

  const params = { processId, filters, trend_granularity: TrendTimeGranularityEnum.WEEK }
  const { data: processWorkTrendData, isLoading: isLoadingProcessWorkTrendData } =
    useGetTransactionActiveWorkTrendQuery({
      ...params,
    })
  const { data: totalAppUsageTrend, isFetching: isFetchingtotalAppUsageTrend } =
    useGetTotalAppUsageTrendQuery()

  const { data: teamGroupData, isFetching: isFetchingTeamGroupData } =
    useGetTransactionStepGroupQuery({
      ...params,
      group_by: [{ field_type: GroupByFieldTypeEnum.TEAM_ID }],
      metrics: [MetricNamesEnum.ACTIVE_WORK, MetricNamesEnum.TOUCH_COUNT],
    })
  const { data: teamCountGroupData, isFetching: isFetchingTeamCount } = useGetTransactionGroupQuery(
    {
      processId,
      filters,
      group_by: [{ field_type: GroupByFieldTypeEnum.TEAM_COUNT }],
      metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
    },
  )

  const { data: processAppsGroupData, isFetching: isFetchingProcessApps } =
    useGetTransactionRelatedSystemGroupQuery({
      ...params,
      group_by: [{ field_type: GroupByFieldTypeEnum.PROCESS_APPLICATION_NAMES }],
      metrics: [MetricNamesEnum.RELATED_SYSTEM_TIME],
    })

  const { data: workTypeGroupData, isFetching: isFetchingWorkType } =
    useGetTransactionStepGroupQuery({
      ...params,
      group_by: [{ field_type: GroupByFieldTypeEnum.STEP_WORK_TYPE }],
      metrics: [MetricNamesEnum.ACTIVE_WORK, MetricNamesEnum.TOUCH_COUNT],
    })

  const { data: metricData, isFetching: isFetchingMetricData } = useGetTransactionMetricsQuery({
    ...params,
    metrics: [
      MetricNamesEnum.TOTAL_TRANSACTIONS,
      MetricNamesEnum.AVG_THROUGHPUT_TIME,
      MetricNamesEnum.AVG_ACTIVE_WORK,
      MetricNamesEnum.AVG_TOUCH_COUNT,
      MetricNamesEnum.ACTIVE_WORK,
    ],
  })

  const { data: metricDataNoFilter, isFetching: isFetchingMetricDataNoFilter } =
    useGetTransactionMetricsQuery({
      processId,
      filters: {},
      metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
    })
  const { data: applicationCategorization } = useGetApplicationCategoriesQuery()

  let prevWeekProcessTime = 0
  let prevWeekAllWorkTime = 1
  if (processWorkTrendData?.length && totalAppUsageTrend?.length) {
    const dataIndex = processWorkTrendData.length > 1 ? processWorkTrendData.length - 2 : 0
    const { year, isoWeekNumber, total_duration_sec } = processWorkTrendData[dataIndex]
    prevWeekProcessTime = total_duration_sec
    ;({ total_duration_sec: prevWeekAllWorkTime = 1 } =
      totalAppUsageTrend.find((t) => t.year === year && t.isoWeekNumber === isoWeekNumber) || {})
  }
  const costPerTransaction =
    ((metricData?.[MetricNamesEnum.AVG_ACTIVE_WORK] || 0) / 60 / 60) * currency.costPerHour

  let processWorkShare = (prevWeekProcessTime / prevWeekAllWorkTime) * 100
  processWorkShare = processWorkShare >= 100 ? 100 : processWorkShare // Cap the percentage at 100%

  const processWorkMetrics = [
    {
      label: 'Active work (previous week)',
      value: `${formatNumber(prevWeekProcessTime / 3600, 10)} h`,
    },
    {
      label: 'Process work share',
      value: `${formatNumber(processWorkShare)} %`,
    },
    {
      label: 'Cost per case',
      value: `${formatNumber(costPerTransaction, 10)} ${company.currencySymbol}`,
    },
  ]

  const totalTransactions = metricDataNoFilter?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0
  const analyzedTransactions = metricData?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0
  const filterdOutTransaction = totalTransactions - analyzedTransactions

  const transactionMetrics = [
    { label: 'All detected cases', value: formatNumber(totalTransactions) },
    { label: 'Cases in analysis', value: formatNumber(analyzedTransactions) },
    { label: 'Filtered out cases', value: formatNumber(filterdOutTransaction) },
  ]

  const timeMetrics = [
    {
      label: 'Avg. Duration',
      value: `${getBestTimeFormatForSeconds(
        metricData?.[MetricNamesEnum.AVG_THROUGHPUT_TIME] || 0,
      )}`,
    },
    {
      label: 'Active work per case',
      value: `${getBestTimeFormatForSeconds(metricData?.[MetricNamesEnum.AVG_ACTIVE_WORK] || 0)}`,
    },
    {
      label: 'Touches per case',
      value: `${formatNumber(metricData?.[MetricNamesEnum.AVG_TOUCH_COUNT] || 0)}`,
    },
  ]

  const teamActiveWorkData = teamGroupData?.[MetricNamesEnum.ACTIVE_WORK]?.map(
    ({ value, label }) => {
      const typedLabel = label as string
      const teamObj = teams[typedLabel]
      return {
        label: teamObj?.name || typedLabel,
        value: value / 3600,
        color: teamBgColorMap[teamObj?.colorIndex || 0],
      }
    },
  )
  const teamTouchData = teamGroupData?.[MetricNamesEnum.TOUCH_COUNT]?.map(({ value, label }) => {
    const typedLabel = label as string
    const teamObj = teams[typedLabel]
    return {
      label: teamObj?.name || typedLabel,
      value: value,
      color: teamBgColorMap[teamObj?.colorIndex || 0],
    }
  })

  const workTypeData = workTypeGroupData?.[MetricNamesEnum.ACTIVE_WORK]?.map(
    ({ value, label }) => ({
      value: value / 3600,
      label: label as string,
    }),
  )

  const processAppsData = processAppsGroupData?.[MetricNamesEnum.RELATED_SYSTEM_TIME]?.map(
    ({ value, label }) => ({
      value: value / 3600,
      label: label as string,
    }),
  )

  const applicationCategoryTotals = {} as { [key: string]: number }
  processAppsGroupData?.[MetricNamesEnum.RELATED_SYSTEM_TIME]?.forEach(({ value, label }) => {
    const category =
      applicationCategorization?.[label as keyof typeof applicationCategorization] ??
      applicationCategories.other
    if (category in applicationCategoryTotals) applicationCategoryTotals[category] += value
    else applicationCategoryTotals[category] = value
  })

  const applicationCategoryData = Object.entries(applicationCategoryTotals)
    .map(([label, value]) => {
      return {
        value: value / 3600,
        label: label,
        color: appCategoryBgColorMap[label],
      }
    })
    .sort((a, b) => b.value - a.value)

  return (
    <div className='mb-10 flex flex-col gap-5'>
      <div>
        <Stats
          data={processWorkMetrics}
          description='Process metrics based on active work time'
          isLoading={
            isLoadingProcessWorkTrendData || isFetchingtotalAppUsageTrend || isFetchingMetricData
          }
        />
      </div>

      <div className='flex flex-col gap-5'>
        <div className='flex w-full gap-3'>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Active work per team
            </Text>
            <HorizontalBar
              data={teamActiveWorkData || []}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingTeamGroupData}
            />
          </ContentDisplay>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Touches per team
            </Text>
            <HorizontalBar
              data={teamTouchData || []}
              unit='touches'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingTeamGroupData}
            />
          </ContentDisplay>
        </div>

        <div>
          <Stats
            data={transactionMetrics}
            title='Case volumes'
            description='Case selected for the analysis is based on the active filter'
            isLoading={isFetchingMetricDataNoFilter}
          />
        </div>

        <div className='flex w-full  gap-3'>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Amount of teams involved in cases
            </Text>
            <HorizontalBar
              data={
                (teamCountGroupData?.[MetricNamesEnum.TOTAL_TRANSACTIONS] as ChartDataEntry[]) || []
              }
              unit='cases'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingTeamCount}
            />
          </ContentDisplay>

          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Work type
            </Text>
            <HorizontalBar
              data={workTypeData || []}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingWorkType}
            />
          </ContentDisplay>
        </div>
        <div className='flex w-full  gap-3'>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Process applications
            </Text>
            <HorizontalBar
              data={processAppsData || []}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingProcessApps}
            />
          </ContentDisplay>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Process application categories
            </Text>
            <HorizontalBar
              data={applicationCategoryData || []}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingProcessApps}
            />
          </ContentDisplay>
        </div>
      </div>

      <div>
        <Stats
          data={timeMetrics}
          title='Process KPIs'
          description='Based on the active filter'
          isLoading={isFetchingMetricData}
        />
      </div>
      <div className='mb-6'>
        <OverviewHarmonization />
      </div>
    </div>
  )
}

export default Overview
