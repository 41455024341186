import { Badge } from '../../common/designs'
import { InsightType } from './types'

interface Props {
  insightTypes: InsightType[]
}

const InsightTypes = ({ insightTypes }: Props) => {
  return (
    <ul className='flex list-none flex-wrap gap-2'>
      {insightTypes.map(({ id, label, color }: any) => (
        <li key={id}>
          <Badge size='l' variant={color}>
            {label}
          </Badge>
        </li>
      ))}
    </ul>
  )
}

export default InsightTypes
