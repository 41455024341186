import type {
  AppMetaFormData,
  ConfigTestCase,
} from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type {
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Text } from '../../designs'
import { ContentDisplay } from '../displays'
import DataCollectionAppForm from './DataCollectionAppForm'
import DataCollectionGranularity from './DataCollectionGranularity'
import DataCollectionRules from './DataCollectionRules'
import DataCollectionTests from './DataCollectionTests'
import type { AppTestCaseGroup, DataCollectionGranularityForm } from './types'

interface Props {
  appTestCasesGroup: AppTestCaseGroup
  appMetaFormData: AppMetaFormData
  isLoadingUpdateAppMeta: boolean
  onUpdateAppData: (appData: AppMetaFormData) => Promise<void>
  granularityFormData: DataCollectionGranularityForm
  onUpdateGranularityData: (formData: DataCollectionGranularityForm) => Promise<void>
  onAddTestCase: (formValues: DataCollectionTestCaseFormData) => Promise<void>
  onUpdateTestCase: (
    formValues: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (testCase: ConfigTestCase) => Promise<void>
  onAddRuleIn: (formValues: DataCollectionRuleFormData) => Promise<void>
  onUpdateRuleIn: (formData: DataCollectionRuleFormData, originalRule: RuleIn) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

const AppLevelTestAndRules = ({
  appTestCasesGroup,
  appMetaFormData,
  onUpdateAppData,
  isLoadingUpdateAppMeta,
  granularityFormData,
  onUpdateGranularityData,
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: Props) => {
  return (
    <div className='space-y-8'>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Application Rules
        </Text>
        <DataCollectionAppForm
          initialAppFormData={appMetaFormData}
          onUpdateAppData={onUpdateAppData}
          isLoading={isLoadingUpdateAppMeta}
        />
      </div>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Data Collection Granularity
        </Text>
        {appTestCasesGroup.rules.length > 0 && (
          <div className='space-y-2'>
            <DataCollectionGranularity
              formData={granularityFormData}
              onUpdateGranularityData={onUpdateGranularityData}
              appName={appTestCasesGroup.appName}
            />
          </div>
        )}
      </div>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Data Collection Rules
        </Text>
        <ContentDisplay className='p-4'>
          <div className='w-full md:grid md:grid-cols-2 md:gap-4'>
            <DataCollectionTests
              testCases={appTestCasesGroup.testCases}
              onAddTestCase={onAddTestCase}
              onUpdateTestCase={onUpdateTestCase}
              onDeleteTestCase={onDeleteTestCase}
            />
            <DataCollectionRules
              rulesForTestCases={appTestCasesGroup.rules}
              onAddRuleIn={onAddRuleIn}
              onUpdateRuleIn={onUpdateRuleIn}
              onUpdateRuleInCode={onUpdateRuleInCode}
              onDeleteRuleIn={onDeleteRuleIn}
            />
          </div>
        </ContentDisplay>
      </div>
    </div>
  )
}

export default AppLevelTestAndRules
