import { PlusIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

import { useGetCaseCategoriesQuery } from '../../app/apiSlice'
import { Button, Link, Table, Text } from '../../common/designs'
import type { CaseCategory } from '../Cases/types'
import AddCaseCategoryModal from './AddCaseCategoryModal'
import ColorBox from './ColorBox'
import EditCaseCategoryModal from './EditCaseCategoryModal'

const CaseSettings = () => {
  const [activeCaseCategory, setActiveCaseCategory] = useState<CaseCategory | null>(null)
  const [isAddCaseCategoryModalOpen, setIsAddtCaseCategoryModalOpen] = useState<boolean>(false)
  const [isEditCaseCategoryModalOpen, setIsEditCaseCategoryModalOpen] = useState<boolean>(false)

  const { data, isLoading } = useGetCaseCategoriesQuery()

  return (
    <>
      <Table
        label='Case Categories'
        head={['Category name', 'Color', 'Action']}
        action={
          <Button
            disabled={isLoading}
            onClick={() => setIsAddtCaseCategoryModalOpen(true)}
            iconStart={<PlusIcon />}
          >
            Add a new opportunity category
          </Button>
        }
        body={
          data?.map((t) => {
            return [
              t.name,
              <ColorBox colorIndex={t.color_index} key={t.id} />,
              t.is_default ? (
                <Text size='xs'>This category can&#39;t be edited</Text>
              ) : (
                <Link
                  onClick={() => {
                    setActiveCaseCategory(t)
                    setIsEditCaseCategoryModalOpen(true)
                  }}
                >
                  Edit
                </Link>
              ),
            ]
          }) ?? []
        }
      />

      {isAddCaseCategoryModalOpen && (
        <AddCaseCategoryModal
          open={isAddCaseCategoryModalOpen}
          onClose={() => setIsAddtCaseCategoryModalOpen(false)}
        />
      )}

      {isEditCaseCategoryModalOpen && activeCaseCategory && (
        <EditCaseCategoryModal
          activeCaseCategory={activeCaseCategory}
          open={isEditCaseCategoryModalOpen}
          onClose={() => setIsEditCaseCategoryModalOpen(false)}
        />
      )}
    </>
  )
}

export default CaseSettings

