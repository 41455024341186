import { useRef } from 'react'
import { Link } from 'react-router-dom'

import { Text } from '../../common/designs'
import InsightTypes from './InsightTypes'
import type { InsightType } from './types'

interface Props {
  title: string
  types: InsightType[]
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const InsightCard = ({ title, types, onClick }: Props) => {
  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <div
      className='flex cursor-pointer flex-col gap-4 rounded-md border bg-white p-4 shadow'
      onClick={() => linkRef.current?.click()}
    >
      <Text variant='h4' size='l'>
        <Link
          ref={linkRef}
          to='#'
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()

            return onClick(e)
          }}
          className='text-gray-900 no-underline hover:underline hover:underline-offset-2'
        >
          {title}
        </Link>
      </Text>

      <InsightTypes insightTypes={types} />
    </div>
  )
}

export default InsightCard
