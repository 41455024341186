import { useState } from 'react'

import { useCreateCommentMutation } from '../../../../../app/apiSlice'
import { Avatar, Button, TextArea } from '../../../../../common/designs'
import { useAppDispatch, useAppSelector, useDashboard } from '../../../../../common/hooks'
import { createInitials } from '../../../../../common/utils/stringFormatting'
import { addComment, loadSnapshot, saveSnapshot, selectActiveCaseId } from '../../../casesSlice'

const CommentField = () => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()
  const caseId = useAppSelector(selectActiveCaseId) as number

  const [newCommentText, setNewCommentText] = useState<string>('')

  const [createCommentMutation] = useCreateCommentMutation()

  const createComment = async () => {
    const comment = {
      created_date_time: new Date(),
      text: newCommentText,
      user_id: user.id,
    }

    dispatch(saveSnapshot())
    dispatch(addComment(comment))
    const newCommentBackup = newCommentText
    setNewCommentText('')

    createCommentMutation({ caseId, body: { text: newCommentText } })
      .unwrap()
      .catch(() => {
        setNewCommentText(newCommentBackup) // Restore written comment if comment was not created
        dispatch(loadSnapshot())
      })
  }

  return (
    <div className='mb-1 mt-3 flex py-1'>
      <div className='mr-3'>
        <Avatar variant={user.colorIndex} size='s' rounded>
          {createInitials(user.username)}
        </Avatar>
      </div>

      <TextArea
        id='Comment-field'
        label='Comment'
        rows={5}
        value={newCommentText}
        onChange={({ target }: any) => setNewCommentText(target.value)}
        placeholder='Ask a question or post an update...'
        disabled={user.isDataReader}
        className='w-full'
      />

      <div className='w-0'>
        {Boolean(newCommentText) && !user.isDataReader && (
          <Button size='xs' onClick={createComment} className='relative right-24 top-24'>
            Comment
          </Button>
        )}
      </div>
    </div>
  )
}

export default CommentField
