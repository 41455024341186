import type { AnalysisGroupByExtraData } from '../../types'
import { GroupByDimensionTypeEnum, MetricTypeEnum } from '../../types'

const replacePerTranscationWithAvg = (metricName: string) => {
  if (!metricName.includes(' per case')) return metricName
  const parserMetricName = metricName.replace(' per case', '')
  return `Average ${parserMetricName.toLowerCase()}`
}

const getRelatedSystemInTransactionDescription = (dimensionName: string, metricName: string) => {
  const descriptionStart = `${metricName} when`
  const descriptionLevel =
    dimensionName === 'Process application name'
      ? 'the application is used in the process.'
      : 'windows that support of the main process tasks.'
  return `${descriptionStart} ${descriptionLevel} `
}

const getStepSpecificUsageDescription = (dimensionName: string, metricName: string) => {
  return `${metricName} in process tasks with given ${dimensionName.toLowerCase()}`
}

const getStepSpecificInTransactionDescription = (dimensionName: string, metricName: string) => {
  return `${replacePerTranscationWithAvg(
    metricName,
  )} for cases that includes given ${dimensionName.toLowerCase()}`
}

const getTransactionSpecificDescription = (
  dimensionName: string,
  metricName: string,
  metricType: MetricTypeEnum,
) => {
  const preposition = metricType === MetricTypeEnum.ACTIVE_WORK_SPECIFIC ? 'in' : 'for'

  return `${replacePerTranscationWithAvg(
    metricName,
  )} ${preposition} cases with given ${dimensionName.toLowerCase()}`
}

export const getDescription = (
  groupByMetadata: AnalysisGroupByExtraData,
  metricMetadata: {
    metric_name: string
    metric_type: MetricTypeEnum
  },
) => {
  const { metric_name, metric_type } = metricMetadata
  const { dimension_name, group_by_dimension_type } = groupByMetadata

  if (group_by_dimension_type === GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC) {
    return getRelatedSystemInTransactionDescription(dimension_name, metric_name)
  }
  if (
    metric_type === MetricTypeEnum.ACTIVE_WORK_SPECIFIC &&
    group_by_dimension_type === GroupByDimensionTypeEnum.STEP_SPECIFIC
  ) {
    return getStepSpecificUsageDescription(dimension_name, metric_name)
  }
  if (group_by_dimension_type === GroupByDimensionTypeEnum.STEP_SPECIFIC) {
    return getStepSpecificInTransactionDescription(dimension_name, metric_name)
  }

  return getTransactionSpecificDescription(dimension_name, metric_name, metric_type)
}
