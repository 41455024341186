import { useState } from 'react'
import type { NodeProps } from 'reactflow'

import { useGetProcessIdentifierMapperQuery } from '../../app/apiSlice'
import { Divider, Text } from '../../common/designs'
import {
  formatNumber,
  fractionToPercentage,
  getBestTimeFormatForSeconds,
} from '../../common/utils/numberFormatting'
import NodeActionButtons from '../Processes/ProcessMining/Variants/NodeActionButtons'
import NodeLayout from '../Processes/ProcessMining/Variants/NodeLayout'
import TaskFlowNodeModal from './TaskFlowNodeModal'
import { getCopyPasteDescriptions, getProcessDescriptions } from './taskAcitivityDescription'
import type { ServerTaskVisualizationNode } from './types'

const TaskFlowNodeWithActivities = ({ data }: NodeProps<ServerTaskVisualizationNode>) => {
  const [isStepModalOpen, setIsStepModalOpen] = useState<boolean>(false)
  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  const {
    display_name: displayName,
    transactions,
    avg_duration_sec,
    is_conformance_flow_node: isConformanceFlowNode,

    step_copies: copies,
    step_pastes: pastes,
    process_steps: processSteps,
    top_ports: topPorts,
    right_ports: rightPorts,
    bottom_ports: bottomPorts,
    left_ports: leftPorts,

    internal_copy_paste_count: internalCopyPasteCount,
    keyboard_press_count: keyboardPressCount,
    activity_categorizations: activityCategorizations,

    relative_share_of_transactions: relativeTransactions,
  } = data

  const timeString = getBestTimeFormatForSeconds(avg_duration_sec)
  const [timeNumber, timeUnit] = timeString.split(' ')

  const { copyDescriptionText, pasteDescriptionText } = getCopyPasteDescriptions(copies, pastes)
  const processDescriptionText = getProcessDescriptions(processSteps, processMapper)

  const displayedActivities = [...activityCategorizations]
    .sort((a, b) => b.activity_count - a.activity_count)
    .map((a) => `${a.activity_name} (${fractionToPercentage(a.activity_count / transactions)})`)

  return (
    <>
      <NodeLayout
        ports={{ topPorts, rightPorts, bottomPorts, leftPorts }}
        relativeSize={relativeTransactions}
        nodeName={displayName}
        timeText={`${timeNumber} ${timeUnit}`}
        transactionCount={transactions}
        isConformanceFlowNode={isConformanceFlowNode}
        actionButtons={<NodeActionButtons onShowMetrics={() => setIsStepModalOpen(true)} />}
      >
        <Divider label='User Activities' color='darkGray' className='my-3' />
        <Text weight={600} className='mb-2'>
          {displayedActivities.join(', ')}
        </Text>
        {copyDescriptionText && (
          <Text className='mb-1'>
            <strong>Copy data to:</strong> {copyDescriptionText}
          </Text>
        )}
        {pasteDescriptionText && (
          <Text className='mb-1'>
            <strong>Paste data from:</strong> {pasteDescriptionText}
          </Text>
        )}
        {processDescriptionText && (
          <Text className='mb-1'>
            <strong>Interact with processes:</strong> {processDescriptionText}
          </Text>
        )}
        <Text className='mb-1'>
          <strong>Avg. internal copy-pastes:</strong>{' '}
          {formatNumber(internalCopyPasteCount / transactions, 10)}
        </Text>
        <Text className='mb-1'>
          <strong>Avg. keyboard presses:</strong>{' '}
          {formatNumber(keyboardPressCount / transactions, 10)}
        </Text>
      </NodeLayout>
      <TaskFlowNodeModal
        isOpen={isStepModalOpen}
        onClose={() => setIsStepModalOpen(false)}
        data={data}
      />
    </>
  )
}

export default TaskFlowNodeWithActivities
