import { Table } from '../../../../../common/designs'
import { useAppSelector, useCostUtils } from '../../../../../common/hooks'
import { selectRoadmapEntitiesAsArrayFiltered } from '../../../casesSlice'

const CaseTable = () => {
  const cases = useAppSelector(selectRoadmapEntitiesAsArrayFiltered)
  const { formatCost } = useCostUtils()

  return (
    <Table
      head={['Title', 'Priority', 'Complexity', 'Annualised Business Case']}
      body={cases.map((c) => [
        c.title,
        c.priority,
        c.complexity,
        formatCost(c.business_case_cost - c.implementation_cost),
      ])}
    />
  )
}

export default CaseTable
