import { useCallback, useMemo, useState } from 'react'

import { useLazyGetTaskFilterOptionsQuery } from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import {
  Button,
  Select,
  SelectMultiple,
  SelectMultipleOption,
  SelectOption,
  Text,
} from '../../common/designs'
import { useQueryFilters } from '../../common/hooks'
import type { ProcessIdentifierMapper } from '../Overview/types'
import type { SelectedFilter } from './types'
import { TaskFilterLabelEnum, TaskFilterNameEnum } from './types'
import { useGetTaskFiltersFromSearch } from './useGetTaskFiltersFromSearch'

interface Props {
  onSetFilter: (key: string, value: string | string[]) => void
  activeTaskFiltersArray: SelectedFilter[]
  processMapper: ProcessIdentifierMapper | undefined
}

const filterOptions = [
  { label: '+ Select filter', value: null },
  {
    label: TaskFilterLabelEnum.MIN_TASK_STEP_AMOUNT,
    value: TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT,
  },
  { label: TaskFilterLabelEnum.START_APP, value: TaskFilterNameEnum.START_APP },
  { label: TaskFilterLabelEnum.START_WINDOW, value: TaskFilterNameEnum.START_WINDOW },
  { label: TaskFilterLabelEnum.END_APP, value: TaskFilterNameEnum.END_APP },
  { label: TaskFilterLabelEnum.END_WINDOW, value: TaskFilterNameEnum.END_WINDOW },
  { label: TaskFilterLabelEnum.APPS_IN_TASK, value: TaskFilterNameEnum.APPS_IN_TASK },
  { label: TaskFilterLabelEnum.WINDOWS_IN_TASK, value: TaskFilterNameEnum.WINDOWS_IN_TASK },
  { label: TaskFilterLabelEnum.MAIN_APP, value: TaskFilterNameEnum.MAIN_APP },
  {
    label: TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_APP,
    value: TaskFilterNameEnum.DATA_TRANSFER_SOURCE_APP,
  },
  {
    label: TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_WINDOW,
    value: TaskFilterNameEnum.DATA_TRANSFER_SOURCE_WINDOW,
  },
  {
    label: TaskFilterLabelEnum.DATA_TRANSFER_TARGET_APP,
    value: TaskFilterNameEnum.DATA_TRANSFER_TARGET_APP,
  },
  {
    label: TaskFilterLabelEnum.DATA_TRANSFER_TARGET_WINDOW,
    value: TaskFilterNameEnum.DATA_TRANSFER_TARGET_WINDOW,
  },
  { label: TaskFilterLabelEnum.PROCESS_NAME, value: TaskFilterNameEnum.PROCESS_NAME },
  { label: TaskFilterLabelEnum.PROCESS_STEP_NAME, value: TaskFilterNameEnum.PROCESS_STEP_NAME },
]

const AddFilterField = ({ onSetFilter, activeTaskFiltersArray, processMapper }: Props) => {
  const { min_date, max_date } = useQueryFilters()
  const [selectedFilter, setSelectedFilter] = useState<TaskFilterNameEnum | null>(null)
  const [selectedFilterValue, setSelectedFilterValue] = useState<string | null>(null)
  const [selectedMultiFilterValue, setSelectedMultiFilterValue] = useState<string[]>([])
  const [filterValueOptions, setFilterValueOptions] = useState<
    SelectOption[] | SelectMultipleOption[]
  >([{ label: 'Select value', value: null }])
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const activeTaskFilters = useGetTaskFiltersFromSearch()

  const [getFilterOptions] = useLazyGetTaskFilterOptionsQuery()

  const onSetSelectedFilter = useCallback(
    (filter: TaskFilterNameEnum | null) => {
      setSelectedFilter(filter)
      if (!filter) return
      setShowLoader(true)
      getFilterOptions({
        filters: { ...activeTaskFilters, min_date, max_date },
        selected_filter: filter,
      })
        .unwrap()
        .then((optionsArray) => {
          if (filter === TaskFilterNameEnum.PROCESS_NAME && processMapper) {
            setFilterValueOptions(
              optionsArray
                .filter((p) => processMapper && p in processMapper)
                .map((p) => ({ label: processMapper[p].name, value: p })),
            )
          } else {
            setFilterValueOptions(optionsArray.map((o) => ({ label: o, value: o })))
          }
          setShowLoader(false)
        })
    },
    [activeTaskFilters, min_date, max_date, getFilterOptions, processMapper],
  )

  const useMultiSelect = useMemo(() => {
    return Boolean(
      selectedFilter &&
        [TaskFilterNameEnum.APPS_IN_TASK, TaskFilterNameEnum.WINDOWS_IN_TASK].includes(
          selectedFilter,
        ),
    )
  }, [selectedFilter])

  const onApplyFilterFilter = useCallback(() => {
    if (!selectedFilter || !(selectedFilterValue || selectedMultiFilterValue.length)) return

    const filterValueToUse = useMultiSelect ? selectedMultiFilterValue : selectedFilterValue
    if (!filterValueToUse) return
    onSetFilter(selectedFilter, filterValueToUse)
    setSelectedFilter(null)
    setSelectedFilterValue(null)
  }, [onSetFilter, selectedFilter, selectedFilterValue, selectedMultiFilterValue, useMultiSelect])

  return (
    <div className='rounded-lg border border-indigo-600 p-4'>
      <Text size='s' weight={600} className='mb-1'>
        Add filter
      </Text>
      <div className='flex gap-2'>
        <Select
          size='xs'
          options={filterOptions.filter(
            (o) => !(o.value && activeTaskFiltersArray.some((f) => f.filterKey === o.value)),
          )}
          value={selectedFilter}
          onChange={(value: string | null) => {
            onSetSelectedFilter(value as TaskFilterNameEnum | null)
            setSelectedFilterValue(null)
          }}
          disabled={showLoader}
        />
        {showLoader ? (
          <InlineLoader size='xs' />
        ) : selectedFilter && !useMultiSelect ? (
          <Select
            size='xs'
            options={[{ label: 'Select value', value: null }, ...filterValueOptions]}
            value={selectedFilterValue}
            onChange={setSelectedFilterValue}
          />
        ) : (
          selectedFilter &&
          useMultiSelect && (
            <SelectMultiple
              size='xs'
              options={filterValueOptions as SelectMultipleOption[]}
              value={selectedMultiFilterValue}
              onChange={setSelectedMultiFilterValue}
            />
          )
        )}
        {selectedFilter && (selectedFilterValue || selectedMultiFilterValue.length) ? (
          <Button size='xs' onClick={onApplyFilterFilter}>
            Set filter
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default AddFilterField
