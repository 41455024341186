import type { Tag } from '../../common/types/common'
import type { Application, ApplicationCategory } from '../../common/types/dataCollectionsConfigs'
import type {
  DashboardContext,
  RuleIn,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'

export interface CatalogProcess {
  processName: string
  businessObjects: string[]
  lastUpdated: string
  configurationFile: string
}

export interface CatalogApp {
  id: number
  app_name: string
  configuration_file: string
  category: ApplicationCategory
  is_pdf_application: boolean
  process_names: string | null
  business_objects: string | null
  last_updated: string
}

export enum ApplicationSubPathEnum {
  PROCESS_CATALOG = 'process-catalog',
  APP_CATALOG = 'app-catalog',
}

export enum DataCollectionLevelEnum {
  APPLICATION = 'application',
  WINDOW = 'window',
}

export enum ReactionTriggersEnum {
  SWITCH_WINDOW = 'SwitchWindow',
  LEFT_CLICK = 'LeftClick',
  RIGHT_CLICK = 'RightClick',
  DOUBLE_CLICK = 'DoubleClick',
  MIDDLE_CLICK = 'MiddleClick',
  WRITE = 'Write',
  COPY = 'Copy',
  PASTE = 'Paste',
  CUT = 'Cut',
}
export enum EventGeneratorsEnum {
  DEFAULT = 'default',
}

interface WindowActivationEventDict {
  active_process_name?: string
  title?: string
  url?: string
  [k: string]: any
}

interface ExtractedIdentifierObject {
  identifier_name: string
  value: string
  [k: string]: any
}

interface InteractedElement {
  user_action: string
  timestamp: string
  extracted_data: Array<{ [key: string]: string }>
}
interface ExpectedProcessedEvent {
  extracted_identifiers?: ExtractedIdentifierObject[]
  tags?: Tag[]
  interacted_elements?: InteractedElement[]
  applied_rules?: { [key in SlucieStagesEnum]: string[] }
}

export interface ConfigTestCase {
  test_id: string
  x_ray?: string | null
  user_action?: ReactionTriggersEnum | null
  description?: string | null
  rule_id: string[]
  row_event: WindowActivationEventDict
  event_generators: EventGeneratorsEnum[]
  force_tracking: boolean
  expect_output: boolean
  expected_salvage_fields: string[]
  expect_hashed_identifiers: boolean
  expected_processed_event: ExpectedProcessedEvent
  rule_generation_hint?: string | null
  is_dashboard_generated?: boolean
  dashboard_context?: DashboardContext
}

export type WindowFormInputs = {
  // Upkeep in requirements
  windowName: string
}

export enum SlucieStagesEnum {
  ALLOWED_TRACKING_STAGE = 'allow_tracking',
  TAGGING_STAGE = 'tagging',
  IDENTIFIER_OF_INTEREST_EXTRACTION_STAGE = 'identifier_of_interest_extraction',
  SALVAGE_FIELDS_STAGE = 'salvage_fields',
  REACTIONS_STAGE = 'reactions',
}

interface TestCaseAppliedRules {
  test_id: string
  applied_rules: { [key in SlucieStagesEnum]: string[] }
}

interface ConfigTestResultData {
  test_id: string
  errors: string[]
  is_passed: boolean
}

export interface ConfigTestReport {
  company_id: string
  team_id?: string
  version: number
  test_case_version: string
  settings_version: string
  is_passed: boolean
  is_salvaging_fields: boolean
  salvage_fields: string[]
  unused_rules: string[]
  failing_tests: string[]
  passing_tests: string[]
  applied_rules: TestCaseAppliedRules[]
  saved_datetime_utc: Date | null
  case_reports: ConfigTestResultData[]
}

export interface RequirementParams {
  team_id?: string | null
  version?: number | null
}

export interface RequirementUploadParams extends RequirementParams {
  test_cases: ConfigTestCase[]
}

export interface RequirementVersion {
  company_id: string
  team_id: string | null
  version: number
  created_on: string
  last_modified: string
}

export interface Requirements extends RequirementVersion {
  test_cases: ConfigTestCase[]
}

export interface Rules extends RequirementVersion {
  rules: RuleIn[]
}

export interface AppTestCasesInfo {
  appName: string
  appMetaData: Application
  testCases: ConfigTestCase[]
}

export enum ConfigureDataCollectionSubPathEnum {
  CONFIG_PORTAL = 'config-portal',
  ADD_CUSTOM_APP = 'add-custom-app',
  APP_CATALOG = 'app-catalog',
  APPS = 'apps',
}

export interface RunTestsParams {
  settings_version?: number
  rules: RuleIn[]
}

export interface AppMetaFormData {
  // APP REQUIREMENT DATA
  appName: string
  category: NonNullable<ApplicationCategory | undefined>
  isPdfApplication: boolean
}

export interface ConfigMetadata {
  name: string
  lastUpdateDate: Date | null
}
