import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query'
import { useCallback, useEffect, useState } from 'react'

export interface PaginationProps<DataFetchParamsType, DataFetchResultType> {
  onFetchData: (
    args: DataFetchParamsType,
  ) => QueryActionCreatorResult<
    QueryDefinition<DataFetchParamsType, BaseQueryFn, 'DasboardSetup', DataFetchResultType, 'api'>
  >
  totalResults: number
  dataBatchSize?: number
}

function usePaginationWithDataQuery<DataFetchParamsType, DataFetchResultType>({
  onFetchData,
  totalResults,
  dataBatchSize = 50,
}: PaginationProps<DataFetchParamsType, DataFetchResultType>) {
  const [nextBatchNumber, setNextBatchNumber] = useState<number>(0)
  const [isAllResultsFetched, setIsAllResultsFetched] = useState<boolean>(false)

  useEffect(() => {
    setNextBatchNumber(0)
  }, [onFetchData, totalResults, dataBatchSize])

  useEffect(() => {
    setIsAllResultsFetched(nextBatchNumber * dataBatchSize >= totalResults)
  }, [nextBatchNumber, totalResults, dataBatchSize])

  const onFetchDataForBatchNumber = useCallback(
    async (args: DataFetchParamsType, batchNumber: number) => {
      const options = {
        ...args,
        pagination_options: { batch_size: dataBatchSize, batch_number: batchNumber },
      }
      setNextBatchNumber(batchNumber + 1)
      return await onFetchData(options).unwrap()
    },
    [dataBatchSize, onFetchData, setNextBatchNumber],
  )

  const onFetchDataForNextBatch = useCallback(
    (args: DataFetchParamsType) => {
      return onFetchDataForBatchNumber(args, nextBatchNumber)
    },
    [onFetchDataForBatchNumber, nextBatchNumber],
  )

  return {
    onFetchDataForNextBatch,
    onFetchDataForBatchNumber,
    isAllResultsFetched,
  }
}

export default usePaginationWithDataQuery
