import { useState } from 'react'

import {
  ApplicationCategory,
  applicationCategories,
} from '../../common/types/dataCollectionsConfigs'

type ClusterVisible = { [K in ApplicationCategory]: boolean }

const useClusterLegend = (): [
  ClusterVisible,
  React.Dispatch<React.SetStateAction<ClusterVisible>>,
] => {
  const [clustersVisible, setClustersVisible] = useState<ClusterVisible>(
    Object.values(applicationCategories).reduce(
      (acc, curr) => ({ ...acc, [curr]: true }),
      {} as ClusterVisible,
    ),
  )

  return [clustersVisible, setClustersVisible]
}

export default useClusterLegend
