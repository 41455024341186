import { filterKeyToLabelMapper } from './filterMappers'
import type {
  SelectedFilter,
  TaskDataTransferFilters,
  TaskRelatedProcessFilters,
  TaskWindowFilters,
} from './types'
import { TaskFilterNameEnum } from './types'
import { useGetTaskFiltersFromSearch } from './useGetTaskFiltersFromSearch'

const mapObjectToFilterArray = (
  object: TaskWindowFilters | TaskDataTransferFilters | TaskRelatedProcessFilters,
) => {
  return Object.entries(object)
    .filter(([_, value]) => value)
    .map(([key, value]) => ({
      label: filterKeyToLabelMapper[key as keyof typeof filterKeyToLabelMapper],
      value,
      filterKey: key as TaskFilterNameEnum,
    }))
}

export const useGetTaskFiltersAsArray = () => {
  const activeTaskFilters = useGetTaskFiltersFromSearch()
  const filterArray = [] as SelectedFilter[]

  if (activeTaskFilters.window_filters) {
    filterArray.push(...mapObjectToFilterArray(activeTaskFilters.window_filters))
  }

  if (activeTaskFilters.data_transfer_filters) {
    filterArray.push(...mapObjectToFilterArray(activeTaskFilters.data_transfer_filters))
  }

  if (activeTaskFilters.related_process_filters) {
    filterArray.push(...mapObjectToFilterArray(activeTaskFilters.related_process_filters))
  }

  if (activeTaskFilters.min_task_step_amount) {
    filterArray.push({
      label: filterKeyToLabelMapper[TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT],
      value: activeTaskFilters.min_task_step_amount,
      filterKey: TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT,
    })
  }

  return filterArray
}
