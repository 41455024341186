import { Outlet, useLocation } from 'react-router-dom'

import { Tabs } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { SubPathEnum } from '../../common/types/routing'

enum TaskDiscoveryTabEnum {
  INDEX = 'INDEX',
  PREDEFINED_TASKS = 'PREDEFINED_TASKS',
}

const TABS = [
  {
    value: TaskDiscoveryTabEnum.INDEX,
    label: 'Task List',
  },
  {
    value: TaskDiscoveryTabEnum.PREDEFINED_TASKS,
    label: 'Predefined Tasks',
  },
]

const TaskDiscoveryLayout = () => {
  const { pathname } = useLocation()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const activeTab = pathname.includes(SubPathEnum.PREDEFINED_TASKS)
    ? TaskDiscoveryTabEnum.PREDEFINED_TASKS
    : TaskDiscoveryTabEnum.INDEX

  const onChangeTab = () => {
    pathname.includes(SubPathEnum.PREDEFINED_TASKS)
      ? navigate('')
      : navigate(SubPathEnum.PREDEFINED_TASKS)
  }
  return (
    <>
      <Tabs options={TABS} value={activeTab} onChange={onChangeTab} />
      <Outlet />
    </>
  )
}

export default TaskDiscoveryLayout
