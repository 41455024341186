import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { BriefcaseIcon } from '@heroicons/react/24/outline'

import { LinkButton, Text } from '../../../common/designs'
import { useEnhancedNavigate } from '../../../common/hooks'
import { MainPathEnum, SubPathEnum } from '../../../common/types/routing'
import { SubSearchParamEnum } from '../../../common/types/searchParams'
import type { ProcessGeneralInfo } from '../types'

interface Props {
  process: ProcessGeneralInfo
}

const TrackedProcessName = ({ process }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const processLink = `${MainPathEnum.PROCESSES}/${process.id}/${SubPathEnum.OVERVIEW}`
  const activeFilterId = process.active_filter_id

  return (
    <div className='w-[420px] overflow-hidden rounded-lg bg-white shadow'>
      <div className='px-4 py-2 md:px-6 md:pt-4'>
        <Text className='mb-3' size='xl' weight={600}>
          {process.name}
        </Text>

        <dt>
          <div className='inline-block rounded-md bg-indigo-500 p-3'>
            <BriefcaseIcon className='size-6 text-white' aria-hidden='true' />
          </div>
        </dt>
      </div>

      <div className='bg-gray-50 p-4 md:px-6'>
        <div className='text-sm'>
          <LinkButton
            className='flex w-[120px] gap-2'
            onClick={() => {
              navigate(`../${processLink}`, {
                search: `?${SubSearchParamEnum.PROCESS_FILTER}=${activeFilterId}`,
              })
            }}
          >
            View process
            <ArrowRightIcon className='size-5' />
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default TrackedProcessName
