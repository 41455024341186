import { useUpdateDataDeletionRequestMutation } from '../../app/apiSlice'
import { ActionModal } from '../../common/designs'

interface Props {
  open: boolean
  onClose: () => void
  id: null | number
}

const UpdateDataDeletionRequest = ({ id, open, onClose }: Props) => {
  const [updateDataDeletionRequest, { isLoading }] = useUpdateDataDeletionRequestMutation()

  return (
    <ActionModal
      variant='primary'
      label='Are you sure you want to cancel the data deletion request?'
      open={open}
      onAction={() => {
        if (!id) return

        updateDataDeletionRequest(id)
        onClose()
      }}
      onClose={onClose}
      loading={isLoading}
      actionLabel='Cancel Request'
    />
  )
}

export default UpdateDataDeletionRequest
