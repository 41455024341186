import { useMemo } from 'react'

import {
  useGetStepVariantAutomationInsightsDataQuery,
  useGetStepVariantTeamDataQuery,
  useGetStepVariantWorkTypeDataQuery,
} from '../../../../app/apiSlice'
import { useAppSelector, useDashboard, useQueryFilters } from '../../../../common/hooks'
import {
  formatNumber,
  getBestTimeFormatForSeconds,
} from '../../../../common/utils/numberFormatting'
import { selectHighlightSelections, selectStepVariantData } from '../../processesSlice'
import { HighlightFieldEnum } from '../../types'
import type { NodeDistributionData } from './types'

const WORK_TYPE_COLORS = {
  'Focused work': 'hsla(198, 83%, 36%, 1)',
  'Multi-system workflows': 'hsla(202, 83%, 21%, 1)',
  'Quick processing': 'hsla(202, 68%, 79%, 1)',
}

export const useNodeHighlightData = () => {
  const {
    isTeamDistributionOpen,
    isWorkTypeDistributionOpen,
    isAutomationInsightsOpen,
    activeHighlighField,
    isUnwindModeActive,
  } = useAppSelector(selectHighlightSelections)
  const { processId, processFiltersWithZooming } = useQueryFilters()
  const selectedVariants = useAppSelector(selectStepVariantData).map((v) => v.steps)
  const { teams } = useDashboard()

  const { data: teamServerData } = useGetStepVariantTeamDataQuery(
    {
      filters: processFiltersWithZooming,
      selected_variants: selectedVariants,
      processId,
      is_unwind_steps_mode: isUnwindModeActive,
    },
    { skip: !isTeamDistributionOpen },
  )

  const { data: workTypeServerData } = useGetStepVariantWorkTypeDataQuery(
    {
      filters: processFiltersWithZooming,
      selected_variants: selectedVariants,
      processId,
      is_unwind_steps_mode: isUnwindModeActive,
    },
    { skip: !isWorkTypeDistributionOpen },
  )
  const { data: automationInsightsData } = useGetStepVariantAutomationInsightsDataQuery(
    {
      filters: processFiltersWithZooming,
      selected_variants: selectedVariants,
      processId,
      is_unwind_steps_mode: isUnwindModeActive,
    },
    { skip: !isAutomationInsightsOpen },
  )

  const teamData = useMemo(() => {
    const data: { [key: string]: NodeDistributionData[] } = {}
    Object.entries(teamServerData ?? {}).forEach(([stepName, teamArray]) => {
      const sortedData = [...teamArray].sort((a, b) =>
        activeHighlighField === HighlightFieldEnum.ACTIVE_WORK
          ? b.duration_percentage - a.duration_percentage
          : b.visit_percentage - a.visit_percentage,
      )

      const top3Data = sortedData.slice(0, 3).map((t) => {
        const { colorIndex, name: teamName } = teams[t.group_name] ?? {
          colorIndex: 1,
          name: t.group_name,
        }
        const [percentage, total] =
          activeHighlighField === HighlightFieldEnum.ACTIVE_WORK
            ? [t.duration_percentage, getBestTimeFormatForSeconds(t.duration_sec)]
            : [t.visit_percentage, `${formatNumber(t.visit_count)} touches`]
        return { colorIndex, name: teamName, percentage, total }
      })

      const restData = sortedData.slice(3).reduce(
        (accumulator: any, w) => {
          const [percentage, total] =
            activeHighlighField === HighlightFieldEnum.ACTIVE_WORK
              ? [w.duration_percentage, w.duration_sec]
              : [w.visit_percentage, w.visit_count]
          accumulator.percentage += percentage
          accumulator.total += total

          return accumulator
        },
        { name: 'Rest', colorIndex: 5, total: 0, percentage: 0 },
      )

      data[stepName] = [...top3Data]
      if (restData.total) {
        data[stepName].push({
          ...restData,
          total:
            activeHighlighField === HighlightFieldEnum.ACTIVE_WORK
              ? getBestTimeFormatForSeconds(restData.total)
              : `${formatNumber(restData.total)} touches`,
        })
      }
    })
    return data
  }, [teamServerData, activeHighlighField, teams])

  const workTypeData = useMemo(() => {
    const data: { [key: string]: NodeDistributionData[] } = {}
    Object.entries(workTypeServerData ?? {}).forEach(([stepName, workTypeArray]) => {
      data[stepName] = workTypeArray
        .map((w) => {
          const name = w.group_name
          const color = WORK_TYPE_COLORS[name as keyof typeof WORK_TYPE_COLORS]
          const [percentage, total] =
            activeHighlighField === HighlightFieldEnum.ACTIVE_WORK
              ? [w.duration_percentage, getBestTimeFormatForSeconds(w.duration_sec)]
              : [w.visit_percentage, `${formatNumber(w.visit_count)} touches`]
          return { color, name, percentage, total }
        })
        .sort((a, b) => b.percentage - a.percentage)
    })
    return data
  }, [workTypeServerData, activeHighlighField])

  return {
    isTeamDistributionOpen,
    teamData,
    isWorkTypeDistributionOpen,
    workTypeData,
    automationInsightsData,
    isAutomationInsightsOpen,
  }
}
