import {
  formatNumber,
  getBestTimeFormatForSeconds,
} from '../../../../common/utils/numberFormatting'
import TaskFlowNode from '../../../TaskDiscovery/TaskFlowNode'
import TaskFlowNodeWithActivities from '../../../TaskDiscovery/TaskFlowNodeWithActivities'
import EndNode from './EndNode'
import StartNode from './StartNode'
import StateFlowNode from './StateFlowNode'
import StepFlowNode from './StepFlowNode'
import TeamFlowNode from './TeamFlowNode'
import { NodeTypeEnum } from './enums'
import type {
  ServerNodeTypes,
  ServerStepFlowNode,
  StateVariantData,
  StepVariantData,
} from './types'
import { VariantVisualizationLevelEnum } from './types'

export const configureInsightBarItems = (
  nodes: Array<ServerNodeTypes>,
  stepVariantData: StepVariantData[],
  stateVariantData: StateVariantData[],
  visualizationLevel: VariantVisualizationLevelEnum,
  currency: {
    name: string
    costPerHour: number
    symbol: string
  },
  formatCost: (cost: number) => string,
) => {
  switch (visualizationLevel) {
    case VariantVisualizationLevelEnum.STEP:
      const stepTransactions = stepVariantData.reduce(
        (totalCases, variantData) => totalCases + variantData.transactions,
        0,
      )
      const stepThroughputTimeSec = stepVariantData.reduce(
        (totalThroughputTime, variantData) =>
          totalThroughputTime + variantData.transactions * variantData.avg_throughput_time_sec,
        0,
      )
      const stepAvgThroughputTimeSec = stepThroughputTimeSec / stepTransactions

      const totalActiveWorkTimeSec = (nodes as ServerStepFlowNode[]).reduce(
        (totalActiveWork, nodeData) => totalActiveWork + nodeData.duration_sec,
        0,
      )
      const averageActiveWorkTimeSec = totalActiveWorkTimeSec / stepTransactions

      const costPerTransaction = (averageActiveWorkTimeSec / 3600) * currency.costPerHour

      return [
        { label: 'Variants', value: formatNumber(stepVariantData.length) },
        { label: 'Cases', value: formatNumber(stepTransactions) },
        {
          label: 'Avg. duration per case',
          value: `${getBestTimeFormatForSeconds(stepAvgThroughputTimeSec)}`,
        },
        {
          label: 'Total active work',
          value: `${getBestTimeFormatForSeconds(totalActiveWorkTimeSec)}`,
        },
        {
          label: 'Avg. active work time per case',
          value: `${getBestTimeFormatForSeconds(averageActiveWorkTimeSec)}`,
        },
        {
          label: 'Cost per case',
          value: formatCost(costPerTransaction),
          decription: 'Based on active work',
        },
      ]
    case VariantVisualizationLevelEnum.STATE:
    case VariantVisualizationLevelEnum.TEAM:
      const stateTransactions = stateVariantData.reduce(
        (totalCases, variantData) => totalCases + variantData.transactions,
        0,
      )
      const stateThroughputTimeSec = stateVariantData.reduce(
        (totalThroughputTime, variantData) =>
          totalThroughputTime + variantData.transactions * variantData.avg_throughput_time_sec,
        0,
      )
      const stateAvgThroughputTimeSec = stateThroughputTimeSec / stateTransactions

      return [
        { label: 'Variants', value: formatNumber(stateVariantData.length) },
        { label: 'Cases', value: formatNumber(stateTransactions) },
        {
          label: 'Avg. duration per case',
          value: `${getBestTimeFormatForSeconds(stateAvgThroughputTimeSec)}`,
        },
      ]

    // TODO
    case VariantVisualizationLevelEnum.WINDOW:
      return []

    default:
      return []
  }
}

export const configureNodeTypes = (
  visualizationLevel: VariantVisualizationLevelEnum,
  isDetailedActivitiesActive: boolean,
) => {
  switch (visualizationLevel) {
    case VariantVisualizationLevelEnum.STEP:
      return {
        [NodeTypeEnum.NORMAL]: StepFlowNode,
        [NodeTypeEnum.START]: StartNode,
        [NodeTypeEnum.END]: EndNode,
      }

    case VariantVisualizationLevelEnum.STATE:
      return {
        [NodeTypeEnum.NORMAL]: StateFlowNode,
        [NodeTypeEnum.START]: StartNode,
        [NodeTypeEnum.END]: EndNode,
      }
    case VariantVisualizationLevelEnum.TEAM:
      return {
        [NodeTypeEnum.NORMAL]: TeamFlowNode,
        [NodeTypeEnum.START]: StartNode,
        [NodeTypeEnum.END]: EndNode,
      }
    case VariantVisualizationLevelEnum.WINDOW:
      return {
        [NodeTypeEnum.NORMAL]: isDetailedActivitiesActive
          ? TaskFlowNodeWithActivities
          : TaskFlowNode,
        [NodeTypeEnum.START]: StartNode,
        [NodeTypeEnum.END]: EndNode,
      }

    default:
      return {
        [NodeTypeEnum.NORMAL]: StepFlowNode,
        [NodeTypeEnum.START]: StartNode,
        [NodeTypeEnum.END]: EndNode,
      }
  }
}

export const configureViewDescription = (visualizationLevel: VariantVisualizationLevelEnum) => {
  switch (visualizationLevel) {
    case VariantVisualizationLevelEnum.STEP:
      return `
      case flow describes the process as visible through the business app. It displays all the process tasks where the agent found a case identifier in the window, e.g., invoice number, claim number, or ticket number.
      
      This view helps you to understand the realistic task flows occurring in the process from the cases' point of view.
      `
    case VariantVisualizationLevelEnum.STATE:
      return `
        State flow describes how the process state changes throughout the case's life cycle, e.g., from received to approved. Zoom into the case flows to drill into the details.
        
        This view helps you understand the high-level process flow to discover the tasks needed to complete a case. 
        `
    case VariantVisualizationLevelEnum.TEAM:
      return `
      Team flow describes how the process progresses between work sessions, team members, and teams. The visualized tasks are about the process case moving to another team member or when the same person returns to the same case in a different working session, e.g., the next day.
      
      This view helps you to understand how teams work together and to identify the issues affecting duration.
      `

    // TODO
    case VariantVisualizationLevelEnum.WINDOW:
      return ''

    default:
      return ''
  }
}

