import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type { RuleIn } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { Application } from '../../types/dataCollectionsConfigs'

export interface TestCaseWithTestResult extends ConfigTestCase {
  isFailing: boolean
  isNotTested: boolean
  errors?: string[] | null
}

export interface AppTestCaseGroup {
  appName: string
  appDashboardConfigs: Application
  testCases: TestCaseWithTestResult[]
  rules: RuleIn[]
  processRules: RuleIn[]
  windowLevelData: WindowTestCaseGroup[]

  hasFailingTests: boolean
  hasNotRunTests: boolean
  hasNoDataCollectionRules: boolean
}

export interface WindowTestCaseGroup {
  windowName: string
  testCases: TestCaseWithTestResult[]
  rulesForTestCases: RuleIn[]
  failingTestCaseIds: string[]
}

export interface DataCollectionGranularityForm {
  isTitleCaptured: boolean
  isUrlCaptured: boolean
  isTypingCaptured: boolean
  isClipboardContentCaptured: boolean
  isKeyboardShortcutsCaptured: boolean
  isInteractedElementsCaptured: boolean
}

export interface RuleCodeForm {
  code: string
}

export enum ApplicatioConfigTabEnum {
  APPLICATION_DATA = 'APPLICATION_DATA',
  WINDOW_DATA = 'WINDOW_DATA',
  PROCESS_DATA = 'PROCESS_DATA',
}
