import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { format, isValid, parse } from 'date-fns'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import { teamBgColorMap } from '../../../../app/theme'
import { InputCalendar, InputField, SelectMultiple } from '../../../../common/designs'
import { useDashboard, useLocalStorage } from '../../../../common/hooks'
import { GLOBAL_DATE_FORMAT } from '../../../../common/types/datetime'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import { removeNullsFromObject } from '../../../../common/utils/utils'
import type { ProcessMetricFilters as Inputs } from '../../types'
import DimensionFilterBlock from './DimensionFilterBlock'
import FilterItem from './FilterItem'
import { processPropertyDimensions } from './constants'
import type { FilterConfigData } from './types'
import {
  convertProcessMetricsSecsToHoursOrMinutes,
  generateDefaultProcessMetricsValues,
  removeDimensionFilters,
  updateDimensionFilters,
} from './utils'

const formSchema = yup.object().shape({
  min_start_date: yup
    .mixed()
    .nullable()
    .test(
      'is-date',
      'Min start date must be a valid date in the format "yyyy-MM-dd"',
      function (value) {
        if (!value) return true

        return isValid(parse(value as any, 'yyyy-MM-dd', new Date()))
      },
    ),
  min_end_date: yup
    .mixed()
    .nullable()
    .test(
      'is-date',
      'Min end date must be a valid date in the format "yyyy-MM-dd"',
      function (value) {
        if (!value) return true

        return isValid(parse(value as any, 'yyyy-MM-dd', new Date()))
      },
    ),
  max_end_date: yup
    .mixed()
    .nullable()
    .test(
      'is-date',
      'Max end date must be a valid date in the format "yyyy-MM-dd"',
      function (value) {
        if (!value) return true

        return isValid(parse(value as any, 'yyyy-MM-dd', new Date()))
      },
    ),
  team_ids: yup.array().of(yup.string().required()),
  min_active_work_sec: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .nullable()
    .min(0, 'Min must be a positive integer or empty')
    .integer('Min must be an integer or empty'),
  max_active_work_sec: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .nullable()
    .min(0, 'Min must be a positive integer or empty')
    .integer('Max must be an integer or empty')
    .test('max-greater-than-min', 'Max cannot be less than min', function (value) {
      const { min_active_work_sec } = this.parent
      if (min_active_work_sec === undefined || value === undefined || value === null) {
        return true
      }
      return value >= min_active_work_sec
    }),
  min_duration_sec: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .nullable()
    .min(0, 'Min must be a positive integer or empty')
    .integer('Min must be an integer or empty'),
  max_duration_sec: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .nullable()
    .min(0, 'Min must be a positive integer or empty')
    .integer('Max must be an integer or empty')
    .test('max-greater-than-min', 'Max cannot be less than min', function (value) {
      const { min_duration_sec } = this.parent
      if (min_duration_sec === undefined || value === undefined || value === null) {
        return true
      }
      return value >= min_duration_sec
    }),
})

const ProcessFilters = () => {
  const storage = useLocalStorage()
  const { dataDate, teams } = useDashboard()

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema) as Resolver<Inputs>,
    defaultValues: generateDefaultProcessMetricsValues(storage),
  })

  const [businessPropertiesConfigurations, setBusinessPropertiesConfigurations] = useState<
    FilterConfigData[]
  >([])
  const [businessPropertiesSelected, setBusinessPropertiesSelected] = useState<FilterConfigData[]>(
    [],
  )
  const [processPropertiesConfigurations, setProcessPropertiesConfigurations] = useState<
    FilterConfigData[]
  >([])
  const [processPropertiesSelected, setProcessPropertiesSelected] = useState<FilterConfigData[]>([])

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    data = convertProcessMetricsSecsToHoursOrMinutes(data)

    storage.updateItem(StorageNameEnum.PROCESS_FILTERS, {
      [ProcessFiltersStorageContentEnum.PROCESS_METRICS]: removeNullsFromObject(data),
    })
  }

  const onApply = () => {
    // Handle process properties state changes and update local storage.
    updateDimensionFilters(
      processPropertiesSelected,
      setProcessPropertiesSelected,
      ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
      storage,
    )

    // Handle business properties state changes and update local storage.
    updateDimensionFilters(
      businessPropertiesSelected,
      setBusinessPropertiesSelected,
      ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES,
      storage,
    )
  }

  const onReset = async () => {
    // Handle process properties form changes and update local storage.
    removeDimensionFilters(ProcessFiltersStorageContentEnum.PROCESS_METRICS, storage)
    reset({
      team_ids: [],
      min_start_date: null as any,
      min_end_date: null as any,
      max_end_date: null as any,
      min_active_work_sec: '' as any,
      max_active_work_sec: '' as any,
      min_duration_sec: '' as any,
      max_duration_sec: '' as any,
    })

    // Handle process properties state changes and update local storage.
    removeDimensionFilters(ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES, storage)
    setProcessPropertiesSelected([])

    // Handle business properties state changes and update local storage.
    removeDimensionFilters(ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES, storage)
    setBusinessPropertiesSelected([])
  }

  return (
    <form
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      className='flex min-h-[400px] flex-col'
    >
      <div className='flex min-h-[400px] flex-col'>
        <>
          <FilterItem.Title>Date and teams</FilterItem.Title>

          <div className='flex flex-col gap-4'>
            <div className='flex gap-4'>
              <div className='w-full'>
                <FilterItem.Description>Start date</FilterItem.Description>

                <Controller
                  name='min_start_date'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <InputCalendar
                      placeholderText='Min'
                      selected={value ? new Date(value) : undefined}
                      minDate={new Date(dataDate.min)}
                      maxDate={
                        new Date(watch('min_end_date') ?? watch('max_end_date') ?? dataDate.max)
                      }
                      onChange={(val) => {
                        onChange(val === null ? undefined : format(val, GLOBAL_DATE_FORMAT))
                      }}
                      onClear={() => setValue('min_start_date', null as any)}
                    />
                  )}
                />
              </div>

              <div className='w-full'>
                <FilterItem.Description>End date</FilterItem.Description>

                <div className='flex w-[337px] items-baseline gap-4'>
                  <Controller
                    name='min_end_date'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <InputCalendar
                        placeholderText='Min'
                        selected={value ? new Date(value) : undefined}
                        minDate={new Date(watch('min_start_date') ?? dataDate.min)}
                        maxDate={new Date(watch('max_end_date') ?? dataDate.max)}
                        onChange={(val) => {
                          onChange(val === null ? undefined : format(val, GLOBAL_DATE_FORMAT))
                        }}
                        onClear={() => setValue('min_end_date', null as any)}
                      />
                    )}
                  />
                  <FilterItem.ToLabel />
                  <Controller
                    name='max_end_date'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <InputCalendar
                        placeholderText='Max'
                        selected={value ? new Date(value) : undefined}
                        minDate={
                          new Date(watch('min_end_date') ?? watch('min_start_date') ?? dataDate.min)
                        }
                        maxDate={new Date(dataDate.max)}
                        onChange={(val) => {
                          onChange(val === null ? undefined : format(val, GLOBAL_DATE_FORMAT))
                        }}
                        onClear={() => setValue('max_end_date', null as any)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <Controller
              name='team_ids'
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectMultiple
                  label='Team(s) that have been part of a case'
                  options={Object.values(teams)
                    .map((t) => ({
                      value: t.id,
                      label: t.name,
                      prefix: (
                        <div className={clsx('size-3 rounded', teamBgColorMap[t.colorIndex])} />
                      ),
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={value ?? []}
                  onChange={(val: string[]) => onChange(val)}
                  selectedConfiguration={{ max: 2, label: 'Selected' }}
                />
              )}
            />
          </div>

          <FilterItem.Divider />

          <FilterItem.Title>Metrics</FilterItem.Title>

          <div className='flex gap-4'>
            <FilterItem.Description className='w-1/2'>
              Active work time (minutes)
            </FilterItem.Description>

            <FilterItem.Description className='w-1/2'>Duration (hours)</FilterItem.Description>
          </div>

          <div>
            <div className='flex items-baseline gap-4'>
              <Controller
                name='min_active_work_sec'
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder='Min'
                    type='number'
                    error={errors.min_active_work_sec?.message}
                    {...field}
                  />
                )}
              />
              <FilterItem.ToLabel />
              <Controller
                name='max_active_work_sec'
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder='Max'
                    type='number'
                    error={errors.max_active_work_sec?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name='min_duration_sec'
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder='Min'
                    type='number'
                    error={errors.min_duration_sec?.message}
                    {...field}
                  />
                )}
              />
              <FilterItem.ToLabel />
              <Controller
                name='max_duration_sec'
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder='Max'
                    type='number'
                    error={errors.max_duration_sec?.message}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </>

        <FilterItem.Divider />

        <DimensionFilterBlock
          title='Process properties'
          storageKey={ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES}
          dimensions={processPropertyDimensions}
          configurations={processPropertiesConfigurations}
          setConfigurations={setProcessPropertiesConfigurations}
          selected={processPropertiesSelected}
          setSelected={setProcessPropertiesSelected}
        />

        <FilterItem.Divider />

        <DimensionFilterBlock
          title='Business properties'
          storageKey={ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES}
          isCustomDimensionBlock
          configurations={businessPropertiesConfigurations}
          setConfigurations={setBusinessPropertiesConfigurations}
          selected={businessPropertiesSelected}
          setSelected={setBusinessPropertiesSelected}
        />

        <FilterItem.Divider />

        <FilterItem.Actions onReset={onReset} onApply={onApply} />
      </div>
    </form>
  )
}

export default ProcessFilters
