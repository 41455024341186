import type {
  DataFlowEdge,
  DataFlowNode,
  EnhancedEdge,
  EnhancedNode,
  NavigationEdge,
  NavigationNode,
} from 'react-graph-vis'

import { Tag } from '../../common/types/common'
import type { ApplicationCategory } from '../../common/types/dataCollectionsConfigs'
import { SimpleDate } from '../../common/types/datetime'

export enum NetworkTypeEnum {
  COPY_PASTE = 'COPY_PASTE',
  NAVIGATION = 'NAVIGATION',
}

export enum NetworkEntityEnum {
  NODE = 'node',
  EDGE = 'edge',
}

export enum VisualizationModeEnum {
  NETWORK = 'network',
  TABLE = 'table',
}

export enum DetailLevelEnum {
  APP = 'app',
  WINDOW = 'window',
}

export interface NetworkDataFlowsParams {
  min_date: SimpleDate
  max_date: SimpleDate
  team_ids: string[]
  tags_of_interest: string[]
  node_amount: number
  tag_filters: Tag[]
  match_any_tag_filters?: Array<Tag[]>
}

export interface NodeFlyoutStatsInsights {
  node: EnhancedNode
  externalCopies: number
  externalPastes: number
  selfLoopCopyPastes: number
  annualizedCost: string
}

export interface EdgeFlyoutStatsInsights {
  edge: EnhancedEdge
  annualizedCost: string
}

export interface DataFlowNodeServer {
  id: string
  x: number
  y: number
  value: number
  group: string
  label: string
  properties: {
    team_ids: string[]
    tags: Tag[]
    copies: number
    pastes: number
  }
}

export interface DataFlowEdgeServer {
  id: string
  to: string | undefined
  from: string | undefined
  value: number
  properties: {
    team_ids: string[]
    source_label: string
    source_tags: Tag[]
    target_label: string
    target_tags: Tag[]
  }
}
export interface DataFlowInsightNode {
  entity: DataFlowNode
  externalCopies: number
  externalPastes: number
  selfLoopCopyPastes: number
  annualizedCost: string
}

export interface DataFlowInsightEdge {
  entity: DataFlowEdge
  annualizedCost: string
}

export interface DataFlowInsight {
  node: DataFlowInsightNode | null
  edge: DataFlowInsightEdge | null
}

export interface NavigationInsightNode {
  entity: NavigationNode
  originVisits?: number | null
  visits: number
  totalH: number
  annualizedCost: string
}

export interface NavigationInsightEdge {
  entity: NavigationEdge
  navigations: number
  annualizedCost: string
}
export interface NavigationInsight {
  node: NavigationInsightNode | null
  edge: NavigationInsightEdge | null
}

export interface NetworkDataFlowsResponse {
  nodes: { [key: string]: DataFlowNodeServer }
  edges: { [key: string]: DataFlowEdgeServer }
}

export interface NetworkNavigationsResponse {
  nodes: { [key: string]: NavigationNode }
  edges: { [key: string]: NavigationEdge }
}

export type ClusterVisible = { [key in ApplicationCategory]: boolean }
