import { cloneElement } from 'react'

import { Text } from '../../designs'

interface Props {
  stepNumber: number
  title: string
  icon: React.ReactElement
  description: string
  children: React.ReactNode
}

const UserFlowStepContainer = ({ stepNumber, title, description, icon, children }: Props) => {
  return (
    <div className='flex gap-3 py-8'>
      <div>
        {cloneElement(icon, { className: 'h-15 w-15 flex-none text-blue-800 lg:mx-6 xl:mx-8' })}
      </div>

      <div className='w-full'>
        <Text size='s' weight={600} className='mb-0.5 capitalize !text-blue-800'>
          Task {stepNumber}
        </Text>
        <Text size='l' weight={500} variant='h3' className='mb-4'>
          {title}
        </Text>

        <Text size='s' className='mb-4'>
          {description}
        </Text>

        {children}
      </div>
    </div>
  )
}

export default UserFlowStepContainer

