import { Outlet, useOutletContext } from 'react-router-dom'

import {
  useGetUserInteractionEventsQuery,
  useGetWindowDataflowsDestinationQuery,
  useGetWindowDataflowsSourceQuery,
} from '../../../../app/apiSlice'
import { Loader } from '../../../../common/components'
import { useQueryFilters } from '../../../../common/hooks'
import type { Tag } from '../../../../common/types/common'
import type { UserInteractionEvents } from '../../types'
import type { SecondLevelLayoutOutletContext } from '../SecondLevelLayout'
import { parseDataflow } from './parseDataflow'

export interface ActivitiesLayoutOutletContext {
  data: UserInteractionEvents | undefined
  tagFilters: Tag[]
}

const ActivitiesLayout = () => {
  const { tagFilters } = useOutletContext<SecondLevelLayoutOutletContext>()
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()

  const queryOptions = {
    min_date,
    max_date,
    team_ids,
    tags_of_interest: tags_of_interest.window,
    tag_filters: tagFilters,
  }

  const { data: interactionData, isFetching: isFetchingInteractionData } =
    useGetUserInteractionEventsQuery(queryOptions)
  const { data: copyData, isFetching: isFetchingCopyData } =
    useGetWindowDataflowsSourceQuery(queryOptions)
  const { data: pasteData, isFetching: isFetchingPasteData } =
    useGetWindowDataflowsDestinationQuery(queryOptions)

  if (isFetchingInteractionData || isFetchingCopyData || isFetchingPasteData) return <Loader />

  const data = {
    copies: parseDataflow(copyData, 'source_name', 'source_tags'),
    pastes: parseDataflow(pasteData, 'destination_name', 'destination_tags'),
    ...interactionData,
  }

  return <Outlet context={{ data, tagFilters }} />
}

export default ActivitiesLayout
