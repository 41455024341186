import { useOutletContext } from 'react-router-dom'

import { useEnhancedNavigate } from '../../../common/hooks'
import TeamsChartView from '../trendAndTeamViews/TeamsChartView'
import type { SecondLevelLayoutOutletContext } from './SecondLevelLayout'

const Teams = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { tagFilters, appName } = useOutletContext<SecondLevelLayoutOutletContext>()

  const onChartClick = (compressedAppTags: string) => navigate(`../../window/${compressedAppTags}`)

  return (
    <TeamsChartView
      chartTitle={`${appName} usage - Team comparison`}
      relativeChartTitle={`${appName} usage - Team comparison - Relative`}
      tagsOfInterestSource='window'
      tagFilters={tagFilters}
      onChartClick={onChartClick}
    />
  )
}

export default Teams
