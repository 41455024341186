import type { BasicColorIndex } from '../../app/theme'
import { TagFilteringLogicEnum } from '../../features/Processes/ProcessMining/Analysis/types'

export enum EnvEnum {
  SB = 'SB', // Sandbox
  DEV = 'DEV', // Development
  STG = 'STG', // Staging
  PROD = 'PROD', // Production
}

// * Settings * //
export interface UserRolesNumbered {
  1: 'Admin'
  2: 'Contributor'
  3: 'Data reader'
  6: 'Platform Viewer'
}

export enum UserRoleMappingEnum {
  ADMIN = 1,
  CONTRIBUTOR = 2,
  DATA_READER = 3,
  PLATFORM_VIEWER = 6,
}

export interface Tag {
  [key: string]: string
}

export interface TagFilter {
  tag_name: string
  tag_filtering_logic: TagFilteringLogicEnum
  values: string[]
}

export type TagsOfInterestList = string[]

export interface Dataset {
  data: number[]
  label?: string
  backgroundColor?: string | string[]
  borderRadius?: number
  hoverBackgroundColor?: string
  borderColor?: string
  fill?: any
  tension?: number
}

export interface ChartData {
  labels: string[]
  datasets: Dataset[]
}

export interface ActivityInsightInfo {
  title: string
  description: string
}

export interface Team {
  id: number
  team_id: string
  team_name: string
  color_index: BasicColorIndex
  cost_per_hour: number | null
  currency: string
  currency_symbol_UTF8: string
  invitation_token: string
  configuration_source: string
}

export type NewTeam = Pick<
  Team,
  'team_name' | 'color_index' | 'cost_per_hour' | 'currency' | 'currency_symbol_UTF8'
>

export interface BaseCompany {
  company_id: number
  company_name: string
  company_uuid: string
}

export interface Company extends BaseCompany {
  cost_per_hour: number
  currency: string
  currency_symbol_UTF8: string
  agent_installation_target: number
  customer_status: string
}

export interface TeamGroup {
  group_id: string
  group_name: string
  group_members: string[]
}

export type PercentageValue =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53
  | 54
  | 55
  | 56
  | 57
  | 58
  | 59
  | 60
  | 61
  | 62
  | 63
  | 64
  | 65
  | 66
  | 67
  | 68
  | 69
  | 70
  | 71
  | 72
  | 73
  | 74
  | 75
  | 76
  | 77
  | 78
  | 79
  | 80
  | 81
  | 82
  | 83
  | 84
  | 85
  | 86
  | 87
  | 88
  | 89
  | 90
  | 91
  | 92
  | 93
  | 94
  | 95
  | 96
  | 97
  | 98
  | 99
  | 100

export type MaxRowLength = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export type JsonObject = { [key: string]: any }
