import { PlusIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react'

import { Select } from '../../../../common/designs'
import type { SelectOption } from '../../../../common/designs'
import { useAppSelector, useLocalStorage } from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import type { ProcessFiltersStorageContent } from '../../../../common/types/storage'
import { isDimensionFilterOptionArray } from '../../../../common/types/typeGuards'
import { selectDimensionFilterOptions } from '../../processesSlice'
import { GroupByFieldTypeEnum } from '../../types'
import DimensionFilterInput from './DimensionFilterInput'
import FilterItem from './FilterItem'
import type { Dimensions } from './constants'
import type { DimensionFilterOption, FilterConfigData } from './types'

interface Props {
  title: string
  storageKey: ProcessFiltersStorageContentEnum
  dimensions?: Dimensions
  isCustomDimensionBlock?: boolean

  configurations: FilterConfigData[]
  setConfigurations: React.Dispatch<React.SetStateAction<FilterConfigData[]>>
  selected: FilterConfigData[]
  setSelected: React.Dispatch<React.SetStateAction<FilterConfigData[]>>
}

const DimensionFilterBlock = (props: Props) => {
  const {
    title,
    storageKey,
    dimensions,
    isCustomDimensionBlock,
    configurations,
    setConfigurations,
    selected,
    setSelected,
  } = props
  const options = useAppSelector(selectDimensionFilterOptions)
  const storage = useLocalStorage()

  useEffect(() => {
    setFilterOptions()
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  const setFilterOptions = () => {
    if (!options) return

    const processFilters = storage.getItem<ProcessFiltersStorageContent>(
      StorageNameEnum.PROCESS_FILTERS,
    )
    const activeFilters = processFilters?.[storageKey] ?? {}

    // Initialize filters: Set select options and initialize selected values to be empty array.
    const filters = options
      .map((f) => {
        if (dimensions && !isCustomDimensionBlock) {
          const fieldKey = f.group_by.field_type as keyof typeof dimensions
          const filterConfigs = dimensions?.[fieldKey]

          // Is default dimension
          if (filterConfigs) {
            return {
              key: filterConfigs.displayName,
              ...filterConfigs,
              options: f.options,
              selectedOptions: [],
              shouldUseMatchAll: false,
            } as FilterConfigData
          }
        } else {
          // Is custom tag dimension
          if (f.group_by.field_type === GroupByFieldTypeEnum.TAG_NAME) {
            return {
              key: f.display_name ?? 'Custom tag',
              requestFieldName: 'tag_filters',
              requestFieldNameMatchAll: 'tag_filters_match_all',
              isEditable: true,
              tagName: f.group_by.tag_name,
              tagFilteringLogic: f.group_by.tag_filtering_logic,
              options: f.options,
              selectedOptions: [],
              shouldUseMatchAll: false,
            } as FilterConfigData
          }
        }

        return null
      })
      .filter(Boolean) as FilterConfigData[]
    setConfigurations(filters)

    // Set selected values for options based on active state

    const updatedSelections: FilterConfigData[] = []

    Object.entries(activeFilters).forEach(([key, value]) => {
      // Custom dimension filters.
      if (key === 'tag_filters' || key === 'tag_filters_match_all') {
        if (!Array.isArray(value)) return

        value.forEach((t: { values: string[]; tag_name: string }) => {
          const f = filters.find((f) => t.tag_name === f.tagName)

          if (!f) return
          if (key === 'tag_filters_match_all') f.shouldUseMatchAll = true

          f.selectedOptions = value
            .filter((v) => v.tag_name === t.tag_name)
            .map((v) => v.values)
            .flatMap((v) => v) as DimensionFilterOption[]

          updatedSelections.push(f)
        })
      } else {
        // Normal dimension filters.
        const f = filters.find(
          (f) => key === f.requestFieldName || key === f.requestFieldNameMatchAll,
        )
        if (!f || !isDimensionFilterOptionArray(value)) return

        if (key === f.requestFieldNameMatchAll) f.shouldUseMatchAll = true
        f.selectedOptions = value

        updatedSelections.push(f)
      }
    })

    setSelected(updatedSelections)
  }

  const onSelectFilter = (filterKey: string) => {
    const selectedFilterConfig = configurations.find((f) => f.key === filterKey)
    if (selectedFilterConfig) {
      setSelected((state) => [...state, selectedFilterConfig])
    }
  }

  const onRemoveFilter = (filterKey: string) => {
    setSelected((state) => state.filter((f) => f.key !== filterKey))
  }

  const onSetFilterValues = (filterKey: string, selectedOptions: DimensionFilterOption[]) => {
    setSelected((state) =>
      state.map((filter) => {
        return filter.key === filterKey ? { ...filter, selectedOptions } : filter
      }),
    )
  }

  const onSetShouldUseMatchAll = (filterKey: string, shouldUseMatchAll: boolean) => {
    setSelected((state) =>
      state.map((filter) => {
        return filter.key === filterKey ? { ...filter, shouldUseMatchAll } : filter
      }),
    )
  }

  const defaultValue: SelectOption = {
    label: 'Choose filter',
    value: 'Choose filter',
    prefix: <PlusIcon className='size-5' />,
  }

  const defaultValueNoOptions: SelectOption = {
    label: 'All filters have been selected',
    value: 'All filters have been selected',
  }

  const isAllDimensionsSelected = isCustomDimensionBlock
    ? configurations.length === selected.length // If all custom dimensions are selected
    : Object.keys(dimensions ?? {}).length === selected.length // If all default dimensions are selected

  return (
    <>
      <FilterItem.Header>
        <FilterItem.Title>{title}</FilterItem.Title>

        {isAllDimensionsSelected ? (
          <Select
            options={[defaultValueNoOptions]}
            value={defaultValueNoOptions.value}
            onChange={(() => null) as any}
            disabled={true}
            className='w-3/5'
          />
        ) : (
          <Select
            options={[
              defaultValue,
              ...configurations
                .filter((f) => f.isEditable && !selected.find(({ key }) => f.key === key))
                .map(({ key }) => ({ label: key, value: key }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            ]}
            value={defaultValue.value}
            onChange={onSelectFilter}
            disabled={Boolean(!configurations.length)}
            className='w-3/5'
          />
        )}
      </FilterItem.Header>

      {selected.map((f) => (
        <DimensionFilterInput
          filter={f}
          key={f.key}
          onRemoveFilter={onRemoveFilter}
          onSetFilterValues={onSetFilterValues}
          shouldUseMatchAllEnabled={Boolean(f.requestFieldNameMatchAll)}
          shouldUseMatchAll={f.shouldUseMatchAll}
          onSetShouldUseMatchAll={onSetShouldUseMatchAll}
          shouldMapTeamIds={f.requestFieldName === 'team_list_filters'}
        />
      ))}
    </>
  )
}

export default DimensionFilterBlock
