import { differenceInDays } from 'date-fns'
import { useMemo } from 'react'

import type { Team } from '../utils/UtilityWrapper/types'
import { formatNumber } from '../utils/numberFormatting'
import { useDashboard, useQueryFilters } from './'

const useCostUtils = () => {
  const { min_date: minDate, max_date: maxDate, team_ids: teamIds } = useQueryFilters()
  const { company, teams } = useDashboard()

  const currency = useMemo(() => {
    const teamId: string | undefined = teamIds[0]
    const team: Team | null = teams[teamId]

    // Unless one specific team is selected then default to company cost.
    const isCompanyCostInUse = teamIds.length === 0 || teamIds.length > 1
    if (isCompanyCostInUse || !team) {
      return {
        name: company.currency,
        costPerHour: company.costPerHour,
        symbol: company.currencySymbol,
      }
    } else {
      return { name: team.currency, costPerHour: team.costPerHour, symbol: team.currencySymbol }
    }
  }, [teamIds, company, teams])

  const annualizedCostMultiplier =
    (365 / differenceInDays(new Date(maxDate), new Date(minDate)) + 1) * currency.costPerHour

  const generateAnnualizedCopyPasteCost = (sum: number) => {
    const COPY_PASTE_SLEEVE_CONSTANT = 0.00194444

    return formatCost(sum * COPY_PASTE_SLEEVE_CONSTANT * annualizedCostMultiplier)
  }

  const generateAnnualizedNavigationsCost = (sum: number) => {
    const NAVIGATION_SLEEVE_CONSTANT = 0.0008333 // 3 Seconds per navigation

    return formatCost(sum * NAVIGATION_SLEEVE_CONSTANT * annualizedCostMultiplier)
  }

  const generateFormattedAnnualizedCost = (sum: number) => {
    return formatCost(sum * annualizedCostMultiplier)
  }

  const formatCost = (cost: number) => `${formatNumber(cost, 1)} ${currency.symbol}`

  return {
    currency,
    annualizedCostMultiplier,
    generateAnnualizedCopyPasteCost,
    generateAnnualizedNavigationsCost,
    generateFormattedAnnualizedCost,
    formatCost,
  }
}

export default useCostUtils
