import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string'

// Naive
type JSON = Record<string, unknown> | Record<string, unknown>[] | string[] | string

export const compressAndEncodeJSON = <TParams extends JSON>(params: TParams): string => {
  const stringifiedParams = JSON.stringify(params)

  return compressToEncodedURIComponent(stringifiedParams)
}

export const decompressAndDecodeJSON = <TParams extends JSON>(encodedParams: string): TParams => {
  const unencodedString = decompressFromEncodedURIComponent(encodedParams) as string

  return JSON.parse(unencodedString) as TParams
}
