import { ChartData } from 'chart.js'

import { CHART_PRIMARY_HSLA } from '../../../app/theme'
import { getVisualizationColor } from '../../utils/colorUtils'
import type { ChartDataEntry, GroupedBarChartDataEntry } from './types'

export const dataToChartData = (
  data: ChartDataEntry[],
  chartColor: string | undefined,
  borderRadius: number | undefined,
  visibleDataAmount: number | undefined,
  useReversedDataOrder: boolean | undefined,
) => {
  const noDataLimit = !visibleDataAmount
  const visibleData = noDataLimit
    ? data
    : useReversedDataOrder
      ? data.slice(-visibleDataAmount)
      : data.slice(0, visibleDataAmount)
  const uniqueColors = [
    ...new Set(visibleData.map((e) => e.color ?? chartColor ?? CHART_PRIMARY_HSLA)),
  ]
  const datasets = uniqueColors.map((c) => ({
    data: [],
    backgroundColor: c,
    borderRadius: borderRadius ?? 6,
  }))

  const colorToDataSetIndex: { [key: string]: number } = {}
  uniqueColors.forEach((c, i) => (colorToDataSetIndex[c] = i))

  const chartData: ChartData<'bar'> = {
    labels: [],
    datasets,
  }

  visibleData.forEach((item) => {
    chartData.labels?.push(item.label)

    const color = item.color ?? chartColor ?? CHART_PRIMARY_HSLA
    const dataSetIndex = colorToDataSetIndex[color]
    chartData.datasets.forEach((dataset, index) => {
      if (index !== dataSetIndex) dataset.data.push(0)
      else dataset.data.push(item.value)
    })
  })

  return chartData
}

export const groupedDataToGroupedChartData = (
  inputData: GroupedBarChartDataEntry[],
  borderRadius: number | undefined,
  visibleDataAmount: number | undefined,
) => {
  const noDataLimit = !visibleDataAmount
  const orderedData = orderGroupedBarchartData(inputData)
  const labelDataSet = new Set()
  orderedData?.forEach(({ data }) => {
    data.forEach(({ label }) => labelDataSet.add(label))
  })
  const labelData = [...labelDataSet]
  const labels = noDataLimit ? labelData : labelData.slice(0, visibleDataAmount)

  const datasets = orderedData.map(({ groupName, color, data }, i) => {
    const visibleData = noDataLimit ? data : data.slice(0, visibleDataAmount)

    return {
      data: visibleData.map((item) => item.value),
      label: groupName,
      backgroundColor: color ?? getVisualizationColor(i),
      borderRadius: borderRadius ?? 6,
    }
  })

  const chartData: ChartData<'bar'> = { labels, datasets }
  return chartData
}

export const orderGroupedBarchartData = (inputData: GroupedBarChartDataEntry[]) => {
  const labelValueCounter: { [key: string]: number } = {}
  const groupNumberDataMapper: { [groupNumber: number]: { [label: string]: number } } = {}

  inputData.forEach(({ data }, groupNumber) => {
    groupNumberDataMapper[groupNumber] = {}
    data.forEach(({ label, value }) => {
      if (label in labelValueCounter) {
        labelValueCounter[label] += value
      } else {
        labelValueCounter[label] = value
      }

      groupNumberDataMapper[groupNumber][label] = value
    })
  })

  const labelArray = Object.entries(labelValueCounter)
  labelArray.sort((a, b) => b[1] - a[1])

  return inputData.map((d, groupNumber) => {
    const valueDataForGroup = groupNumberDataMapper[groupNumber]
    return {
      ...d,
      data: labelArray.map(([label]) => ({
        label,
        value: valueDataForGroup[label] ?? 0,
      })),
    } as GroupedBarChartDataEntry
  })
}

export const dataToDoughnutChartData = (
  data: ChartDataEntry[],
  unit: string,
  chartColor: string | undefined,
  visibleDataAmount: number | undefined,
) => {
  const noDataLimit = !visibleDataAmount
  const visibleData = noDataLimit ? data : data.slice(0, visibleDataAmount)

  const chartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        label: unit,
        data: [],
        backgroundColor: [],
      },
    ],
  }

  visibleData.forEach((item) => {
    chartData.labels?.push(item.label)
    ;(chartData.datasets[0].data as number[]).push(item.value)
    ;(chartData.datasets[0].backgroundColor as string[]).push(
      item.color ?? chartColor ?? CHART_PRIMARY_HSLA,
    )
  })

  return chartData
}
