import { ReactElement } from 'react'

import { Text } from '../../designs'
import { getKeyFromValueOrElement } from '../../utils/reactUtils'

interface Props {
  label: string
  children: ReactElement
  description?: string | undefined
  toggleActions?: ReactElement[] | undefined
  selectActions?: ReactElement[] | undefined
  otherActions?: ReactElement[] | undefined
}

const VisualizationContainer = (props: Props) => {
  const { label, children, description, toggleActions, selectActions, otherActions } = props

  return (
    <div className='bg-white px-8 py-4 shadow-md ring-2 ring-black/5 md:rounded-lg'>
      <div className='mb-3 flex'>
        <div className='flex-auto'>
          <Text size='l' weight={600}>
            {label}
          </Text>
          {description && <Text size='s'>{description}</Text>}
        </div>

        <div className='flex shrink-0 gap-2'>
          {toggleActions &&
            toggleActions.map((t) => (
              <div key={getKeyFromValueOrElement(t)} className='pt-2'>
                {t}
              </div>
            ))}

          {selectActions &&
            selectActions.map((t) => (
              <div key={getKeyFromValueOrElement(t)} className='w-52'>
                {t}
              </div>
            ))}

          {otherActions &&
            otherActions.map((t) => <div key={getKeyFromValueOrElement(t)}>{t}</div>)}
        </div>
      </div>

      {children}
    </div>
  )
}

export default VisualizationContainer
