import clsx from 'clsx'
import { ReactNode } from 'react'

const backgroundSizes: { [key in Size]: string } = {
  xs: 'h-[30px] w-[30px] min-h-[30px] min-w-[30px] max-h-[30px] max-w-[30px]',
  s: 'h-[34px] w-[34px] min-h-[34px] min-w-[34px] max-h-[34px] max-w-[34px]',
  m: 'h-[38px] w-[38px] min-h-[38px] min-w-[38px] max-h-[38px] max-w-[38px]',
  l: 'h-[42px] w-[42px] min-h-[42px] min-w-[42px] max-h-[42px] max-w-[42px]',
  xl: 'h-[50px] w-[50px] min-h-[50px] min-w-[50px] max-h-[50px] max-w-[50px]',
}

const contentSizes: { [key in Size]: string } = {
  xs: 'text-xs',
  s: 'text-sm',
  m: 'text-base',
  l: 'text-lg',
  xl: 'text-xl',
}

const backgroundStyles: { [key in Variant]: string } = {
  default: 'bg-gray-100',
  gray: 'bg-gray-400',
  red: 'bg-red-400',
  yellow: 'bg-yellow-400',
  amber: 'bg-amber-400',
  orange: 'bg-orange-400',
  green: 'bg-green-400',
  emerald: 'bg-emerald-400',
  teal: 'bg-teal-400',
  lime: 'bg-lime-400',
  blue: 'bg-blue-400',
  cyan: 'bg-cyan-400',
  sky: 'bg-sky-400',
  indigo: 'bg-indigo-400',
  purple: 'bg-purple-400',
  fuchsia: 'bg-fuchsia-400',
  violet: 'bg-violet-400',
  pink: 'bg-pink-400',
  rose: 'bg-rose-400',
}

const contentStyles: { [key in Variant]: string } = {
  default: 'text-gray-300',
  gray: 'text-gray-900',
  red: 'text-red-900',
  yellow: 'text-yellow-900',
  amber: 'text-amber-900',
  orange: 'text-orange-900',
  green: 'text-green-900',
  emerald: 'text-emerald-900',
  teal: 'text-teal-900',
  lime: 'text-lime-900',
  blue: 'text-blue-900',
  cyan: 'text-cyan-900',
  sky: 'text-sky-900',
  indigo: 'text-indigo-900',
  purple: 'text-purple-900',
  fuchsia: 'text-fuchsia-900',
  violet: 'text-violet-900',
  pink: 'text-pink-900',
  rose: 'text-rose-900',
}

type Size = 'xs' | 's' | 'm' | 'l' | 'xl'
export type Variant =
  | 'default'
  | 'gray'
  | 'red'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'green'
  | 'emerald'
  | 'teal'
  | 'lime'
  | 'blue'
  | 'cyan'
  | 'sky'
  | 'indigo'
  | 'purple'
  | 'fuchsia'
  | 'violet'
  | 'pink'
  | 'rose'

interface Props {
  variant?: Variant
  size?: Size
  children?: string | ReactNode
  className?: string
  bgColor?: string // Used to overwrite variant styling
  textColor?: string // Used to overwrite variant styling
  rounded?: boolean
  placeholder?: boolean
}

const Avatar = (props: Props) => {
  const {
    variant = 'default',
    size = 'm',
    rounded,
    placeholder,
    className,
    bgColor,
    textColor,
    children,
  } = props

  return (
    <span
      className={clsx(
        'inline-flex items-center justify-center overflow-hidden',
        rounded ? 'rounded-full' : 'rounded-md',
        backgroundSizes[size],
        backgroundStyles[variant],
        className,
      )}
      style={{ backgroundColor: bgColor }}
    >
      {placeholder || (variant === 'default' && !children) ? (
        <svg
          className={clsx('size-full', contentSizes[size], contentStyles[variant])}
          fill='currentColor'
          viewBox='0 0 24 24'
          style={{ color: textColor }}
        >
          <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
        </svg>
      ) : (
        <span
          className={clsx('font-medium leading-none', contentSizes[size], contentStyles[variant])}
          style={{ color: textColor }}
        >
          {children}
        </span>
      )}
    </span>
  )
}

export default Avatar
