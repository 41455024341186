import clsx from 'clsx'
import { ButtonHTMLAttributes, ReactNode } from 'react'

type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

const sizeStyles: { [key in Size]: string } = {
  xs: 'text-xs',
  s: 'text-sm',
  m: 'text-m',
  l: 'text-l',
  xl: 'text-xl',
}

type Props = {
  size?: Size
  className?: string
  children?: string | ReactNode
  underline?: boolean
  disabled?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const LinkButton = ({ size = 'm', className, children, underline, disabled, ...rest }: Props) => {
  return (
    <button
      className={clsx(
        underline && 'underline',
        'cursor-pointer font-inter decoration-2 underline-offset-4 disabled:cursor-not-allowed',
        'text-primary-600 decoration-primary-600 hover:bg-primary-100 disabled:bg-transparent',
        sizeStyles[size],
        className,
      )}
      {...rest}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default LinkButton
