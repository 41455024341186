import { Control, Controller } from 'react-hook-form'

import type { AppMetaFormData } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import { InputField, Select, Toggle } from '../../designs'
import { applicationCategories } from '../../types/dataCollectionsConfigs'
import { InlineLoader } from '../Loader'

interface Props {
  control: Control<AppMetaFormData, any>
  isLoading: boolean
}

const AppCommonInfoForm = ({ control, isLoading }: Props) => {
  if (isLoading) {
    return <InlineLoader />
  }

  return (
    <div className='space-y-2'>
      <Controller
        name='appName'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputField
            label='Application name'
            size='s'
            autoComplete='off'
            error={error?.message}
            {...field}
          />
        )}
      />

      <Controller
        name='category'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            label='Category'
            size='s'
            options={Object.values(applicationCategories).map((value) => ({
              label: value,
              value: value,
            }))}
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name='isPdfApplication'
        control={control}
        render={({ field }) => <Toggle size='xs' label='Is PDF Application' {...field} />}
      />
    </div>
  )
}

export default AppCommonInfoForm
