import clsx from 'clsx'
import { ReactNode, TextareaHTMLAttributes, forwardRef } from 'react'

type Props = {
  className?: string
  label?: string
  description?: string
  error?: string | undefined
  name?: TextareaHTMLAttributes<HTMLTextAreaElement>['name']
  optional?: boolean
  footer?: ReactNode
} & TextareaHTMLAttributes<HTMLTextAreaElement>

const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { className, label, description, error, name, optional, rows = 3, footer, ...rest } = props

  return (
    <div className={className}>
      <div className='flex justify-between'>
        {label && (
          <label htmlFor={name} className='block text-sm font-medium text-gray-700'>
            {label}
          </label>
        )}

        {optional && (
          <span id={`${name}-optional`} className='text-sm text-gray-400'>
            Optional
          </span>
        )}
      </div>

      <div className={clsx((label || optional) && 'mt-1')}>
        <textarea
          rows={rows}
          name={name}
          id={`${name}-textarea`}
          className={clsx(
            'block w-full rounded-md text-sm disabled:cursor-not-allowed',
            error
              ? 'border-red-300 text-red-900 placeholder:text-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
          )}
          aria-describedby={`${name}-optional`}
          {...rest}
          ref={ref}
        />
      </div>

      <div
        className={clsx(
          'flex ',
          footer && !description && !error ? 'justify-end' : 'justify-between',
        )}
      >
        {(description || error) && (
          <p
            id={`${name}-description`}
            className={clsx(error ? 'text-red-600' : 'text-gray-500', 'mt-2 text-sm')}
          >
            {error ? error : description}
          </p>
        )}

        {footer}
      </div>
    </div>
  )
})

TextArea.displayName = 'TextArea'
export default TextArea
