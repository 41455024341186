import type { UseLocalStorageType } from '../../../../common/hooks/useLocalStorage'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import type { ProcessFiltersStorageContent } from '../../../../common/types/storage'
import { isDimensionFilterOptionArray } from '../../../../common/types/typeGuards'
import type {
  BusinessPropertyFilters,
  ProcessMetricFilters,
  ProcessPropertyFilters,
} from '../../types'
import type { FilterConfigData } from './types'

export const updateDimensionFilters = (
  state: FilterConfigData[],
  setState: React.Dispatch<React.SetStateAction<FilterConfigData[]>>,
  storageKey: ProcessFiltersStorageContentEnum,
  storage: UseLocalStorageType,
) => {
  const newState = [...state].filter((f) => Boolean(f.selectedOptions?.length))
  setState(newState)

  const newSearchParams: Record<string, object> = {}
  const newTagFilters: Pick<BusinessPropertyFilters, 'tag_filters'>[] = []
  const newTagFiltersMatchall: Pick<BusinessPropertyFilters, 'tag_filters_match_all'>[] = []

  newState.forEach((item) => {
    const isMatchAllFilter = item.shouldUseMatchAll
    const value = item.selectedOptions
    if (!isDimensionFilterOptionArray(value)) return

    if (storageKey === ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES) {
      if (
        item.requestFieldNameMatchAll === 'tag_filters_match_all' &&
        Boolean(value?.length) &&
        item.shouldUseMatchAll
      ) {
        newTagFiltersMatchall.push({
          values: value,
          tag_name: item.tagName,
          tag_filtering_logic: item.tagFilteringLogic,
        } as Pick<BusinessPropertyFilters, 'tag_filters_match_all'>)
      } else if (item.requestFieldName === 'tag_filters' && Boolean(value?.length)) {
        newTagFilters.push({
          values: value,
          tag_name: item.tagName,
          tag_filtering_logic: item.tagFilteringLogic,
        } as Pick<BusinessPropertyFilters, 'tag_filters'>)
      }
    } else {
      if (isMatchAllFilter) {
        newSearchParams[item.requestFieldNameMatchAll as keyof ProcessPropertyFilters] = value
      } else {
        newSearchParams[item.requestFieldName as keyof ProcessPropertyFilters] = value
      }
    }
  })

  if (storageKey === ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES) {
    if (newTagFilters.length) {
      newSearchParams.tag_filters = newTagFilters
    }
    if (newTagFiltersMatchall.length) {
      newSearchParams.tag_filters_match_all = newTagFiltersMatchall
    }
  }

  storage.updateItem(StorageNameEnum.PROCESS_FILTERS, {
    [storageKey]: newSearchParams,
  })
}

export const removeDimensionFilters = (
  storageKey: ProcessFiltersStorageContentEnum,
  storage: UseLocalStorageType,
) => {
  storage.removeNestedItem(StorageNameEnum.PROCESS_FILTERS, storageKey)
}

export const generateDefaultProcessMetricsValues = (storage: UseLocalStorageType) => {
  const values: ProcessMetricFilters = storage.getItem<ProcessFiltersStorageContent>(
    StorageNameEnum.PROCESS_FILTERS,
  )?.processMetrics ?? { team_ids: [] }

  return convertProcessMetricsHoursToSecsOrMinutes(values)
}

export const convertProcessMetricsHoursToSecsOrMinutes = (f: ProcessMetricFilters) => {
  if (f.min_active_work_sec) f.min_active_work_sec = f.min_active_work_sec / 60
  if (f.max_active_work_sec) f.max_active_work_sec = f.max_active_work_sec / 60
  if (f.min_duration_sec) f.min_duration_sec = f.min_duration_sec / 60 / 60
  if (f.max_duration_sec) f.max_duration_sec = f.max_duration_sec / 60 / 60

  return f
}

export const convertProcessMetricsSecsToHoursOrMinutes = (f: ProcessMetricFilters) => {
  if (f.min_active_work_sec) f.min_active_work_sec = f.min_active_work_sec * 60
  if (f.max_active_work_sec) f.max_active_work_sec = f.max_active_work_sec * 60
  if (f.min_duration_sec) f.min_duration_sec = f.min_duration_sec * 60 * 60
  if (f.max_duration_sec) f.max_duration_sec = f.max_duration_sec * 60 * 60

  return f
}
