import { memo } from 'react'

import { ActivityItem } from '.'
import { Text } from '../../../../../common/designs'
import type { Comment } from '../../../types'

interface Props {
  comments: Comment[]
}

const CommentSection = ({ comments }: Props) => {
  return (
    <div className='mb-6 bg-gray-100'>
      {comments.map((c) => {
        return (
          <div key={`comment-${c.created_date_time}`}>
            <ActivityItem
              userId={c.user_id}
              activityMessage='Comment'
              dateTime={c.created_date_time}
              key={`update-item-${c.created_date_time}`}
            />

            <div className='-mt-3 ml-11 rounded-md border border-gray-300 px-2 py-1'>
              <Text size='xs' color='gray'>
                {c.text}
              </Text>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(CommentSection)
