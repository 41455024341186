import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { useGetStateNamesQuery } from '../../../app/apiSlice'
import { Button, IconButton, InputField, Modal, Select } from '../../../common/designs'
import { useAppSelector, useQueryFilters } from '../../../common/hooks'
import { selectStepNameOptions } from '../processesSlice'
import type { Activity, ConformanceFlowsData, ConformanceModalData } from '../types'
import { ConformanceTypeEnum } from '../types'
import { getProcessIdFromLocation } from '../utils'

const formSchema = yup
  .object({
    flowName: yup
      .string()
      .max(128, 'Name tag is over 128 characters long')
      .required('Name is required'),
  })
  .required()

type Inputs = {
  flowName: string
}

interface Props {
  onClose: () => void
  selectedConformanceType: ConformanceTypeEnum
  onSave?: (data: ConformanceModalData) => void
  onEdit?: (data: ConformanceModalData, conformanceId: string) => void
  isOpen: boolean
  addDataSteps?: Activity[]
  editData?: ConformanceFlowsData | null
}

const NO_SELECTION = 'Select task...'

const PreferredProcessEditModal = ({
  onClose,
  selectedConformanceType,
  onSave,
  onEdit,
  isOpen,
  editData,
  addDataSteps = [],
}: Props) => {
  const location = useLocation()
  const { processFilters: filters } = useQueryFilters()
  const processId = getProcessIdFromLocation(location)

  const stepNames = useAppSelector(selectStepNameOptions)
  const { data: stateNames } = useGetStateNamesQuery({ processId, filters })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: { flowName: editData ? editData.conformance_name : '' },
  })
  const [selectedSteps, setSelectedSteps] = useState<string[]>([])

  useEffect(() => {
    setSelectedSteps(addDataSteps)
  }, [addDataSteps])

  useEffect(() => {
    setSelectedSteps(editData?.steps ?? [])
  }, [editData])

  useEffect(() => {
    setValue('flowName', editData ? editData.conformance_name : '')
  }, [editData, setValue])

  const selectOptions = useMemo(() => {
    let activeOptionsList
    if (selectedConformanceType === ConformanceTypeEnum.STEP_FLOW) {
      activeOptionsList = stepNames ? ([...stepNames.options] as string[]) : []
    } else {
      activeOptionsList = stateNames ? [...stateNames] : []
    }

    activeOptionsList.sort((a, b) => a.localeCompare(b))
    return [NO_SELECTION, ...activeOptionsList].map((a) => ({
      label: a,
      value: a,
    }))
  }, [stepNames, stateNames, selectedConformanceType])

  const onStepChange = (target: string, i: number) => {
    const selectedStepsCopy = [...selectedSteps]
    selectedStepsCopy[i] = target
    setSelectedSteps(selectedStepsCopy)
  }

  const onAddStep = () => setSelectedSteps([...selectedSteps, NO_SELECTION])

  const onRemoveStep = (i: number) => {
    const selectedStepsCopy = [...selectedSteps]
    selectedStepsCopy.splice(i, 1)
    setSelectedSteps(selectedStepsCopy)
  }

  const onSubmit: SubmitHandler<Inputs> = async ({ flowName }: Inputs) => {
    const body = {
      steps: selectedSteps.filter((s) => s !== NO_SELECTION),
      conformance_name: flowName,
    }
    if (!editData && onSave) onSave(body)
    else if (editData && onEdit) onEdit(body, editData.id)
  }

  return (
    <Modal size='2xl' label='Edit conformance flow' open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='flowName'
          control={control}
          render={({ field }) => (
            <InputField
              className='mb-3'
              label='Conformance name'
              error={errors.flowName?.message}
              {...field}
            />
          )}
        />

        {selectedSteps.map((step, i) => (
          <div className='mb-3 flex items-end gap-3' key={`${step}-${i}`}>
            <Select
              label={`Task number ${i + 1}`}
              options={selectOptions}
              value={step}
              onChange={(target: string) => onStepChange(target, i)}
              className='w-full'
            />

            <IconButton
              variant='destructive'
              onClick={() => onRemoveStep(i)}
              icon={<XMarkIcon />}
            />
          </div>
        ))}

        <Button variant='white' iconStart={<PlusIcon />} onClick={onAddStep} wFull>
          Add task
        </Button>

        <div className='mt-4 flex justify-end gap-4'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit'>Save</Button>
        </div>
      </form>
    </Modal>
  )
}

export default PreferredProcessEditModal

