import { ReactElement } from 'react'

import { Text } from '../../../common/designs'
import type { ProcessGeneralInfo } from '../types'
import TrackedProcessCard from './TrackedProcessCard'

interface Props {
  category: string
  processes: ProcessGeneralInfo[]
  totalAppUsageTrendMapper: {
    [key: string]: number
  }
  lastItem?: ReactElement | null
}

const ProcessSection = ({ category, processes, totalAppUsageTrendMapper, lastItem }: Props) => {
  return (
    <div className='mb-5 flex flex-col gap-2'>
      <Text size='2xl' weight={600}>
        {category}
      </Text>
      <div className='flex flex-wrap gap-4 pl-2'>
        {[
          ...processes.map((process) => (
            <TrackedProcessCard
              key={process.id}
              process={process}
              totalAppUsageTrendMapper={totalAppUsageTrendMapper}
            />
          )),
          lastItem ? lastItem : null,
        ]}
      </div>
    </div>
  )
}

export default ProcessSection
