import { MAX_FILE_UPLOAD_SIZE } from '../../app/constants'

export const readUploadedFileAsText = (inputFile: File) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(JSON.parse(temporaryFileReader.result as string))
    }
    temporaryFileReader.readAsText(inputFile)
  })
}

export const validateFile = (
  file: File,
  allowedFileType: string,
): { file: File | null; error?: string } => {
  if (file.type !== allowedFileType)
    return { file: null, error: `Only ${allowedFileType} files are supported` }
  if (file?.size > MAX_FILE_UPLOAD_SIZE)
    return {
      file: null,
      error: `File size exceed limit of ${MAX_FILE_UPLOAD_SIZE / 1000 / 1000} MB`,
    }
  return { file: file }
}
