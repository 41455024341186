import clsx from 'clsx'
import { ButtonHTMLAttributes, cloneElement } from 'react'

import { InlineLoader } from '../components'

const variantStyles: { [key in Variant]: string } = {
  primary:
    'border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500 disabled:bg-primary-400 disabled:cursor-not-allowed',
  secondary:
    'border-transparent bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-primary-500 disabled:bg-primary-50 disabled:text-primary-400 disabled:cursor-not-allowed',
  white:
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-100 focus:ring-primary-500 disabled:border-gray-200 disabled:text-gray-400 disabled:hover:bg-transparent disabled:cursor-not-allowed',
  ghost:
    'border-transparent bg-transparent hover:bg-gray-100 focus:ring-2 focus:ring-primary-500 disabled:text-gray-400 disabled:hover:bg-transparent disabled:cursor-not-allowed',
  success:
    'border-transparent bg-green-600 text-white hover:bg-green-700 focus:ring-green-500 disabled:bg-green-300 disabled:cursor-not-allowed',
  destructive:
    'border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 disabled:bg-red-300 disabled:cursor-not-allowed',
}

const sizeStyles: { [key in Size]: string } = {
  xs: 'max-h-7.5 px-2.5 py-1.5 text-xs',
  s: 'max-h-8.5 px-3 py-2 text-sm leading-4',
  m: 'max-h-9.5 px-4 py-2 text-sm',
  l: 'max-h-10.5 px-4 py-2 text-base',
  xl: 'max-h-12.5 px-6 py-3 text-base',
}

const iconStartSizeStyles: { [key in Size]: string } = {
  xs: 'mr-1.5 h-3.5 w-3.5',
  s: '-ml-0.5 mr-2 size-4',
  m: '-ml-1 mr-2 size-5',
  l: '-ml-1 mr-3 size-5',
  xl: '-ml-1 mr-3 size-5',
}

const iconEndSizeStyles: { [key in Size]: string } = {
  xs: 'ml-1.5 h-3.5 w-3.5',
  s: 'ml-2 -mr-0.5 size-4',
  m: 'ml-2 -mr-1 size-5',
  l: 'ml-3 -mr-1 size-5',
  xl: 'ml-3 -mr-1 size-5',
}

const iconCenterSizeStyles: { [key in Size]: string } = {
  xs: 'h-3.5 w-3.5',
  s: 'size-4',
  m: 'size-5',
  l: 'size-5',
  xl: 'size-5',
}

type Variant = 'primary' | 'secondary' | 'white' | 'ghost' | 'destructive' | 'success'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

type Props = {
  variant?: Variant
  size?: Size
  className?: string
  iconCenter?: React.ReactElement
  iconStart?: React.ReactElement
  iconEnd?: React.ReactElement
  loading?: boolean
  rounded?: boolean
  wFull?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = (props: Props) => {
  const {
    variant = 'primary',
    size = 'm',
    children,
    className,

    iconCenter,
    iconStart,
    iconEnd,

    type = 'button',
    disabled,
    loading,
    rounded,
    wFull,
    ...rest
  } = props

  return (
    <button
      className={clsx(
        'inline-flex items-center justify-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        rounded ? 'rounded-full' : size === 'xs' ? 'rounded' : 'rounded-md',
        variantStyles[variant],
        sizeStyles[size],
        wFull && 'w-full',
        className,
      )}
      disabled={disabled || loading}
      type={type}
      {...rest}
    >
      {!loading && iconStart
        ? cloneElement(iconStart, { className: clsx(iconStartSizeStyles[size]) })
        : null}

      {loading ? (
        <InlineLoader size={`btn_${size}`} />
      ) : (
        <>
          {iconCenter
            ? cloneElement(iconCenter, { className: clsx(iconCenterSizeStyles[size]) })
            : children}
        </>
      )}

      {!loading && iconEnd
        ? cloneElement(iconEnd, { className: clsx(iconEndSizeStyles[size]) })
        : null}
    </button>
  )
}

export default Button
