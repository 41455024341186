import { useGetWindowUsageQuery } from '../../app/apiSlice'
import type { Tag } from '../types/common'
import { useQueryFilters } from './'

const tagsOfInterestSources = {
  APPLICATION: 'application',
  WINDOW: 'window',
} as const
export type TagOfInterestSource = (typeof tagsOfInterestSources)[keyof typeof tagsOfInterestSources]

interface Interval {
  min_date: string
  max_date: string
}

interface Variables {
  team_comparison_data?: string[] | undefined
  tags_of_interest?: string[] | undefined
  tag_filters?: Tag[] | undefined
  date_comparison_data?: Interval[] | undefined
  min_date?: string | undefined | null
  max_date?: string | undefined | null
  use_exact_tag_filter_match?: boolean | undefined | null
}

export interface FilteredWindowUsageQueryOptions {
  variables?: Variables
  tagsOfInterestSource: TagOfInterestSource
}

const useFilteredWindowUsageQuery = ({
  variables = {},
  tagsOfInterestSource,
}: FilteredWindowUsageQueryOptions) => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()

  return useGetWindowUsageQuery({
    body: {
      tags_of_interest: tags_of_interest[tagsOfInterestSource],
      sort_by_field: 'time_h',
      min_date,
      max_date,
      team_ids,
      ...variables,
    },
  })
}

export default useFilteredWindowUsageQuery
