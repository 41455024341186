import { Tooltip } from '../../common/designs'

interface DistributionData {
  bgColor: string
  relativeValue: number
  value: string | number
  label: string
}

interface Props {
  data: DistributionData[]
}

const DistributionChart = ({ data }: Props) => {
  return (
    <div className='flex h-4 w-52'>
      {data.map(({ bgColor, relativeValue, value, label }, i) => {
        const roundedStart = i === 0 ? 'rounded-l-lg' : ''
        const roundedEnd = i === data.length - 1 ? 'rounded-r-lg' : ''
        return (
          <Tooltip
            label={label}
            text={value.toString()}
            placement='t'
            size='l'
            style={{ width: `${relativeValue}%` }}
            key={`${label}-${value}`}
          >
            <div className={`${bgColor} h-full ${roundedStart} ${roundedEnd}`} />
          </Tooltip>
        )
      })}
    </div>
  )
}

export default DistributionChart
