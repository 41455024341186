import { ReactElement, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DrawerMultiple } from '../../../common/designs'
import type { DrawerMultipleSize } from '../../../common/designs'
import { MainPathEnum, SubPathEnum } from '../../../common/types/routing'
import { FilterMenu as OpportunitiesFilter } from '../../../features/Cases/components/Opportunities/components'
import { FilterMenu as RoadmapFilter } from '../../../features/Cases/components/Roadmap/components'
import FlowFilters from '../../../features/DataFlows/FlowFilters'
import { ProcessFilters, TaskFilters } from '../../../features/Processes/ProcessMining/Filters'
import TaskVariantFilter from '../../../features/TaskDiscovery/TaskVariantFilter'

const processFilterSize: DrawerMultipleSize = 'l'
const processAndTaskFilters = [
  { label: 'Process', children: <ProcessFilters />, size: processFilterSize },
  { label: 'Task', children: <TaskFilters />, size: processFilterSize },
]

const masterFilterTree: {
  [key: string]: {
    [key: string | 'index']: Array<{
      label: string
      children: ReactElement
      size?: DrawerMultipleSize
    }>
  }
} = {
  [MainPathEnum.DATA_FLOWS]: {
    index: [{ label: 'Flows', children: <FlowFilters /> }],
  },
  [MainPathEnum.OPPORTUNITIES]: {
    index: [{ label: 'Kanban', children: <OpportunitiesFilter /> }],
    [SubPathEnum.ROADMAP]: [{ label: 'Roadmap', children: <RoadmapFilter /> }],
  },
  [MainPathEnum.PROCESSES]: {
    [SubPathEnum.OVERVIEW]: processAndTaskFilters,
    [SubPathEnum.VARIANTS]: processAndTaskFilters,
    [SubPathEnum.INSIGHTS]: processAndTaskFilters,
    [SubPathEnum.ANALYSIS]: processAndTaskFilters,
    [SubPathEnum.CONFORMANCE]: processAndTaskFilters,
    [SubPathEnum.TRENDS]: processAndTaskFilters,
    [SubPathEnum.USER_ACTIVITIES]: processAndTaskFilters,
    [SubPathEnum.TASK_VARIANTS]: [{ label: 'Variants', children: <TaskVariantFilter /> }],
  },
}

const FilterDrawer = forwardRef<any, any>((_: any, ref: any) => {
  const location = useLocation()

  const [state, setState] = useState<{
    isDisabled: boolean
    isOpen: boolean
    tabIndex: number
  }>({ isDisabled: true, isOpen: false, tabIndex: 0 })

  // useImperativeHandle allows us to expose the toggle function to the vertical child component through the parent.
  useImperativeHandle(ref, () => ({
    toggle: () => setState({ ...state, isOpen: !state.isOpen }),
    getIsDisabledState: () => state.isDisabled,
  }))

  // This hook is used to determine which filters are available for the current path.
  const filters = useMemo(() => {
    // Validate if the location main path consists of any filters.
    const splittedPath = location.pathname.split('/')
    splittedPath.shift()
    const filterTreeBranch = masterFilterTree[splittedPath[0]]
    if (!filterTreeBranch) return setState({ ...state, isDisabled: true })

    // Check what kind of filters are applied to the path.
    let newFilters = filterTreeBranch.index
    Object.keys(filterTreeBranch).forEach((key) => {
      splittedPath.forEach((p) => {
        if (p.includes(key)) newFilters = filterTreeBranch[key]
      })
    })

    setState({
      ...state,
      isDisabled: !newFilters,
      // Prevent a tab index being open that does not exist.
      tabIndex: state.tabIndex > newFilters?.length - 1 ? 0 : state.tabIndex,
    })

    return newFilters
  }, [state.isOpen, location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const defaultSize: DrawerMultipleSize = 'm'

  return (
    <DrawerMultiple
      label='Filters'
      size={filters ? filters[state.tabIndex]?.size ?? defaultSize : defaultSize}
      open={state.isOpen}
      onClose={() => setState({ ...state, isOpen: false })}
      tabs={filters?.map((f) => f.label) ?? []}
      tabIndex={state.tabIndex}
      setTabIndex={(i) => setState({ ...state, tabIndex: i })}
      hasNoMainNavigationOverlap
    >
      {state.isOpen && filters?.[state.tabIndex]?.children}
    </DrawerMultiple>
  )
})

FilterDrawer.displayName = 'FilterDrawer'
export default FilterDrawer

