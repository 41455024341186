import { yupResolver } from '@hookform/resolvers/yup'
import { Resolver, SubmitHandler, useForm } from 'react-hook-form'

import { useCreateDataCollectionsConfigApplicationMutation } from '../../app/apiSlice'
import { ContentDisplay } from '../../common/components'
import AppCommonInfoForm from '../../common/components/DataCollectionForms/AppCommonInfoForm'
import { appMetaFormSchema } from '../../common/components/DataCollectionForms/formSchemas'
import { Breadcrumbs, Button, Divider, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { applicationCategories } from '../../common/types/dataCollectionsConfigs'
import { type AppMetaFormData, ConfigureDataCollectionSubPathEnum } from './types'

const AddCustomApp = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const [createAppCommonInfo, { isLoading }] = useCreateDataCollectionsConfigApplicationMutation()

  // Initialize the application and test case form.
  const { control, handleSubmit } = useForm<AppMetaFormData>({
    resolver: yupResolver(appMetaFormSchema) as Resolver<AppMetaFormData>,
    defaultValues: {
      category: applicationCategories.other,
    },
  })

  // When form is submited, generated test case, save it
  // to session storage, and navigate to app rule view.
  const onFormSubmit: SubmitHandler<AppMetaFormData> = async (formData: AppMetaFormData) => {
    await createAppCommonInfo({
      name: formData.appName,
      category: formData.category,
      is_pdf_application: formData.isPdfApplication,
    })
    navigate(
      `../${ConfigureDataCollectionSubPathEnum.APPS}/${encodeURIComponent(formData.appName)}`,
    )
  }

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('..'),
          },
          {
            label: 'Add Custom App',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      <form onSubmit={handleSubmit(onFormSubmit)} className='space-y-4' id='config-wizard-form'>
        <div className='mt-4 space-y-6'>
          <ContentDisplay className='p-4'>
            <Text size='l' weight={600}>
              Application Info
            </Text>
            <AppCommonInfoForm control={control} isLoading={isLoading} />
          </ContentDisplay>
        </div>
        <div className='flex justify-end'>
          <Button type='submit'>Save Application</Button>
        </div>
      </form>
    </>
  )
}

export default AddCustomApp
