import { Button, Text } from '../../../common/designs'
import StepActivityTaskBarChart from './StepActivityTaskBarChart'
import StepActivityValueBox from './StepActivityValueBox'
import { ActivityLabelEnum, activityExplanationMapper } from './stepActivityConstants'

interface Props {
  transactionIds: string[]
  stepName: string
  stepActivity: string
  onNavBack?: () => void
  processPotential: {
    label: string
    value: string
  }[]
  workPotential: {
    label: string
    value: string
  }[]
}

const StepActivityPotential = ({
  transactionIds,
  stepName,
  stepActivity,
  onNavBack,
  processPotential,
  workPotential,
}: Props) => {
  const renderStepActivityValueBoxes = (data: any[]) => {
    return data.map((item, i) => (
      <StepActivityValueBox
        key={item.label}
        label={item.label}
        value={item.value}
        isHighlighted={i === 0}
      />
    ))
  }

  return (
    <>
      {onNavBack ? (
        <div className='mb-2'>
          <Button variant='white' onClick={onNavBack}>
            Back
          </Button>
        </div>
      ) : null}

      <Text size='l' weight={600}>
        {stepActivity}
      </Text>
      <Text size='s' className='mb-3'>
        {activityExplanationMapper[stepActivity as keyof typeof activityExplanationMapper]}
      </Text>

      <Text size='l' weight={600}>
        Improvement potential for {stepActivity}
      </Text>

      <div className='mt-2 flex items-center gap-2'>
        <div className='w-20'>
          <Text size='l' weight={600}>
            Process
          </Text>
        </div>
        {renderStepActivityValueBoxes(processPotential)}
      </div>

      <div className='mt-2 flex items-center gap-2'>
        <div className='w-20'>
          <Text size='l' weight={600}>
            Work
          </Text>
        </div>
        {renderStepActivityValueBoxes(workPotential)}
      </div>

      {stepActivity === ActivityLabelEnum.MULTI_SYSTEM_WORKFLOWS && (
        <StepActivityTaskBarChart stepName={stepName} transactionIds={transactionIds} />
      )}
    </>
  )
}

export default StepActivityPotential
