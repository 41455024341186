import { Button, Modal, Text } from '../../../../common/designs'
import { useDashboard } from '../../../../common/hooks'
import { formatNumber } from '../../../../common/utils/numberFormatting'

export interface ModalContent {
  label: string
  value: number
  unit: string
  filterValue: string | string[]
}

interface Props {
  content: ModalContent | null
  onClose: () => void
  onFilter: () => void
  onFilterAndNavigate: () => void
}

const AnalysisFilterPopupModal = (props: Props) => {
  const { content, onClose, onFilter, onFilterAndNavigate } = props
  const {
    user: { isPlatformViewer },
  } = useDashboard()

  return (
    <Modal label='Analysis Selection' size='l' open={Boolean(content)} onClose={onClose}>
      {content ? (
        <div className='space-y-4'>
          <div className='flex gap-4'>
            <Text>{content.label}:</Text>
            <Text weight={500}>
              {formatNumber(content.value)} {content.unit}
            </Text>
          </div>

          <Text>
            Do you want to set <span className='font-medium text-primary-600'>{content.label}</span>{' '}
            as a data filter?
          </Text>

          <div className='flex gap-4'>
            <Button variant='white' onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onFilter}>Set filter</Button>
            {!isPlatformViewer && (
              <Button onClick={onFilterAndNavigate}>
                Set filter and show results in Variants view
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

export default AnalysisFilterPopupModal
