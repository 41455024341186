import type {
  GroupResultData,
  MetricValue,
  ProcessIdQueryParamsBase,
  TransactionFiltersWithZooming,
} from '../../../types'

export enum StepMetricNamesEnum {
  TOTAL_TRANSACTIONS = 'total_transactions',
  TOTAL_STEP_VISITS = 'total_step_visits',
  STEP_VISITS_PER_TRANSACTION = 'step_visits_per_transaction',

  DURATION_SEC = 'duration_sec',
  TIME_FROM_PREV_STEP_SEC = 'time_from_prev_step_sec',
  COPY_COUNT = 'copy_count',
  PASTE_COUNT = 'paste_count',
  KEYBOARD_PRESS_COUNT = 'keyboard_press_count',
  MOUSE_CLICK_COUNT = 'mouse_click_count',

  AVG_DURATION_SEC = 'avg_duration_sec',
  AVG_TIME_FROM_PREV_STEP_SEC = 'avg_time_from_prev_step_sec',
  AVG_COPY_COUNT = 'avg_copy_count',
  AVG_PASTE_COUNT = 'avg_paste_count',
  AVG_KEYBOARD_PRESS_COUNT = 'avg_keyboard_press_count',
  AVG_MOUSE_CLICK_COUNT = 'avg_mouse_click_count',
}

export enum StepGroupByFieldTypeEnum {
  TRANSACTION_ID = 'transaction_id',
  TRANSACTION_TAG = 'transaction_tag',
  STEP_TAG = 'step_tag',
  STEP_TEAM_ID = 'team_id',
  STEP_WORK_TYPE = 'step_work_type',
}

// ------------ METRICS ------------
export interface StepMetricQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFiltersWithZooming
  metrics: StepMetricNamesEnum[]
}

export type StepMetricResult = {
  [key in StepMetricNamesEnum]: MetricValue
}

// ------------ ANALYSIS ------------
export interface StepGroupByField {
  field_type: StepGroupByFieldTypeEnum
  tag_name?: string
}

export interface StepGroupQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFiltersWithZooming
  group_by: StepGroupByField
  metrics: StepMetricNamesEnum[]
}

export type StepAnalysisResult = {
  [key in StepMetricNamesEnum]: GroupResultData[]
}

// ------------ TASK FLOWS ------------
export enum WindowsRelationToStepEnum {
  MULTI_SYSTEM_WINDOW = 'multi_system_window',
  PREVIOUS_WINDOW = 'previous_window',
  NEXT_WINDOW = 'next_window',
}

export interface TaskFlowParams extends ProcessIdQueryParamsBase {
  filters: TransactionFiltersWithZooming
  window_relation_to_step: WindowsRelationToStepEnum
}

export interface SystemDistributionData {
  window_name: string
  total_count: number
  total_duration_sec: number
}

// ------------ STATE THROUGHPUT TIME ------------
export interface StateThroughputTimeParams extends ProcessIdQueryParamsBase {
  filters: TransactionFiltersWithZooming
}

export interface StateThroughputTimeData {
  total_duration_sec: number
  avg_duration_sec: number
}

