import { Button, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import type { AdvancedSetupSubPathEnum } from './types'

interface Props {
  title: string
  description: string
  navPath: AdvancedSetupSubPathEnum
}

const AdvancedSetupSection = ({ navPath, description, title }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <div className='bg-white shadow-md ring-2 ring-black/5 md:rounded-lg'>
      <div className='relative border-r border-r-gray-300 px-10 py-6'>
        <Text size='l' weight={700} className='mb-2'>
          {title}
        </Text>
        <Text className='mb-2'>{description}</Text>
        <div className='flex gap-6'>
          <Button className='shrink-0' onClick={() => navigate(navPath)}>
            Open
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSetupSection
