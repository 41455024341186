import { FolderArrowDownIcon } from '@heroicons/react/24/outline'
import { formatDistanceToNow } from 'date-fns'

import { Text } from '../../common/designs'

interface Props {
  lastUpdateDate: Date | null
  configName: string
  onClick: () => void
}

const ConfigFileDownloadButton = ({ lastUpdateDate, configName, onClick }: Props) => {
  return (
    <div className='flex cursor-pointer gap-1' onClick={onClick}>
      <FolderArrowDownIcon className='h-10 w-8' />
      <div>
        <Text weight={500}>
          {configName.length > 25 ? `${configName.substring(0, 22)}...` : configName}
        </Text>
        <Text size='xs'>
          Last updated{' '}
          {lastUpdateDate ? `${formatDistanceToNow(new Date(lastUpdateDate))} ago` : 'never'}
        </Text>
      </div>
    </div>
  )
}

export default ConfigFileDownloadButton
