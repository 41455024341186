import { Handle, Position } from 'reactflow'

import type { PortData } from './types'

interface Props {
  topPorts: PortData[]
  rightPorts: PortData[]
  bottomPorts: PortData[]
  leftPorts: PortData[]
  color: string
  children: JSX.Element | JSX.Element[]
}

const NodeHandleLayout = ({
  topPorts,
  rightPorts,
  bottomPorts,
  leftPorts,
  color,
  children,
}: Props) => {
  const topSeparationSize = 80 / (topPorts.length - 1)
  const rightSeparationSize = 70 / (rightPorts.length - 1)
  const bottomSeparationSize = 80 / (bottomPorts.length - 1)
  const leftSeparationSize = 70 / (leftPorts.length - 1)

  return (
    <>
      {topPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (topSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Top}
            id={id}
            key={id}
            style={{
              background: color,
              left: `${50 + direction * shift}%`,
              width: '13px',
              height: '13px',
            }}
          />
        )
      })}
      {rightPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (rightSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Right}
            id={id}
            key={id}
            style={{
              background: color,
              top: `${50 + direction * shift}%`,
              width: '13px',
              height: '13px',
            }}
          />
        )
      })}

      {children}

      {bottomPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (bottomSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Bottom}
            id={id}
            key={id}
            style={{
              background: color,
              left: `${50 + direction * shift}%`,
              width: '13px',
              height: '13px',
            }}
          />
        )
      })}
      {leftPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (leftSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Left}
            id={id}
            key={id}
            style={{
              background: color,
              top: `${50 + direction * shift}%`,
              width: '13px',
              height: '13px',
            }}
          />
        )
      })}
    </>
  )
}

export default NodeHandleLayout
