import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

type Size = 'xs' | 's' | 'm'

const sizeStyles: { [key in Size]: string } = {
  xs: 'h-1',
  s: 'h-1.5',
  m: 'h-2',
}

interface Props {
  value: number
  onChange: Dispatch<SetStateAction<number>>

  size?: Size
  className?: string
  label?: string
  disabled?: boolean
}

const Slider = (props: Props) => {
  const { value, onChange, size = 'm', className, label, disabled } = props

  return (
    <div className={className}>
      <div className='mb-1 flex justify-between text-gray-900'>
        <label htmlFor='small-range' className='block text-sm font-medium'>
          {label}
        </label>

        <span className='text-sm font-bold'>{value}</span>
      </div>

      <input
        id='small-range'
        type='range'
        value={value}
        onChange={({ target }) => onChange(Number(target.value))}
        className={clsx(
          'w-full cursor-pointer appearance-none rounded-full border-gray-300 bg-primary-100 accent-primary-600	focus:border-primary-500 focus:ring-primary-500 disabled:cursor-not-allowed disabled:bg-gray-100',
          sizeStyles[size],
        )}
        disabled={disabled}
      />
    </div>
  )
}

export default Slider
