import { apiSlice } from './apiSlice'

const dataTrends = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ** COMPANY ** //
    getCompanyTrends: builder.query<any, void>({
      query: () => `/data-trends/company-trends/get`,
      providesTags: ['CompanyTrend'],
    }),
    createCompanyTrend: builder.mutation({
      query: (trend: any) => ({
        url: `/data-trends/company-trends`,
        method: 'POST',
        body: trend,
      }),
      invalidatesTags: ['CompanyTrend'],
    }),
    updateCompanyTrend: builder.mutation({
      query: ({ id: companyTrendId, ...patch }) => ({
        url: `/data-trends/company-trends/${companyTrendId}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['CompanyTrend'],
    }),
    deleteCompanyTrend: builder.mutation({
      query: (companyTrendId: string | number) => ({
        url: `/data-trends/company-trends/${companyTrendId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyTrend'],
    }),

    // ** USER ** //
    getUserTrends: builder.query<any, void>({
      query: () => '/data-trends/user-trends',
      providesTags: ['UserTrend'],
    }),
    createUserTrend: builder.mutation({
      query: (userTrend: any) => ({
        url: '/data-trends/user-trends',
        method: 'POST',
        body: userTrend,
      }),
      invalidatesTags: ['UserTrend'],
    }),
    updateUserTrend: builder.mutation({
      query: ({ id: trendId, ...body }) => ({
        url: `/data-trends/user-trends/${trendId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['UserTrend'],
    }),
    deleteUserTrend: builder.mutation({
      query: (trendId: string | number) => ({
        url: `/data-trends/user-trends/${trendId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserTrend'],
    }),

    // ** CASE ** //
    getCaseTrends: builder.query<any, any>({
      query: (caseId: string | number) => `/data-trends/case-trends/${caseId}`,
      providesTags: ['CaseTrend'],
    }),
    createCaseTrend: builder.mutation({
      query: ({ caseId, ...trendCase }: any) => ({
        url: `/data-trends/case-trends/${caseId}`,
        method: 'POST',
        body: trendCase,
      }),
      invalidatesTags: ['CaseTrend'],
    }),
    updateCaseTrend: builder.mutation({
      query: ({ caseId, id: trendId, ...trendCase }: any) => ({
        url: `/data-trends/case-trends/${caseId}/${trendId}`,
        method: 'PATCH',
        body: trendCase,
      }),
      invalidatesTags: ['CaseTrend'],
    }),
    deleteCaseTrend: builder.mutation({
      query: ({ caseId, trendId }: any) => ({
        url: `/data-trends/case-trends/${caseId}/${trendId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CaseTrend'],
    }),
  }),
})

export const {
  // ** Company ** //
  useGetCompanyTrendsQuery,
  useCreateCompanyTrendMutation,
  useUpdateCompanyTrendMutation,
  useDeleteCompanyTrendMutation,

  // ** User ** //
  useGetUserTrendsQuery,
  useCreateUserTrendMutation,
  useUpdateUserTrendMutation,
  useDeleteUserTrendMutation,

  // ** Case ** //
  useGetCaseTrendsQuery,
  useCreateCaseTrendMutation,
  useUpdateCaseTrendMutation,
  useDeleteCaseTrendMutation,
} = dataTrends
