// This is not listed in constants because technically it is a type.
export const GLOBAL_DATE_FORMAT = 'yyyy-MM-dd'

type YYYY = '2021' | '2022' | '2023' | '2024' | '2025' | '2026' | '2027' | '2028' | '2029'
type MM = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
type DD =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'

export type SimpleDate = `${YYYY}-${MM}-${DD}` // Ex. '2021-12-31' | '2022-01-09'
