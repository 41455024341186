import _ from 'lodash'
import { useState } from 'react'
import type { NodeProps } from 'reactflow'

import { useGetProcessIdentifierMapperQuery } from '../../app/apiSlice'
import { Badge, Divider } from '../../common/designs'
import { getBestTimeFormatForSeconds } from '../../common/utils/numberFormatting'
import NodeActionButtons from '../Processes/ProcessMining/Variants/NodeActionButtons'
import NodeLayout from '../Processes/ProcessMining/Variants/NodeLayout'
import TaskFlowNodeModal from './TaskFlowNodeModal'
import TaskListActivityDistribution from './TaskListActivityDistribution'
import type { ServerTaskVisualizationNode } from './types'

const isServerTaskVisualizationNode = (object: any): object is ServerTaskVisualizationNode => {
  return _.isPlainObject(object) && 'step_copies' in object
}

const TaskFlowNode = ({ data }: NodeProps<ServerTaskVisualizationNode>) => {
  const [isStepModalOpen, setIsStepModalOpen] = useState<boolean>(false)
  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  // Sometimes the passed data updates slowly for the node. Don't
  // render the node until the data is loaded in correct format
  if (!isServerTaskVisualizationNode(data)) {
    return null
  }

  const {
    display_name: displayName,
    transactions,
    avg_duration_sec,
    is_conformance_flow_node: isConformanceFlowNode,

    step_copies: copies,
    step_pastes: pastes,
    process_steps: processSteps,
    top_ports: topPorts,
    right_ports: rightPorts,
    bottom_ports: bottomPorts,
    left_ports: leftPorts,

    internal_copy_paste_count: internalCopyPasteCount,
    keyboard_press_count: keyboardPressCount,
    relative_share_of_transactions: relativeTransactions,
    activity_categorizations: activityCategorizations,
  } = data

  const timeString = getBestTimeFormatForSeconds(avg_duration_sec)
  const [timeNumber, timeUnit] = timeString.split(' ')

  const processNameOccurances = {} as { [key: string]: number }
  processSteps.forEach((p) => {
    if (p.process_name in processNameOccurances) {
      processNameOccurances[p.process_name] += 1
    } else {
      processNameOccurances[p.process_name] = 1
    }
  })

  return (
    <>
      <NodeLayout
        ports={{ topPorts, rightPorts, bottomPorts, leftPorts }}
        relativeSize={relativeTransactions}
        nodeName={displayName}
        timeText={`${timeNumber} ${timeUnit}`}
        transactionCount={transactions}
        isConformanceFlowNode={isConformanceFlowNode}
        actionButtons={<NodeActionButtons onShowMetrics={() => setIsStepModalOpen(true)} />}
      >
        <Divider label='User Activities' color='darkGray' className='my-3' />
        <div className='p-1'>
          <TaskListActivityDistribution activityData={activityCategorizations} />
        </div>
        <div className='flex gap-2 p-1'>
          <Badge size='l' variant='indigo'>
            Copies: {copies.length}
          </Badge>
          <Badge size='l' variant='indigo'>
            Pastes: {pastes.length}
          </Badge>
        </div>
        <div className='flex flex-col space-y-2 p-1'>
          <Badge size='l' variant='indigo'>
            Keyboard presses: {keyboardPressCount}
          </Badge>
          <Badge size='l' variant='indigo'>
            Internal copy-pastes: {internalCopyPasteCount}
          </Badge>
        </div>

        <div className='flex flex-col gap-1 p-1'>
          {Object.entries(processNameOccurances).map(([process]) =>
            processMapper && process in processMapper ? (
              <Badge size='l' variant='green' key={process}>
                {processMapper && processMapper[process].name}
              </Badge>
            ) : null,
          )}
        </div>
      </NodeLayout>

      <TaskFlowNodeModal
        isOpen={isStepModalOpen}
        onClose={() => setIsStepModalOpen(false)}
        data={data}
      />
    </>
  )
}

export default TaskFlowNode
