import { Text } from '../../common/designs'
import { MainPathEnum, MainPathTitleEnum, SubPathEnum } from '../../common/types/routing'
import { compressAndEncodeJSON } from '../../common/utils/jsonCompression'
import {
  activityDescriptions,
  activityNames,
} from '../BusinessApps/TimeLayout/activityDescriptions'
import { activityExplanationMapper } from '../Processes/ProcessMining/stepActivityConstants'
import { RecommendationTypeEnum } from './enums'
import type { ProcessIdentifierMapper, RecommendationData } from './types'

export const getAnnualCostForRecommendation = (hours: number, teamOrCompanyCostPerHour: number) => {
  const costForHours = hours * teamOrCompanyCostPerHour
  return (365 / 7) * costForHours
}

const findValidExtraData = (recommendation: RecommendationData) => {
  const trendData = recommendation.trend_data.filter((e: any) => {
    return Boolean(e.extra_data)
  })
  if (!trendData.length) return null

  return trendData[0].extra_data[0]
}

export const getNavigationInfo = (
  recommendation: RecommendationData,
  processMapper: ProcessIdentifierMapper | undefined,
) => {
  const extraData = findValidExtraData(recommendation)

  switch (recommendation.recommendation_type) {
    case RecommendationTypeEnum.COPY_PASTE:
      const copyPasteSourceAppTags = extraData?.source_tags || {}
      const copyPasteCompressedTags = compressAndEncodeJSON([copyPasteSourceAppTags])
      return {
        path: `${MainPathEnum.BUSINESS_APPS}/${copyPasteCompressedTags}/${SubPathEnum.ACTIVITIES}`,
        title: `${copyPasteSourceAppTags.value} in ${MainPathTitleEnum.BUSINESS_APPS}`,
      }

    case RecommendationTypeEnum.NAVIGATION:
      const navigationSourceAppTags = extraData?.source_tags || {}
      const navigationCompressedTags = compressAndEncodeJSON([navigationSourceAppTags])
      return {
        path: `${MainPathEnum.BUSINESS_APPS}/${navigationCompressedTags}`,
        title: `${navigationSourceAppTags.value} in ${MainPathTitleEnum.BUSINESS_APPS}`,
      }

    case RecommendationTypeEnum.APP_USAGE_LEAD:
      const appUsageLeadFilter = `recommendation_app_usage_lead=${recommendation.recommendation_name}`
      return {
        path: `${MainPathEnum.BUSINESS_APPS}?${appUsageLeadFilter}`,
        title: `Go to ${MainPathTitleEnum.BUSINESS_APPS}`,
      }

    case RecommendationTypeEnum.APP_ACTIVITY_LEAD:
      const activityAppTags = extraData?.application_name_tags || {}
      const activityCompressedTags = compressAndEncodeJSON([activityAppTags])
      return {
        path: `${MainPathEnum.BUSINESS_APPS}/${activityCompressedTags}/activities`,
        title: `${activityAppTags.value} in ${MainPathTitleEnum.BUSINESS_APPS}`,
      }

    case RecommendationTypeEnum.PROCESS_ACTIVITES:
      const processIdentifier = extraData?.process_name || ''
      const processObj =
        !processMapper || !(processIdentifier in processMapper)
          ? { id: '', name: processIdentifier }
          : processMapper[processIdentifier]
      return {
        path: `${MainPathEnum.PROCESSES}/${processObj.id}/${SubPathEnum.INSIGHTS}`,
        title: `${processObj.name} insights`,
      }

    default:
      return {
        path: `${MainPathEnum.BUSINESS_APPS}`,
        title: `See in ${MainPathTitleEnum.BUSINESS_APPS}`,
      }
  }
}

export const getNameComponent = (
  recommendation: RecommendationData,
  processMapper: ProcessIdentifierMapper | undefined,
  isSmallTextSize = false,
) => {
  let titleComponent = null
  if (recommendation.recommendation_type === RecommendationTypeEnum.PROCESS_ACTIVITES) {
    const extraData = findValidExtraData(recommendation)
    if (!extraData) {
      titleComponent = (
        <Text
          key={recommendation.recommendation_name}
          size={isSmallTextSize ? 's' : 'l'}
          weight={isSmallTextSize ? 500 : 600}
        >
          {recommendation.recommendation_name}
        </Text>
      )
    } else {
      const { process_name, step_name, step_activity } = extraData
      const processName =
        !processMapper || !(process_name in processMapper)
          ? process_name
          : processMapper[process_name].name
      titleComponent = (
        <div className='flex gap-2' key={recommendation.recommendation_name}>
          <Text size={isSmallTextSize ? 's' : 'l'} weight={isSmallTextSize ? 500 : 600}>
            {step_activity}{' '}
          </Text>
          <Text size={isSmallTextSize ? 's' : 'l'} weight={isSmallTextSize ? 500 : 600}>
            {step_name}{' '}
          </Text>
          <Text size={isSmallTextSize ? 's' : 'l'} weight={isSmallTextSize ? 500 : 600}>
            In {processName}
          </Text>
        </div>
      )
    }
  } else {
    let recommendation_name = recommendation.recommendation_name
    Object.entries(activityNames).forEach(([key, value]) => {
      const regex = new RegExp(`^${key}`) //Ensure the replace happens at the start of the string
      recommendation_name = recommendation_name.replace(regex, value)
    })
    titleComponent = (
      <Text
        key={recommendation_name}
        size={isSmallTextSize ? 's' : 'l'}
        weight={isSmallTextSize ? 500 : 600}
      >
        {recommendation_name}
      </Text>
    )
  }
  return titleComponent
}

export const getRecommendationDescription = (recommendation: RecommendationData) => {
  if (recommendation.recommendation_type === RecommendationTypeEnum.PROCESS_ACTIVITES) {
    const extraData = findValidExtraData(recommendation)
    if (extraData) {
      const { step_activity } = extraData
      return [activityExplanationMapper[step_activity as keyof typeof activityExplanationMapper]]
    } else {
      return ['']
    }
  } else if (recommendation.recommendation_type === RecommendationTypeEnum.APP_ACTIVITY_LEAD) {
    let activityDescription = ['']
    Object.entries(activityNames).every(([activityName, activityKey]) => {
      if (recommendation.recommendation_name.includes(activityName)) {
        activityDescription = activityDescriptions[activityKey]
        return false
      }
      return true
    })
    return activityDescription
  } else if (recommendation.recommendation_type === RecommendationTypeEnum.COPY_PASTE) {
    return [
      'Check the data flow contents and windows: https://app.workfellow.com/work ',
      'Ideas to improve:',
      '- Check if there is a cloud API existing for these applications for data integration?',
      '- Could it be automated with RPA?',
    ]
  } else if (recommendation.recommendation_type === RecommendationTypeEnum.NAVIGATION) {
    return [
      'There are lots of jumps between spreadsheets/documents and business applications. Ideas to improve: ',
      '- Replace the spreadsheet with a low code app if this work is part of a repetitive process',
      '- Discuss with the teams if there would be a possibility to replace excels with reporting tools or low-code applications?',
      '- Discuss with the teams if the Excel calculations could be done automatically within the business apps?',
      '- Discuss with the teams if there is a need for Excel training and tip&tricks',
    ]
  } else if (recommendation.recommendation_type === RecommendationTypeEnum.APP_USAGE_LEAD) {
    return [
      'Check the email and calendar best practices:',
      '- Automate calendar scheduling, e.g., with Scheduler for Microsoft 365 or Calendly',
      '- Best practices to be set up and used for email archiving and searching. Training for people?',
      '- Steer the customer queries and frequently ask questions to self-service portals and FAQ documents. Standardize and automate those.',
    ]
  }

  return ['']
}
