import clsx from 'clsx'

import { Text } from '../../../common/designs'

interface Props {
  label: string
  value: string
  isHighlighted?: boolean
}

const StepActivityValueBox = ({ label, value, isHighlighted = false }: Props) => {
  return (
    <div
      className={clsx(
        'flex h-24 w-[200px] flex-col items-center justify-center rounded-md px-1',
        isHighlighted ? 'bg-primary-200' : 'bg-gray-100',
      )}
    >
      <Text className='mb-1 text-center'>{label}</Text>

      <Text size='2xl' className='text-center'>
        {value}
      </Text>
    </div>
  )
}

export default StepActivityValueBox
