import clsx from 'clsx'

import { useGetCaseCategoriesQuery } from '../../../../../app/apiSlice'
import { basicColorMap } from '../../../../../app/theme'
import type { BasicColorIndex } from '../../../../../app/theme'
import { Avatar, Select, Text } from '../../../../../common/designs'
import { useAppSelector } from '../../../../../common/hooks'
import { createInitials } from '../../../../../common/utils/stringFormatting'
import { selectUsers } from '../../../casesSlice'

interface Props {
  title?: string
  text?: string | number | (() => string | undefined)
  onChange?: (value: number) => void
  disabled?: boolean
  assigneeId?: number
  categoryId?: number
}

const DetailItem = (props: Props) => {
  const { title, text, onChange, disabled, assigneeId, categoryId } = props
  const users = useAppSelector(selectUsers)

  const { data: caseCategories = [] } = useGetCaseCategoriesQuery()

  if (text) {
    return (
      <div className='flex items-center'>
        <div className='w-32'>
          <Text size='s' color='gray' weight={500}>
            {title}
          </Text>
        </div>

        <div className='w-32'>
          <Text size='s' color='lightGray' weight={400}>
            {text as string}
          </Text>
        </div>
      </div>
    )
  }

  if (assigneeId) {
    const usersSelectData = users.map((u) => ({
      label: u.username,
      value: u.id,
    }))
    const assignee = users.find((u) => u.id === assigneeId)
    if (!assignee) return null

    return (
      <div className='flex items-center'>
        <div className='w-32'>
          <Text size='s' color='gray' weight={500}>
            Assignee
          </Text>
        </div>

        <div className='flex items-baseline'>
          <Avatar size='s' variant={assignee.color_index} rounded>
            {createInitials(assignee.username)}
          </Avatar>

          {users?.length && onChange ? (
            <Select
              variant='ghost'
              value={assigneeId}
              onChange={onChange}
              disabled={Boolean(disabled)}
              options={usersSelectData}
            />
          ) : (
            <Text size='s' color='lightGray' weight={400} className='ml-2'>
              {assignee.username}
            </Text>
          )}
        </div>
      </div>
    )
  }

  const caseSelectData = caseCategories.map((c) => ({
    label: c.name,
    value: c.id,
    prefix: (
      <div className={clsx('size-4 rounded-md', basicColorMap[c.color_index as BasicColorIndex])} />
    ),
  }))

  if (!categoryId || !onChange) return null

  return (
    <div className='flex items-center'>
      <div className='w-32'>
        <Text size='s' color='gray' weight={500}>
          Category
        </Text>
      </div>

      <Select
        variant='ghost'
        value={categoryId}
        onChange={onChange}
        options={caseSelectData}
        className='-ml-3'
      />
    </div>
  )
}

export default DetailItem
