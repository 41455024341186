import { Tabs } from '../../common/designs'
import { useEnhancedSearchParams } from '../../common/hooks'
import { SubSearchParamEnum } from '../../common/types/searchParams'
import { DetailLevelEnum } from '../Network/types'
import useOptions from './useOptions'

const DetailLevelSelector = () => {
  const { detailLevel } = useOptions()
  const { updateSearchParam, deleteSearchParam } = useEnhancedSearchParams()

  const onChangeDetailLevel = (value: string) => {
    if (value === DetailLevelEnum.APP) {
      deleteSearchParam(SubSearchParamEnum.DETAIL_LEVEL)
    } else {
      updateSearchParam(SubSearchParamEnum.DETAIL_LEVEL, DetailLevelEnum.WINDOW)
    }
  }

  return (
    <Tabs
      size='xs'
      variant='secondary'
      value={detailLevel}
      options={[
        { label: 'App', value: DetailLevelEnum.APP },
        { label: 'Window', value: DetailLevelEnum.WINDOW },
      ]}
      onChange={onChangeDetailLevel}
    />
  )
}

export default DetailLevelSelector
