import { ChartPieIcon, SquaresPlusIcon } from '@heroicons/react/24/outline'

import { useEnhancedNavigate } from '../../common/hooks'
import { MainPathEnum, SubPathEnum } from '../../common/types/routing'
import { compressAndEncodeJSON } from '../../common/utils/jsonCompression'
import Stat from '../Network/Stat'
import { DataFlowInsight, DataFlowInsightEdge, DataFlowInsightNode } from '../Network/types'
import { TaskFilterNameEnum } from '../TaskDiscovery/types'

interface Props {
  insights: DataFlowInsight
  isSelfLoopsVisible: boolean
}

const FlyoutStats = ({ insights, isSelfLoopsVisible }: Props) => {
  if (insights.node) {
    return <NodeFlyoutStats insights={insights.node} isSelfLoopsVisible={isSelfLoopsVisible} />
  }

  if (insights.edge) {
    return <EdgeFlyoutStats insights={insights.edge} />
  }

  return null
}

interface NodeFlyoutStatsProps {
  insights: DataFlowInsightNode
  isSelfLoopsVisible: boolean
}

const NodeFlyoutStats = ({ insights, isSelfLoopsVisible }: NodeFlyoutStatsProps) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { tags, nodeName, teamIds } = insights.entity.properties

  const onWorkflowsNavigation = () => {
    if (!tags.length) return
    const clickedNodeAppName =
      Object.values(tags)
        .filter((tag) => tag.key === 'appname')
        .map((tag) => tag.value)?.[0] ?? ''

    navigate(`/${MainPathEnum.PROCESSES}/${SubPathEnum.TASK_DISCOVERY}`, {
      search: `${TaskFilterNameEnum.MAIN_APP}=${clickedNodeAppName}`,
    })
  }

  const onMoreDetailsNavigation = () => {
    if (!tags.length) return
    navigate(`/${MainPathEnum.BUSINESS_APPS}/${compressAndEncodeJSON(tags)}`)
  }

  const splitLabel = (nodeName ?? '').split(' | ')

  return (
    <Stat.Root>
      <Stat.Label mainLabel={splitLabel[0]} subLabel={splitLabel[1]} />

      <Stat.Divider />

      <Stat.Container>
        <Stat.ItemCopies value={insights.externalCopies} />
        <Stat.ItemPastes value={insights.externalPastes} />
        {isSelfLoopsVisible && <Stat.ItemSelfLoop value={insights.selfLoopCopyPastes} />}
        <Stat.ItemTeams value={teamIds} />
      </Stat.Container>

      <Stat.Divider />

      <Stat.Cost label='Annualized cost' value={insights.annualizedCost} />

      {tags.length > 0 ? (
        <Stat.Navigation
          options={[
            {
              label: 'Workflows',
              onClick: onWorkflowsNavigation,
              icon: <SquaresPlusIcon />,
            },
            {
              label: 'More details',
              onClick: onMoreDetailsNavigation,
              icon: <ChartPieIcon />,
            },
          ]}
        />
      ) : null}
    </Stat.Root>
  )
}

interface EdgeFlyoutStatsProps {
  insights: DataFlowInsightEdge
}

const EdgeFlyoutStats = ({ insights }: EdgeFlyoutStatsProps) => {
  const sourceSplitLabel = (insights.entity.properties.sourceNodeName ?? '').split(' | ')
  const targetSplitLabel = (insights.entity.properties.targetNodeName ?? '').split(' | ')

  return (
    <Stat.Root>
      <Stat.Label mainLabel={sourceSplitLabel[0]} subLabel={sourceSplitLabel[1]} />
      <Stat.Divider label='to' />
      <Stat.Label mainLabel={targetSplitLabel[0]} subLabel={targetSplitLabel[1]} />

      <Stat.Divider />

      <Stat.Container>
        <Stat.ItemCopies value={insights.entity.value} />
        <Stat.ItemTeams value={insights.entity.properties.teamIds} />
      </Stat.Container>

      <Stat.Divider />

      <Stat.Cost label='Annualized cost' value={insights.annualizedCost} />
    </Stat.Root>
  )
}

export default FlyoutStats
