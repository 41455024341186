import { useCallback, useState } from 'react'

import { Modal, Tabs } from '../../../../../common/designs'
import Analysis from './Analysis'
import RelatedWindows from './RelatedWindows'
import StepMetrics from './StepMetrics'
import StepUserActivities from './StepUserActivities'
import { stepMetricNameExtraDataMapper } from './analysisConfigs'
import { ActiveTabEnum } from './constants'

const navTabs = [
  { label: 'Task Metrics', value: ActiveTabEnum.STEP_METRICS },
  { label: 'Task Analysis', value: ActiveTabEnum.STEP_ANALYSIS },
  { label: 'Related Windows', value: ActiveTabEnum.RELATED_WINDOWS },
  { label: 'Task Analysis', value: ActiveTabEnum.TASK_ANALYSIS },
]

interface Props {
  isOpen: boolean
  onClose: () => void
  stepName: string
}

export const StepDataModal = ({ isOpen, onClose, stepName }: Props) => {
  const [activeViewValue, setActiveViewValue] = useState<ActiveTabEnum>(ActiveTabEnum.STEP_METRICS)
  const onTabSelected = useCallback((val: string) => setActiveViewValue(val as ActiveTabEnum), [])

  let activeViewComponent = null

  if (isOpen) {
    switch (activeViewValue) {
      case ActiveTabEnum.STEP_METRICS:
        activeViewComponent = <StepMetrics />
        break
      case ActiveTabEnum.STEP_ANALYSIS:
        activeViewComponent = <Analysis metricOptionMapper={stepMetricNameExtraDataMapper} />
        break
      case ActiveTabEnum.RELATED_WINDOWS:
        activeViewComponent = <RelatedWindows />
        break
      case ActiveTabEnum.TASK_ANALYSIS:
        activeViewComponent = <StepUserActivities />
        break
      default:
        activeViewComponent = <StepMetrics />
        break
    }
  }

  return (
    <Modal label={stepName} open={isOpen} onClose={onClose} size='6xl'>
      <Tabs size='s' options={navTabs} value={activeViewValue} onChange={onTabSelected} />
      <div className='size-full'>{activeViewComponent}</div>
    </Modal>
  )
}

export default StepDataModal
