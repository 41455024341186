export enum DeletionScopeEnum {
  COMPANY = 'COMPANY',
  TEAM = 'TEAM',
}

export enum DataDeletionRequestState {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export interface DataDeletionRequest {
  id: number
  requested_by_user_full_name: string
  requested_at_timestamp: Date
  deletion_scope: DeletionScopeEnum
  team_name: string
  state: DataDeletionRequestState
}
