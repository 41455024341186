import { v4 as uuid4 } from 'uuid'

import {
  useCreateDataCollectionsConfigApplicationMutation,
  useUploadCompanyConfigMutation,
} from '../../app/apiSlice'
import { Button, Modal, Text } from '../../common/designs'
import { APP_TAG_KEY } from '../ConfigureDataCollectionAdvancedAdminPanelPage/constants'
import useManageServerTestCasesAndConfigs from './useManageServerTestCasesAndConfigs'

interface Props {
  open: boolean
  onClose: () => void
}

const SYSTEM_DISCOVERY_APP_NAME = 'System Discovery'

const SystemDiscoveryModal = ({ open, onClose }: Props) => {
  const [createAppCommonInfo, { isLoading: isLoadingCreateAppCommonInfo }] =
    useCreateDataCollectionsConfigApplicationMutation()
  const { createRules, isUploadingRules } = useManageServerTestCasesAndConfigs()
  const [uploadCompanyConfig, { isLoading: isLoadingUploadConfig }] =
    useUploadCompanyConfigMutation()

  const onEnableSystemDiscovery = async () => {
    await Promise.all([
      createAppCommonInfo({
        name: SYSTEM_DISCOVERY_APP_NAME,
        category: 'Other',
        is_pdf_application: false,
      }),
      createRules([
        {
          id: uuid4(),
          tags: [{ key: APP_TAG_KEY, value: SYSTEM_DISCOVERY_APP_NAME }],
          allow_tracking: true,
          extract_identifiers: [],
          salvage_fields: ['title', 'title_lower', 'url', 'url_parsed'],
          reactions: [],
          matching_criteria: {
            rule_engine_rule: 'true',
            context: {},
          },
          is_dashboard_generated: false,
          dashboard_context: {
            app_name: SYSTEM_DISCOVERY_APP_NAME,
          },
        },
      ]),
    ])

    await uploadCompanyConfig()
    onClose()
  }

  const isLoading = isLoadingCreateAppCommonInfo || isUploadingRules || isLoadingUploadConfig

  return (
    <Modal label='Start System Discovery?' open={open} onClose={onClose} size='xl'>
      <div className='space-y-4'>
        <Text>
          System Discovery will record URLs, titles, and process names for all applications while
          the agent is installed and running. Based on the collected data, you can establish more
          detailed data collection rules for the specific applications you wish to monitor.
        </Text>

        <Text size='xs'>
          <b>Note:</b> The data collection encompasses all systems and may include data from
          applications used outside of business functionalities.
        </Text>

        <div className='sm:grid sm:grid-cols-2 sm:gap-3'>
          <Button onClick={onEnableSystemDiscovery} loading={isLoading}>
            Enable System Discovery
          </Button>
          <Button variant='white' onClick={onClose} loading={isLoading}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SystemDiscoveryModal
