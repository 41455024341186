import { PlusIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import {
  useGetTransactionMetricsQuery,
  useLazyGetTransactionLogQuery,
} from '../../../../app/apiSlice'
import { DEFAULT_DATE_FORMATTING_LOCALE } from '../../../../app/constants'
import { InlineLoader } from '../../../../common/components'
import { Button, LinkButton, Modal, Table, Text } from '../../../../common/designs'
import {
  useLocalStorage,
  usePaginationWithDataQuery,
  useQueryFilters,
} from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import {
  formatNumber,
  getBestTimeFormatForSeconds,
} from '../../../../common/utils/numberFormatting'
import {
  MetricNamesEnum,
  type TransactionFilters,
  type TransactionLogData,
  type TransactionLogQueryOptions,
} from '../../types'
import { TREND_DATE_FORMAT_OPTIONS } from './../TrendConstants'

const TRANSACTION_TABLE_HEADERS = ['Case ID', 'Start Date', 'End Date', 'Duration', '']

interface Props {
  open: boolean
  onClose: () => void
  longCasesThresholdSec: number
}

const TransactionNormalDistributionModal = ({ open, onClose, longCasesThresholdSec }: Props) => {
  const { processId, processFilters: filters } = useQueryFilters()
  const storage = useLocalStorage()

  const [transactionList, setTransactionList] = useState<TransactionLogData[] | null>(null)
  const [isLoadingTransactionList, setIsLoadingTransactionList] = useState<boolean>(false)

  const minDurationFilter = { min_duration_sec: Math.round(longCasesThresholdSec * 100) / 100 }
  const usedQueryFilters = {
    ...filters,
    ...minDurationFilter,
  } as TransactionFilters

  const { data: metricData, isFetching: isFetchingMetricData } = useGetTransactionMetricsQuery({
    processId,
    filters: usedQueryFilters,
    metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
  })

  const [getTransactionList] = useLazyGetTransactionLogQuery()
  const { onFetchDataForNextBatch, isAllResultsFetched } = usePaginationWithDataQuery<
    TransactionLogQueryOptions,
    TransactionLogData[]
  >({
    onFetchData: getTransactionList,
    totalResults: metricData?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0,
    dataBatchSize: 30,
  })

  const onShowMoreTransactions = async () => {
    setIsLoadingTransactionList(true)

    let data: TransactionLogData[]

    try {
      data = await onFetchDataForNextBatch({
        processId,
        filters: usedQueryFilters,
      })
    } catch (error) {
      console.error('ERROR: ', error)
      data = []
    }

    setTransactionList((existingData) => [...(existingData ?? []), ...data])
    setIsLoadingTransactionList(false)
  }

  const onSetTrendPeriodFilter = () => {
    storage.updateNestedItem(
      StorageNameEnum.PROCESS_FILTERS,
      ProcessFiltersStorageContentEnum.PROCESS_METRICS,
      minDurationFilter,
    )

    onCloseModal()
  }

  const onSetTransactionFilter = (transactionId: string) => {
    storage.updateNestedItem(
      StorageNameEnum.PROCESS_FILTERS,
      ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
      { transaction_id_filters: [transactionId] },
    )

    onCloseModal()
  }

  const onCloseModal = () => {
    setTransactionList(null)
    setIsLoadingTransactionList(false)
    onClose()
  }

  return (
    <Modal
      label={`Cases longer than ${getBestTimeFormatForSeconds(longCasesThresholdSec)} (+1 σ)`}
      size='4xl'
      open={open}
      onClose={onCloseModal}
    >
      {isFetchingMetricData ? (
        <InlineLoader />
      ) : transactionList ? (
        <div>
          <Table
            className='-ml-4'
            head={TRANSACTION_TABLE_HEADERS}
            body={transactionList.map((tr) => [
              tr.case_id,
              tr.start_date.toLocaleString(
                DEFAULT_DATE_FORMATTING_LOCALE,
                TREND_DATE_FORMAT_OPTIONS,
              ),
              tr.end_date.toLocaleString(DEFAULT_DATE_FORMATTING_LOCALE, TREND_DATE_FORMAT_OPTIONS),
              `${formatNumber(tr.duration_in_seconds / 3600, 1000)} hours`,
              <LinkButton
                key={`${tr.case_id}-filter-link`}
                onClick={() => onSetTransactionFilter(tr.case_id)}
              >
                Filter by this Case
              </LinkButton>,
            ])}
          />
          <Text className='mt-1' size='xs' color='lightGray'>
            {transactionList.length}/{metricData?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0} Cases
            Shown
          </Text>
          {isLoadingTransactionList && <InlineLoader />}
          {!isAllResultsFetched && (
            <Button
              iconStart={<PlusIcon />}
              className='my-2'
              wFull
              onClick={onShowMoreTransactions}
              disabled={isLoadingTransactionList}
            >
              Show More
            </Button>
          )}
          <div className='flex gap-4'>
            <Button variant='white' onClick={() => setTransactionList(null)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className='space-y-4'>
          {isLoadingTransactionList && <InlineLoader />}

          <div className='flex gap-4'>
            <Button variant='white' onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSetTrendPeriodFilter} disabled={isLoadingTransactionList}>
              Set {getBestTimeFormatForSeconds(longCasesThresholdSec)} as a filter
            </Button>
            <Button onClick={onShowMoreTransactions} disabled={isLoadingTransactionList}>
              Show List of Cases
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default TransactionNormalDistributionModal
