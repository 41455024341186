import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { RootState } from '../../app/store'
import type {
  FilterDimemsionOptionData,
  FilterUserActionOptionData,
} from './ProcessMining/Filters/types'
import type { StateVariantData, StepVariantData } from './ProcessMining/Variants/types'
import { ProcessProperties } from './ProcessMining/types'
import { OrderByDirectionEnum } from './constants'
import { GroupByFieldTypeEnum, HighlightFieldEnum, StepVariantOrderByEnum } from './types'
import type { HighlightSelections, State, VariantOrder } from './types'

const initialState: State = {
  dimensionFilterOptions: [],
  userActionFilterOptions: null,
  initialVisibleVariantAmount: 5,
  variantOrder: {
    variable: StepVariantOrderByEnum.TRANSACTION_AMOUNT,
    direction: OrderByDirectionEnum.DESC,
  },
  highlightSelections: {
    activeHighlighField: HighlightFieldEnum.TRANSACTIONS,
    isTeamDistributionOpen: false,
    isWorkTypeDistributionOpen: false,
    isAutomationInsightsOpen: false,
    isUnwindModeActive: false,
  },
  variantsLayoutData: {
    variantsInsightBarItems: [],
    viewDescriptionText: '',
  },
  stepVariantData: [],
  stateVariantData: [],
  processProperties: null,
}

export const processesSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {
    setDimensionFilterOptions: (state, action: PayloadAction<FilterDimemsionOptionData[]>) => {
      state.dimensionFilterOptions = action.payload
    },
    setUserActionFilterOptions: (state, action: PayloadAction<FilterUserActionOptionData>) => {
      state.userActionFilterOptions = action.payload
    },
    setVariantOrder: (state, action: PayloadAction<VariantOrder>) => {
      state.variantOrder = action.payload
    },
    setVariantsInsightBarItems: (
      state,
      action: PayloadAction<Array<{ label: string; value: string | number }>>,
    ) => {
      state.variantsLayoutData.variantsInsightBarItems = action.payload
    },
    setViewDescriptionText: (state, action: PayloadAction<string>) => {
      state.variantsLayoutData.viewDescriptionText = action.payload
    },
    setHighlightSelections: (state, action: PayloadAction<HighlightSelections>) => {
      state.highlightSelections = action.payload
    },
    setActiveHighlighField: (state, action: PayloadAction<HighlightFieldEnum>) => {
      state.highlightSelections.activeHighlighField = action.payload
    },
    toggleIsTeamdistributionOpen: (state) => {
      state.highlightSelections = {
        ...state.highlightSelections,
        isTeamDistributionOpen: !state.highlightSelections.isTeamDistributionOpen,
      }
    },
    toggleIsWorkTypeDistributionOpen: (state) => {
      state.highlightSelections = {
        ...state.highlightSelections,
        isWorkTypeDistributionOpen: !state.highlightSelections.isWorkTypeDistributionOpen,
      }
    },
    toggleIsAutomationInsightsOpen: (state) => {
      state.highlightSelections = {
        ...state.highlightSelections,
        isAutomationInsightsOpen: !state.highlightSelections.isAutomationInsightsOpen,
      }
    },
    toggleIsUnwindModeActive: (state) => {
      state.highlightSelections = {
        ...state.highlightSelections,
        isUnwindModeActive: !state.highlightSelections.isUnwindModeActive,
      }
    },
    setStepVariantData: (state, action: PayloadAction<StepVariantData[]>) => {
      state.stepVariantData = action.payload
    },
    setStateVariantData: (state, action: PayloadAction<StateVariantData[]>) => {
      state.stateVariantData = action.payload
    },
    setProcessProperties: (state, action: PayloadAction<ProcessProperties>) => {
      state.processProperties = action.payload
    },
  },
})

export const {
  setDimensionFilterOptions,
  setUserActionFilterOptions,
  setVariantOrder,
  setVariantsInsightBarItems,
  setViewDescriptionText,
  setHighlightSelections,
  setActiveHighlighField,
  toggleIsTeamdistributionOpen,
  toggleIsWorkTypeDistributionOpen,
  toggleIsAutomationInsightsOpen,
  toggleIsUnwindModeActive,
  setStepVariantData,
  setStateVariantData,
  setProcessProperties,
} = processesSlice.actions

export const selectDimensionFilterOptions = (state: RootState) =>
  state.processes.dimensionFilterOptions
export const selectUserActionFilterOptions = (state: RootState) =>
  state.processes.userActionFilterOptions
export const selectStepNameOptions = createSelector(
  selectDimensionFilterOptions,
  (dimensionFilterOptions) =>
    dimensionFilterOptions.find((o) => o.group_by.field_type === GroupByFieldTypeEnum.STEP_NAMES),
)
export const selectInitialVisibleVariantAmount = (state: RootState) =>
  state.processes.initialVisibleVariantAmount
export const selectVariantOrder = (state: RootState) => state.processes.variantOrder
export const selectVariantsLayoutData = (state: RootState) => state.processes.variantsLayoutData
export const selectHighlightSelections = (state: RootState) => state.processes.highlightSelections
export const selectToggleableHighlightSelections = (state: RootState) => {
  const highlightSelections = state.processes.highlightSelections
  return Object.keys(highlightSelections)
    .filter((key) => typeof (highlightSelections as any)[key] === 'boolean')
    .reduce(
      (acc, key) => {
        acc[key] = (highlightSelections as any)[key]
        return acc
      },
      {} as { [key: string]: boolean },
    )
}
export const selectActiveHighlightField = (state: RootState) =>
  state.processes.highlightSelections.activeHighlighField
export const selectStepVariantData = (state: RootState) => state.processes.stepVariantData
export const selectStateVariantData = (state: RootState) => state.processes.stateVariantData
export const selectProcessProperties = (state: RootState) => state.processes.processProperties
