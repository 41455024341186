import { useCallback, useEffect, useRef, useState } from 'react'

import { Loader, VariableCombinationBarChartAndTableDisplay } from '../../../../common/components'
import type { VariabeCombinationDataEntry } from '../../../../common/components/Charts/VariableCombinationBarChart'
import type { GroupedBarChartDataEntry } from '../../../../common/components/Charts/types'
import GroupedBarChartAndTableDisplay from '../../../../common/components/displays/GroupedBarChartAndTableDisplay'
import { Button, Select, Tabs } from '../../../../common/designs'
import { useDashboard } from '../../../../common/hooks'
import { isStringArray } from '../../../../common/types/typeGuards'
import type { LabelData, MetricNamesEnum, TransactionGroupResultMultiple } from '../../types'
import { setUpChartDataAndMetricForMultipleDimensions } from './chartDataHelpers'
import { metricNameExtraDataMapper } from './constants'

interface Props {
  stepRequestMultipleData: TransactionGroupResultMultiple | undefined
  selectedMetric: MetricNamesEnum | null
  selectedGroupByFields: string[]
}

enum TimeSelectionEnum {
  DAYS = 'days',
  HOURS = 'h',
  MINUTES = 'min',
  SECONDS = 'sec',
}
enum VisualizationOptionEnum {
  GROUPED_BARCHART = 'Grouped Barchart',
  BARCHART = 'Barchart',
}

export const CustomAnalysisMultipleDimensions = ({
  stepRequestMultipleData,
  selectedMetric,
  selectedGroupByFields,
}: Props) => {
  const { teams } = useDashboard()

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<TimeSelectionEnum>(
    TimeSelectionEnum.MINUTES,
  )
  const [selectedVisualization, setSelectedVisualization] = useState<VisualizationOptionEnum>(
    VisualizationOptionEnum.BARCHART,
  )
  const [activeUnit, setActiveUnit] = useState<string>('')
  const [activeGroupedChartData, setActiveGroupedChartData] = useState<{
    data: GroupedBarChartDataEntry[]
    mainGroupIndex: number
  }>({ data: [], mainGroupIndex: 0 })
  const [activeVariableCombinationChartData, setActiveVariableCombinationChartData] = useState<
    VariabeCombinationDataEntry[]
  >([])
  const teamNameToIdMapper = useRef<{ [key: string]: string }>({})
  const teamNamesToIdArrayMapper = useRef<{ [key: string]: string[] }>({})

  useEffect(() => {
    if (selectedGroupByFields.length > 2) setSelectedVisualization(VisualizationOptionEnum.BARCHART)
  }, [selectedGroupByFields])

  const setChartData = useCallback(
    (activeData: TransactionGroupResultMultiple | undefined) => {
      if (!activeData || !selectedMetric || !activeData[selectedMetric] || !selectedGroupByFields)
        return

      let selectedTimeData

      switch (selectedTimeUnit) {
        case TimeSelectionEnum.DAYS:
          selectedTimeData = { divider: 24 * 3600, unit: TimeSelectionEnum.DAYS }
          break
        case TimeSelectionEnum.HOURS:
          selectedTimeData = { divider: 3600, unit: TimeSelectionEnum.HOURS }
          break
        case TimeSelectionEnum.MINUTES:
          selectedTimeData = { divider: 60, unit: TimeSelectionEnum.MINUTES }
          break
        case TimeSelectionEnum.SECONDS:
          selectedTimeData = { divider: 1, unit: TimeSelectionEnum.SECONDS }
          break
        default:
          selectedTimeData = { divider: 1, unit: TimeSelectionEnum.SECONDS }
          break
      }

      const {
        chartData,
        unit,
        teamNameToIdMapper: newTeamNameToIdMapper,
        teamNamesToIdArrayMapper: newTeamNamesToIdArrayMapper,
      } = setUpChartDataAndMetricForMultipleDimensions(
        activeData,
        selectedMetric,
        teams,
        selectedTimeData,
      )
      teamNameToIdMapper.current = newTeamNameToIdMapper
      teamNamesToIdArrayMapper.current = newTeamNamesToIdArrayMapper

      setActiveUnit(unit)

      if (selectedVisualization === VisualizationOptionEnum.GROUPED_BARCHART) {
        const groupedData: { [key: string]: Array<{ label: LabelData; value: number }> } = {}
        chartData.forEach(({ value, labels }) => {
          const labelValue = labels[activeGroupedChartData.mainGroupIndex].label_value
          const key = isStringArray(labelValue) ? labelValue.join(', ') : labelValue
          if (key in groupedData) {
            groupedData[key].push({
              label: labels[activeGroupedChartData.mainGroupIndex ? 0 : 1],
              value,
            })
          } else {
            groupedData[key] = [
              {
                label: labels[activeGroupedChartData.mainGroupIndex ? 0 : 1],
                value,
              },
            ]
          }
        })

        const groupedChartData: GroupedBarChartDataEntry[] = Object.entries(groupedData).map(
          ([groupName, dataArray]) => {
            return {
              groupName,
              data: dataArray.map(({ label, value }) => ({
                label: isStringArray(label.label_value)
                  ? label.label_value.join(', ')
                  : label.label_value,
                value,
              })),
            }
          },
        )

        setActiveGroupedChartData((cur) => ({ ...cur, data: groupedChartData }))
      } else {
        const variableCombinationChartData: VariabeCombinationDataEntry[] = chartData.map(
          ({ value, labels }) => ({
            variables: labels.map((l) => ({
              label: l.label_type,
              value: isStringArray(l.label_value) ? l.label_value.join(', ') : l.label_value,
            })),
            value,
          }),
        )
        setActiveVariableCombinationChartData(variableCombinationChartData)
      }
    },
    [
      selectedVisualization,
      selectedMetric,
      selectedGroupByFields,
      selectedTimeUnit,
      teams,
      activeGroupedChartData.mainGroupIndex,
    ],
  )

  // Server data to chart data logic
  useEffect(() => {
    setChartData(stepRequestMultipleData)
  }, [stepRequestMultipleData, setChartData])

  const metricMetaData =
    metricNameExtraDataMapper[selectedMetric as keyof typeof metricNameExtraDataMapper]

  const tabOptions = [
    { label: VisualizationOptionEnum.BARCHART, value: VisualizationOptionEnum.BARCHART },
    {
      label: VisualizationOptionEnum.GROUPED_BARCHART,
      value: VisualizationOptionEnum.GROUPED_BARCHART,
      disabled: selectedGroupByFields.length > 2,
    },
  ]

  const selectActions =
    activeUnit === selectedTimeUnit
      ? [
          <Select
            size='xs'
            key='time-unit'
            options={[
              { label: TimeSelectionEnum.DAYS, value: TimeSelectionEnum.DAYS },
              { label: TimeSelectionEnum.HOURS, value: TimeSelectionEnum.HOURS },
              { label: TimeSelectionEnum.MINUTES, value: TimeSelectionEnum.MINUTES },
              { label: TimeSelectionEnum.SECONDS, value: TimeSelectionEnum.SECONDS },
            ]}
            value={selectedTimeUnit}
            onChange={(val: string) => setSelectedTimeUnit(val as TimeSelectionEnum)}
          />,
        ]
      : []

  if (!stepRequestMultipleData) return <Loader />

  return (
    <>
      <Tabs
        className='w-96'
        variant='secondary'
        size='xs'
        options={tabOptions}
        value={selectedVisualization}
        onChange={setSelectedVisualization as (value: string) => void}
      />
      {selectedVisualization === VisualizationOptionEnum.BARCHART ? (
        <VariableCombinationBarChartAndTableDisplay
          label={metricMetaData?.metric_name ?? ''}
          data={activeVariableCombinationChartData}
          unit={activeUnit}
          selectActions={selectActions}
        />
      ) : (
        <GroupedBarChartAndTableDisplay
          label={metricMetaData?.metric_name ?? ''}
          data={activeGroupedChartData.data}
          unit={activeUnit}
          numberRounding={100}
          selectActions={selectActions}
          otherActions={[
            <Button
              key='orientation'
              size='xs'
              onClick={() =>
                setActiveGroupedChartData((cur) => ({
                  ...cur,
                  mainGroupIndex: cur.mainGroupIndex ? 0 : 1,
                }))
              }
            >
              Change orientation
            </Button>,
          ]}
        />
      )}
    </>
  )
}

export default CustomAnalysisMultipleDimensions
