import { teamBgColorMap } from '../../app/theme'
import {
  TimeUnitEnum,
  formatNumber,
  getBestTimeFormatTransformationForSeconds,
} from '../../common/utils/numberFormatting'
import DistributionChart from './DistributionChart'
import type { ActivityCategorization } from './types'

interface Props {
  activityData: ActivityCategorization[]
}

const TaskListActivityDistribution = ({ activityData }: Props) => {
  const totalTime = activityData.reduce((total, item) => total + item.duration_sec, 0)
  const sortedData = [...activityData].sort((a, b) => b.duration_sec - a.duration_sec)
  const { divider, unit } = getBestTimeFormatTransformationForSeconds(
    sortedData[0].duration_sec,
    TimeUnitEnum.HOURS,
  )

  const visualizationData = sortedData.map(({ activity_name, duration_sec }, i) => {
    return {
      value: `${formatNumber(duration_sec / divider)} ${unit}`,
      relativeValue: Math.round((duration_sec / totalTime) * 100),
      label: activity_name,
      bgColor: teamBgColorMap[(i % 10) + 1],
    }
  })

  return <DistributionChart data={visualizationData} />
}

export default TaskListActivityDistribution
