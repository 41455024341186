import { Modal, Text } from '../../common/designs'
import type { Team } from '../../common/utils/UtilityWrapper/types'

interface Props {
  activeTeam: Team | null
  open: boolean
  onClose: () => void
}

const InstallationInstructionsModal = ({ activeTeam, open, onClose }: Props) => {
  const { name, invitationToken } = activeTeam ?? {}

  return (
    <Modal label='Installation Guide' size='5xl' open={open} onClose={onClose}>
      <div className='space-y-2'>
        <Text>
          An invitation token connects the Agent to a team. Those tokens can be added as
          installation parameters when using the command line or Intune for installation.
          Alternatively, it can be set directly as an environment variable in the target computer.
        </Text>

        {invitationToken ? (
          <>
            <InstructionBox label='Team name'>{name}</InstructionBox>

            <InstructionBox label='Invitation token'>{invitationToken}</InstructionBox>

            <InstructionBox label='Installation command'>
              msiexec /i &quot;workfellow-plug-in.msi&quot; /qn INVITATION_TOKEN=&quot;
              {invitationToken}&quot;
            </InstructionBox>

            <InstructionBox label='Environment variable'>
              WORKFELLOW_INVITATION_TOKEN={invitationToken}
            </InstructionBox>
          </>
        ) : (
          <Text>For some reason the invitation token is missing. You should contact support.</Text>
        )}
      </div>
    </Modal>
  )
}

interface InstructionBoxProps {
  label: string
  children: string | undefined | string[]
}

const InstructionBox = ({ label, children }: InstructionBoxProps) => {
  return (
    <div className='flex gap-6 rounded-md border p-2'>
      <Text weight={500}>{label}:</Text>
      <Text color='primary'>{children}</Text>
    </div>
  )
}

export default InstallationInstructionsModal
