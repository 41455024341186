import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { StateVariantData, StepVariantData, TeamVariantData } from '../ProcessMining/Variants/types'
import type { OrderByVariableValue } from '../types'
import { StateVariantOrderByEnum, StepVariantOrderByEnum } from '../types'

export const getTransactionFlowMetricLabel = (orderVariable: OrderByVariableValue) => {
  switch (orderVariable) {
    case StepVariantOrderByEnum.TRANSACTION_AMOUNT:
      return 'Avg. duration'

    case StepVariantOrderByEnum.STEP_AMOUNT:
      return 'Steps'

    case StepVariantOrderByEnum.THROUGHPUT_TIME:
      return 'Avg. duration'

    case StepVariantOrderByEnum.ACTIVE_WORK:
      return 'Avg. active work'

    case StepVariantOrderByEnum.TOUCH_AMOUNT:
      return 'Avg. touches'

    default:
      return 'Avg. duration'
  }
}

export const getTransactionFlowMetricValue = (
  orderVariable: OrderByVariableValue,
  variant: TeamVariantData | StepVariantData | StateVariantData,
) => {
  switch (orderVariable) {
    case StepVariantOrderByEnum.TRANSACTION_AMOUNT:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)

    case StepVariantOrderByEnum.STEP_AMOUNT:
      return formatNumber(variant.step_amount)

    case StepVariantOrderByEnum.THROUGHPUT_TIME:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)

    case StepVariantOrderByEnum.ACTIVE_WORK:
      return getBestTimeFormatForSeconds((variant as StepVariantData).avg_active_work_sec)

    case StepVariantOrderByEnum.TOUCH_AMOUNT:
      return formatNumber((variant as StepVariantData).avg_touch_amount)

    default:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)
  }
}

export const getStateChangeFlowMetricLabel = (orderVariable: OrderByVariableValue) => {
  switch (orderVariable) {
    case StateVariantOrderByEnum.TRANSACTION_AMOUNT:
      return 'Avg. duration'

    case StateVariantOrderByEnum.STEP_AMOUNT:
      return 'Steps'

    case StateVariantOrderByEnum.THROUGHPUT_TIME:
      return 'Avg. duration'

    default:
      return 'Avg. duration'
  }
}

export const getStateChangeFlowMetricValue = (
  orderVariable: OrderByVariableValue,
  variant: StepVariantData | StateVariantData,
) => {
  switch (orderVariable) {
    case StateVariantOrderByEnum.TRANSACTION_AMOUNT:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)

    case StateVariantOrderByEnum.STEP_AMOUNT:
      return formatNumber(variant.step_amount)

    case StateVariantOrderByEnum.THROUGHPUT_TIME:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)

    default:
      return getBestTimeFormatForSeconds(variant.avg_throughput_time_sec)
  }
}

export const windowFlowOrderByOptions = Object.values(StepVariantOrderByEnum).map((o) => ({
  label: o,
  value: o,
  key: o,
}))

export const stateChangeFlowOrderByOptions = Object.values(StateVariantOrderByEnum).map((o) => ({
  label: o,
  value: o,
  key: o,
}))

