import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'

import { BarChartAndTableDisplay } from '../../../../common/components'
import { Select } from '../../../../common/designs'
import { useEnhancedNavigate } from '../../../../common/hooks'
import { compressAndEncodeJSON } from '../../../../common/utils/jsonCompression'
import { ActivitiesLayoutOutletContext } from './ActivitiesLayout'

const options = [
  { label: 'Activities', value: '' },
  { label: 'Key presses', value: 'text_entry' },
  { label: 'Copies from app', value: 'copies' },
  { label: 'Pastes to app', value: 'pastes' },
]
interface Props {
  dataKey: 'copies' | 'pastes' | 'text_entry'
  title: string
}

const ActivityBarChart = ({ dataKey, title }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { data } = useOutletContext<ActivitiesLayoutOutletContext>()

  const chartData = useMemo(() => {
    return (
      data?.[dataKey]?.map((item) => ({
        label: item.window_name,
        value: item.total,
      })) ?? []
    )
  }, [data, dataKey])

  const onChartClick = (_: string, dataIndex: number) => {
    if (data?.[dataKey]?.[dataIndex]) {
      const windowTags = data[dataKey][dataIndex].window_tags
      navigate(`../../../window/${compressAndEncodeJSON(windowTags)}`)
    }
  }
  const unit = dataKey === 'text_entry' ? 'key presses' : dataKey

  return (
    <BarChartAndTableDisplay
      label={`${title} in total`}
      data={chartData}
      unit={unit}
      tableHeaderValues={['Activity', 'Value']}
      onClick={onChartClick}
      isExportable
      selectActions={[
        <Select
          key='select-activity'
          size='xs'
          options={options}
          value={options.find((o) => o.value === dataKey)?.value ?? null}
          onChange={(val: string) => navigate(`../${val}`)}
        />,
      ]}
    />
  )
}

export default ActivityBarChart
