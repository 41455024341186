import { Stat } from '../../common/components'
import { useCostUtils } from '../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../common/utils/numberFormatting'
import type { TaskVariantData } from './types'

interface Props {
  data: TaskVariantData
  selectedVariants: number[]
}

const TaskVariantInsightBar = ({ data, selectedVariants }: Props) => {
  const { currency, formatCost } = useCostUtils()

  const selectedVariantData = selectedVariants
    .filter((i) => data.variant_list[i])
    .map((variantIndex) => ({
      totalCases: data.variant_list[variantIndex]?.transactions,
      totalThroughputTime:
        data.variant_list[variantIndex]?.transactions *
        data.variant_list[variantIndex]?.avg_active_work_sec,
    }))

  const taskAmount = selectedVariantData.reduce(
    (totalCases, variantData) => totalCases + variantData.totalCases,
    0,
  )
  const taskDurationSec = data.nodes.reduce(
    (totalActiveWork, nodeData) => totalActiveWork + nodeData.duration_sec,
    0,
  )
  const avgTaskDurationSec = taskDurationSec / taskAmount
  const costPerTask = (avgTaskDurationSec / 3600) * currency.costPerHour

  const items = [
    { label: 'Variants', value: formatNumber(selectedVariants.length) },
    { label: 'Total tasks', value: formatNumber(taskAmount) },
    {
      label: 'Avg. task duration',
      value: `${getBestTimeFormatForSeconds(avgTaskDurationSec)}`,
    },
    {
      label: 'Cost per task',
      value: formatCost(costPerTask),
    },
  ]

  return (
    <div className='flex flex-col gap-1'>
      {items.map((item) => (
        <Stat data={item} key={item.label} />
      ))}
    </div>
  )
}

export default TaskVariantInsightBar
