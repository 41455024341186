import { Checkboxes } from '../../../../common/designs'
import { useAppDispatch, useAppSelector, useDashboard } from '../../../../common/hooks'
import {
  selectFilterCollaboratorIds,
  selectUsers,
  setFilterCollaboratorIds,
} from '../../casesSlice'

const CollaboratorFilter = () => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()
  const users = useAppSelector(selectUsers)
  const filterIds = useAppSelector(selectFilterCollaboratorIds)

  if (user.isDataReader) return null

  return (
    <div>
      <Checkboxes
        label='Collaborator / Assignee'
        values={filterIds}
        options={[
          ...Object.values(users).map((u) => ({
            label: u.username,
            value: u.id,
          })),
        ]}
        onChange={(arr: number[]) => dispatch(setFilterCollaboratorIds(arr))}
      />
    </div>
  )
}

export default CollaboratorFilter
