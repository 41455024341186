import {
  ArrowDownTrayIcon,
  FolderArrowDownIcon,
  LinkIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'

import { useGetInstallerLinkQuery } from '../../app/apiSlice'
import { LINK_USER_DOCUMENTATION } from '../../app/constants'
import { UserFlowStepContainer } from '../../common/components'
import { Button, Divider, Link, Table } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import type { Team } from '../../common/utils/UtilityWrapper/types'
import { downloadFile } from '../../common/utils/utils'
import InstallationInstructionsModal from './InstallationInstructionsModal'
import TeamSettings from './TeamSettings'

const InstallationCenter = () => {
  const { teams } = useDashboard()
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
  const { data: installerLink } = useGetInstallerLinkQuery()

  return (
    <>
      <div>
        <UserFlowStepContainer
          stepNumber={1}
          title='Team Setup'
          description='Define data collection teams'
          icon={<UserCircleIcon />}
        >
          <TeamSettings />
        </UserFlowStepContainer>

        <Divider />

        <UserFlowStepContainer
          stepNumber={2}
          title='Agent Deployment'
          description='Team-specific installation instructions are below. The MSI installer is the same for all installations.'
          icon={<FolderArrowDownIcon />}
        >
          <div className='-ml-4'>
            <Table
              head={['Team Name', 'Installation Guide']}
              body={
                Object.values(teams).map((t) => {
                  return [
                    t.name,
                    <Link onClick={() => setSelectedTeam(t)} key={t.id}>
                      Open Instructions
                    </Link>,
                  ]
                }) ?? []
              }
              action={
                <div className='flex gap-4'>
                  <Button
                    variant='secondary'
                    onClick={() => window.open(LINK_USER_DOCUMENTATION, '_blank')}
                    iconStart={<LinkIcon />}
                    className='-ml-4'
                  >
                    Documentation
                  </Button>

                  <Button
                    iconStart={<ArrowDownTrayIcon />}
                    onClick={() => {
                      downloadFile(installerLink ?? '', 'workfellow-plug-in.msi')
                    }}
                    disabled={!installerLink}
                  >
                    Download Agent Installer
                  </Button>
                </div>
              }
            />
          </div>
        </UserFlowStepContainer>
      </div>

      <InstallationInstructionsModal
        activeTeam={selectedTeam}
        open={Boolean(selectedTeam)}
        onClose={() => setSelectedTeam(null)}
      />
    </>
  )
}

export default InstallationCenter
