import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { Line } from 'react-chartjs-2'

import type { ChartData } from '../../../common/types/common'
import { formatNumber } from '../../../common/utils/numberFormatting'

const lineChartOptions = {
  interaction: {
    mode: 'index',
    intersect: false,
  },
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
          return `${tooltipItem.label}: ${tooltipItem.formattedValue}`
        },
      },
    },
    legend: {
      align: 'start',
      labels: {
        padding: 4,
        usePointStyle: true,
        pointStyleWidth: 16,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value: string | number) => formatNumber(value),
      },
    },
  },
}

interface Props {
  data: ChartData
  tooltipCallback?: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => string
}

const LineChart = ({ data, tooltipCallback }: Props) => {
  if (tooltipCallback) lineChartOptions.plugins.tooltip.callbacks.label = tooltipCallback

  return (
    <div className='h-[200px] w-full'>
      <Line data={data as any} options={lineChartOptions as any} />
    </div>
  )
}

export default LineChart
