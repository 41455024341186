export interface State {
  activeViewFavorite: FavoriteType[] | null
}

export interface FavoriteType {
  automatic_name: string
  url: string
  query_options: any
  unit: string
  data_key: string
  data_key_parent?: string
}

export enum FavoriteTypeEnum {
  PRIVATE = 0,
  COMPANY = 1,
  OPPORTUNITY = 2,
}

