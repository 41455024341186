import { generateCustomIndexesArrayUntil } from '../../common/utils/utils'
import {
  PerformanceReadingTypeCpuEnum,
  PerformanceReadingTypeEnum,
  PerformanceReadingTypeMemoryEnum,
} from './types'

export const usageDistributionsAsArray = {
  [PerformanceReadingTypeEnum.CPU]: {
    [PerformanceReadingTypeCpuEnum.MAIN_PROCESS]: generateCustomIndexesArrayUntil(101, 0),
    [PerformanceReadingTypeCpuEnum.PROCESSING_UNIVERSE]: generateCustomIndexesArrayUntil(101, 0),
    [PerformanceReadingTypeCpuEnum.MONITORING_UNIVERSE]: generateCustomIndexesArrayUntil(101, 0),
    [PerformanceReadingTypeCpuEnum.IO_UNIVERSE]: generateCustomIndexesArrayUntil(101, 0),
    [PerformanceReadingTypeCpuEnum.TOTAL_SYSTEM_CPU_LOAD]: generateCustomIndexesArrayUntil(101, 0),
  },
  [PerformanceReadingTypeEnum.MEMORY]: {
    [PerformanceReadingTypeMemoryEnum.PLUGIN_MEMORY_LOAD]: generateCustomIndexesArrayUntil(101, 0),
    [PerformanceReadingTypeMemoryEnum.TOTAL_SYSTEM_MEMORY_LOAD]: generateCustomIndexesArrayUntil(
      101,
      0,
    ),
  },
}

export const usageDistributionsAsSum = {
  [PerformanceReadingTypeEnum.CPU]: {
    [PerformanceReadingTypeCpuEnum.MAIN_PROCESS]: 0,
    [PerformanceReadingTypeCpuEnum.PROCESSING_UNIVERSE]: 0,
    [PerformanceReadingTypeCpuEnum.MONITORING_UNIVERSE]: 0,
    [PerformanceReadingTypeCpuEnum.IO_UNIVERSE]: 0,
    [PerformanceReadingTypeCpuEnum.TOTAL_SYSTEM_CPU_LOAD]: 0,
  },
  [PerformanceReadingTypeEnum.MEMORY]: {
    [PerformanceReadingTypeMemoryEnum.PLUGIN_MEMORY_LOAD]: 0,
    [PerformanceReadingTypeMemoryEnum.TOTAL_SYSTEM_MEMORY_LOAD]: 0,
  },
}

export const performanceReadingTypeColors = {
  [PerformanceReadingTypeEnum.CPU]: {
    [PerformanceReadingTypeCpuEnum.MAIN_PROCESS]: '#10b981', // Emerald 500
    [PerformanceReadingTypeCpuEnum.PROCESSING_UNIVERSE]: '#34d399', // Emerald 400
    [PerformanceReadingTypeCpuEnum.MONITORING_UNIVERSE]: '#6ee7b7', // Emerald 300
    [PerformanceReadingTypeCpuEnum.IO_UNIVERSE]: '#a7f3d0', // Emerald 200
    [PerformanceReadingTypeCpuEnum.TOTAL_SYSTEM_CPU_LOAD]: '#fca5a5', // Red 300
  },
  [PerformanceReadingTypeEnum.MEMORY]: {
    [PerformanceReadingTypeMemoryEnum.PLUGIN_MEMORY_LOAD]: '#6ee7b7', // Emerald 300
    [PerformanceReadingTypeMemoryEnum.TOTAL_SYSTEM_MEMORY_LOAD]: '#fca5a5', // Red 300
  },
}

export const performanceReadingTypeMapper = {
  [PerformanceReadingTypeEnum.CPU]: 'CPU',
  [PerformanceReadingTypeEnum.MEMORY]: 'Memory',
}

export const PerformanceReadingTypeTotalLoadMapper = {
  [PerformanceReadingTypeEnum.CPU]: PerformanceReadingTypeCpuEnum.TOTAL_SYSTEM_CPU_LOAD,
  [PerformanceReadingTypeEnum.MEMORY]: PerformanceReadingTypeMemoryEnum.TOTAL_SYSTEM_MEMORY_LOAD,
}
