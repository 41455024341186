import { getAppNameFromTestCase } from '../../common/components/ApplicationConfigPage/dataHelpers'
import type {
  AppTestCaseGroup,
  WindowTestCaseGroup,
} from '../../common/components/ApplicationConfigPage/types'
import { type Application, applicationCategories } from '../../common/types/dataCollectionsConfigs'
import { WINDOW_TAG_KEY } from '../ConfigureDataCollectionAdvancedAdminPanelPage/constants'
import type { RuleIn } from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { ConfigTestCase, ConfigTestReport } from './types'

export const getRulesWithoutTestCases = (
  testCases: ConfigTestCase[],
  configFileReport: ConfigTestReport,
  allRules: RuleIn[],
): RuleIn[] => {
  const testCaseRelatedRuleIds = testCases.reduce<string[]>(
    (acc, testCase) => [...acc, ...(testCase.rule_id ?? [])],
    [],
  )
  const ruleIdsWithoutTestCases = configFileReport.unused_rules.filter(
    (ruleId) =>
      !testCaseRelatedRuleIds.includes(ruleId) && allRules.find((rule) => rule.id === ruleId),
  )

  return ruleIdsWithoutTestCases.map((ruleId) =>
    allRules.find((rule) => rule.id === ruleId),
  ) as RuleIn[]
}

// Failing test ids, non tested ids
export const getAppListFromTestCases = (
  testCases: ConfigTestCase[],
  appMetaData: Application[],
  rules: RuleIn[],
  configFileTestReport: ConfigTestReport | undefined | null,
): { appData: AppTestCaseGroup[]; rulesWithoutAppInfo: RuleIn[] } => {
  const testedTestCaseIds = new Set(
    (configFileTestReport?.applied_rules ?? []).map(({ test_id }) => test_id),
  )
  const failingTestCaseIds = new Set(configFileTestReport?.failing_tests ?? [])
  const testCaseIdToErrorMessagesMapper: { [key: string]: string[] } = (
    configFileTestReport?.case_reports ?? []
  )
    .filter(({ is_passed }) => !is_passed)
    .reduce(
      (mapper, data) => ({
        ...mapper,
        [data.test_id]: data.errors,
      }),
      {} as { [key: string]: string[] },
    )

  const groupDataMapper: { [key: string]: AppTestCaseGroup } = appMetaData.reduce(
    (mapper, application) => ({
      ...mapper,
      [application.name]: {
        appName: application.name,
        appDashboardConfigs: application,
        testCases: [],
        rules: [],
        processRules: [],
        windowLevelData: [],
        hasFailingTests: false,
        hasNotRunTests: false,
        hasNoDataCollectionRules: true,
      },
    }),
    {} as { [key: string]: AppTestCaseGroup },
  )

  // Associate Test cases under correct apps and windows
  associateTestCasesForAppsAndWindows(
    groupDataMapper,
    testCases,
    testedTestCaseIds,
    failingTestCaseIds,
    testCaseIdToErrorMessagesMapper,
  )

  // Associate rules under correct apps and windows
  const rulesWithoutAppInfo: RuleIn[] = associateRulesForAppsAndWindows(groupDataMapper, rules)

  return { appData: Object.values(groupDataMapper), rulesWithoutAppInfo }
}

const DEFAULT_APP_METADATA = {
  category: applicationCategories.other,
  is_pdf_application: false,
}

const associateTestCasesForAppsAndWindows = (
  groupDataMapper: { [key: string]: AppTestCaseGroup },
  testCases: ConfigTestCase[],
  testedTestCaseIds: Set<string>,
  failingTestCaseIds: Set<string>,
  testCaseIdToErrorMessagesMapper: { [key: string]: string[] },
) => {
  testCases.forEach((testCase: ConfigTestCase) => {
    // 1. If Dashboard context exists => Use it to assign the test case for the correct app and window.
    let appName: string | null | undefined
    let windowName: string | null | undefined

    if (testCase.dashboard_context && testCase.dashboard_context.app_name) {
      appName = testCase.dashboard_context.app_name
      windowName = testCase.dashboard_context.window_name
    } else {
      // 2. Get the Application and Window name from tags.
      const appNameFromTags = getAppNameFromTestCase(testCase)
      windowName = getWindowNameFromTestCase(testCase)

      // 3. If no app or window name from the tags => Add a test case under the “Other” application name
      appName = appNameFromTags ? appNameFromTags : 'Other'
    }

    appendTestCaseToAppTestCaseGroup(
      groupDataMapper,
      testCase,
      appName ?? 'Other',
      windowName,
      testedTestCaseIds,
      failingTestCaseIds,
      testCaseIdToErrorMessagesMapper,
    )
  })
}

const appendTestCaseToAppTestCaseGroup = (
  groupDataMapper: { [key: string]: AppTestCaseGroup },
  testCase: ConfigTestCase,
  appName: string,
  windowName: string | null | undefined,
  testedTestCaseIds: Set<string>,
  failingTestCaseIds: Set<string>,
  testCaseIdToErrorMessagesMapper: { [key: string]: string[] },
) => {
  const isFailing = failingTestCaseIds.has(testCase.test_id)
  const isNotTested = !testedTestCaseIds.has(testCase.test_id)
  const testCaseWithTestResult = {
    ...testCase,
    isFailing,
    isNotTested,
    errors: testCaseIdToErrorMessagesMapper[testCase.test_id],
  }

  if (!(appName in groupDataMapper)) {
    groupDataMapper[appName] = {
      appName: appName,
      appDashboardConfigs: { ...DEFAULT_APP_METADATA, name: appName },
      testCases: [],
      rules: [],
      processRules: [],
      windowLevelData: [],
      hasFailingTests: false,
      hasNotRunTests: false,
      hasNoDataCollectionRules: true,
    }
  }

  groupDataMapper[appName].hasFailingTests = isFailing || groupDataMapper[appName].hasFailingTests
  groupDataMapper[appName].hasNotRunTests = isNotTested || groupDataMapper[appName].hasNotRunTests

  // Add test case to application level
  if (!windowName) {
    groupDataMapper[appName].testCases.push(testCaseWithTestResult)
    return
  }

  // Add test case to window level
  // Check if window exists in the application already.
  const existingWindow = groupDataMapper[appName].windowLevelData.find(
    (windowData: WindowTestCaseGroup) => windowData.windowName === windowName,
  )

  // Update existing window or create new window.
  if (existingWindow) {
    existingWindow.testCases.push(testCaseWithTestResult)
  } else {
    groupDataMapper[appName].windowLevelData.push({
      windowName,
      testCases: [testCaseWithTestResult],
      rulesForTestCases: [],
      failingTestCaseIds: [],
    })
  }
}

const associateRulesForAppsAndWindows = (
  groupDataMapper: { [key: string]: AppTestCaseGroup },
  rules: RuleIn[],
): RuleIn[] => {
  const rulesWithoutAppInfo: RuleIn[] = []

  rules.forEach((rule: RuleIn) => {
    // 1. If Dashboard context exists => Use it to assign the rule for the correct app and window.
    if (rule.dashboard_context && rule.dashboard_context.app_name) {
      appendRuleToAppTestCaseGroup(groupDataMapper, rule)
    } else {
      rulesWithoutAppInfo.push(rule)
    }
  })

  return rulesWithoutAppInfo
}

const appendRuleToAppTestCaseGroup = (
  groupDataMapper: { [key: string]: AppTestCaseGroup },
  rule: RuleIn,
) => {
  const appName = rule.dashboard_context?.app_name ?? 'Other'
  const windowName = rule.dashboard_context?.window_name
  const processName = rule.dashboard_context?.process_name

  if (!(appName in groupDataMapper)) {
    groupDataMapper[appName] = {
      appName: appName,
      appDashboardConfigs: { ...DEFAULT_APP_METADATA, name: appName },
      testCases: [],
      rules: [],
      processRules: [],
      windowLevelData: [],
      hasFailingTests: false,
      hasNotRunTests: false,
      hasNoDataCollectionRules: false,
    }
  }

  // Add test case to application or process level
  if (!windowName) {
    processName
      ? groupDataMapper[appName].processRules.push(rule)
      : groupDataMapper[appName].rules.push(rule)
    groupDataMapper[appName].hasNoDataCollectionRules = false
    return
  }

  // Add test case to window level
  // Check if window exists in the application already.
  const existingWindow = groupDataMapper[appName].windowLevelData.find(
    (windowData: WindowTestCaseGroup) => windowData.windowName === windowName,
  )

  // Update existing window or create new window.
  if (existingWindow) {
    existingWindow.rulesForTestCases.push(rule)
  } else {
    groupDataMapper[appName].windowLevelData.push({
      windowName,
      testCases: [],
      rulesForTestCases: [rule],
      failingTestCaseIds: [],
    })
  }
}

const getWindowNameFromTestCase = (testCase: ConfigTestCase): string | null => {
  if (!testCase.expected_processed_event.tags) {
    return null
  }

  const windowTagValues = testCase.expected_processed_event.tags
    .filter((tag) => tag.key && tag.key === WINDOW_TAG_KEY)
    .map((tag) => tag.value)

  if (windowTagValues.length > 0) {
    return windowTagValues.join(' | ')
  }

  return null
}
