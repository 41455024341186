// **--------** //
// ** KEYS ** //
// **--------** //
export enum GlobalSearchParamEnum {
  TEAM_ID = 'teamId',
  TEAM_IDS = 'teamIds',
  TEAM_GROUP = 'teamGroup',
  TIME_PERIOD = 'timePeriod',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum SubSearchParamEnum {
  ACTIVE_VIEW = 'activeView',
  VISUALIZATION_MODE = 'visualizationMode',
  DETAIL_LEVEL = 'detailLevel',
  DATA_VARIANT = 'dataVariant',
  SHOWN_VARIANTS_STATES = 'shownVariantsStates',
  SHOWN_VARIANTS_STEPS = 'shownVariantsSteps',
  SHOWN_VARIANTS_WINDOWS = 'shownVariantsWindows',
  NODE_AMOUNT = 'nodeAmount',
  APP_LIST = 'appList',

  // Process specific.
  PROCESS_FILTER = 'processFilter',
  ZOOM_MODE = 'zoomMode',
  SELECTED_STEP = 'selectedStep',
  SELECTED_STATE = 'selectedState',
}

// **--------** //
// ** VALUES ** //
// **--------** //
export enum TimePeriodValueEnum {
  CURRENT_MONTH = 'current-m',
  CURRENT_WEEK = 'current-w',
  LAST_7_DAYS = 'last-7d',
  LAST_30_DAYS = 'last-30d',
  LAST_90_DAYS = 'last-90d',
  LAST_180_DAYS = 'last-180d',
}
