import { useEffect, useState } from 'react'

import { useGetInsightTypesQuery, useGetInsightsQuery } from '../../../../app/apiSlice'
import { InlineLoader } from '../../../../common/components'
import { Tabs, Text } from '../../../../common/designs'
import type { TabOption } from '../../../../common/designs'
import InsightCard from '../../../Insights/InsightCard'
import InsightModal from '../../../Insights/InsightsModal'

const InsightsSection = () => {
  const [tabOptions, setTabOptions] = useState<TabOption[]>([])
  const [tabSelected, setTabSelected] = useState<string | null>(null)

  const {
    data: insights,
    isLoading: isLoadingInsights,
    isFetching: isFetchingInsights,
  } = useGetInsightsQuery(tabSelected ? { types: [String(tabSelected)] } : undefined)
  const { data: insightTypes, isLoading: isLoadingInsightTypes } = useGetInsightTypesQuery()
  const [insightChosenForInspection, setInsightChosenForInspection] = useState<any>()

  useEffect(() => {
    if (!insightTypes) return setTabOptions([])

    setTabOptions(insightTypes.map(({ id, label }) => ({ label: label, value: id })))
  }, [insightTypes])

  useEffect(() => {
    if (tabOptions.length) setTabSelected(tabOptions[0].value as string)
  }, [tabOptions])

  const isLoading = isLoadingInsights || isLoadingInsightTypes
  const isInsightModalOpen = Boolean(insightChosenForInspection)

  return (
    <div className='my-4 flex w-full flex-col gap-2'>
      <div>
        <Text size='l' weight={600}>
          What do you want to focus on first?
        </Text>

        <Text size='s'>Select type of insight you wish to inspect.</Text>
      </div>
      {isLoading && <InlineLoader />}
      {!isLoading && tabOptions && insights && tabSelected && (
        <>
          <Tabs size='xs' options={tabOptions} value={tabSelected} onChange={setTabSelected} />

          {isFetchingInsights ? (
            <InlineLoader />
          ) : (
            <div className='grid grid-cols-2 gap-3 lg:grid-cols-3 2xl:grid-cols-4'>
              {insights.map((insight) => {
                const { id, title, types } = insight

                return (
                  <InsightCard
                    key={id}
                    title={title}
                    types={types}
                    onClick={() => setInsightChosenForInspection(insight)}
                  />
                )
              })}
            </div>
          )}
        </>
      )}

      {isInsightModalOpen && (
        <InsightModal
          open={isInsightModalOpen}
          onClose={() => setInsightChosenForInspection(null)}
          title={insightChosenForInspection.title}
          description={insightChosenForInspection.description}
          solution={insightChosenForInspection.solution}
          imageUrl={insightChosenForInspection.image_url}
          imageAlt={insightChosenForInspection.image_alt}
          href={insightChosenForInspection.href}
          types={insightChosenForInspection.types}
        />
      )}
    </div>
  )
}

export default InsightsSection
