export enum NodeTypeEnum {
  NORMAL = 'Normal',
  START = 'Start',
  END = 'End',
}

export enum VariantViewRoutesEnum {
  STEP_FLOWS = '',
  TEAM_FLOWS = 'team-flows',
  STATE_FLOWS = 'state-flows',
}

export enum VariantViewNamesEnum {
  STEP_FLOWS = 'Case flows',
  TEAM_FLOWS = 'Team flows',
  STATE_FLOWS = 'State flows',
}

