import { LinkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import type { Data as CsvData } from 'react-csv/lib/core'
import { useParams } from 'react-router-dom'

import {
  useGetProcessIdentifierMapperQuery,
  useGetRecommendationsQuery,
} from '../../../app/apiSlice'
import { DataDisplayTable, Loader, VisualizationContainer } from '../../../common/components'
import { Button, Select } from '../../../common/designs'
import { useCostUtils, useEnhancedNavigate } from '../../../common/hooks'
import type { PercentageValue } from '../../../common/types/common'
import { MainPathEnum, SubPathEnum } from '../../../common/types/routing'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { RecommendationCategoryEnum, RecommendationSortOptionsEnum } from '../enums'
import { getAnnualCostForRecommendation, getNameComponent } from '../recommendationFieldUtils'
import type { RecommendationData } from '../types'
import RecommendationModal from './RecommendationModal'
import { filterByRecommendationCategory, sortByOption, sortKeyMapper } from './dataModification'

const MAX_RENDERED_RECOMMENDATIONS = 50

const orderSelectData = [
  {
    label: RecommendationSortOptionsEnum.IMPROVEMENT_POTENTIAL,
    key: RecommendationSortOptionsEnum.IMPROVEMENT_POTENTIAL,
    value: RecommendationSortOptionsEnum.IMPROVEMENT_POTENTIAL,
  },
  {
    label: RecommendationSortOptionsEnum.IMPACT_SCORE,
    key: RecommendationSortOptionsEnum.IMPACT_SCORE,
    value: RecommendationSortOptionsEnum.IMPACT_SCORE,
  },
  {
    label: RecommendationSortOptionsEnum.THROUGHPUT_TIME,
    key: RecommendationSortOptionsEnum.THROUGHPUT_TIME,
    value: RecommendationSortOptionsEnum.THROUGHPUT_TIME,
  },
  {
    label: RecommendationSortOptionsEnum.EVENT_COUNT,
    key: RecommendationSortOptionsEnum.EVENT_COUNT,
    value: RecommendationSortOptionsEnum.EVENT_COUNT,
  },
]

const categorySelectData = [
  {
    label: RecommendationCategoryEnum.ALL,
    value: RecommendationCategoryEnum.ALL,
  },
  {
    label: RecommendationCategoryEnum.SYSTEM,
    value: RecommendationCategoryEnum.SYSTEM,
  },
  {
    label: RecommendationCategoryEnum.TASK,
    value: RecommendationCategoryEnum.TASK,
  },
  {
    label: RecommendationCategoryEnum.PROCESS,
    value: RecommendationCategoryEnum.PROCESS,
  },
]

const tableHeaderValues = [
  'Recommendation',
  'Issue',
  'Category',
  'Potential',
  'Work time',
  'Duration',
  'Events',
  'Impact score',
]

const Recommendations = () => {
  const { recommendationName } = useParams()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { currency } = useCostUtils()

  const [shownData, setShownData] = useState<RecommendationData[]>([])
  const [selectedSortOrder, setSelectedSortOrder] = useState<RecommendationSortOptionsEnum>(
    orderSelectData[0].value,
  )
  const [selectedCategory, setSelectedCategory] = useState<RecommendationCategoryEnum>(
    categorySelectData[0].value,
  )

  const [csvData, setCsvData] = useState<CsvData | null>(null)

  const { data, isLoading } = useGetRecommendationsQuery()
  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  useEffect(() => {
    if (!data) {
      setShownData([])
    } else {
      const filteredData = filterByRecommendationCategory(data, selectedCategory)
      const orderedData = sortByOption(filteredData, selectedSortOrder)
      setShownData(orderedData)
    }
  }, [data, selectedSortOrder, selectedCategory])

  const onClick = (_: string, dataIndex: number) =>
    navigate(shownData[dataIndex].recommendation_name)

  const maxValue = useMemo(() => {
    if (!shownData) return 1
    const key = sortKeyMapper[selectedSortOrder] as keyof RecommendationData
    return ((shownData[0]?.[key] as number) ?? 1) * 1.1
  }, [selectedSortOrder, shownData])

  const csvExportButton = useMemo(() => {
    return csvData
      ? [
          <CSVLink
            key='csv-export'
            data={csvData}
            filename={'pi-export.csv'}
            className={clsx(
              'inline-flex items-center border font-medium focus:outline-none focus:ring-1',
              'rounded-md border-gray-300 bg-white px-2.5 py-1 text-sm shadow-sm focus:ring-offset-2',
              'text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:ring-primary-500  disabled:cursor-not-allowed',
              'disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            )}
            target='_blank'
          >
            Export to CSV
          </CSVLink>,
        ]
      : []
  }, [csvData])

  const tableBody = useMemo(() => {
    return shownData.slice(0, MAX_RENDERED_RECOMMENDATIONS).map((recommendation) => {
      return [
        <Button
          variant='secondary'
          size='xs'
          iconEnd={<LinkIcon />}
          key={`${recommendation.recommendation_name}-${recommendation.throughput_time_sec}`}
        >
          Open insights
        </Button>,
        getNameComponent(recommendation, processMapper, true),
        recommendation.recommendation_category,
        `${formatNumber(
          getAnnualCostForRecommendation(
            recommendation.business_case_time_sec / 60 / 60,
            currency.costPerHour,
          ),
          1,
        )} ${currency.symbol}`,
        getBestTimeFormatForSeconds(recommendation.business_case_time_sec),
        getBestTimeFormatForSeconds(recommendation.throughput_time_sec),
        formatNumber(recommendation.business_case_event_count, 1),
        formatNumber(recommendation.impact_score, 1),
      ]
    })
  }, [shownData, processMapper, currency.costPerHour, currency.symbol])

  const tableVisualizationValues = useMemo(() => {
    return shownData.slice(0, MAX_RENDERED_RECOMMENDATIONS).map((recommendation) => {
      const key = sortKeyMapper[selectedSortOrder] as keyof RecommendationData

      return Math.round(((recommendation[key] as number) / maxValue) * 100) as PercentageValue
    })
  }, [shownData, maxValue, selectedSortOrder])

  const setCsvExportData = useCallback((data: CsvData) => setCsvData(data), [setCsvData])

  if (isLoading) return <Loader />

  return (
    <>
      <VisualizationContainer
        label='Recommendations'
        selectActions={[
          <Select
            key='selectSortOrder'
            options={orderSelectData}
            value={selectedSortOrder}
            onChange={setSelectedSortOrder as React.Dispatch<React.SetStateAction<string>>}
            size='xs'
          />,
          <Select
            key='selectCategory'
            options={categorySelectData}
            value={selectedCategory}
            onChange={setSelectedCategory as React.Dispatch<React.SetStateAction<string>>}
            size='xs'
          />,
        ]}
        otherActions={[...csvExportButton]}
      >
        <DataDisplayTable
          head={tableHeaderValues}
          body={tableBody}
          visualizationValues={tableVisualizationValues}
          onClick={onClick}
          setExportedData={setCsvExportData}
        />
      </VisualizationContainer>

      <RecommendationModal
        recommendationName={recommendationName}
        onClose={() => {
          navigate(`/${MainPathEnum.START}/${SubPathEnum.RECOMMENDATIONS}`)
        }}
        recommendations={data}
      />
    </>
  )
}

export default Recommendations

