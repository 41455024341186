import { TaskFlowChart } from '../../common/components'
import { Text } from '../../common/designs'
import TaskListActivityDistribution from './TaskListActivityDistribution'
import type { ActivityCategorization, ItemTaskCountData, TopVariantData } from './types'

interface Props {
  activityData: ActivityCategorization[]
  variants: TopVariantData[]
  dataTransafers: ItemTaskCountData[]
}

const TaskListItemExtraContent = ({ activityData, variants, dataTransafers }: Props) => {
  return (
    <div className='mt-2'>
      <Text weight={600}>Task activities</Text>
      <div className='w-full'>
        <TaskListActivityDistribution activityData={activityData} />
      </div>

      <Text weight={600}>Top task flows</Text>
      {variants.map((flow) => (
        <div className='mb-1' key={JSON.stringify(flow)}>
          <Text size='s' weight={500}>
            Task count: {flow.task_count}
          </Text>
          <TaskFlowChart
            data={flow.window_steps.map((window) => ({
              durationSec: window.avg_duration_sec,
              label: window.window_name,
              colorClass: 'bg-indigo-300',
            }))}
            barHeight='h-4'
          />
        </div>
      ))}

      <Text weight={600}>Top copy-pastes</Text>
      {dataTransafers.map((transfer) => (
        <div className='mb-1' key={JSON.stringify(transfer)}>
          <Text size='s' weight={500}>
            {transfer.name} ({transfer.task_count})
          </Text>
        </div>
      ))}
    </div>
  )
}

export default TaskListItemExtraContent
