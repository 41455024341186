import { ChartOptions } from 'chart.js'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { Loader } from '../../common/components'
import { Text } from '../../common/designs'
import { ChartData } from '../../common/types/common'
import { capitalizeFirstLetter } from '../../common/utils/stringFormatting'
import { generateIncrementalIndexesArrayUntil } from '../../common/utils/utils'
import {
  performanceReadingTypeColors,
  performanceReadingTypeMapper,
  usageDistributionsAsArray,
} from './constants'
import type {
  PerformanceReadingCpu,
  PerformanceReadingMemory,
  PerformanceReadingTypeEnum,
} from './types'

interface Props {
  unit: PerformanceReadingTypeEnum
  data: PerformanceReadingMemory[] | PerformanceReadingCpu[] | undefined
  isLoading: boolean
}

const UsageHistogram = ({ unit, data, isLoading }: Props) => {
  const [chartData, setChartData] = useState<null | ChartData>(null)

  useEffect(() => {
    if (!data) return

    const distributions = _.cloneDeep(usageDistributionsAsArray[unit])
    const colors = performanceReadingTypeColors[unit]

    // Populate the distributions with the data
    data.forEach((item) => {
      const type = item.reading
      const index = Math.round(item.value) // Index is rounded to cloesest percentage
      // Increment the count for the corresponding type and value index
      distributions[type as keyof typeof distributions][index]++
    })

    // Map the distributions to chart datasets
    const datasets = Object.entries(distributions).map(([key, value]) => ({
      label: key,
      data: value,
      backgroundColor: colors[key as keyof typeof colors],
    }))

    // Set the chart data using the datasets
    setChartData({
      labels: generateIncrementalIndexesArrayUntil(101).map((label) => `${label}%`),
      datasets,
    })
  }, [unit, data])

  const options: ChartOptions<'bar'> = {
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  }

  if (!chartData || isLoading) return <Loader />

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <Text>{capitalizeFirstLetter(performanceReadingTypeMapper[unit])} Usage Histogram</Text>

      <Bar data={chartData} options={options} height='90px' />
    </div>
  )
}

export default UsageHistogram
