import type { ChartDataEntry } from '../../common/components/Charts/types'
import { PaginationOptions } from '../../common/types/api'
import type { TagFilter } from '../../common/types/common'
import type { SimpleDate } from '../../common/types/datetime'
import { DetailLevelEnum } from '../Network/types'
import type { TagFilteringLogicEnum } from './ProcessMining/Analysis/types'
import type {
  FilterDimemsionOptionData,
  FilterUserActionOptionData,
} from './ProcessMining/Filters/types'
import type { StateVariantData, StepVariantData } from './ProcessMining/Variants/types'
import { ProcessProperties } from './ProcessMining/types'
import type { OrderByDirectionEnum } from './constants'

export interface State {
  dimensionFilterOptions: FilterDimemsionOptionData[]
  userActionFilterOptions: FilterUserActionOptionData | null
  initialVisibleVariantAmount: number
  variantOrder: VariantOrder
  highlightSelections: HighlightSelections
  variantsLayoutData: {
    variantsInsightBarItems: Array<{ label: string; value: string | number }>
    viewDescriptionText: string
  }
  stepVariantData: StepVariantData[]
  stateVariantData: StateVariantData[]
  processProperties: ProcessProperties | null
}

export interface ProcessGeneralInfo {
  id: string
  name: string
  process_identifier_name: string
  process_category: string | undefined
  initial_visible_variant_amount: number
  state_tag_key: string
  active_filter_id: string | null
}

export type NewProcessGeneralInfo = Pick<
  ProcessGeneralInfo,
  | 'name'
  | 'process_identifier_name'
  | 'initial_visible_variant_amount'
  | 'process_category'
  | 'state_tag_key'
>

export interface ProcessMetricFilters {
  min_start_date?: SimpleDate
  max_start_date?: SimpleDate
  min_end_date?: SimpleDate
  max_end_date?: SimpleDate
  team_ids: string[] // ! Renamed from "team_ids_filters"
  // team_id_filters_match_all?: string[] // TODO Not yet implemented
  min_active_work_sec?: number
  max_active_work_sec?: number
  min_duration_sec?: number
  max_duration_sec?: number
}

export type ProcessPropertyFiltersKeys = keyof ProcessPropertyFilters
export interface ProcessPropertyFilters {
  transaction_id_filters?: string[]
  window_step_variant_filters?: Array<string[]>

  team_count_filters?: number[]
  team_list_filters?: Array<string[]>

  step_name_filters?: string[]
  step_name_filters_match_all?: string[]
  // step_name_filters_match_none?: string[] // TODO Not yet implemented
  start_step_name_filters?: string[]
  end_step_name_filters?: string[]

  step_count_filters?: number[]
  touch_count_filters?: number[]
  self_loop_count_filters?: number[]
}

export interface BusinessPropertyFilters {
  tag_filters?: TagFilter[]
  tag_filters_match_all?: TagFilter[]
}

export type SupportingSystemFiltersKeys = keyof SupportingSystemFilters
export interface SupportingSystemFilters {
  process_application_filters?: string[]
  process_application_filters_match_all?: string[]

  related_window_filters?: string[]
  related_window_filters_match_all?: string[]
}

export interface TaskFilterData {
  min_value?: number
  max_value?: number
}

export interface InteractionLogFilterData extends TaskFilterData {
  value_filter?: string
}

export interface UserInteractionFilters {
  copy_count_filter?: TaskFilterData
  paste_count_filter?: TaskFilterData
  keyboard_press_count_filter?: TaskFilterData
  mouse_click_count_filter?: TaskFilterData

  click_element_filter?: InteractionLogFilterData
  data_input_element_filter?: InteractionLogFilterData
  data_input_content_filter?: InteractionLogFilterData
}

export interface ZoomingFilters {
  selected_step_variants?: Array<string[]> | null
  step_name?: string | null
  step_index?: number | null
  selected_state_variants?: Array<string[]> | null
  state_name?: string | null
}

export type TransactionFilters = ProcessPropertyFilters &
  BusinessPropertyFilters &
  SupportingSystemFilters &
  UserInteractionFilters

export type TransactionFiltersWithZooming = TransactionFilters & {
  zooming_filters?: ZoomingFilters
}

export interface VariantOrder {
  variable: OrderByVariableValue
  direction: OrderByDirectionEnum
}

export interface InsightQueryOptions {
  filters: TransactionFilters
  processId: string
}

export interface ServerInsightsData {
  insight_name: string
  step_name: string
  step_work_type: string
  leadtime_improvement_sec: number
  worktime_improvement_sec: number
  annual_worktime_improvement_sec: number
  leadtime_avg_improvement_sec: number
}

export type Activity = string

export enum ConformanceTypeEnum {
  STEP_FLOW = 'STEP_FLOW',
  STATE_FLOW = 'STATE_FLOW',
}

export interface GetConformanceFlowsParams {
  processId: string
  conformanceType: ConformanceTypeEnum
}

export interface ConformanceFlowsData {
  steps: Activity[]
  conformance_type: ConformanceTypeEnum
  conformance_name: string
  attachment?: string
  attachment_name: string
  id: string
}

export interface ConformanceModalData {
  steps: string[]
  conformance_name: string
}

interface ConformanceFlowBody {
  steps: Activity[]
  conformance_type: ConformanceTypeEnum
  conformance_name: string
}

export interface CreateConformanceFlowParams {
  body: ConformanceFlowBody
  processId: string
}
export interface EditConformanceFlowParams {
  body: ConformanceFlowBody
  processId: string
  conformanceId: string
}

export interface UploadAttachmentConformanceFlowParams {
  body: FormData
  processId: string
  conformanceId: string
}

export interface DeleteConformanceFlowParams {
  processId: string
  conformanceId: string
}

export enum HighlightFieldEnum {
  TRANSACTIONS = 'Case amount',
  ACTIVE_WORK = 'Active work',
}

export interface HighlightSelections {
  activeHighlighField: HighlightFieldEnum
  isTeamDistributionOpen: boolean
  isWorkTypeDistributionOpen: boolean
  isAutomationInsightsOpen: boolean
  isUnwindModeActive: boolean
}

export enum StepVariantOrderByEnum {
  TRANSACTION_AMOUNT = 'Transaction amount',
  STEP_AMOUNT = 'Tasks amount',
  THROUGHPUT_TIME = 'Duration per case',
  ACTIVE_WORK = 'Active work per case',
  TOUCH_AMOUNT = 'Touches per case',
}

export enum StateVariantOrderByEnum {
  TRANSACTION_AMOUNT = 'Transaction amount',
  STEP_AMOUNT = 'Tasks amount',
  THROUGHPUT_TIME = 'Duration per transaction',
}

export type OrderByVariableValue = StepVariantOrderByEnum | StateVariantOrderByEnum

export enum MetricNamesEnum {
  TOTAL_TRANSACTIONS = 'total_transactions',
  THROUGHPUT_TIME = 'throughput_time_sec',
  AVG_THROUGHPUT_TIME = 'avg_throughput_time_sec',
  AVG_TEAM_COUNT = 'avg_team_count',
  AVG_STEP_AMOUNT = 'avg_step_amount',
  AVG_SELF_LOOPS = 'avg_self_loop_count',

  ACTIVE_WORK = 'active_work_sec',
  AVG_ACTIVE_WORK = 'avg_active_work_sec',
  TOUCH_COUNT = 'touch_count',
  AVG_TOUCH_COUNT = 'avg_touch_count',
  COPY_COUNT = 'copy_count',
  AVG_COPY_COUNT = 'avg_copy_count',
  PASTE_COUNT = 'paste_count',
  AVG_PASTE_COUNT = 'avg_paste_count',
  KEYBOARD_PRESS_COUNT = 'keyboard_press_count',
  AVG_KEYBOARD_PRESS_COUNT = 'avg_keyboard_press_count',
  MOUSE_CLICK_COUNT = 'mouse_click_count',
  AVG_MOUSE_CLICK_COUNT = 'avg_mouse_click_count',

  RELATED_SYSTEM_TIME = 'related_system_time',
  RELATED_SYSTEM_VISITS = 'related_system_visits',
}

export enum MetricTypeEnum {
  TRANSACTION_SPECIFIC = 'TRANSACTION_SPECIFIC',
  ACTIVE_WORK_SPECIFIC = 'ACTIVE_WORK_SPECIFIC',
  RELATED_SYSTEM_SPECIFIC = 'RELATED_SYSTEM_SPECIFIC',
}

export enum GroupByDimensionTypeEnum {
  TRANSACTION_SPECIFIC = 'TRANSACTION_SPECIFIC',
  STEP_SPECIFIC = 'STEP_SPECIFIC',
  RELATED_SYSTEM_SPECIFIC = 'RELATED_SYSTEM_SPECIFIC',
}

export enum GroupByFieldTypeEnum {
  TRANSACTION_ID = 'transaction_id',
  TEAM_COUNT = 'team_count',
  TEAM_IDS = 'team_ids',
  WINDOW_STEP_VARIANT = 'window_step_variant',
  STEP_AMOUNT = 'step_amount',
  TOUCH_COUNT = 'touch_count',
  SELF_LOOP_COUNT = 'self_loop_count',
  START_DATE = 'start_date',
  END_DATE = 'end_date',

  TEAM_ID = 'team_id',
  RELATED_WINDOW_NAMES = 'related_window_names',
  PROCESS_APPLICATION_NAMES = 'process_application_names',
  STEP_NAMES = 'step_names',
  STEP_WORK_TYPE = 'step_work_type',
  TAG_NAME = 'tag_name',
}

export interface GroupByField {
  field_type: GroupByFieldTypeEnum
  tag_name?: string
  tag_filtering_logic?: TagFilteringLogicEnum | undefined
}

export interface ProcessIdQueryParamsBase {
  processId: string
}
export interface MetricQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
  metrics: MetricNamesEnum[]
}

export interface GroupQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
  group_by: GroupByField[]
  metrics: MetricNamesEnum[]
}
export interface GroupQueryMultipleParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
  group_by: GroupByField[]
  metrics: MetricNamesEnum[]
}

export type MetricValue = number

export type TransactionMetricResult = {
  [key in MetricNamesEnum]: MetricValue
}

export interface GroupResultData {
  label: string | string[]
  value: MetricValue
}

export type TransactionGroupResult = {
  [key in MetricNamesEnum]: GroupResultData[]
}

export interface LabelData {
  label_type: GroupByFieldTypeEnum
  label_value: string | string[]
}

export interface MultipleLabelsGroupResult {
  value: MetricValue
  labels: LabelData[]
}

export type TransactionGroupResultMultiple = {
  [key in MetricNamesEnum]: MultipleLabelsGroupResult[]
}

export interface TrendQueryParams {
  filters: TransactionFilters
  processId: string
  trend_granularity: TrendTimeGranularityEnum
}

export interface TrendData {
  dayNumber: number | null
  monthNumber: number | null
  year: number | null

  weekNumber: number | null
  weekYear: number | null

  total_transactions: number
  total_duration_sec: number
  avg_duration_sec: number
  total_touch_count: number
  avg_touch_count: number

  started_transactions: number
  closed_transactions: number
  started_avg_throughput_time_sec: number
  started_median_throughput_time_sec: number
  ended_avg_throughput_time_sec: number
  ended_median_throughput_time_sec: number
  ended_standard_deviation_throughput_time_sec: number
}

export interface ActiveWorkTrendData {
  isoWeekNumber: number
  year: number
  total_duration_sec: number
}

export enum TrendVariableEnum {
  totalTransactions = 'total_transactions',
  totalDuration = 'total_duration_sec',
  avgDuration = 'avg_duration_sec',
  totalTouches = 'total_touch_count',
  avgTouches = 'avg_touch_count',
  startedTransaction = 'started_transactions',
  closedTransactions = 'closed_transactions',

  startedTransactionAvgDuration = 'started_avg_throughput_time_sec',
  startedTransactionMedianDuration = 'started_median_throughput_time_sec',
  endedTransactionAvgDuration = 'ended_avg_throughput_time_sec',
  endedTransactionMedianDuration = 'ended_median_throughput_time_sec',
  endedTransactionDurationStandardDeviation = 'ended_standard_deviation_throughput_time_sec',
}

export enum TrendTimeGranularityEnum {
  DATE = 'DATE',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface GroupBucketQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
  metric: MetricNamesEnum.THROUGHPUT_TIME | MetricNamesEnum.ACTIVE_WORK
}

export interface TransactionSearchQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
  transaction_search_string: string
}

export interface ConformanceQueryOptions {
  filters: TransactionFilters
  conformance_type: ConformanceTypeEnum
  processId: string
}

export interface ConformanceMetrics {
  conformance_percentage: number

  avg_throughput_time_sec: number
  throughput_diff_to_others_sec: number

  avg_touch_count: number
  avg_touch_count_diff_to_others: number
  annualized_touch_count_diff: number

  avg_duration_sec: number
  duration_diff_to_others_sec: number
  annualized_duration_diff_sec: number
}

export interface ConformanceTrendData {
  iso_week_number: number
  year: number
  metrics: ConformanceMetrics
}

export interface ConformanceByVariantMetrics {
  variant: string[]
  total_transactions: number
  avg_throughput_time_sec: number
  avg_touch_count: number
  avg_duration_sec: number
}

export interface AppUsageTrendData {
  isoWeekNumber: number
  year: number
  total_duration_sec: number
}

export interface AnalysisChartConfigurationData {
  label: string
  chartData: ChartDataEntry[]
  unit: string
  onBarClick?: (label: string, i: number) => void
  description?: string
}

export interface AnalysisGroupByExtraData {
  group_by_dimension_type: GroupByDimensionTypeEnum
  dimension_name: string
  is_tag_dimension: boolean
  filter_key: string
  tag_filtering_logic?: TagFilteringLogicEnum
}

export interface DimensionExtraDataMapper {
  [key: string]: AnalysisGroupByExtraData
}

export interface StateNameQueryParams extends ProcessIdQueryParamsBase {
  filters: TransactionFilters
}

export enum VariableLabelEnum {
  APPLICATION = 'Application',
  WINDOW = 'Window',
  FIELD_NAME = 'Field Name',
  SOURCE_WINDOW = 'Source Window',
  TARGET_WINDOW = 'Target Window',
  SOURCE_FIELD = 'Source Field',
  TARGET_FIELD = 'Target Field',
  CONTENT = 'Content',
}

interface VariableData {
  label: VariableLabelEnum
  value: string
}

export interface UserActivitiesData {
  value: number
  variables: VariableData[]
}

export enum UserActionTypeEnum {
  CLICK_ACTION = 'click_action',
  FIELD_EDIT = 'field_edit',
}

export interface CopyPasteAnalysisQueryOptions {
  filters: TransactionFilters
  processId: string
}
export interface UserActionQueryOptions {
  filters: TransactionFilters
  processId: string
  action_type: UserActionTypeEnum
}

export interface ProcessDataflowsQueryOptions {
  filters: TransactionFilters
  node_amount: number
  processId: string
  detail_level: DetailLevelEnum
}

export interface TransactionLogData {
  case_id: string
  start_date: Date
  end_date: Date
  duration_in_seconds: number
}

export interface TransactionLogQueryOptions {
  processId: string
  filters: TransactionFilters
  pagination_options?: PaginationOptions
}

export interface NormalDistributionPoint {
  x_sec: number
  y_percentage: number
}

export interface NormalDistributionData {
  avg_sec: number
  std_dev_sec: number
  normal_distribution_points: NormalDistributionPoint[]
}

export interface NormalDistributionQueryOptions {
  processId: string
  filters: TransactionFilters
}
