import { Popover as HPopover } from '@headlessui/react'
import clsx from 'clsx'

import { DefaultTransistion } from './HelperComponents'

const originStyle: { [key in Origin]: string } = {
  l: 'origin-top-left left-0',
  r: 'origin-top-right right-0',
}

type Origin = 'l' | 'r'

interface Props {
  action: React.ReactNode
  children: React.ReactNode

  actionAs?: 'button' | 'div'
  origin?: Origin
  btnClassName?: string
  itemsClassName?: string
  className?: string
  disabled?: boolean
}

const Popover = (props: Props) => {
  const {
    action,
    actionAs = 'button',
    children,
    origin = 'l',
    btnClassName,
    itemsClassName,
    className,
    disabled,
  } = props

  return (
    <HPopover as='div' className={clsx('relative inline-block', className)}>
      <HPopover.Button as={actionAs} className={btnClassName} disabled={Boolean(disabled)}>
        {action}
      </HPopover.Button>

      <DefaultTransistion>
        <HPopover.Panel
          className={clsx(
            'absolute z-20 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
            originStyle[origin],
            itemsClassName,
          )}
        >
          {children}
        </HPopover.Panel>
      </DefaultTransistion>
    </HPopover>
  )
}

export default Popover
