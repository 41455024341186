import { useMemo, useState } from 'react'

import {
  useGetComputerCountDataQuery,
  useGetPredefinedTaskAppUsageQuery,
  useGetPredefinedTaskMetricsQuery,
  useGetPredefinedTaskSessionOrderingQuery,
} from '../../app/apiSlice'
import {
  ContentDisplay,
  HorizontalBar,
  InlineLoader,
  Stats,
  VariableCombinationBarChart,
} from '../../common/components'
import { Button, InputField, Text } from '../../common/designs'
import { useFilteredWindowUsageQuery, useQueryFilters } from '../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../common/utils/numberFormatting'
import type { WindowUsageData } from '../BusinessApps/types'

const PredefinedTasks = () => {
  const { min_date, max_date, min_date_iso, max_date_iso, team_ids } = useQueryFilters()
  const [minTaskOrderTasks, setMinTaskOrderTasks] = useState<number>(2)
  const [shownTaskOrderResults, setShownTaskOrderResults] = useState<number>(10)

  const { data: metricData, isLoading: isLoadingMetricData } = useGetPredefinedTaskMetricsQuery({
    filters: { min_date, max_date, team_ids },
  })
  const { data: taskAppUsageData, isLoading: isLoadingTaskAppUsageData } =
    useGetPredefinedTaskAppUsageQuery({
      filters: { min_date, max_date, team_ids },
    })
  const { data: sessionOrderingData, isLoading: isLoadingSessionOrderingData } =
    useGetPredefinedTaskSessionOrderingQuery({
      filters: { min_date, max_date, team_ids },
    })

  const { data: dailyComputerCountSum, isLoading: isLoadingDailyComputerCountSum } =
    useGetComputerCountDataQuery({
      team_ids,
      start_timestamp: min_date_iso,
      end_timestamp: max_date_iso,
    })
  const { data: totalAppUsageData, isLoading: isLoadingTotalAppUsageData } =
    useFilteredWindowUsageQuery({ tagsOfInterestSource: 'application' })

  const taskMetricStats = useMemo(() => {
    if (!metricData || dailyComputerCountSum === undefined) return []

    return [
      {
        label: 'Total time spent in predefined tasks',
        value: `${formatNumber(metricData.total_task_time_sec / 3600, 10)} h`,
      },
      {
        label: 'Total predefined tasks',
        value: `${formatNumber(metricData.total_task_count)}`,
      },
      {
        label: 'Time per predefined task',
        value: `${getBestTimeFormatForSeconds(
          metricData.total_task_time_sec / metricData.total_task_count,
        )}`,
      },
      {
        label: 'Daily tasks per computer',
        value: `${
          dailyComputerCountSum
            ? formatNumber(metricData.total_task_count / dailyComputerCountSum)
            : 0
        }`,
      },
    ]
  }, [metricData, dailyComputerCountSum])

  const timeUsageSplit = useMemo(() => {
    if (!totalAppUsageData || !metricData) return []
    const totalTimeH =
      (totalAppUsageData as WindowUsageData[]).reduce((sum: number, val) => sum + val.time_h, 0) ??
      0

    const taskTimeH = metricData.total_task_time_sec / 3600

    return [
      {
        label: 'Predefined tasks',
        value: taskTimeH,
      },
      {
        label: 'Other work',
        value: totalTimeH - taskTimeH,
      },
    ]
  }, [metricData, totalAppUsageData])

  const { taskAppSplit, restAppSplit } = useMemo(() => {
    if (!totalAppUsageData || !taskAppUsageData) return { taskAppSplit: [], restAppSplit: [] }

    const appTimeMapper: { [key: string]: number } = {}
    const taskAppSplit = taskAppUsageData.map((app) => {
      appTimeMapper[app.app_name] = app.total_task_time_sec / 3600
      return {
        label: app.app_name,
        value: app.total_task_time_sec / 3600,
      }
    })

    const restAppSplit = (totalAppUsageData as WindowUsageData[]).map((app) => {
      return {
        label: app.name,
        value: app.time_h - (appTimeMapper[app.name] ?? 0),
      }
    })

    return { taskAppSplit, restAppSplit }
  }, [taskAppUsageData, totalAppUsageData])

  const sessionTaskOrderData = useMemo(() => {
    if (!sessionOrderingData) return []
    return sessionOrderingData.map((session) => ({
      variables: session.task_names.map((name) => ({
        label: '',
        value: name,
      })),
      value: session.total_count,
    }))
  }, [sessionOrderingData])

  return (
    <div className='space-y-6 px-2 pt-4'>
      <div className='w-full'>
        <Text size='l' weight={600} className='mb-2'>
          Predefined task Metrics
        </Text>

        <Stats
          data={taskMetricStats}
          isLoading={isLoadingMetricData || isLoadingDailyComputerCountSum}
        />
      </div>

      <div className='w-full'>
        <Text size='l' weight={600} className='mb-2'>
          Time in predefined tasks vs. rest of work time
        </Text>
        <ContentDisplay className='w-full space-y-4 px-4 py-3'>
          <div>
            <Text size='l' weight={600} className='mb-2'>
              Task time
            </Text>
            <HorizontalBar
              data={timeUsageSplit}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isLoadingMetricData || isLoadingTotalAppUsageData}
            />
          </div>

          <div>
            <Text size='l' weight={600} className='mb-2'>
              App split in predefined tasks
            </Text>
            <HorizontalBar
              data={taskAppSplit}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isLoadingTaskAppUsageData || isLoadingTotalAppUsageData}
            />
          </div>
          <div>
            <Text size='l' weight={600} className='mb-2'>
              App split in other work
            </Text>
            <HorizontalBar
              data={restAppSplit}
              unit='hours'
              maxVisualizedValues={4}
              showLabels
              isLoading={isLoadingTaskAppUsageData || isLoadingTotalAppUsageData}
            />
          </div>
        </ContentDisplay>
      </div>
      {isLoadingSessionOrderingData ? (
        <InlineLoader />
      ) : (
        <div className='w-full'>
          <Text size='l' weight={600} className='mb-2'>
            Task execution order in a user session
          </Text>
          <InputField
            className='mb-2 w-36'
            label='Min tasks in session'
            type='number'
            value={minTaskOrderTasks}
            onChange={(e) => setMinTaskOrderTasks(parseInt(e.target.value))}
            min={1}
            max={20}
            size='s'
          />
          <VariableCombinationBarChart
            data={sessionTaskOrderData
              .filter((s) => s.variables.length >= minTaskOrderTasks)
              .slice(0, shownTaskOrderResults)}
          />
          {sessionTaskOrderData.length > shownTaskOrderResults && (
            <Button
              variant='secondary'
              wFull
              onClick={() => setShownTaskOrderResults((val) => val + 10)}
            >
              Show more
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default PredefinedTasks
