import { PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import {
  useCreateCollaboratorMutation,
  useDeleteCollaboratorMutation,
} from '../../../../../app/apiSlice'
import { Avatar, Button, IconButton, Modal, Text, Toggle } from '../../../../../common/designs'
import { useAppDispatch, useAppSelector, useDashboard } from '../../../../../common/hooks'
import { createInitials } from '../../../../../common/utils/stringFormatting'
import { addCollaborator, removeCollaborator, selectUsers } from '../../../casesSlice'
import type { Collaborator } from '../../../types'

interface Props {
  caseId: number
  collaborators: Collaborator[]
}

const Collaborators = ({ caseId, collaborators }: Props) => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()
  const users = useAppSelector(selectUsers)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [toggleMap, setToggleMap] = useState<
    Array<{ label: string; value: number; isActive: boolean }>
  >(
    users.map((u) => ({
      label: u.username,
      value: u.id,
      isActive: collaborators.some((c) => c.user_id === u.id),
    })),
  )

  const [createCollaborator] = useCreateCollaboratorMutation()
  const [deleteCollaborator] = useDeleteCollaboratorMutation()

  const renderCollaboratorPlaceholders = () => {
    const components = []

    for (let i = collaborators.length; i < 5; i++)
      components.push(<Avatar size='s' rounded placeholder key={i} />)

    return components
  }

  return (
    <div className='my-4 flex items-center gap-1'>
      <Text size='s' weight={500} className='mr-2'>
        Collaborators
      </Text>

      {collaborators.map((c) => {
        const user = users.find((u) => u.id === c.user_id)

        if (!user) return null

        return (
          <Avatar size='s' variant={user.color_index} rounded key={user.username}>
            {createInitials(user.username)}
          </Avatar>
        )
      })}

      {renderCollaboratorPlaceholders()}

      {!user.isDataReader && (
        <IconButton
          variant='ghost'
          size='s'
          icon={<PlusIcon />}
          onClick={() => setIsModalOpen(true)}
          rounded
        />
      )}

      <Modal label='Collaborators' open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='space-y-1'>
          {users.map((u) => {
            const toggleUser = toggleMap.find((o) => o.value === u.id)
            if (!toggleUser) return null

            const onChange = () => {
              const newIsActiveState = !toggleUser.isActive

              const updatedToggleMap = toggleMap.map((o) => {
                if (o.value !== toggleUser.value) return o

                return { ...toggleUser, isActive: newIsActiveState }
              })
              setToggleMap(updatedToggleMap)

              const userCollaborator = collaborators.find((c) => c.user_id === toggleUser.value)
              const userId = toggleUser.value

              if (newIsActiveState) {
                createCollaborator({ caseId, body: { userId } })
                  .unwrap()
                  .then((collaborator) => dispatch(addCollaborator({ caseId, collaborator })))
              } else {
                if (!userCollaborator) return
                deleteCollaborator({ caseId, collaboratorId: userCollaborator.id }).then(() => {
                  dispatch(removeCollaborator({ caseId, collaboratorId: userCollaborator.id }))
                })
              }
            }

            return (
              <Toggle
                label={toggleUser.label}
                value={toggleUser.isActive}
                onChange={onChange}
                key={toggleUser.label}
              />
            )
          })}
        </div>

        <div className='flex justify-end'>
          <Button onClick={() => setIsModalOpen(false)}>Back to case</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Collaborators
