import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMemo } from 'react'

import { Badge, Button, ProgressBar, Text } from '../../common/designs'
import type { Variant as BadgeVariant } from '../../common/designs/Badge'
import { useEnhancedNavigate } from '../../common/hooks'
import { PercentageValue } from '../../common/types/common'
import { MainPathEnum, SubPathEnum } from '../../common/types/routing'
import { formatNumber } from '../../common/utils/numberFormatting'
import type { ProcessIdentifierMapper } from '../Overview/types'
import TaskListAppDistribution from './TaskListAppDistribution'
import TaskListTeamDistribution from './TaskListTeamDistribution'
import type { TaskDiscoveryData } from './types'
import { TaskTypeEnum } from './types'

interface Props {
  task: TaskDiscoveryData
  progressBarSize: PercentageValue
  processMapper: ProcessIdentifierMapper | undefined
  isAccordionOpen: boolean
  setIsAccordionOpen: () => void
}

const TaskListItem = ({
  task,
  progressBarSize,
  processMapper,
  isAccordionOpen,
  setIsAccordionOpen,
}: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const { badgeText, badgeColor } = useMemo(() => {
    let badgeText, badgeColor
    switch (task.task_type) {
      case TaskTypeEnum.PREDEFINED_TASK:
        badgeText = 'Predefined task'
        badgeColor = 'green' as BadgeVariant
        break

      case TaskTypeEnum.WORK_FLOW_TASK:
        badgeText = 'Work flow task'
        badgeColor = 'blue' as BadgeVariant
        break

      default:
        badgeText = 'Repetitive task'
        badgeColor = 'orange' as BadgeVariant
        break
    }

    return { badgeText, badgeColor }
  }, [task])

  return (
    <div>
      <div className='mb-2 flex justify-between'>
        <Text size='xl' weight={600}>
          {task.task_name}
        </Text>
        <Badge variant={badgeColor} rounded>
          {badgeText}
        </Badge>
      </div>

      <div className='mb-4 grid w-full grid-cols-4 items-center'>
        <div className='w-52 items-center'>
          <Text size='s'>
            {Math.round(task.duration_sec / 3600)} h - Total executions:{' '}
            {formatNumber(task.task_count)}
          </Text>
          <ProgressBar size='l' value={progressBarSize} rounded />
        </div>
        <div>
          <Text size='s'>Teams</Text>
          <TaskListTeamDistribution teamData={task.team_data} />
        </div>

        <div>
          <Text size='s'>Apps</Text>
          <TaskListAppDistribution appData={task.app_usage_data} />
        </div>
        <p className='mt-2 text-end text-sm text-gray-500'>
          {task.process_data
            .filter((p) => processMapper && p.name in processMapper)
            .map((p) => (
              <Badge key={p.name} variant='indigo' rounded className='mb-2 ml-2'>
                {processMapper && p.name in processMapper
                  ? processMapper[p.name as keyof typeof processMapper].name
                  : p.name}{' '}
                ({p.task_count})
              </Badge>
            ))}
        </p>
      </div>

      <div className='flex gap-4'>
        <Button
          onClick={() =>
            navigate(
              `/${MainPathEnum.PROCESSES}/${SubPathEnum.TASK_DISCOVERY}/${task.task_name}/${SubPathEnum.TASK_VARIANTS}`,
            )
          }
        >
          Show flows
        </Button>
        <Button
          onClick={setIsAccordionOpen}
          iconEnd={isAccordionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        >
          {isAccordionOpen ? 'Less' : 'More'} info
        </Button>
      </div>
    </div>
  )
}

export default TaskListItem
