import { useGetUserTrendsQuery } from '../../app/dataTrendsApiSlice'
import FavoritesDisplay, {
  FavoriteTypeEnum,
} from '../../common/components/displays/FavoritesDisplay/FavoritesDisplay'

const PersonalGoals = () => {
  const { data } = useGetUserTrendsQuery()

  return <FavoritesDisplay type={FavoriteTypeEnum.PERSONAL} queries={data} />
}

export default PersonalGoals
