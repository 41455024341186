import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useAppSelector,
  useEnhancedSearchParams,
  useLocalStorage,
  useQueryFilters,
} from '../../../../common/hooks'
import { SubSearchParamEnum } from '../../../../common/types/searchParams'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import { selectStateVariantData, selectStepVariantData } from '../../processesSlice'
import type { ZoomingFilters } from '../../types'
import { ZoomModeEnum } from './types'

export const useZoomingFilters = (): {
  zoomingFilters: ZoomingFilters | undefined
  setZoomingFilters: ({
    stepName,
    stepIndex,
    stateName,
  }: {
    stepName?: string | null | undefined
    stepIndex?: number | null | undefined
    stateName?: string | null | undefined
  }) => void
  updateZoomingFilters: ({
    stepName,
    stepIndex,
    stateName,
  }: {
    stepName?: string | null | undefined
    stepIndex?: number | null | undefined
    stateName?: string | null | undefined
  }) => void
  clearZoomingFilters: () => void
  setStepModalFilters: (stepName: string, stepIndex: number | null) => void
  clearStepModalFilters: () => void
  setStateModalFilters: (stateName: string) => void
  clearStateModalFilters: () => void
  activeZoomMode: ZoomModeEnum | null
} => {
  const [searchParams] = useSearchParams()
  const { updateSearchParam, deleteSearchParam } = useEnhancedSearchParams()
  const { getItem, updateItem } = useLocalStorage()
  const { processFiltersWithZooming } = useQueryFilters()

  const stepVariantData = useAppSelector(selectStepVariantData)
  const stateVariantData = useAppSelector(selectStateVariantData)

  const zoomingFilters = processFiltersWithZooming.zooming_filters

  const activeZoomMode = searchParams.get(SubSearchParamEnum.ZOOM_MODE) as ZoomModeEnum

  const updateZoomingFilters = useCallback(
    ({
      stepName,
      stepIndex,
      stateName,
    }: {
      stepName?: string | null | undefined
      stepIndex?: number | null | undefined
      stateName?: string | null | undefined
    }) => {
      const dataCopy = {
        ...(processFiltersWithZooming.zooming_filters ?? {}),
      }

      dataCopy.step_name = stepName ? stepName : dataCopy.step_name
      dataCopy.step_index = stepIndex || stepIndex === 0 ? stepIndex : dataCopy.step_index
      dataCopy.selected_step_variants = stepName
        ? stepVariantData.map((v) => v.steps)
        : dataCopy.selected_step_variants
      dataCopy.state_name = stateName ? stateName : dataCopy.state_name
      dataCopy.selected_state_variants = stateName
        ? stateVariantData.map((v) => v.steps)
        : dataCopy.selected_state_variants
      updateItem(StorageNameEnum.PROCESS_FILTERS, {
        [ProcessFiltersStorageContentEnum.ZOOMING]: { zooming_filters: dataCopy },
      })

      if (stepName) {
        updateSearchParam(SubSearchParamEnum.ZOOM_MODE, ZoomModeEnum.WINDOW)
      } else if (stateName) {
        updateSearchParam(SubSearchParamEnum.ZOOM_MODE, ZoomModeEnum.STEP)
      }
    },
    [
      stepVariantData,
      stateVariantData,
      updateItem,
      updateSearchParam,
      processFiltersWithZooming.zooming_filters,
    ],
  )

  const setZoomingFilters = useCallback(
    ({
      stepName,
      stepIndex,
      stateName,
    }: {
      stepName?: string | null | undefined
      stepIndex?: number | null | undefined
      stateName?: string | null | undefined
    }) => {
      const dataCopy = {
        ...(processFiltersWithZooming.zooming_filters ?? {}),
      }

      dataCopy.step_name = stepName ? stepName : null
      dataCopy.step_name = stepIndex || stepIndex === 0 ? stepIndex : null
      dataCopy.selected_step_variants = stepName ? stepVariantData.map((v) => v.steps) : null
      dataCopy.state_name = stateName ? stateName : null
      dataCopy.selected_state_variants = stateName ? stateVariantData.map((v) => v.steps) : null
      updateItem(StorageNameEnum.PROCESS_FILTERS, {
        [ProcessFiltersStorageContentEnum.ZOOMING]: { zooming_filters: dataCopy },
      })

      if (stepName) {
        updateSearchParam(SubSearchParamEnum.ZOOM_MODE, ZoomModeEnum.WINDOW)
      } else if (stateName) {
        updateSearchParam(SubSearchParamEnum.ZOOM_MODE, ZoomModeEnum.STEP)
      } else {
        deleteSearchParam(SubSearchParamEnum.ZOOM_MODE)
      }
    },
    [
      stepVariantData,
      stateVariantData,
      updateItem,
      updateSearchParam,
      processFiltersWithZooming.zooming_filters,
      deleteSearchParam,
    ],
  )

  const clearZoomingFilters = useCallback(() => {
    updateItem(StorageNameEnum.PROCESS_FILTERS, {
      [ProcessFiltersStorageContentEnum.ZOOMING]: {
        zooming_filters: {
          step_name: null,
          selected_step_variants: null,
          state_name: null,
          selected_state_variants: null,
        },
      },
    })
  }, [updateItem])

  const setStepModalFilters = useCallback(
    (stepName: string, stepIndex: number | null = null) => {
      const dataCopy = {
        ...(getItem(StorageNameEnum.PROCESS_FILTERS)?.[ProcessFiltersStorageContentEnum.ZOOMING]
          ?.zooming_filters ?? {}),
      }
      dataCopy.step_name = stepName
      dataCopy.selected_step_variants = stepVariantData.map((v) => v.steps)
      if (stepIndex !== null) {
        dataCopy.step_index = stepIndex
      }

      updateItem(StorageNameEnum.PROCESS_FILTERS, {
        [ProcessFiltersStorageContentEnum.ZOOMING]: { zooming_filters: dataCopy },
      })
    },
    [getItem, stepVariantData, updateItem],
  )

  const clearStepModalFilters = useCallback(() => {
    const dataCopy = {
      ...(getItem(StorageNameEnum.PROCESS_FILTERS)?.[ProcessFiltersStorageContentEnum.ZOOMING]
        ?.zooming_filters ?? {}),
    }
    dataCopy.step_name = null
    dataCopy.selected_step_variants = null
    dataCopy.step_index = null

    updateItem(StorageNameEnum.PROCESS_FILTERS, {
      [ProcessFiltersStorageContentEnum.ZOOMING]: { zooming_filters: dataCopy },
    })
  }, [getItem, updateItem])

  const setStateModalFilters = useCallback(
    (stateName: string) => {
      updateItem(StorageNameEnum.PROCESS_FILTERS, {
        [ProcessFiltersStorageContentEnum.ZOOMING]: {
          zooming_filters: {
            state_name: stateName,
            selected_state_variants: stateVariantData.map((v) => v.steps),
          },
        },
      })
    },
    [stateVariantData, updateItem],
  )

  const clearStateModalFilters = useCallback(() => {
    updateItem(StorageNameEnum.PROCESS_FILTERS, {
      [ProcessFiltersStorageContentEnum.ZOOMING]: {
        zooming_filters: {
          state_name: null,
          selected_state_variants: null,
        },
      },
    })
  }, [updateItem])

  return {
    zoomingFilters,
    setZoomingFilters,
    updateZoomingFilters,
    clearZoomingFilters,
    setStepModalFilters,
    clearStepModalFilters,
    activeZoomMode,
    setStateModalFilters,
    clearStateModalFilters,
  }
}
