import { useEnhancedNavigate } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import { compressAndEncodeJSON } from '../../../common/utils/jsonCompression'
import TimeLayout from '../TimeLayout'
import ApplicationCategoriesStats from './ApplicationCategoriesStats'
import TimeStats from './TimeStats'

const Time = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <div className='flex h-3/4 flex-col gap-3'>
      <TimeStats />
      <ApplicationCategoriesStats />
      <TimeLayout
        queryOptionsApplications={{ tagsOfInterestSource: 'application' }}
        queryOptionsActivities={{ tagsOfInterestSource: 'application' }}
        onNavigate={(tags: Tag[]) => navigate(compressAndEncodeJSON(tags))}
        hasColorAppCategories
      />
    </div>
  )
}

export default Time
