import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useGetActivityInsightTimesQuery,
  useGetApplicationCategoriesQuery,
} from '../../../app/apiSlice'
import { CHART_PRIMARY_HSLA, appCategoryColorMap } from '../../../app/theme'
import { BarChartAndTableDisplay, Loader } from '../../../common/components'
import { ChartDataEntry } from '../../../common/components/Charts/types'
import { Select } from '../../../common/designs'
import {
  useEnhancedSearchParams,
  useFilteredWindowUsageQuery,
  useGetActivityInsightBody,
} from '../../../common/hooks'
import { FilteredWindowUsageQueryOptions } from '../../../common/hooks/useFilteredWindowUsageQuery'
import { GetActivityInsightBodyOptions } from '../../../common/hooks/useGetActivityInsightBody'
import type { Tag } from '../../../common/types/common'
import { applicationCategories } from '../../../common/types/dataCollectionsConfigs'
import { SubSearchParamEnum } from '../../../common/types/searchParams'
import { formatNumber, fractionToPercentage } from '../../../common/utils/numberFormatting'
import { ActiveTimeTabEnum } from '../enums'
import type { WindowUsageData } from '../types'
import {
  VisitActivityDescriptionEnum,
  activityDescriptions,
  getActivityName,
} from './activityDescriptions'

interface Props {
  queryOptionsApplications: FilteredWindowUsageQueryOptions
  queryOptionsActivities: GetActivityInsightBodyOptions
  hasColorAppCategories?: boolean
  onNavigate: (tags: Tag[]) => void
}

const activeViewOptions = [
  { label: 'Application usage', value: ActiveTimeTabEnum.APPLICATIONS },
  { label: 'Activities', value: ActiveTimeTabEnum.ACTIVITIES },
]

const TimeLayout = ({
  queryOptionsApplications,
  queryOptionsActivities,
  hasColorAppCategories,
  onNavigate,
}: Props) => {
  const { updateSearchParam } = useEnhancedSearchParams()
  const [searchParams] = useSearchParams()
  const [chartDataApps, setChartDataApps] = useState<ChartDataEntry[]>([])
  const [chartDataActivities, setChartDataActivities] = useState<ChartDataEntry[]>([])
  const [activeViewValue, setActiveViewValue] = useState<ActiveTimeTabEnum>(
    ActiveTimeTabEnum.APPLICATIONS,
  )

  const { data: appData, isLoading } = useFilteredWindowUsageQuery(queryOptionsApplications)
  const queryBody = useGetActivityInsightBody(queryOptionsActivities)
  const { data: activityData } = useGetActivityInsightTimesQuery(queryBody)
  const { data: applicationCategorization } = useGetApplicationCategoriesQuery()

  useEffect(() => {
    if (appData) convertAppDataToChart()
  }, [appData, applicationCategorization]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activityData) convertActivityDataToChart()
  }, [activityData]) // eslint-disable-line react-hooks/exhaustive-deps

  const convertAppDataToChart = () => {
    if (!appData) return
    const chartData = (appData as WindowUsageData[]).map(
      (item: { name: string; time_h: number }) => {
        const { name, time_h } = item
        const category = applicationCategorization?.[name] ?? applicationCategories.other

        return {
          label: name,
          value: time_h,
          color: hasColorAppCategories
            ? appCategoryColorMap[category] ?? appCategoryColorMap[category]
            : CHART_PRIMARY_HSLA,
        }
      },
    )

    setChartDataApps(chartData)
  }

  const convertActivityDataToChart = () => {
    if (!activityData) return

    const chartData = activityData.map((item) => {
      const { name, total_duration_sec } = item
      const timeH = total_duration_sec / 60 / 60
      return {
        label: getActivityName(name),
        value: timeH,
      }
    })

    setChartDataActivities(chartData)
  }

  // Update state when URL search params change.
  useEffect(() => {
    const mode = searchParams.get(SubSearchParamEnum.ACTIVE_VIEW) as ActiveTimeTabEnum
    setActiveViewValue(mode ?? ActiveTimeTabEnum.APPLICATIONS)
  }, [searchParams, setActiveViewValue])

  // Update URL search params when visualization mode is changed.
  const onChangeActiveView = useCallback(
    (val: string) => {
      updateSearchParam(SubSearchParamEnum.ACTIVE_VIEW, val)
    },
    [updateSearchParam],
  )

  const totalTime = useMemo(() => {
    const activeData =
      activeViewValue === ActiveTimeTabEnum.APPLICATIONS ? chartDataApps : chartDataActivities
    return activeData.reduce((previousValue, { value }) => previousValue + value, 0)
  }, [activeViewValue, chartDataApps, chartDataActivities])

  const timeTooltipCallback = useCallback(
    (tooltipItems: any) =>
      `${formatNumber(tooltipItems.raw, 10)} h (${fractionToPercentage(
        tooltipItems.raw / totalTime,
      )})`,
    [totalTime],
  )

  const legendData = useMemo(() => {
    if (!hasColorAppCategories || activeViewValue !== ActiveTimeTabEnum.APPLICATIONS) return null
    return Object.entries(appCategoryColorMap).map(([label, color]) => ({ label, color }))
  }, [hasColorAppCategories, activeViewValue])

  const activityTooltipCallback = useCallback(
    (tooltipItems: any) => [
      `${formatNumber(tooltipItems.raw, 10)} h (${fractionToPercentage(
        tooltipItems.raw / totalTime,
      )})`,
      ...activityDescriptions[tooltipItems.label as VisitActivityDescriptionEnum],
    ],
    [totalTime],
  )

  const onChartClick = (_: string, index: number) => {
    onNavigate((appData as WindowUsageData[])[index]?.tags)
  }

  if (isLoading) return <Loader />

  return (
    <BarChartAndTableDisplay
      label={
        activeViewValue === ActiveTimeTabEnum.APPLICATIONS ? 'Business apps usage' : 'Activities'
      }
      legend={legendData}
      data={
        activeViewValue === ActiveTimeTabEnum.APPLICATIONS ? chartDataApps : chartDataActivities
      }
      unit='h'
      tableHeaderValues={[
        activeViewValue === ActiveTimeTabEnum.APPLICATIONS ? 'System' : 'Activity',
        'Time',
      ]}
      onClick={activeViewValue === ActiveTimeTabEnum.APPLICATIONS ? onChartClick : undefined}
      tooltipCallback={
        activeViewValue === ActiveTimeTabEnum.APPLICATIONS
          ? timeTooltipCallback
          : activityTooltipCallback
      }
      isExportable
      selectActions={[
        <Select
          key='app-activity-select'
          size='xs'
          options={activeViewOptions}
          value={
            activeViewValue === ActiveTimeTabEnum.APPLICATIONS
              ? activeViewOptions[0].value
              : activeViewOptions[1].value
          }
          onChange={onChangeActiveView}
        />,
      ]}
    />
  )
}

export default TimeLayout
