import { teamColorMap } from '../../../../../app/theme'
import type { Teams } from '../../../../../common/utils/UtilityWrapper/types'
import { getBestTimeFormatTransformationForSeconds } from '../../../../../common/utils/numberFormatting'
import { TIME_UNIT } from '../../../constants'
import type { GroupResultData } from '../../../types'
import { GroupByDimensionTypeEnum } from '../../../types'
import { StepGroupByFieldTypeEnum, StepMetricNamesEnum } from './types'

export const defaultGroupByDimensionsExtraDataMapper = {
  [StepGroupByFieldTypeEnum.TRANSACTION_ID]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC,
    dimension_name: 'Case id',
    is_tag_dimension: false,
    filter_key: 'transaction_id_filters',
  },
  [StepGroupByFieldTypeEnum.STEP_TEAM_ID]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
    dimension_name: 'Team',
    is_tag_dimension: false,
    filter_key: 'team_id_filters',
  },
  [StepGroupByFieldTypeEnum.STEP_WORK_TYPE]: {
    group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
    dimension_name: 'Work type',
    is_tag_dimension: false,
    filter_key: '',
  },
}

export const stepMetricNameExtraDataMapper = {
  [StepMetricNamesEnum.TOTAL_TRANSACTIONS]: {
    metric_unit: 'transactions',
    metric_name: 'Total cases',
  },
  [StepMetricNamesEnum.TOTAL_STEP_VISITS]: {
    metric_unit: 'visits',
    metric_name: 'Total task visits',
  },
  [StepMetricNamesEnum.STEP_VISITS_PER_TRANSACTION]: {
    metric_unit: 'visits',
    metric_name: 'Task visits per case',
  },

  [StepMetricNamesEnum.DURATION_SEC]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Total visit time',
  },

  [StepMetricNamesEnum.AVG_DURATION_SEC]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Avg. visit time',
  },
  [StepMetricNamesEnum.AVG_TIME_FROM_PREV_STEP_SEC]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Avg. lead time to task',
  },
  [StepMetricNamesEnum.AVG_COPY_COUNT]: {
    metric_unit: 'copies',
    metric_name: 'Avg. copies',
  },
  [StepMetricNamesEnum.AVG_PASTE_COUNT]: {
    metric_unit: 'pastes',
    metric_name: 'Avg. pastes',
  },
  [StepMetricNamesEnum.AVG_KEYBOARD_PRESS_COUNT]: {
    metric_unit: 'key presses',
    metric_name: 'Avg. key presses',
  },
  [StepMetricNamesEnum.AVG_MOUSE_CLICK_COUNT]: {
    metric_unit: 'mouse clicks',
    metric_name: 'Avg. mouse clicks',
  },
}

export const stateMetricNameExtraDataMapper = {
  [StepMetricNamesEnum.TOTAL_TRANSACTIONS]: {
    metric_unit: 'transactions',
    metric_name: 'Total cases',
  },
  [StepMetricNamesEnum.TOTAL_STEP_VISITS]: {
    metric_unit: 'visits',
    metric_name: 'Total state touches',
  },
  [StepMetricNamesEnum.STEP_VISITS_PER_TRANSACTION]: {
    metric_unit: 'visits',
    metric_name: 'State touches per case',
  },

  [StepMetricNamesEnum.DURATION_SEC]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Active work time in state',
  },

  [StepMetricNamesEnum.AVG_DURATION_SEC]: {
    metric_unit: TIME_UNIT,
    metric_name: 'Avg. active work time in state',
  },

  [StepMetricNamesEnum.AVG_COPY_COUNT]: {
    metric_unit: 'copies',
    metric_name: 'Avg. copies in state',
  },
  [StepMetricNamesEnum.AVG_PASTE_COUNT]: {
    metric_unit: 'pastes',
    metric_name: 'Avg. pastes in state',
  },
  [StepMetricNamesEnum.AVG_KEYBOARD_PRESS_COUNT]: {
    metric_unit: 'key presses',
    metric_name: 'Avg. key presses in state',
  },
  [StepMetricNamesEnum.AVG_MOUSE_CLICK_COUNT]: {
    metric_unit: 'mouse clicks',
    metric_name: 'Avg. mouse clicks in state',
  },
}

export const setUpStepChartDataAndMetric = (
  dataArray: GroupResultData[],
  selectedMetric: StepMetricNamesEnum,
  groupByField: string,
  teamIdMapper: Teams,
) => {
  let chartData
  let unit =
    stepMetricNameExtraDataMapper[selectedMetric as keyof typeof stepMetricNameExtraDataMapper]
      .metric_unit
  let divider = 1
  // If time metric => get best time format for seconds and update unit
  if (unit === TIME_UNIT) {
    ;({ divider, unit } = getBestTimeFormatTransformationForSeconds(dataArray[0]?.value || 0))
  }
  // If team id grouping => Change team ids to team names and colors.
  if (groupByField === StepGroupByFieldTypeEnum.STEP_TEAM_ID) {
    chartData = dataArray.map(({ label, value }) => {
      const typedLabel = label as string
      const teamObj = teamIdMapper[typedLabel]
      return {
        label: teamObj?.name || typedLabel,
        value: value / divider,
        color: teamColorMap[teamObj?.colorIndex || 0],
      }
    })
  }
  // Else use activeData as is
  else {
    chartData = dataArray.map(({ label, value }) => ({
      label: label as string,
      value: value / divider,
    }))
  }

  return { chartData, unit }
}
