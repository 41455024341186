import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useUploadConformanceAttachmentMutation } from '../../../app/apiSlice'
import { MAX_FILE_UPLOAD_SIZE } from '../../../app/constants'
import { Button, FileSelector, Modal } from '../../../common/designs'
import { useQueryFilters } from '../../../common/hooks'
import type { ConformanceFlowsData } from '../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  data: ConformanceFlowsData
}

const ConformanceAttachmentModal = ({ isOpen, onClose, data }: Props) => {
  const { processId } = useQueryFilters()
  const [activeAttachment, setActiveAttachment] = useState<Blob | null>(null)

  const [uploadCompanyConfig] = useUploadConformanceAttachmentMutation()

  useEffect(() => {
    setActiveAttachment(null)
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFileChange = (files: Blob[]): React.ReactText | void => {
    const file = files[0]
    if (!file) return
    if (file?.size > MAX_FILE_UPLOAD_SIZE) return toast.warning('File size exceed limit of 10 MB')

    setActiveAttachment(file)
  }

  const onUpload = () => {
    if (!activeAttachment) return

    const formData = new FormData()
    formData.append('files', activeAttachment)
    uploadCompanyConfig({
      processId,
      conformanceId: data.id,
      body: formData,
    })
      .unwrap()
      .then(() => {
        toast.success('Attachment uploaded successfully')
        onClose()
      })
      .catch(() => {
        toast.error('Something went wrong when uploading the attachment')
      })
  }

  return (
    <Modal label={`Upload attachment for ${data.conformance_name}`} open={isOpen} onClose={onClose}>
      <div className='space-y-4'>
        <FileSelector
          onChange={onFileChange}
          accept='image/*,application/*,.docx,.doc,.pdf,.xlsx,.xls,.ppt,.pptx,.txt,.csv,.rtf,.zip,.rar'
        />

        <div className='flex justify-end'>
          <Button iconStart={<ArrowDownTrayIcon />} disabled={!activeAttachment} onClick={onUpload}>
            Upload
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConformanceAttachmentModal
