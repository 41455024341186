export interface ProcessProperties {
  id: string
  initial_visible_variant_amount: number
  name: string
  process_category: string | null
  process_identifier_name: string
  state_tag_key: string | null
}

export enum UserActivityTabEnum {
  DATA_FLOWS = 'DATA_FLOWS',
  COPY_PASTE_ANALYSIS = 'COPY_PASTE_ANALYSIS',
  ACTIVITY_ANALYSIS = 'ACTIVITY_ANALYSIS',
  FIELD_CHANGE_ANALYSIS = 'FIELD_CHANGE_ANALYSIS',
}
