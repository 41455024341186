import type { PercentageValue } from '../../types/common'
import { formatNumber } from '../../utils/numberFormatting'
import type { VariabeCombinationDataEntry } from '../Charts/VariableCombinationBarChart'
import { orderGroupedBarchartData } from '../Charts/dataToChartData'
import type { ChartDataEntry, GroupedBarChartDataEntry } from '../Charts/types'

export const convertChartDataToTableBody = (chartData: ChartDataEntry[], unit: string) => {
  return chartData.map((d) => [d.label, `${formatNumber(d.value, 10)} ${unit}`])
}

export const convertChartDataToVisualizationValues = (chartData: ChartDataEntry[]) => {
  const maxValue = Math.max(...chartData.map((d) => d.value)) * 1.1
  return chartData.map((d) => Math.round((d.value / maxValue) * 100) as PercentageValue)
}

export const convertGroupChartDataToTableHead = (chartData: GroupedBarChartDataEntry[]) => {
  if (chartData.length === 0) return []

  return chartData.map((g) => g.groupName)
}

export const variableCombinationChartDataToTableHead = (
  chartData: VariabeCombinationDataEntry[],
) => {
  if (chartData.length === 0) return []

  return chartData[0].variables.map((v) => v.label)
}

export const convertGroupChartDataToTableBody = (
  chartData: GroupedBarChartDataEntry[],
  unit: string,
  numberRounding: number,
) => {
  if (chartData.length === 0) return [[]]
  const orderedData = orderGroupedBarchartData(chartData)
  const tableData: { [key: string]: string[] } = {}
  const rowDefaultValues = orderedData.map((_) => `0 ${unit}`)

  orderedData.forEach((d, columnIndex) =>
    d.data.forEach((val) => {
      if (!(val.label in tableData)) {
        tableData[val.label] = [val.label, ...rowDefaultValues]
      }
      tableData[val.label][columnIndex + 1] = `${formatNumber(val.value, numberRounding)} ${unit}`
    }),
  )

  return Object.values(tableData)
}

export const convertVariableCombinationChartDataToTableBody = (
  chartData: VariabeCombinationDataEntry[],
  numberRounding: number,
) => {
  return chartData.map((item) => [
    ...item.variables.map((v) => v.value),
    formatNumber(item.value, numberRounding),
  ])
}

export const convertGroupChartDataToVisualizationValues = (
  chartData: GroupedBarChartDataEntry[],
) => {
  const orderedData = orderGroupedBarchartData(chartData)
  const rowData: { [key: string]: number[] } = {}
  orderedData.forEach((d) =>
    d.data.forEach((val) => {
      if (!(val.label in rowData)) {
        rowData[val.label] = []
      }
      rowData[val.label].push(val.value)
    }),
  )

  const rowValues = Object.values(rowData)
  orderedData.forEach((d) => d.data.forEach((val, row) => rowValues[row].push(val.value)))
  const rowTotalValues = rowValues.map((d) => d.reduce((partialSum, a) => partialSum + a, 0))
  const maxValue = Math.max(...rowTotalValues) * 1.1
  return rowTotalValues.map((d) => Math.round((d / maxValue) * 100) as PercentageValue)
}

export const convertCombinationChartChartDataToVisualizationValues = (
  chartData: VariabeCombinationDataEntry[],
) => {
  const maxValue = Math.max(...chartData.map((d) => d.value)) * 1.1
  return chartData.map((d) => Math.round((d.value / maxValue) * 100) as PercentageValue)
}
