import {
  AcademicCapIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/solid'
import { ReactNode, useState } from 'react'

import type { BasicColorIndex } from '../../app/theme'
import {
  BarChart,
  BarChartAndTableDisplay,
  ContentDisplay,
  DataDisplayTable,
  GroupedBarChart,
  HorizontalBar,
  VisualizationContainer,
} from '../../common/components'
import {
  convertChartDataToTableBody,
  convertChartDataToVisualizationValues,
} from '../../common/components/tables/dataUtils'
import {
  ActionModal,
  Avatar,
  Badge,
  Breadcrumbs,
  Button,
  Checkboxes,
  ColorPicker,
  Divider,
  Drawer,
  DrawerMultiple,
  FileSelector,
  IconButton,
  InputField,
  KBD,
  Link,
  LinkButton,
  Modal,
  ProgressBar,
  Radios,
  Select,
  SelectMultiple,
  Slider,
  Table,
  Tabs,
  Text,
  TextArea,
  Toggle,
  Tooltip,
} from '../../common/designs'
import {
  chartData,
  chartDataColors,
  chartDataColorsLegend,
  chartLongLegend,
  chartTableTabOptions,
  checkboxOptions,
  confirmLabel,
  groupedChartData,
  lorem,
  radiosOptions,
  radiosOptions2,
  shortLorem,
  tableData,
  tabsOptions,
  visualizationTableBody,
  visualizationTableHead,
  visualizationTablePercentageValues,
} from './data'

const selectOptionsNew = [
  {
    label: 'Team kontula',
    value: 'kontula',
    prefix: <AcademicCapIcon className='size-4' />,
  },
  {
    label: 'Team Itis',
    value: 'itis',
  },
  {
    label: 'Team Mellunmäki',
    value: 'mellunmäki',
    prefix: <TrashIcon className='size-4' />,
  },
  {
    label: 'Team Myllypuro',
    value: 'myllypuro',
    prefix: <TrashIcon className='size-4' />,
  },
]

const Sandbox = () => {
  const [slider, setSlider] = useState<number>(55)

  const [tabSelected, setTabSelected] = useState<string>(tabsOptions[1].value)

  const [colorIndex, setColorIndex] = useState<BasicColorIndex>(2)

  const [radioSelected, setRadioSelected] = useState<any>({
    name: 'First',
    value: 'first',
  })
  const [radioSelected2, setRadioSelected2] = useState<any>({
    name: 'Radio 4',
    value: 'radio4',
  })

  const [toggle, setToggle] = useState<boolean>(false)
  const [toggle2, setToggle2] = useState<boolean>(true)
  const [toggle3, setToggle3] = useState<boolean>(false)
  const [toggle4, setToggle4] = useState<boolean>(true)

  const [checkboxSelected, setCheckboxSelected] = useState<string[]>(['recommendations'])

  const [selectSelected, setSelectSelected] = useState<string>(selectOptionsNew[0].value)
  const [selectSelected2, setSelectSelected2] = useState<string>(selectOptionsNew[0].value)
  const [multipleselectSelected, setMultipleSelectSelected] = useState<string[]>([
    selectOptionsNew[0].value,
    selectOptionsNew[1].value,
  ])

  const [, setFileSelected] = useState<File[] | null>(null)

  const [modalOpen1, setModalOpen1] = useState<boolean>(false)
  const [modalOpen2, setModalOpen2] = useState<boolean>(false)
  const [modalOpen3, setModalOpen3] = useState<boolean>(false)
  const [modalOpen4, setModalOpen4] = useState<boolean>(false)
  const [modalOpen5, setModalOpen5] = useState<boolean>(false)
  const [modalOpen6, setModalOpen6] = useState<boolean>(false)
  const [modalOpen7, setModalOpen7] = useState<boolean>(false)
  const [modalOpen8, setModalOpen8] = useState<boolean>(false)
  const [modalOpen9, setModalOpen9] = useState<boolean>(false)
  const [modalOpen10, setModalOpen10] = useState<boolean>(false)

  const [actionModalOpen1, setActionModalOpen1] = useState<boolean>(false)
  const [actionModalOpen2, setActionModalOpen2] = useState<boolean>(false)
  const [actionModalOpen3, setActionModalOpen3] = useState<boolean>(false)

  const [drawerOpen1, setDrawerOpen1] = useState<boolean>(false)
  const [drawerOpen2, setDrawerOpen2] = useState<boolean>(false)
  const [drawerOpen3, setDrawerOpen3] = useState<boolean>(false)
  const [drawerOpen4, setDrawerOpen4] = useState<boolean>(false)
  const [drawerOpen5, setDrawerOpen5] = useState<boolean>(false)
  const [drawerOpen6, setDrawerOpen6] = useState<boolean>(false)
  const [drawerOpen7, setDrawerOpen7] = useState<boolean>(false)

  const [drawerOpen8, setDrawerOpen8] = useState<boolean>(false)
  const [drawerOpen9, setDrawerOpen9] = useState<boolean>(false)
  const [drawerOpen10, setDrawerOpen10] = useState<boolean>(false)
  const [drawerOpen11, setDrawerOpen11] = useState<boolean>(false)
  const [drawerOpen12, setDrawerOpen12] = useState<boolean>(false)

  const [drawerMultiple, setDrawerMultiple] = useState<{
    isOpen: boolean
    tabIndex: number
  }>({
    isOpen: false,
    tabIndex: 0,
  })

  const [vizContainerTabValue, setVizContainerTabValue] = useState<string>(
    chartTableTabOptions[0].value,
  )

  return (
    <div className='w-full bg-white p-4'>
      <DesignSystem title='Color picker'>
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={500}
          size='xs'
          label='Color picker'
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={400}
          size='s'
          label='Color picker'
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={300}
          size='m'
          label='Color picker'
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={200}
          size='l'
          label='Color picker'
        />

        <div className='m-4' />

        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={500}
          size='xs'
          label='Color picker'
          rounded
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={400}
          size='s'
          label='Color picker'
          rounded
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={300}
          size='m'
          label='Color picker'
          rounded
        />
        <ColorPicker
          value={colorIndex}
          onChange={setColorIndex}
          variant={200}
          size='l'
          label='Color picker'
          rounded
        />
      </DesignSystem>

      <DesignSystem title='Modal'>
        <Button onClick={() => setModalOpen1((s) => !s)} className='mr-2'>
          XS
        </Button>

        <Button onClick={() => setModalOpen2((s) => !s)} className='mr-2'>
          S
        </Button>

        <Button onClick={() => setModalOpen3((s) => !s)} className='mr-2'>
          M
        </Button>

        <Button onClick={() => setModalOpen4((s) => !s)} className='mr-2'>
          L
        </Button>

        <Button onClick={() => setModalOpen5((s) => !s)} className='mr-2'>
          XL
        </Button>

        <Button onClick={() => setModalOpen6((s) => !s)} className='mr-2'>
          2XL
        </Button>

        <Button onClick={() => setModalOpen7((s) => !s)} className='mr-2'>
          3XL
        </Button>

        <Button onClick={() => setModalOpen8((s) => !s)} className='mr-2'>
          4XL
        </Button>

        <Button onClick={() => setModalOpen9((s) => !s)} className='mr-2'>
          5XL
        </Button>

        <Button onClick={() => setModalOpen10((s) => !s)} className='mr-2'>
          6XL
        </Button>

        <Modal
          label='XS modal'
          size='xs'
          onClose={() => setModalOpen1((s) => !s)}
          open={modalOpen1}
        >
          {lorem}
        </Modal>

        <Modal label='S modal' size='s' onClose={() => setModalOpen2((s) => !s)} open={modalOpen2}>
          {lorem}
        </Modal>

        <Modal label='M modal' size='m' onClose={() => setModalOpen3((s) => !s)} open={modalOpen3}>
          {lorem}
        </Modal>

        <Modal label='L modal' size='l' onClose={() => setModalOpen4((s) => !s)} open={modalOpen4}>
          {lorem}
        </Modal>

        <Modal
          label='XL modal'
          size='xl'
          onClose={() => setModalOpen5((s) => !s)}
          open={modalOpen5}
        >
          {lorem}
        </Modal>

        <Modal
          label='2XL modal'
          size='2xl'
          onClose={() => setModalOpen6((s) => !s)}
          open={modalOpen6}
        >
          {lorem}
        </Modal>

        <Modal
          label='3XL modal'
          size='3xl'
          onClose={() => setModalOpen7((s) => !s)}
          open={modalOpen7}
        >
          {lorem}
        </Modal>

        <Modal
          label='4XL modal'
          size='4xl'
          onClose={() => setModalOpen8((s) => !s)}
          open={modalOpen8}
        >
          {lorem}
        </Modal>

        <Modal
          label='5XL modal'
          size='5xl'
          onClose={() => setModalOpen9((s) => !s)}
          open={modalOpen9}
        >
          {lorem}
        </Modal>

        <Modal
          label='6XL modal'
          size='6xl'
          onClose={() => setModalOpen10((s) => !s)}
          open={modalOpen10}
        >
          {lorem}
        </Modal>
      </DesignSystem>

      <DesignSystem title='ActionModal'>
        <Button onClick={() => setActionModalOpen1((s) => !s)} className='mr-2'>
          Primary
        </Button>

        <Button
          variant='destructive'
          onClick={() => setActionModalOpen2((s) => !s)}
          className='mr-2'
        >
          Destructive
        </Button>

        <Button variant='success' onClick={() => setActionModalOpen3((s) => !s)} className='mr-2'>
          Success
        </Button>

        <ActionModal
          actionLabel='Proceed'
          label={confirmLabel}
          description={shortLorem}
          open={actionModalOpen1}
          onClose={() => setActionModalOpen1((s) => !s)}
          onAction={() => null}
        />

        <ActionModal
          variant='destructive'
          actionLabel='Delete'
          label={confirmLabel}
          description={shortLorem}
          open={actionModalOpen2}
          onClose={() => setActionModalOpen2((s) => !s)}
          onAction={() => null}
        />

        <ActionModal
          variant='success'
          actionLabel='Save'
          label={confirmLabel}
          description={shortLorem}
          open={actionModalOpen3}
          onClose={() => setActionModalOpen3((s) => !s)}
          onAction={() => null}
        />
      </DesignSystem>

      <DesignSystem title='Drawer'>
        <Button onClick={() => setDrawerOpen1((s) => !s)} className='mr-2'>
          XS
        </Button>

        <Button onClick={() => setDrawerOpen2((s) => !s)} className='mr-2'>
          S
        </Button>

        <Button onClick={() => setDrawerOpen3((s) => !s)} className='mr-2'>
          M
        </Button>

        <Button onClick={() => setDrawerOpen4((s) => !s)} className='mr-2'>
          L
        </Button>

        <Button onClick={() => setDrawerOpen5((s) => !s)} className='mr-2'>
          XL
        </Button>

        <Button onClick={() => setDrawerOpen6((s) => !s)} className='mr-2'>
          2XL
        </Button>

        <Button onClick={() => setDrawerOpen7((s) => !s)} className='mr-2'>
          3XL
        </Button>

        <Button onClick={() => setDrawerOpen8((s) => !s)} className='mr-2'>
          Primary
        </Button>

        <Button variant='secondary' onClick={() => setDrawerOpen9((s) => !s)} className='mr-2'>
          Secondary
        </Button>

        <Button variant='white' onClick={() => setDrawerOpen10((s) => !s)} className='mr-2'>
          White
        </Button>

        <Button variant='ghost' onClick={() => setDrawerOpen11((s) => !s)} className='mr-2'>
          Ghost
        </Button>

        <Button variant='destructive' onClick={() => setDrawerOpen12((s) => !s)} className='mr-2'>
          Destructive
        </Button>

        <Drawer
          open={drawerOpen1}
          onClose={() => setDrawerOpen1((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='xs'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen2}
          onClose={() => setDrawerOpen2((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='s'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen3}
          onClose={() => setDrawerOpen3((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='m'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen4}
          onClose={() => setDrawerOpen4((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='l'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen5}
          onClose={() => setDrawerOpen5((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='xl'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen6}
          onClose={() => setDrawerOpen6((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='2xl'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen7}
          onClose={() => setDrawerOpen7((s) => !s)}
          label='Label'
          description='Description'
          focus
          size='3xl'
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          open={drawerOpen8}
          onClose={() => setDrawerOpen8((s) => !s)}
          label='Primary'
          description='Description'
          focus
          footer={
            <>
              <Button variant='destructive'>Delete</Button>
              <Button>Save</Button>
            </>
          }
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          variant='secondary'
          open={drawerOpen9}
          onClose={() => setDrawerOpen9((s) => !s)}
          label='Secondary'
          description='Description'
          focus
          footer={
            <>
              <Button variant='destructive'>Delete</Button>
              <Button>Save</Button>
            </>
          }
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          variant='white'
          open={drawerOpen10}
          onClose={() => setDrawerOpen10((s) => !s)}
          label='White'
          description='Description'
          focus
          footer={
            <>
              <Button variant='destructive'>Delete</Button>
              <Button>Save</Button>
            </>
          }
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          variant='ghost'
          open={drawerOpen11}
          onClose={() => setDrawerOpen11((s) => !s)}
          label='Ghost'
          description='Description'
          focus
          footer={
            <>
              <Button variant='destructive'>Delete</Button>
              <Button>Save</Button>
            </>
          }
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>

        <Drawer
          variant='destructive'
          open={drawerOpen12}
          onClose={() => setDrawerOpen12((s) => !s)}
          label='Destructive'
          description='Description'
          focus
          footer={
            <>
              <Button variant='destructive'>Delete</Button>
              <Button>Save</Button>
            </>
          }
        >
          <div className='absolute inset-0 px-4 sm:px-6'>
            <div className='h-full border-2 border-dashed border-gray-200' aria-hidden='true' />
          </div>
        </Drawer>
      </DesignSystem>

      <DesignSystem title='Drawer multiple'>
        <Button
          onClick={() => setDrawerMultiple({ ...drawerMultiple, isOpen: true })}
          className='mr-2'
        >
          M
        </Button>

        <DrawerMultiple
          label='Multiple drawer label'
          open={drawerMultiple.isOpen}
          onClose={() => setDrawerMultiple({ ...drawerMultiple, isOpen: false })}
          size='m'
          focus
          tabs={['Example', 'Lorem', 'Ipsum', 'Foobar']}
          tabIndex={drawerMultiple.tabIndex}
          setTabIndex={(i: number) => setDrawerMultiple({ ...drawerMultiple, tabIndex: i })}
        >
          There&#39;s different sizes of this drawer as well, ranging from xs to 3xl. Tab index
          open: <b>{drawerMultiple.tabIndex}</b>
        </DrawerMultiple>
      </DesignSystem>

      <DesignSystem title='Table'>
        <Table
          size='xs'
          head={['first name', 'last name', 'email', 'gender', '']}
          body={tableData.slice(0, 3).map((t) => [
            t.first_name,
            t.last_name,
            <>
              {t.email}

              <Badge variant='green' rounded className='ml-2'>
                Active
              </Badge>
            </>,
            t.gender,
            <Link
              href='https://www.youtube.com/watch?v=dQw3w9WgXcQ'
              target='_blank'
              key={`${t.first_name}-${t.last_name}`}
            >
              Edit
            </Link>,
          ])}
        />

        <Table
          size='s'
          head={['first name', 'last name', 'email', 'gender', '']}
          body={tableData.slice(0, 3).map((t) => [
            t.first_name,
            t.last_name,
            <>
              {t.email}

              <Badge variant='green' rounded className='ml-2'>
                Active
              </Badge>
            </>,
            t.gender,
            <Link
              href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
              target='_blank'
              key={`${t.first_name}-${t.last_name}`}
            >
              Edit
            </Link>,
          ])}
          className='mt-4'
        />

        <Table
          label='Label'
          description='Description'
          action={<Button>Action</Button>}
          size='m'
          head={['first name', 'last name', 'email', 'gender', '']}
          body={tableData.slice(0, 10).map((t) => [
            t.first_name,
            t.last_name,
            <>
              {t.email}

              <Badge variant='green' rounded className='ml-2'>
                Active
              </Badge>
            </>,
            t.gender,
            <>
              <Link
                href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                target='_blank'
                className='mr-4'
              >
                Edit
              </Link>
              <Link href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank'>
                Delete
              </Link>
            </>,
          ])}
        />
      </DesignSystem>
      <DesignSystem title='File selector'>
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='xs'
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='s'
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='m'
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='l'
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='xl'
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='m'
          disabled
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='m'
          rounded
        />
        <FileSelector
          className='mt-2'
          onChange={(files: File[]) => setFileSelected(files)}
          size='m'
          label='Label'
          description='You can select multiple files on this'
          multiple
        />
      </DesignSystem>

      <DesignSystem title='Select'>
        <Select
          size='xs'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
        />

        <Select
          size='s'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
        />

        <Select
          size='m'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
        />

        <Select
          size='m'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
          disabled
        />

        <Select
          size='m'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
          error='Much errors.'
        />

        <Select
          label='Label'
          optional
          className='mt-2'
          description='Description'
          size='m'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
        />

        <Select
          size='m'
          className='mt-2'
          options={selectOptionsNew}
          value={selectSelected2}
          onChange={setSelectSelected2}
          rounded
        />
      </DesignSystem>

      <DesignSystem title='Select multiple'>
        <SelectMultiple
          options={selectOptionsNew}
          value={multipleselectSelected}
          onChange={setMultipleSelectSelected}
          className='mb-2'
        />

        <SelectMultiple
          options={selectOptionsNew}
          value={multipleselectSelected}
          onChange={setMultipleSelectSelected}
          selectedConfiguration={{
            max: 1,
            label: 'The best teams have been selected',
          }}
        />
      </DesignSystem>

      <DesignSystem title='Textarea'>
        <TextArea error='Much errors.' optional label='Label' />

        <TextArea
          className='mt-2'
          description='Description'
          optional
          footer={
            <div className='mt-2 flex gap-2'>
              <Button variant='white'>Discard</Button>
              <Button>Save</Button>
            </div>
          }
        />
      </DesignSystem>

      <DesignSystem title='Input field'>
        <InputField size='xs' className='mt-2' />
        <InputField size='s' className='mt-2' />
        <InputField size='m' className='mt-2' />
        <InputField size='m' label='Label' description='Description' className='mt-2' />
        <InputField size='m' label='Label' error='Much errors.' className='mt-2' optional />
        <InputField size='m' className='mt-2' disabled />
        <InputField size='m' className='mt-2' iconStart={<MagnifyingGlassIcon />} />
        <InputField size='m' className='mt-2' iconStart={<MagnifyingGlassIcon />} rounded />
        <InputField size='m' className='mt-2' iconEnd={<MagnifyingGlassIcon />} rounded />
      </DesignSystem>

      <DesignSystem title='Checkbox'>
        <Checkboxes
          label='Label'
          options={checkboxOptions}
          values={checkboxSelected}
          onChange={setCheckboxSelected}
        />
      </DesignSystem>

      <DesignSystem title='Divider'>
        <Divider />
        <Divider label='+' className='mt-8' />
        <Divider label='Label' className='mt-8' />
      </DesignSystem>

      <DesignSystem title='Toggle'>
        <Toggle value={toggle} onChange={setToggle} className='mt-2' />
        <Toggle value={toggle2} onChange={setToggle2} className='mt-2' />
        <Toggle value={toggle3} onChange={setToggle3} className='mt-2' disabled />
        <Toggle value={toggle4} onChange={setToggle4} className='mt-2' disabled />
        <Toggle value={toggle4} onChange={setToggle4} className='mt-2' size='xs' label='Size xs' />
        <Toggle value={toggle4} onChange={setToggle4} className='mt-2' size='s' label='Size s' />
        <Toggle value={toggle4} onChange={setToggle4} className='mt-2' size='m' label='Size m' />
        <Toggle value={toggle4} onChange={setToggle4} className='mt-2' size='l' label='Size l' />
      </DesignSystem>

      <DesignSystem title='Slider'>
        <Slider value={slider} onChange={setSlider} size='xs' />
        <Slider value={slider} onChange={setSlider} size='s' />
        <Slider value={slider} onChange={setSlider} size='m' />
        <Slider value={slider} onChange={setSlider} size='m' disabled label='Label' />
      </DesignSystem>

      <DesignSystem title='Radio'>
        <Radios
          options={radiosOptions}
          value={radioSelected}
          onChange={setRadioSelected}
          inline
          className='my-8'
          label='Label 1'
        />

        <Radios
          options={radiosOptions2}
          value={radioSelected2}
          onChange={setRadioSelected2}
          description='Description'
          inline
          className='my-8'
          label='Label'
        />
      </DesignSystem>

      <DesignSystem title='Text'>
        <Text variant='span'>Lorem ipsum.</Text>
        <Text variant='p'>Lorem ipsum.</Text>
        <Text variant='h6'>Lorem ipsum.</Text>
        <Text variant='h5'>Lorem ipsum.</Text>
        <Text variant='h4'>Lorem ipsum.</Text>
        <Text variant='h3'>Lorem ipsum.</Text>
        <Text variant='h2'>Lorem ipsum.</Text>
        <Text variant='h1'>Lorem ipsum.</Text>

        <Text variant='p' weight={400} className='mt-4'>
          Weight 400.
        </Text>
        <Text variant='p' weight={500}>
          Weight 500.
        </Text>
        <Text variant='p' weight={600}>
          Weight 600.
        </Text>
        <Text variant='p' weight={700}>
          Weight 700.
        </Text>
        <Text variant='p' weight={800}>
          Weight 800.
        </Text>
        <Text variant='p' weight={900}>
          Weight 900.
        </Text>

        <Text variant='p' color='white' className='mt-4 w-16 bg-black'>
          White.
        </Text>
        <Text variant='p' color='darkGray'>
          Dark gray.
        </Text>
        <Text variant='p' color='gray'>
          Gray.
        </Text>
        <Text variant='p' color='lightGray'>
          Light gray.
        </Text>
        <Text variant='p' color='lightestGray'>
          Lightest gray.
        </Text>
        <Text variant='p' color='primary'>
          Primary.
        </Text>
      </DesignSystem>
      <DesignSystem title='Tabs'>
        <div>
          <Tabs
            size='xs'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            size='s'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            size='m'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            size='l'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            size='xl'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />

          <Tabs
            variant='secondary'
            size='xs'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='secondary'
            size='s'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='secondary'
            size='m'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='secondary'
            size='l'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='secondary'
            size='xl'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />

          <Tabs
            variant='white'
            size='xs'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='white'
            size='s'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='white'
            size='m'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='white'
            size='l'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />
          <Tabs
            variant='white'
            size='xl'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
          />

          <Tabs
            size='m'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
            rounded
          />

          <Tabs
            size='m'
            options={tabsOptions}
            value={tabSelected}
            onChange={setTabSelected}
            className='m-2'
            maxRowLength={1}
          />
        </div>
      </DesignSystem>

      <DesignSystem title='Breadcrumbs'>
        <Breadcrumbs
          size='s'
          items={[
            { label: 'Normal link', href: '#' },
            {
              label: 'Link button',
              onClick: () => {
                alert('Breadcrumb clicked')
              },
            },
            { label: 'Text component' },
          ]}
        />

        <Breadcrumbs
          size='m'
          items={[
            { label: 'Normal link', href: '#' },
            {
              label: 'Link button',
              onClick: () => {
                alert('Breadcrumb clicked')
              },
            },
            { label: 'Text component' },
          ]}
        />

        <div className='flex flex-col space-y-2 rounded-md bg-gray-100 p-2'>
          <Breadcrumbs
            size='s'
            variant='secondary'
            items={[
              { label: 'Normal link', href: '#' },
              {
                label: 'Link button',
                onClick: () => {
                  alert('Breadcrumb clicked')
                },
              },
              { label: 'Text component' },
            ]}
          />

          <Breadcrumbs
            size='m'
            variant='secondary'
            items={[
              { label: 'Normal link', href: '#' },
              {
                label: 'Link button',
                onClick: () => {
                  alert('Breadcrumb clicked')
                },
              },
              { label: 'Text component' },
            ]}
          />

          <Breadcrumbs size='s' variant='secondary' items={[{ label: 'Text Component' }]} />

          <Breadcrumbs size='m' variant='secondary' items={[{ label: 'Text Component' }]} />
        </div>
      </DesignSystem>

      <DesignSystem title='Button'>
        <div>
          <Button size='xs' className='m-2'>
            Primary
          </Button>
          <Button size='s' className='m-2'>
            Primary
          </Button>
          <Button size='m' className='m-2' disabled>
            Primary
          </Button>
          <Button size='l' className='m-2'>
            Primary
          </Button>
          <Button size='xl' className='m-2'>
            Primary
          </Button>
        </div>

        <div>
          <Button variant='secondary' size='xs' className='m-2'>
            Secondary
          </Button>
          <Button variant='secondary' size='s' className='m-2'>
            Secondary
          </Button>
          <Button variant='secondary' size='m' className='m-2' disabled>
            Secondary
          </Button>
          <Button variant='secondary' size='l' className='m-2'>
            Secondary
          </Button>
          <Button variant='secondary' size='xl' className='m-2'>
            Secondary
          </Button>
        </div>

        <div>
          <Button variant='white' size='xs' className='m-2'>
            White
          </Button>
          <Button variant='white' size='s' className='m-2'>
            White
          </Button>
          <Button variant='white' size='m' className='m-2' disabled>
            White
          </Button>
          <Button variant='white' size='l' className='m-2'>
            White
          </Button>
          <Button variant='white' size='xl' className='m-2'>
            White
          </Button>
        </div>

        <div>
          <Button variant='ghost' size='xs' className='m-2'>
            ghost
          </Button>
          <Button variant='ghost' size='s' className='m-2'>
            ghost
          </Button>
          <Button variant='ghost' size='m' className='m-2' disabled>
            ghost
          </Button>
          <Button variant='ghost' size='l' className='m-2'>
            ghost
          </Button>
          <Button variant='ghost' size='xl' className='m-2'>
            ghost
          </Button>
        </div>

        <div>
          <Button variant='destructive' size='xs' className='m-2'>
            Destructive
          </Button>
          <Button variant='destructive' size='s' className='m-2'>
            Destructive
          </Button>
          <Button variant='destructive' size='m' className='m-2' disabled>
            Destructive
          </Button>
          <Button variant='destructive' size='l' className='m-2'>
            Destructive
          </Button>
          <Button variant='destructive' size='xl' className='m-2'>
            Destructive
          </Button>
        </div>

        <div>
          <Button variant='success' size='xs' className='m-2'>
            Success
          </Button>
          <Button variant='success' size='s' className='m-2'>
            Success
          </Button>
          <Button variant='success' size='m' className='m-2' disabled>
            Success
          </Button>
          <Button variant='success' size='l' className='m-2'>
            Success
          </Button>
          <Button variant='success' size='xl' className='m-2'>
            Success
          </Button>
        </div>

        <div>
          <Button size='m' className='m-2'>
            Primary
          </Button>

          <Button size='m' disabled className='m-2'>
            Primary
          </Button>

          <Button size='m' iconStart={<UserIcon />} className='m-2'>
            Primary
          </Button>

          <Button size='m' iconEnd={<UserIcon />} className='m-2'>
            Primary
          </Button>

          <Button size='m' loading className='m-2'>
            Primary
          </Button>

          <Button size='m' loading disabled className='m-2'>
            Primary
          </Button>

          <Button size='m' rounded className='m-2'>
            Primary
          </Button>
        </div>
      </DesignSystem>

      <DesignSystem title='Icon buttons'>
        <div>
          <IconButton icon={<UserIcon />} size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} size='s' className='m-2' />
          <IconButton icon={<UserIcon />} size='m' className='m-2' />
          <IconButton icon={<UserIcon />} size='l' className='m-2' />
          <IconButton icon={<UserIcon />} size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} variant='secondary' size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} variant='secondary' size='s' className='m-2' />
          <IconButton icon={<UserIcon />} variant='secondary' size='m' className='m-2' />
          <IconButton icon={<UserIcon />} variant='secondary' size='l' className='m-2' />
          <IconButton icon={<UserIcon />} variant='secondary' size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} variant='white' size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} variant='white' size='s' className='m-2' />
          <IconButton icon={<UserIcon />} variant='white' size='m' className='m-2' />
          <IconButton icon={<UserIcon />} variant='white' size='l' className='m-2' />
          <IconButton icon={<UserIcon />} variant='white' size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} variant='ghost' size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} variant='ghost' size='s' className='m-2' />
          <IconButton icon={<UserIcon />} variant='ghost' size='m' className='m-2' />
          <IconButton icon={<UserIcon />} variant='ghost' size='l' className='m-2' />
          <IconButton icon={<UserIcon />} variant='ghost' size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} variant='destructive' size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} variant='destructive' size='s' className='m-2' />
          <IconButton icon={<UserIcon />} variant='destructive' size='m' className='m-2' />
          <IconButton icon={<UserIcon />} variant='destructive' size='l' className='m-2' />
          <IconButton icon={<UserIcon />} variant='destructive' size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} variant='success' size='xs' className='m-2' />
          <IconButton icon={<UserIcon />} variant='success' size='s' className='m-2' />
          <IconButton icon={<UserIcon />} variant='success' size='m' className='m-2' />
          <IconButton icon={<UserIcon />} variant='success' size='l' className='m-2' />
          <IconButton icon={<UserIcon />} variant='success' size='xl' className='m-2' />
        </div>
        <div>
          <IconButton icon={<UserIcon />} size='m' className='m-2' />
          <IconButton icon={<UserIcon />} size='m' className='m-2' disabled />
          <IconButton icon={<UserIcon />} size='m' className='m-2' loading />
          <IconButton icon={<UserIcon />} size='m' className='m-2' loading disabled />
          <IconButton icon={<UserIcon />} size='m' className='m-2' rounded />
        </div>
      </DesignSystem>

      <DesignSystem title='KBD'>
        <Text>
          Press <KBD>CTRL</KBD> + <KBD>S</KBD> to save
        </Text>
      </DesignSystem>

      <DesignSystem title='Link'>
        <Text>
          Press{' '}
          <Link href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank'>
            here
          </Link>{' '}
          for magic
        </Text>

        <Text>
          Press{' '}
          <Link href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank' underline>
            here
          </Link>{' '}
          for magic
        </Text>
      </DesignSystem>

      <DesignSystem title='Link Buttons'>
        <Text size='xs'>
          Size XS{' '}
          <LinkButton size='xs' onClick={() => alert('Clicked a Link Button')}>
            Link button
          </LinkButton>
        </Text>
        <Text size='s'>
          Size S{' '}
          <LinkButton size='s' onClick={() => alert('Clicked a Link Button')}>
            Link button
          </LinkButton>
        </Text>
        <Text>
          Size MD{' '}
          <LinkButton onClick={() => alert('Clicked a Link Button')}>Link button</LinkButton>
        </Text>
        <Text size='l'>
          Size L{' '}
          <LinkButton size='l' onClick={() => alert('Clicked a Link Button')}>
            Link button
          </LinkButton>
        </Text>
        <Text size='xl'>
          Size XL{' '}
          <LinkButton size='xl' onClick={() => alert('Clicked a Link Button')}>
            Link button
          </LinkButton>
        </Text>

        <Text>
          Underline{' '}
          <LinkButton onClick={() => alert('Clicked a Link Button')} underline>
            Link button
          </LinkButton>
        </Text>

        <Text>
          Disabled{' '}
          <LinkButton onClick={() => alert('Clicked a Link Button')} disabled>
            Link button
          </LinkButton>
        </Text>
      </DesignSystem>

      <DesignSystem title='Progressbar'>
        <ProgressBar value={37} size='s' className='m-2' />
        <ProgressBar value={37} size='s' className='m-2' rounded />

        <ProgressBar value={37} size='m' className='m-2' />
        <ProgressBar value={37} size='m' className='m-2' rounded />

        <ProgressBar value={37} size='l' className='m-2' />
        <ProgressBar value={37} size='l' className='m-2' rounded />

        <ProgressBar value={37} size='xl' className='m-2' />
        <ProgressBar value={37} size='xl' className='m-2' rounded />
      </DesignSystem>

      <DesignSystem title='Badge'>
        <Badge size='s' variant='purple' className='m-2'>
          small
        </Badge>

        <Badge size='m' variant='purple' className='m-2'>
          medium
        </Badge>

        <Badge size='l' variant='purple' className='m-2'>
          large
        </Badge>

        <Badge size='s' variant='purple' className='m-2' rounded>
          small
        </Badge>

        <Badge size='m' variant='purple' className='m-2' rounded>
          medium
        </Badge>

        <Badge size='l' variant='purple' className='m-2' rounded>
          large
        </Badge>

        <br />

        <Badge size='s' variant='purple' className='m-2' onClick={() => console.log('onClick')}>
          onClick
        </Badge>

        <Badge size='m' variant='purple' className='m-2' onClick={() => console.log('onClick')}>
          onClick
        </Badge>

        <Badge size='l' variant='purple' className='m-2' onClick={() => console.log('onClick')}>
          onClick
        </Badge>

        <Badge
          size='s'
          variant='purple'
          className='m-2'
          rounded
          onClick={() => console.log('onClick')}
        >
          onClick
        </Badge>

        <Badge
          size='m'
          variant='purple'
          className='m-2'
          rounded
          onClick={() => console.log('onClick')}
        >
          onClick
        </Badge>

        <Badge
          size='l'
          variant='purple'
          className='m-2'
          rounded
          onClick={() => console.log('onClick')}
        >
          onClick
        </Badge>

        <br />

        <Badge size='s' variant='purple' className='m-2' icon={<FunnelIcon />}>
          icon
        </Badge>

        <Badge size='m' variant='purple' className='m-2' icon={<FunnelIcon />}>
          icon
        </Badge>

        <Badge size='l' variant='purple' className='m-2' icon={<TrashIcon />}>
          icon
        </Badge>

        <Badge size='s' variant='purple' className='m-2' rounded icon={<TrashIcon />}>
          icon
        </Badge>

        <Badge size='m' variant='purple' className='m-2' rounded icon={<UserIcon />}>
          icon
        </Badge>

        <Badge size='l' variant='purple' className='m-2' rounded icon={<UserIcon />}>
          icon
        </Badge>

        <br />

        <Badge
          size='s'
          variant='purple'
          className='m-2'
          icon={<FunnelIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <Badge
          size='m'
          variant='purple'
          className='m-2'
          icon={<FunnelIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <Badge
          size='l'
          variant='purple'
          className='m-2'
          icon={<TrashIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <Badge
          size='s'
          variant='purple'
          className='m-2'
          rounded
          icon={<TrashIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <Badge
          size='m'
          variant='purple'
          className='m-2'
          rounded
          icon={<UserIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <Badge
          size='l'
          variant='purple'
          className='m-2'
          rounded
          icon={<UserIcon />}
          onClick={() => console.log('onClick')}
        >
          icon & click
        </Badge>

        <br />

        <Badge variant='red' className='m-2'>
          red
        </Badge>

        <Badge variant='yellow' className='m-2'>
          yellow
        </Badge>

        <Badge variant='orange' className='m-2'>
          orange
        </Badge>

        <Badge variant='green' className='m-2'>
          green
        </Badge>

        <Badge variant='lime' className='m-2'>
          lime
        </Badge>

        <Badge variant='blue' className='m-2'>
          blue
        </Badge>

        <Badge variant='cyan' className='m-2'>
          cyan
        </Badge>

        <Badge variant='indigo' className='m-2'>
          indigo
        </Badge>

        <Badge variant='purple' className='m-2'>
          purple
        </Badge>

        <Badge variant='pink' className='m-2'>
          pink
        </Badge>

        <br />

        <Badge variant='red' className='m-2' onClick={() => console.log('red')}>
          red
        </Badge>

        <Badge variant='yellow' className='m-2' onClick={() => console.log('yellow')}>
          yellow
        </Badge>

        <Badge variant='orange' className='m-2' onClick={() => console.log('orange')}>
          orange
        </Badge>

        <Badge variant='green' className='m-2' onClick={() => console.log('green')}>
          green
        </Badge>

        <Badge variant='lime' className='m-2' onClick={() => console.log('lime')}>
          lime
        </Badge>

        <Badge variant='blue' className='m-2' onClick={() => console.log('blue')}>
          blue
        </Badge>

        <Badge variant='cyan' className='m-2' onClick={() => console.log('cyan')}>
          cyan
        </Badge>

        <Badge variant='indigo' className='m-2' onClick={() => console.log('indigo')}>
          indigo
        </Badge>

        <Badge variant='purple' className='m-2' onClick={() => console.log('purple')}>
          purple
        </Badge>

        <Badge variant='pink' className='m-2' onClick={() => console.log('pink')}>
          pink
        </Badge>
      </DesignSystem>

      <DesignSystem title='Avatar'>
        <Avatar placeholder size='xs' className='m-2' />
        <Avatar placeholder size='s' className='m-2' />
        <Avatar placeholder size='m' className='m-2' />
        <Avatar placeholder size='l' className='m-2' />
        <Avatar placeholder size='xl' className='m-2' />
        <Avatar placeholder size='xs' className='m-2' rounded />
        <Avatar placeholder size='s' className='m-2' rounded />
        <Avatar placeholder size='m' className='m-2' rounded />
        <Avatar placeholder size='l' className='m-2' rounded />
        <Avatar placeholder size='xl' className='m-2' rounded />

        <Avatar variant='gray' className='m-2' rounded>
          <UserIcon className='size-6' />
        </Avatar>

        <Avatar variant='default' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='gray' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='red' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='yellow' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='amber' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='orange' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='green' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='emerald' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='teal' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='lime' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='blue' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='cyan' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='sky' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='indigo' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='purple' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='fuchsia' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='violet' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='pink' className='m-2'>
          JK
        </Avatar>
        <Avatar variant='rose' className='m-2'>
          JK
        </Avatar>
      </DesignSystem>

      <DesignSystem title='Tooltip'>
        <div className='space-x-4 space-y-4'>
          <Tooltip text='Primary' variant='primary'>
            <Button size='xs'>PRIMARY</Button>
          </Tooltip>
          <Tooltip text='Info' variant='info'>
            <Button size='xs'>INFO</Button>
          </Tooltip>
          <Tooltip text='Question' variant='question'>
            <Button size='xs'>QUESTION</Button>
          </Tooltip>
          <Tooltip label='Did you know...' text='You can also have a nice label on top?' size='l'>
            <Button size='xs'>LABELED</Button>
          </Tooltip>
          <Tooltip
            text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non velit ac quam placerat tempus. Donec et lacus varius nisl egestas auctor nec sed tellus. Vivamus tempus porta ullamcorper. Vestibulum ipsum enim, volutpat a laoreet et, consequat at nulla. Proin pulvinar libero in suscipit facilisis. Nam porttitor tincidunt dolor, commodo congue sapien venenatis eu. Nullam sed risus eu diam auctor commodo id et arcu. Praesent egestas blandit bibendum. Nam eu nibh tortor. Proin pretium, elit ac ullamcorper finibus, metus tortor dignissim sapien, nec laoreet sem ante vitae arcu. Mauris aliquam magna in lectus vulputate vulputate. Nunc venenatis sapien sit amet arcu dignissim iaculis. Quisque in lacinia tellus. Suspendisse ornare rutrum consequat. Vestibulum mollis neque nisi, in finibus ante aliquam eu. Quisque vel enim dolor.'
            size='4xl'
          >
            <Button size='xs'>LONG TEXT</Button>
          </Tooltip>
        </div>

        <div className='space-x-4 space-y-4'>
          <Tooltip text='Herra hoveri!' placement='t'>
            <Button size='xs'>TOP</Button>
          </Tooltip>
          <Tooltip text='Herra hoveri!' placement='r'>
            <Button size='xs'>RIGHT</Button>
          </Tooltip>
          <Tooltip text='Herra hoveri!' placement='b'>
            <Button size='xs'>BOTTOM</Button>
          </Tooltip>
          <Tooltip text='Herra hoveri!' placement='l'>
            <Button size='xs'>LEFT</Button>
          </Tooltip>
        </div>

        <div className='space-x-4 space-y-4'>
          <Tooltip text='Dark Gray' color='darkGray'>
            <Button size='xs'>DARK GRAY</Button>
          </Tooltip>
          <Tooltip text='Gray' color='gray'>
            <Button size='xs'>GRAY</Button>
          </Tooltip>
          <Tooltip text='Light Gray' color='lightGray'>
            <Button size='xs'>LIGHT GRAY</Button>
          </Tooltip>
          <Tooltip text='Lightest Gray' color='lightestGray'>
            <Button size='xs'>LIGHTEST GRAY</Button>
          </Tooltip>
          <Tooltip text='White' color='white'>
            <Button size='xs'>WHITE</Button>
          </Tooltip>
          <Tooltip text='Primary' color='primary'>
            <Button size='xs'>PRIMARY</Button>
          </Tooltip>
        </div>

        <div className='space-x-4 space-y-4'>
          <Tooltip text='XS' size='xs'>
            <Button size='xs'>XS</Button>
          </Tooltip>
          <Tooltip text='S' size='s'>
            <Button size='xs'>S</Button>
          </Tooltip>
          <Tooltip text='M' size='m'>
            <Button size='xs'>M</Button>
          </Tooltip>
          <Tooltip text='L' size='l'>
            <Button size='xs'>L</Button>
          </Tooltip>
          <Tooltip text='XL' size='xl'>
            <Button size='xs'>XL</Button>
          </Tooltip>
          <Tooltip text='2XL' size='2xl'>
            <Button size='xs'>2XL</Button>
          </Tooltip>
          <Tooltip text='3XL' size='3xl'>
            <Button size='xs'>3XL</Button>
          </Tooltip>
          <Tooltip text='4XL' size='4xl'>
            <Button size='xs'>4XL</Button>
          </Tooltip>
          <Tooltip text='5XL' size='5xl'>
            <Button size='xs'>5XL</Button>
          </Tooltip>
          <Tooltip text='6XL' size='6xl'>
            <Button size='xs'>6XL</Button>
          </Tooltip>
          <Tooltip text='7XL' size='7xl'>
            <Button size='xs'>7XL</Button>
          </Tooltip>
          <Tooltip text='8XL' size='8xl'>
            <Button size='xs'>8XL</Button>
          </Tooltip>
          <Tooltip text='9XL' size='9xl'>
            <Button size='xs'>9XL</Button>
          </Tooltip>
        </div>
      </DesignSystem>

      <DesignSystem title='Bar charts'>
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <Text variant='h4' className='mb-2'>
              Minimally configured chart
            </Text>
            <BarChart data={chartData} unit='h' />
          </div>
          <div>
            <Text variant='h4' className='mb-2'>
              chartColor, borderRadius, onClick, and legend
            </Text>
            <BarChart
              data={chartData}
              unit='h'
              chartColor='#31C48D'
              onClick={() => alert('Bar clicked')}
              borderRadius={16}
              legend={[{ label: 'Applications', color: '#31C48D' }]}
            />
          </div>

          <div>
            <Text variant='h4' className='mb-2'>
              Multiple colors
            </Text>
            <BarChart data={chartDataColors} unit='h' legend={chartDataColorsLegend} />
          </div>

          <div>
            <Text variant='h4' className='mb-2'>
              size, tooltipCallback, tickAmount, hideGrid
            </Text>
            <BarChart
              data={chartData}
              unit='h'
              width='450px'
              height='200px'
              tickAmount={8}
              tooltipCallback={(tooltipItem) => [
                `${tooltipItem.label}: ${tooltipItem.formattedValue}`,
                `${chartData[tooltipItem.dataIndex].description}`,
              ]}
            />
            <BarChart data={chartData} unit='h' width='450px' height='200px' hideGrid />
          </div>
          <div>
            <Text variant='h4' className='mb-2'>
              Overflow legend
            </Text>
            <BarChart data={chartData} unit='h' legend={chartLongLegend} />
          </div>

          <div>
            <Text variant='h4' className='mb-2'>
              Grouped bar chart
            </Text>
            <GroupedBarChart data={groupedChartData} unit='h' />
          </div>
        </div>
      </DesignSystem>

      <DesignSystem title='Visualization table'>
        <DataDisplayTable
          head={['Application', 'time']}
          body={convertChartDataToTableBody(chartData, 'h')}
          visualizationValues={convertChartDataToVisualizationValues(chartData)}
        />
        <div className='h-3' />
        <DataDisplayTable
          head={visualizationTableHead}
          body={visualizationTableBody}
          visualizationValues={visualizationTablePercentageValues}
        />
      </DesignSystem>

      <DesignSystem title='Visualization containers'>
        <VisualizationContainer
          label='Application usage'
          description='See how much time is spent with different applications'
          toggleActions={[<Toggle key='toggle' value={toggle} onChange={setToggle} />]}
          selectActions={[
            <Select
              key='select'
              size='xs'
              options={selectOptionsNew}
              value={selectSelected}
              onChange={setSelectSelected}
            />,
          ]}
          otherActions={[
            <Tabs
              key='tabs'
              variant='secondary'
              size='xs'
              options={chartTableTabOptions}
              value={vizContainerTabValue}
              onChange={setVizContainerTabValue}
            />,
          ]}
        >
          {vizContainerTabValue === chartTableTabOptions[0].value ? (
            <BarChart data={chartData} unit='h' />
          ) : (
            <DataDisplayTable
              head={['Application', 'time']}
              body={convertChartDataToTableBody(chartData, 'h')}
              visualizationValues={convertChartDataToVisualizationValues(chartData)}
            />
          )}
        </VisualizationContainer>

        <BarChartAndTableDisplay
          label='Application usage'
          data={chartData}
          unit='h'
          tableHeaderValues={['Application', 'Time']}
          onClick={() => alert('Clicked!')}
          isExportable
        />
      </DesignSystem>

      <DesignSystem title='Visualization table'>
        <ContentDisplay className='px-5 py-4'>
          <Text size='l' weight={600} className='mb-2'>
            Title
          </Text>
          <HorizontalBar data={chartData} unit='h' showLabels />
        </ContentDisplay>
      </DesignSystem>
    </div>
  )
}

const DesignSystem = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <>
      <div>
        <Text variant='h2' className='mb-2 mt-10'>
          {title}
        </Text>

        {children}
      </div>

      <Divider className='my-6' />
    </>
  )
}

export default Sandbox
