import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useGetUserManagementListQuery, useSendUserIntivationMutation } from '../../app/apiSlice'
import { defaultUserRole } from '../../app/constants'
import { Button, InputField, Modal, Select } from '../../common/designs'

const formSchema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .max(254, 'Email is over 254 characters long')
      .required('Email is a required field'),
    role: yup.number().required('Role is a required field'),
  })
  .required()

type Inputs = {
  email: string
  role: number
}

interface Props {
  open: boolean
  onClose: () => void
}

const AddNewUserModal = ({ open, onClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: { role: defaultUserRole },
  })

  const { data, isLoading } = useGetUserManagementListQuery()
  const [sendInvitation] = useSendUserIntivationMutation()

  const onSend: SubmitHandler<Inputs> = ({ email, role }: Inputs) => {
    sendInvitation({ email, role })
      .unwrap()
      .then(() => {
        onClose()
        reset()
      })
  }

  return (
    <Modal label='Add a new user' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSend)} className='space-y-4'>
        <Controller
          name='role'
          control={control}
          render={({ field }) => (
            <Select
              label='Role'
              options={
                data?.roleOptions
                  ? Object.entries(data.roleOptions).map(([id, name]) => ({
                      label: name,
                      value: parseInt(id),
                    }))
                  : []
              }
              error={errors.role?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <InputField
              autoFocus
              label='Email address'
              autoComplete='off'
              error={errors.email?.message}
              className='mb-2'
              {...field}
            />
          )}
        />

        <div className='mt-2 flex justify-end gap-4'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit' loading={isLoading}>
            Send invite
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddNewUserModal
