import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { useGetCasesForTrendsDropdownQuery } from '../../../app/apiSlice'
import {
  useCreateCaseTrendMutation,
  useCreateCompanyTrendMutation,
  useCreateUserTrendMutation,
} from '../../../app/dataTrendsApiSlice'
import type { StatDetails } from '../../../common/components/displays/Stats'
import { Button, InputField, Modal, Select } from '../../../common/designs'
import { useDashboard } from '../../../common/hooks'
import { FavoriteTypeEnum } from '../../../features/Favorites/types'

const formSchema = yup
  .object({
    favoriteType: yup.string().required('Favorite type is required field'),
    nameTag: yup.string().max(128, 'Name tag is over 128 characters long'), // Not sure about the theoretical max length
    caseId: yup.number().when('favoriteType', (favoriteType, schema) => {
      // Validates that favoriteType really is an enum value
      if (Number(favoriteType) !== FavoriteTypeEnum.OPPORTUNITY) return schema

      return yup.number().required('No case has been selected')
    }),
  })
  .required()

type Inputs = {
  nameTag?: string | undefined
  caseId?: number | undefined
  favoriteType: string
}

interface Props {
  open: boolean
  onClose: () => void
  details: StatDetails | null
}

const StatSaveModal = ({ open, onClose, details }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      favoriteType: FavoriteTypeEnum.PRIVATE.toString(),
      nameTag: '',
      caseId: undefined,
    },
  })
  const { user } = useDashboard()

  const [selected, setSelected] = useState<FavoriteTypeEnum>(FavoriteTypeEnum.PRIVATE)

  const getCasesForTrendsDropdownQuery = useGetCasesForTrendsDropdownQuery()
  const [createCompanyTrend] = useCreateCompanyTrendMutation()
  const [createUserTrend] = useCreateUserTrendMutation()
  const [createCaseTrendForCase] = useCreateCaseTrendMutation()

  // Watches the value of favoriteType changing which triggers useEffect
  const selectedType = watch('favoriteType')
  useEffect(() => setSelected(Number(selectedType) as FavoriteTypeEnum), [selectedType]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFavoriteSave = async (nameTag: string | null | undefined, caseId?: number) => {
    const params = details?.params

    if (!params) return

    const favoriteData = {
      custom_name: nameTag,
      automatic_name: params.label,
      url: params.url,
      query_options: params.queryOptions,
      data_key: params.dataKey,
      unit: params.unit,
    }

    onClose()

    try {
      if (selected === FavoriteTypeEnum.PRIVATE) await createUserTrend(favoriteData)
      if (selected === FavoriteTypeEnum.COMPANY) await createCompanyTrend(favoriteData).unwrap()
      if (selected === FavoriteTypeEnum.OPPORTUNITY)
        await createCaseTrendForCase({ ...favoriteData, caseId })

      toast.success('Favorite saved successfully')
    } catch (err) {
      toast.error('Error saving favorite')
    }
  }

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const { nameTag, caseId } = data
    onFavoriteSave(nameTag, caseId)
    reset()
  }

  return (
    <Modal
      label={`Save favorite "${details?.label ?? ''}"`}
      open={open}
      onClose={() => {
        onClose()
        reset()
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
        <Controller
          name='favoriteType'
          control={control}
          render={({ field }) => (
            <Select
              label='Save location'
              error={errors.favoriteType?.message}
              options={
                // Personal shown to everyone, company only to admins, case to contributors/admins
                [
                  { label: 'Personal favorites', value: FavoriteTypeEnum.PRIVATE },
                  ...(user.isAdmin
                    ? [{ label: 'Company favorites', value: FavoriteTypeEnum.COMPANY }]
                    : []),
                  ...(user.isDataReader
                    ? []
                    : [{ label: 'Opportunity favorites', value: FavoriteTypeEnum.OPPORTUNITY }]),
                ]
              }
              {...field}
            />
          )}
        />

        {selected === FavoriteTypeEnum.OPPORTUNITY && (
          <Controller
            name='caseId'
            control={control}
            render={({ field }) => (
              // @ts-ignore
              <Select
                label='Link to an opportunity'
                options={
                  getCasesForTrendsDropdownQuery?.data?.map((c) => {
                    return {
                      label: `#${c.id} - ${c.title}`,
                      value: c.id,
                    }
                  }) ?? []
                }
                error={errors?.caseId?.message}
                {...field}
              />
            )}
          />
        )}

        <Controller
          name='nameTag'
          control={control}
          render={({ field }) => (
            <InputField label='Name' error={errors.nameTag?.message} {...field} />
          )}
        />

        <div className='mt-4 flex justify-end gap-4'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit'>Save</Button>
        </div>
      </form>
    </Modal>
  )
}

export default StatSaveModal

