import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'

import type { BasicColorIndex } from '../../app/theme'

const COLORS_200 = [
  { label: 'Gray', value: 'bg-gray-200', ringValue: 'ring-gray-200' },
  { label: 'Red', value: 'bg-red-200', ringValue: 'ring-red-200' },
  { label: 'Yellow', value: 'bg-yellow-200', ringValue: 'ring-yellow-200' },
  { label: 'Orange', value: 'bg-orange-200', ringValue: 'ring-orange-200' },
  { label: 'Green', value: 'bg-green-200', ringValue: 'ring-green-200' },
  { label: 'Lime', value: 'bg-lime-200', ringValue: 'ring-lime-200' },
  { label: 'Blue', value: 'bg-blue-200', ringValue: 'ring-blue-200' },
  { label: 'Cyan', value: 'bg-cyan-200', ringValue: 'ring-cyan-200' },
  { label: 'Indigo', value: 'bg-indigo-200', ringValue: 'ring-indigo-200' },
  { label: 'Purple', value: 'bg-purple-200', ringValue: 'ring-purple-200' },
  { label: 'Pink', value: 'bg-pink-200', ringValue: 'ring-pink-200' },
]

const COLORS_300 = [
  { label: 'Gray', value: 'bg-gray-300', ringValue: 'ring-gray-300' },
  { label: 'Red', value: 'bg-red-300', ringValue: 'ring-red-300' },
  { label: 'Yellow', value: 'bg-yellow-300', ringValue: 'ring-yellow-300' },
  { label: 'Orange', value: 'bg-orange-300', ringValue: 'ring-orange-300' },
  { label: 'Green', value: 'bg-green-300', ringValue: 'ring-green-300' },
  { label: 'Lime', value: 'bg-lime-300', ringValue: 'ring-lime-300' },
  { label: 'Blue', value: 'bg-blue-300', ringValue: 'ring-blue-300' },
  { label: 'Cyan', value: 'bg-cyan-300', ringValue: 'ring-cyan-300' },
  { label: 'Indigo', value: 'bg-indigo-300', ringValue: 'ring-indigo-300' },
  { label: 'Purple', value: 'bg-purple-300', ringValue: 'ring-purple-300' },
  { label: 'Pink', value: 'bg-pink-300', ringValue: 'ring-pink-300' },
]

const COLORS_400 = [
  { label: 'Gray', value: 'bg-gray-400', ringValue: 'ring-gray-400' },
  { label: 'Red', value: 'bg-red-400', ringValue: 'ring-red-400' },
  { label: 'Yellow', value: 'bg-yellow-400', ringValue: 'ring-yellow-400' },
  { label: 'Orange', value: 'bg-orange-400', ringValue: 'ring-orange-400' },
  { label: 'Green', value: 'bg-green-400', ringValue: 'ring-green-400' },
  { label: 'Lime', value: 'bg-lime-400', ringValue: 'ring-lime-400' },
  { label: 'Blue', value: 'bg-blue-400', ringValue: 'ring-blue-400' },
  { label: 'Cyan', value: 'bg-cyan-400', ringValue: 'ring-cyan-400' },
  { label: 'Indigo', value: 'bg-indigo-400', ringValue: 'ring-indigo-400' },
  { label: 'Purple', value: 'bg-purple-400', ringValue: 'ring-purple-400' },
  { label: 'Pink', value: 'bg-pink-400', ringValue: 'ring-pink-400' },
]

const COLORS_500 = [
  { label: 'Gray', value: 'bg-gray-500', ringValue: 'ring-gray-500' },
  { label: 'Red', value: 'bg-red-500', ringValue: 'ring-red-500' },
  { label: 'Yellow', value: 'bg-yellow-500', ringValue: 'ring-yellow-500' },
  { label: 'Orange', value: 'bg-orange-500', ringValue: 'ring-orange-500' },
  { label: 'Green', value: 'bg-green-500', ringValue: 'ring-green-500' },
  { label: 'Lime', value: 'bg-lime-500', ringValue: 'ring-lime-500' },
  { label: 'Blue', value: 'bg-blue-500', ringValue: 'ring-blue-500' },
  { label: 'Cyan', value: 'bg-cyan-500', ringValue: 'ring-cyan-500' },
  { label: 'Indigo', value: 'bg-indigo-500', ringValue: 'ring-indigo-500' },
  { label: 'Purple', value: 'bg-purple-500', ringValue: 'ring-purple-500' },
  { label: 'Pink', value: 'bg-pink-500', ringValue: 'ring-pink-500' },
]

const colorOptionVariants: { [key in Variant]: any[] } = {
  200: COLORS_200,
  300: COLORS_300,
  400: COLORS_400,
  500: COLORS_500,
}

type Variant = 200 | 300 | 400 | 500
type Size = 'xs' | 's' | 'm' | 'l'

interface Props {
  value: BasicColorIndex
  onChange: (i: BasicColorIndex) => void

  variant?: Variant
  size?: Size
  label?: string
  rounded?: boolean
}

const sizeStyles: { [key in Size]: string } = {
  xs: 'size-4',
  s: 'size-6',
  m: 'size-8',
  l: 'size-10',
}

const ColorPicker = ({ value, onChange, variant = 300, size = 'm', label, rounded }: Props) => {
  const colorOptions = colorOptionVariants[variant]

  return (
    <RadioGroup
      value={colorOptions[value]}
      onChange={(o) =>
        onChange(colorOptions.findIndex((c) => c.value === o.value) as BasicColorIndex)
      }
    >
      {label && (
        <RadioGroup.Label className='block text-sm font-medium text-gray-700'>
          {label}
        </RadioGroup.Label>
      )}

      <div className={clsx(label && 'mt-1', 'flex items-center space-x-3')}>
        {colorOptions.map((color) => (
          <RadioGroup.Option
            key={color.label}
            value={color}
            className={({ active, checked }) =>
              clsx(
                color.ringValue,
                active && checked && 'ring ring-offset-1',
                !active && checked && 'ring-2',
                rounded ? 'rounded-full' : 'rounded-md',
                'relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none',
              )
            }
          >
            <RadioGroup.Label as='span' className='sr-only'>
              {color.label}
            </RadioGroup.Label>
            <span
              aria-hidden='true'
              className={clsx(
                color.value,
                sizeStyles[size],
                rounded ? 'rounded-full border-black/10' : 'rounded-md border-black/10',
                'border',
              )}
            />
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default ColorPicker
