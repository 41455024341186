import { laneColorMap } from '../../../../app/theme'

interface LaneColors {
  [key: number]: string
}

export const locationRange = 0.4 // Used to generate random locations for bubbles in chart
export const colorAlpha = 0.85
export const laneColors: LaneColors = {
  1: laneColorMap.lead,
  2: laneColorMap.validation,
  3: laneColorMap.specification,
  4: laneColorMap.implementation,
  5: laneColorMap.done,
}

export const scaleStringToNum = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
}
