import { useLottie } from 'lottie-react'

import loaderAnimationData from './loaderData.json'

const sizes = {
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  '2xl': '2xl',
  '3xl': '3xl',
  '4xl': '4xl',
  '5xl': '5xl',

  btn_xs: 'btn_xs',
  btn_s: 'btn_s',
  btn_m: 'btn_m',
  btn_l: 'btn_l',
  btn_xl: 'btn_xl',

  icon_btn_xs: 'icon_btn_xs',
  icon_btn_s: 'icon_btn_s',
  icon_btn_m: 'icon_btn_m',
  icon_btn_l: 'icon_btn_l',
  icon_btn_xl: 'icon_btn_xl',
} as const
type Size = (typeof sizes)[keyof typeof sizes]

const sizeToNumberMap = {
  [sizes.xs]: 16,
  [sizes.s]: 32,
  [sizes.m]: 64,
  [sizes.l]: 96,
  [sizes.xl]: 128,
  [sizes['2xl']]: 160,
  [sizes['3xl']]: 192,
  [sizes['4xl']]: 224,
  [sizes['5xl']]: 256,

  // Unique button loader sizes
  [sizes.btn_xs]: 16,
  [sizes.btn_s]: 16,
  [sizes.btn_m]: 20,
  [sizes.btn_l]: 24,
  [sizes.btn_xl]: 24,

  // Unique icon button loader sizes
  [sizes.icon_btn_xs]: 20,
  [sizes.icon_btn_s]: 20,
  [sizes.icon_btn_m]: 20,
  [sizes.icon_btn_l]: 24,
  [sizes.icon_btn_xl]: 24,
}

interface Props {
  size?: Size
  type?: 'button'
}

const InlineLoader = ({ size = sizes.m }: Props) => {
  const spinnerLottieOptions = {
    animationData: loaderAnimationData,
    loop: true,
    autoplay: true,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  }

  const spinnerStyle = {
    height: sizeToNumberMap[size],
    width: sizeToNumberMap[size],
  }

  return useLottie(spinnerLottieOptions, spinnerStyle).View
}

export default InlineLoader
