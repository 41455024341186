import { useCallback } from 'react'
import { toast } from 'react-toastify'

import {
  useGetDataCollectionConfigsQuery,
  useGetTestCasesQuery,
  useRunConfigTestsMutation,
  useUploadCompanyDbConfigsMutation,
  useUploadRequirementsMutation,
} from '../../app/apiSlice'
import { HTTPException } from '../../common/types/api'
import type { RuleIn } from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { ConfigTestCase, Rules } from './types'

interface UseManageServerTestCasesAndConfigsInterface {
  isInitializing: boolean

  createTestCases: (testCase: ConfigTestCase[]) => Promise<void>
  updateTestCases: (testCases: ConfigTestCase[]) => Promise<void>
  deleteTestCase: (testCaseId: string) => Promise<void>
  isUploadingTestCases: boolean

  createRules: (rule: RuleIn[]) => Promise<Rules | null>
  setRules: (rule: RuleIn[]) => Promise<Rules | null>
  updateRules: (rules: RuleIn[]) => Promise<RuleIn[]>
  deleteRule: (ruleId: string) => Promise<RuleIn[]>
  isUploadingRules: boolean

  runConfigTests: (rules?: RuleIn[], rulesVersion?: number) => Promise<void>
  isRunningConfigTests: boolean

  clearTestCasesAndRules: () => Promise<void>
}

const useManageServerTestCasesAndConfigs = (): UseManageServerTestCasesAndConfigsInterface => {
  const { data: requirementsData, isFetching: isFetchingRequirementsData } = useGetTestCasesQuery()
  const { data: ruleData, isFetching: isFetchingRuleData } = useGetDataCollectionConfigsQuery()

  const [uploadRequirements, { isLoading: isUploadingTestCases }] = useUploadRequirementsMutation()
  const [uploadRules, { isLoading: isUploadingRules }] = useUploadCompanyDbConfigsMutation()
  const [runConfigTestsRequest, { isLoading: isRunningConfigTests }] = useRunConfigTestsMutation()

  const createTestCases = useCallback(
    async (testCases: ConfigTestCase[]) => {
      if (!requirementsData) return

      const res = await uploadRequirements({
        ...requirementsData,
        test_cases: [...requirementsData.test_cases, ...testCases],
      })

      if ('error' in res) {
        const errorData = (res as HTTPException).error.data
        toast.error(errorData.detail ?? errorData.message)
      }
    },
    [requirementsData, uploadRequirements],
  )

  const updateTestCases = useCallback(
    async (testCases: ConfigTestCase[]) => {
      if (!requirementsData) return

      const copiedTestCases = [...requirementsData.test_cases]

      testCases.forEach((testCase) => {
        const updatedTestCaseIndex = requirementsData.test_cases.findIndex(
          (arrayTestCase) => arrayTestCase.test_id === testCase.test_id,
        )
        if (updatedTestCaseIndex === -1) return
        copiedTestCases[updatedTestCaseIndex] = testCase
      })

      await uploadRequirements({
        ...requirementsData,
        test_cases: copiedTestCases,
      })
    },
    [requirementsData, uploadRequirements],
  )

  const deleteTestCase = useCallback(
    async (testCaseId: string) => {
      if (!requirementsData) return

      const copiedTestCases = requirementsData.test_cases.filter(
        (arrayTestCase) => arrayTestCase.test_id !== testCaseId,
      )

      await uploadRequirements({
        ...requirementsData,
        test_cases: copiedTestCases,
      })
    },
    [requirementsData, uploadRequirements],
  )

  const createRules = useCallback(
    async (rules: RuleIn[]) => {
      const appRules: RuleIn[] = [...(ruleData?.rules ?? [])]
      appRules.push(...rules)

      const res = await uploadRules({
        rules: appRules,
      })

      if ('error' in res) {
        const errorData = (res as HTTPException).error.data
        toast.error(errorData.detail ?? errorData.message)
      } else if ('data' in res) {
        return { rules: appRules, ...res.data } as Rules
      }

      return null
    },
    [ruleData, uploadRules],
  )

  const setRules = useCallback(
    async (rules: RuleIn[]) => {
      const res = await uploadRules({
        rules,
      })

      if ('error' in res) {
        const errorData = (res as HTTPException).error.data
        toast.error(errorData.detail ?? errorData.message)
      } else if ('data' in res) {
        return { rules: rules, ...res.data } as Rules
      }

      return null
    },
    [uploadRules],
  )

  const updateRules = useCallback(
    async (rules: RuleIn[]) => {
      const appRules: RuleIn[] = [...(ruleData?.rules ?? [])]

      rules.forEach((rule) => {
        const updatedRuleIndex = appRules.findIndex((arrayRule) => arrayRule.id === rule.id)
        if (updatedRuleIndex === -1) return
        appRules[updatedRuleIndex] = rule
      })

      await uploadRules({
        rules: appRules,
      })

      return appRules
    },
    [ruleData, uploadRules],
  )

  const deleteRule = useCallback(
    async (ruleId: string) => {
      const appRules: RuleIn[] = [...(ruleData?.rules ?? [])]
      const newRules = appRules.filter((arrayRule) => arrayRule.id !== ruleId)

      await uploadRules({
        rules: newRules,
      })

      return newRules
    },
    [ruleData, uploadRules],
  )

  const runConfigTests = useCallback(
    async (rules?: RuleIn[], rulesVersion?: number) => {
      if (rules && rulesVersion) {
        await runConfigTestsRequest({ rules, settings_version: rulesVersion })
      } else {
        const appRules: RuleIn[] = [...(ruleData?.rules ?? [])]
        await runConfigTestsRequest({ rules: appRules, settings_version: ruleData?.version ?? 0 })
      }
    },
    [runConfigTestsRequest, ruleData],
  )

  const clearTestCasesAndRules = useCallback(async () => {
    await uploadRules({ rules: [] })
    await uploadRequirements({
      ...requirementsData,
      test_cases: [],
    })
  }, [uploadRules, uploadRequirements, requirementsData])

  return {
    createTestCases,
    updateTestCases,
    deleteTestCase,
    isUploadingTestCases,
    createRules,
    setRules,
    updateRules,
    deleteRule,
    isUploadingRules,
    isInitializing: isFetchingRequirementsData || isFetchingRuleData,
    runConfigTests,
    isRunningConfigTests,
    clearTestCasesAndRules,
  }
}

export default useManageServerTestCasesAndConfigs
