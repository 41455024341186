import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import { useGetTransactionLogQuery } from '../../../../app/apiSlice'
import { Button } from '../../../../common/designs'
import { useQueryFilters } from '../../../../common/hooks'
import { downloadFile, objArrayToCsv } from '../../../../common/utils/utils'

export const TransactionLog = () => {
  const { processId, processFilters: filters } = useQueryFilters()
  const [isGeneratingCsvFile, setIsGeneratingCsvFile] = useState<boolean>(false)

  const { data: logData, isFetching } = useGetTransactionLogQuery({
    processId,
    filters,
  })

  const onDownloadCsv = () => {
    if (!logData) return

    setIsGeneratingCsvFile(true)
    downloadFile(window.URL.createObjectURL(new Blob([objArrayToCsv(logData)])), `${processId}.csv`)
    setIsGeneratingCsvFile(false)
  }

  return (
    <div className='my-3'>
      <Button
        variant='white'
        iconStart={<ArrowDownTrayIcon />}
        loading={isFetching || isGeneratingCsvFile}
        disabled={!logData}
        onClick={onDownloadCsv}
      >
        Download Case Log CSV
      </Button>
    </div>
  )
}

export default TransactionLog
