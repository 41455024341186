import { useMemo } from 'react'

import { HorizontalBar, Stats } from '../../common/components'
import { Modal, Text } from '../../common/designs'
import {
  TimeUnitEnum,
  formatNumber,
  getBestTimeFormatForSeconds,
  getBestTimeFormatTransformationForSeconds,
} from '../../common/utils/numberFormatting'
import type { ServerTaskVisualizationNode } from './types'

interface Props {
  isOpen: boolean
  onClose: () => void
  data: ServerTaskVisualizationNode
}

export const TaskFlowNodeModal = ({ isOpen, onClose, data }: Props) => {
  const stats = useMemo(() => {
    const {
      step_visit_count,
      duration_sec,
      avg_duration_sec,
      step_copies,
      step_pastes,
      internal_copy_paste_count,
      keyboard_press_count,
    } = data

    return [
      [
        {
          value: formatNumber(step_visit_count, 1),
          label: 'Visits',
        },
        {
          value: getBestTimeFormatForSeconds(duration_sec),
          label: 'Total time in window',
        },
        {
          value: getBestTimeFormatForSeconds(avg_duration_sec),
          label: 'Average visit duration',
        },
      ],
      [
        {
          value: formatNumber(step_copies.length, 1),
          label: 'Copies from the task',
        },
        {
          value: formatNumber(step_pastes.length, 1),
          label: 'Pastes to the task',
        },
        {
          value: formatNumber(internal_copy_paste_count, 1),
          label: 'Internal copy pastes',
        },
      ],
      [
        {
          value: formatNumber(keyboard_press_count, 1),
          label: 'Keyboard press count',
        },
        {
          value: formatNumber(keyboard_press_count / step_visit_count, 100),
          label: 'Keyboard presses per visit',
        },
      ],
    ]
  }, [data])

  const { activityTimeData, unit } = useMemo(() => {
    const { activity_categorizations } = data
    const timeDataSorted = [...activity_categorizations].sort(
      (a, b) => b.duration_sec - a.duration_sec,
    )
    const { divider, unit } = getBestTimeFormatTransformationForSeconds(
      timeDataSorted[0].duration_sec,
      TimeUnitEnum.HOURS,
    )

    const activityTimeData = timeDataSorted.map((a) => ({
      label: a.activity_name,
      value: a.duration_sec / divider,
    }))

    return { activityTimeData, unit }
  }, [data])

  const activityCountData = useMemo(() => {
    return [...data.activity_categorizations]
      .sort((a, b) => b.activity_count - a.activity_count)
      .map((a) => ({
        label: a.activity_name,
        value: a.activity_count,
      }))
  }, [data])

  return (
    <Modal label={data.display_name} open={isOpen} onClose={onClose} size='6xl'>
      <>
        <Text size='xl' weight={600}>
          Metrics
        </Text>
        <div className='mb-3 flex flex-col gap-2'>
          {stats.map((data, i) => (
            <Stats key={`stat-index-${i}`} data={data} />
          ))}
        </div>

        <Text size='xl' weight={600}>
          Activities
        </Text>
        <Text>Activities by time</Text>
        <HorizontalBar data={activityTimeData} unit={unit} />

        <Text className='mt-2'>Activities by count</Text>
        <HorizontalBar data={activityCountData} unit='visits' />
      </>
    </Modal>
  )
}

export default TaskFlowNodeModal

