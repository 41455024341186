import { StarIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import type { NodeProps } from 'reactflow'

import { teamBgColorMap } from '../../../../app/theme'
import { Text } from '../../../../common/designs'
import { useDashboard } from '../../../../common/hooks'
import NodeHandleLayout from './NodeHandleLayout'
import type { ServerTeamFlowNode } from './types'

type OpacityMultiplier = 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1

const opacityMapper: { [key in OpacityMultiplier]: string } = {
  0.5: 'opacity-50',
  0.6: 'opacity-60',
  0.7: 'opacity-70',
  0.8: 'opacity-80',
  0.9: 'opacity-90',
  1: 'opacity-100',
}

const getColorStyles = (color_index: number, relativeShareOfTransactions: number) => {
  const opacityMultiplier = (Math.round((0.5 + 0.5 * relativeShareOfTransactions) * 10) /
    10) as OpacityMultiplier // Opacity cannot be less than 0.5

  return `${teamBgColorMap[color_index]} ${opacityMapper[opacityMultiplier]}`
}

const TeamFlowNode = ({ data }: NodeProps<ServerTeamFlowNode>) => {
  const {
    team_id,
    step_position,

    transactions,
    is_conformance_flow_node: isConformanceFlowNode,

    top_ports: topPorts,
    right_ports: rightPorts,
    bottom_ports: bottomPorts,
    left_ports: leftPorts,

    relative_share_of_transactions,
  } = data
  const { teams } = useDashboard()

  const team = teams[team_id as string]
  const activity = `${team?.name} | Touch ${step_position + 1}`

  if (!team) return null

  return (
    <NodeHandleLayout
      topPorts={topPorts}
      rightPorts={rightPorts}
      bottomPorts={bottomPorts}
      leftPorts={leftPorts}
      color={getColorStyles(team.colorIndex, relative_share_of_transactions)}
    >
      <div
        className={clsx(
          'flex rounded-lg p-1',
          getColorStyles(team.colorIndex, relative_share_of_transactions),
        )}
      >
        <div className='flex shrink grow flex-col items-center justify-center px-2 py-1'>
          <Text variant='h4' weight={500} className='mb-2'>
            {activity}
          </Text>
          <Text variant='h5' weight={700}>
            {transactions}
          </Text>
        </div>

        {isConformanceFlowNode && (
          <div className='relative right-1 top-0 ml-1'>
            <StarIcon className='text-white' />
          </div>
        )}
      </div>
    </NodeHandleLayout>
  )
}

export default TeamFlowNode
