import {
  BusinessPropertyFilters,
  ProcessMetricFilters,
  ProcessPropertyFilters,
  SupportingSystemFilters,
  UserInteractionFilters,
  ZoomingFilters,
} from '../../features/Processes/types'

export enum StorageNameEnum {
  TOKEN = 'token',
  USER_PROFILE = 'userProfile',
  SETTINGS = 'settings',
  PROCESS_FILTERS = 'processFilters',
  DASHBOARD = 'dashboard',
  IFRAME = 'iFrame',
}

export const storageVersionHistory = {
  [StorageNameEnum.TOKEN]: 1,
  [StorageNameEnum.USER_PROFILE]: 1,
  [StorageNameEnum.SETTINGS]: 2,
  [StorageNameEnum.PROCESS_FILTERS]: 1,
  [StorageNameEnum.DASHBOARD]: 1,
  [StorageNameEnum.IFRAME]: 1,
}

export enum ProcessFiltersStorageContentEnum {
  PROCESS_METRICS = 'processMetrics',
  PROCESS_PROPERTIES = 'processProperties',
  BUSINESS_PROPERTIES = 'businessProperties',
  SUPPORTING_SYSTEM = 'supportingSystem',
  USER_INTERACTION = 'interaction',
  ZOOMING = 'zooming',
}

export interface ProcessFiltersStorageContent {
  [ProcessFiltersStorageContentEnum.PROCESS_METRICS]?: ProcessMetricFilters
  [ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES]?: ProcessPropertyFilters
  [ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES]?: BusinessPropertyFilters
  [ProcessFiltersStorageContentEnum.SUPPORTING_SYSTEM]?: SupportingSystemFilters
  [ProcessFiltersStorageContentEnum.USER_INTERACTION]?: UserInteractionFilters
  [ProcessFiltersStorageContentEnum.ZOOMING]?: { zooming_filters?: ZoomingFilters }
}

