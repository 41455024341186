import type { Tag } from '../../common/types/common'
import { fromDateString } from '../../common/utils/dateTimeUtils'

export interface DataItem {
  annual_cost: number
  event_date: string
  id: string
  name: string
  tags: Tag[]
  team_count: number
  team_id: string | null
  teams: string[]
  time_h: number
  time_ms: number
  visits: number
}

export interface Data {
  totals: DataItem[]
  [key: string]: DataItem[]
}

export const intervalStringToObject = (intervalString: string): Interval => {
  try {
    const [startDateString, endDateString] = intervalString.split(' - ')

    return {
      start: fromDateString(startDateString),
      end: fromDateString(endDateString),
    }
  } catch (e) {
    throw Error(`Failed to parse interval from interval string: ${e}`)
  }
}
