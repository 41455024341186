import { HandThumbUpIcon } from '@heroicons/react/24/outline'
import { MouseEvent } from 'react'

import { useCreateLikeMutation, useDeleteLikeMutation } from '../../../../../app/apiSlice'
import { IconButton, Text } from '../../../../../common/designs'
import { useAppDispatch, useDashboard } from '../../../../../common/hooks'
import { addLike, removeLike } from '../../../casesSlice'
import type { Like } from '../../../types'

interface Props {
  caseId: number
  likes: Like[]
}

const LikeButton = ({ caseId, likes }: Props) => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()

  const [createLikeMutation] = useCreateLikeMutation()
  const [deleteLikeMutation] = useDeleteLikeMutation()

  const userLike = likes.find((l) => l.user_id === user.id)
  const hasUserLikedAlready = Boolean(userLike)

  const onLike = async (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation()

    if (userLike && hasUserLikedAlready) {
      deleteLikeMutation({ caseId, likeId: userLike.id })
        .unwrap()
        .then(() => dispatch(removeLike({ caseId, likeId: userLike.id })))
    } else {
      createLikeMutation({ caseId })
        .unwrap()
        .then((like) => dispatch(addLike({ caseId, like })))
    }
  }

  const disableLikeBtn = user.isDataReader

  return (
    <div className='mr-1 flex w-6 items-center'>
      <Text size='xs' color={hasUserLikedAlready ? 'primary' : 'darkGray'} className='mr-1 w-0.5'>
        {likes.length}
      </Text>

      <IconButton
        size='xs'
        variant='ghost'
        iconClassName={hasUserLikedAlready ? 'text-sky-600' : ''}
        icon={<HandThumbUpIcon />}
        disabled={disableLikeBtn}
        onClick={(e: any) => (disableLikeBtn ? () => e.stopPropagation() : onLike(e))}
        rounded
      />
    </div>
  )
}

export default LikeButton
