import { useEffect, useState } from 'react'

import { laneColorMap } from '../../../../app/theme'
import { useAppSelector } from '../../../../common/hooks'
import { changeHslaColorAlpha } from '../../../../common/utils/stringFormatting'
import {
  selectKanban,
  selectLaneIds,
  selectRoadmapEntitiesAsArrayFiltered,
  selectRoadmapIds,
} from '../../casesSlice'
import type { BubbleChartData, BubbleNode, Case } from '../../types'
import { BubbleChart, CaseTable, ColorExplanation } from './components'
import { colorAlpha, laneColors, locationRange, scaleStringToNum } from './utils'

const Roadmap = () => {
  const laneIds = useAppSelector(selectLaneIds)
  const roadmapIds = useAppSelector(selectRoadmapIds)
  const roadmapEntitiesAsArray = useAppSelector(selectRoadmapEntitiesAsArrayFiltered)
  const kanban = useAppSelector(selectKanban)

  const [chartData, setChartData] = useState<BubbleChartData>()

  useEffect(() => {
    if (roadmapIds && roadmapEntitiesAsArray) {
      initializeChartData()
    }
  }, [roadmapIds, roadmapEntitiesAsArray]) // eslint-disable-line react-hooks/exhaustive-deps

  const initializeChartData = () => {
    const maxAnnualisedBuzCaseArr: number[] = []
    roadmapEntitiesAsArray.forEach((c) => {
      maxAnnualisedBuzCaseArr.push(c.business_case_cost - c.implementation_cost)
    })
    const maxAnnualisedBuzCase = Math.max(...maxAnnualisedBuzCaseArr)

    const data: BubbleChartData = {
      datasets: [
        {
          label: 'Case',
          data: [],
          borderColor: [],
          backgroundColor: [],
        },
      ],
    }

    roadmapEntitiesAsArray.forEach((c) => {
      const annualisedBuzCase = c.business_case_cost - c.implementation_cost
      let color: string = laneColorMap.default

      if (c.id) {
        Object.entries(kanban).forEach(([key, ids]) => {
          if (ids.some((id) => id === c.id)) {
            color = laneColors[Number(key)]
          }
        })
      }

      addCaseToChartData(data, c, annualisedBuzCase, color, maxAnnualisedBuzCase)
    })

    setChartData(data)
  }

  const addCaseToChartData = (
    data: BubbleChartData,
    _case: Case,
    annualisedBuzCase: number,
    color: string,
    maxAnnualisedBuzCase: number,
  ) => {
    let priority = scaleStringToNum[_case.priority]
    let complexity = scaleStringToNum[_case.complexity]

    priority =
      Math.random() * (priority + locationRange - (priority - locationRange)) +
      (priority - locationRange)
    complexity =
      Math.random() * (complexity + locationRange - (complexity - locationRange)) +
      (complexity - locationRange)

    // This will determine bubble minimum and maximum sizes based on annualized business case
    // which converts the bubblesize (r field in data) to be between 4 - 25
    const bubbleSize = (annualisedBuzCase / maxAnnualisedBuzCase) * 21 + 4 || 4
    const bubbleNode: BubbleNode = {
      x: complexity,
      y: priority,
      r: bubbleSize,
    }
    data.datasets[0].data.push(bubbleNode)

    // Bubble color is based on lanes where the cases are
    const backgroundColor = changeHslaColorAlpha(color, colorAlpha)

    data.datasets[0].borderColor.push(color)
    data.datasets[0].backgroundColor.push(backgroundColor)
  }

  return (
    <div className='m-8 bg-white p-3'>
      <div className='flex justify-center gap-6'>
        {laneIds.map((id) => (
          <ColorExplanation id={id} key={id} />
        ))}
      </div>

      <BubbleChart chartData={chartData} />

      <CaseTable />
    </div>
  )
}

export default Roadmap
