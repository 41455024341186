import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { Button, InputField, Modal, Select, TextArea } from '../../../common/designs'
import { useCostUtils } from '../../../common/hooks'
import { convertPathToCaseName } from '../../../common/utils/stringFormatting'
import OptionsBoxes from '../../../features/Cases/components/CaseDetails/components/OptionsBoxes'
import { IntensityLevelEnum } from '../../../features/Cases/enums'
import { useCreateCaseMutation, useGetCaseCategoriesQuery } from '../../apiSlice'
import { maxInteger } from '../../constants'
import { basicColorMap } from '../../theme'

interface FormProps {
  title: string
  description: string
  category_id: number
  business_case_cost: number
  implementation_cost: number
  priority: IntensityLevelEnum
  complexity: IntensityLevelEnum
}

const formSchema = yup
  .object({
    title: yup
      .string()
      .max(200, 'Title cannot be more than 200 characters long')
      .required('Title is a required field'),
    description: yup.string().max(9999),
    category_id: yup.number().required('Category is a required field'),
    business_case_cost: yup
      .number()
      .typeError('Cost must be a number')
      .min(0, 'Cost cannot be less than 0')
      .max(maxInteger)
      .required('Cost is a required field'),
    implementation_cost: yup
      .number()
      .typeError('Cost must be a number')
      .min(0, 'Cost cannot be less than 0')
      .max(maxInteger)
      .required('Cost is a required field'),
    priority: yup.string().max(200).required(),
    complexity: yup.string().max(200).required(),
  })
  .required()

interface Props {
  open: boolean
  onClose: () => void
}

const CreateCaseModal = ({ open, onClose }: Props) => {
  const location = useLocation()
  const { currency } = useCostUtils()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<FormProps>({
    resolver: yupResolver(formSchema) as Resolver<FormProps>,
    defaultValues: {
      title: `Note: ${convertPathToCaseName(location.pathname)}`,
      description: `The case created at ${window.location}`,
      category_id: 12,
      business_case_cost: 0,
      implementation_cost: 0,
      priority: IntensityLevelEnum.LOW,
      complexity: IntensityLevelEnum.LOW,
    },
  })

  const { data: caseCategories = [] } = useGetCaseCategoriesQuery()
  const [createCase, { isLoading }] = useCreateCaseMutation()

  useEffect(() => {
    reset()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onCaseCreated: SubmitHandler<FormProps> = async (body: FormProps) => {
    onClose()

    createCase({ body })
      .unwrap()
      .then(() => toast.success('Opportunity created successfully.'))
      .catch(() => toast.error('Something went wrong while creating an opportunity.'))
  }

  return (
    <Modal label='Create an opportunity' open={open} onClose={onClose}>
      <form autoComplete='off' onSubmit={handleSubmit(onCaseCreated)}>
        <div className='flex gap-4'>
          <div className='flex flex-col gap-4'>
            <Controller
              name='title'
              control={control}
              render={({ field }) => (
                <InputField
                  label='Opportunity title'
                  placeholder='Add an opportunity title'
                  error={errors.title?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <TextArea
                  label='Description'
                  rows={5}
                  placeholder='Add a description'
                  error={errors.description?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='category_id'
              control={control}
              render={({ field }) => (
                <Select
                  label='Category'
                  options={caseCategories.map((c) => ({
                    label: c.name,
                    value: c.id,
                    prefix: (
                      <div className={clsx('size-3 rounded', basicColorMap[c.color_index])} />
                    ),
                  }))}
                  error={errors.category_id?.message}
                  {...field}
                />
              )}
            />

            <div className='flex gap-4'>
              <Controller
                name='business_case_cost'
                control={control}
                render={({ field }) => (
                  <InputField
                    label='Estimated business case'
                    iconStart={currency.symbol}
                    error={errors.business_case_cost?.message}
                    type='number'
                    min={0}
                    className='w-full'
                    {...field}
                  />
                )}
              />
              <Controller
                name='implementation_cost'
                control={control}
                render={({ field }) => (
                  <InputField
                    label='Effort required to fix'
                    iconStart={currency.symbol}
                    error={errors.implementation_cost?.message}
                    type='number'
                    min={0}
                    className='w-full'
                    {...field}
                  />
                )}
              />
            </div>

            <div className='flex gap-4'>
              <OptionsBoxes
                title='Priority'
                value={watch('priority')}
                onChange={(value: IntensityLevelEnum) => setValue('priority', value)}
              />

              <OptionsBoxes
                title='Complexity'
                value={watch('complexity')}
                onChange={(value: IntensityLevelEnum) => setValue('complexity', value)}
              />
            </div>

            <Button type='submit' wFull loading={isLoading}>
              Save case
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CreateCaseModal

