import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import clsx from 'clsx'
import { add, format, isBefore, isEqual } from 'date-fns'
import { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { useGetDynamicPostQuery } from '../../../../app/apiSlice'
import { USE_CURRENCY_SYMBOL_UNIT } from '../../../../app/constants'
import {
  useDeleteCaseTrendMutation,
  useDeleteCompanyTrendMutation,
  useDeleteUserTrendMutation,
  useUpdateCaseTrendMutation,
  useUpdateCompanyTrendMutation,
  useUpdateUserTrendMutation,
} from '../../../../app/dataTrendsApiSlice'
import { CHART_PRIMARY_HSLA, PRIMARY_300_HEX } from '../../../../app/theme'
import { selectActiveCaseId } from '../../../../features/Cases/casesSlice'
import { ActionModal, Divider, IconButton, Text } from '../../../designs'
import { useAppSelector, useCostUtils, useDashboard, useQueryFilters } from '../../../hooks'
import { ChartData } from '../../../types/common'
import { GLOBAL_DATE_FORMAT } from '../../../types/datetime'
import { formatNumber } from '../../../utils/numberFormatting'
import { changeHslaColorAlpha } from '../../../utils/stringFormatting'
import EditFavoriteChartModal from './EditFavoriteChartModal'
import { FavoriteTypeEnum } from './FavoritesDisplay'
import type { TrendQuery } from './types'

interface Props {
  type: FavoriteTypeEnum
  query: TrendQuery
}

const FavoriteDisplayItem = ({ type, query }: Props) => {
  const { url, query_options, custom_name, automatic_name, data_key, unit } = query
  const { min_date, max_date } = useQueryFilters()
  const { user } = useDashboard()
  const { currency } = useCostUtils()
  const activeCaseId = useAppSelector(selectActiveCaseId)

  const { data } = useGetDynamicPostQuery({ url, body: { ...query_options, min_date, max_date } })

  const [editFavoriteChartModalOpen, setEditFavoriteChartModalOpen] = useState<boolean>(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)

  const [updateUserTrend] = useUpdateUserTrendMutation()
  const [updateCompanyTrend] = useUpdateCompanyTrendMutation()
  const [updateCaseTrend] = useUpdateCaseTrendMutation()

  const [deleteUserTrend] = useDeleteUserTrendMutation()
  const [deleteCompanyTrend] = useDeleteCompanyTrendMutation()
  const [deleteCaseTrend] = useDeleteCaseTrendMutation()

  const chartData = useMemo(() => {
    if (!data) return null
    const chartDataObj: ChartData = {
      labels: [],
      datasets: [
        {
          label: custom_name,
          data: [],
          borderColor: CHART_PRIMARY_HSLA,
          backgroundColor: PRIMARY_300_HEX,
          fill: {
            target: 'origin',
            above: changeHslaColorAlpha(CHART_PRIMARY_HSLA, 0.1),
          },
          tension: 0.4,
        },
      ],
    }

    // Loop dates between active start and end date range.
    for (
      let d = new Date(min_date);
      isBefore(d, new Date(max_date)) || isEqual(d, new Date(max_date));
      d = add(d, { days: 1 })
    ) {
      const dateString = format(d, GLOBAL_DATE_FORMAT)
      chartDataObj.labels.push(format(d, 'dd-MM-yyyy'))

      let value = data[dateString]?.[data_key] ?? 0
      value = Math.round(value * 10) / 10
      chartDataObj.datasets[0]?.data?.push(value)
    }

    return chartDataObj
  }, [data, query]) // eslint-disable-line react-hooks/exhaustive-deps

  const options = {
    interaction: { mode: 'index', intersect: false },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItem: TooltipItem<keyof ChartTypeRegistry>) {
            return ` ${tooltipItem.formattedValue}${
              unit === USE_CURRENCY_SYMBOL_UNIT ? ` ${currency.symbol}` : unit
            }`
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback(value: string | number) {
            return `${formatNumber(value)}`
          },
        },
      },
    },
  } as const

  const onDelete = () => {
    switch (type) {
      case FavoriteTypeEnum.PERSONAL:
        deleteUserTrend(query.id)
          .unwrap()
          .then(() => setConfirmationModalOpen(false))
        break

      case FavoriteTypeEnum.COMPANY:
        deleteCompanyTrend(query.id)
          .unwrap()
          .then(() => setConfirmationModalOpen(false))
        break

      case FavoriteTypeEnum.OPPORTUNITY:
        deleteCaseTrend({ caseId: activeCaseId, trendId: query.id })
          .unwrap()
          .then(() => setConfirmationModalOpen(false))
        break

      default:
        break
    }
  }

  const onUpdate = (data: any) => {
    switch (type) {
      case FavoriteTypeEnum.PERSONAL:
        updateUserTrend({ id: query.id, ...data })
          .unwrap()
          .then(() => setEditFavoriteChartModalOpen(false))
        break

      case FavoriteTypeEnum.COMPANY:
        updateCompanyTrend({ id: query.id, ...data })
          .unwrap()
          .then(() => setEditFavoriteChartModalOpen(false))
        break

      case FavoriteTypeEnum.OPPORTUNITY:
        updateCaseTrend({ caseId: activeCaseId, id: query.id, ...data })
          .unwrap()
          .then(() => setEditFavoriteChartModalOpen(false))
        break

      default:
        break
    }
  }

  return (
    <>
      <div className={clsx('rounded-md bg-white shadow')}>
        <div className='flex justify-between p-6'>
          <div className='text-left'>
            <Text variant='h5' weight={500}>
              {custom_name}
            </Text>
            <Text size='s'>{automatic_name}</Text>
          </div>

          <div className='flex w-52 justify-end gap-3'>
            {!user.isAdmin ? null : (
              <>
                <IconButton
                  variant='white'
                  size='xs'
                  icon={<PencilIcon />}
                  onClick={() => setEditFavoriteChartModalOpen(true)}
                />

                <IconButton
                  variant='white'
                  size='xs'
                  icon={<TrashIcon />}
                  onClick={() => setConfirmationModalOpen(true)}
                />
              </>
            )}
          </div>
        </div>
        <Divider color='lightGray' />
        <div className={clsx('h-[calc(100%-60px)]')}>
          <div className='m-3 h-80 pb-3'>
            {chartData ? <Line data={chartData} options={options} /> : null}
          </div>
        </div>
      </div>

      <EditFavoriteChartModal
        open={editFavoriteChartModalOpen}
        onClose={() => setEditFavoriteChartModalOpen(false)}
        initialName={custom_name}
        onUpdate={onUpdate}
      />

      <ActionModal
        variant='destructive'
        label='Are you sure you want to delete this favorite?'
        actionLabel='Delete'
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onAction={onDelete}
      />
    </>
  )
}

export default FavoriteDisplayItem

