import { useRoutes } from 'react-router-dom'

import { SubPathEnum } from '../../common/types/routing'
import CompanyGoals from './CompanyGoals'
import PersonalGoals from './PersonalGoals'

const FavoritesRouting = () => {
  return useRoutes([
    { index: true, element: <CompanyGoals />, id: 'favorites_company_goals' },
    { path: SubPathEnum.PRIVATE, element: <PersonalGoals />, id: 'favorites_personal_goals' },
  ])
}

export default FavoritesRouting
