import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { cloneElement, forwardRef } from 'react'
import type { InputHTMLAttributes } from 'react'

const sizeStyles: { [key in Size]: string } = {
  xs: 'py-1',
  s: 'py-1.5',
  m: 'py-2',
}

type Size = 'xs' | 's' | 'm'

type Props = {
  size?: Size
  className?: string
  label?: string
  description?: string
  error?: string | undefined
  iconStart?: any
  iconEnd?: any
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  name?: InputHTMLAttributes<HTMLInputElement>['name']
  rounded?: boolean
  optional?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

const InputField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    size = 'm',
    className,
    label,
    description,
    error,
    iconStart,
    iconEnd,
    type = 'text',
    name,
    rounded,
    optional,
    ...rest
  } = props

  const isReactIconStartComponent = Boolean(iconStart && typeof iconStart === 'object')
  const isReactIconEndComponent = Boolean(iconEnd && typeof iconEnd === 'object')

  return (
    <div className={className}>
      <>
        <div className='flex justify-between'>
          {label && (
            <label htmlFor={name} className='block text-sm font-medium text-gray-700'>
              {label}
            </label>
          )}

          {optional && (
            <span id={`${name}-optional`} className='text-sm text-gray-400'>
              Optional
            </span>
          )}
        </div>

        <div className={clsx('relative', label && 'mt-1')}>
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            {iconStart && isReactIconStartComponent ? (
              cloneElement(iconStart, {
                className: clsx(error ? 'text-red-500' : 'text-gray-400', 'size-5'),
                'aria-hidden': true,
              })
            ) : (
              <span className={clsx(error ? 'text-red-500' : 'text-gray-400', 'text-sm')}>
                {iconStart}
              </span>
            )}
          </div>

          <input
            type={type}
            name={name}
            id={`${name}-inputfield`}
            className={clsx(
              'block w-full text-sm disabled:cursor-not-allowed',
              sizeStyles[size],
              error ? 'pr-9' : 'pr-3',
              rounded ? 'rounded-full' : 'rounded-md',
              iconStart ? (isReactIconStartComponent ? 'pl-10' : 'pl-7') : 'pl-3',
              iconEnd ? (isReactIconEndComponent ? 'pr-10' : 'pr-7') : 'pr-3',
              error
                ? 'border-red-300 text-red-900 placeholder:text-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            )}
            aria-describedby={`${name}-optional`}
            ref={ref}
            {...rest}
          />

          {(error || iconEnd) && (
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              {error ? (
                <ExclamationCircleIcon className='size-5 text-red-500' aria-hidden='true' />
              ) : iconEnd && isReactIconEndComponent ? (
                cloneElement(iconEnd, {
                  className: clsx(error ? 'text-red-500' : 'text-gray-400', 'size-5'),
                  'aria-hidden': true,
                })
              ) : (
                <span className={clsx(error ? 'text-red-500' : 'text-gray-400', 'text-sm')}>
                  {iconEnd}
                </span>
              )}
            </div>
          )}
        </div>
      </>

      {(description || error) && (
        <p
          id={`${name}-description`}
          className={clsx(error ? 'text-red-600' : 'text-gray-500', 'mt-2 text-sm')}
        >
          {error ? error : description}
        </p>
      )}
    </div>
  )
})

InputField.displayName = 'InputField'
export default InputField
