import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

import type { MaxRowLength } from '../types/common'
import { maxRowLengthSizes } from './constants'

const variantStyles: { [key in Variant]: string } = {
  primary: 'bg-primary-600 text-white',
  secondary: 'bg-primary-100 text-primary-700',
  white: 'bg-gray-200 text-gray-900',
}

const sizeStyles: { [key in Size]: string } = {
  xs: 'px-0.75', // Negative padding not possible here so div has reduced padding around L45.
  s: 'py-0.25 px-1',
  m: 'py-0.75 px-1',
  l: 'py-1.25 px-2',
  xl: 'py-2.25 px-3',
}

export interface TabOption {
  label: string
  value: string | number | null

  disabled?: boolean
  prefix?: JSX.Element
}

type Variant = 'primary' | 'secondary' | 'white'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl'
interface Props {
  options: TabOption[]
  value: string | number | null
  onChange:
    | ((val: string | number) => void)
    | ((val: string) => void)
    | ((val: number) => void)
    | Dispatch<SetStateAction<string | number>>
    | Dispatch<SetStateAction<string>>
    | Dispatch<SetStateAction<number>>

  variant?: Variant
  size?: Size
  className?: string
  label?: string
  rounded?: boolean
  maxRowLength?: MaxRowLength // You can set the max number of options in a row. If the number of options exceeds this, the options will be wrapped to the next row.
}

const Tabs = (props: Props) => {
  const {
    options,
    value,
    onChange,

    variant = 'primary',
    size = 'm',
    className,
    label,
    rounded,
    maxRowLength,
  } = props

  return (
    <RadioGroup value={value} onChange={onChange as any} className={className}>
      {label && (
        <RadioGroup.Label className='mb-1 block text-sm font-medium text-gray-700'>
          {label}
        </RadioGroup.Label>
      )}

      <div
        className={clsx(
          `grid gap-2 border border-gray-300 bg-white`,
          maxRowLengthSizes[maxRowLength ?? (options?.length as MaxRowLength)],
          size === 'xs' ? 'p-0.75' : 'p-1',
          rounded ? 'rounded-full' : 'rounded-md',
        )}
      >
        {options.map((option) => (
          <RadioGroup.Option
            key={option.label}
            value={option.value}
            className={({ active, checked }) =>
              clsx(
                'flex items-center justify-center border text-sm font-medium sm:flex-1',
                option.disabled
                  ? 'cursor-not-allowed opacity-25'
                  : 'cursor-pointer focus:outline-none',
                active && 'ring-2 ring-primary-500 ring-offset-2',
                checked
                  ? `border-transparent  transition-colors duration-200 ease-in-out ${variantStyles[variant]}`
                  : 'border-transparent bg-white text-gray-900 transition-colors duration-200 ease-in-out hover:bg-gray-100',
                sizeStyles[size],
                rounded ? 'rounded-full' : 'rounded-md',
              )
            }
            disabled={Boolean(option.disabled)}
          >
            <RadioGroup.Label as='span' className='flex items-center gap-2'>
              {option?.prefix} {option.label}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default Tabs
