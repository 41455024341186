import { Menu } from '@headlessui/react'
import clsx from 'clsx'

import { DefaultTransistion } from './HelperComponents'

interface Props {
  action: React.ReactNode
  children: React.ReactNode

  actionAs?: 'button' | 'div'
  btnClassName?: string
  itemsClassName?: string
  className?: string
  disabled?: boolean
}

const Dropdown = (props: Props) => {
  const {
    action,
    actionAs = 'button',
    children,
    btnClassName,
    itemsClassName,
    className,
    disabled,
  } = props

  return (
    <Menu as='div' className={clsx('relative inline-block', className)}>
      <Menu.Button as={actionAs} className={btnClassName} disabled={Boolean(disabled)}>
        {action}
      </Menu.Button>

      <DefaultTransistion>
        <Menu.Items
          className={clsx(
            'absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
            itemsClassName,
          )}
        >
          {children}
        </Menu.Items>
      </DefaultTransistion>
    </Menu>
  )
}

export default Dropdown
