import { ArrowRightIcon } from '@heroicons/react/24/outline'
import type { ChartData } from 'chart.js'
import clsx from 'clsx'
import { Doughnut } from 'react-chartjs-2'

import { InlineLoader } from '../../../common/components'
import { Button, Divider, Text } from '../../../common/designs'
import { useDashboard, useEnhancedNavigate } from '../../../common/hooks'
import type { AdminMainPathEnum } from '../../../common/types/routing'

interface Props {
  chartData: ChartData<'doughnut'> | undefined
  titleProps: {
    label: string
    value: string | number | undefined
  }
  descriptionProps: {
    label: string
    value: string | number | undefined
  }
  navProps: {
    label: string
    link: AdminMainPathEnum | string
  }
  isLoading?: boolean
}

const StatusCard = (props: Props) => {
  const { titleProps, descriptionProps, chartData, navProps, isLoading = false } = props
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { user } = useDashboard()

  return (
    <div
      className={clsx(
        'flex w-72 flex-col items-center justify-center rounded-md bg-white shadow-md',
        isLoading && 'animate-pulse',
      )}
    >
      <div className='w-full p-4'>
        <div className='mb-2 flex justify-between'>
          {isLoading ? (
            <div className='mb-4 h-2.5 w-full rounded-full bg-gray-200'></div>
          ) : (
            <>
              <Text variant='h6' size='m' weight={500}>
                {titleProps.label}
              </Text>

              <Text variant='h6' size='m' weight={500}>
                {titleProps.value}
              </Text>
            </>
          )}
        </div>

        <div className='flex justify-between'>
          {isLoading ? (
            <div className='h-2.5 w-full rounded-full bg-gray-200'></div>
          ) : (
            <>
              <Text variant='h6' size='s' weight={500} color='lightGray'>
                {descriptionProps.label}
              </Text>

              <Text variant='h6' size='s' weight={500} color='lightGray'>
                {descriptionProps.value}
              </Text>
            </>
          )}
        </div>
      </div>

      <Divider />

      <div className='flex h-72 items-center justify-center p-4'>
        {!isLoading && chartData ? (
          <Doughnut
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={chartData}
          />
        ) : (
          <InlineLoader size='2xl' />
        )}
      </div>

      {user.isAdmin && (
        <div className='mb-4'>
          <Button
            variant='ghost'
            iconEnd={<ArrowRightIcon />}
            onClick={() => navigate(navProps.link)}
          >
            {navProps.label}
          </Button>
        </div>
      )}
    </div>
  )
}

export default StatusCard
