import Stat from '../Network/Stat'
import type {
  DetailLevelEnum,
  NavigationInsight,
  NavigationInsightEdge,
  NavigationInsightNode,
} from '../Network/types'

interface Props {
  insights: NavigationInsight
  detailLevel?: DetailLevelEnum | undefined
}

const NavigationsFlyoutStats = ({ insights, detailLevel }: Props) => {
  if (insights.node) {
    return <NavigationsNodeFlyoutStats insights={insights.node} detailLevel={detailLevel} />
  }
  if (insights.edge) return <NavigationsEdgeFlyoutStats insights={insights.edge} />
  return null
}

interface NodeFlyoutStatsProps {
  insights: NavigationInsightNode
  detailLevel: DetailLevelEnum | undefined
}

const NavigationsNodeFlyoutStats = ({ insights, detailLevel }: NodeFlyoutStatsProps) => {
  const splitLabel = (insights.entity.label ?? '').split(' | ')

  return (
    <Stat.Root>
      <Stat.Label mainLabel={splitLabel[0]} subLabel={splitLabel[1]} />

      <Stat.Divider />

      <Stat.Container>
        {insights.originVisits ? (
          <Stat.ItemOriginVisits value={insights.originVisits} detailLevel={detailLevel} />
        ) : null}
        <Stat.ItemVisits value={insights.visits} />
        <Stat.ItemTotalHours value={insights.totalH} />
        <Stat.ItemTeams value={insights.entity.properties.team_ids} />
      </Stat.Container>

      <Stat.Divider />

      <Stat.Cost label='Annualized cost' value={insights.annualizedCost} />
    </Stat.Root>
  )
}

interface EdgeFlyoutStatsProps {
  insights: NavigationInsightEdge
}

const NavigationsEdgeFlyoutStats = ({ insights }: EdgeFlyoutStatsProps) => {
  const sourceSplitLabel = (insights.entity.properties.source_label ?? '').split(' | ')
  const targetSplitLabel = (insights.entity.properties.target_label ?? '').split(' | ')

  return (
    <Stat.Root>
      <Stat.Label mainLabel={sourceSplitLabel[0]} subLabel={sourceSplitLabel[1]} />
      <Stat.Divider label='to' />
      <Stat.Label mainLabel={targetSplitLabel[0]} subLabel={targetSplitLabel[1]} />

      <Stat.Divider />

      <Stat.Container>
        <Stat.ItemNavigations value={insights.navigations} />
        <Stat.ItemTeams value={insights.entity.properties.team_ids} />
      </Stat.Container>

      <Stat.Divider />

      <Stat.Cost label='Annualized cost' value={insights.annualizedCost} />
    </Stat.Root>
  )
}

export default NavigationsFlyoutStats
