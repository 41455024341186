import { useEffect } from 'react'
import type { Data as CsvData } from 'react-csv/lib/core'

import { Link, ProgressBar, Table, Text } from '../../designs'
import type { PercentageValue } from '../../types/common'
import { exportTableDataToCSV } from './exportUtils'

interface Props {
  head: string[]
  body: Array<(string | number | React.ReactNode)[]>
  visualizationValues: PercentageValue[]
  onClick?: ((label: string, dataIndex: number) => void) | undefined
  setExportedData?: (data: CsvData) => void | undefined
}

const DataDisplayTable = ({ head, body, visualizationValues, onClick, setExportedData }: Props) => {
  if (body.length !== visualizationValues.length) {
    throw TypeError('body and visualizationValues need to have the same length in DataDisplayTable')
  }

  useEffect(() => {
    if (setExportedData) setExportedData(exportTableDataToCSV(head, body))
  }, [setExportedData, head, body])

  const bodyWithVisualization = body.map((row, index) => {
    const key = String(row)

    return [
      onClick ? (
        <Link
          key={key}
          className='text-sm !font-medium'
          onClick={() => onClick(row[0] as string, index)}
        >
          {row[0]}
        </Link>
      ) : (
        <Text key={key} size='s' weight={500}>
          {row[0]}
        </Text>
      ),
      ...row.slice(1),
      <div key={key} className='inline-block w-32'>
        <ProgressBar value={visualizationValues[index]} rounded />
      </div>,
    ]
  })

  return (
    <div className='h-full overflow-y-hidden'>
      <Table head={[...head, '']} body={bodyWithVisualization} size='s' />
    </div>
  )
}

export default DataDisplayTable
