import { useEffect, useState } from 'react'

import {
  useGetProcessCustomGroupByDimensionsQuery,
  useGetTransactionGroupQuery,
  useGetTransactionRelatedSystemGroupQuery,
  useGetTransactionStepGroupMultipleQuery,
  useGetTransactionStepGroupQuery,
} from '../../../../app/apiSlice'
import { ContentDisplay, Loader } from '../../../../common/components'
import { Select, SelectMultiple, Text } from '../../../../common/designs'
import { useQueryFilters } from '../../../../common/hooks'
import {
  DimensionExtraDataMapper,
  GroupByDimensionTypeEnum,
  GroupByFieldTypeEnum,
  MetricNamesEnum,
  MetricTypeEnum,
} from '../../types'
import type { GroupByField } from '../../types'
import CustomAnalysisMultipleDimensions from './CustomAnalysisMultipleDimensions'
import CustomAnalysisOneDimension from './CustomAnalysisOneDimension'
import { defaultGroupByDimensionsExtraDataMapper, metricNameExtraDataMapper } from './constants'

const NO_SELECTION = 'No selection'

export const CustomAnalysis = () => {
  const { processId, processFilters: filters } = useQueryFilters()

  const [selectedGroupByFields, setSelectedGroupByFields] = useState<string[]>([])
  const [selectedMetric, setSelectedMetric] = useState<MetricNamesEnum | null>(null)
  const [groupByDimensionsExtraDataMapper, setGroupByDimensionsExtraDataMapper] =
    useState<DimensionExtraDataMapper>(defaultGroupByDimensionsExtraDataMapper)

  // Fetch custom group by dimensions from server. After data fetching
  // update dimensions to groupByDimensionsExtraDataMapper
  const { data: customDimensions, isFetching: isFetchingDimensions } =
    useGetProcessCustomGroupByDimensionsQuery(processId)

  // Chart data fetching related logic
  const skipQuery = !selectedGroupByFields || !selectedMetric
  let shouldUseStepQuery = false
  let shouldUseStepMultipleQuery = false
  let shouldUseRelatedSystemQuery = false
  let gropByData = [] as GroupByField[]
  let metricToUse = MetricNamesEnum.ACTIVE_WORK
  if (!skipQuery) {
    metricToUse = selectedMetric

    gropByData = selectedGroupByFields.map((selectedGroupByField) => {
      const groupByDataForSelectedField = groupByDimensionsExtraDataMapper[selectedGroupByField]
      if (groupByDataForSelectedField.is_tag_dimension) {
        return {
          field_type: GroupByFieldTypeEnum.TAG_NAME,
          tag_name: selectedGroupByField,
          tag_filtering_logic: groupByDataForSelectedField.tag_filtering_logic,
        }
      } else {
        return { field_type: selectedGroupByField as GroupByFieldTypeEnum }
      }
    })

    let groupByType
    if (selectedGroupByFields.length === 1) {
      groupByType =
        groupByDimensionsExtraDataMapper[selectedGroupByFields[0]]?.group_by_dimension_type ||
        GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC
    }

    const metricMapperKey = selectedMetric as keyof typeof metricNameExtraDataMapper
    const metricType =
      metricNameExtraDataMapper[metricMapperKey]?.metric_type || MetricTypeEnum.TRANSACTION_SPECIFIC

    shouldUseStepQuery =
      groupByType === GroupByDimensionTypeEnum.STEP_SPECIFIC &&
      metricType === MetricTypeEnum.ACTIVE_WORK_SPECIFIC &&
      gropByData.length === 1

    shouldUseStepMultipleQuery = gropByData.length > 1

    shouldUseRelatedSystemQuery =
      groupByType === GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC &&
      metricType === MetricTypeEnum.RELATED_SYSTEM_SPECIFIC
  }
  const { data: relatedSystemRequestData } = useGetTransactionRelatedSystemGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || !shouldUseRelatedSystemQuery },
  )
  const { data: stepRequestData } = useGetTransactionStepGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || !shouldUseStepQuery },
  )
  const { data: stepRequestMultipleData } = useGetTransactionStepGroupMultipleQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || !shouldUseStepMultipleQuery },
  )
  const { data: transactionRequestData } = useGetTransactionGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    {
      skip:
        skipQuery ||
        shouldUseStepQuery ||
        shouldUseRelatedSystemQuery ||
        shouldUseStepMultipleQuery,
    },
  )

  // Server data to chart data logic
  useEffect(() => {
    if (!customDimensions) return
    const customDimensionMapper = {} as DimensionExtraDataMapper
    customDimensions.forEach(
      ({ dimension_key, dimension_name, is_tag_dimension, tag_filtering_logic }) => {
        customDimensionMapper[dimension_key] = {
          dimension_name,
          group_by_dimension_type: GroupByDimensionTypeEnum.STEP_SPECIFIC,
          is_tag_dimension,
          filter_key: dimension_key,
          tag_filtering_logic,
        }
      })

    setGroupByDimensionsExtraDataMapper((cur) => ({ ...cur, ...customDimensionMapper }))
  }, [customDimensions]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetchingDimensions) return <Loader />

  const groupByOptions = [
    ...Object.entries(groupByDimensionsExtraDataMapper).map(([value, { dimension_name }]) => ({
      label: dimension_name,
      value,
    })),
  ]

  let ignoredMetricType: MetricTypeEnum | null = null
  let ignoredMetricName: string | null = null

  if (selectedGroupByFields.length === 1) {
    const hasRelatedSystem = selectedGroupByFields.some((selectedGroupByField) => {
      const extraData = groupByDimensionsExtraDataMapper[selectedGroupByField]
      return extraData.group_by_dimension_type === GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC
    })

    ignoredMetricType = hasRelatedSystem
      ? MetricTypeEnum.ACTIVE_WORK_SPECIFIC
      : MetricTypeEnum.RELATED_SYSTEM_SPECIFIC
  } else if (selectedGroupByFields.length > 1) {
    ignoredMetricType = MetricTypeEnum.RELATED_SYSTEM_SPECIFIC
    ignoredMetricName = 'Total throughput time'
  }

  const metricOptions = selectedGroupByFields.length
    ? [
        { label: NO_SELECTION, value: NO_SELECTION },
        ...Object.entries(metricNameExtraDataMapper)
          .filter(
            ([_, { metric_type, metric_name }]) =>
              metric_type !== ignoredMetricType && metric_name !== ignoredMetricName,
          )
          .map(([value, { metric_name }]) => ({
            label: metric_name,
            value,
          })),
      ]
    : [{ label: NO_SELECTION, value: NO_SELECTION }]

  const onGroupByValueChange = (val: string[]) => {
    // If the new dimension type is different than previous, reset metric value
    // as the current metric might not be valid for the new dimension.
    if (val.length !== selectedGroupByFields.length) {
      setSelectedMetric(null)
    }
    setSelectedGroupByFields(val)
  }

  return (
    <>
      <div className='flex flex-col gap-2'>
        <ContentDisplay className='px-5 pb-3 pt-4'>
          <div className='flex gap-6'>
            <div className='w-96'>
              <SelectMultiple
                options={groupByOptions}
                value={selectedGroupByFields}
                onChange={onGroupByValueChange}
                label='Group by dimensions'
              />
            </div>
            <div className=' w-96'>
              <Select
                options={metricOptions}
                value={selectedMetric || NO_SELECTION}
                onChange={(val: string) =>
                  setSelectedMetric(val === NO_SELECTION ? null : (val as MetricNamesEnum))
                }
                label='Metric'
              />
            </div>
          </div>
          <Text className='mt-2' size='xs'>
            The analysis view shows only a maximum of 2000 results
          </Text>
        </ContentDisplay>
        {selectedGroupByFields.length === 1 && selectedMetric && (
          <CustomAnalysisOneDimension
            transactionRequestData={transactionRequestData}
            stepRequestData={stepRequestData}
            relatedSystemRequestData={relatedSystemRequestData}
            selectedMetric={selectedMetric}
            selectedGroupByField={selectedGroupByFields[0]}
            groupByDimensionsExtraDataMapper={groupByDimensionsExtraDataMapper}
          />
        )}
        {selectedGroupByFields.length > 1 && selectedMetric && (
          <CustomAnalysisMultipleDimensions
            stepRequestMultipleData={stepRequestMultipleData}
            selectedMetric={selectedMetric}
            selectedGroupByFields={selectedGroupByFields}
          />
        )}
      </div>
    </>
  )
}

export default CustomAnalysis
