import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { SetStateAction } from 'react'

import { Divider, Select, Text } from '../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import {
  selectActiveHighlightField,
  selectToggleableHighlightSelections,
  setActiveHighlighField,
  toggleIsAutomationInsightsOpen,
  toggleIsTeamdistributionOpen,
  toggleIsUnwindModeActive,
  toggleIsWorkTypeDistributionOpen,
} from '../../processesSlice'
import { HighlightFieldEnum } from '../../types'
import { useSelectedVariantsIndicies } from './useSelectedVariantsIndicies'

const highlightByOptions = [
  { label: HighlightFieldEnum.TRANSACTIONS, value: HighlightFieldEnum.TRANSACTIONS },
  { label: HighlightFieldEnum.ACTIVE_WORK, value: HighlightFieldEnum.ACTIVE_WORK },
]

const optionsConfig = {
  isTeamDistributionOpen: {
    label: 'Involved teams',
    dispatchAction: toggleIsTeamdistributionOpen,
  },
  isWorkTypeDistributionOpen: {
    label: 'Task activities',
    dispatchAction: toggleIsWorkTypeDistributionOpen,
  },
  isAutomationInsightsOpen: {
    label: 'Improvement insights',
    dispatchAction: toggleIsAutomationInsightsOpen,
  },
  isUnwindModeActive: {
    label: 'Unwind self-loops',
    dispatchAction: toggleIsUnwindModeActive,
  },
}

const HighlightOptions = () => {
  const dispatch = useAppDispatch()
  const activeHighlightField = useAppSelector(selectActiveHighlightField)
  const highlightOptions = useAppSelector(selectToggleableHighlightSelections)
  const { stepLevelVariantIndices } = useSelectedVariantsIndicies()

  const onActiveHighlightFieldChange = (value: SetStateAction<string | number>) => {
    dispatch(setActiveHighlighField(value as HighlightFieldEnum))
  }

  return (
    <div className='pointer-events-auto w-[280px] rounded-md bg-white shadow-md ring-1 ring-gray-300'>
      <div className='flex items-center gap-2 p-1 pl-1.5'>
        <Text size='xs' weight={500}>
          Highlight by
        </Text>
        <Select
          size='xs'
          options={highlightByOptions}
          value={activeHighlightField}
          onChange={onActiveHighlightFieldChange}
          className='w-[192px]'
        />
      </div>
      <Divider className='my-0.5' />
      <div className='p-1'>
        {Object.entries(highlightOptions).map(([key, bool]) => {
          const isUnwindModeDisabled =
            key === 'isUnwindModeActive' && stepLevelVariantIndices.length > 1

          const option = optionsConfig[key as keyof typeof optionsConfig]

          return (
            <div
              className={clsx(
                'flex items-center gap-1 rounded-md p-1',
                !isUnwindModeDisabled && 'cursor-pointer hover:bg-gray-200',
              )}
              onClick={() => {
                if (!isUnwindModeDisabled) dispatch(option.dispatchAction())
              }}
              key={key}
            >
              {bool ? (
                <EyeIcon className='size-4 text-primary-700' />
              ) : (
                <EyeSlashIcon
                  className={clsx(
                    'size-4',
                    isUnwindModeDisabled ? 'text-gray-400' : 'text-gray-900',
                  )}
                />
              )}
              <Text
                size='xs'
                weight={500}
                color={isUnwindModeDisabled ? 'lighterGray' : bool ? 'primary' : 'darkGray'}
              >
                {option.label}
              </Text>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HighlightOptions
