import { useMemo, useState } from 'react'
import type {
  Data,
  DataFlowEdge,
  DataFlowNode,
  GraphEvents,
  GraphEventsSelectParams,
} from 'react-graph-vis'

import { appCategoryColorMap } from '../../app/theme'
import { useCostUtils, useUpdateEffect } from '../../common/hooks'
import type { ApplicationCategory } from '../../common/types/dataCollectionsConfigs'
import Legend from '../Network/Legend'
import NetworkGraph from '../Network/NetworkGraph'
import { VisualizationModeEnum } from '../Network/types'
import type { DataFlowInsight, DataFlowInsightEdge, DataFlowInsightNode } from '../Network/types'
import useClusterLegend from '../Network/useClusterLegend'
import { removeWhiteSpaces } from '../Network/utils'
import DataFlowsFlyoutStats from './DataFlowsFlyoutStats'
import DetailLevelSelector from './DetailLevelSelector'
import TableVis from './TableVis'
import VisualizationModeSelector from './VisualizationModeSelector'
import useOptions from './useOptions'

interface Props {
  nodes: DataFlowNode[] | null
  edges: DataFlowEdge[] | null
}

const DataFlowVisualization = ({ nodes, edges }: Props) => {
  const { visualizationMode, detailLevel } = useOptions()
  const { generateAnnualizedCopyPasteCost } = useCostUtils()

  const [insights, setInsights] = useState<DataFlowInsight>({ node: null, edge: null })
  const [clustersVisible, setClustersVisible] = useClusterLegend()

  const isSelfLoopsVisible = true // TODO: Consider implementing visibility toggle for self-loops.

  useUpdateEffect(() => {
    setInsights({ node: null, edge: null })
  }, [visualizationMode, detailLevel])

  const { visualizationNodes, visualizationEdges } = useMemo(() => {
    if (!nodes || !edges) return { visualizationNodes: [], visualizationEdges: [] }

    const visualizationNodes: DataFlowNode[] = nodes
      .filter(
        ({ properties: { applicationCategory } }) =>
          clustersVisible[applicationCategory as ApplicationCategory],
      )
      .map(({ id, value, x, y, properties }) => {
        return {
          id,
          label: properties.nodeName,
          value,
          x,
          y,
          group: properties.applicationCategory,
          color: appCategoryColorMap[properties.applicationCategory],
          properties,
        }
      })

    const visualizationEdges: DataFlowEdge[] = edges
      .filter(({ properties: { sourceNodeId, targetNodeId } }) => {
        return isSelfLoopsVisible || sourceNodeId !== targetNodeId
      })
      .map(({ id, properties, value }) => ({
        id,
        from: properties.sourceNodeId,
        to: properties.targetNodeId,
        value,
        properties,
      }))

    return { visualizationNodes, visualizationEdges }
  }, [nodes, edges, isSelfLoopsVisible, clustersVisible])

  const events: GraphEvents = {
    select: ({ nodes: graphNodes, edges: graphEdges }: GraphEventsSelectParams) => {
      if (!nodes || !edges) return

      let nodeInsight: DataFlowInsightNode | null = null
      let edgeInsight: DataFlowInsightEdge | null = null

      if (graphNodes.length) {
        const nodeId = removeWhiteSpaces(graphNodes[0])
        const node = nodes.find((n) => n.id === nodeId)
        if (!node) return

        const selfLoopEdgeId = `${nodeId}..${nodeId}`
        const selfLoopEdge = edges.find((e) => e.id === selfLoopEdgeId)
        const selfLoopCopyPasteSum = selfLoopEdge?.value ?? 0

        const copiesSum = node.properties.copyCount - selfLoopCopyPasteSum
        const pastesSum = node.properties.pasteCount - selfLoopCopyPasteSum
        const combinedSum = copiesSum + pastesSum + selfLoopCopyPasteSum * 2

        nodeInsight = {
          entity: node,
          externalCopies: copiesSum,
          externalPastes: pastesSum,
          selfLoopCopyPastes: selfLoopCopyPasteSum,
          annualizedCost: generateAnnualizedCopyPasteCost(combinedSum),
        }
      }

      if (graphEdges.length) {
        const edgeId = graphEdges[0]
        const edge = edges.find((e) => e.id === edgeId)
        if (!edge) return

        edgeInsight = {
          entity: edge,
          annualizedCost: generateAnnualizedCopyPasteCost(edge.value),
        }
      }

      setInsights({ node: nodeInsight, edge: edgeInsight })
    },
  }

  return (
    <>
      <DataFlowsFlyoutStats insights={insights} isSelfLoopsVisible={isSelfLoopsVisible} />

      {visualizationMode === VisualizationModeEnum.NETWORK ? (
        <>
          <div className='absolute right-0 z-[1] space-y-2'>
            <div className='space-y-2'>
              <VisualizationModeSelector />
              <DetailLevelSelector />
            </div>

            <Legend clustersVisible={clustersVisible} setClustersVisible={setClustersVisible} />
          </div>

          <NetworkGraph
            data={{ nodes: visualizationNodes, edges: visualizationEdges } as Data}
            events={events}
          />
        </>
      ) : (
        <TableVis nodes={visualizationNodes} edges={visualizationEdges} detailLevel={detailLevel} />
      )}
    </>
  )
}

export default DataFlowVisualization
