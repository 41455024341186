import { LINK_COPMANY_HOME_PAGE } from '../../app/constants'
import { ReactComponent as MicrosoftIcon } from '../../common/assets/icons/icon-microsoft.svg'
import { InlineLoader } from '../../common/components'
import { Button } from '../../common/designs'
import useAuthentication from './useAuthentication'

const Authentication = () => {
  const { onSignIn, isLoading } = useAuthentication()

  return (
    <>
      <div className='flex min-h-full'>
        <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm pb-32 lg:w-96'>
            {isLoading ? (
              <div className='flex justify-center'>
                <InlineLoader size='2xl' />
              </div>
            ) : (
              <>
                <div>
                  <img
                    className='h-12 w-auto'
                    alt='Process Intelligence logo'
                    src='/images/pi-logo-color.png'
                  />
                  <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900'>
                    Sign in to your account
                  </h2>
                  <p className='mt-2 text-sm text-gray-600'>
                    Or{' '}
                    <a
                      href={LINK_COPMANY_HOME_PAGE}
                      target='__blank'
                      className='font-medium text-indigo-600 hover:text-indigo-500'
                    >
                      get a free trial
                    </a>
                  </p>
                </div>

                <div className='mt-8'>
                  <div>
                    <div>
                      <p className='text-sm font-medium text-gray-700'>Sign in with</p>

                      <div className='mt-1'>
                        <span className='sr-only'>Sign in with Microsoft</span>
                        <Button
                          iconCenter={<MicrosoftIcon />}
                          variant='white'
                          className='flex w-full justify-center'
                          onClick={onSignIn}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='relative hidden w-0 flex-1 lg:block'>
          <img
            className='absolute inset-0 size-full object-cover'
            src='/images/sign-in.jpg'
            alt='Process Intelligence dashboard'
          />
        </div>
      </div>
    </>
  )
}

export default Authentication
