import { useOutletContext } from 'react-router-dom'

import { useEnhancedNavigate } from '../../../common/hooks'
import TrendsChartView from '../trendAndTeamViews/TrendsChartView'
import type { SecondLevelLayoutOutletContext } from './SecondLevelLayout'

const Trends = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { tagFilters, appName } = useOutletContext<SecondLevelLayoutOutletContext>()

  return (
    <TrendsChartView
      tagsOfInterestSource='window'
      tagFilters={tagFilters}
      chartTitle={`${appName} trends`}
      relativeChartTitle={`${appName} trends - Relative`}
      onChartClick={(compressedAndEncodedTags) => {
        navigate(`../../window/${compressedAndEncodedTags}/trends`)
      }}
    />
  )
}

export default Trends
