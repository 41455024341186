import { useState } from 'react'

import { Button } from '../../../common/designs'
import { useDashboard } from '../../../common/hooks'
import CreateCaseModal from './CreateCaseModal'

const CreateActionButton = () => {
  const { user } = useDashboard()

  const [isCreateCaseModalOpen, setIsCreateCaseModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        size='s'
        rounded
        onClick={() => setIsCreateCaseModalOpen((s) => !s)}
        disabled={user.isDataReader || user.isPlatformViewer}
      >
        Create
      </Button>

      {isCreateCaseModalOpen && (
        <CreateCaseModal
          open={isCreateCaseModalOpen}
          onClose={() => setIsCreateCaseModalOpen((s) => !s)}
        />
      )}
    </>
  )
}

export default CreateActionButton
