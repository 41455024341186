import { IdentificationIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

interface Props {
  onZoom?: (() => void) | null
  onShowMetrics?: (() => void) | null
}

export const NodeActionButtons = ({ onZoom, onShowMetrics }: Props) => {
  if (!onZoom && !onShowMetrics) return null

  const buttonClass = 'flex w-full items-center justify-center gap-x-2.5 p-2 hover:bg-gray-200'
  const gridCols = onShowMetrics && onZoom ? 'grid-cols-2' : 'grid-cols-1'
  const zoomButtonRounding = onShowMetrics ? 'rounded-bl-3xl' : 'rounded-b-3xl'
  const metricsButtonRounding = onZoom ? 'rounded-br-3xl' : 'rounded-b-3xl'

  return (
    <div
      className={clsx(
        'grid divide-x divide-gray-900/5 rounded-b-3xl border-t bg-gray-100 text-xs font-semibold text-gray-900',
        gridCols,
      )}
    >
      {onZoom && (
        <button onClick={onZoom} className={clsx(buttonClass, zoomButtonRounding)}>
          <MagnifyingGlassPlusIcon className='size-5 flex-none text-gray-400' />
          Focus in
        </button>
      )}
      {onShowMetrics && (
        <button onClick={onShowMetrics} className={clsx(buttonClass, metricsButtonRounding)}>
          <IdentificationIcon className='size-5 flex-none text-gray-400' />
          Metrics
        </button>
      )}
    </div>
  )
}

export default NodeActionButtons

