import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'

const AdminPanelLayout = () => {
  return (
    <div className='flex min-h-full min-w-full'>
      <div className='h-screen w-72 min-w-72 max-w-72'>
        <Sidebar />
      </div>

      <main className='h-screen min-h-full min-w-[calc(100%-18rem)] overflow-y-auto bg-background-main p-4'>
        <Outlet />
      </main>
    </div>
  )
}

export default AdminPanelLayout

