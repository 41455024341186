import clsx from 'clsx'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import type { Data as CsvData } from 'react-csv/lib/core'
import { useSearchParams } from 'react-router-dom'

import { Tabs } from '../../designs'
import { useEnhancedSearchParams } from '../../hooks'
import { SubSearchParamEnum } from '../../types/searchParams'
import { GroupedBarChart } from '../Charts'
import ChartDataSelector from '../Charts/ChartDataSelector'
import type { GroupedBarChartDataEntry } from '../Charts/types'
import { VisualizationContainer } from '../containers'
import { DataDisplayTable } from '../tables'
import {
  convertGroupChartDataToTableBody,
  convertGroupChartDataToTableHead,
  convertGroupChartDataToVisualizationValues,
} from '../tables/dataUtils'

interface Props {
  label: string
  data: GroupedBarChartDataEntry[]
  unit: string
  numberRounding?: number
  onClick?: ((groupName: string, label: string) => void) | undefined
  description?: string
  toggleActions?: ReactElement[]
  selectActions?: ReactElement[]
  otherActions?: ReactElement[]
}

enum VisualizationModeEnum {
  CHART = 'chart',
  TABLE = 'table',
}

const visualizationOtions = [
  { label: 'Chart', value: VisualizationModeEnum.CHART },
  { label: 'Table', value: VisualizationModeEnum.TABLE },
]

const GroupedBarChartAndTableDisplay = ({
  label,
  data,
  unit,
  onClick,
  description,
  numberRounding = 10,
  toggleActions = [],
  selectActions = [],
  otherActions = [],
}: Props) => {
  const { updateSearchParam } = useEnhancedSearchParams()
  const [searchParams] = useSearchParams()
  const [visualizationTabValue, setVisualizationTabValue] = useState<VisualizationModeEnum>(
    visualizationOtions[0].value,
  )
  const [visibleDataAmount, setVisibleDataAmount] = useState<number>(10)
  const [csvData, setCsvData] = useState<CsvData | null>(null)

  // Update state when URL search params change.
  useEffect(() => {
    const mode = searchParams.get(SubSearchParamEnum.VISUALIZATION_MODE) as VisualizationModeEnum
    setVisualizationTabValue(
      mode === VisualizationModeEnum.TABLE
        ? visualizationOtions[1].value
        : visualizationOtions[0].value,
    )
  }, [searchParams, setVisualizationTabValue])

  // Update URL search params when visualization mode is changed.
  const onChangeVisualization = useCallback(
    (value: VisualizationModeEnum) =>
      updateSearchParam(SubSearchParamEnum.VISUALIZATION_MODE, value),
    [updateSearchParam],
  )

  const onTableClick = (label: string, _: number) => {
    if (onClick) onClick('totals', label)
  }

  const totalAmount = Math.max(...data.map(({ data }) => data.length))
  const dataSelector =
    visualizationTabValue === visualizationOtions[0].value
      ? [
          <ChartDataSelector
            key='data-selector'
            value={visibleDataAmount}
            options={[5, 10, 50]}
            onChange={(amount) => setVisibleDataAmount(amount)}
            totalAmount={totalAmount}
          />,
        ]
      : []

  const csvExportButton = useMemo(() => {
    return csvData && visualizationTabValue === visualizationOtions[1].value
      ? [
          <CSVLink
            key='csv-export'
            data={csvData}
            filename={'pi-export.csv'}
            className={clsx(
              'inline-flex items-center border font-medium focus:outline-none focus:ring-1',
              'rounded-md border-gray-300 bg-white px-2.5 py-1 text-sm shadow-sm focus:ring-offset-2',
              'text-gray-700 hover:bg-gray-100 focus:border-primary-500 focus:ring-primary-500  disabled:cursor-not-allowed',
              'disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            )}
            target='_blank'
          >
            Export to CSV
          </CSVLink>,
        ]
      : []
  }, [csvData, visualizationTabValue])

  const tableHeaderValues = useMemo(() => ['', ...convertGroupChartDataToTableHead(data)], [data])
  const tableBody = useMemo(
    () => convertGroupChartDataToTableBody(data, unit, numberRounding),
    [data, unit, numberRounding],
  )
  const tableVisualizationValues = useMemo(
    () => convertGroupChartDataToVisualizationValues(data),
    [data],
  )

  const setCsvExportData = useCallback((data: CsvData) => setCsvData(data), [setCsvData])

  return (
    <VisualizationContainer
      label={label}
      description={description}
      toggleActions={toggleActions}
      selectActions={selectActions}
      otherActions={[
        ...otherActions,
        <Tabs
          key='select-visualization'
          variant='secondary'
          size='xs'
          options={visualizationOtions}
          value={visualizationTabValue}
          onChange={onChangeVisualization as (value: string) => void}
        />,
        ...dataSelector,
        ...csvExportButton,
      ]}
    >
      {visualizationTabValue === visualizationOtions[0].value ? (
        <GroupedBarChart
          data={data}
          unit={unit}
          onClick={onClick}
          visibleDataAmount={visibleDataAmount}
        />
      ) : (
        <DataDisplayTable
          head={tableHeaderValues}
          body={tableBody}
          visualizationValues={tableVisualizationValues}
          onClick={onClick && onTableClick}
          setExportedData={setCsvExportData}
        />
      )}
    </VisualizationContainer>
  )
}

export default GroupedBarChartAndTableDisplay
