import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { Button, Divider } from '../../../common/designs'
import { useDashboard, useEnhancedNavigate } from '../../../common/hooks'
import { MainPathEnum, SubPathEnum } from '../../../common/types/routing'
import NavigationHeader from './NavigationHeader'
import TeamSelect from './TeamSelect'
import TimePeriodSelect from './TimePeriodSelect'
import TopLevelNavigation from './TopLevelNavigation'

type NavBranch = Record<number, { label: string; to: string; isPlatformViewerView?: true }[]>
type NavTree = Partial<Record<MainPathEnum, NavBranch>>

const masterNavTree: NavTree = {
  [MainPathEnum.START]: {
    1.1: [
      { label: 'Get Started', to: '' },
      { label: 'Recommendations', to: `/${SubPathEnum.RECOMMENDATIONS}` },
    ],
  },
  [MainPathEnum.DATA_FLOWS]: {
    1.1: [{ label: 'Network', to: '' }],
  },
  [MainPathEnum.BUSINESS_APPS]: {
    1.1: [
      { label: 'Time', to: '' },
      { label: 'Trends', to: `/${SubPathEnum.TRENDS}` },
      { label: 'Teams', to: `/${SubPathEnum.TEAMS}` },
      { label: 'Activities', to: `/${SubPathEnum.ACTIVITIES}` },
      { label: 'Navigations', to: `/${SubPathEnum.NAVIGATIONS}` },
    ],
    2.1: [
      { label: 'Time', to: '' },
      { label: 'Trends', to: `/${SubPathEnum.TRENDS}` },
      { label: 'Teams', to: `/${SubPathEnum.TEAMS}` },
      { label: 'Activities', to: `/${SubPathEnum.ACTIVITIES}` },
      { label: 'Navigations', to: `/${SubPathEnum.NAVIGATIONS}` },
    ],
    3.1: [
      { label: 'Time', to: '' },
      { label: 'Trends', to: `/${SubPathEnum.TRENDS}` },
      { label: 'Navigations', to: `/${SubPathEnum.NAVIGATIONS_SANKEY}` },
      { label: 'Copy Pastes', to: `/${SubPathEnum.COPY_PASTES}` },
      { label: 'Navigation Flows', to: `/${SubPathEnum.NAVIGATIONS}` },
    ],
  },
  [MainPathEnum.PROCESSES]: {
    1.1: [
      { label: 'Process Flows', to: '', isPlatformViewerView: true },
      { label: 'Task Discovery', to: `/${SubPathEnum.TASK_DISCOVERY}` },
    ],
    2.1: [
      { label: 'Overview', to: `/${SubPathEnum.OVERVIEW}`, isPlatformViewerView: true },
      { label: 'Analysis', to: `/${SubPathEnum.ANALYSIS}`, isPlatformViewerView: true },
      { label: 'Trends', to: `/${SubPathEnum.TRENDS}`, isPlatformViewerView: true },
      { label: 'Insights', to: `/${SubPathEnum.INSIGHTS}` },
      { label: 'Variants', to: `/${SubPathEnum.VARIANTS}` },
      { label: 'Conformance', to: `/${SubPathEnum.CONFORMANCE}` },
      { label: 'User Activities', to: `/${SubPathEnum.USER_ACTIVITIES}` },
    ],
    2.2: [{ label: 'Variants', to: `/${SubPathEnum.TASK_VARIANTS}` }],
  },
  [MainPathEnum.OPPORTUNITIES]: {
    1.1: [
      { label: 'Board', to: '' },
      { label: 'Roadmap', to: `/${SubPathEnum.ROADMAP}` },
    ],
  },
  [MainPathEnum.FAVORITES]: {
    1.1: [
      { label: 'Company Shared', to: '' },
      { label: 'Private', to: `/${SubPathEnum.PRIVATE}` },
    ],
  },
}

interface Loc {
  main: MainPathEnum
  layer: number
  subIndex: number
  isFiltersHidden: boolean
  isTimePeriodHidden: boolean
  isTeamsHidden: boolean
}

interface Props {
  onFilterBtnClick: () => void
  getIsDisabledFilterBtnState: () => boolean
}

const MainNavigation = ({ onFilterBtnClick, getIsDisabledFilterBtnState }: Props) => {
  const location = useLocation()
  const { generateEnhancedPath } = useEnhancedNavigate()
  const {
    user: { isPlatformViewer },
  } = useDashboard()

  const [loc, setLoc] = useState<Loc>({
    main: MainPathEnum.START,
    layer: 1.1,
    subIndex: 0,
    isFiltersHidden: true,
    isTimePeriodHidden: false,
    isTeamsHidden: false,
  })

  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fragments = location.pathname.substring(1).split('/') // Substring remove leading slash

    const isFiltersHidden = getIsDisabledFilterBtnState()
    const updatedLoc: Loc = {
      ...loc,
      main: fragments[0] as MainPathEnum,
      isFiltersHidden,
      isTimePeriodHidden: false,
      isTeamsHidden: false,
    }

    switch (fragments[0]) {
      case MainPathEnum.START:
        if (fragments[1] === undefined)
          return setLoc({
            ...updatedLoc,
            layer: 1.1,
            subIndex: 0,
            isTimePeriodHidden: true,
            isTeamsHidden: true,
          })
        if (fragments[1] === SubPathEnum.RECOMMENDATIONS)
          return setLoc({
            ...updatedLoc,
            layer: 1.1,
            subIndex: 1,
            isTimePeriodHidden: true,
            isTeamsHidden: true,
          })
        break

      case MainPathEnum.DATA_FLOWS:
        if (fragments[1] === undefined) return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 0 })
        break

      case MainPathEnum.BUSINESS_APPS:
        // First layer
        if (fragments[1] === undefined) return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 0 })
        if (fragments[1] === SubPathEnum.TRENDS)
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 1 })
        if (fragments[1] === SubPathEnum.TEAMS)
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 2, isTeamsHidden: true })
        if (fragments[1] === SubPathEnum.ACTIVITIES)
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 3 })
        if (fragments[1] === SubPathEnum.NAVIGATIONS)
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 4 })

        // Second layer
        if (fragments[1] === 'window') {
          if (fragments[3] === undefined) return setLoc({ ...updatedLoc, layer: 3.1, subIndex: 0 })
          if (fragments[3] === SubPathEnum.TRENDS)
            return setLoc({ ...updatedLoc, layer: 3.1, subIndex: 1 })
          if (fragments[3] === SubPathEnum.NAVIGATIONS_SANKEY)
            return setLoc({ ...updatedLoc, layer: 3.1, subIndex: 2 })
          if (fragments[3] === SubPathEnum.COPY_PASTES)
            return setLoc({ ...updatedLoc, layer: 3.1, subIndex: 3 })
          if (fragments[3] === SubPathEnum.NAVIGATIONS)
            return setLoc({ ...updatedLoc, layer: 3.1, subIndex: 4 })
        }

        // Third layer
        if (fragments[1]) {
          if (fragments[2] === undefined) return setLoc({ ...updatedLoc, layer: 2.1, subIndex: 0 })
          if (fragments[2] === SubPathEnum.TRENDS)
            return setLoc({ ...updatedLoc, layer: 2.1, subIndex: 1 })
          if (fragments[2] === SubPathEnum.TEAMS)
            return setLoc({ ...updatedLoc, layer: 2.1, subIndex: 2, isTeamsHidden: true })
          if (fragments[2] === SubPathEnum.ACTIVITIES)
            return setLoc({ ...updatedLoc, layer: 2.1, subIndex: 3 })
          if (fragments[2] === SubPathEnum.NAVIGATIONS)
            return setLoc({ ...updatedLoc, layer: 2.1, subIndex: 4 })
        }

        break

      case MainPathEnum.PROCESSES:
        if (fragments[1] === undefined)
          return setLoc({
            ...updatedLoc,
            layer: 1.1,
            subIndex: 0,
            isTimePeriodHidden: true,
            isTeamsHidden: true,
          })
        if (fragments[1] === SubPathEnum.TASK_DISCOVERY) {
          if (fragments[3] === SubPathEnum.TASK_VARIANTS)
            return setLoc({ ...updatedLoc, layer: 2.2, subIndex: 0 })
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 1 })
        }

        if (fragments[1]) {
          if (fragments[2] === SubPathEnum.OVERVIEW)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 0,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
          if (fragments[2] === SubPathEnum.ANALYSIS)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 1,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
          if (fragments[2] === SubPathEnum.TRENDS)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 2,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
          if (fragments[2] === SubPathEnum.INSIGHTS)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 3,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
          if (fragments[2] === SubPathEnum.VARIANTS)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 4,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
          if (fragments[2] === SubPathEnum.CONFORMANCE)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 5,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })

          if (fragments[2] === SubPathEnum.USER_ACTIVITIES)
            return setLoc({
              ...updatedLoc,
              layer: 2.1,
              subIndex: 6,
              isTimePeriodHidden: true,
              isTeamsHidden: true,
            })
        }

        break
      case MainPathEnum.OPPORTUNITIES:
        if (fragments[1] === undefined)
          return setLoc({
            ...updatedLoc,
            layer: 1.1,
            subIndex: 0,
            isTimePeriodHidden: true,
            isTeamsHidden: true,
          })
        if (fragments[1] === 'roadmap')
          return setLoc({
            ...updatedLoc,
            layer: 1.1,
            subIndex: 1,
            isTimePeriodHidden: true,
            isTeamsHidden: true,
          })
        break

      case MainPathEnum.FAVORITES:
        if (fragments[1] === undefined)
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 0, isTeamsHidden: true })
        if (fragments[1] === 'private')
          return setLoc({ ...updatedLoc, layer: 1.1, subIndex: 1, isTeamsHidden: true })
        break

      default:
        break
    }
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const sideMenuWidth = loc.isTeamsHidden && loc.isTimePeriodHidden ? 'w-28' : 'w-96'

  return (
    <div ref={rootRef}>
      {!isPlatformViewer && <NavigationHeader />}

      <Divider color='lightGray' />

      {/* MAIN */}
      <div className='flex flex-col items-center justify-center'>
        {!isPlatformViewer && <TopLevelNavigation />}

        <Divider color='lightGray' />

        <div className='flex h-24 w-full flex-wrap items-center justify-between px-8 lg:h-12 lg:flex-nowrap'>
          <div className={clsx('order-2 flex gap-2 lg:order-1', sideMenuWidth)}>
            {loc.isTeamsHidden ? null : <TeamSelect />}
          </div>

          {/* SUB */}
          <nav
            className='order-1 flex min-w-full justify-center space-x-4 lg:order-2 lg:min-w-0'
            aria-label='Navigation tabs'
          >
            {(masterNavTree[loc.main] as NavBranch)[loc.layer]?.map((t, i) => {
              let relativePath = location.pathname.split('/')
              relativePath.shift()

              if (loc.layer === 1.1) relativePath = relativePath.slice(0, 1)
              if (loc.layer === 2.1 || loc.layer === 2.2) relativePath = relativePath.slice(0, 2)
              if (loc.layer === 3.1) relativePath = relativePath.slice(0, 3)

              if (isPlatformViewer && !t.isPlatformViewerView) return null

              return (
                <NavLink
                  key={t.to}
                  to={generateEnhancedPath(`${relativePath.join('/')}${t.to}`)}
                  className={clsx(
                    'block rounded-full px-3 py-0.5 font-inter text-sm font-medium',
                    loc.subIndex === i
                      ? 'bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                      : 'text-gray-900 hover:bg-gray-100 focus:border-indigo-500 focus:ring-indigo-500',
                  )}
                >
                  {t.label}
                </NavLink>
              )
            })}
          </nav>

          <div className={clsx('order-2 flex justify-end gap-2 lg:order-3', sideMenuWidth)}>
            {loc.isTimePeriodHidden ? null : <TimePeriodSelect disabled={loc.isTimePeriodHidden} />}

            <Button
              variant='white'
              size='xs'
              iconStart={<AdjustmentsHorizontalIcon />}
              onClick={onFilterBtnClick}
              disabled={loc.isFiltersHidden}
            >
              Filters
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNavigation

