import { FunnelIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'
import { useMemo } from 'react'

import { Badge } from '../../../common/designs'
import { useDashboard, useLocalStorage } from '../../../common/hooks'
import type { TagFilter } from '../../../common/types/common'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../common/types/storage'
import type { ProcessFiltersStorageContent } from '../../../common/types/storage'
import { removeEmptyKeyPairs } from '../../../common/utils/utils'
import {
  InteractionLogFilterData,
  ProcessMetricFilters,
  TaskFilterData,
  TransactionFilters,
} from '../types'

type Filters = Omit<TransactionFilters, 'zooming_filters'> & ProcessMetricFilters
const filterMapper: Record<keyof Filters, string> = {
  // ProcessMetricFilters
  min_start_date: 'Min start date',
  max_start_date: 'Max start date',
  min_end_date: 'Min end date',
  max_end_date: 'Max end date',
  team_ids: 'Teams',
  min_active_work_sec: 'Min active work (min)',
  max_active_work_sec: 'Max active work (min)',
  min_duration_sec: 'Min duration (h)',
  max_duration_sec: 'Max duration (h)',
  // ProcessPropertyFilters
  transaction_id_filters: 'Case id',
  window_step_variant_filters: 'Variants',
  team_count_filters: 'Team amount',
  team_list_filters: 'Teams involved',
  step_name_filters: 'Process tasks',
  step_name_filters_match_all: 'Process tasks (match all)',
  start_step_name_filters: 'Start task name',
  end_step_name_filters: 'End task name',
  step_count_filters: 'Task amount',
  touch_count_filters: 'Touch amount',
  self_loop_count_filters: 'Self loop amount',
  // BusinessPropertyFilters
  tag_filters: 'Tag filter',
  tag_filters_match_all: 'Tag filter (match all)',
  // SupportingSystemFilters
  process_application_filters: 'Process applications',
  process_application_filters_match_all: 'Process applications (match all)',
  related_window_filters: 'Supporting windows',
  related_window_filters_match_all: 'Supporting windows (match all)',
  // UserInteractionFilters
  copy_count_filter: 'Copy counts',
  paste_count_filter: 'Paste counts',
  keyboard_press_count_filter: 'Keyboard press counts',
  mouse_click_count_filter: 'Mouse click counts',
  click_element_filter: 'Click elements',
  data_input_element_filter: 'Data input element',
  data_input_content_filter: 'Data input content',
}

const interactionMapper: Record<keyof InteractionLogFilterData, string> = {
  value_filter: 'Value',
  min_value: 'Min',
  max_value: 'Max',
}

interface ActiveFilterItemProps {
  label: string
  value: string
  key: string
}

const ActiveFilterItem = ({ label, value, key }: ActiveFilterItemProps) => {
  return (
    <Badge variant='gray' icon={<FunnelIcon />} key={key}>
      {label} | {value}
    </Badge>
  )
}

const ActiveFilterBar = () => {
  const { teams } = useDashboard()
  const storage = useLocalStorage()

  const activeFilters = useMemo(() => {
    const dirtyFilters = _.omit(
      storage.getItem<ProcessFiltersStorageContent>(StorageNameEnum.PROCESS_FILTERS) ?? {},
      ProcessFiltersStorageContentEnum.ZOOMING,
    )
    return removeEmptyKeyPairs(dirtyFilters) as ProcessFiltersStorageContent
  }, [storage])

  const getTeamName = (teamId: string) => Object.values(teams).find((t) => t.id === teamId)?.name
  const getTeamNames = (teamIds: string[]) => teamIds.map(getTeamName).join(', ')
  const handleTeamIdsFilter = (values: string[]) => values.map(getTeamName).join(', ')
  const handleTeamListFilters = (values: Array<string[]>) => values.map(getTeamNames).join(' | ')
  const handleTagFilters = (values: TagFilter[]) => {
    return values.map((tag: TagFilter) => {
      const tagValues = tag.values.join(', ')
      return <ActiveFilterItem label={tag.tag_name} value={tagValues} key={tag.tag_name} />
    })
  }
  const handleInteractionLogFilters = (values: InteractionLogFilterData) => {
    return Object.entries(values)
      .map(([k, v]) => {
        return `${interactionMapper[k as keyof InteractionLogFilterData]}: ${v}`
      })
      .join(' | ')
  }

  if (_.isObject(activeFilters) && _.isEmpty(activeFilters)) return null

  return (
    <div className='mb-4 flex flex-wrap gap-2 rounded-md bg-white p-2 shadow ring-2 ring-black/5'>
      {Object.values(activeFilters).map((categories) => {
        return Object.entries(categories).map(([key, values]) => {
          switch (key) {
            case 'min_duration_sec':
            case 'max_duration_sec':
              values = (values as number) / 60 / 60
              break
            case 'min_active_work_sec':
            case 'max_active_work_sec':
              values = (values as number) / 60
              break
            case 'team_ids':
              values = handleTeamIdsFilter(values as string[])
              break
            case 'team_list_filters':
              values = handleTeamListFilters(values as Array<string[]>)
              break
            case 'tag_filters':
            case 'tag_filters_match_all':
              return handleTagFilters(values as TagFilter[])
            case 'copy_count_filter':
            case 'paste_count_filter':
            case 'keyboard_press_count_filter':
            case 'mouse_click_count_filter':
            case 'click_element_filter':
            case 'data_input_element_filter':
            case 'data_input_content_filter':
              values = handleInteractionLogFilters(
                values as TaskFilterData | InteractionLogFilterData,
              )
              break
            default:
              values = Array.isArray(values) ? values.join(', ') : values
              break
          }

          const label = filterMapper[key as keyof Filters]

          return <ActiveFilterItem label={label} value={String(values)} key={key} />
        })
      })}
    </div>
  )
}

export default ActiveFilterBar
