import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { EnvEnum } from '../common/types/common'
import { onHooklessSignOut } from '../features/Authentication/useAuthentication'
import { ENVIRONMENT } from './configs'
import { STATUS } from './constants'

const genericErrToast = 'Unable to fetch data'

export const errorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const originalStatus = action.payload?.originalStatus
    const status = action.payload?.status

    if (ENVIRONMENT === EnvEnum.DEV) {
      console.error(`[errorLogger] Error: ${action.error.message}`)
    }

    if (
      status === STATUS.HTTP_401_UNAUTHORIZED ||
      originalStatus === STATUS.HTTP_401_UNAUTHORIZED
    ) {
      onHooklessSignOut()
    } else if (
      originalStatus === STATUS.HTTP_500_INTERNAL_SERVER_ERROR ||
      originalStatus === STATUS.HTTP_404_NOT_FOUND ||
      !action.payload?.data?.errMsg
    ) {
      toast.error(genericErrToast)
    } else {
      toast.error(action.payload?.data?.errMsg || action.payload.message)
    }
  }

  return next(action)
}

