import { getISOWeek } from 'date-fns'

import { teamColorMap } from '../../../app/theme'
import { Teams } from '../../../common//utils/UtilityWrapper/types'
import { GroupedBarChartDataEntry } from '../../../common/components/Charts/types'
import type { GroupedWindowUsageData } from '../types'
import { intervalStringToObject } from '../windowUsageUtils'

export const convertTrendServerDataToChartData = (data: GroupedWindowUsageData) => {
  const dataCopy = { ...data }
  delete dataCopy.totals
  const groups = Object.entries(dataCopy).map(([key, valueArr]) => {
    const { start } = intervalStringToObject(key)
    const groupName = `Week ${getISOWeek(start)}`

    return {
      groupName,
      data: valueArr.map((item) => ({ label: item.name, value: item.time_h })),
    }
  })

  groups.sort((a, b) => a.groupName?.localeCompare(b.groupName ?? '') ?? 0)
  return groups
}

export const convertTrendDefaultDataToRelativeData = (data: GroupedBarChartDataEntry[]) => {
  const totalsForGroups = data.map((d) => {
    const valuesArray = d.data.map((item) => item.value)
    return valuesArray.reduce((partialSum, a) => partialSum + a, 0)
  })

  return data.map((entry, i) => {
    const entryCopy = { ...entry }
    const totalValue = totalsForGroups[i]
    entryCopy.data = entryCopy.data.map((dataEntry) => {
      const dataEntryCopy = { ...dataEntry }
      dataEntryCopy.value = (dataEntryCopy.value / totalValue) * 100
      return dataEntryCopy
    })
    return entryCopy
  })
}

export const convertTeamServerDataToChartData = (data: GroupedWindowUsageData, teams: Teams) => {
  const dataCopy = { ...data }
  delete dataCopy.totals
  return Object.entries(dataCopy).map(([key, valueArr]) => {
    return {
      groupName: teams[key as keyof typeof teams]?.name ?? key,
      color: teamColorMap[teams[key as keyof typeof teams]?.colorIndex],
      data: valueArr.map((item) => ({ label: item.name, value: item.time_h })),
    }
  })
}

export const convertTeamDefaultDataToRelativeData = (data: GroupedBarChartDataEntry[]) => {
  const totalsForGroups = data.map((d) => {
    const valuesArray = d.data.map((item) => item.value)
    return valuesArray.reduce((partialSum, a) => partialSum + a, 0)
  })

  return data.map((entry, i) => {
    const entryCopy = { ...entry }
    const totalValue = totalsForGroups[i]
    entryCopy.data = entryCopy.data.map((dataEntry) => {
      const dataEntryCopy = { ...dataEntry }
      dataEntryCopy.value = (dataEntryCopy.value / totalValue) * 100
      return dataEntryCopy
    })
    return entryCopy
  })
}
