import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { useValidateDataCollectionRulesMutation } from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Button, InputField, Modal, Select, Text, TextArea } from '../../common/designs'
import type { HTTPException } from '../../common/types/api'
import useManageServerTestCasesAndConfigs from '../ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import type { AddReactionFormData, Reaction } from './types'
import { addReactionToRule } from './xRayAddReactionsToRules'

interface Props {
  selectedApplication: AppTestCaseGroup | null
  onClose: () => void
}

const formSchema = yup
  .object({
    windowName: yup.string().required(),
    reaction: yup.string().required(),
    elementName: yup.string().required(),
  })
  .required()

const AddReactionModal = ({ selectedApplication, onClose }: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<AddReactionFormData>({
    resolver: yupResolver(formSchema) as Resolver<AddReactionFormData>,
  })

  const [validationError, setValidationError] = useState<string | null>(null)
  const [reactionsForSelectedWindow, setReactionsForSelectedWindow] = useState<Reaction[]>([])

  const [validateRules, { isLoading: isLoadingValidation }] =
    useValidateDataCollectionRulesMutation()
  const { updateRules, isUploadingRules } = useManageServerTestCasesAndConfigs()

  const windowName = watch('windowName')
  useEffect(() => {
    const windowData = selectedApplication?.windowLevelData.find(
      (window) => window.windowName === windowName,
    )

    if (!windowData) return setReactionsForSelectedWindow([])

    const reactions = [] as Reaction[]

    windowData.rulesForTestCases.forEach((rule) => reactions.push(...rule.reactions))
    setReactionsForSelectedWindow(reactions)
  }, [windowName, selectedApplication])

  const windowNameOptions = useMemo(() => {
    const options = selectedApplication?.windowLevelData.map(({ windowName }) => ({
      label: windowName,
      value: windowName,
    }))
    reset({ windowName: options?.length ? options[0].value : '' })
    return options
  }, [selectedApplication?.windowLevelData, reset])

  const onFormSubmit: SubmitHandler<AddReactionFormData> = async (
    formData: AddReactionFormData,
  ) => {
    const windowData = selectedApplication?.windowLevelData.find(
      (window) => window.windowName === formData.windowName,
    )
    if (!windowData) return

    const updatedRules = addReactionToRule(
      windowData.rulesForTestCases,
      formData.reaction,
      formData.elementName,
    )

    setValidationError(null)
    const res = await validateRules({ rules: updatedRules })
    if (res && 'error' in res) {
      const errorData = (res as HTTPException).error.data
      return setValidationError(errorData.detail ?? errorData.message ?? null)
    }

    await updateRules(updatedRules)
    onClose()
    toast.success('Reaction Added')
  }

  return (
    <Modal
      label={`Add reaction for ${selectedApplication?.appName}`}
      open={Boolean(selectedApplication)}
      onClose={onClose}
    >
      {isUploadingRules || isLoadingValidation ? (
        <InlineLoader />
      ) : (
        <form onSubmit={handleSubmit(onFormSubmit)} className='space-y-4' id='config-wizard-form'>
          <Controller
            name='windowName'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                label='Select Window'
                size='s'
                options={windowNameOptions ?? []}
                error={error?.message}
                {...field}
              />
            )}
          />

          <Text size='xs'>{reactionsForSelectedWindow.length} Existing Reactions</Text>

          <Controller
            name='reaction'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextArea label='Paste Reaction' rows={5} error={error?.message} {...field} />
            )}
          />

          <Controller
            name='elementName'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputField
                size='s'
                label='Name for the captured element'
                error={error?.message}
                {...field}
              />
            )}
          />
          {validationError && <Text color='error'>{validationError}</Text>}

          <Button type='submit' size='s'>
            Save
          </Button>
        </form>
      )}
    </Modal>
  )
}

export default AddReactionModal
