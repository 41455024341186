import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { LinkButton, Table } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import ApplicationTableTitle from './ApplicationTableTitle'
import { ConfigureDataCollectionSubPathEnum } from './types'

interface Props {
  applicationList: AppTestCaseGroup[]
}

const ApplicationTable = ({ applicationList }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <div className='-ml-4'>
      <Table
        key='Applications'
        head={['Application name', '']}
        body={
          applicationList.map((application) => {
            return [
              <ApplicationTableTitle key={application.appName} application={application} />,
              <div className='space-x-4' key={`${application.appName}-actions`}>
                <LinkButton
                  onClick={() => {
                    navigate(
                      `${ConfigureDataCollectionSubPathEnum.APPS}/${encodeURIComponent(
                        application.appName,
                      )}`,
                    )
                  }}
                >
                  Data Collection Rules
                </LinkButton>
              </div>,
            ]
          }) ?? []
        }
      />
    </div>
  )
}

export default ApplicationTable
