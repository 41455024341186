import { useCallback } from 'react'

import { AZ_STORAGE_ACCOUNT_URL_DASHBOARD } from '../../app/configs'
import { STORAGE_ACCOUNT } from '../../app/constants'
import { useDashboard } from './'

const useStorageAccountCasesViewUrlGenerator = () => {
  const { fileStore, company } = useDashboard()

  const generateUrl = useCallback(
    (filePath: string) => {
      return `${AZ_STORAGE_ACCOUNT_URL_DASHBOARD}/${company.id}/${STORAGE_ACCOUNT.OPPORTUNITIES.CONTAINER_NAME}/${STORAGE_ACCOUNT.OPPORTUNITIES.FOLDER_PATH}/${filePath}?${fileStore.token}`
    },
    [company.id, fileStore.token],
  )

  return [generateUrl]
}

export default useStorageAccountCasesViewUrlGenerator

