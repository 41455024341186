import { useState } from 'react'

import { useLocalStorage } from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import { UserInteractionFilters } from '../../types'
import DimensionFilterBlock from './DimensionFilterBlock'
import FilterItem from './FilterItem'
import UserInteractionFilterBlock from './UserInteractionFilterBlock'
import { supportingSystemDimensions } from './constants'
import type { FilterConfigData } from './types'
import { removeDimensionFilters, updateDimensionFilters } from './utils'

const TaskFilters = () => {
  const [configurations, setConfigurations] = useState<FilterConfigData[]>([])
  const [selectedSupportSystemFilters, setSelectedSupportSystemFilters] = useState<
    FilterConfigData[]
  >([])
  const [selectedInteractionFilters, setSelectedInteractionFilters] =
    useState<UserInteractionFilters>({})
  const storage = useLocalStorage()

  const onApply = () => {
    // Handle supporting systems state changes and update local storage.
    updateDimensionFilters(
      selectedSupportSystemFilters,
      setSelectedSupportSystemFilters,
      ProcessFiltersStorageContentEnum.SUPPORTING_SYSTEM,
      storage,
    )

    storage.updateItem(StorageNameEnum.PROCESS_FILTERS, {
      [ProcessFiltersStorageContentEnum.USER_INTERACTION]: selectedInteractionFilters,
    })
  }

  const onReset = () => {
    // Handle supporting systems state changes and update local storage.
    setSelectedSupportSystemFilters([])
    setSelectedInteractionFilters({})
    removeDimensionFilters(ProcessFiltersStorageContentEnum.SUPPORTING_SYSTEM, storage)
    removeDimensionFilters(ProcessFiltersStorageContentEnum.USER_INTERACTION, storage)
  }

  return (
    <div className='flex flex-col'>
      <DimensionFilterBlock
        title='Supporting systems'
        storageKey={ProcessFiltersStorageContentEnum.SUPPORTING_SYSTEM}
        dimensions={supportingSystemDimensions}
        configurations={configurations}
        setConfigurations={setConfigurations}
        selected={selectedSupportSystemFilters}
        setSelected={setSelectedSupportSystemFilters}
      />

      <FilterItem.Divider />

      <UserInteractionFilterBlock
        title='User interactions'
        selectedFilterData={selectedInteractionFilters}
        setSelectedFilterData={setSelectedInteractionFilters}
      />

      {/* <UserActionDataFilterBlock
        title='User actions'
        selectedFilterData={selectedUserActionFilters}
        setSelectedFilterData={setSelectedUserActionFilters}
      /> */}

      <FilterItem.Divider />

      <FilterItem.Actions onReset={onReset} onApply={onApply} />
    </div>
  )
}

export default TaskFilters
