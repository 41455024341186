import { useMemo, useState } from 'react'

import VariableCombinationBarChart from '../../../common/components/Charts/VariableCombinationBarChart'
import { Button, Checkboxes } from '../../../common/designs'
import type { UserActivitiesData } from '../types'

interface Props {
  checkboxOptions: Array<{ label: string; value: string }>
  defaultSelectedValues: string[]
  data: UserActivitiesData[]
}

const UserActivitiesBarchart = ({ checkboxOptions, defaultSelectedValues, data }: Props) => {
  const [checkboxSelected, setCheckboxSelected] = useState<string[]>(defaultSelectedValues)

  const [shownResults, setShownResults] = useState<number>(10)

  const visualizationData = useMemo(() => {
    const dataWithSelecetedVariables = data.map(({ value, variables }) => {
      return { value, variables: variables.filter((v) => checkboxSelected.includes(v.label)) }
    })

    const groupedData = dataWithSelecetedVariables.reduce(
      (accumulatorObject, dataPoint) => {
        const groupKey = JSON.stringify(dataPoint.variables)
        groupKey in accumulatorObject
          ? (accumulatorObject[groupKey] += dataPoint.value)
          : (accumulatorObject[groupKey] = dataPoint.value)
        return accumulatorObject
      },
      {} as { [key: string]: number },
    )

    return Object.entries(groupedData)
      .map(([key, value]) => ({
        value,
        variables: JSON.parse(key),
      }))
      .sort((a, b) => b.value - a.value)
  }, [checkboxSelected, data])

  return (
    <div className='space-y-6'>
      <Checkboxes
        label='Variables'
        className='flex gap-3 space-y-0'
        options={checkboxOptions}
        values={checkboxSelected}
        onChange={setCheckboxSelected}
      />
      <VariableCombinationBarChart data={visualizationData.slice(0, shownResults)} />
      {visualizationData.length > shownResults && (
        <Button variant='secondary' wFull onClick={() => setShownResults((val) => val + 10)}>
          Show more
        </Button>
      )}
    </div>
  )
}

export default UserActivitiesBarchart
