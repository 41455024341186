import { Loader } from '../..'
import { Text } from '../../../designs'
import FavoriteDisplayItem from './FavoriteDisplayItem'
import { TrendQuery } from './types'

export enum FavoriteTypeEnum {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
  OPPORTUNITY = 'OPPORTUNITY',
}

interface Props {
  queries: TrendQuery[] | undefined
  type: FavoriteTypeEnum
}

const FavoritesDisplay = ({ queries, type }: Props) => {
  if (!queries) return <Loader />
  if (!queries.length) {
    return (
      <Text variant='h6' className='mt-20 text-center'>
        No favorites have been created yet.
      </Text>
    )
  }

  return (
    <>
      {queries.map((query) => (
        <FavoriteDisplayItem
          type={type}
          query={query}
          key={`${query.automatic_name}-${query.custom_name}`}
        />
      ))}
    </>
  )
}

export default FavoritesDisplay

