import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { ReactText, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useDeleteAttachmentMutation,
  useGetCaseAttachmentsQuery,
  useUpdateAttachmentMutation,
} from '../../../../../app/apiSlice'
import { MAX_FILE_UPLOAD_SIZE } from '../../../../../app/constants'
import { ReactComponent as AttachmentPlaceholder } from '../../../../../common/assets/icons/icon-attachment-placeholder-filled.svg'
import { Button, IconButton, InputField, Modal, Text, Tooltip } from '../../../../../common/designs'
import {
  useAddAttachmentToCase,
  useDashboard,
  useStorageAccountCasesViewUrlGenerator,
} from '../../../../../common/hooks'
import type { Attachment } from '../../../types'

interface HTMLInputTarget {
  target: HTMLInputElement
}

interface Props {
  caseId: number
}

const AttachmentControl = ({ caseId }: Props) => {
  const { user } = useDashboard()

  const [renameAttachmentModalOpen, setRenameAttachmentModalOpen] = useState<boolean>(false)
  const [attachmentSelected, setAttachmentSelected] = useState<Attachment | null>(null)
  const [modalImgOpenIndex, setModalImgOpenIndex] = useState<number | null>(null)

  const { data: attachments } = useGetCaseAttachmentsQuery(caseId)
  const [updateAttachmentMutation] = useUpdateAttachmentMutation()
  const [deleteAttachmentMutation] = useDeleteAttachmentMutation()
  const [addAttachmentToCase] = useAddAttachmentToCase()
  const [generateUrl] = useStorageAccountCasesViewUrlGenerator()

  const onFileChange = async ({ target }: HTMLInputTarget): Promise<ReactText | void> => {
    const file = target.files?.[0]

    if (!file) return
    if (file?.size > MAX_FILE_UPLOAD_SIZE) return toast.warning('File size exceed limit of 10 MB')

    addAttachmentToCase(caseId, file)
  }

  const onDelete = async (attachment: Attachment) => {
    try {
      fetch(generateUrl(attachment.path), { method: 'DELETE' }).then(() => {
        deleteAttachmentMutation({
          caseId,
          attachmentId: attachment.id,
          body: attachment,
        })
      })
    } catch (err) {
      console.error('Something went wrong')
    }
  }

  const onUpdateAttachment = async () => {
    if (!attachmentSelected) return

    setRenameAttachmentModalOpen(false)
    const body = attachmentSelected as Attachment

    updateAttachmentMutation({ caseId, attachmentId: attachmentSelected.id, body })
  }

  const isModalImgOpen = modalImgOpenIndex === 0 || Boolean(modalImgOpenIndex)

  if (!attachments) return null

  return (
    <>
      <Text size='s' weight={500} className='mb-1'>
        Attachments
      </Text>

      <div className='flex items-center gap-4'>
        {attachments.map((a, i) => {
          const src = generateUrl(a.path)
          const tooltipTitle = a.name || `Attachment ${i + 1}`
          const isImage = a?.type?.includes('image')

          return (
            <div className='group' key={a.path}>
              {isImage ? (
                <Tooltip placement='b' text={tooltipTitle}>
                  <img
                    alt={`Attachment ${i + 1}`}
                    className='h-[64px] w-[104px] rounded-md border'
                    src={src}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement='b' text={tooltipTitle}>
                  <AttachmentPlaceholder className='h-[64px] w-[104px]' />
                </Tooltip>
              )}

              <div className='relative top-[-48px] flex justify-center gap-1'>
                {!user.isDataReader && (
                  <IconButton
                    size='xs'
                    variant='destructive'
                    icon={<TrashIcon />}
                    className='invisible justify-center group-hover:visible'
                    onClick={() => onDelete(a)}
                  />
                )}

                {!user.isDataReader && (
                  <IconButton
                    size='xs'
                    variant='white'
                    icon={<PencilIcon />}
                    className='invisible justify-center group-hover:visible'
                    onClick={() => {
                      setAttachmentSelected(a)
                      setRenameAttachmentModalOpen(true)
                    }}
                  />
                )}

                {isImage ? (
                  <IconButton
                    size='xs'
                    variant='white'
                    onClick={() => setModalImgOpenIndex(i)}
                    icon={<MagnifyingGlassIcon />}
                    className='invisible justify-center group-hover:visible'
                  />
                ) : (
                  <a href={src} target='_blank' rel='noreferrer'>
                    <IconButton
                      size='xs'
                      variant='white'
                      icon={<ArrowTopRightOnSquareIcon />}
                      className='invisible justify-center group-hover:visible'
                    />
                  </a>
                )}
              </div>
            </div>
          )
        })}

        {attachments.length <= 5 && !user.isDataReader && (
          <>
            <input
              accept='image/*,application/*,.docx,.doc,.pdf,.xlsx,.xls,.ppt,.pptx,.txt,.csv,.rtf,.zip,.rar'
              type='file'
              onChange={onFileChange}
              style={{ display: 'none' }}
              id='icon-button-file2'
            />
            <label htmlFor='icon-button-file2' className='mb-8'>
              <span className='inline-flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-transparent p-1 font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:bg-transparent'>
                + Add attachment
              </span>
            </label>
          </>
        )}
      </div>

      <Modal
        label='Edit attachment'
        open={renameAttachmentModalOpen}
        onClose={() => setRenameAttachmentModalOpen(false)}
      >
        <InputField
          label='Name'
          value={attachmentSelected?.name}
          onChange={({ target }: { target: HTMLInputElement }) =>
            setAttachmentSelected({
              ...attachmentSelected,
              name: target.value,
            } as Attachment)
          }
        />

        <div className='mt-4 flex justify-end gap-4'>
          <Button variant='white' onClick={() => setRenameAttachmentModalOpen(false)}>
            Cancel
          </Button>

          <Button onClick={onUpdateAttachment}>Save</Button>
        </div>
      </Modal>

      <Modal
        size='6xl'
        label='Opportunity attachment'
        open={isModalImgOpen}
        onClose={() => setModalImgOpenIndex(null)}
      >
        {isModalImgOpen ? (
          <img
            src={generateUrl(attachments[Number(modalImgOpenIndex)].path)}
            alt='Opportunity attachment'
            className='size-auto max-h-full max-w-full object-contain'
          />
        ) : null}
      </Modal>
    </>
  )
}

export default AttachmentControl
