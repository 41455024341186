import { trendColorArr } from '../../app/theme'

export const getVisualizationColor = (index: number) => {
  const colorIndex = index % trendColorArr.length
  return trendColorArr[colorIndex]
}

export const getColorBasedOnValuePercentage = (value: number, expectedValue: number) => {
  const percentage = (value / expectedValue) * 100
  let color

  if (percentage > 100) {
    color = 'hsla(346, 100%, 32%, 0.8)' // Vivid Crimson for more than 100%
  } else if (percentage > 90) {
    color = 'hsla(152, 100%, 20%, 0.8)' // Rich Forest Green for 91-100%
  } else if (percentage > 80) {
    color = 'hsla(146, 71%, 35%, 0.8)' // Deep Emerald for 81-90%
  } else if (percentage > 70) {
    color = 'hsla(118, 41%, 56%, 0.8)' // Meadow Green for 71-80%
  } else if (percentage > 60) {
    color = 'hsla(88, 59%, 63%, 0.8)' // Fresh Green for 61-70%
  } else if (percentage > 50) {
    color = 'hsla(73, 76%, 74%, 0.8)' // Light Lime for 51-60%
  } else if (percentage > 40) {
    color = 'hsla(44, 98%, 77%, 0.8)' // Pale Gold for 41-50%
  } else if (percentage > 30) {
    color = 'hsla(30, 98%, 69%, 0.8)' // Soft Orange for 31-40%
  } else if (percentage > 20) {
    color = 'hsla(14, 89%, 61%, 0.8)' // Bright Coral for 21-30%
  } else if (percentage > 10) {
    color = 'hsla(3, 69%, 50%, 0.8)' // Fiery Red for 11-20%
  } else {
    color = 'hsla(346, 100%, 32%, 0.8)' // Vivid Crimson for equal or less than 10%
  }

  return color
}
