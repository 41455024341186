import Graph from 'react-graph-vis'
import type { Data, GraphEvents } from 'react-graph-vis'

import { Loader } from '../../common/components'
import options from './options'

interface Props {
  data: Data | null
  events?: GraphEvents | null | undefined
}

const NetworkGraph = ({ data, events }: Props) => {
  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Graph
          graph={{ nodes: data.nodes, edges: data.edges }}
          options={options}
          events={events ?? {}}
        />
      )}
    </>
  )
}

export default NetworkGraph
