import type { ProcessPropertyFiltersKeys, SupportingSystemFiltersKeys } from '../../types'
import { GroupByFieldTypeEnum } from '../../types'
import type { DefaultDimensionFilterData } from './types'

export interface Dimensions {
  [key: string]: DefaultDimensionFilterData
}

export const processPropertyDimensions: Dimensions = {
  [GroupByFieldTypeEnum.TRANSACTION_ID]: {
    displayName: 'Case id',
    requestFieldName: 'transaction_id_filters',
    isEditable: false,
  },
  [GroupByFieldTypeEnum.WINDOW_STEP_VARIANT]: {
    displayName: 'Variants',
    requestFieldName: 'window_step_variant_filters',
    isEditable: false,
  },
  [GroupByFieldTypeEnum.TEAM_COUNT]: {
    displayName: 'Team amount',
    requestFieldName: 'team_count_filters',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.TEAM_IDS]: {
    displayName: 'Teams involved',
    requestFieldName: 'team_list_filters',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.STEP_NAMES]: {
    displayName: 'Process tasks',
    requestFieldName: 'step_name_filters',
    requestFieldNameMatchAll: 'step_name_filters_match_all',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.STEP_AMOUNT]: {
    displayName: 'Task amount',
    requestFieldName: 'step_count_filters',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.TOUCH_COUNT]: {
    displayName: 'Touch amount',
    requestFieldName: 'touch_count_filters',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.SELF_LOOP_COUNT]: {
    displayName: 'Self loop amount',
    requestFieldName: 'self_loop_count_filters',
    isEditable: true,
  },
}

export const supportingSystemDimensions: Dimensions = {
  [GroupByFieldTypeEnum.PROCESS_APPLICATION_NAMES]: {
    displayName: 'Process applications',
    requestFieldName: 'process_application_filters',
    requestFieldNameMatchAll: 'process_application_filters_match_all',
    isEditable: true,
  },
  [GroupByFieldTypeEnum.RELATED_WINDOW_NAMES]: {
    displayName: 'Supporting windows',
    requestFieldName: 'related_window_filters',
    requestFieldNameMatchAll: 'related_window_filters_match_all',
    isEditable: true,
  },
}

export const processPropertyFiltersKeys: ProcessPropertyFiltersKeys[] = [
  'transaction_id_filters',
  'window_step_variant_filters',
  'team_count_filters',
  'team_list_filters',
  'step_name_filters',
  'step_name_filters_match_all',
  'start_step_name_filters',
  'end_step_name_filters',
  'step_count_filters',
  'touch_count_filters',
  'self_loop_count_filters',
]

export const supportingSystemFiltersKeys: SupportingSystemFiltersKeys[] = [
  'process_application_filters',
  'process_application_filters_match_all',
  'related_window_filters',
  'related_window_filters_match_all',
]

