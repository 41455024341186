import { Dispatch, SetStateAction, useState } from 'react'

import { Button, Select, Toggle } from '../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../common/hooks'
import { OrderByDirectionEnum } from '../constants'
import { selectVariantOrder, setVariantOrder } from '../processesSlice'
import type { OrderByVariableValue } from '../types'

interface Props {
  orderByOptions: {
    label: OrderByVariableValue
    value: OrderByVariableValue
  }[]
}

const SortVariantsActions = ({ orderByOptions }: Props) => {
  const dispatch = useAppDispatch()
  const { variable, direction } = useAppSelector(selectVariantOrder)

  const [orderByDirection, setOrderByDirection] = useState<OrderByDirectionEnum>(direction)
  const [orderByVariable, setOrderByVariable] = useState<OrderByVariableValue>(variable)
  const [isOrdered, setIsOrdered] = useState<boolean>(false)

  const onSetOrdering = () => {
    dispatch(setVariantOrder({ variable: orderByVariable, direction: orderByDirection }))
  }

  const directionData = Object.values(OrderByDirectionEnum).map((o) => {
    return { label: o, value: o }
  })

  return (
    <div className='my-2'>
      <Toggle label='Enable variant ordering' value={isOrdered} onChange={setIsOrdered} />

      {isOrdered && (
        <div className='mt-2 flex flex-col gap-3'>
          <Select
            label='Order by'
            options={orderByOptions}
            value={orderByVariable}
            onChange={setOrderByVariable as Dispatch<SetStateAction<string>>}
          />

          <Select
            label='Order direction'
            options={directionData}
            value={orderByDirection}
            onChange={setOrderByDirection as Dispatch<SetStateAction<string>>}
          />

          <Button wFull onClick={onSetOrdering}>
            Update view
          </Button>
        </div>
      )}
    </div>
  )
}

export default SortVariantsActions
