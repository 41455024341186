export enum PerformanceReadingTypeCpuEnum {
  MAIN_PROCESS = 'MainProcess',
  PROCESSING_UNIVERSE = 'processing_universe',
  MONITORING_UNIVERSE = 'monitoring_universe',
  IO_UNIVERSE = 'io_universe',
  TOTAL_SYSTEM_CPU_LOAD = 'total_system_cpu_load',
}

export enum PerformanceReadingTypeMemoryEnum {
  PLUGIN_MEMORY_LOAD = 'plugin_memory_load',
  TOTAL_SYSTEM_MEMORY_LOAD = 'total_system_memory_load',
}

export enum PerformanceReadingTypeEnum {
  CPU = 'cpu',
  MEMORY = 'memory',
}

export interface PerformanceReadingCpu {
  company_id: string
  team_id: string
  plugin_version: string
  reading_type: PerformanceReadingTypeEnum.CPU
  reading: PerformanceReadingTypeCpuEnum
  value: number
  timestamp: Date
}

export interface PerformanceReadingMemory {
  company_id: string
  team_id: string
  plugin_version: string
  reading_type: PerformanceReadingTypeEnum.MEMORY
  reading: PerformanceReadingTypeMemoryEnum
  value: number
  timestamp: Date
}
