import { DocumentIcon } from '@heroicons/react/24/outline'
import { intlFormat } from 'date-fns'
import { useState } from 'react'

import { useGetDataDeletionRequestsQuery } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { Button, LinkButton, Table } from '../../common/designs'
import RequestDataDeletion from './RequestDataDeletion'
import UpdateDataDeletionRequest from './UpdateDataDeletionRequest'
import { DataDeletionRequestState, DeletionScopeEnum } from './types'

const DataDeletion = () => {
  const { data, isLoading } = useGetDataDeletionRequestsQuery()

  const [isRequestDataDeletionModalOpen, setIsRequestDataDeletionModalOpen] =
    useState<boolean>(false)
  const [requestIdSelected, setRequestIdSelected] = useState<null | number>(null)

  if (isLoading) return <Loader />

  return (
    <>
      <Table
        label='Data Deletion Requests'
        description='When you make a data deletion request, there will be a 7 day waiting period before it takes effect. Before that it is possible to cancel any data deletion requests.'
        action={
          <Button
            variant='destructive'
            iconStart={<DocumentIcon />}
            onClick={() => setIsRequestDataDeletionModalOpen(true)}
          >
            Request Data Deletion
          </Button>
        }
        head={[
          'State Of Request',
          'Data To Be Deleted',
          'Request Created At',
          'Request By User',
          'Actions',
        ]}
        body={[
          ...(data ?? []).map((dp) => {
            return [
              dp.state,
              dp.deletion_scope === DeletionScopeEnum.COMPANY
                ? DeletionScopeEnum.COMPANY
                : `${DeletionScopeEnum.TEAM} (${dp?.team_name})`,
              intlFormat(new Date(dp.requested_at_timestamp), {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }),
              dp.requested_by_user_full_name,
              dp.state === DataDeletionRequestState.PENDING ? (
                <LinkButton
                  key={`${dp.requested_at_timestamp}-${dp.requested_by_user_full_name}`}
                  onClick={() => setRequestIdSelected(dp.id)}
                >
                  Cancel Request
                </LinkButton>
              ) : null,
            ]
          }),
        ]}
      />

      <RequestDataDeletion
        open={isRequestDataDeletionModalOpen}
        onClose={() => setIsRequestDataDeletionModalOpen(false)}
      />

      <UpdateDataDeletionRequest
        open={Boolean(requestIdSelected)}
        onClose={() => setRequestIdSelected(null)}
        id={requestIdSelected}
      />
    </>
  )
}

export default DataDeletion
