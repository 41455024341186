import { Tooltip } from '../../../../common/designs'

const MatchAllInfoTooltip = () => {
  return (
    <Tooltip
      variant='info'
      size='2xl'
      text='By default, the analysis includes cases that match at least one of the selected values. To narrow down the results to only cases that match all of the selected values, select the option "Match all values'
    />
  )
}

export default MatchAllInfoTooltip

