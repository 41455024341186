import { ArrowDownTrayIcon, FolderArrowDownIcon } from '@heroicons/react/24/solid'
import { formatDistanceToNow } from 'date-fns'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useGetTestCasesQuery, useUploadRequirementsMutation } from '../../app/apiSlice'
import { JSON_FILE_TYPE } from '../../app/constants'
import { Loader } from '../../common/components'
import { Breadcrumbs, Button, Divider, FileSelector, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import type { HTTPException } from '../../common/types/api'
import { readUploadedFileAsText, validateFile } from '../../common/utils/fileUtils'
import type {
  RequirementUploadParams,
  RequirementVersion,
} from '../ConfigureDataCollectionAdminPanelPage/types'

const RequirementFileManagement = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [fileInputError, setFileInputError] = useState<string | undefined>(undefined)
  const [fileSelectorKey, setFileSelectorKey] = useState<number>(0)
  const [lastModifiedDate, setLastModifiedDate] = useState<string | undefined>(undefined)

  const [uploadRequirementFile, uploadRequirementFileRes] = useUploadRequirementsMutation()
  const { data: requirementsFileData, isFetching: isFetchingRequirementsFileData } =
    useGetTestCasesQuery()

  const onFileChange = (files: File[]) => {
    const { file, error } = validateFile(files[0], JSON_FILE_TYPE)
    error ? toast.warning(error) : setUploadedFile(file)
  }

  const onUpload = async () => {
    if (!uploadedFile) return
    setFileInputError(undefined)

    const fileContent = (await readUploadedFileAsText(uploadedFile)) as RequirementUploadParams
    const res = await uploadRequirementFile(fileContent)

    if ('error' in res) {
      const errorData = (res as HTTPException).error.data
      setFileInputError(errorData.detail ?? errorData.message)
      // Changes the file selector's key, which resets the active file.
      // This is a bit hacky, but it seems to be the only viable option.
      setFileSelectorKey((curVal) => curVal + 1)
    } else {
      toast.success('Uploaded requirement file succesfully')
      setLastModifiedDate((res.data as RequirementVersion).last_modified)
    }
  }

  useEffect(() => setLastModifiedDate(requirementsFileData?.last_modified), [requirementsFileData])

  const onDownloadRequirementFile = () => {
    const rawData = JSON.stringify(requirementsFileData ?? {})
    const blobData = new Blob([rawData])
    const downloadUrl = window.URL.createObjectURL(blobData)
    const tempLink = document.createElement('a')
    tempLink.href = downloadUrl
    tempLink.setAttribute('download', 'requirements.json')
    tempLink.click()
  }

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('../..'),
          },
          {
            label: 'Advanced Setup',
            onClick: () => navigate('..'),
          },
          {
            label: 'Test File Management',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      {isFetchingRequirementsFileData ? (
        <Loader />
      ) : (
        <div className='mt-4 space-y-4'>
          <Text size='l' weight={500} className='mb-3'>
            Select Requirement File to Upload
          </Text>
          <FileSelector
            onChange={onFileChange}
            accept={JSON_FILE_TYPE}
            error={fileInputError}
            key={fileSelectorKey}
          />

          <div className='flex justify-end'>
            <Button
              iconStart={<ArrowDownTrayIcon />}
              disabled={!uploadedFile}
              onClick={onUpload}
              loading={uploadRequirementFileRes.isLoading}
            >
              Upload
            </Button>
          </div>

          <Text size='l' weight={500} className='mb-3'>
            Download Current Requirement File
          </Text>
          <div className='flex cursor-pointer gap-1' onClick={onDownloadRequirementFile}>
            <FolderArrowDownIcon className='h-10 w-8' />
            <div>
              <Text weight={500}>
                Requirement File (version: {requirementsFileData?.version ?? '-'})
              </Text>
              <Text size='xs'>
                Last updated{' '}
                {lastModifiedDate
                  ? `${formatDistanceToNow(new Date(lastModifiedDate + 'Z'))} ago`
                  : 'never'}
              </Text>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RequirementFileManagement
