import { useRoutes } from 'react-router-dom'

import CSVExport from './CSVExport'
import DataIntegrationLayout from './DataIntegrationLayout'
import IngestionAPI from './IngestionAPI'
import Process from './Process'
import { ProcessSubPathEnum } from './types'

const ProcessRouting = () => {
  return useRoutes([
    {
      children: [
        {
          index: true,
          element: <Process />,
          id: 'processes_index',
        },
        {
          path: `:processId`,
          element: <DataIntegrationLayout />,
          id: 'processes_data_integration',
          children: [
            {
              path: ProcessSubPathEnum.DATA_INTEGRATION,
              element: <CSVExport />,
              id: 'processes_csv_export',
            },
            {
              path: ProcessSubPathEnum.INGESTION_API,
              element: <IngestionAPI />,
              id: 'processes_ingestion_api',
            },
          ],
        },
      ],
    },
  ])
}

export default ProcessRouting
