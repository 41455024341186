import { ArrowPathIcon, ShareIcon, XCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import _ from 'lodash'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useCreateProcessFiltersUrlMutation } from '../../../../app/apiSlice'
import { WEB_APP_URL_DASHBOARD_FRONTEND } from '../../../../app/configs'
import { ActionModal, Button, Divider as JDivider, Text } from '../../../../common/designs'
import { useLocalStorage } from '../../../../common/hooks'
import { SubSearchParamEnum } from '../../../../common/types/searchParams'
import { StorageNameEnum } from '../../../../common/types/storage'
import type { ProcessFiltersStorageContent } from '../../../../common/types/storage'
import { getProcessIdFromLocation } from '../../utils'

interface CommonProps {
  children: React.ReactNode
  className?: string
}

const Title = ({ children, className }: CommonProps) => {
  return (
    <Text variant='h6' weight={600} className={clsx('mb-1', className)}>
      {children}
    </Text>
  )
}

const Header = ({ children, className }: CommonProps) => {
  return <div className={clsx('flex items-center justify-between', className)}>{children}</div>
}

const Description = ({ children, className }: CommonProps) => {
  return (
    <Text size='s' weight={500} className={clsx('mb-1', className)}>
      {children}
    </Text>
  )
}

const ToLabel = () => <Text size='s'>to</Text>

const Divider = () => <JDivider className='-mx-6 my-4 !w-[559px]' />

interface ActionsProps {
  onReset: () => void
  onApply: () => void
}

const Actions = ({ onReset, onApply }: ActionsProps) => {
  const storage = useLocalStorage()
  const location = useLocation()

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)

  const [createProcessFiltersUrl, { isLoading, data }] = useCreateProcessFiltersUrlMutation()

  const onConfirm = () => {
    const filters = storage.getItem<ProcessFiltersStorageContent>(StorageNameEnum.PROCESS_FILTERS)
    const processId = getProcessIdFromLocation(location)

    if (!_.isEmpty(filters)) {
      createProcessFiltersUrl({ process_id: processId, filters })
        .unwrap()
        .then(() => {
          setIsConfirmModalOpen(false)
          setIsSuccessModalOpen(true)
        })
    } else {
      toast.warning('You need to apply filters before sharing them.')
      setIsConfirmModalOpen(false)
    }
  }

  const onClipboardSave = async () => {
    if (!data) return

    try {
      await navigator.clipboard.writeText(url)
      toast.success('Saving to clipboard was successful!')
      setIsSuccessModalOpen(false)
    } catch (err) {
      toast.error('Could not copy text to clipboard.')
    }
  }

  const url = `${WEB_APP_URL_DASHBOARD_FRONTEND}${location.pathname}?${SubSearchParamEnum.PROCESS_FILTER}=${data?.id}`

  return (
    <>
      <div className='mt-2 space-y-4'>
        <Button
          type='submit'
          className='w-[65%]'
          iconEnd={<ArrowPathIcon />}
          onClick={onApply}
          wFull
        >
          Apply filters to the view
        </Button>

        <div className='flex gap-4'>
          <Button variant='white' onClick={onReset} iconEnd={<XCircleIcon />} className='w-[35%]'>
            Reset filters
          </Button>

          <Button
            variant='secondary'
            className='w-[65%]'
            iconEnd={<ShareIcon />}
            onClick={() => setIsConfirmModalOpen(true)}
          >
            Share filters
          </Button>
        </div>
      </div>

      <ActionModal
        label='Confirm Applied Filters'
        description='Make sure you have applied filters to your data before proceeding. By proceeding, we will generate a unique URL representing these filters. You can then easily share or reuse this URL.'
        onAction={onConfirm}
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        actionLabel='Generate URL'
        loading={isLoading}
      />

      <ActionModal
        variant='success'
        label='You can now share the filters by copying the URL below'
        description={`${WEB_APP_URL_DASHBOARD_FRONTEND}${location.pathname}?filter=${data?.id}`}
        onAction={onClipboardSave}
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        actionLabel='Copy to Clipboard'
      />
    </>
  )
}

const FilterItem = {
  Title,
  Header,
  Description,
  Divider,
  ToLabel,
  Actions,
}

export default FilterItem

