import clsx from 'clsx'

import { Text } from '../../../../../common/designs'
import { capitalizeFirstLetter } from '../../../../../common/utils/stringFormatting'
import { IntensityLevelEnum } from '../../../enums'

const bgLowClass = '!bg-green-300'
const bgMediumClass = '!bg-yellow-300'
const bgHighClass = '!bg-red-300'
const boxClass = 'm-2 h-4 w-[30%] cursor-pointer rounded-full first:ml-0 last:mr-0 bg-gray-200'

interface CommonProps {
  onChange: (target: IntensityLevelEnum) => void
}

interface Props extends CommonProps {
  title: string
  value: string
}

const OptionsBoxes = ({ title, value, onChange }: Props) => {
  return (
    <div className='w-full'>
      <div className='flex items-center'>
        <Text variant='p' size='s' color='gray' weight={500}>
          {title}
        </Text>
        <Text variant='p' size='xs' color='lightGray' className='ml-3 capitalize'>
          {capitalizeFirstLetter(value.toLowerCase())}
        </Text>
      </div>

      <div className='flex'>
        {value === IntensityLevelEnum.LOW && <LowBoxes onChange={onChange} />}
        {value === IntensityLevelEnum.MEDIUM && <MediumBoxes onChange={onChange} />}
        {value === IntensityLevelEnum.HIGH && <HighBoxes onChange={onChange} />}
      </div>
    </div>
  )
}

const LowBoxes = ({ onChange }: CommonProps) => {
  return (
    <>
      <div
        onClick={() => onChange(IntensityLevelEnum.LOW)}
        className={clsx(boxClass, bgLowClass)}
      />
      <div onClick={() => onChange(IntensityLevelEnum.MEDIUM)} className={boxClass} />
      <div onClick={() => onChange(IntensityLevelEnum.HIGH)} className={boxClass} />
    </>
  )
}

const MediumBoxes = ({ onChange }: CommonProps) => {
  return (
    <>
      <div
        onClick={() => onChange(IntensityLevelEnum.LOW)}
        className={clsx(boxClass, bgMediumClass)}
      />
      <div
        onClick={() => onChange(IntensityLevelEnum.MEDIUM)}
        className={clsx(boxClass, bgMediumClass)}
      />
      <div onClick={() => onChange(IntensityLevelEnum.HIGH)} className={boxClass} />
    </>
  )
}

const HighBoxes = ({ onChange }: CommonProps) => {
  return (
    <>
      <div
        onClick={() => onChange(IntensityLevelEnum.LOW)}
        className={clsx(boxClass, bgHighClass)}
      />
      <div
        onClick={() => onChange(IntensityLevelEnum.MEDIUM)}
        className={clsx(boxClass, bgHighClass)}
      />
      <div
        onClick={() => onChange(IntensityLevelEnum.HIGH)}
        className={clsx(boxClass, bgHighClass)}
      />
    </>
  )
}

export default OptionsBoxes
