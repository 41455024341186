import { useLocation } from 'react-router-dom'

import { useAppSelector } from '../../../../common/hooks'
import Stat from '../../../Network/Stat'
import { selectVariantsLayoutData } from '../../processesSlice'
import { getVariantViewRoutesEnumFromLocation } from '../../utils'

const VariantFlyoutStats = () => {
  const location = useLocation()
  const { variantsInsightBarItems } = useAppSelector(selectVariantsLayoutData)

  const activeRoute = getVariantViewRoutesEnumFromLocation(location)
  const isDefaultVariantPage = activeRoute === ''

  return (
    <Stat.Root>
      <Stat.Label mainLabel='Insights' />

      <Stat.Divider />

      <Stat.Container>
        {variantsInsightBarItems.map((item, i) => {
          if (item.label === 'Variants') return null
          if (isDefaultVariantPage && i === variantsInsightBarItems.length - 1) return null

          return <Stat.ItemBase label={item.label} value={item.value} key={item.label} />
        })}
      </Stat.Container>

      {isDefaultVariantPage ? (
        <>
          <Stat.Divider />

          <Stat.Cost
            label={variantsInsightBarItems[variantsInsightBarItems.length - 1]?.label}
            value={`${variantsInsightBarItems[variantsInsightBarItems.length - 1]?.value}`}
          />
        </>
      ) : null}
    </Stat.Root>
  )
}

export default VariantFlyoutStats
