import clsx from 'clsx'

import { Text } from '../../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks'
import { selectIsCommentsOpen, toggleIsCommentsOpen } from '../../../casesSlice'

interface Props {
  commentAmount: number
}

const NavigationBar = ({ commentAmount }: Props) => {
  const dispatch = useAppDispatch()
  const isCommentsOpen = useAppSelector(selectIsCommentsOpen)

  const commonStyle =
    'flex items-center justify-center w-full rounded-tl-md rounded-tr-md border-b-0'
  const activeStyle = 'border-gray-300 border bg-gray-100'
  const inactiveStyle =
    'border-white border-b-[1px] border-b-gray-300 hover:bg-gray-50 cursor-pointer'

  return (
    <div className='-mb-0.25 mt-3 box-border flex h-12'>
      <div
        className={clsx(commonStyle, isCommentsOpen ? activeStyle : inactiveStyle, 'space-x-1')}
        onClick={() => dispatch(toggleIsCommentsOpen())}
      >
        <Text size='s' weight={500}>
          Comments
        </Text>
        <Text size='s' weight={500}>
          ({commentAmount})
        </Text>
      </div>

      <div
        className={clsx(commonStyle, isCommentsOpen ? inactiveStyle : activeStyle)}
        onClick={() => dispatch(toggleIsCommentsOpen())}
      >
        <Text size='s' weight={500}>
          Activity feed
        </Text>
      </div>
    </div>
  )
}

export default NavigationBar
