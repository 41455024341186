import _ from 'lodash'

import { WindowUsageData } from '../../features/BusinessApps/types'

export const formatBuszAppData = (array: WindowUsageData[]): number[] => {
  const arr = [...array]

  const uniqueTeams = new Set()
  let annualCost = 0
  let visits = 0

  for (const r of arr) {
    annualCost += r.annual_cost
    visits += r.visits
    r.teams?.forEach((t: number | string) => t !== 0 && uniqueTeams.add(t))
  }

  return [uniqueTeams.size, annualCost, visits]
}

// Used for ex. on file uploads to make image go to right folder in azure blob container
export const renameFile = (originalFile: File, newName: string): File => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  })
}

export const sortArrayOfObjectsBasedOnKeyStringValue = (arr: any[], key: string) => {
  return [...arr].sort((a, b) => ('' + a[key]).localeCompare(b[key]))
}

type AnyObject = Record<string, any> | any[]

// This function is used to to convert snake_case to camelCase.
// NOTE: The key 'teams' have special treatment, since we don't want to convert it to camelCase.
export const toCamelCase = (obj: AnyObject, parentKey?: string): AnyObject => {
  // If the input is an array, map over it and convert each item
  if (_.isArray(obj)) return obj.map((v) => toCamelCase(v))
  // If the input is an object (but not a Date or RegExp), convert its keys
  else if (_.isObject(obj) && !_.isDate(obj) && !_.isRegExp(obj)) {
    return Object.keys(obj).reduce((result, key) => {
      // If the parent key is 'teams', don't convert the key to camelCase
      const newKey = parentKey === 'teams' ? key : _.camelCase(key)
      return {
        ...result,
        // @ts-ignore - TS doesn't like that we're using a string to index an object here.
        [newKey]: toCamelCase(obj[key], key),
      }
    }, {})
  }
  return obj
}
