import { useMemo, useState } from 'react'

import { useGetProcessInsightsQuery } from '../../../app/apiSlice'
import { BarChartAndTableDisplay, ContentDisplay, Loader } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import { Text } from '../../../common/designs'
import { useQueryFilters } from '../../../common/hooks'
import type { ServerInsightsData } from '../types'
import InsightsModal from './InsightsModal'
import StepActivityExplanations from './StepActivityExplanations'

type valueFiledKeyType = 'leadtime_avg_improvement_sec' | 'annual_worktime_improvement_sec'
const generateChartData = (
  data: ServerInsightsData[] | undefined,
  valueFiledKey: valueFiledKeyType,
) => {
  if (!data) return []

  return [...data]
    .map((item) => {
      const value = item[valueFiledKey] / (60 * 60)
      return {
        label: item.insight_name,
        value,
      }
    })
    .sort((a, b) => b.value - a.value)
}

const Insights = () => {
  const { processFilters: filters, processId } = useQueryFilters()

  const { data, isFetching } = useGetProcessInsightsQuery({ processId, filters })

  const [modalData, setModalData] = useState<any>(null)

  const leadtimeChartData: ChartDataEntry[] = useMemo(() => {
    return generateChartData(data, 'leadtime_avg_improvement_sec')
  }, [data])

  const worktimeChartData: ChartDataEntry[] = useMemo(() => {
    return generateChartData(data, 'annual_worktime_improvement_sec')
  }, [data])

  const onBarClick = (
    dataIndex: number,
    sortByField: 'annual_worktime_improvement_sec' | 'leadtime_avg_improvement_sec',
  ) => {
    if (!data) return

    const dataCopy = [...data]
    dataCopy.sort((a, b) => b[sortByField] - a[sortByField])

    const clickedInsight = dataCopy[dataIndex]
    setModalData({
      stepName: clickedInsight.step_name,
      stepActivity: clickedInsight.step_work_type,
      insightName: clickedInsight.insight_name,
    })
  }

  if (isFetching) return <Loader />

  return (
    <>
      <div className='flex flex-col gap-3 pb-2'>
        <ContentDisplay className='px-5 py-4'>
          <Text size='l' weight={600} className='mb-1'>
            These insights are about the process tasks with the most significant impact on improving
            the process flow.
          </Text>
          <StepActivityExplanations />
        </ContentDisplay>
        <BarChartAndTableDisplay
          label='Lead time improvement opportunities'
          description='The average of the total lead times before the process task'
          data={leadtimeChartData}
          unit='hours'
          tableHeaderValues={['Insight', 'time']}
          onClick={(_: string, dataIndex: number) =>
            onBarClick(dataIndex, 'leadtime_avg_improvement_sec')
          }
          isExportable
          height='250px'
          maxXLabelLength={20}
        />

        <BarChartAndTableDisplay
          label='Automation improvement opportunities'
          description='The annualized manual time of the process steps per activity category'
          data={worktimeChartData}
          unit='hours'
          tableHeaderValues={['Insight', 'time']}
          onClick={(_: string, dataIndex: number) =>
            onBarClick(dataIndex, 'annual_worktime_improvement_sec')
          }
          isExportable
          height='250px'
          maxXLabelLength={20}
        />
      </div>
      <InsightsModal
        onClose={() => setModalData(null)}
        isOpen={modalData ? true : false}
        stepName={modalData?.stepName}
        stepActivity={modalData?.stepActivity}
        insightName={modalData?.insightName}
      />
    </>
  )
}

export default Insights

