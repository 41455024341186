import { useOutletContext } from 'react-router-dom'

import { useEnhancedNavigate } from '../../../../common/hooks'
import type { Tag } from '../../../../common/types/common'
import { compressAndEncodeJSON } from '../../../../common/utils/jsonCompression'
import ActivitiesByWindow from '../../ActivitiesByWindow'
import type { SecondLevelLayoutOutletContext } from '../SecondLevelLayout'

const ActivityCategorization = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { tagFilters } = useOutletContext<SecondLevelLayoutOutletContext>()

  const onNavigate = (tags: Tag[]) => {
    navigate(`../../window/${compressAndEncodeJSON(tags)}`)
  }

  return (
    <ActivitiesByWindow
      onNavigate={onNavigate}
      queryOptions={{ tagsOfInterestSource: 'window', tagFilters }}
    />
  )
}

export default ActivityCategorization
