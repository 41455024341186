import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'

import { UserRoleMappingEnum } from '../common/types/common'
import { AdminMainPathEnum, GlobalPathEnum, MainPathEnum } from '../common/types/routing'
import UtilityWrapper from '../common/utils/UtilityWrapper'
import AgentMonitoring from '../features/AgentMonitoringAdminPanelPage/AgentMonitoring'
import AppError from '../features/AppError/AppError'
import Authentication from '../features/Authentication/Authentication'
import AutomaticAuthentication from '../features/Authentication/AutomaticAuthentication'
import BusinessAppsRouting from '../features/BusinessApps/BusinessAppsRouting'
import CaseSettings from '../features/CaseSettingsAdminPanelPage/CaseSettings'
import CasesRouting from '../features/Cases/CasesRouting'
import CompanySettings from '../features/CompanySettingsAdminPanelPage/CompanySettings'
import ConfigureDataCollectionRouting from '../features/ConfigureDataCollectionAdminPanelPage/ConfigureDataCollectionRouting'
import DataDeletion from '../features/DataDeletionAdminPanelPage/DataDeletion'
import DataFlowsRouting from '../features/DataFlows/DataFlowsRouting'
import FavoritesRouting from '../features/Favorites/FavoritesRouting'
import GetStarted from '../features/GetStartedAdminPanelPage/GetStarted'
import InstallationCenter from '../features/InstallationCenterAdminPanelPage/InstallationCenter'
import ManageUsers from '../features/ManageUsersAdminPanelPage/ManageUsers'
import MonitoringOverviewAdminPanelPage from '../features/MonitoringOverviewAdminPanelPage/MonitoringOverviewAdminPanelPage'
import NotFound from '../features/NotFound/NotFound'
import OverviewRouting from '../features/Overview/OverviewRouting'
import PerformanceMonitoring from '../features/PerformanceMonitoringAdminPanelPage/PerformanceMonitoring'
import AdminProcessRouting from '../features/ProcessAdminPanelPage/ProcessRouting'
import ProcessesRouting from '../features/Processes/ProcessesRouting'
import Sandbox from '../features/Sandbox/Sandbox'
import ManageGroups from '../features/TeamSettingsAdminPanelPage/ManageGroups'
import URLSearchParamsHandler from './URLSearchParamsHandler'
import { AdminPanelLayout } from './layouts'
import MainLayout from './layouts/MainNavigation/MainLayout'

const router = createBrowserRouter([
  {
    element: <Outlet />,
    children: [
      {
        path: GlobalPathEnum.AUTOMATIC_SIGN_IN,
        element: <AutomaticAuthentication />,
        id: 'automatic_sign_in',
      },
      {
        element: (
          <>
            <URLSearchParamsHandler />

            <Outlet />
          </>
        ),
        children: [
          {
            path: '/',
            element: <Navigate to={MainPathEnum.DEFAULT} replace />,
            id: 'root',
          },
          {
            path: GlobalPathEnum.SIGN_IN,
            element: <Authentication />,
            id: 'sign_in',
          },
          {
            path: GlobalPathEnum.SANDBOX,
            element: <Sandbox />,
            id: 'sandbox',
          },
          {
            element: (
              <UtilityWrapper requireAuth>
                <MainLayout />
              </UtilityWrapper>
            ),
            children: [
              { path: `${MainPathEnum.START}/*`, element: <OverviewRouting />, id: 'start' },
              {
                path: `${MainPathEnum.DATA_FLOWS}/*`,
                element: <DataFlowsRouting />,
                id: 'data_flows',
              },
              {
                path: `${MainPathEnum.BUSINESS_APPS}/*`,
                element: <BusinessAppsRouting />,
                id: 'business_apps',
              },
              {
                path: `${MainPathEnum.PROCESSES}/*`,
                element: <ProcessesRouting />,
                id: 'processes',
              },
              {
                path: `${MainPathEnum.OPPORTUNITIES}/*`,
                element: <CasesRouting />,
                id: 'opportunities',
              },
              {
                path: `${MainPathEnum.FAVORITES}/*`,
                element: <FavoritesRouting />,
                id: 'favorites',
              },
            ],
          },
          {
            element: (
              <UtilityWrapper requireAuth restrictAccessToRoleIds={[UserRoleMappingEnum.ADMIN]}>
                <AdminPanelLayout />
              </UtilityWrapper>
            ),
            children: [
              // Service Setup
              {
                path: AdminMainPathEnum.ROOT,
                element: <Navigate to={AdminMainPathEnum.GET_STARTED} replace />,
                id: 'admin_root',
              },
              {
                path: AdminMainPathEnum.GET_STARTED,
                element: <GetStarted />,
                id: 'admin_get_started',
              },
              {
                path: AdminMainPathEnum.INSTALLATION_CENTER,
                element: <InstallationCenter />,
                id: 'admin_installation_center',
              },
              {
                path: `${AdminMainPathEnum.PROCESS}/*`,
                element: <AdminProcessRouting />,
              },
              {
                path: `${AdminMainPathEnum.DATA_COLLECTION}/*`,
                element: <ConfigureDataCollectionRouting />,
                id: 'admin_apps',
              },
              // Dashboard Settings
              {
                path: AdminMainPathEnum.USERS,
                element: <ManageUsers />,
                id: 'admin_users',
              },
              {
                path: AdminMainPathEnum.OPPORTUNITIES,
                element: <CaseSettings />,
                id: 'admin_cases',
              },
              {
                path: AdminMainPathEnum.COMPANY,
                element: <CompanySettings />,
                id: 'admin_company',
              },
              {
                path: AdminMainPathEnum.TEAM_GROUPS,
                element: <ManageGroups />,
                id: 'admin_team_groups',
              },
              {
                path: AdminMainPathEnum.DATA_DELETION,
                element: <DataDeletion />,
                id: 'admin_data_deletion',
              },
              // Monitoring
              {
                path: AdminMainPathEnum.MONITORING_OVERVIEW,
                element: <MonitoringOverviewAdminPanelPage />,
                id: 'admin_monitoring_overview',
              },
              {
                path: AdminMainPathEnum.AGENTS,
                element: <AgentMonitoring />,
                id: 'admin_agents',
              },
              {
                path: AdminMainPathEnum.PERFORMANCE,
                element: <PerformanceMonitoring />,
                id: 'admin_performance',
              },
            ],
          },
          {
            path: 'app-error',
            element: <AppError />,
            id: 'app_error',
          },
          {
            path: '/blank.html', // Redirect auth popup to black.html page
            id: 'blank',
          },
          {
            path: '*',
            element: <NotFound />,
            id: 'not_found',
          },
        ],
      },
    ],
  },
])

export default router

