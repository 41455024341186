import { TrashIcon } from '@heroicons/react/20/solid'
import { ChangeEvent, useCallback, useState } from 'react'

import { IconButton, InputField, Text } from '../../../../common/designs'

interface Props {
  filterName: string
  onRemoveFilter: () => void
  minValue: number | undefined
  onSetMinValue: (value: number) => void
  maxValue: number | undefined
  onSetMaxValue: (value: number) => void
}

const errorMessage = 'Max cannot be less than min'

const UserInteractionFilterInput = ({
  filterName,
  onRemoveFilter,
  minValue,
  onSetMinValue,
  maxValue,
  onSetMaxValue,
}: Props) => {
  const [error, setError] = useState<string | undefined>(
    minValue && maxValue && minValue > maxValue ? errorMessage : undefined,
  )

  const onChangeMinValue = useCallback(
    (el: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(el.target.value)
      onSetMinValue(value)
      if (maxValue && value > maxValue) setError(errorMessage)
    },
    [onSetMinValue, maxValue],
  )
  const onChangeMaxValue = useCallback(
    (el: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(el.target.value)
      onSetMaxValue(value)
      if (minValue && value < minValue) setError(errorMessage)
    },
    [onSetMaxValue, minValue],
  )

  return (
    <div className='relative my-3 flex flex-col rounded-lg bg-white p-3 shadow-sm ring-2 ring-black/5'>
      <div className='mb-4'>
        <Text weight={600}>{filterName}</Text>
      </div>
      <IconButton
        className='absolute right-2 top-3'
        variant='secondary'
        icon={<TrashIcon />}
        size='s'
        onClick={onRemoveFilter}
      />

      <div className='mb-3 flex w-full gap-2'>
        <InputField
          placeholder='Min'
          type='number'
          min={0}
          className='w-full'
          value={minValue}
          onChange={onChangeMinValue}
        />
        <InputField
          placeholder='Max'
          type='number'
          min={0}
          className='w-full'
          value={maxValue}
          onChange={onChangeMaxValue}
          error={error}
        />
      </div>
    </div>
  )
}

export default UserInteractionFilterInput
