import { StarIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { Text, Tooltip } from '../../../../common/designs'
import { formatNumber } from '../../../../common/utils/numberFormatting'
import NodeHandleLayout from './NodeHandleLayout'
import type { PortData } from './types'

interface Ports {
  topPorts: PortData[]
  rightPorts: PortData[]
  bottomPorts: PortData[]
  leftPorts: PortData[]
}

interface Props {
  children?: React.ReactNode
  ports: Ports
  relativeSize: number
  nodeName: string
  timeText?: string
  transactionCount: number
  isHighlightingConformance?: boolean
  isConformanceFlowNode: boolean
  actionButtons: React.ReactNode | React.ReactNode[] | null
  isPreparationStep?: boolean
}

const NodeLayout = (props: Props) => {
  const {
    children,
    ports,
    relativeSize,
    nodeName,
    transactionCount,
    timeText,
    isHighlightingConformance = false,
    isConformanceFlowNode,
    actionButtons,
    isPreparationStep = false,
  } = props
  const { topPorts, rightPorts, bottomPorts, leftPorts } = ports

  const alpha = 0.5 + 0.5 * relativeSize
  const lightness = 60 - 30 * relativeSize
  const nodeColor = isPreparationStep
    ? `hsla(35, 94%, ${lightness}%, ${alpha})` // amber-600-ish
    : `hsla(223, 92%, ${lightness}%, ${alpha})`

  let relativeIndicatorColor
  let relativeText
  let borderWidth
  if (relativeSize > 0.8) {
    relativeIndicatorColor = 'bg-red-600'
    relativeText = 'High deviation from the conformance'
    borderWidth = 'border-[8px]'
  } else if (relativeSize > 0.6) {
    relativeIndicatorColor = 'bg-red-400'
    relativeText = 'High deviation from the conformance'
    borderWidth = 'border-[6px]'
  } else if (relativeSize > 0.4) {
    relativeIndicatorColor = 'bg-yellow-600'
    relativeText = 'Medium deviation from the conformance'
    borderWidth = 'border-[4px]'
  } else {
    relativeIndicatorColor = 'bg-yellow-400'
    relativeText = 'Medium deviation from the conformance'
    borderWidth = 'border-[2px]'
  }

  return (
    <>
      <NodeHandleLayout
        topPorts={topPorts}
        rightPorts={rightPorts}
        bottomPorts={bottomPorts}
        leftPorts={leftPorts}
        color={nodeColor}
      >
        <div
          className={clsx('w-[500px] rounded-3xl bg-white', borderWidth)}
          style={{ borderColor: nodeColor }}
        >
          <div className='p-3'>
            <div className='flex justify-between'>
              <Text weight={600} size='xl'>
                {nodeName}
              </Text>

              {isHighlightingConformance ? (
                isConformanceFlowNode ? (
                  <Tooltip text='Part of the defined conformance' size='l' placement='b'>
                    <StarIcon className='size-8 text-green-600' />
                  </Tooltip>
                ) : (
                  <Tooltip text={relativeText} size='l' placement='b'>
                    <div className={clsx('size-5 rounded-full', relativeIndicatorColor)} />
                  </Tooltip>
                )
              ) : null}
            </div>

            <div className='flex items-end justify-between'>
              <div>
                {timeText && (
                  <Text size='l' weight={600} color='primary' className='mr-3'>
                    {timeText}
                  </Text>
                )}
                <Text size='s' weight={500} color='lightGray'>
                  {formatNumber(transactionCount, 1)} Cases
                </Text>
              </div>
            </div>
            {children ?? null}
          </div>
          {actionButtons}
        </div>
      </NodeHandleLayout>
    </>
  )
}

export default NodeLayout
