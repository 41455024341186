import type { Edge, Node } from 'reactflow'

import { getBestTimeFormatForSeconds } from '../../../../common/utils/numberFormatting'
import { defaultEdgeArrowStyle } from './DefaultEdge'
import type { ServerEdge, ServerNodeTypes } from './types'
import { VariantVisualizationLevelEnum } from './types'

const DEFAULT_COLUMN_WIDTH_PX = 700

export const configureRowHeightForVariantView = (
  visualizationLevel: VariantVisualizationLevelEnum,
  isTaskPathVisualization: boolean,
  isTeamDistributionOpen: boolean,
  isWorkTypeDistributionOpen: boolean,
  isAutomationInsightsOpen: boolean,
) => {
  let rowHeightPx = 0

  switch (visualizationLevel) {
    case VariantVisualizationLevelEnum.STEP:
      rowHeightPx = 300
      if (isTeamDistributionOpen) rowHeightPx += 200
      if (isWorkTypeDistributionOpen) rowHeightPx += 200
      if (isAutomationInsightsOpen) rowHeightPx += 200
      break

    case VariantVisualizationLevelEnum.STATE:
      rowHeightPx = 300
      break

    case VariantVisualizationLevelEnum.TEAM:
      rowHeightPx = 200
      break

    case VariantVisualizationLevelEnum.WINDOW:
      rowHeightPx = isTaskPathVisualization ? 400 : 500
      break

    default:
      break
  }

  return rowHeightPx
}

export const serverStepFlowNodesToChartNodes = (
  serverNodes: Array<ServerNodeTypes>,
  rowHeightPx: number,
): Node<ServerNodeTypes>[] => {
  return serverNodes.map((n) => ({
    id: n.display_name,
    position: { x: n.column_index * DEFAULT_COLUMN_WIDTH_PX, y: n.row_index * rowHeightPx },
    data: n,
    type: n.node_type,
  }))
}

export const serverEdgesToChartEdges = (serverEdges: ServerEdge[]): Edge[] => {
  return serverEdges.map((e) => ({
    type: 'defaultEdge',
    id: e.id,
    source: e.source,
    target: e.target,
    data: e,
    sourceHandle: e.source_port,
    targetHandle: e.target_port,
    label: `${e.total_transactions}x | ${getBestTimeFormatForSeconds(e.average_time_sec)}`,

    // These wont work inside DefaultEdge component, hence these styling props are here.
    markerEnd: { ...defaultEdgeArrowStyle, strokeWidth: 1 },
    animated: true,
  }))
}
