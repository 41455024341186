import clsx from 'clsx'
import { useRef } from 'react'
import { Outlet } from 'react-router-dom'

import { useDashboard } from '../../../common/hooks'
import FilterDrawer from './FilterDrawer'
import MainNavigation from './MainNavigation'

const MainLayout = () => {
  const drawerRef = useRef<{ toggle: () => void; getIsDisabledState: () => boolean }>(null!)
  const rootRef = useRef<HTMLDivElement>(null)
  const {
    user: { isPlatformViewer },
  } = useDashboard()

  const heightClasses = isPlatformViewer
    ? 'h-[calc(100vh-115px)] lg:h-[calc(100vh-50px)]'
    : 'h-[calc(100vh-206px)] lg:h-[calc(100vh-158px)]'

  return (
    <div className='h-full' ref={rootRef}>
      <header>
        <MainNavigation
          onFilterBtnClick={() => drawerRef.current.toggle()}
          getIsDisabledFilterBtnState={() => drawerRef.current.getIsDisabledState()}
        />
      </header>

      <main className={clsx(heightClasses, 'bg-background-main pl-6 pt-3')}>
        <div className='h-full overflow-y-auto py-2 pl-2 pr-8'>
          <Outlet />
        </div>
      </main>

      <FilterDrawer ref={drawerRef} />
    </div>
  )
}

export default MainLayout
