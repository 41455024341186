import type { NodeTypeEnum } from '../Processes/ProcessMining/Variants/enums'
import type {
  PortData,
  ServerEdge,
  StepVariantData,
} from '../Processes/ProcessMining/Variants/types'
import type { VariantOrder } from '../Processes/types'

export interface TaskWindowFilters {
  start_app?: string | null
  start_window?: string | null
  end_app?: string | null
  end_window?: string | null

  apps_in_task?: string[] | null
  windows_in_task?: string[] | null

  main_app?: string | null
}

export interface TaskDataTransferFilters {
  data_transfer_source_app?: string | null
  data_transfer_target_app?: string | null
  data_transfer_source_window?: string | null
  data_transfer_target_window?: string | null
}

export interface TaskRelatedProcessFilters {
  process_name?: string | null
  process_step_name?: string | null
}

export interface TaskFilters {
  team_ids?: string[]
  min_date?: string
  max_date?: string
  min_task_step_amount?: number | null
  window_filters?: TaskWindowFilters
  data_transfer_filters?: TaskDataTransferFilters
  related_process_filters?: TaskRelatedProcessFilters
}

// Task List
export interface TaskListQueryOptions {
  filters: TaskFilters
}

export enum TaskTypeEnum {
  WORK_FLOW_TASK = 'work_flow_task',
  PREDEFINED_TASK = 'predifined_task',
  REPETITIVE_TASK = 'repetitive_task',
}

export interface TeamDistributionData {
  team_id: string
  task_count: number
  duration_sec: number
}

export interface ItemTaskCountData {
  name: string
  task_count: number
}

interface TopVariantWindow {
  window_name: string
  avg_duration_sec: number
}

export interface TopVariantData {
  window_steps: TopVariantWindow[]
  task_count: number
}

export interface ActivityCategorization {
  activity_name: string
  duration_sec: number
  activity_count: number
}

export interface AppUsageData {
  app_name: string
  duration_sec: number
}

export interface TaskDiscoveryData {
  task_name: string
  task_count: number
  data_transfer_count: number
  duration_sec: number
  task_type: TaskTypeEnum
  team_data: TeamDistributionData[]
  process_data: ItemTaskCountData[]
  top_variants: TopVariantData[]
  top_data_transfers: ItemTaskCountData[]
  activity_categorizations: ActivityCategorization[]
  app_usage_data: AppUsageData[]
}

// Task Variants
interface UIFieldData {
  field_value: string
  control_key: string | null
}

export interface CopyPasteEvent {
  copied_from: string
  clipboard_uuid: string
  copy_timestamp: string
  paste_timestamp: string

  source_app_name: string
  source_window_name: string
  target_app_name: string
  target_window_name: string
  content_full: string | null
  content_categorization: string | null
  source_field_data: UIFieldData | null
  target_field_data: UIFieldData | null
}

export interface ProcessStep {
  process_name: string
  transaction_id: string
  step_name: string
}
export interface TaskVariantOptions {
  filters: TaskFilters
  task_name?: string | undefined
  variant_order: VariantOrder
  selected_variants: number[]
  is_task_path_visualization: boolean
}

export interface ServerTaskVisualizationNode {
  display_name: string
  transaction_ids: string[]

  // Metrics
  variants: number
  transactions: number
  duration_sec: number
  avg_duration_sec: number
  step_visit_count: number

  // Activity data
  step_copies: CopyPasteEvent[]
  step_pastes: CopyPasteEvent[]
  process_steps: ProcessStep[]
  relative_share_of_transactions: number
  relative_time_per_transaction: number
  internal_copy_paste_count: number
  keyboard_press_count: number
  click_count: number
  activity_categorizations: ActivityCategorization[]

  // Visualization
  node_type: NodeTypeEnum
  is_conformance_flow_node: boolean
  column_index: number
  row_index: number
  x_coord: number
  y_coord: number
  top_ports: PortData[]
  right_ports: PortData[]
  bottom_ports: PortData[]
  left_ports: PortData[]
}

export interface TaskVariantData {
  variant_list: StepVariantData[]
  total_transactions: number
  nodes: ServerTaskVisualizationNode[]
  edges: ServerEdge[]
}

// Filter visualization

export interface SelectedFilter {
  label: string
  value: string | number | string[]
  filterKey: TaskFilterNameEnum
}

export enum TaskFilterNameEnum {
  MIN_TASK_STEP_AMOUNT = 'min_task_step_amount',
  START_APP = 'start_app',
  START_WINDOW = 'start_window',
  END_APP = 'end_app',
  END_WINDOW = 'end_window',
  APPS_IN_TASK = 'apps_in_task',
  WINDOWS_IN_TASK = 'windows_in_task',
  MAIN_APP = 'main_app',

  DATA_TRANSFER_SOURCE_APP = 'data_transfer_source_app',
  DATA_TRANSFER_SOURCE_WINDOW = 'data_transfer_source_window',
  DATA_TRANSFER_TARGET_APP = 'data_transfer_target_app',
  DATA_TRANSFER_TARGET_WINDOW = 'data_transfer_target_window',

  PROCESS_NAME = 'process_name',
  PROCESS_STEP_NAME = 'process_step_name',
}

export enum TaskFilterLabelEnum {
  MIN_TASK_STEP_AMOUNT = 'Min task tasks',
  START_APP = 'Start app',
  START_WINDOW = 'Start window',
  END_APP = 'End app',
  END_WINDOW = 'End window',
  APPS_IN_TASK = 'Apps in task',
  WINDOWS_IN_TASK = 'Windows in task',
  MAIN_APP = 'Main app',

  DATA_TRANSFER_SOURCE_APP = 'Data transfer source app',
  DATA_TRANSFER_SOURCE_WINDOW = 'Data transfer source window',
  DATA_TRANSFER_TARGET_APP = 'Data transfer target app',
  DATA_TRANSFER_TARGET_WINDOW = 'Data transfer target window',

  PROCESS_NAME = 'Process name',
  PROCESS_STEP_NAME = 'Process task name',
}

export interface TaskFilterOptionParams {
  filters: TaskFilters
  selected_filter: TaskFilterNameEnum
}

// ** TASK DISCOVERY - PREDEFINED TASKS ** //
export interface PredefinedTaskAnalysisFilters {
  min_date?: string
  max_date?: string
  team_ids?: string[]
}

export interface PredefinedTaskAnalysisQueryOptions {
  filters: PredefinedTaskAnalysisFilters
}

export interface PredefinedTaskMetricData {
  total_task_count: number
  total_task_time_sec: number
}

export interface PredefinedTaskAppUsageData {
  app_name: string
  total_task_count: number
  total_task_time_sec: number
}

export interface PredefinedTaskSessionOrderData {
  task_names: string[]
  total_count: number
}

