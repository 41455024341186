import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useLazyGetProcessFiltersUrlQuery } from '../app/apiSlice'
import { useLocalStorage } from '../common/hooks'
import { GlobalPathEnum, MainPathEnum, SubPathEnum } from '../common/types/routing'
import { GlobalSearchParamEnum, SubSearchParamEnum } from '../common/types/searchParams'
import { StorageNameEnum } from '../common/types/storage'
import { TaskFilterNameEnum } from '../features/TaskDiscovery/types'

const {
  ACTIVE_VIEW,
  DETAIL_LEVEL,
  NODE_AMOUNT,
  APP_LIST,
  VISUALIZATION_MODE,
  DATA_VARIANT,
  SHOWN_VARIANTS_STATES,
  SHOWN_VARIANTS_STEPS,
  SHOWN_VARIANTS_WINDOWS,
  PROCESS_FILTER,
  ZOOM_MODE,
  SELECTED_STEP,
  SELECTED_STATE,
} = SubSearchParamEnum

const LOCAL_STORAGE_PROCESS_FILTERS = StorageNameEnum.PROCESS_FILTERS

const masterSearchParamTagTree: {
  [key in MainPathEnum]?: {
    [subKey in SubPathEnum | 'index']?: string[]
  }
} = {
  [MainPathEnum.START]: {
    index: [],
    [SubPathEnum.RECOMMENDATIONS]: [],
  },
  [MainPathEnum.DATA_FLOWS]: {
    index: [DETAIL_LEVEL, VISUALIZATION_MODE, NODE_AMOUNT, APP_LIST],
  },
  [MainPathEnum.BUSINESS_APPS]: {
    index: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
    [SubPathEnum.TRENDS]: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
    [SubPathEnum.TEAMS]: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
    [SubPathEnum.ACTIVITIES]: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
    [SubPathEnum.NAVIGATIONS]: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
    [SubPathEnum.COPY_PASTES]: [ACTIVE_VIEW, VISUALIZATION_MODE, DATA_VARIANT],
  },
  [MainPathEnum.PROCESSES]: {
    index: [],
    [SubPathEnum.TASK_DISCOVERY]: [...Object.values(TaskFilterNameEnum), SHOWN_VARIANTS_WINDOWS],
    [SubPathEnum.OVERVIEW]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
    ],
    [SubPathEnum.VARIANTS]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
      ZOOM_MODE,
      SELECTED_STEP,
      SELECTED_STATE,
    ],
    [SubPathEnum.INSIGHTS]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
    ],
    [SubPathEnum.ANALYSIS]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
    ],
    [SubPathEnum.CONFORMANCE]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
    ],
    [SubPathEnum.TRENDS]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
    ],
    [SubPathEnum.USER_ACTIVITIES]: [
      VISUALIZATION_MODE,
      SHOWN_VARIANTS_STEPS,
      SHOWN_VARIANTS_STATES,
      SHOWN_VARIANTS_WINDOWS,
      LOCAL_STORAGE_PROCESS_FILTERS,
      PROCESS_FILTER,
      DETAIL_LEVEL,
      VISUALIZATION_MODE,
      NODE_AMOUNT,
      APP_LIST,
    ],
  },
  [MainPathEnum.OPPORTUNITIES]: {
    index: [],
    [SubPathEnum.ROADMAP]: [],
  },
  [MainPathEnum.FAVORITES]: {
    index: [],
    [SubPathEnum.PRIVATE]: [],
  },
}

// *
//  The point of this component is to sanitize the URL search parameters based on the current URL after navigation.
// *
const URLSearchParamsHandler = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const storage = useLocalStorage()

  const [getProcessFiltersUrl] = useLazyGetProcessFiltersUrlQuery()

  const buildSanitizedSearchParams = useCallback(() => {
    // Substring remove leading slash and split on remaining slashes.
    const fragments = location.pathname.substring(1).split('/')

    // The first fragment is expected to be an enumerated value defined in the MainPathEnum enum.
    const mainPath = fragments[0]
    fragments.shift()

    // If the mainPath is not in the MainPathEnum enum, return empty searchParams.
    if (Object.values(GlobalPathEnum).includes(mainPath as any)) return ''

    // Retrieve a branch of allowed search parameters based on the mainPath.
    const tagBranch = masterSearchParamTagTree[mainPath as MainPathEnum] ?? {}
    let allowedSearchParams = tagBranch?.['index'] ?? []

    // Update the allowed search parameters based on other fragments in the URL.
    Object.entries(tagBranch).forEach(([key, values]) => {
      if (fragments.includes(key)) {
        allowedSearchParams = values
      }
    })

    // TODO Could be done better in future and probably seperated from here, at least partially.
    // Custom logic to delete the processFilters from local storage.
    if (!allowedSearchParams.some((t) => t === LOCAL_STORAGE_PROCESS_FILTERS)) {
      storage.removeItem(LOCAL_STORAGE_PROCESS_FILTERS)
    }

    // Remove any search parameters that are not in the GlobalSearchParamEnum
    // enum or in the allowed search parameters.
    searchParams.forEach((value, key) => {
      if (
        !Object.values(GlobalSearchParamEnum).includes(key as GlobalSearchParamEnum) &&
        !allowedSearchParams.includes(key)
      ) {
        return searchParams.delete(key)
      }

      if (key === PROCESS_FILTER) {
        // Fetch the process filters from the database and insert the filters to storage.
        // After that remove search params from the URL.
        if (value !== 'null') {
          getProcessFiltersUrl(value)
            .unwrap()
            .then((res) => {
              storage.setItem(StorageNameEnum.PROCESS_FILTERS, res.filters)
            })
        }

        searchParams.delete(key)
      }
    })

    return searchParams.toString()
  }, [location.pathname, searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  // Trigger a navigation event to update the URL with the sanitized search
  // parameters whenever the location or search parameters change.
  useEffect(() => {
    const newSearchParams = buildSanitizedSearchParams()

    // This only updates the searchParams and does not rerender the page.
    navigate(`?${newSearchParams}`, { replace: true })
  }, [location.pathname, buildSanitizedSearchParams, navigate])

  return null
}

export default URLSearchParamsHandler

