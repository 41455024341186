import { useRoutes } from 'react-router-dom'

import { SubPathEnum } from '../../common/types/routing'
import BusinessAppsLayout from './BusinessAppsLayout'
// These components share names so import them as namespaced
import * as firstLayerComponents from './FirstLayer'
import * as secondLayerComponents from './SecondLayer'
import * as thirdLayerComponents from './ThirdLayer'

const BusinessAppsRouting = () => {
  return useRoutes([
    {
      element: <BusinessAppsLayout />,
      children: [
        {
          index: true,
          element: <firstLayerComponents.Time />,
          id: 'business_apps_first_time',
        },
        {
          path: SubPathEnum.TEAMS,
          element: <firstLayerComponents.Teams />,
          id: 'business_apps_first_teams',
        },
        {
          path: SubPathEnum.TRENDS,
          element: <firstLayerComponents.Trends />,
          id: 'business_apps_first_trends',
        },
        {
          path: SubPathEnum.ACTIVITIES,
          element: <firstLayerComponents.Activities />,
          id: 'business_apps_first_activities',
        },
        {
          path: SubPathEnum.NAVIGATIONS,
          element: <firstLayerComponents.Navigations />,
          id: 'business_apps_first_navigations',
        },
      ],
    },
    {
      path: ':encodedAppTags',
      element: <BusinessAppsLayout />,
      children: [
        {
          index: true,
          element: <secondLayerComponents.Time />,
          id: 'business_apps_second_time',
        },
        {
          path: SubPathEnum.TEAMS,
          element: <secondLayerComponents.Teams />,
          id: 'business_apps_second_teams',
        },
        {
          path: SubPathEnum.TRENDS,
          element: <secondLayerComponents.Trends />,
          id: 'business_apps_second_trends',
        },
        {
          path: SubPathEnum.ACTIVITIES,
          element: <secondLayerComponents.ActivitiesLayout />,
          children: [
            {
              index: true,
              element: <secondLayerComponents.ActivityCategorization />,
              id: 'business_apps_second_activities_categorization',
            },
            {
              path: 'text_entry',
              element: (
                <secondLayerComponents.ActivityBarChart dataKey='text_entry' title='Key presses' />
              ),
              id: 'business_apps_second_activities_text_entry',
            },
            {
              path: 'copies',
              element: (
                <secondLayerComponents.ActivityBarChart dataKey='copies' title='Data copies' />
              ),
              id: 'business_apps_second_activities_copies',
            },
            {
              path: 'pastes',
              element: (
                <secondLayerComponents.ActivityBarChart dataKey='pastes' title='Data pastes' />
              ),
              id: 'business_apps_second_activities_pastes',
            },
          ],
        },
        {
          path: SubPathEnum.NAVIGATIONS,
          element: <secondLayerComponents.Navigations />,
          id: 'business_apps_second_navigations',
        },
      ],
    },
    {
      path: 'window/:encodedWindowTags',
      element: <BusinessAppsLayout />,
      children: [
        {
          index: true,
          element: <thirdLayerComponents.Time />,
          id: 'business_apps_third_time',
        },
        {
          path: SubPathEnum.NAVIGATIONS_SANKEY,
          element: <thirdLayerComponents.Navigations />,
          id: 'business_apps_third_navigations_sankey',
        },
        {
          path: SubPathEnum.COPY_PASTES,
          element: <thirdLayerComponents.CopyPastes />,
          id: 'business_apps_third_copypastes',
        },
        {
          path: SubPathEnum.TRENDS,
          element: <thirdLayerComponents.Trends />,
          id: 'business_apps_third_trends',
        },
        {
          path: SubPathEnum.NAVIGATIONS,
          element: <thirdLayerComponents.NewNavigations />,
          id: 'business_apps_third_navigations',
        },
      ],
    },
  ])
}

export default BusinessAppsRouting
