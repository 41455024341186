import { NodeTypeEnum } from './enums'
import type { ServerStepFlowNode } from './types'

export const INITIAL_NODE_START: ServerStepFlowNode = {
  display_name: 'start',
  step_name: 'start',
  step_index: 0,
  variants: 0,
  transactions: 0,
  node_type: NodeTypeEnum.START,
  is_conformance_flow_node: false,
  column_index: 0,
  row_index: 0,
  x_coord: 60,
  y_coord: 0,
  top_ports: [],
  right_ports: [],
  bottom_ports: [
    {
      id: 'source-port-bottom-0',
      type: 'source',
    },
  ],
  left_ports: [],
  duration_sec: 0,
  avg_duration_sec: 0,
  total_step_visits: 0,
  relative_share_of_transactions: 0,
  relative_time_per_transaction: 0,
  is_preparation_step: false,
}

export const INITIAL_NODE_END: ServerStepFlowNode = {
  display_name: 'end',
  step_name: 'end',
  step_index: 0,
  variants: 0,
  transactions: 0,
  node_type: NodeTypeEnum.END,
  is_conformance_flow_node: false,
  column_index: 0,
  row_index: 2,
  x_coord: 60,
  y_coord: 1125,
  top_ports: [
    {
      id: 'target-port-top-0',
      type: 'target',
    },
  ],
  right_ports: [],
  bottom_ports: [],
  left_ports: [],
  duration_sec: 0,
  avg_duration_sec: 0,
  total_step_visits: 0,
  relative_share_of_transactions: 0,
  relative_time_per_transaction: 0,
  is_preparation_step: false,
}

export const INITIAL_EDGE = {
  id: 'start||end',
  total_transactions: 1,
  source: 'start',
  target: 'end',
  source_port: 'source-port-bottom-0',
  target_port: 'target-port-top-0',
  average_time_sec: 1,
  relative_share_of_transactions: 1,
  relative_average_time_sec: 1,
}
