import { useEffect, useRef, useState } from 'react'

import { useDashboard, useEnhancedNavigate, useLocalStorage } from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import {
  processPropertyFiltersKeys,
  supportingSystemFiltersKeys,
} from '../../ProcessMining/Filters/constants'
import { GroupByFieldTypeEnum } from '../../types'
import type {
  AnalysisChartConfigurationData,
  DimensionExtraDataMapper,
  MetricNamesEnum,
  TransactionGroupResult,
} from '../../types'
import AnalysisChart from './AnalysisChart'
import AnalysisFilterPopupModal from './AnalysisFilterPopupModal'
import type { ModalContent } from './AnalysisFilterPopupModal'
import { getFilterValueForChartClick, setUpChartDataAndMetric } from './chartDataHelpers'
import { metricNameExtraDataMapper } from './constants'
import { getDescription } from './getDescription'

interface Props {
  transactionRequestData: TransactionGroupResult | undefined
  stepRequestData: TransactionGroupResult | undefined
  relatedSystemRequestData: TransactionGroupResult | undefined
  selectedMetric: MetricNamesEnum | null
  selectedGroupByField: string
  groupByDimensionsExtraDataMapper: DimensionExtraDataMapper
}

export const CustomAnalysisOneDimension = ({
  transactionRequestData,
  stepRequestData,
  relatedSystemRequestData,
  selectedMetric,
  selectedGroupByField,
  groupByDimensionsExtraDataMapper,
}: Props) => {
  const { teams } = useDashboard()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const storage = useLocalStorage()

  const [chartConfiguration, setChartConfiguration] =
    useState<AnalysisChartConfigurationData | null>(null)
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const teamNameToIdMapper = useRef<{ [key: string]: string }>({})
  const teamNamesToIdArrayMapper = useRef<{ [key: string]: string[] }>({})

  // Server data to chart data logic
  useEffect(() => {
    setChartData(transactionRequestData)
  }, [transactionRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setChartData(stepRequestData)
  }, [stepRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setChartData(relatedSystemRequestData)
  }, [relatedSystemRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  const setChartData = (activeData: TransactionGroupResult | undefined) => {
    if (!activeData || !selectedMetric || !activeData[selectedMetric] || !selectedGroupByField)
      return
    const {
      chartData,
      unit,
      teamNameToIdMapper: newTeamNameToIdMapper,
      teamNamesToIdArrayMapper: newTeamNamesToIdArrayMapper,
    } = setUpChartDataAndMetric(
      activeData[selectedMetric],
      selectedMetric,
      selectedGroupByField,
      teams,
    )
    teamNameToIdMapper.current = newTeamNameToIdMapper
    teamNamesToIdArrayMapper.current = newTeamNamesToIdArrayMapper

    const groupByMetadata = groupByDimensionsExtraDataMapper[selectedGroupByField]
    const metricMetaData =
      metricNameExtraDataMapper[selectedMetric as keyof typeof metricNameExtraDataMapper]

    setChartConfiguration({
      label: `${groupByMetadata.dimension_name} - ${metricMetaData.metric_name}`,
      description: getDescription(groupByMetadata, metricMetaData),
      chartData,
      unit,
      onBarClick: async (label, i) => {
        const chartDataPoint = chartData[i]
        const filterValue = getFilterValueForChartClick(
          label,
          selectedGroupByField,
          teamNameToIdMapper.current,
          teamNamesToIdArrayMapper.current,
        )

        setModalContent({
          label: chartDataPoint.label,
          value: chartDataPoint.value,
          unit,
          filterValue,
        })
      },
    })
  }

  const onFilter = async () => {
    if (!modalContent || !selectedMetric || !selectedGroupByField) return

    const { filter_key, is_tag_dimension } = groupByDimensionsExtraDataMapper[selectedGroupByField]
    if (is_tag_dimension) {
      storage.updateNestedItem(
        StorageNameEnum.PROCESS_FILTERS,
        ProcessFiltersStorageContentEnum.BUSINESS_PROPERTIES,
        { tag_filters: [{ tag_name: filter_key, values: [modalContent.label] }] },
      )
    } else {
      if (selectedGroupByField === GroupByFieldTypeEnum.TEAM_ID) {
        storage.updateNestedItem(
          StorageNameEnum.PROCESS_FILTERS,
          ProcessFiltersStorageContentEnum.PROCESS_METRICS,
          { team_ids: [modalContent.filterValue] },
        )
      } else {
        const isProcessProperty = processPropertyFiltersKeys.some((key) => key === filter_key)
        const isSupportingSystem = supportingSystemFiltersKeys.some((key) => key === filter_key)

        storage.updateNestedItem(
          StorageNameEnum.PROCESS_FILTERS,
          isProcessProperty
            ? ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES
            : isSupportingSystem
              ? ProcessFiltersStorageContentEnum.SUPPORTING_SYSTEM
              : 'invalid_key',
          { [filter_key]: [modalContent.filterValue] },
        )
      }
    }

    onCloseModal()
  }

  const onFilterAndNavigate = () => {
    onFilter()
    navigate('../../variants')
    onCloseModal()
  }

  const onCloseModal = () => setModalContent(null)

  return (
    <>
      {chartConfiguration ? (
        <AnalysisChart configurations={chartConfiguration} isLoading={false} />
      ) : null}

      <AnalysisFilterPopupModal
        content={modalContent}
        onClose={onCloseModal}
        onFilter={onFilter}
        onFilterAndNavigate={onFilterAndNavigate}
      />
    </>
  )
}

export default CustomAnalysisOneDimension
