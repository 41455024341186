import { NavLink } from 'react-router-dom'

import { useEnhancedNavigate } from '../../../common/hooks'
import { MainPathEnum } from '../../../common/types/routing'

const mainNav = [
  { label: 'Start', to: MainPathEnum.START },
  { label: 'Data Flows', to: MainPathEnum.DATA_FLOWS },
  { label: 'Business Apps', to: MainPathEnum.BUSINESS_APPS },
  { label: 'Processes', to: MainPathEnum.PROCESSES, isPlatformViewerView: true },
  { label: 'Opportunities', to: MainPathEnum.OPPORTUNITIES },
  { label: 'Favorites', to: MainPathEnum.FAVORITES },
]

const TopLevelNavigation = () => {
  const { generateEnhancedPath } = useEnhancedNavigate()

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='flex h-12 items-center justify-center'>
        <nav className='flex space-x-4' aria-label='Tabs'>
          {mainNav.map((t) => (
            <NavLink
              key={t.label}
              to={generateEnhancedPath(t.to)}
              className={({ isActive }) => {
                return [
                  'block rounded-full px-3 py-0.5 font-inter text-sm font-medium',
                  isActive
                    ? 'bg-primary-600 text-white hover:bg-primary-700 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    : 'text-gray-900 hover:bg-gray-100 focus:border-primary-500  focus:ring-primary-500',
                ].join(' ')
              }}
            >
              {t.label}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default TopLevelNavigation

