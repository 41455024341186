import { PlusIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useState } from 'react'

import { useGetTeamGroupQuery, useGetTeamsQuery } from '../../app/apiSlice'
import { teamBgColorMap } from '../../app/theme'
import { Button, Link, Table, Text } from '../../common/designs'
import type { TeamGroup } from '../../common/types/common'
import AddTeamGroupModal from './AddTeamGroupModal'
import EditTeamGroupModal from './EditTeamGroupModal'

const ManageGroups = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [selectedTeamGroup, setSelectedTeamGroup] = useState<TeamGroup | null>(null)

  const { data: teams, isLoading } = useGetTeamsQuery()
  const { data: teamGroups } = useGetTeamGroupQuery()

  const onToggleAddModal = () => setIsAddModalOpen((s) => !s)
  const onToggleEditModal = (group: TeamGroup | null) => setSelectedTeamGroup(group)

  return (
    <>
      <Table
        head={['Group name', 'Group members', 'Action']}
        label='Team Groups'
        description='Effortlessly organize teams into groups for improved dashboard view filtering'
        action={
          <Button disabled={isLoading} onClick={onToggleAddModal} iconStart={<PlusIcon />}>
            Add a new team group
          </Button>
        }
        body={
          teamGroups?.map((g) => {
            return [
              g.group_name,
              g.group_members.map((teamId, i) => {
                const team = teams?.find((t) => t.team_id === teamId)
                if (!team) return null

                return (
                  <div className='flex items-baseline gap-3' key={`${g.group_id}-${teamId}`}>
                    <div
                      className={clsx(
                        'size-3 rounded',
                        teamBgColorMap[team.color_index],
                        i !== 0 && 'mt-2',
                      )}
                      key={team.team_id}
                    />

                    <Text size='s'>{team.team_name}</Text>
                  </div>
                )
              }),
              <Link type='ghost' onClick={() => onToggleEditModal(g)} key={g.group_id}>
                Edit
              </Link>,
            ]
          }) ?? []
        }
      />

      {isAddModalOpen && <AddTeamGroupModal open={isAddModalOpen} onClose={onToggleAddModal} />}
      {selectedTeamGroup && (
        <EditTeamGroupModal
          open={Boolean(selectedTeamGroup)}
          onClose={() => onToggleEditModal(null)}
          teamGroup={selectedTeamGroup}
        />
      )}
    </>
  )
}

export default ManageGroups
