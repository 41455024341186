import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { LocalStorageProvider } from './LocalStorageContext'
import router from './Router'

const App = () => {
  return (
    <LocalStorageProvider>
      <ToastContainer position='bottom-center' limit={4} />

      <RouterProvider router={router} />
    </LocalStorageProvider>
  )
}

export default App
