import { sub } from 'date-fns'
import { useState } from 'react'

import { Text } from '../../common/designs'
import type { DateRange } from '../../common/types/monitoring'
import { roundToNextHour } from '../../common/utils/dateTimeUtils'
import { PerformanceReadingTypeEnum } from '../PerformanceMonitoringAdminPanelPage/types'
import ConnectionsBarchart from './ConnectionsBarchart'
import DateSelector from './DateSelector'
import IdentifierOccurancesHeatmap from './IdentifierOccurancesBarchart'
import PerformancePercentilesBarchart from './PerformancePercentilesLinechart'

const MonitoringOverviewAdminPanelPage = () => {
  const today = new Date()

  const [selectedDates, setSelectedDates] = useState<DateRange>({
    start: new Date(sub(today, { days: 6 }).setHours(0, 0, 0, 0)),
    end: roundToNextHour(today),
    isSelecting: false,
  })

  return (
    <div className='space-y-4'>
      <div className='flex items-center gap-4'>
        <Text variant='h4' size='l'>
          Monitoring Overview
        </Text>

        <DateSelector selectedDates={selectedDates} setSelectedDates={setSelectedDates} />
      </div>

      <ConnectionsBarchart selectedDates={selectedDates} />

      <IdentifierOccurancesHeatmap selectedDates={selectedDates} />

      <div className='flex w-full flex-wrap'>
        <div className='w-full p-2 2xl:w-1/2'>
          <PerformancePercentilesBarchart
            selectedDates={selectedDates}
            unit={PerformanceReadingTypeEnum.CPU}
            percentile={0.95}
          />
        </div>

        <div className='w-full p-2 2xl:w-1/2'>
          <PerformancePercentilesBarchart
            selectedDates={selectedDates}
            unit={PerformanceReadingTypeEnum.MEMORY}
            percentile={0.95}
          />
        </div>
      </div>
    </div>
  )
}

export default MonitoringOverviewAdminPanelPage
