import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useGetProcessIdentifierMapperQuery } from '../../app/apiSlice'
import { isStringArray } from '../../common/types/typeGuards'
import ActiveFilterDisplay from './ActiveFilterDisplay'
import AddFilterField from './AddFilterField'
import { useGetTaskFiltersAsArray } from './useGetTaskFiltersAsArray'

const TaskListFilterMenu = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTaskFiltersArray = useGetTaskFiltersAsArray()

  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  const onDeleteFilter = useCallback(
    (filterKey: string) => {
      searchParams.delete(filterKey)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const onSetFilter = useCallback(
    (filterKey: string, filterValue: string | string[]) => {
      const paramValue = isStringArray(filterValue) ? JSON.stringify(filterValue) : filterValue
      searchParams.set(filterKey, paramValue)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  return (
    <>
      <div className='flex gap-2'>
        {activeTaskFiltersArray.map((f) => (
          <ActiveFilterDisplay
            key={f.filterKey}
            data={f}
            onDelete={() => onDeleteFilter(f.filterKey)}
            processMapper={processMapper}
          />
        ))}
      </div>
      <AddFilterField
        onSetFilter={onSetFilter}
        activeTaskFiltersArray={activeTaskFiltersArray}
        processMapper={processMapper}
      />
    </>
  )
}

export default TaskListFilterMenu
