import { PlusIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useState } from 'react'

import { useGetTeamsQuery } from '../../app/apiSlice'
import { teamBgColorMap } from '../../app/theme'
import { Button, Link, Table } from '../../common/designs'
import type { Team } from '../../common/types/common'
import AddTeamModal from './AddTeamModal'
import EditTeamModal from './EditTeamModal'
import EditUsernamesModal from './EditUsernamesModal'

const TeamSettings = () => {
  const [activeTeam, setActiveTeam] = useState<Team | null>(null)
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState<boolean>(false)
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState<boolean>(false)
  const [isEditUsernamesModalOpen, setIsEditUsernamesModalOpen] = useState<boolean>(false)

  const { data, isLoading } = useGetTeamsQuery()

  return (
    <>
      <div className='-ml-4'>
        <Table
          head={['Team name', 'Color', 'Cost', 'Currency', 'Action']}
          action={
            <Button
              disabled={isLoading}
              onClick={() => setIsAddTeamModalOpen(true)}
              iconStart={<PlusIcon />}
              className='-ml-4'
            >
              Add a new team
            </Button>
          }
          body={
            data?.map((t) => {
              return [
                t.team_name,
                <div
                  className={clsx('size-5 rounded-md', teamBgColorMap[t.color_index])}
                  key={t.id}
                />,
                t.cost_per_hour,
                t.currency,
                <div className='flex justify-end gap-8' key={`actions-${t.id}`}>
                  <Link
                    type='ghost'
                    onClick={() => {
                      setActiveTeam(t)
                      setIsEditUsernamesModalOpen(true)
                    }}
                  >
                    Map users
                  </Link>

                  <Link
                    type='ghost'
                    onClick={() => {
                      setActiveTeam(t)
                      setIsEditTeamModalOpen(true)
                    }}
                  >
                    Edit
                  </Link>
                </div>,
              ]
            }) ?? []
          }
        />
      </div>

      <AddTeamModal open={isAddTeamModalOpen} onClose={() => setIsAddTeamModalOpen(false)} />

      <EditUsernamesModal
        invitationToken={activeTeam?.invitation_token}
        open={isEditUsernamesModalOpen}
        onClose={() => setIsEditUsernamesModalOpen(false)}
      />

      <EditTeamModal
        activeTeam={activeTeam}
        open={isEditTeamModalOpen}
        onClose={() => setIsEditTeamModalOpen(false)}
      />
    </>
  )
}

export default TeamSettings
