import { ChevronRightIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Fragment } from 'react'

import Link from './Link'
import LinkButton from './LinkButton'
import Text from './Text'

export interface BreadcrumbOption {
  label: string
  onClick?: () => void
  href?: string
}

const secondaryHeightInPixels: { [key in Size]: string } = {
  s: 'h-[29.33px]',
  m: 'h-[36.66px]',
}

const secondaryOrderedListStyles: { [key in Size]: string } = {
  s: 'space-x-3 px-4',
  m: 'space-x-4 px-5',
}

const secondaryChevronStyles: { [key in Size]: string } = {
  s: 'w-4 text-gray-300',
  m: 'w-5 text-gray-200',
}

const iconStyles: { [key in Size]: string } = {
  s: 'size-4',
  m: 'size-5',
}

type Variant = 'primary' | 'secondary'
type Size = 's' | 'm'

interface Props {
  items: BreadcrumbOption[]
  variant?: Variant
  size?: Size
  className?: string
}

const BreadcrumbItem = ({ item, size }: { item: BreadcrumbOption; size: Size }) => {
  if (item.href) {
    return (
      <Link size={size} href={item.href}>
        {item.label}
      </Link>
    )
  }

  if (item.onClick) {
    return (
      <LinkButton size={size} onClick={item.onClick}>
        {item.label}
      </LinkButton>
    )
  }

  return <Text size={size}>{item.label}</Text>
}

const Breadcrumbs = ({ items, variant = 'primary', size = 'm', className }: Props) => {
  if (variant === 'primary') {
    return (
      <nav className={clsx('w-full', className)} aria-label='Breadcrumb'>
        <ol className='flex list-none items-center p-0'>
          {items.map((item, i) => (
            <Fragment key={item.label}>
              <li>
                <BreadcrumbItem item={item} size={size} />
              </li>

              {i < items.length - 1 && (
                <li>
                  <ChevronRightIcon
                    className={clsx('shrink-0 text-gray-400', iconStyles[size])}
                    aria-hidden='true'
                  />
                </li>
              )}
            </Fragment>
          ))}
        </ol>
      </nav>
    )
  }

  return (
    <nav className={clsx('flex', secondaryHeightInPixels[size], className)} aria-label='Breadcrumb'>
      <ol className={clsx('flex rounded-md bg-white shadow', secondaryOrderedListStyles[size])}>
        {items.map((item, i) => {
          const isFirstItemInArray = i === 0

          return (
            <li key={item.label} className='flex'>
              <div className='flex items-center'>
                {isFirstItemInArray ? null : (
                  <svg
                    className={clsx('h-full shrink-0', secondaryChevronStyles[size])}
                    viewBox='0 0 24 44'
                    preserveAspectRatio='none'
                    fill='currentColor'
                    aria-hidden='true'
                  >
                    <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                  </svg>
                )}

                <li className={clsx(!isFirstItemInArray && 'ml-4')}>
                  <BreadcrumbItem item={item} size={size} />
                </li>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
