import { teamBgColorMap } from '../../app/theme'
import { formatNumber } from '../../common/utils/numberFormatting'
import DistributionChart from './DistributionChart'
import { AppUsageData } from './types'

interface Props {
  appData: AppUsageData[]
}

const TaskListAppDistribution = ({ appData }: Props) => {
  const totalTime = appData.reduce((total, item) => total + item.duration_sec, 0)

  const visualizationData = appData.map(({ app_name, duration_sec }, i) => {
    return {
      value: `${formatNumber(duration_sec / 60 / 60)} h`,
      relativeValue: Math.round((duration_sec / totalTime) * 100),
      label: app_name,
      bgColor: teamBgColorMap[(i % 10) + 1],
    }
  })

  return <DistributionChart data={visualizationData} />
}

export default TaskListAppDistribution
