import { PencilIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { IconButton, InputField, Text } from '../../../../../common/designs'

interface Props {
  title: string
  control?: any
  errors?: any
  hideEdit?: boolean
}

const Title = ({ title, control, errors, hideEdit }: Props) => {
  const [allowEdit, setAllowEdit] = useState<boolean>(false)

  return (
    <div className='group mb-3 mt-1'>
      <div className='flex items-center'>
        {allowEdit ? (
          hideEdit ? (
            <Text variant='h4' size='2xl'>
              {title}
            </Text>
          ) : (
            <Controller
              name='title'
              control={control}
              render={({ field }) => <InputField error={errors.title?.message} {...field} />}
            />
          )
        ) : (
          <Text variant='h4' size='2xl'>
            {title}
          </Text>
        )}

        <IconButton
          variant='white'
          onClick={() => setAllowEdit(!allowEdit)}
          icon={<PencilIcon />}
          className='ml-6 hidden group-hover:flex'
        />
      </div>
    </div>
  )
}

export default Title
