import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import useLocalStorage from '../common/hooks/useLocalStorage'
import type { Item, Key } from '../common/hooks/useLocalStorage'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../common/types/storage'
import type { ProcessFiltersStorageContent as ProcessFiltersType } from '../common/types/storage'
import { DashboardInitialization } from '../common/utils/UtilityWrapper/types'

interface LocalStorageContextType {
  processFilters: ProcessFiltersType
  setProcessFilters: React.Dispatch<ProcessFiltersType>
  handleProcessFilterChange: (key: Key, item: Item) => void
  dashboardSetup: DashboardInitialization | null
  isDashboardInitialized: boolean
  handleIsDashboardInitializedChange: (key: Key, item: DashboardInitialization) => void
}

const LocalStorageContext = createContext<LocalStorageContextType>({
  processFilters: {
    [ProcessFiltersStorageContentEnum.ZOOMING]: { zooming_filters: {} },
  },
  setProcessFilters: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  handleProcessFilterChange: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  dashboardSetup: null,
  isDashboardInitialized: false,
  handleIsDashboardInitializedChange: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

export const useLocalStorageContext = () => useContext(LocalStorageContext)

export const LocalStorageProvider = ({ children }: { children: React.ReactNode }) => {
  // Initialize values.
  const initialProcessFilters = useInitialProcessFilters()
  const initialIsDashboardInitialized = useInitialIsDashboardInitialized()

  // States.
  const [processFilters, setProcessFilters] = useState<ProcessFiltersType>(initialProcessFilters)
  const [dashboardSetup, setDashboardSetup] = useState<DashboardInitialization | null>(
    initialIsDashboardInitialized,
  )

  // Handlers.
  const handleProcessFilterChange = useCallback(
    (key: Key, item: Item) => {
      if (key === StorageNameEnum.PROCESS_FILTERS) setProcessFilters(item)
    },
    [setProcessFilters],
  )
  const handleIsDashboardInitializedChange = useCallback(
    (key: Key, item: DashboardInitialization) => {
      if (key === StorageNameEnum.DASHBOARD) setDashboardSetup(item)
    },
    [setDashboardSetup],
  )

  return (
    <LocalStorageContext.Provider
      value={useMemo(
        () => ({
          processFilters,
          setProcessFilters,
          handleProcessFilterChange,
          dashboardSetup,
          handleIsDashboardInitializedChange,
          isDashboardInitialized: Boolean(dashboardSetup),
        }),
        [
          processFilters,
          dashboardSetup,
          handleProcessFilterChange,
          handleIsDashboardInitializedChange,
        ],
      )}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}

// Value initializers.
const useInitialProcessFilters = (): ProcessFiltersType => {
  const { getItem } = useLocalStorage()

  return (
    getItem<ProcessFiltersType>(StorageNameEnum.PROCESS_FILTERS) ?? {
      [ProcessFiltersStorageContentEnum.ZOOMING]: {
        zooming_filters: {
          selected_step_variants: null,
          step_name: null,
          selected_state_variants: null,
          state_name: null,
        },
      },
    }
  )
}

const useInitialIsDashboardInitialized = () => {
  const { getItem } = useLocalStorage()

  return getItem<DashboardInitialization>(StorageNameEnum.DASHBOARD)
}
