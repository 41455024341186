import { ActivityInsightsBody } from '../../features/BusinessApps/types'
import type { Tag } from '../types/common'
import { useQueryFilters } from './'
import { TagOfInterestSource } from './useFilteredWindowUsageQuery'

export interface GetActivityInsightBodyOptions {
  tagsOfInterestSource?: TagOfInterestSource
  tagFilters?: Tag[]
  use_exact_tag_filter_match?: boolean
}

const useGetActivityInsightBody = ({
  tagsOfInterestSource,
  tagFilters,
  use_exact_tag_filter_match = false,
}: GetActivityInsightBodyOptions): ActivityInsightsBody => {
  const { min_date, max_date, team_ids, tags_of_interest } = useQueryFilters()

  return {
    min_date,
    max_date,
    team_ids,
    tags_of_interest: tagsOfInterestSource ? tags_of_interest[tagsOfInterestSource] : null,
    tag_filters: tagFilters,
    use_exact_tag_filter_match,
  }
}

export default useGetActivityInsightBody
