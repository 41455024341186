import type { ChartData } from 'chart.js'
import { formatISO } from 'date-fns'
import { Line } from 'react-chartjs-2'

import { useGetPerformanceReadingsPercentilesQuery } from '../../app/apiSlice'
import { InlineLoader, Loader } from '../../common/components'
import { Text } from '../../common/designs'
import {
  type DateRange,
  GranularityEnum,
  PerformanceReadingsPercentilesPayload,
} from '../../common/types/monitoring'
import { performanceReadingTypeMapper } from '../PerformanceMonitoringAdminPanelPage/constants'
import {
  PerformanceReadingTypeCpuEnum,
  PerformanceReadingTypeEnum,
  PerformanceReadingTypeMemoryEnum,
} from '../PerformanceMonitoringAdminPanelPage/types'

const readingTypeArrays = {
  [PerformanceReadingTypeEnum.CPU]: {
    pluginLoad: [
      PerformanceReadingTypeCpuEnum.MONITORING_UNIVERSE,
      PerformanceReadingTypeCpuEnum.PROCESSING_UNIVERSE,
      PerformanceReadingTypeCpuEnum.IO_UNIVERSE,
      PerformanceReadingTypeCpuEnum.MAIN_PROCESS,
    ],
    totalLoad: [PerformanceReadingTypeCpuEnum.TOTAL_SYSTEM_CPU_LOAD],
  },
  [PerformanceReadingTypeEnum.MEMORY]: {
    pluginLoad: [PerformanceReadingTypeMemoryEnum.PLUGIN_MEMORY_LOAD],
    totalLoad: [PerformanceReadingTypeMemoryEnum.TOTAL_SYSTEM_MEMORY_LOAD],
  },
}

interface Props {
  selectedDates: DateRange
  unit: PerformanceReadingTypeEnum
  percentile: number // Float between 0.0 - 1.00
}

const PerformancePercentilesLinechart = ({ selectedDates, unit, percentile }: Props) => {
  const { data: rawData, isFetching } = useGetPerformanceReadingsPercentilesQuery({
    start_timestamp: formatISO(selectedDates.start ?? new Date()),
    end_timestamp: formatISO(selectedDates.end ?? new Date()),
    reading_type: unit,
    granularity: GranularityEnum.DAY,
    percentiles: [percentile],
  })

  if (!rawData) return <Loader />

  const aggregateData = (
    rawData: PerformanceReadingsPercentilesPayload,
    readingTypes: string[],
    percentile: number,
  ) => {
    const aggregatedData = uniqueTimestamps.map((timestamp) => {
      const filteredData = rawData.filter(
        (dp) =>
          dp.grouping_timestamp === timestamp &&
          readingTypes.includes(dp.reading) &&
          dp.percentile === percentile,
      )

      const sum = filteredData.reduce((sum, item) => sum + item.value, 0)
      return Math.round(sum * 10) / 10
    })

    return aggregatedData
  }

  // Get all unique timestamps and sort them by ascending order
  const uniqueTimestamps = [...new Set(rawData.map((dp) => dp.grouping_timestamp))].sort()

  // Generate chartdata for the percentiles.
  const chartData: ChartData<'line'> = {
    labels: uniqueTimestamps.map((timestamp) => timestamp.split('T')[0]),
    datasets: [
      {
        label: `Process Intelligence`,
        data: aggregateData(rawData, readingTypeArrays[unit].pluginLoad, percentile),
        backgroundColor: '#6ee7b7', // Emerald 300
        fill: 'origin',
      },
      {
        label: `Total System Load`,
        data: aggregateData(rawData, readingTypeArrays[unit].totalLoad, percentile),
        backgroundColor: '#fca5a5', // Red 300
        fill: 'origin',
      },
    ],
  }

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <div className='mb-2 mr-2 flex justify-between gap-4'>
        <Text>{performanceReadingTypeMapper[unit]} Performance</Text>
        {isFetching ? <InlineLoader /> : null}
        <div /> {/* Empty div to centralize InlineLoader like in other components. */}
      </div>

      <Line
        data={chartData}
        options={{
          scales: {
            y: {
              ticks: {
                font: {
                  family: 'Inter',
                },
              },
              suggestedMax: 100,
            },
          },
          plugins: {
            legend: {
              display: true,
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
        }}
        height='60px'
      />

      <Text size='xs' className='mt-6'>
        The data above shows that 95% of the time the usage is below this threshold.
      </Text>
    </div>
  )
}

export default PerformancePercentilesLinechart
