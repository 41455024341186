import { Button, Text } from '../../../common/designs'
import { generateIncrementalIndexesArrayUntil } from '../../../common/utils/utils'

interface Props {
  totalVariantAmount: number
  setSelectedVariantsForUpdate: (variants: number[]) => void
  onUpdate: () => void
}

const SelectionDrawerContent = (props: Props) => {
  const { totalVariantAmount, onUpdate, setSelectedVariantsForUpdate } = props

  const renderSelectButton = (label: string, amount: number) => {
    const totalSelections = amount <= totalVariantAmount ? amount : totalVariantAmount

    return (
      <Button
        variant='white'
        onClick={() => {
          setSelectedVariantsForUpdate(generateIncrementalIndexesArrayUntil(totalSelections))
        }}
        wFull
      >
        {label}
      </Button>
    )
  }

  return (
    <>
      <div className='mb-2'>
        <Text size='s' weight={500} color='gray' className='mb-2'>
          Variant amount quick selection
        </Text>

        <div className='flex gap-2'>
          {renderSelectButton('None', 0)}
          {renderSelectButton('Top 5', 5)}
          {renderSelectButton('Top 15', 15)}
          {renderSelectButton('All', totalVariantAmount)}
        </div>
      </div>

      <div className='sticky mb-2'>
        <Button onClick={onUpdate} wFull>
          Update view
        </Button>
      </div>
    </>
  )
}

export default SelectionDrawerContent
