import {
  ArrowPathIcon,
  BanknotesIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  ComputerDesktopIcon,
  DocumentDuplicateIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { cloneElement } from 'react'

import { teamBgColorMap } from '../../app/theme'
import { Divider as DIVIDER, Popover, Text } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import { formatNumber } from '../../common/utils/numberFormatting'
import useOptions from '../DataFlows/useOptions'
import { DetailLevelEnum } from './types'

const Root = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='absolute z-[1] w-[264px] rounded-xl bg-white/80 shadow-lg ring-1 ring-gray-900/5'>
      {children}
    </div>
  )
}

const Container = ({ children }: { children: React.ReactNode }) => {
  return <div className='px-3 py-2'>{children}</div>
}

const Divider = ({ label }: { label?: string }) => {
  return <DIVIDER color={label ? 'lightGray' : 'gray'} label={label} />
}

const Label = ({ mainLabel, subLabel }: { mainLabel: string; subLabel?: string }) => {
  return (
    <div className='px-3 pb-2 pt-3'>
      <Text variant='h4' size='xl'>
        {mainLabel}
      </Text>

      {subLabel ? (
        <Text variant='h4' size='s' color='lightGray'>
          {subLabel}
        </Text>
      ) : null}
    </div>
  )
}

interface ItemBaseProps {
  label: string
  labelSuffix?: string
  value: string | number
  icon?: JSX.Element
  children?: JSX.Element
}

const ItemBase = ({ label, labelSuffix, value, icon, children }: ItemBaseProps) => {
  return (
    <div key={label} className='pb-2'>
      <Text size='xl' className='mt-1 text-indigo-600' variant='h5' weight={400}>
        {value} {labelSuffix && <span className='text-sm text-gray-400'>{labelSuffix}</span>}
      </Text>

      <div className='flex items-center gap-1'>
        {icon ? <>{cloneElement(icon, { className: 'size-4' })}</> : null}
        <Text size='xs' variant='h4' weight={600} color='gray'>
          {label}
        </Text>

        {children && (
          <Popover
            action={
              <div className='cursor-pointer rounded-md p-0.5 hover:bg-gray-200'>
                <ChevronDownIcon className='size-3 ' />
              </div>
            }
            actionAs='div'
          >
            {children}
          </Popover>
        )}
      </div>
    </div>
  )
}

const ItemCopies = ({ value }: { value: number | string }) => {
  const { detailLevel } = useOptions()

  return (
    <ItemBase
      label={`Copies fom the ${detailLevel}`}
      value={value}
      icon={<DocumentDuplicateIcon />}
    />
  )
}

const ItemPastes = ({ value }: { value: number | string }) => {
  const { detailLevel } = useOptions()

  return (
    <ItemBase
      label={`Pastes to the ${detailLevel}`}
      value={value}
      icon={<ClipboardDocumentListIcon />}
    />
  )
}

const ItemSelfLoop = ({ value }: { value: number | string }) => {
  const { detailLevel } = useOptions()

  return (
    <ItemBase
      label={`Copy-pastes within the ${detailLevel}`}
      value={value}
      icon={<ArrowPathIcon />}
    />
  )
}

const ItemOriginVisits = ({
  value,
  detailLevel,
}: {
  value: number | string
  detailLevel: DetailLevelEnum | undefined
}) => {
  const { detailLevel: detailLevelState } = useOptions()

  return (
    <ItemBase
      label={`Visits to/from the origin ${detailLevel ?? detailLevelState}`}
      value={formatNumber(value)}
      icon={<ComputerDesktopIcon />}
    />
  )
}

const ItemVisits = ({ value }: { value: number | string }) => {
  return (
    <ItemBase label='Visits in total' value={formatNumber(value)} icon={<ComputerDesktopIcon />} />
  )
}

const ItemNavigations = ({ value }: { value: number | string }) => {
  return (
    <ItemBase
      label='Navigations in total'
      value={formatNumber(value)}
      icon={<ComputerDesktopIcon />}
    />
  )
}

const ItemTotalHours = ({ value }: { value: number }) => {
  return <ItemBase label='Hours in total' value={formatNumber(value, 1)} icon={<ClockIcon />} />
}

const ItemTeams = ({ value }: { value: string[] }) => {
  const { company, teams } = useDashboard()

  return (
    <ItemBase
      label='Teams'
      labelSuffix={`out of ${company.teamCount}`}
      value={value.length}
      icon={<UsersIcon />}
    >
      <div className='w-60 p-2'>
        {value.map((teamId, i) => {
          const team = teams[teamId]

          return team ? (
            <div className={clsx('flex items-baseline gap-2', i !== 0 && 'mt-2')} key={teamId}>
              <div className={clsx('size-3 rounded', teamBgColorMap[team.colorIndex])} />

              <Text size='s'>{team.name}</Text>
            </div>
          ) : null
        })}
      </div>
    </ItemBase>
  )
}

const Cost = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stat.Container>
      <ItemBase label={label} value={value} icon={<BanknotesIcon />} />
    </Stat.Container>
  )
}

type NavigationOption = {
  label: string
  onClick: () => void
  icon: JSX.Element
}

interface StatNavigationProps {
  options: [NavigationOption] | [NavigationOption, NavigationOption]
}

const Navigation = ({ options }: StatNavigationProps) => {
  const hasOnlyOneOption = options.length === 1

  return (
    <div
      className={clsx(
        ' divide-x divide-gray-900/5 rounded-b-xl border-t bg-gray-50 text-xs',
        !hasOnlyOneOption && 'grid grid-cols-2',
      )}
    >
      {options.map(({ label, onClick, icon }, i) => {
        const isSecondOption = i === 1
        const btnStyle = hasOnlyOneOption
          ? 'rounded-b-2xl'
          : isSecondOption
            ? 'justify-center rounded-br-2xl'
            : 'justify-center rounded-bl-2xl'

        return (
          <button
            onClick={onClick}
            className={clsx(
              'flex w-full items-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100',
              btnStyle,
            )}
            key={label}
          >
            {cloneElement(icon, { className: 'size-5 flex-none text-gray-400' })}
            {label}
          </button>
        )
      })}
    </div>
  )
}

const Stat = {
  Root,
  Container,
  Divider,
  Label,
  ItemBase,
  ItemCopies,
  ItemPastes,
  ItemSelfLoop,
  ItemOriginVisits,
  ItemVisits,
  ItemNavigations,
  ItemTotalHours,
  ItemTeams,
  Cost,
  Navigation,
}

export default Stat
