import { PlusIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'

import { Select } from '../../../../common/designs'
import { useLocalStorage } from '../../../../common/hooks'
import {
  ProcessFiltersStorageContent,
  ProcessFiltersStorageContentEnum,
  StorageNameEnum,
} from '../../../../common/types/storage'
import type { TaskFilterData, UserInteractionFilters } from '../../types'
import FilterItem from './FilterItem'
import UserInteractionFilterInput from './UserInteractionFilterInput'
import { InteractionFilterOptionEnum } from './types'

interface Props {
  title: string
  selectedFilterData: UserInteractionFilters
  setSelectedFilterData: React.Dispatch<React.SetStateAction<UserInteractionFilters>>
}

const defaultValue = {
  label: 'Choose filter',
  value: 'Choose filter',
  prefix: <PlusIcon className='size-5' />,
}

const defaultValueNoOptions = {
  label: 'All filters have been selected',
  value: 'All filters have been selected',
}

const interactionFilterNames = {
  [InteractionFilterOptionEnum.COPY_COUNT]: 'Data copies',
  [InteractionFilterOptionEnum.PASTE_COUNT]: 'Data pastes',
  [InteractionFilterOptionEnum.KEYBOARD_COUNT]: 'Keyboard key presses',
  [InteractionFilterOptionEnum.MOUSE_CLICK_COUNT]: 'Mouse clicks',
}

const userInteractionFilterOptions = [
  { label: 'Data copies', value: InteractionFilterOptionEnum.COPY_COUNT },
  { label: 'Data pastes', value: InteractionFilterOptionEnum.PASTE_COUNT },
  { label: 'Keyboard key presses', value: InteractionFilterOptionEnum.KEYBOARD_COUNT },
  { label: 'Mouse clicks', value: InteractionFilterOptionEnum.MOUSE_CLICK_COUNT },
]

const UserInteractionFilterBlock = ({
  title,
  selectedFilterData,
  setSelectedFilterData,
}: Props) => {
  const storage = useLocalStorage()

  const [selectedFiltersSet, setSelectedFiltersSet] = useState<Set<InteractionFilterOptionEnum>>(
    new Set(),
  )

  // Initialize active interaction filters from storage
  useEffect(() => {
    const interactionFilters =
      storage.getItem<ProcessFiltersStorageContent>(StorageNameEnum.PROCESS_FILTERS)?.[
        ProcessFiltersStorageContentEnum.USER_INTERACTION
      ] ?? {}
    setSelectedFilterData(interactionFilters)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedFiltersSet(new Set(Object.keys(selectedFilterData) as InteractionFilterOptionEnum[]))
  }, [selectedFilterData]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFilter = (selectedValue: string) => {
    setSelectedFilterData({
      ...selectedFilterData,
      [selectedValue as keyof UserInteractionFilters]: {},
    })
  }

  const onRemoveFilter = (selectedValue: string) => {
    const newSelectedFilterData = { ...selectedFilterData }
    delete newSelectedFilterData[selectedValue as keyof UserInteractionFilters]
    setSelectedFilterData(newSelectedFilterData)
  }

  const onUpdateMinMaxForFilter = (selectedFilterValue: string, updatedData: TaskFilterData) => {
    const newSelectedFilterData = { ...selectedFilterData }
    const selectedFilter = {
      ...(newSelectedFilterData[selectedFilterValue as keyof UserInteractionFilters] || {}),
      ...updatedData,
    }
    newSelectedFilterData[selectedFilterValue as keyof UserInteractionFilters] = selectedFilter
    setSelectedFilterData(newSelectedFilterData)
  }

  const isAllDimensionsSelected = selectedFiltersSet.size === userInteractionFilterOptions.length

  return (
    <>
      <FilterItem.Header>
        <FilterItem.Title>{title}</FilterItem.Title>

        {isAllDimensionsSelected ? (
          <Select
            options={[defaultValueNoOptions]}
            value={defaultValueNoOptions.value}
            onChange={() => null}
            disabled={true}
            className='w-3/5'
          />
        ) : (
          <Select
            options={[
              defaultValue,
              ...userInteractionFilterOptions
                .filter(({ value }) => !selectedFiltersSet.has(value))
                .sort((a, b) => a.label.localeCompare(b.label)),
            ]}
            value={defaultValue.value}
            onChange={onSelectFilter}
            className='w-3/5'
          />
        )}
      </FilterItem.Header>

      {[...selectedFiltersSet].map((filterValue) => (
        <UserInteractionFilterInput
          filterName={interactionFilterNames[filterValue]}
          onRemoveFilter={() => onRemoveFilter(filterValue)}
          minValue={selectedFilterData[filterValue]?.min_value ?? undefined}
          onSetMinValue={(value: number) =>
            onUpdateMinMaxForFilter(filterValue, { min_value: value })
          }
          maxValue={selectedFilterData[filterValue]?.max_value ?? undefined}
          onSetMaxValue={(value: number) =>
            onUpdateMinMaxForFilter(filterValue, { max_value: value })
          }
          key={filterValue}
        />
      ))}
    </>
  )
}

export default UserInteractionFilterBlock
