import type { NodeProps } from 'reactflow'

import { useAppSelector } from '../../../../common/hooks'
import { getBestTimeFormatForSeconds } from '../../../../common/utils/numberFormatting'
import { selectHighlightSelections } from '../../processesSlice'
import { HighlightFieldEnum } from '../../types'
import NodeActionButtons from './NodeActionButtons'
import NodeAutomationInsights from './NodeAutomationInsights'
import { StepDataModal } from './NodeDataModal'
import NodeLayout from './NodeLayout'
import NodeTeamDistribution from './NodeTeamDistribution'
import NodeWorkTypeDistribution from './NodeWorkTypeDistribution'
import type { ServerStepFlowNode } from './types'
import { useModalConfigurations } from './useModalConfigurations'
import { useZoomingFilters } from './useZoomingFilters'

const StepFlowNode = ({ data }: NodeProps<ServerStepFlowNode>) => {
  const { updateZoomingFilters, setStepModalFilters, clearStepModalFilters, zoomingFilters } =
    useZoomingFilters()
  const { selectedStepName, setStepModalStepName, closeStepModal } = useModalConfigurations()

  const {
    display_name: displayName,
    step_name: stepName,
    step_index: stepIndex,
    transactions,
    avg_duration_sec,
    is_conformance_flow_node: isConformanceFlowNode,

    top_ports: topPorts,
    right_ports: rightPorts,
    bottom_ports: bottomPorts,
    left_ports: leftPorts,

    relative_time_per_transaction: relativeTime,
    relative_share_of_transactions: relativeTransactions,
    is_preparation_step: isPreparationStep,
  } = data

  const isDataModalOpen = selectedStepName === displayName
  const { activeHighlighField } = useAppSelector(selectHighlightSelections)

  const relativeData =
    activeHighlighField === HighlightFieldEnum.TRANSACTIONS ? relativeTransactions : relativeTime

  const timeString = getBestTimeFormatForSeconds(avg_duration_sec)

  const onZoom = () => {
    updateZoomingFilters({ stepName, stepIndex })
  }

  const onOpenDataModal = () => {
    setStepModalStepName(displayName)
    setStepModalFilters(stepName, stepIndex)
  }

  const onCloseDataModal = () => {
    clearStepModalFilters()
    closeStepModal()
  }

  return (
    <>
      <NodeLayout
        ports={{ topPorts, rightPorts, bottomPorts, leftPorts }}
        relativeSize={relativeData}
        nodeName={displayName}
        timeText={timeString}
        transactionCount={transactions}
        isConformanceFlowNode={isConformanceFlowNode}
        actionButtons={<NodeActionButtons onZoom={onZoom} onShowMetrics={onOpenDataModal} />}
        isPreparationStep={isPreparationStep}
        isHighlightingConformance
      >
        <NodeTeamDistribution stepName={displayName} />
        <NodeWorkTypeDistribution stepName={displayName} />
        <NodeAutomationInsights stepName={displayName} />
      </NodeLayout>
      <StepDataModal
        isOpen={Boolean(zoomingFilters?.step_name) && isDataModalOpen}
        onClose={onCloseDataModal}
        stepName={displayName}
      />
    </>
  )
}

export default StepFlowNode
