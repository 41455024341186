import clsx from 'clsx'

import { basicBgColorMap } from '../../app/theme'
import type { BasicColorIndex } from '../../app/theme'

interface Props {
  colorIndex: BasicColorIndex
}

const ColorBox = ({ colorIndex }: Props) => {
  return <div className={clsx('size-5 rounded-md', basicBgColorMap[colorIndex])} />
}

export default ColorBox
