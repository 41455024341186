import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React, { Fragment } from 'react'

import { useDashboard } from '../hooks'
import { DrawerFocusTransition, DrawerTransition } from './HelperComponents'

const sizeStyles: { [key in Size]: string } = {
  xs: 'max-w-sm',
  s: 'max-w-md',
  m: 'max-w-lg',
  l: 'max-w-xl',
  xl: 'max-w-2xl',
  '2xl': 'max-w-3xl',
  '3xl': 'max-w-4xl',
}

export type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl'

interface Props {
  open: boolean
  onClose: () => void
  label: string
  children: React.ReactNode
  tabs: string[]
  tabIndex: number
  setTabIndex: (value: number) => void

  size?: Size
  focus?: boolean // Blurs background to focus on the Drawer.
  hasNoMainNavigationOverlap?: boolean // Adds margin to the top of the Drawer to avoid overlap with the main navigation.
}

const DrawerMultiple = (props: Props) => {
  const {
    open,
    onClose,
    label,
    children,
    tabs,
    tabIndex,
    setTabIndex,

    size = 'm',
    focus = true,
    hasNoMainNavigationOverlap,
  } = props

  const {
    user: { isPlatformViewer },
  } = useDashboard()

  let marginTop = ''
  if (hasNoMainNavigationOverlap) {
    marginTop = isPlatformViewer ? 'mt-12' : 'mt-52 lg:mt-40'
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <div className='fixed inset-0' />

        {focus && <DrawerFocusTransition hasNoMainNavigationOverlap={hasNoMainNavigationOverlap} />}

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <DrawerTransition>
                <Dialog.Panel
                  className={clsx(sizeStyles[size], marginTop, 'pointer-events-auto w-screen')}
                >
                  <div className='flex h-full flex-col overflow-hidden bg-white shadow-xl'>
                    <div className='p-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                          {label}
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
                            onClick={onClose}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='size-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='border-b border-gray-200'>
                      <div className='px-6'>
                        <nav className='-mb-px flex space-x-6'>
                          {tabs.map((tab, i) => (
                            <button
                              key={tab}
                              onClick={() => setTabIndex(i)}
                              className={clsx(
                                tabIndex === i
                                  ? 'border-indigo-500 text-indigo-600'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                              )}
                            >
                              {tab}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>

                    <div className='h-full overflow-y-auto p-6'>{children}</div>
                  </div>
                </Dialog.Panel>
              </DrawerTransition>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DrawerMultiple
