import type { ProcessIdentifierMapper } from '../Overview/types'
import type { CopyPasteEvent, ProcessStep } from './types'

export const getCopyPasteDescriptions = (
  stepCopies: CopyPasteEvent[],
  stepPastes: CopyPasteEvent[],
) => {
  return {
    copyDescriptionText: getItemCountText(stepCopies, 'target_window_name'),
    pasteDescriptionText: getItemCountText(stepPastes, 'source_window_name'),
  }
}

export const getProcessDescriptions = (
  processSteps: ProcessStep[],
  processMapper: ProcessIdentifierMapper | undefined,
) => {
  if (!processMapper) return null

  const processNameOccurances = generateItemCountMapper(processSteps, 'process_name')
  const processeNames = Object.entries(processNameOccurances)
    .filter(([process_name]) => process_name in processMapper)
    .map(
      ([process_name, count]) => `${processMapper[process_name].name ?? process_name} (x${count})`,
    )

  return processeNames.length > 0 ? processeNames.join(', ') : null
}

const getItemCountText = (dataArray: any[], dataKey: string) => {
  const itemCountMapper = generateItemCountMapper(dataArray, dataKey)
  const itemCountArray = Object.entries(itemCountMapper).map(([key, val]) => `${key} (x${val})`)
  return itemCountArray.length > 0 ? itemCountArray.join(', ') : null
}

const generateItemCountMapper = (dataArray: any[], dataKey: string) => {
  const occuranceMapper = {} as { [key: string]: number }
  dataArray.forEach((p) => {
    if (p[dataKey] in occuranceMapper) occuranceMapper[p[dataKey]] += 1
    else occuranceMapper[p[dataKey]] = 1
  })
  return occuranceMapper
}
