import { useEnhancedNavigate } from '../../../common/hooks'
import type { Tag } from '../../../common/types/common'
import { compressAndEncodeJSON } from '../../../common/utils/jsonCompression'
import ActivitiesByWindow from '../ActivitiesByWindow'

const Activities = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <ActivitiesByWindow
      onNavigate={(tags: Tag[]) => navigate(`../${compressAndEncodeJSON(tags)}`)}
      queryOptions={{ tagsOfInterestSource: 'application' }}
      isFirstLayer
    />
  )
}

export default Activities
