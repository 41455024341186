import clsx from 'clsx'
import { ReactNode } from 'react'

const variantStyles: { [key in Variant]: string } = {
  primary: 'border-gray-200 bg-gray-100 px-2 py-1.5 text-xs font-semibold text-gray-800',
}

type Variant = 'primary'

interface Props {
  children: ReactNode

  variant?: Variant
  className?: string
}

const KBD = ({ children, variant = 'primary', className }: Props) => {
  return (
    <kbd className={clsx('appearance-none rounded-md border ', variantStyles[variant], className)}>
      {children}
    </kbd>
  )
}

export default KBD
