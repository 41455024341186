import { useEffect, useRef, useState } from 'react'

import {
  useGetTransactionGroupQuery,
  useGetTransactionRelatedSystemGroupQuery,
  useGetTransactionStepGroupQuery,
} from '../../../../app/apiSlice'
import { ContentDisplay } from '../../../../common/components'
import { Select, Text } from '../../../../common/designs'
import {
  useDashboard,
  useEnhancedNavigate,
  useLocalStorage,
  useQueryFilters,
} from '../../../../common/hooks'
import { ProcessFiltersStorageContentEnum, StorageNameEnum } from '../../../../common/types/storage'
import {
  GroupByDimensionTypeEnum,
  GroupByFieldTypeEnum,
  MetricNamesEnum,
  MetricTypeEnum,
} from '../../types'
import type {
  AnalysisChartConfigurationData,
  GroupByField,
  TransactionGroupResult,
} from '../../types'
import AnalysisChart from './AnalysisChart'
import AnalysisFilterPopupModal from './AnalysisFilterPopupModal'
import type { ModalContent } from './AnalysisFilterPopupModal'
import { getFilterValueForChartClick, setUpChartDataAndMetric } from './chartDataHelpers'
import {
  defaultGroupByDimensionsExtraDataMapper,
  metricNameExtraDataMapper,
  metricNameExtraDataMapperPlatformViewer,
} from './constants'

interface Props {
  groupByField: GroupByFieldTypeEnum
  groupFilterKey: string
  label: string
  isIndexPage?: boolean
}

export const ConstantDimensionAnalysis = ({
  groupByField,
  groupFilterKey,
  label,
  isIndexPage = false,
}: Props) => {
  const { processId, processFilters: filters } = useQueryFilters()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { teams, user } = useDashboard()
  const storage = useLocalStorage()

  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const [chartConfiguration, setChartConfiguration] =
    useState<AnalysisChartConfigurationData | null>(null)
  const [selectedMetric, setSelectedMetric] = useState<MetricNamesEnum>(
    user.isPlatformViewer ? MetricNamesEnum.AVG_THROUGHPUT_TIME : MetricNamesEnum.ACTIVE_WORK,
  )
  const teamNameToIdMapper = useRef<{ [key: string]: string }>({})
  const teamNamesToIdArrayMapper = useRef<{ [key: string]: string[] }>({})

  const groupByFieldKey = groupByField as keyof typeof defaultGroupByDimensionsExtraDataMapper
  const metricOptionsDataMapper = user.isPlatformViewer
    ? metricNameExtraDataMapperPlatformViewer
    : metricNameExtraDataMapper

  // Chart data fetching related logic
  const skipQuery = !groupByField || !selectedMetric
  let shouldUseStepQuery = false
  let shouldUseRelatedSystemQuery = false
  let gropByData = [] as GroupByField[]
  let metricToUse = MetricNamesEnum.ACTIVE_WORK
  if (!skipQuery) {
    metricToUse = selectedMetric
    if (defaultGroupByDimensionsExtraDataMapper[groupByFieldKey].is_tag_dimension) {
      gropByData = [{ field_type: GroupByFieldTypeEnum.TAG_NAME, tag_name: groupByField }]
    } else {
      gropByData = [{ field_type: groupByField }]
    }

    const groupByType =
      defaultGroupByDimensionsExtraDataMapper[groupByFieldKey]?.group_by_dimension_type ||
      GroupByDimensionTypeEnum.TRANSACTION_SPECIFIC
    const metricMapperKey = selectedMetric as keyof typeof metricOptionsDataMapper
    const metricType =
      metricOptionsDataMapper[metricMapperKey]?.metric_type || MetricTypeEnum.TRANSACTION_SPECIFIC

    shouldUseStepQuery =
      groupByType === GroupByDimensionTypeEnum.STEP_SPECIFIC &&
      metricType === MetricTypeEnum.ACTIVE_WORK_SPECIFIC

    shouldUseRelatedSystemQuery =
      groupByType === GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC &&
      metricType === MetricTypeEnum.RELATED_SYSTEM_SPECIFIC
  }
  const { data: relatedSystemRequestData } = useGetTransactionRelatedSystemGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || !shouldUseRelatedSystemQuery },
  )
  const { data: stepRequestData } = useGetTransactionStepGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || !shouldUseStepQuery },
  )
  const { data: transactionRequestData } = useGetTransactionGroupQuery(
    { processId, filters, group_by: gropByData, metrics: [metricToUse] },
    { skip: skipQuery || shouldUseStepQuery || shouldUseRelatedSystemQuery },
  )

  // Server data to chart data logic
  useEffect(() => {
    setChartData(transactionRequestData)
  }, [transactionRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setChartData(stepRequestData)
  }, [stepRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setChartData(relatedSystemRequestData)
  }, [relatedSystemRequestData]) // eslint-disable-line react-hooks/exhaustive-deps

  const setChartData = (activeData: TransactionGroupResult | undefined) => {
    if (!activeData || !selectedMetric) return

    const {
      chartData,
      unit,
      teamNameToIdMapper: newTeamNameToIdMapper,
      teamNamesToIdArrayMapper: newTeamNamesToIdArrayMapper,
    } = setUpChartDataAndMetric(activeData[selectedMetric], selectedMetric, groupByField, teams)
    teamNameToIdMapper.current = newTeamNameToIdMapper
    teamNamesToIdArrayMapper.current = newTeamNamesToIdArrayMapper

    setChartConfiguration({
      label,
      chartData,
      unit,
      onBarClick: async (label, i) => {
        const chartDataPoint = chartData[i]
        const filterValue = getFilterValueForChartClick(
          label,
          groupByField,
          teamNameToIdMapper.current,
          teamNamesToIdArrayMapper.current,
        )

        setModalContent({
          label: chartDataPoint.label,
          value: chartDataPoint.value,
          unit,
          filterValue,
        })
      },
    })
  }

  const onFilter = async () => {
    if (!modalContent) return

    if (groupByField === GroupByFieldTypeEnum.TEAM_ID) {
      await storage.updateNestedItem(
        StorageNameEnum.PROCESS_FILTERS,
        ProcessFiltersStorageContentEnum.PROCESS_METRICS,
        { team_ids: [modalContent.filterValue] },
      )
    } else {
      await storage.updateNestedItem(
        StorageNameEnum.PROCESS_FILTERS,
        ProcessFiltersStorageContentEnum.PROCESS_PROPERTIES,
        { [groupFilterKey]: [modalContent.filterValue] },
      )
    }

    onCloseModal()
  }

  const onFilterAndNavigate = () => {
    onFilter()
    navigate(`${isIndexPage ? '../' : '../../'}variants`)
    onCloseModal()
  }

  const onCloseModal = () => setModalContent(null)

  let ignoredMetricType: MetricTypeEnum | null = null
  if (groupByField) {
    const extraData = defaultGroupByDimensionsExtraDataMapper[groupByFieldKey]
    ignoredMetricType =
      extraData.group_by_dimension_type === GroupByDimensionTypeEnum.RELATED_SYSTEM_SPECIFIC
        ? MetricTypeEnum.ACTIVE_WORK_SPECIFIC
        : MetricTypeEnum.RELATED_SYSTEM_SPECIFIC
  }

  const metricOptions = [
    ...Object.entries(metricOptionsDataMapper)
      .filter(([_, { metric_type }]) => metric_type !== ignoredMetricType)
      .map(([value, { metric_name }]) => ({
        label: metric_name,
        value,
      })),
  ]

  return (
    <>
      <div className='mb-3 flex flex-col gap-2'>
        <ContentDisplay className='px-5 py-4'>
          <>
            <div className='flex gap-6'>
              <div className=' w-96'>
                <Select
                  options={metricOptions}
                  value={selectedMetric}
                  onChange={(val: string) => setSelectedMetric(val as MetricNamesEnum)}
                  label='Metric'
                />
              </div>
            </div>
            <Text className='mt-2' size='xs'>
              The analysis view shows only a maximum of 2000 results
            </Text>
          </>
        </ContentDisplay>
        {chartConfiguration ? (
          <AnalysisChart configurations={chartConfiguration} isLoading={false} />
        ) : null}
      </div>

      <AnalysisFilterPopupModal
        content={modalContent}
        onClose={onCloseModal}
        onFilter={onFilter}
        onFilterAndNavigate={onFilterAndNavigate}
      />
    </>
  )
}

export default ConstantDimensionAnalysis
