import { toast } from 'react-toastify'

import { AZ_STORAGE_ACCOUNT_URL_DASHBOARD } from '../../app/configs'

export const uploadFile = async (
  file: File,
  rootContainer: string,
  companyId: string,
  sasToken: string,
): Promise<void> => {
  const { BlobServiceClient } = await import('@azure/storage-blob')
  // Get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobServiceClient = new BlobServiceClient(
    `${AZ_STORAGE_ACCOUNT_URL_DASHBOARD}/${companyId}?${sasToken}`,
  )

  // Get Container
  const containerClient = blobServiceClient.getContainerClient(rootContainer)

  // Upload file
  if (file) {
    // Create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(file.name)

    // Set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } }

    try {
      // Upload file to Azure
      await blobClient.uploadData(file, options)
    } catch (err) {
      toast.error('Unable to upload the attachment')
    }
  }
}
