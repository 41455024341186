import { PlayCircleIcon } from '@heroicons/react/24/outline'
import { Handle, Position } from 'reactflow'
import type { NodeProps } from 'reactflow'

import { Text } from '../../../../common/designs'
import type { ServerStepFlowNode } from './types'

const StartNode = ({ data }: NodeProps<ServerStepFlowNode>) => {
  const { bottom_ports: bottomPorts } = data

  const bottomSeparationSize = 80 / (bottomPorts.length - 1)

  return (
    <>
      <div className='flex w-[500px] items-center justify-center gap-2 rounded-md bg-blue-600 p-2'>
        <PlayCircleIcon className='size-10 text-white' />

        <Text size='2xl' weight={500} color='white'>
          Start
        </Text>
      </div>

      {bottomPorts.map(({ id, type }, i) => {
        const direction = i % 2 === 0 ? -1 : 1
        const shift = (bottomSeparationSize * i) / 2
        return (
          <Handle
            type={type}
            position={Position.Bottom}
            id={id}
            key={id}
            className='bg-blue-700'
            style={{ left: `${50 + direction * shift}%`, width: '13px', height: '13px' }}
          />
        )
      })}
    </>
  )
}

export default StartNode
