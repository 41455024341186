import clsx from 'clsx'

import { useGetCaseCategoriesQuery } from '../../../../../app/apiSlice'
import { basicColorMap } from '../../../../../app/theme'
import { Text } from '../../../../../common/designs'

interface Props {
  id: number
}

const CategoryBox = ({ id }: Props) => {
  const { data: caseCategories = [] } = useGetCaseCategoriesQuery()

  const category = caseCategories.find((c) => c.id === id)
  const color_index = category?.color_index ?? 0

  return (
    <div className={clsx('max-w-[200px] rounded-full px-1.5 py-0.75', basicColorMap[color_index])}>
      <Text size='xs' weight={500}>
        {category?.name}
      </Text>
    </div>
  )
}

export default CategoryBox
