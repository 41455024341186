import { ChartOptions } from 'chart.js'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { ChartData } from '../../common/types/common'
import {
  PerformanceReadingTypeTotalLoadMapper,
  performanceReadingTypeColors,
  performanceReadingTypeMapper,
  usageDistributionsAsSum,
} from './constants'
import type {
  PerformanceReadingCpu,
  PerformanceReadingMemory,
  PerformanceReadingTypeEnum,
} from './types'

interface Props {
  unit: PerformanceReadingTypeEnum
  data: PerformanceReadingMemory[] | PerformanceReadingCpu[] | undefined
  isLoading: boolean
}

const UsageDistribution = ({ unit, data, isLoading }: Props) => {
  const [chartData, setChartData] = useState<null | ChartData>(null)

  useEffect(() => {
    if (!data) return

    const distributions = _.cloneDeep(usageDistributionsAsSum[unit])
    const totalLoadKey = PerformanceReadingTypeTotalLoadMapper[unit]
    const colors = performanceReadingTypeColors[unit]

    // Aggregate data into categories
    data.forEach((record) => {
      const category = record.reading
      // @ts-ignore
      distributions[category] += record.value
    })

    // Calculate the total load excluding the total system load
    let specificProcessSum = 0
    Object.keys(distributions).forEach((key) => {
      if (key !== totalLoadKey) {
        specificProcessSum += distributions[key as keyof typeof distributions]
      }
    })

    // Adjust total_system_cpu_load to represent the remaining load
    // @ts-ignore
    distributions[totalLoadKey] -= specificProcessSum

    // Calculate percentage distribution for each category
    // @ts-ignore
    const totalCpuLoad = distributions[totalLoadKey] + specificProcessSum
    const percentages = Object.keys(distributions).map((category) => {
      // @ts-ignore
      return (distributions[category] / totalCpuLoad) * 100
    })

    // Set up chart data for stacked horizontal bar chart
    setChartData({
      labels: [`${performanceReadingTypeMapper[unit]} Usage`],
      datasets: Object.keys(distributions).map((category, index) => ({
        label: category,
        data: [percentages[index]],
        backgroundColor: colors[category as keyof typeof colors],
      })),
    })
  }, [unit, data])

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function (value: string | number) {
            return `${value}%`
          },
        },
        max: 100,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || ''
            if (label) label += ': '
            label += `${context.parsed.x.toFixed(2)}%`
            return label
          },
        },
      },
    },
  }

  if (!chartData || isLoading) return null

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <h2>Total {performanceReadingTypeMapper[unit]} Usage Distribution by Process</h2>
      <Bar data={chartData} options={options} height='60px' />
    </div>
  )
}

export default UsageDistribution
