import clsx from 'clsx'
import { ReactNode } from 'react'

import { Tooltip } from '../../../common/designs'

interface Props {
  children: ReactNode
  isActive: boolean
  variantSteps?: string[]
  variantIndex: number
  selectedVariantsForUpdate: number[]
  setSelectedVariantsForUpdate: (variants: number[]) => void
}

const VariantSelectBox = (props: Props) => {
  const {
    children,
    isActive,
    variantSteps,
    variantIndex,
    selectedVariantsForUpdate,
    setSelectedVariantsForUpdate,
  } = props

  const onVariantToggle = () => {
    const index = selectedVariantsForUpdate.findIndex((i) => i === variantIndex)
    const selectedVariantsForUpdateCopy = [...selectedVariantsForUpdate]
    if (index === -1) selectedVariantsForUpdateCopy.push(variantIndex)
    else selectedVariantsForUpdateCopy.splice(index, 1)
    selectedVariantsForUpdateCopy.sort((a, b) => a - b)
    setSelectedVariantsForUpdate(selectedVariantsForUpdateCopy)
  }

  const rootClass = clsx(
    'mb-1 w-full cursor-pointer rounded-md bg-white p-1 pl-3',
    isActive
      ? 'border-2 border-l-4 border-primary-600 bg-primary-100 hover:bg-primary-200'
      : 'border border-gray-300 hover:bg-gray-100',
  )

  return (
    <Tooltip text={variantSteps?.join(' --> ') || ''} placement='b' size='3xl' className='w-full'>
      <div className={rootClass} onClick={onVariantToggle}>
        {children}
      </div>
    </Tooltip>
  )
}

export default VariantSelectBox
