import { useGetUserManagementListQuery, useUpdateUserRoleMutation } from '../../app/apiSlice'
import { Button, InputField, Modal, Select } from '../../common/designs'
import type { UserManagementUser } from './types'

interface Props {
  activeUser: UserManagementUser
  onClose: () => void
  onRoleChange: (e: any) => void
}

const EditUserModal = ({ activeUser, onClose, onRoleChange }: Props) => {
  const { data } = useGetUserManagementListQuery()
  const [updateUserRole] = useUpdateUserRoleMutation()

  const onSave = () => {
    if (!activeUser) return

    updateUserRole({
      body: { [activeUser?.userId]: activeUser?.role },
    })
      .unwrap()
      .then(() => onClose())
  }

  return (
    <>
      <Modal label='Edit existing user' open={Boolean(activeUser)} onClose={onClose}>
        <div className='space-y-4'>
          <Select
            label='Role'
            value={activeUser.role}
            options={
              data?.roleOptions
                ? Object.entries(data.roleOptions).map(([id, name]) => ({
                    label: name,
                    value: parseInt(id),
                  }))
                : []
            }
            onChange={onRoleChange}
          />

          <InputField
            label='Email address'
            value={`${activeUser.username} - ${activeUser.email}`}
            disabled
          />

          <div className='mt-2 flex justify-end gap-4'>
            <Button variant='white' onClick={onClose}>
              Cancel
            </Button>

            <Button onClick={onSave}>Save</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditUserModal
