import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Dispatch, Fragment, SetStateAction } from 'react'

const sizeStyles: { [key in Size]: string } = {
  xs: 'max-h-7.5 pl-2 py-1',
  s: 'max-h-8.5 pl-3 py-1.5',
  m: 'max-h-9.5 pl-3 py-2',
}

export interface SelectMultipleOption {
  label: string
  value: string | number

  prefix?: JSX.Element
}

type Size = 'xs' | 's' | 'm'
interface Props {
  options: SelectMultipleOption[]
  value: Array<string | number | null>
  onChange:
    | ((val: Array<string | number>) => void)
    | ((val: Array<string>) => void)
    | ((val: Array<number>) => void)
    | Dispatch<SetStateAction<Array<string | number>>>
    | Dispatch<SetStateAction<Array<string>>>
    | Dispatch<SetStateAction<Array<number>>>
  size?: Size
  className?: string
  label?: string
  description?: string
  error?: string | undefined
  disabled?: boolean
  rounded?: boolean
  optional?: boolean
  selectedConfiguration?: { max: number; label: string }
}

const SelectMultiple = (props: Props) => {
  const {
    options,
    value,
    onChange,

    size = 'm',
    className,
    label,
    description,
    error,
    disabled,
    rounded,
    optional,
    selectedConfiguration = { max: 3, label: 'Selected' },
  } = props

  return (
    <Listbox value={value} onChange={onChange as any} disabled={Boolean(disabled)} multiple>
      {({ open }) => (
        <div className={className}>
          <div
            className={clsx(
              (label || optional) && 'flex',
              !label && optional ? 'flex-row-reverse' : 'justify-between',
            )}
          >
            {label && (
              <Listbox.Label className='block text-sm font-medium text-gray-700'>
                {label}
              </Listbox.Label>
            )}

            {optional && <span className='text-sm text-gray-400'>Optional</span>}
          </div>

          <div className={clsx('relative cursor-pointer', (label || optional) && 'mt-1')}>
            <Listbox.Button
              className={clsx(
                'relative w-full border bg-white pl-3 pr-10 text-left text-sm focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
                rounded ? 'rounded-full' : 'rounded-md',
                error
                  ? 'border-red-300  focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                sizeStyles[size],
              )}
            >
              <div className={clsx('flex', options?.some((n) => n.prefix) ? 'gap-3' : 'gap-1')}>
                {value.length === 0 ? (
                  <div className='h-5' />
                ) : value.length > selectedConfiguration.max ? (
                  <span className='block truncate'>
                    {`${selectedConfiguration.label} (${value.length})`}
                  </span>
                ) : (
                  value?.map((v, i) => {
                    const val = options.find((o) => o.value === v)

                    if (!val) return null

                    return val.prefix ? (
                      <div className='flex' key={`${val.value}-${val.label}`}>
                        <div className='mr-3 flex items-center justify-center'>{val.prefix}</div>
                        <span className={clsx(error && 'text-red-700')}>{val.label}</span>
                      </div>
                    ) : (
                      <span className='block truncate' key={`${val.value}-${val.label}`}>
                        {val.label}
                        {i + 1 === value.length ? '' : ','}
                      </span>
                    )
                  })
                )}
              </div>

              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon
                  className={clsx('size-5', error ? 'text-red-400' : 'text-gray-400')}
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-50 mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none'>
                {options?.map((o) => (
                  <Listbox.Option
                    key={o.value}
                    className={({ active }) =>
                      clsx(
                        'relative select-none py-2 pl-3 pr-9',
                        active ? 'bg-primary-600 text-white' : 'text-gray-900',
                      )
                    }
                    value={o.value}
                  >
                    {(state) => {
                      const { active } = state

                      const selected = value.some((v) => v === o.value)

                      return (
                        <div className='flex items-center'>
                          {o.prefix && (
                            <div className='mr-3 flex items-center justify-center'>{o.prefix}</div>
                          )}

                          <span
                            className={clsx(
                              'block truncate',
                              selected ? 'font-semibold' : 'font-normal',
                            )}
                          >
                            {o.label}
                          </span>

                          {selected && (
                            <span
                              className={clsx(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-primary-600',
                              )}
                            >
                              <CheckIcon className='size-5' aria-hidden='true' />
                            </span>
                          )}
                        </div>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>

          {(description || error) && (
            <p className={clsx(error ? 'text-red-600' : 'text-gray-500', 'mt-2 text-sm')}>
              {error ? error : description}
            </p>
          )}
        </div>
      )}
    </Listbox>
  )
}

export default SelectMultiple
