import { useMemo } from 'react'

import { useGetDataCollectionConfigsQuery } from '../../app/apiSlice'
import type { SelectOption } from '../../common/designs'
import {
  APP_TAG_KEY,
  WINDOW_TAG_KEY,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/constants'

export type Item = object

const useGetIdentifierOptions = () => {
  const { data, isLoading } = useGetDataCollectionConfigsQuery()

  const identifierOptions = useMemo(() => {
    const dimensionOptions = [
      {
        label: 'No Value Selected',
        value: '',
      },
    ] as SelectOption[]
    ;(data?.rules ?? []).forEach((rule) => {
      dimensionOptions.push(
        ...rule.extract_identifiers.map(({ identifier_name }) => ({
          label: `${identifier_name} (${rule.dashboard_context?.app_name})`,
          value: identifier_name,
        })),
      )

      dimensionOptions.push(
        ...rule.tags
          .filter((tag) => ![APP_TAG_KEY, WINDOW_TAG_KEY].includes(tag.key))
          .map((tag) => ({
            label: `${tag.value} (${rule.dashboard_context?.app_name})`,
            value: tag.value,
          })),
      )
    })
    return dimensionOptions
  }, [data])

  return {
    data: identifierOptions,
    isLoading,
  }
}

export default useGetIdentifierOptions
