import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { GlobalSearchParamEnum, SubSearchParamEnum } from '../types/searchParams'

type Param = GlobalSearchParamEnum | SubSearchParamEnum
type Value = string | null

const useEnhancedSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParam = useCallback(
    async (param: Param, value: string) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.set(param, value)
      await setSearchParams(updatedSearchParams.toString())
    },
    [searchParams, setSearchParams],
  )

  const deleteSearchParam = useCallback(
    async (param: Param) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      updatedSearchParams.delete(param)
      await setSearchParams(updatedSearchParams.toString())
    },
    [searchParams, setSearchParams],
  )

  const bulkUpdateSearchParams = useCallback(
    async (paramValuePairs: Array<[string, Value]>) => {
      const updatedSearchParams = new URLSearchParams(searchParams.toString())
      paramValuePairs.forEach(([param, value]) => {
        if (value) updatedSearchParams.set(param, value)
        else updatedSearchParams.delete(param)
      })
      await setSearchParams(updatedSearchParams.toString())
    },
    [searchParams, setSearchParams],
  )

  return { updateSearchParam, bulkUpdateSearchParams, deleteSearchParam }
}

export default useEnhancedSearchParams
