import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { NavigateOptions } from 'react-router-dom'

interface EnhancedNavigateOptions {
  search?: string
  replace?: boolean
  state?: unknown
  hash?: string
}
type CustomNavigateOptions = NavigateOptions & EnhancedNavigateOptions

const combineSearchStrings = (search: string, optionsSearch: string | undefined) => {
  if (search.length === 0) {
    return optionsSearch ?? search
  } else {
    return `${search}&${optionsSearch}`
  }
}

const useEnhancedNavigate = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // Search variable is seperated to prevent unneccessary re-renders of the enhancedNavigate function.
  const search = searchParams?.toString() ?? ''

  const enhancedNavigate = useCallback(
    (pathname: string, options?: EnhancedNavigateOptions | null) => {
      const customSearch = options === null ? '' : combineSearchStrings(search, options?.search)
      const customOptions: CustomNavigateOptions = { ...options, search: customSearch }

      return navigate({ pathname, ...customOptions })
    },
    [navigate, search],
  )

  const generateEnhancedPath = useCallback(
    (pathname: string) => {
      return {
        pathname,
        search: searchParams.toString(),
      }
    },
    [searchParams],
  )

  return { enhancedNavigate, generateEnhancedPath }
}

export default useEnhancedNavigate
