import { formatDistanceToNow } from 'date-fns'
import { memo } from 'react'

import { Avatar, Text } from '../../../../../common/designs'
import { useAppSelector } from '../../../../../common/hooks'
import { createInitials } from '../../../../../common/utils/stringFormatting'
import { selectUsers } from '../../../casesSlice'

interface Props {
  userId: number
  activityMessage: string
  dateTime: Date
}

const ActivityItem = ({ userId, activityMessage, dateTime }: Props) => {
  const users = useAppSelector(selectUsers)

  const user = users.find((u) => u.id === userId)

  if (!user) return null

  return (
    <div className='flex items-baseline py-2'>
      <div className='mr-1.5'>
        <Avatar variant={user.color_index} rounded size='s'>
          {createInitials(user.username)}
        </Avatar>
      </div>

      <div className='ml-1 mr-8'>
        <Text size='s'>
          <b>{user?.username}</b> - {activityMessage}
        </Text>
      </div>

      <Text size='xs' color='lightGray'>
        {formatDistanceToNow(new Date(dateTime))} ago
      </Text>
    </div>
  )
}

export default memo(ActivityItem)
