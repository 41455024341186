import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

export interface CheckboxesOption {
  label: string
  value: string | number

  description?: string
  prefix?: JSX.Element
  disabled?: boolean
}

interface Props {
  options: CheckboxesOption[]
  values: Array<string | number>
  onChange:
    | ((val: Array<string | number>) => void)
    | ((val: Array<string>) => void)
    | ((val: Array<number>) => void)
    | Dispatch<SetStateAction<Array<string | number>>>
    | Dispatch<SetStateAction<Array<string>>>
    | Dispatch<SetStateAction<Array<number>>>

  disabled?: boolean
  label?: string
  className?: string
}

const Checkboxes = ({ options, values, onChange, label, disabled, className }: Props) => {
  const onClick = (target: EventTarget & HTMLInputElement, option: CheckboxesOption) => {
    // target.checked works kinds of 'backwards' meaning target.checked = true being if its unchecked.
    if (target.checked) {
      onChange([...values, option.value] as any)
    } else {
      onChange(values.filter((val) => val !== option.value) as any)
    }
  }

  return (
    <fieldset className={clsx('space-y-2', className)} disabled={disabled}>
      {label && <legend className='text-lg font-medium text-gray-900'>{label}</legend>}

      {options.map((option) => {
        const ariaDescription = `${option.value}-description`
        // Disabled prop on parent level can override option disabled property.
        const disabledOption =
          typeof disabled === 'undefined' || disabled === false
            ? Boolean(option.disabled)
            : disabled

        const key = `${String(label)}-${option.label}-${option.value}`

        return (
          <div className='relative flex items-start' key={key}>
            <div className='flex h-5 items-center'>
              <input
                id={key}
                aria-describedby={ariaDescription}
                name={key}
                type='checkbox'
                checked={values.some((val) => val === option.value)}
                onChange={({ target }) => onClick(target, option)}
                disabled={disabledOption}
                className={clsx(
                  'cursor-pointer rounded border-gray-300 text-primary-600 hover:bg-primary-50 focus:ring-primary-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
                  'size-4',
                )}
              />
            </div>

            <div className='ml-3 text-sm'>
              <label
                htmlFor={key}
                className={clsx(
                  'flex cursor-pointer items-center gap-2 font-medium',
                  disabledOption ? 'text-gray-500' : 'text-gray-700',
                )}
              >
                {option?.prefix}
                {option.label}
              </label>

              {option.description && (
                <p
                  id={ariaDescription}
                  className={clsx(disabledOption ? 'text-gray-400' : 'text-gray-500')}
                >
                  {option.description}
                </p>
              )}
            </div>
          </div>
        )
      })}
    </fieldset>
  )
}

export default Checkboxes
