import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type {
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Button, InputField, Text } from '../../designs'
import { ContentDisplay } from '../displays'
import DataCollectionRules from './DataCollectionRules'
import DataCollectionTests from './DataCollectionTests'
import type { AppTestCaseGroup, TestCaseWithTestResult } from './types'

interface WindowDisplayProps {
  windowName: string
  testCases: TestCaseWithTestResult[]
  rulesForTestCases: RuleIn[]
  onAddTestCase: (formValues: DataCollectionTestCaseFormData, windowName: string) => Promise<void>
  onUpdateTestCase: (
    data: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (testCase: ConfigTestCase) => Promise<void>
  onAddRuleIn: (formValues: DataCollectionRuleFormData, windowName: string) => Promise<void>
  onUpdateRuleIn: (formData: DataCollectionRuleFormData, originalRule: RuleIn) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

const WindowDisplay = ({
  windowName,
  testCases,
  rulesForTestCases,
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: WindowDisplayProps) => {
  return (
    <ContentDisplay className='p-4'>
      <Text size='l' weight={500}>
        {windowName}
      </Text>

      <div className='w-full md:grid md:grid-cols-2 md:gap-4'>
        <DataCollectionTests
          testCases={testCases}
          onAddTestCase={(formValues: DataCollectionTestCaseFormData) =>
            onAddTestCase(formValues, windowName)
          }
          onUpdateTestCase={onUpdateTestCase}
          onDeleteTestCase={onDeleteTestCase}
        />
        <DataCollectionRules
          rulesForTestCases={rulesForTestCases}
          onAddRuleIn={(formValues: DataCollectionRuleFormData) =>
            onAddRuleIn(formValues, windowName)
          }
          onUpdateRuleIn={onUpdateRuleIn}
          onUpdateRuleInCode={onUpdateRuleInCode}
          onDeleteRuleIn={onDeleteRuleIn}
        />
      </div>
    </ContentDisplay>
  )
}

interface Props {
  appTestCasesGroup: AppTestCaseGroup
  onAddNewWindow: (windowName: string) => void
  onAddTestCase: (formValues: DataCollectionTestCaseFormData, windowName: string) => Promise<void>
  onUpdateTestCase: (
    data: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (testCase: ConfigTestCase) => Promise<void>
  onAddRuleIn: (formValues: DataCollectionRuleFormData, windowName: string) => Promise<void>
  onUpdateRuleIn: (formData: DataCollectionRuleFormData, originalRule: RuleIn) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

interface AddNewWindowFormData {
  windowName: string
}

const addNewWindowFormSchema = yup
  .object({
    windowName: yup
      .string()
      .max(128, 'Window Name cannot be more than 128 characters long')
      .required('Window Name is a required field'),
  })
  .required()

const WindowLevelTestAndRules = ({
  appTestCasesGroup,
  onAddNewWindow,
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: Props) => {
  const [isAddNewWindowOpen, setIsAddNewWindowOpen] = useState<boolean>(false)
  const [isAddingNewWindow, setIsAddingNewWindow] = useState<boolean>(false)

  const {
    handleSubmit: handleAddNewWindowSubmit,
    formState: { errors: addNewWindowFormErrors },
    control: addNewWindowFormControl,
    reset: resetAddNewWindowForm,
  } = useForm<AddNewWindowFormData>({
    resolver: yupResolver(addNewWindowFormSchema) as Resolver<AddNewWindowFormData>,
  })

  const onAddNewWindowFormSubmit = async (formData: AddNewWindowFormData) => {
    setIsAddingNewWindow(true)
    onAddNewWindow(formData.windowName)
    setIsAddingNewWindow(false)
    resetAddNewWindowForm()
    setIsAddNewWindowOpen(false)
  }

  return (
    <div>
      <div className={isAddNewWindowOpen ? 'my-4 rounded-lg border border-gray-600 p-4' : 'my-4'}>
        <Button
          className='mb-3'
          iconStart={isAddNewWindowOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setIsAddNewWindowOpen((val) => !val)}
        >
          {isAddNewWindowOpen ? 'Hide Form' : 'Add New Window'}
        </Button>
        {isAddNewWindowOpen && (
          <form
            className='mt-2 flex items-end gap-2'
            autoComplete='off'
            onSubmit={handleAddNewWindowSubmit(onAddNewWindowFormSubmit)}
          >
            <Controller
              name='windowName'
              control={addNewWindowFormControl}
              render={({ field }) => (
                <InputField
                  label='Window Name'
                  error={addNewWindowFormErrors.windowName?.message}
                  {...field}
                  autoFocus
                />
              )}
            />
            <Button type='submit' disabled={isAddingNewWindow}>
              Add Window
            </Button>
          </form>
        )}
      </div>

      <div className='space-y-8'>
        {appTestCasesGroup.windowLevelData.map(({ windowName, testCases, rulesForTestCases }) => (
          <WindowDisplay
            key={windowName}
            windowName={windowName}
            testCases={testCases}
            rulesForTestCases={rulesForTestCases}
            onAddTestCase={onAddTestCase}
            onUpdateTestCase={onUpdateTestCase}
            onDeleteTestCase={onDeleteTestCase}
            onAddRuleIn={onAddRuleIn}
            onUpdateRuleInCode={onUpdateRuleInCode}
            onUpdateRuleIn={onUpdateRuleIn}
            onDeleteRuleIn={onDeleteRuleIn}
          />
        ))}
      </div>
    </div>
  )
}

export default WindowLevelTestAndRules
