import { isValidElement } from 'react'
import type { Data as CsvData } from 'react-csv/lib/core'

export const exportTableDataToCSV = (
  head: string[],
  body: Array<(string | number | React.ReactNode)[]>,
) => {
  const bodyWithOnlyStrings = body.map((row) =>
    row.map((cell) => (isValidElement(cell) ? '' : cell)),
  )
  return [head, ...bodyWithOnlyStrings] as CsvData
}
