import { useDashboard } from '../../../common/hooks'
import type { ProcessGeneralInfo } from '../types'
import TrackedProcessName from './TrackedProcessName'
import TrackedProcessStat from './TrackedProcessStat'

interface Props {
  process: ProcessGeneralInfo
  totalAppUsageTrendMapper: { [key: string]: number }
}

const TrackedProcessCard = ({ process, totalAppUsageTrendMapper }: Props) => {
  const {
    company: { isPmCustomer },
  } = useDashboard()

  return isPmCustomer ? (
    <TrackedProcessName process={process} />
  ) : (
    <TrackedProcessStat process={process} totalAppUsageTrendMapper={totalAppUsageTrendMapper} />
  )
}

export default TrackedProcessCard
