export enum ActiveDateButtonEnum {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
  NONE = 'NONE',
}

export enum IntensityLevelEnum {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum LaneTypeEnum {
  DEFAULT = 'DEFAULT',
  NORMAL = 'NORMAL',
  COMPLETE = 'COMPLETE',
  DISCARD = 'DISCARD',
}
