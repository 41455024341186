import clsx from 'clsx'

const colorStyles: { [key in Color]: string } = {
  darkGray: 'border-gray-400',
  gray: 'border-gray-300',
  lightGray: 'border-gray-200',
  lightestGray: 'border-gray-100',
}

type Color = 'darkGray' | 'gray' | 'lightGray' | 'lightestGray'

interface Props {
  color?: Color
  label?: string | undefined // In the middle of the divider line you can add a label. This increases margins as well.
  className?: string
}

const Divider = ({ color = 'gray', label, className }: Props) => {
  return (
    <div className={clsx('relative flex h-0.25 w-full items-center justify-center', className)}>
      <div className='absolute inset-0 items-center' aria-hidden='true'>
        <div className={clsx(colorStyles[color], 'w-full border-t')} />
      </div>

      {label && (
        <div className='relative flex justify-center'>
          <span className='bg-white px-2 text-sm text-gray-500'>{label}</span>
        </div>
      )}
    </div>
  )
}

export default Divider
