import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAutomaticSignInMutation } from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import { useLocalStorage } from '../../common/hooks'
import { MainPathEnum } from '../../common/types/routing'
import { StorageNameEnum } from '../../common/types/storage'

const Authentication = () => {
  const [searchParams] = useSearchParams()
  const storage = useLocalStorage()
  const navigate = useNavigate()

  const [automaticSignIn, { isLoading }] = useAutomaticSignInMutation()

  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) return

    // This information is used for redirecting after access_token expires.
    storage.setItem(StorageNameEnum.IFRAME, { isEnabled: true, token: token })

    // Check if access_token already existed in local storage indicating sign in has already been done previously.
    const storageItem = storage.getItem(StorageNameEnum.TOKEN)
    if (storageItem?.access_token) {
      navigate(`/${MainPathEnum.PROCESSES}`)
      return
    }

    // If access_token does not exist, perform a fresh automatic sign in.
    automaticSignIn({ token })
      .unwrap()
      .then((token) => {
        storage.setItem(StorageNameEnum.TOKEN, { access_token: token })
        navigate(`/${MainPathEnum.PROCESSES}`)
      })
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className='flex min-h-full'>
        <div className='flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div className='mx-auto w-full max-w-sm pb-32 lg:w-96'>
            <div>
              <img
                className='h-12 w-auto'
                alt='Process Intelligence logo'
                src='/images/pi-logo-color.png'
              />
              <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900'>
                Automatic Sign In
              </h2>
              <div className='flex justify-center'>
                {isLoading ? <InlineLoader size='2xl' /> : null}{' '}
              </div>
            </div>
          </div>
        </div>

        <div className='relative hidden w-0 flex-1 lg:block'>
          <img
            className='absolute inset-0 size-full object-cover'
            src='/images/sign-in.jpg'
            alt='Process Intelligence dashboard'
          />
        </div>
      </div>
    </>
  )
}

export default Authentication
