import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import { useCreateCaseCategoryMutation } from '../../app/apiSlice'
import type { BasicColorIndex } from '../../app/theme'
import { Button, ColorPicker, InputField, Modal } from '../../common/designs'
import { caseValidator } from '../../common/types/validators'

const formSchema = yup
  .object({
    case_category_name: caseValidator.case_category_name,
    color_index: caseValidator.color_index,
  })
  .required()

type Inputs = {
  case_category_name: string
  color_index: BasicColorIndex
}

interface Props {
  open: boolean
  onClose: () => void
}

const AddCaseCategoryModal = ({ open, onClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema) as Resolver<Inputs>,
    defaultValues: { case_category_name: '', color_index: 0 },
  })

  const [createCaseCategory, { isLoading }] = useCreateCaseCategoryMutation()

  const onCreate: SubmitHandler<Inputs> = (data: Inputs) => {
    createCaseCategory({
      body: {
        name: data.case_category_name,
        color_index: data.color_index,
      },
    })
      .unwrap()
      .then(() => onClose())
  }

  return (
    <Modal label='Add a new opportunity category' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onCreate)} className='flex flex-col gap-3'>
        <Controller
          name='case_category_name'
          control={control}
          render={({ field }) => (
            <InputField
              autoFocus
              label='Category name'
              autoComplete='off'
              error={errors.case_category_name?.message}
              {...field}
            />
          )}
        />

        <ColorPicker
          size='s'
          label='Color'
          value={watch('color_index')}
          onChange={(i) => setValue('color_index', i)}
        />

        <div className='mt-2 flex justify-end gap-3'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit' loading={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddCaseCategoryModal

