import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  useCreateCustomDimensionMutation,
  useDeleteCustomDimensionMutation,
  useUpdateCustomDimensionMutation,
} from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import { Button, InputField, Select } from '../../common/designs'
import { TagFilteringLogicEnum } from '../Processes/ProcessMining/Analysis/types'
import type { CustomDimensionData } from '../Processes/ProcessMining/Analysis/types'
import type { ProcessGeneralInfo } from '../Processes/types'
import validator from '../Processes/validator'
import useGetIdentifierOptions from './useIdentifierOptions'

interface FormData {
  dimension_name: string
  dimension_key: string
  tag_filtering_logic: TagFilteringLogicEnum
}

const formSchema = yup
  .object({
    dimension_name: validator.customDimensionName,
    dimension_key: validator.customDimensionKey,
    tag_filtering_logic: validator.customDimensionFilteringLogic,
  })
  .required()

interface Props {
  editedData?: CustomDimensionData | null
  onClose: () => void
  process: ProcessGeneralInfo
}

const dimensionTypeLabels = {
  [TagFilteringLogicEnum.LATEST_VALUE]: 'Use Latest Value',
  [TagFilteringLogicEnum.VALUE_HISTORY]: 'Check Value History',
}

const DimensionForm = ({ editedData, onClose, process }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      dimension_name: '',
      dimension_key: '',
      tag_filtering_logic: TagFilteringLogicEnum.LATEST_VALUE,
    },
  })

  const { data: dimensionOptions, isLoading: isLoadingDimensionOptions } = useGetIdentifierOptions()
  const [createCustomDimension, { isLoading: isLoadingCreate }] = useCreateCustomDimensionMutation()
  const [updateCustomDimension, { isLoading: isLoadingEdit }] = useUpdateCustomDimensionMutation()
  const [deleteCustomDimension, { isLoading: isLoadingDelete }] = useDeleteCustomDimensionMutation()

  useEffect(() => {
    const initialData = editedData
      ? {
          dimension_name: editedData.dimension_name,
          dimension_key: editedData.dimension_key,
          tag_filtering_logic: editedData.tag_filtering_logic,
        }
      : {
          dimension_name: '',
          dimension_key: '',
          tag_filtering_logic: TagFilteringLogicEnum.LATEST_VALUE,
        }
    reset(initialData)
  }, [editedData, reset])

  const onSave = (data: FormData) => {
    createCustomDimension({ processId: process.id, body: { ...data } })
      .unwrap()
      .then(() => {
        reset()
        onClose()
      })
  }

  const onEdit = (data: FormData) => {
    if (!editedData) return

    updateCustomDimension({
      processId: process.id,
      dimensionId: editedData.id,
      body: { ...data, id: editedData.id, is_tag_dimension: editedData.is_tag_dimension },
    })
      .unwrap()
      .catch((error) => {
        console.log(error)
      })
      .then(() => onClose())
  }

  const onDelete = () => {
    if (!editedData) return

    deleteCustomDimension({ processId: process.id, dimensionId: editedData.id })
      .unwrap()
      .then(() => onClose())
  }

  const onSubmitForm = (data: FormData) => {
    editedData ? onEdit(data) : onSave(data)
  }

  return (
    <>
      {isLoadingDimensionOptions ? (
        <InlineLoader />
      ) : (
        <form
          className='flex flex-col gap-3'
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <Controller
            name='dimension_name'
            control={control}
            render={({ field }) => (
              <InputField
                label='Name'
                error={errors.dimension_name?.message}
                {...field}
                autoFocus
              />
            )}
          />

          <Controller
            name='dimension_key'
            control={control}
            render={({ field }) => (
              <Select
                label='Key'
                error={errors.dimension_key?.message}
                options={dimensionOptions}
                {...field}
              />
            )}
          />

          <Controller
            name='tag_filtering_logic'
            control={control}
            render={({ field }) => (
              <Select
                label='Dimension Usage for Filtering and Analysis'
                error={errors.tag_filtering_logic?.message}
                options={Object.values(TagFilteringLogicEnum).map((value) => ({
                  value,
                  label: dimensionTypeLabels[value],
                }))}
                {...field}
              />
            )}
          />

          <div className='mt-3 flex justify-end'>
            <div className='flex gap-4'>
              <Button
                variant='white'
                onClick={onClose}
                type='button'
                disabled={isLoadingCreate || isLoadingEdit || isLoadingDelete}
              >
                Cancel
              </Button>

              {editedData && (
                <Button
                  variant='destructive'
                  onClick={onDelete}
                  disabled={isLoadingCreate || isLoadingEdit || isLoadingDelete}
                >
                  Delete
                </Button>
              )}

              <Button
                variant='primary'
                type='submit'
                disabled={isLoadingCreate || isLoadingEdit || isLoadingDelete}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default DimensionForm
