import { useCallback, useEffect, useMemo, useRef } from 'react'

import { Loader } from '../../../../common/components'
import { Breadcrumbs, Text } from '../../../../common/designs'
import { BreadcrumbOption } from '../../../../common/designs/Breadcrumbs'
import {
  useAppDispatch,
  useAppSelector,
  useCostUtils,
  useEnhancedSearchParams,
} from '../../../../common/hooks'
import { SubSearchParamEnum } from '../../../../common/types/searchParams'
import {
  selectStateVariantData,
  selectStepVariantData,
  setVariantsInsightBarItems,
  setViewDescriptionText,
} from '../../processesSlice'
import FlowChart from './FlowChart'
import {
  configureInsightBarItems,
  configureNodeTypes,
  configureViewDescription,
} from './configureVariantVisualization'
import {
  configureRowHeightForVariantView,
  serverEdgesToChartEdges,
  serverStepFlowNodesToChartNodes,
} from './dataMappings'
import { VariantViewNamesEnum, type VariantViewRoutesEnum } from './enums'
import type { ServerNodeTypes } from './types'
import { ZoomModeEnum } from './types'
import { useGetVariantData } from './useGetVariantData'
import { useNodeHighlightData } from './useNodeHighlightData'
import { useSelectedVariantsIndicies } from './useSelectedVariantsIndicies'
import { useZoomingFilters } from './useZoomingFilters'

interface Props {
  activeRoute: VariantViewRoutesEnum
}

const VariantVisualization = ({ activeRoute }: Props) => {
  const dispatch = useAppDispatch()
  const { currency, formatCost } = useCostUtils()
  const { activeZoomMode, zoomingFilters, clearZoomingFilters, setZoomingFilters } =
    useZoomingFilters()
  const { nodes, edges, visualizationLevel, isFetching } = useGetVariantData(activeRoute)
  const { isTeamDistributionOpen, isWorkTypeDistributionOpen, isAutomationInsightsOpen } =
    useNodeHighlightData()
  const stepVariantData = useAppSelector(selectStepVariantData)
  const stateVariantData = useAppSelector(selectStateVariantData)
  const { bulkUpdateSearchParams } = useEnhancedSearchParams()
  const { windowLevelVariantIndices } = useSelectedVariantsIndicies()
  const prevRoute = useRef<VariantViewRoutesEnum>(activeRoute)

  const nodeTypes = configureNodeTypes(visualizationLevel, windowLevelVariantIndices.length === 1)

  const breadcrumbs = useMemo(() => {
    if (!activeZoomMode || !zoomingFilters) return null

    const breadcrumbs = []
    if (zoomingFilters.state_name) {
      breadcrumbs.push({
        label: VariantViewNamesEnum.STATE_FLOWS,
        onClick: () => {
          clearZoomingFilters()

          bulkUpdateSearchParams([
            [SubSearchParamEnum.SELECTED_STEP, null],
            [SubSearchParamEnum.ZOOM_MODE, null],
          ])
        },
      })
      const stateBreadcrumb: BreadcrumbOption = { label: `State: ${zoomingFilters.state_name}` }
      if (zoomingFilters.step_name) {
        stateBreadcrumb.onClick = () => {
          setZoomingFilters({
            stepName: null,
            stepIndex: null,
            stateName: zoomingFilters.state_name,
          })
        }
      }
      breadcrumbs.push(stateBreadcrumb)
    }

    if (activeZoomMode === ZoomModeEnum.WINDOW && zoomingFilters.step_name) {
      if (!zoomingFilters.state_name) {
        breadcrumbs.push({
          label: VariantViewNamesEnum.STEP_FLOWS,
          onClick: () => {
            clearZoomingFilters()

            bulkUpdateSearchParams([
              [SubSearchParamEnum.SELECTED_STEP, null],
              [SubSearchParamEnum.ZOOM_MODE, null],
            ])
          },
        })
      }

      breadcrumbs.push({ label: `Task: ${zoomingFilters.step_name}` })
    }

    return breadcrumbs
  }, [
    activeZoomMode,
    zoomingFilters,
    bulkUpdateSearchParams,
    clearZoomingFilters,
    setZoomingFilters,
  ])

  useEffect(() => {
    if (prevRoute.current === activeRoute) return
    clearZoomingFilters()

    bulkUpdateSearchParams([
      [SubSearchParamEnum.SELECTED_STEP, null],
      [SubSearchParamEnum.ZOOM_MODE, null],
    ])
    prevRoute.current = activeRoute
  }, [activeRoute, clearZoomingFilters, bulkUpdateSearchParams])

  useEffect(() => {
    dispatch(setViewDescriptionText(configureViewDescription(visualizationLevel)))
  }, [dispatch, visualizationLevel])

  useEffect(() => {
    if (nodes) {
      const items = configureInsightBarItems(
        nodes,
        stepVariantData,
        stateVariantData,
        visualizationLevel,
        currency,
        formatCost,
      )
      dispatch(setVariantsInsightBarItems(items))
    }
  }, [nodes, stepVariantData, stateVariantData, currency, dispatch, formatCost, visualizationLevel])

  const configureNodes = useCallback(
    (serverNodes: Array<ServerNodeTypes>) => {
      const rowHeightPx = configureRowHeightForVariantView(
        visualizationLevel,
        windowLevelVariantIndices.length === 1,
        isTeamDistributionOpen,
        isWorkTypeDistributionOpen,
        isAutomationInsightsOpen,
      )
      return serverStepFlowNodesToChartNodes(serverNodes, rowHeightPx)
    },
    [
      visualizationLevel,
      windowLevelVariantIndices,
      isTeamDistributionOpen,
      isWorkTypeDistributionOpen,
      isAutomationInsightsOpen,
    ],
  )

  if (isFetching) return <Loader />

  if (!nodes || !edges) {
    return (
      <div className='flex w-screen items-center justify-center'>
        <Text variant='h4' size='xl'>
          No data available with specified filters.
        </Text>
      </div>
    )
  }

  return (
    <div className='w-full'>
      {breadcrumbs ? (
        <Breadcrumbs variant='secondary' size='s' items={breadcrumbs} className='absolute z-[1]' />
      ) : null}

      <FlowChart
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        serverEdgesToChartEdges={serverEdgesToChartEdges}
        serverNodesToChartNodes={configureNodes}
      />
    </div>
  )
}

export default VariantVisualization

