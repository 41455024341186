import { v4 as uuid4 } from 'uuid'

import { TAGS_OF_INTEREST } from '../../../app/constants'
import type {
  AppMetaFormData,
  ConfigTestCase,
} from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import { EventGeneratorsEnum } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import {
  APP_TAG_KEY,
  PROCESS_ID_REGEX_TITLE_END,
  PROCESS_ID_REGEX_TITLE_START,
  PROCESS_ID_REGEX_URL_PATH_END,
  PROCESS_ID_REGEX_URL_PATH_START,
  WINDOW_TAG_KEY,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/constants'
import type {
  DashboardContext,
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  ExtractIdentifierRule,
  MatchingCriteria,
  MatchingCriteriaContext,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { JsonObject } from '../../types/common'
import type { DataCollectionGranularityForm } from './types'

const appAndWindowNamesToTags = (appNames: string[], windowNames: string[]) => {
  const appTags = appNames.map((app) => ({ key: APP_TAG_KEY, value: app }))
  const windowTags = windowNames.map((window) => ({ key: WINDOW_TAG_KEY, value: window }))
  return [...appTags, ...windowTags]
}

export const getSalvageFields = (
  granularityData: DataCollectionGranularityForm | null | undefined,
) => {
  const salvageFields: string[] = []
  if (granularityData?.isTitleCaptured) salvageFields.push('title', 'title_lower')
  if (granularityData?.isUrlCaptured) salvageFields.push('url', 'url_parsed')
  if (granularityData?.isTypingCaptured) salvageFields.push('text_events')
  if (granularityData?.isClipboardContentCaptured)
    salvageFields.push('clipboard_copy_data', 'clipboard_paste_data')
  if (granularityData?.isKeyboardShortcutsCaptured) salvageFields.push('keyboard_shortcuts')
  if (granularityData?.isInteractedElementsCaptured) salvageFields.push('interacted_elements')

  salvageFields.sort()
  return salvageFields
}

export const generateConfigTestCase = (
  formData: DataCollectionTestCaseFormData,
  granularityData: DataCollectionGranularityForm,
  dashboardContext: DashboardContext,
) => {
  const appNames = dashboardContext.app_name ? [dashboardContext.app_name] : []
  const windowNames = dashboardContext.window_name ? [dashboardContext.window_name] : []

  return {
    test_id: uuid4(),
    rule_id: [],
    row_event: {
      title: formData.title,
      url: formData.url,
      active_process_name: formData.processName,
    },
    event_generators: [EventGeneratorsEnum.DEFAULT],
    force_tracking: false,
    expect_output: true,
    expected_salvage_fields: getSalvageFields(granularityData),
    expect_hashed_identifiers: false,
    expected_processed_event: {
      tags: appAndWindowNamesToTags(appNames, windowNames),
      extracted_identifiers:
        formData.processIdentifierName && formData.processIdentifierValue
          ? [
              {
                identifier_name: formData.processIdentifierName,
                value: formData.processIdentifierValue,
              },
            ]
          : [],
    },
    is_dashboard_generated: true,
    dashboard_context: dashboardContext,
  } as ConfigTestCase
}

export const generateRuleIn = (
  ruleFormData: DataCollectionRuleFormData,
  granularityData: DataCollectionGranularityForm,
  dashboardContext: DashboardContext,
): RuleIn => {
  const appNames = dashboardContext.app_name ? [dashboardContext.app_name] : []
  const windowNames = dashboardContext.window_name ? [dashboardContext.window_name] : []

  return {
    id: uuid4(),
    tags: appAndWindowNamesToTags(appNames, windowNames),
    allow_tracking: true,
    extract_identifiers: getExtractedidentifiers(ruleFormData),
    salvage_fields: getSalvageFields(granularityData),
    reactions: [],
    matching_criteria: ruleFormDataToMatchingCriteria(ruleFormData),
    is_dashboard_generated: true,
    dashboard_context: dashboardContext,
  }
}

const getExtractedidentifiers = (
  ruleFormData: DataCollectionRuleFormData,
): ExtractIdentifierRule[] => {
  const extractIdentifiers: ExtractIdentifierRule[] = []
  if (ruleFormData.processIdPathBefore && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromUrlPath(ruleFormData.processIdPathBefore, ruleFormData.processName),
    )
  }
  if (ruleFormData.processIdParamKey && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromUrlQuery(ruleFormData.processIdParamKey, ruleFormData.processName),
    )
  }
  if (ruleFormData.processIdTitleBefore && ruleFormData.processName) {
    extractIdentifiers.push(
      extractIdentifierFromTitle(ruleFormData.processIdTitleBefore, ruleFormData.processName),
    )
  }

  return extractIdentifiers
}

const extractIdentifierFromUrlPath = (
  processIdPathBefore: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    from_fields: ['url'],
    regex_pattern: `${PROCESS_ID_REGEX_URL_PATH_START}${processIdPathBefore}${PROCESS_ID_REGEX_URL_PATH_END}`,
    compiled_regex: `${PROCESS_ID_REGEX_URL_PATH_START}${processIdPathBefore}${PROCESS_ID_REGEX_URL_PATH_END}`,
    key: null,
    hash_identifier: false,
  }
}

const extractIdentifierFromUrlQuery = (
  processIdParamKey: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    key: processIdParamKey,
    hash_identifier: false,
  }
}

const extractIdentifierFromTitle = (
  processIdTitleBefore: string,
  processName: string,
): ExtractIdentifierRule => {
  return {
    id: uuid4(),
    identifier_name: processName,
    from_fields: ['title'],
    regex_pattern: `${PROCESS_ID_REGEX_TITLE_START}${processIdTitleBefore}${PROCESS_ID_REGEX_TITLE_END}`,
    compiled_regex: `${PROCESS_ID_REGEX_TITLE_START}${processIdTitleBefore}${PROCESS_ID_REGEX_TITLE_END}`,
    key: null,
    hash_identifier: false,
  }
}

export const updateAppTestCaseFromForm = (
  formData: DataCollectionTestCaseFormData,
  appData: AppMetaFormData,
  granularityData: DataCollectionGranularityForm,
  originaTestCase: ConfigTestCase,
) => {
  const generatedTestCase = generateConfigTestCase(formData, granularityData, {
    app_name: appData.appName,
  })
  // Keep original tags that are not used in app naming
  const nonAppNameTags =
    originaTestCase.expected_processed_event.tags?.filter(
      (tag) => !tag['key'] || tag['key'] !== APP_TAG_KEY,
    ) ?? []

  const combinedTags = [
    ...nonAppNameTags,
    ...(generatedTestCase.expected_processed_event.tags ?? []),
  ]

  return {
    ...originaTestCase,
    row_event: generatedTestCase.row_event,
    expected_salvage_fields: generatedTestCase.expected_salvage_fields,
    expected_processed_event: {
      ...originaTestCase.expected_processed_event,
      tags: combinedTags,
      extracted_identifiers:
        formData.processIdentifierName && formData.processIdentifierValue
          ? [
              {
                identifier_name: formData.processIdentifierName,
                value: formData.processIdentifierValue,
              },
            ]
          : [],
    },
    dashboard_context: { ...originaTestCase.dashboard_context, app_name: appData.appName },
  } as ConfigTestCase
}

const ruleEngineRuleTemplate = (
  contextKey: keyof MatchingCriteriaContext,
  fieldName: 'title_lower' | 'active_process_name_lower' | 'url',
) => {
  return `${fieldName} and not [w for w in context['${contextKey}'] if w not in ${fieldName}]`
}

const ruleFormDataToMatchingCriteria = (
  ruleFormData: DataCollectionRuleFormData,
): MatchingCriteria => {
  const ruleEngineRules: string[] = []
  const context: MatchingCriteriaContext = {}

  const cleanedUrlData = (ruleFormData.inUrl ?? []).filter(({ value }) => value)
  if (cleanedUrlData.length) {
    context.in_url = cleanedUrlData.map(({ value }) => value)
    ruleEngineRules.push(ruleEngineRuleTemplate('in_url', 'url'))
  }

  const cleanedTitleData = (ruleFormData.inTitle ?? []).filter(({ value }) => value)
  if (cleanedTitleData.length) {
    context.in_title = cleanedTitleData.map(({ value }) => value.toLowerCase())
    ruleEngineRules.push(ruleEngineRuleTemplate('in_title', 'title_lower'))
  }

  if (ruleFormData.inProcessName) {
    context.in_process_name = [ruleFormData.inProcessName.toLowerCase()]
    ruleEngineRules.push(ruleEngineRuleTemplate('in_process_name', 'active_process_name_lower'))
  }

  return {
    rule_engine_rule: ruleEngineRules.join(' and '),
    context,
  }
}

export const updateRuleIn = (
  ruleFormData: DataCollectionRuleFormData,
  originalRule: RuleIn,
): RuleIn => {
  return {
    ...originalRule,
    extract_identifiers: getExtractedidentifiers(ruleFormData),
    matching_criteria: ruleFormDataToMatchingCriteria(ruleFormData),
    dashboard_context: {
      ...originalRule.dashboard_context,
      process_name: ruleFormData.processName,
    },
  }
}

export const getAppNameFromTestCase = (testCase: ConfigTestCase): string => {
  if (!testCase.expected_processed_event['tags']) return 'Other'
  const tags = testCase.expected_processed_event['tags'] as JsonObject[]
  return tags
    .filter((tag) => tag['key'] && tag['key'] === APP_TAG_KEY)
    .map((tag) => tag['value'])
    .join(' | ')
}

export const getTestCaseLabel = (testCase: ConfigTestCase): string => {
  // Try to get app + window name from tags
  if (testCase.expected_processed_event.tags) {
    const windowTagValues = testCase.expected_processed_event.tags
      .filter((tag) => TAGS_OF_INTEREST.window.includes(tag.key) && tag.value)
      .map((tag) => tag.value)

    if (windowTagValues.length > 0) return windowTagValues.join(' | ')
  }

  if (testCase.description) return testCase.description

  return testCase.test_id
}

export const testCaseToFormData = (
  configTestCase: ConfigTestCase,
): DataCollectionTestCaseFormData => {
  const idName =
    configTestCase?.expected_processed_event?.extracted_identifiers?.[0]?.identifier_name
  const idValue = configTestCase?.expected_processed_event?.extracted_identifiers?.[0]?.value

  return {
    processName: configTestCase?.row_event?.active_process_name ?? '',
    url: configTestCase?.row_event?.url ?? '',
    title: configTestCase?.row_event?.title ?? '',
    processIdentifierName: idName ?? '',
    processIdentifierValue: idValue ?? '',
  }
}
