import { useMemo } from 'react'

import { useLocalStorageContext } from '../../app/LocalStorageContext'
import { DashboardInitialization } from '../utils/UtilityWrapper/types'

const useDashboard = () => {
  const { isDashboardInitialized: isInitialized, dashboardSetup } = useLocalStorageContext()

  const payload = useMemo(() => {
    return !dashboardSetup || !isInitialized ? null : { ...dashboardSetup, isInitialized: true }
  }, [isInitialized, dashboardSetup])

  return payload ?? ({ isInitialized: false } as DashboardInitialization)
}

export default useDashboard
