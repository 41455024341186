import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetActivityInsightTimesByApplicationQuery } from '../../app/apiSlice'
import { BarChartAndTableDisplay, Loader } from '../../common/components'
import { ChartDataEntry } from '../../common/components/Charts/types'
import { Select } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import useGetActivityInsightBody, {
  GetActivityInsightBodyOptions,
} from '../../common/hooks/useGetActivityInsightBody'
import type { Tag } from '../../common/types/common'
import { getActivityName } from './TimeLayout/activityDescriptions'

const options = [
  { label: 'Activities', value: '' },
  { label: 'Key presses', value: 'text_entry' },
  { label: 'Copies from app', value: 'copies' },
  { label: 'Pastes to app', value: 'pastes' },
]

interface Props {
  onNavigate: (tags: Tag[]) => void
  queryOptions: GetActivityInsightBodyOptions
  isFirstLayer?: boolean | undefined
}

const ActivitiesByWindow = ({ onNavigate, queryOptions, isFirstLayer }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { pathname } = useLocation()

  const [chartData, setChartData] = useState<ChartDataEntry[]>([])

  const queryBody = useGetActivityInsightBody(queryOptions)
  const { data, isLoading } = useGetActivityInsightTimesByApplicationQuery(queryBody)

  useEffect(() => {
    convertDataToChart()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const convertDataToChart = () => {
    if (!data) return

    setChartData(
      data.map((d) => ({
        label: d.window_name + ' - ' + getActivityName(d.activity),
        value: d.total_duration_sec / 3600,
      })),
    )
  }

  const onChartClick = (_: string, dataIndex: number) => {
    if (data?.[dataIndex]) onNavigate(data[dataIndex].tags)
  }

  if (isLoading) return <Loader />

  return (
    <BarChartAndTableDisplay
      label='Activities'
      data={chartData}
      unit='h'
      tableHeaderValues={['Activity', 'time']}
      onClick={onChartClick}
      isExportable
      selectActions={
        isFirstLayer
          ? []
          : [
              <Select
                key='select-activity'
                size='xs'
                options={options}
                value={options[0].value}
                onChange={(val: string) => {
                  navigate(`${pathname.split('/').slice(0, 4).join('/')}/${val}`)
                }}
              />,
            ]
      }
    />
  )
}

export default ActivitiesByWindow
