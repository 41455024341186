import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

export interface RadiosOption {
  name: string
  value: string | number

  disabled?: boolean
}

interface Props {
  value: RadiosOption
  options: RadiosOption[]
  onChange: Dispatch<SetStateAction<RadiosOption>>

  className?: string
  label?: string
  description?: string
  inline?: boolean
}

const Radios = (props: Props) => {
  const {
    value,
    options,
    onChange,

    className,
    label,
    description,
    inline,
  } = props

  return (
    <div className={className}>
      {label && <label className='mb-1 text-base font-medium text-gray-900'>{label}</label>}

      <fieldset>
        {label && <legend className='sr-only'>{label}</legend>}

        <div
          className={clsx(
            'space-y-4',
            inline && 'sm:flex sm:items-center sm:space-x-10 sm:space-y-0',
          )}
        >
          {options.map((option) => (
            <div key={option.value} className='flex items-center'>
              <input
                id={String(option.value)}
                name={String(option.value)}
                type='radio'
                className='size-4 cursor-pointer border-gray-300 text-indigo-600 hover:bg-primary-50 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
                checked={value.value === option.value}
                onChange={() => !option.disabled && onChange(option)}
                disabled={option.disabled}
              />

              <label
                htmlFor={String(option.value)}
                className={clsx(
                  'ml-3 block text-sm font-medium',
                  option.disabled ? 'text-gray-400' : 'text-gray-700',
                )}
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {description && <p className='mt-2 text-sm leading-5 text-gray-500'>{description}</p>}
    </div>
  )
}

export default Radios
