import clsx from 'clsx'
import { ButtonHTMLAttributes, cloneElement } from 'react'

import { InlineLoader } from '../components'

const variantStyles: { [key in Variant]: string } = {
  primary:
    'border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500 disabled:bg-primary-400 disabled:cursor-not-allowed',
  secondary:
    'border-transparent bg-primary-100 text-primary-700 hover:bg-primary-200 focus:ring-primary-500 disabled:bg-primary-50 disabled:text-primary-400 disabled:cursor-not-allowed',
  white:
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-100 focus:ring-primary-500 disabled:border-gray-200 disabled:text-gray-400 disabled:hover:bg-transparent disabled:cursor-not-allowed',
  ghost:
    'border-transparent bg-transparent hover:bg-gray-100 hover:text-gray-700 focus:ring-2 focus:ring-primary-500 disabled:text-gray-400 disabled:hover:bg-transparent disabled:cursor-not-allowed',
  success:
    'border-transparent bg-green-600 text-white hover:bg-green-700 focus:ring-green-500 disabled:bg-green-300 disabled:cursor-not-allowed',
  destructive:
    'border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 disabled:bg-red-300 disabled:cursor-not-allowed',
}

const sizeStyles: { [key in Size]: string } = {
  xs: 'p-1',
  s: 'p-1.5',
  m: 'p-2',
  l: 'p-2',
  xl: 'p-3',
}

const maxSizeStyles: { [key in Size]: string } = {
  xs: 'max-h-7.5',
  s: 'max-h-8.5',
  m: 'max-h-9.5',
  l: 'max-h-10.5',
  xl: 'max-h-12.5',
}

const iconSizeStyles: { [key in Size]: string } = {
  xs: 'size-5',
  s: 'size-5',
  m: 'size-5',
  l: 'size-6',
  xl: 'size-6',
}

type Variant = 'primary' | 'secondary' | 'white' | 'ghost' | 'destructive' | 'success'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

type ButtonProps = {
  icon: React.ReactElement

  variant?: Variant
  size?: Size
  iconClassName?: string
  className?: string
  loading?: boolean
  rounded?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const IconButton = (props: ButtonProps) => {
  const {
    icon,
    variant = 'primary',
    size = 'm',
    iconClassName,
    className,
    type = 'button',
    loading,
    rounded,
    disabled,
    ...rest
  } = props

  return (
    <button
      className={clsx(
        'inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        variantStyles[variant],
        maxSizeStyles[size],
        sizeStyles[size],
        rounded ? 'rounded-full' : size === 'xs' ? 'rounded' : 'rounded-md',
        className,
      )}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {loading ? (
        <InlineLoader size={`icon_btn_${size}`} />
      ) : (
        <>
          {icon &&
            cloneElement(icon, {
              className: clsx(iconSizeStyles[size], iconClassName),
            })}
        </>
      )}
    </button>
  )
}

export default IconButton
