import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import {
  useGetFilterDimensionOptionsQuery,
  useGetFilterUserActionOptionsQuery,
  useGetTrackedProcessPropertiesQuery,
} from '../../../app/apiSlice'
import { Loader } from '../../../common/components'
import { Button, Text } from '../../../common/designs'
import { useAppDispatch, useDashboard, useEnhancedNavigate } from '../../../common/hooks'
import { SubPathEnum } from '../../../common/types/routing'
import {
  setDimensionFilterOptions,
  setProcessProperties,
  setUserActionFilterOptions,
} from '../processesSlice'
import { GroupByFieldTypeEnum } from '../types'
import { getProcessIdFromLocation } from '../utils'
import ActiveFilterBar from './ActiveFilterBar'
import { processPropertyDimensions, supportingSystemDimensions } from './Filters/constants'
import type { FilterDimemsionRequestData } from './Filters/types'

const dimensionsWithoutOptions = [
  {
    group_by: { field_type: GroupByFieldTypeEnum.TRANSACTION_ID },
    options: [],
    display_name: 'Case id',
  },
  {
    group_by: { field_type: GroupByFieldTypeEnum.WINDOW_STEP_VARIANT },
    options: [],
    display_name: 'Variants',
  },
]

const TrackedProcessLayout = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const processId = getProcessIdFromLocation(location)
  const { data: processProperties, isLoading } = useGetTrackedProcessPropertiesQuery(processId)
  const {
    user: { isPlatformViewer },
  } = useDashboard()

  const { data: dimensionData } = useGetFilterDimensionOptionsQuery({
    processId,
    group_by_fields: Object.keys({ ...processPropertyDimensions, ...supportingSystemDimensions })
      .filter(
        (k) =>
          !dimensionsWithoutOptions
            .map((o) => o.group_by.field_type)
            .includes(k as GroupByFieldTypeEnum),
      )
      .map((k) => ({ group_by: { field_type: k } }) as FilterDimemsionRequestData),
  })
  const { data: userActionData } = useGetFilterUserActionOptionsQuery({ processId })

  const isVariantsView = location.pathname.includes(SubPathEnum.VARIANTS)

  useEffect(() => {
    if (dimensionData) {
      dispatch(setDimensionFilterOptions([...dimensionData, ...dimensionsWithoutOptions]))
    }
  }, [dimensionData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processProperties) {
      dispatch(setProcessProperties(processProperties))
    }
  }, [processProperties]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userActionData) dispatch(setUserActionFilterOptions(userActionData))
  }, [userActionData]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) return <Loader />

  return (
    <>
      {/* Variants view has its own customised ActiveFilterBar */}
      {isVariantsView ? null : <ActiveFilterBar />}
      {isPlatformViewer ? (
        // The min-[1024px] breakpoint keeps the button in the same row as filter button
        // which jumps to a lower row when screen width is smaller than 1024px.
        <div className='absolute left-4 top-14 flex h-7 items-center gap-2 min-[1024px]:top-3'>
          <Button
            iconStart={<ArrowLeftIcon />}
            className=''
            variant='white'
            size='xs'
            onClick={() => navigate('..')}
          >
            Process List
          </Button>
          <Text size='xs' weight={500} className='w-52 text-ellipsis'>
            {processProperties?.name ?? ''}
          </Text>
        </div>
      ) : null}

      <Outlet />
    </>
  )
}

export default TrackedProcessLayout
