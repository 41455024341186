import { useState } from 'react'

import { useUpdateDataCollectionsConfigApplicationMutation } from '../../../app/apiSlice'
import {
  type AppMetaFormData,
  type ConfigTestCase,
  ConfigureDataCollectionSubPathEnum,
} from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import useManageServerTestCasesAndConfigs from '../../../features/ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import { APP_TAG_KEY } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/constants'
import type {
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Tabs } from '../../designs'
import { useEnhancedNavigate } from '../../hooks'
import { Loader } from '../Loader'
import AppLevelTestAndRules from './AppLevelTestAndRules'
import ProcessLevelTestAndRules from './ProcessLevelTestAndRules'
import WindowLevelTestAndRules from './WindowLevelTestAndRules'
import {
  generateConfigTestCase,
  generateRuleIn,
  getSalvageFields,
  testCaseToFormData,
  updateAppTestCaseFromForm,
  updateRuleIn,
} from './dataHelpers'
import type {
  AppTestCaseGroup,
  DataCollectionGranularityForm,
  TestCaseWithTestResult,
} from './types'
import { ApplicatioConfigTabEnum } from './types'

interface Props {
  isLoading: boolean
  appTestCasesGroup: AppTestCaseGroup
  onAddNewWindow: (windowName: string) => void
}

const tabOptions = [
  {
    label: 'App-Level Data Collection',
    value: ApplicatioConfigTabEnum.APPLICATION_DATA,
  },
  {
    label: 'Window-Level Data Collection',
    value: ApplicatioConfigTabEnum.WINDOW_DATA,
  },
  {
    label: 'Structured Process Data Collection',
    value: ApplicatioConfigTabEnum.PROCESS_DATA,
  },
]

const ApplicationConfigPage = ({ isLoading, appTestCasesGroup, onAddNewWindow }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const [isLoadingUpdateAppMeta, setIsLoadingUpdateAppMeta] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<ApplicatioConfigTabEnum>(
    ApplicatioConfigTabEnum.APPLICATION_DATA,
  )

  const {
    createTestCases,
    updateTestCases,
    deleteTestCase,
    updateRules,
    createRules,
    deleteRule,
    isUploadingTestCases,
    isUploadingRules,
    isRunningConfigTests,
  } = useManageServerTestCasesAndConfigs()

  const [updateAppMetaData, { isLoading: isLoadingUpdateAppMetaData }] =
    useUpdateDataCollectionsConfigApplicationMutation()

  if (
    isUploadingTestCases ||
    isUploadingRules ||
    isRunningConfigTests ||
    isLoadingUpdateAppMetaData ||
    isLoading
  )
    return <Loader />

  const updateRuleAppName = (rule: RuleIn, appData: AppMetaFormData) => {
    const ruleToUpdate = { ...rule }

    // Keep original tags that are not used in app naming
    const nonAppNameTags =
      ruleToUpdate.tags.filter((tag) => !tag['key'] || tag['key'] !== APP_TAG_KEY) ?? []
    // Create new app name tag
    const updatedAppNameTag = { key: APP_TAG_KEY, value: appData.appName }

    ruleToUpdate.tags = [...nonAppNameTags, updatedAppNameTag]
    ruleToUpdate.dashboard_context = {
      ...ruleToUpdate.dashboard_context,
      app_name: appData.appName,
    }
    return ruleToUpdate
  }

  const onUpdateAppData = async (appData: AppMetaFormData) => {
    setIsLoadingUpdateAppMeta(true)
    // Update rules and the tests if tags or salvage_fields were updated
    const updatedTestCases = appTestCasesGroup.testCases.map((testCase) => {
      return updateAppTestCaseFromForm(
        testCaseToFormData(testCase),
        appData,
        granularityFormData,
        testCase,
      )
    })

    const rulesToUpdate = appTestCasesGroup.rules.map((rule) => updateRuleAppName(rule, appData))

    rulesToUpdate.push(
      ...appTestCasesGroup.processRules.map((rule) => updateRuleAppName(rule, appData)),
    )

    appTestCasesGroup.windowLevelData.forEach((window) => {
      updatedTestCases.push(
        ...window.testCases.map((testCase) => {
          return updateAppTestCaseFromForm(
            testCaseToFormData(testCase),
            appData,
            granularityFormData,
            testCase,
          )
        }),
      )

      rulesToUpdate.push(
        ...window.rulesForTestCases.map((rule) => updateRuleAppName(rule, appData)),
      )
    })

    const updateRequests: Promise<any>[] = [
      updateTestCases(updatedTestCases),
      updateRules(rulesToUpdate),
    ]

    // Update app metadata to DashboardDB if the app exists there.
    if (appTestCasesGroup.appDashboardConfigs.id) {
      updateRequests.push(
        updateAppMetaData({
          id: appTestCasesGroup.appDashboardConfigs.id,
          name: appData.appName,
          category: appData.category,
          is_pdf_application: appData.isPdfApplication,
        }),
      )
    }

    await Promise.all(updateRequests)

    setIsLoadingUpdateAppMeta(false)

    // Navigate to new application name page if app name changes.
    if (appMetaFormData.appName !== appData.appName) {
      navigate(
        `../${ConfigureDataCollectionSubPathEnum.APPS}/${encodeURIComponent(appData.appName)}`,
      )
    }
  }

  const setTestCaseSalvageFields = (testCase: TestCaseWithTestResult, salvageFields: string[]) => {
    return {
      ...testCase,
      expected_salvage_fields: salvageFields,
    }
  }
  const setRuleSalvageFields = (rule: RuleIn, salvageFields: string[]) => {
    return {
      ...rule,
      salvage_fields: salvageFields,
    }
  }

  const onUpdateGranularityData = async (formData: DataCollectionGranularityForm) => {
    const salvageFields = getSalvageFields(formData)

    const updatedTestCases = appTestCasesGroup.testCases.map((testCase) =>
      setTestCaseSalvageFields(testCase, salvageFields),
    )

    const rulesToUpdate = appTestCasesGroup.rules.map((rule) =>
      setRuleSalvageFields(rule, salvageFields),
    )

    appTestCasesGroup.windowLevelData.forEach((window) => {
      updatedTestCases.push(
        ...window.testCases.map((testCase) => setTestCaseSalvageFields(testCase, salvageFields)),
      )

      rulesToUpdate.push(
        ...window.rulesForTestCases.map((rule) => setRuleSalvageFields(rule, salvageFields)),
      )
    })

    const updateRequests: Promise<any>[] = [
      updateTestCases(updatedTestCases),
      updateRules(rulesToUpdate),
    ]

    await Promise.all(updateRequests)
  }

  const onAddTestCaseForApp = async (formValues: DataCollectionTestCaseFormData) => {
    const newTestCase = generateConfigTestCase(formValues, granularityFormData, {
      app_name: appMetaFormData.appName,
    })
    await createTestCases([newTestCase])
  }

  const onAddTestCaseForWindow = async (
    formValues: DataCollectionTestCaseFormData,
    windowName: string,
  ) => {
    const newTestCase = generateConfigTestCase(formValues, granularityFormData, {
      app_name: appMetaFormData.appName,
      window_name: windowName,
    })
    await createTestCases([newTestCase])
  }

  const onUpdateTestCase = async (
    formData: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => {
    const updatedTestCase = updateAppTestCaseFromForm(
      formData,
      appMetaFormData,
      granularityFormData,
      configTestCase,
    )
    await updateTestCases([updatedTestCase])
  }

  const onDeleteTestCase = async (testCase: ConfigTestCase) => {
    await deleteTestCase(testCase.test_id)
  }

  const onAddRuleInApp = async (formValues: DataCollectionRuleFormData) => {
    await createRules([
      generateRuleIn(formValues, granularityFormData, {
        app_name: appMetaFormData.appName,
      }),
    ])
  }
  const onAddRuleInWindow = async (formValues: DataCollectionRuleFormData, windowName: string) => {
    await createRules([
      generateRuleIn(formValues, granularityFormData, {
        app_name: appMetaFormData.appName,
        window_name: windowName,
      }),
    ])
  }
  const onAddRuleInProcess = async (formValues: DataCollectionRuleFormData) => {
    await createRules([
      generateRuleIn(formValues, granularityFormData, {
        app_name: appMetaFormData.appName,
        process_name: formValues.processName,
      }),
    ])
  }

  const onUpdateRuleIn = async (formData: DataCollectionRuleFormData, originalRule: RuleIn) => {
    await updateRules([updateRuleIn(formData, originalRule)])
  }
  const onUpdateRuleInCode = async (updatedRuleIn: RuleIn) => {
    await updateRules([updatedRuleIn])
  }
  const onDeleteRuleIn = async (rule: RuleIn) => {
    await deleteRule(rule.id)
  }

  const appMetaFormData = {
    appName: appTestCasesGroup.appName,
    category: appTestCasesGroup.appDashboardConfigs.category,
    isPdfApplication: appTestCasesGroup.appDashboardConfigs.is_pdf_application,
  } as AppMetaFormData

  const granularityFormData = {
    isTitleCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes('title'),
    isUrlCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes('url'),
    isTypingCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes('text_events'),
    isClipboardContentCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes(
      'clipboard_copy_data',
    ),
    isKeyboardShortcutsCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes(
      'keyboard_shortcuts',
    ),
    isInteractedElementsCaptured: (appTestCasesGroup.rules[0]?.salvage_fields ?? []).includes(
      'interacted_elements',
    ),
  } as DataCollectionGranularityForm

  return (
    <div className='space-y-6'>
      <Tabs
        options={tabOptions}
        value={activeTab}
        onChange={setActiveTab as React.Dispatch<React.SetStateAction<string>>}
        size='s'
      />

      {activeTab === ApplicatioConfigTabEnum.APPLICATION_DATA ? (
        <AppLevelTestAndRules
          appTestCasesGroup={appTestCasesGroup}
          appMetaFormData={appMetaFormData}
          onUpdateAppData={onUpdateAppData}
          isLoadingUpdateAppMeta={isLoadingUpdateAppMeta}
          granularityFormData={granularityFormData}
          onUpdateGranularityData={onUpdateGranularityData}
          onAddTestCase={onAddTestCaseForApp}
          onUpdateTestCase={onUpdateTestCase}
          onDeleteTestCase={onDeleteTestCase}
          onAddRuleIn={onAddRuleInApp}
          onUpdateRuleIn={onUpdateRuleIn}
          onUpdateRuleInCode={onUpdateRuleInCode}
          onDeleteRuleIn={onDeleteRuleIn}
        />
      ) : activeTab === ApplicatioConfigTabEnum.WINDOW_DATA ? (
        <WindowLevelTestAndRules
          appTestCasesGroup={appTestCasesGroup}
          onAddNewWindow={onAddNewWindow}
          onAddTestCase={onAddTestCaseForWindow}
          onUpdateTestCase={onUpdateTestCase}
          onDeleteTestCase={onDeleteTestCase}
          onAddRuleIn={onAddRuleInWindow}
          onUpdateRuleIn={onUpdateRuleIn}
          onUpdateRuleInCode={onUpdateRuleInCode}
          onDeleteRuleIn={onDeleteRuleIn}
        />
      ) : (
        <ProcessLevelTestAndRules
          appTestCasesGroup={appTestCasesGroup}
          onAddRuleIn={onAddRuleInProcess}
          onUpdateRuleIn={onUpdateRuleIn}
          onUpdateRuleInCode={onUpdateRuleInCode}
          onDeleteRuleIn={onDeleteRuleIn}
        />
      )}
    </div>
  )
}

export default ApplicationConfigPage
