import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'

import { Text } from '../../../../../common/designs'

interface Props {
  amount: number
}

const CommentBubble = ({ amount }: Props) => {
  return (
    <div className='flex items-center'>
      {amount > 0 && (
        <>
          <Text size='xs' className='mr-1'>
            {amount}
          </Text>

          <ChatBubbleOvalLeftIcon className='size-5' />
        </>
      )}
    </div>
  )
}
export default CommentBubble
