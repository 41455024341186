import { formatISO, sub } from 'date-fns'

import { useGetPluginPingsStatusQuery } from '../../../app/apiSlice'
import { CHART_NEUTRAL_HSLA, CHART_PRIMARY_HSLA } from '../../../app/theme'
import { useDashboard } from '../../../common/hooks'
import { GranularityEnum, OrganizationLevelEnum } from '../../../common/types/monitoring'
import { AdminMainPathEnum } from '../../../common/types/routing'
import { fractionToPercentage } from '../../../common/utils/numberFormatting'
import StatusCard from './StatusCard'

const AgentStatus = () => {
  const { company } = useDashboard()

  const { data: rawData, isLoading } = useGetPluginPingsStatusQuery({
    start_timestamp: formatISO(sub(new Date().setHours(0, 0, 0, 0), { days: 6 })),
    end_timestamp: formatISO(new Date().setHours(0, 0, 0, 0)),
    granularity: GranularityEnum.DAY,
    organization_level: OrganizationLevelEnum.COMPANY,
  })

  const totalComputerConnectedCount = company.agentInstallationTarget
  const currentComputerConnectedCount =
    rawData?.reduce((max, ping) => Math.max(max, ping.computer_count), 0) ?? 0

  const chartData = {
    labels: ['Connected computers', 'Computers remaining'],
    datasets: [
      {
        data: [
          currentComputerConnectedCount,
          totalComputerConnectedCount - currentComputerConnectedCount,
        ],
        backgroundColor: [CHART_PRIMARY_HSLA, CHART_NEUTRAL_HSLA],
      },
    ],
  }

  return (
    <StatusCard
      chartData={chartData}
      titleProps={{
        label: 'Connected computers',
        value: fractionToPercentage(currentComputerConnectedCount / totalComputerConnectedCount),
      }}
      descriptionProps={{
        label: 'Last 7 days',
        value: `${currentComputerConnectedCount}/${totalComputerConnectedCount}`,
      }}
      navProps={{
        label: 'Download installer',
        link: AdminMainPathEnum.INSTALLATION_CENTER,
      }}
      isLoading={isLoading}
    />
  )
}

export default AgentStatus
