import { Text } from '../../../common/designs'

const StepActivityExplanations = () => {
  return (
    <>
      <Text size='s' className='mb-1'>
        <strong>Quick processing</strong> activities are short repetitive activities moving business
        process cases to the next stage. Consider automation due to the duration improvements.
      </Text>
      <Text size='s' className='mb-1'>
        <strong>Multi-system workflows</strong> are repetitive work performed in other systems to
        execute the process tasks. Consider automation due to the duration improvements and reducing
        manual efforts.
      </Text>
      <Text size='s'>
        <strong>Focused work</strong> is activities done within one process task that requires more
        long-term activities without a need to jump to other business applications. Consider
        applying decision intelligence for duration improvements and reducing manual efforts.
      </Text>
    </>
  )
}

export default StepActivityExplanations

