import { useCallback, useEffect, useMemo, useState } from 'react'

import { useGetActivityInsightTimesQuery } from '../../../app/apiSlice'
import { BarChartAndTableDisplay, Loader } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import useGetActivityInsightBody, {
  GetActivityInsightBodyOptions,
} from '../../../common/hooks/useGetActivityInsightBody'
import { formatNumber, fractionToPercentage } from '../../../common/utils/numberFormatting'
import {
  VisitActivityDescriptionEnum,
  activityDescriptions,
  getActivityName,
} from './activityDescriptions'

interface Props {
  queryOptions: GetActivityInsightBodyOptions
}

const TimeActivities = ({ queryOptions }: Props) => {
  const [chartData, setChartData] = useState<ChartDataEntry[]>([])
  const queryBody = useGetActivityInsightBody(queryOptions)

  const { data, isLoading } = useGetActivityInsightTimesQuery(queryBody)

  useEffect(() => {
    if (data) convertDataToChart()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const convertDataToChart = () => {
    if (!data) return

    const chartData = data.map((item) => {
      const { name, total_duration_sec } = item
      const timeH = total_duration_sec / 60 / 60
      return {
        label: getActivityName(name),
        value: timeH,
      }
    })

    setChartData(chartData)
  }

  const totalTime = useMemo(() => {
    return chartData.reduce((previousValue, { value }) => previousValue + value, 0)
  }, [chartData])

  const tooltipCallback = useCallback(
    (tooltipItems: any) => [
      `${formatNumber(tooltipItems.raw, 10)} h (${fractionToPercentage(
        tooltipItems.raw / totalTime,
      )})`,
      ...activityDescriptions[tooltipItems.label as VisitActivityDescriptionEnum],
    ],
    [totalTime],
  )

  if (isLoading) return <Loader />

  return (
    <BarChartAndTableDisplay
      label='Activities'
      data={chartData}
      unit='h'
      tableHeaderValues={['Activity', 'Time']}
      tooltipCallback={tooltipCallback}
      isExportable
    />
  )
}

export default TimeActivities
