import _ from 'lodash'

// Filters all null key-value pairs off the object and returns cleaned object
export const removeNullsFromObject = (obj: { [k: string]: any }) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

// Filters all null and empty array key-value pairs off the object and returns cleaned object
export const removeNullsAndEmptyArraysFromObject = (obj: { [k: string]: any }) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => (Array.isArray(v) ? v.length > 0 : v != null)),
  )
}

// Removes empty arrays and objects from the object.
export const removeEmptyKeyPairs = (obj: Record<string, any>): Record<string, any> => {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value) && !_.isArray(value)) {
      value = removeEmptyKeyPairs(value)
    }

    if (!(_.isObject(value) && _.isEmpty(value))) {
      result[key] = value
    }
  })
}

export const convertHoursToSecondsOrNull = (hours: number | null | undefined) => {
  return hours ? hours * 60 * 60 : null
}

export const generateIncrementalIndexesArrayUntil = (amount: number): number[] => [
  ...Array(amount).keys(),
]
export const generateCustomIndexesArrayUntil = (amount: number, index: number): number[] =>
  new Array(amount).fill(index)

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

type NestedObject = {
  [key: string]: any
}

// Works quite the same as flatMap but for objects.
export const flattenNestedObject = (obj: NestedObject): NestedObject => {
  const result: NestedObject = {}

  Object.values(obj).forEach((nestedObj) => {
    if (typeof nestedObj === 'object' && nestedObj !== null) {
      Object.entries(nestedObj as NestedObject).forEach(([nestedKey, value]) => {
        result[nestedKey] = value
      })
    }
  })

  return result
}

// Checks whether the object has specific property
export const hasOwnProperty = (obj: object, prop: string | number) => {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const objArrayToCsv = (objArray: object[]) => {
  let csvString = ''

  if (!objArray.length) return csvString

  // Generate the headings
  const headers = Object.keys(objArray[0])
  csvString += headers.join(',') + '\r\n'

  // Generate the data rows
  objArray.forEach((row) => {
    const rowValues = Object.values(row)
    csvString += rowValues.join(',') + '\r\n'
  })

  return csvString
}
