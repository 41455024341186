import clsx from 'clsx'

import { Text } from '../../designs'
import type { StatDetails } from './Stats'

interface Props {
  data: StatDetails
  className?: string
  isLoading?: boolean
}

const Stat = ({ data, className, isLoading = false }: Props) => {
  return (
    <div
      className={clsx(
        'overflow-hidden rounded-lg bg-white px-4 py-5 shadow-md ring-2 ring-black/5 sm:p-6',
        className,
      )}
    >
      <Text size='l' weight={600}>
        {data.label}
      </Text>
      <div className='flex items-baseline text-2xl font-semibold text-primary-600'>
        {isLoading ? (
          <div className='mt-1 inline-block size-6 animate-pulse rounded-full bg-gray-200' />
        ) : (
          data.value
        )}
      </div>
    </div>
  )
}

export default Stat
