import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}

const ShadowContainer = ({ children, className }: Props) => {
  return (
    <div className={clsx('bg-white shadow-md ring-2 ring-black/5 md:rounded-lg', className)}>
      {children}
    </div>
  )
}

export default ShadowContainer
