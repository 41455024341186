import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useDeleteCaseMutation, useUpdateCaseMutation } from '../../../../app/apiSlice'
import { ActionModal, Button, Divider, InputField, TextArea } from '../../../../common/designs'
import {
  useAppDispatch,
  useAppSelector,
  useCostUtils,
  useDashboard,
} from '../../../../common/hooks'
import { formatNumber } from '../../../../common/utils/numberFormatting'
import {
  deleteCase,
  loadSnapshot,
  saveSnapshot,
  selectActiveCase,
  selectActiveCaseLane,
  selectIsCommentsOpen,
  setActiveCaseId,
  toggleIsMetricOpen,
  updateCase,
} from '../../casesSlice'
import { IntensityLevelEnum } from '../../enums'
import type { Case } from '../../types'
import validator from '../../validators'
import {
  ActivityFeedSection,
  AttachmentControl,
  Collaborators,
  CommentField,
  CommentSection,
  DetailItem,
  NavigationBar,
  OptionsBoxes,
  Title,
} from './components'

interface Inputs {
  description?: string | undefined
  title: string
  category_id: number
  assignee_id: number
  business_case_cost: number
  implementation_cost: number
  priority: string
  complexity: string
}

const formSchema = yup
  .object({
    title: validator.title,
    description: validator.description,
    category_id: validator.category_id,
    assignee_id: validator.assignee_id,
    business_case_cost: validator.businessCaseCost,
    implementation_cost: validator.implementationCost,
    priority: validator.priority,
    complexity: validator.complexity,
  })
  .required()

const CaseView = () => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()
  const { currency } = useCostUtils()
  const activeCase = useAppSelector(selectActiveCase)
  const activeCaseLane = useAppSelector(selectActiveCaseLane)
  const isCommentsOpen = useAppSelector(selectIsCommentsOpen)
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema),
    defaultValues: { ...activeCase },
  })

  const [updateCaseMutation] = useUpdateCaseMutation()
  const [deleteCaseMutation] = useDeleteCaseMutation()

  useEffect(() => {
    reset({ ...activeCase })
  }, [activeCase]) // eslint-disable-line react-hooks/exhaustive-deps

  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState<boolean>(false)

  const onSave: SubmitHandler<Inputs> = (body: Inputs) => {
    const caseObject: Case = {
      ...activeCase,
      title: body.title,
      description: body.description ?? '',
      category_id: body.category_id,
      business_case_cost: body.business_case_cost,
      implementation_cost: body.implementation_cost,
      priority: body.priority as IntensityLevelEnum,
      complexity: body.complexity as IntensityLevelEnum,
      assignee_id: body.assignee_id,
    }

    dispatch(saveSnapshot())
    dispatch(updateCase(caseObject))
    dispatch(setActiveCaseId(null))

    updateCaseMutation({
      caseId: activeCase.id,
      body: caseObject,
    })
      .unwrap()
      .catch(() => {
        dispatch(loadSnapshot())
      })
  }

  const onDelete = () => {
    dispatch(saveSnapshot())
    dispatch(deleteCase(activeCase.id))
    dispatch(setActiveCaseId(null))

    deleteCaseMutation(activeCase.id)
      .unwrap()
      .catch(() => {
        dispatch(loadSnapshot())
      })
  }

  return (
    <form autoComplete='off' onSubmit={handleSubmit(onSave)}>
      <div className='mb-2 flex items-center justify-between'>
        <Title title={watch('title')} control={control} errors={errors} />

        <Button
          variant='white'
          iconEnd={<ChevronRightIcon />}
          onClick={() => dispatch(toggleIsMetricOpen())}
          className='ml-3'
        >
          Metrics
        </Button>
      </div>
      <Divider />

      <div className='mt-7 text-left'>
        <div className='mb-4 space-y-2'>
          <div className='flex items-baseline justify-between'>
            <DetailItem
              assigneeId={watch('assignee_id')}
              onChange={(value: number) => setValue('assignee_id', value)}
            />
            <DetailItem
              title='Created'
              text={format(new Date(activeCase.created_date_time), 'dd-MM-yyyy')}
            />
          </div>
          <div className='flex justify-between'>
            <DetailItem title='Status' text={activeCaseLane} />
            <DetailItem
              title='Status updated'
              text={format(new Date(activeCase.latest_update_date_time), 'dd-MM-yyyy')}
            />
          </div>

          <div className='flex justify-between'>
            <DetailItem
              categoryId={watch('category_id')}
              onChange={(value: number) => setValue('category_id', value)}
            />
            <DetailItem title='Opportunity ID' text={activeCase.id} />
          </div>
        </div>

        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <TextArea
              label='Description'
              placeholder='Add a description'
              error={errors.description?.message}
              rows={7}
              {...field}
            />
          )}
        />

        <div className='mb-4 mt-3 flex justify-between gap-4'>
          <Controller
            name='business_case_cost'
            control={control}
            render={({ field }) => (
              <InputField
                label='Annualised savings'
                error={errors.business_case_cost?.message}
                iconStart={currency.symbol}
                {...field}
              />
            )}
          />

          <Controller
            name='implementation_cost'
            control={control}
            render={({ field }) => (
              <InputField
                label='Effort required to fix'
                error={errors.implementation_cost?.message}
                iconStart={currency.symbol}
                {...field}
              />
            )}
          />

          <InputField
            label='Annualised business case'
            name='business_case_cost'
            value={formatNumber(watch('business_case_cost') - watch('implementation_cost'), 1)}
            iconStart={currency.symbol}
            disabled
          />
        </div>

        <div className='mt-1 flex'>
          <div className='mr-3 w-[219px]'>
            <OptionsBoxes
              title='Priority'
              value={watch('priority')}
              onChange={(value: IntensityLevelEnum) => setValue('priority', value)}
            />
          </div>

          <div className='w-[219px]'>
            <OptionsBoxes
              title='Complexity'
              value={watch('complexity')}
              onChange={(value: IntensityLevelEnum) => setValue('complexity', value)}
            />
          </div>
        </div>

        <div className='mb-8 mt-2'>
          <AttachmentControl caseId={activeCase.id} />
        </div>

        <NavigationBar commentAmount={activeCase.comments.length} />
        <div className='-mx-6 w-[751px] min-w-[751px] border-t border-t-gray-300 bg-gray-100 px-8 py-6'>
          {isCommentsOpen ? (
            <CommentSection comments={activeCase.comments} />
          ) : (
            <ActivityFeedSection caseId={activeCase.id} />
          )}
          <div className='mb-2' />

          <Divider className='-mx-8 w-[751px] min-w-[751px]' />

          <CommentField />
        </div>
        <Divider className='-mx-6 w-[751px] min-w-[751px]' />

        <div className='flex justify-between'>
          <Collaborators caseId={activeCase.id} collaborators={activeCase.collaborators} />
        </div>

        <div className='mt-2 flex gap-4'>
          <Button
            variant='destructive'
            type='button'
            onClick={() => setShowDeleteCaseModal(true)}
            disabled={user.isDataReader}
            wFull
          >
            Delete opportunity
          </Button>

          <Button type='submit' disabled={user.isDataReader} wFull>
            Save changes
          </Button>
        </div>

        <ActionModal
          variant='destructive'
          actionLabel='Delete'
          label='Are you sure you want to delete this case?'
          open={showDeleteCaseModal}
          onClose={() => setShowDeleteCaseModal(false)}
          onAction={onDelete}
        />
      </div>
    </form>
  )
}

export default CaseView
