import { Outlet, useLocation } from 'react-router-dom'

import { Tabs } from '../../../../common/designs'
import { useDashboard, useEnhancedNavigate } from '../../../../common/hooks'
import { AnalysisRouteTitles, AnalysisRoutes } from './constants'

const NAV_OPTIONS = [
  { label: AnalysisRouteTitles.STEPS, value: AnalysisRoutes.STEPS },
  { label: AnalysisRouteTitles.TEAMS, value: AnalysisRoutes.TEAMS },
  { label: AnalysisRouteTitles.TRANSACTIONS, value: AnalysisRoutes.TRANSACTIONS },
  { label: AnalysisRouteTitles.CUSTOM, value: AnalysisRoutes.CUSTOM },
]

const NAV_OPTIONS_PLATFORM_VIEWER = [
  { label: AnalysisRouteTitles.STEPS, value: AnalysisRoutes.STEPS },
  { label: AnalysisRouteTitles.TRANSACTIONS, value: AnalysisRoutes.TRANSACTIONS },
  { label: AnalysisRouteTitles.CUSTOM, value: AnalysisRoutes.CUSTOM },
]

export const SecondLayerAnalysisLayout = () => {
  const location = useLocation()
  const { user } = useDashboard()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const navTabs = user.isPlatformViewer ? NAV_OPTIONS_PLATFORM_VIEWER : NAV_OPTIONS
  let dimension = location.pathname.split('analysis').pop()
  dimension = dimension && dimension[0] === '/' ? dimension.substring(1) : dimension

  const onTabSelected = (val: string) => {
    navigate(`./${val}`)
  }

  return (
    <div className='flex flex-col gap-3'>
      <Tabs size='s' options={navTabs} value={dimension || ''} onChange={onTabSelected} />
      <Outlet />
    </div>
  )
}

export default SecondLayerAnalysisLayout
