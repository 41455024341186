import { useMemo, useState } from 'react'

import { Tabs } from '../../../../../common/designs'
import { UserActivityTabEnum } from '../../types'
import StepUserActivitiesActivity from './StepUserActivitiesActivity'
import StepUserActivitiesCopyPastes from './StepUserActivitiesCopyPastes'
import StepUserActivitiesFieldChange from './StepUserActivitiesFieldChange'

const TAB_OPTIONS = [
  {
    label: 'Copy-Paste Analysis',
    value: UserActivityTabEnum.COPY_PASTE_ANALYSIS,
  },
  {
    label: 'Activity Analysis',
    value: UserActivityTabEnum.ACTIVITY_ANALYSIS,
  },
  {
    label: 'Field Change Analysis',
    value: UserActivityTabEnum.FIELD_CHANGE_ANALYSIS,
  },
]

const StepUserActivities = () => {
  const [activeTab, setActiveTab] = useState<UserActivityTabEnum>(
    UserActivityTabEnum.COPY_PASTE_ANALYSIS,
  )

  const activeView = useMemo(() => {
    switch (activeTab) {
      case UserActivityTabEnum.COPY_PASTE_ANALYSIS:
        return <StepUserActivitiesCopyPastes />

      case UserActivityTabEnum.ACTIVITY_ANALYSIS:
        return <StepUserActivitiesActivity />

      case UserActivityTabEnum.FIELD_CHANGE_ANALYSIS:
        return <StepUserActivitiesFieldChange />

      default:
        return <StepUserActivitiesCopyPastes />
    }
  }, [activeTab])

  return (
    <div className='mt-4 space-y-3 px-1 lg:px-2 xl:px-3'>
      <Tabs
        options={TAB_OPTIONS}
        value={activeTab}
        onChange={setActiveTab as React.Dispatch<React.SetStateAction<string>>}
      />

      {activeView}
    </div>
  )
}

export default StepUserActivities
