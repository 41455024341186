import clsx from 'clsx'
import { AnchorHTMLAttributes, ReactNode } from 'react'

type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

const sizeStyles: { [key in Size]: string } = {
  xs: 'text-xs',
  s: 'text-sm',
  m: 'text-m',
  l: 'text-l',
  xl: 'text-xl',
}

type Props = {
  size?: Size
  className?: string
  children?: string | ReactNode
  underline?: boolean
} & AnchorHTMLAttributes<HTMLAnchorElement>

const Link = ({ size = 'm', className, children, underline, ...rest }: Props) => {
  return (
    <a
      className={clsx(
        underline && 'underline',
        'cursor-pointer font-inter decoration-2 underline-offset-4',
        'text-primary-600 decoration-primary-600 hover:bg-primary-100',
        sizeStyles[size],
        className,
      )}
      {...rest}
    >
      {children}
    </a>
  )
}

export default Link
