import { Text } from '../../designs'
import { formatNumber } from '../../utils/numberFormatting'

interface ItemProps {
  value: string
  label: string
  isLastItem: boolean
}

const VariableCombinationBarChartItem = ({ label, value, isLastItem }: ItemProps) => {
  return (
    <>
      <div className='flex min-w-0 py-2'>
        <div className='min-w-0 flex-auto'>
          <Text size='xs' weight={600} className='text-gray-900'>
            {label}
          </Text>
          <Text size='xs' className='text-gray-500'>
            {value}
          </Text>
        </div>
      </div>
      {isLastItem ? null : <div className='h-full w-0.25 bg-gray-300' />}
    </>
  )
}

interface VariableData {
  label: string
  value: string
}

export interface VariabeCombinationDataEntry {
  variables: VariableData[]
  value: number
}

interface Props {
  data: VariabeCombinationDataEntry[]
  unit?: string
}

const VariableCombinationBarChart = ({ data, unit = '' }: Props) => {
  const maxValue = Math.max(...data.map((item) => item.value)) * 1.1
  return (
    <ul className='divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl'>
      {data.map(({ value, variables }) => (
        <li
          key={JSON.stringify(variables.map(({ value }) => value))}
          className='w-full space-y-2 px-4 py-5 hover:bg-gray-50 sm:px-6'
        >
          <div className='flex justify-between'>
            <div className='xs:gap-1 flex sm:gap-4 md:gap-6 lg:gap-8'>
              {variables.map(({ label, value }, i) => (
                <VariableCombinationBarChartItem
                  key={value}
                  value={value}
                  label={label}
                  isLastItem={i === variables.length - 1}
                />
              ))}
            </div>
            <Text weight={600}>{`${formatNumber(value, 100)} ${unit}`}</Text>
          </div>
          <div
            className='h-8 rounded-r-xl bg-blue-600'
            style={{ width: `${(value / maxValue) * 100}%` }}
          />
        </li>
      ))}
    </ul>
  )
}

export default VariableCombinationBarChart
