import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'

import { useDashboard } from '../hooks'
import { DrawerFocusTransition, DrawerTransition } from './HelperComponents'

const sizeStyles: { [key in Size]: string } = {
  xs: 'max-w-sm',
  s: 'max-w-md',
  m: 'max-w-lg',
  l: 'max-w-xl',
  xl: 'max-w-2xl',
  '2xl': 'max-w-3xl',
  '3xl': 'max-w-4xl',
}

const variantStyles: { [key in Variant]: string } = {
  primary: 'bg-primary-600',
  secondary: 'bg-primary-100',
  white: 'bg-gray-100',
  ghost: 'bg-white',
  destructive: 'bg-red-500 ',
}

const variantLabelStyles: { [key in Variant]: string } = {
  primary: 'text-white',
  secondary: 'text-primary-700',
  white: 'text-gray-900',
  ghost: 'text-gray-900',
  destructive: 'text-white',
}

const variantDescriptionStyles: { [key in Variant]: string } = {
  primary: 'text-primary-300',
  secondary: 'text-primary-400',
  white: 'text-gray-500',
  ghost: 'text-gray-500',
  destructive: 'text-neutral-200',
}

const variantCloseBtnStyles: { [key in Variant]: string } = {
  primary: 'text-primary-200 hover:text-white focus:ring-primary-500',
  secondary: 'text-primary-500 hover:text-primary-700 focus:ring-primary-500',
  white: 'text-gray-400 hover:text-gray-500 focus:ring-primary-500',
  ghost: 'text-gray-400 hover:text-gray-500 focus:ring-primary-500',
  destructive: 'text-neutral-200 hover:text-neutral-300 focus:ring-red-700',
}

type Variant = 'primary' | 'secondary' | 'white' | 'ghost' | 'destructive'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl'

interface Props {
  open: boolean
  onClose: () => void
  label: string
  children: ReactNode

  variant?: Variant
  size?: Size
  className?: string
  description?: string
  footer?: ReactNode
  focus?: boolean // Blurs background to focus on the Drawer.
  hasNoMainNavigationOverlap?: boolean // Adds margin to the top of the Drawer to avoid overlap with the main navigation.
}

const Drawer = (props: Props) => {
  const {
    open,
    onClose,
    label,
    children,

    variant = 'primary',
    size = 'm',
    className,
    description,
    footer,
    focus,
    hasNoMainNavigationOverlap,
  } = props

  const {
    user: { isPlatformViewer },
  } = useDashboard()

  let marginTop = ''
  if (hasNoMainNavigationOverlap) {
    marginTop = isPlatformViewer ? 'mt-12' : 'mt-52 lg:mt-40'
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className={clsx('relative z-10', className)} onClose={onClose}>
        <div className='fixed inset-0' />

        {focus && <DrawerFocusTransition hasNoMainNavigationOverlap={hasNoMainNavigationOverlap} />}

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <DrawerTransition>
                <Dialog.Panel
                  className={clsx(sizeStyles[size], marginTop, 'pointer-events-auto w-screen')}
                >
                  <div className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl'>
                    <div className='flex min-h-0 flex-1 flex-col overflow-hidden pb-6'>
                      <div className={clsx(variantStyles[variant], 'p-6')}>
                        <div className='flex items-start justify-between'>
                          <Dialog.Title
                            className={clsx(variantLabelStyles[variant], 'text-lg font-medium')}
                          >
                            {label}
                          </Dialog.Title>

                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className={clsx(
                                variantCloseBtnStyles[variant],
                                'rounded-md focus:outline-none focus:ring-2 ',
                              )}
                              onClick={onClose}
                            >
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon className='size-6' aria-hidden='true' />
                            </button>
                          </div>
                        </div>

                        {description && (
                          <div className='mt-1'>
                            <p className={clsx(variantDescriptionStyles[variant], 'text-sm')}>
                              {description}
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        className={clsx(
                          variant !== 'ghost' && 'mt-6',
                          'relative flex-1 overflow-y-auto px-6',
                        )}
                      >
                        {children}
                      </div>
                    </div>

                    {footer && <div className='flex shrink-0 justify-end gap-4 p-4'>{footer}</div>}
                  </div>
                </Dialog.Panel>
              </DrawerTransition>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Drawer
