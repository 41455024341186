import { ArrowLeftOnRectangleIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline'
import { UserIcon } from '@heroicons/react/24/solid'

import { Button, Divider, Dropdown, IconButton, Text } from '../../../common/designs'
import { useDashboard, useEnhancedNavigate } from '../../../common/hooks'
import { UserRoleMappingEnum } from '../../../common/types/common'
import { AdminMainPathEnum } from '../../../common/types/routing'
import { capitalizeFirstLetter } from '../../../common/utils/stringFormatting'
import useAuthentication from '../../../features/Authentication/useAuthentication'

const ProfilePopover = () => {
  const { onSignOut } = useAuthentication()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { user } = useDashboard()

  return (
    <Dropdown
      actionAs='div'
      action={
        <IconButton
          variant='ghost'
          size='s'
          icon={<UserIcon />}
          iconClassName='size-6 text-gray-600'
          rounded
        />
      }
      className='mt-1'
    >
      <div className='m-4'>
        <Text weight={600} className='mb-1'>
          {user.username}
        </Text>

        <Text color='lightGray' size='s'>
          {capitalizeFirstLetter(UserRoleMappingEnum[user.roleId]).replace(/_/, ' ')}
        </Text>
      </div>

      {user.isAdmin ? (
        <>
          <Divider />

          <div className='m-2 flex flex-col'>
            <Button
              variant='ghost'
              iconStart={<WrenchScrewdriverIcon />}
              className='!justify-start text-primary-600'
              onClick={() => navigate(AdminMainPathEnum.ROOT)}
            >
              Admin panel
            </Button>
          </div>
        </>
      ) : null}
      <Divider />

      <div className='m-2 flex flex-col'>
        <Button
          variant='ghost'
          iconStart={<ArrowLeftOnRectangleIcon />}
          className='!justify-start text-primary-600'
          onClick={onSignOut}
        >
          Sign out
        </Button>
      </div>
    </Dropdown>
  )
}

export default ProfilePopover

