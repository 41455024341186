import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TAGS_OF_INTEREST } from '../../app/constants'
import { SubSearchParamEnum } from '../../common/types/searchParams'
import { decompressAndDecodeJSON } from '../../common/utils/jsonCompression'
import { DetailLevelEnum, VisualizationModeEnum } from '../Network/types'

const useOptions = () => {
  const [searchParams] = useSearchParams()

  const parseSearchParam = <T extends string>(
    paramName: SubSearchParamEnum,
    defaultValue: T,
  ): T => {
    return (searchParams.get(paramName) as T) ?? defaultValue
  }

  // Initialize visualization mode.
  const [visualizationMode, setVisualizationMode] = useState<VisualizationModeEnum>(
    parseSearchParam(SubSearchParamEnum.VISUALIZATION_MODE, VisualizationModeEnum.NETWORK),
  )

  useEffect(() => {
    const visualizationModeParam = searchParams.get(SubSearchParamEnum.VISUALIZATION_MODE)

    if (visualizationModeParam === VisualizationModeEnum.TABLE) {
      setVisualizationMode(VisualizationModeEnum.TABLE)
    } else {
      setVisualizationMode(VisualizationModeEnum.NETWORK)
    }
  }, [searchParams.get(SubSearchParamEnum.VISUALIZATION_MODE)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize detail level.
  const [detailLevel, setDetailLevel] = useState<DetailLevelEnum>(
    parseSearchParam(SubSearchParamEnum.DETAIL_LEVEL, DetailLevelEnum.APP),
  )

  useEffect(() => {
    const detailLevelParam = searchParams.get(SubSearchParamEnum.DETAIL_LEVEL)

    if (detailLevelParam === DetailLevelEnum.WINDOW) {
      setDetailLevel(DetailLevelEnum.WINDOW)
    } else {
      setDetailLevel(DetailLevelEnum.APP)
    }
  }, [searchParams.get(SubSearchParamEnum.DETAIL_LEVEL)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize tags of interest.
  const tagsOfInterest =
    detailLevel === DetailLevelEnum.WINDOW ? TAGS_OF_INTEREST.window : TAGS_OF_INTEREST.application

  // Initialize node amount.
  const nodeAmount = useMemo(() => {
    return Number(searchParams.get(SubSearchParamEnum.NODE_AMOUNT)) || 25
  }, [searchParams.get(SubSearchParamEnum.NODE_AMOUNT)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize app list.
  const appList = useMemo(() => {
    const encodedAppList = searchParams.get(SubSearchParamEnum.APP_LIST)
    if (encodedAppList) {
      return decompressAndDecodeJSON<string[]>(encodedAppList)
    } else {
      return [] as string[]
    }
  }, [searchParams.get(SubSearchParamEnum.APP_LIST)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize match any tag filters list.
  const matchAnyTagFilters = useMemo(
    () => appList.map((app) => [{ key: 'appname', value: app }]),
    [appList],
  )

  return {
    visualizationMode,
    detailLevel,
    tagsOfInterest,
    nodeAmount,
    appList,
    matchAnyTagFilters,
  }
}

export default useOptions
