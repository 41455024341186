import {
  ArrowUpTrayIcon,
  FolderArrowDownIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { isEqual } from 'lodash'
import { useState } from 'react'

import { Button, Text } from '../../../common/designs'
import { useDashboard } from '../../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { downloadFile } from '../../../common/utils/utils'
import type { ConformanceByVariantMetrics, ConformanceFlowsData } from '../types'
import ConformanceAttachmentModal from './ConformanceAttachmentModal'

interface Props {
  data: ConformanceFlowsData
  variantConformanceMetrics: ConformanceByVariantMetrics[] | undefined
  onOpenModal: (editFlow: ConformanceFlowsData | null) => void
  onDeleteConformanceFlow: (conformanceId: string) => Promise<void>
  isFetchingMetrics: boolean
}

const ConformanceFlowListItem = ({
  data,
  variantConformanceMetrics,
  onOpenModal,
  onDeleteConformanceFlow,
  isFetchingMetrics,
}: Props) => {
  const { user } = useDashboard()
  const [isUploadConfigModalOpen, setIsUploadConfigModalOpen] = useState<boolean>(false)

  const itemMetrics = variantConformanceMetrics?.find((v) => isEqual(v.variant, data.steps))

  const onDownload = () => {
    if (!data.attachment) return
    downloadFile(data.attachment, data.attachment_name)
  }

  return (
    <>
      <div className='w-full rounded-lg bg-white p-1 shadow-md ring-black/5 md:p-2 lg:p-3 xl:p-4'>
        <div className='grid grid-cols-2'>
          <div className='flex flex-col'>
            <Text size='xl' weight={600}>
              {data.conformance_name}
            </Text>
            <ol className='my-3 mr-auto overflow-hidden'>
              {data.steps.map((step, i) => (
                <li key={`${step}-${i}`} className='relative overflow-hidden'>
                  <div className='overflow-hidden border border-gray-200'>
                    <span
                      className='absolute left-0 top-0 h-full w-1 bg-indigo-600'
                      aria-hidden='true'
                    />
                    <span className='flex items-center px-3 py-2 text-sm font-medium'>
                      <span className='shrink-0'>
                        <span className='flex size-8 items-center justify-center rounded-full border-2 border-indigo-600'>
                          <Text size='xs' className='text-indigo-600'>
                            {i + 1}
                          </Text>
                        </span>
                      </span>
                      <Text size='xs' weight={500} className='ml-2 xl:ml-3'>
                        {step}
                      </Text>
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </div>
          {!isFetchingMetrics && (
            <div className='grid grid-cols-2 self-start'>
              <div className='mb-3'>
                <Text size='s' weight={500}>
                  Cases
                </Text>
                <Text weight={500} className='text-primary-600'>
                  {formatNumber(itemMetrics?.total_transactions ?? 0)}
                </Text>
              </div>
              <div>
                <Text size='s' weight={500}>
                  Avg. duration
                </Text>
                <Text weight={500} className=' text-primary-600'>
                  {getBestTimeFormatForSeconds(itemMetrics?.avg_throughput_time_sec ?? 0)}
                </Text>
              </div>
              <div>
                <Text size='s' weight={500}>
                  Work per case
                </Text>
                <Text weight={500} className='text-primary-600'>
                  {getBestTimeFormatForSeconds(itemMetrics?.avg_duration_sec ?? 0)}
                </Text>
              </div>
              <div>
                <Text size='s' weight={500}>
                  Touches per case
                </Text>
                <Text weight={500} className='text-primary-600'>
                  {formatNumber(itemMetrics?.avg_touch_count ?? 0, 10)}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className='flex w-full items-end justify-between'>
          <div className='flex gap-2'>
            <Button
              onClick={() => onOpenModal(data)}
              iconStart={<PencilIcon />}
              size='xs'
              disabled={!user.isAdmin}
            >
              Edit
            </Button>
            <Button
              variant='destructive'
              onClick={() => onDeleteConformanceFlow(data.id)}
              iconStart={<XMarkIcon />}
              size='xs'
              disabled={!user.isAdmin}
            >
              Delete
            </Button>
          </div>

          <div className='flex flex-col gap-2'>
            {data.attachment && (
              <div
                className='flex cursor-pointer items-center gap-1 rounded border-2'
                onClick={onDownload}
              >
                <FolderArrowDownIcon className='h-8 w-6 text-primary-600' />
                <div>
                  <Text size='xs' weight={500}>
                    {data.attachment_name.length > 25
                      ? `${data.attachment_name.substring(0, 22)}...`
                      : data.attachment_name}
                  </Text>
                </div>
              </div>
            )}
            <Button
              size='xs'
              iconStart={<ArrowUpTrayIcon />}
              onClick={() => setIsUploadConfigModalOpen(true)}
            >
              {data.attachment ? 'Change process model' : 'Upload process model'}
            </Button>
          </div>
        </div>
      </div>

      <ConformanceAttachmentModal
        isOpen={isUploadConfigModalOpen}
        onClose={() => setIsUploadConfigModalOpen(false)}
        data={data}
      />
    </>
  )
}

export default ConformanceFlowListItem
