import { ChartOptions } from 'chart.js'
import { createRef, useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { PRIMARY_600_HEX } from '../../../../app/theme'
import { getBestTimeFormatTransformationForSeconds } from '../../../../common/utils/numberFormatting'
import { cssCalcPixels } from '../../../../common/utils/stringFormatting'
import type { NormalDistributionData } from '../../types'
import { getLineAnnotiation } from './TransactionNormalDistributionHelpers'

interface Props {
  data: NormalDistributionData
}

const CHART_HEIGHT = '400px'
const CHART_WIDTH = '100%'

export const TransactionNormalDistributionChart = ({ data }: Props) => {
  const [chartHeight, setChartHeight] = useState<string>('0px')
  const legendRef = createRef<HTMLDivElement>()
  const dataSelectorRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const extraSpace =
      (legendRef.current?.clientHeight || 0) + (dataSelectorRef.current?.clientHeight || 0)
    setChartHeight(cssCalcPixels(CHART_HEIGHT, extraSpace, '-'))
  }, [legendRef, dataSelectorRef, setChartHeight])

  const { chartData, options } = useMemo(() => {
    const labels = [] as number[]
    const yValues = [] as number[]

    const { divider, unit } = getBestTimeFormatTransformationForSeconds(data.avg_sec)
    const roundTime = (time: number) => Math.round(time * 100) / 100

    data.normal_distribution_points.forEach((point) => {
      labels.push(roundTime(point.x_sec / divider))
      yValues.push(point.y_percentage)
    })

    const avgLine = getLineAnnotiation(
      data.avg_sec,
      `Average: ${roundTime(data.avg_sec / divider)} ${unit}`,
      data.normal_distribution_points,
      yValues,
    )
    const deviationLine = getLineAnnotiation(
      data.avg_sec + data.std_dev_sec,
      `+1 σ: ${roundTime((data.avg_sec + data.std_dev_sec) / divider)} ${unit}`,
      data.normal_distribution_points,
      yValues,
    )
    const deviationLineNegative = getLineAnnotiation(
      data.avg_sec - data.std_dev_sec,
      `-1 σ: ${roundTime((data.avg_sec - data.std_dev_sec) / divider)} ${unit}`,
      data.normal_distribution_points,
      yValues,
    )

    const yMaxValue = Math.max(...data.normal_distribution_points.map((d) => d.y_percentage)) * 1.1

    const options = {
      scales: {
        y: {
          suggestedMax: yMaxValue,
          ticks: {
            maxTicksLimit: 15,
            font: {
              family: 'Inter',
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (val: number) {
              return `${this.getLabelForValue(val)} ${unit}`
            },
            font: {
              family: 'Inter',
            },
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: () => '',
            title: (tooltipItems: any[]) => `${tooltipItems[0].label} ${unit}`,
          },
        },
        annotation: {
          annotations: {
            avgLine,
            deviationLine,
            deviationLineNegative,
          },
        },
      },
    } as ChartOptions<'line'>

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Normal Distribution',
          data: yValues,
          fill: true,
          backgroundColor: 'rgba(88, 80, 236, 0.2)', // PRIMARY_600_HEX with alpha
          borderColor: PRIMARY_600_HEX,
        },
      ],
    }

    return {
      chartData,
      options,
    }
  }, [data])

  return (
    <>
      {chartHeight !== '0px' ? (
        <div style={{ height: CHART_HEIGHT, width: CHART_WIDTH }}>
          <div style={{ height: chartHeight, width: CHART_WIDTH }}>
            <Line data={chartData} options={options} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TransactionNormalDistributionChart
