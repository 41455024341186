import { useState } from 'react'

import {
  useGetProcessConformanceMetricsQuery,
  useGetProcessConformanceTrendsQuery,
} from '../../../app/apiSlice'
import { InlineLoader, Stat } from '../../../common/components'
import { Tabs, Text } from '../../../common/designs'
import { useQueryFilters } from '../../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { ConformanceTypeEnum } from '../types'
import ConformanceFlowList from './ConformanceFlowList'
import ConformanceTrends from './ConformanceTrends'

const tabOptions = [
  {
    label: 'Task conformance',
    value: ConformanceTypeEnum.STEP_FLOW,
  },
  {
    label: 'State conformance',
    value: ConformanceTypeEnum.STATE_FLOW,
  },
]

export enum SelectedVisualizationEnum {
  METRICS = 'METRICS',
  TRENDS = 'TRENDS',
}

const Conformance = () => {
  const { processFilters: filters, processId } = useQueryFilters()
  const [selectedConformanceType, setSelectedConformanceType] = useState<ConformanceTypeEnum>(
    ConformanceTypeEnum.STEP_FLOW,
  )
  const [selectedVisualization, setSelectedVisualization] = useState<SelectedVisualizationEnum>(
    SelectedVisualizationEnum.METRICS,
  )

  const { data: metricsData, isFetching: isFetchingMetricsData } =
    useGetProcessConformanceMetricsQuery(
      {
        processId,
        filters,
        conformance_type: selectedConformanceType,
      },
      { skip: selectedVisualization !== SelectedVisualizationEnum.METRICS },
    )
  const { data: trendData, isFetching: isFetchingTrendData } = useGetProcessConformanceTrendsQuery(
    {
      processId,
      filters,
      conformance_type: selectedConformanceType,
    },
    { skip: selectedVisualization !== SelectedVisualizationEnum.TRENDS },
  )

  const { conformance_percentage, avg_throughput_time_sec, avg_touch_count, avg_duration_sec } =
    metricsData || {}

  const metricList = [
    {
      label: 'Conformance %',
      value: `${formatNumber(conformance_percentage || 0)} %`,
    },
    {
      label: 'Avg. duration',
      value: `${getBestTimeFormatForSeconds(avg_throughput_time_sec || 0)}`,
    },
    {
      label: 'Work per case',
      value: `${getBestTimeFormatForSeconds(avg_duration_sec || 0)}`,
    },
    {
      label: 'Touches per case',
      value: `${formatNumber(avg_touch_count || 0)}`,
    },
  ]

  const onChangeVisualizationMode = (value: string) => {
    setSelectedVisualization(value as SelectedVisualizationEnum)
  }

  const metricVisualization = isFetchingMetricsData ? (
    <InlineLoader />
  ) : (
    <div className='mb-6 flex w-full gap-3'>
      {metricList.map((metricData) => (
        <Stat className='w-96' key={metricData.label} data={metricData} />
      ))}
    </div>
  )

  const trendVisualization = isFetchingTrendData ? (
    <InlineLoader />
  ) : (
    trendData && <ConformanceTrends data={trendData} />
  )

  return (
    <div className='space-y-3 px-1 lg:px-2 xl:px-3'>
      <div className='flex items-start justify-between'>
        <Text size='2xl' weight={700}>
          Conformance
        </Text>
      </div>

      <Text>
        Manage conformance by adding the definitions for intended process flows. You can add new
        conformance variants by defining them manually on this page or by selecting an existing
        variant in the Variants view.
      </Text>

      <Tabs
        options={tabOptions}
        value={selectedConformanceType}
        onChange={setSelectedConformanceType as React.Dispatch<React.SetStateAction<string>>}
      />

      <div className='flex justify-end'>
        <Tabs
          size='xs'
          variant='secondary'
          value={selectedVisualization}
          options={[
            { label: 'Metrics', value: SelectedVisualizationEnum.METRICS },
            { label: 'Trends', value: SelectedVisualizationEnum.TRENDS },
          ]}
          onChange={onChangeVisualizationMode}
        />
      </div>

      {selectedVisualization === SelectedVisualizationEnum.METRICS
        ? metricVisualization
        : trendVisualization}

      <ConformanceFlowList selectedConformanceType={selectedConformanceType} />
    </div>
  )
}

export default Conformance

