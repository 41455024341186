import { ContentDisplay } from '../../common/components'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { InputField, Modal, Select, Text, Toggle } from '../../common/designs'

interface Props {
  onClose: () => void
  application: AppTestCaseGroup | null
}

const AppTemplateModal = ({ onClose, application }: Props) => {
  return (
    <Modal label='Create a case' open={Boolean(application)} onClose={onClose} size='5xl'>
      {application && (
        <ContentDisplay className='space-y-2 p-4'>
          <InputField
            label='Application name'
            size='s'
            value={application.appDashboardConfigs.name}
            disabled
            onChange={() => null}
          />
          <Select
            label='Category'
            size='s'
            options={[
              {
                label: application.appDashboardConfigs.category,
                value: application.appDashboardConfigs.category,
              },
            ]}
            value={application.appDashboardConfigs.category}
            disabled
            onChange={() => null}
          />
          <Text>Category: {application.appDashboardConfigs.category}</Text>
          <Toggle
            size='xs'
            label='Is PDF Application'
            value={application.appDashboardConfigs.is_pdf_application}
            disabled
            onChange={() => null}
          />
        </ContentDisplay>
      )}
    </Modal>
  )
}

export default AppTemplateModal

