import { EnvEnum } from '../common/types/common'

export const AZ_STORAGE_ACCOUNT_URL_DASHBOARD = process.env
  .REACT_APP_AZ_STORAGE_ACCOUNT_URL_DASHBOARD as string
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as EnvEnum
export const MICROSOFT_ENTRA_CLIENT_ID = process.env.REACT_APP_MICROSOFT_ENTRA_CLIENT_ID as string
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string
export const WEB_APP_URL_DASHBOARD_BACKEND = process.env
  .REACT_APP_WEB_APP_URL_DASHBOARD_BACKEND as string
export const WEB_APP_URL_DASHBOARD_FRONTEND = process.env
  .REACT_APP_WEB_APP_URL_DASHBOARD_FRONTEND as string

