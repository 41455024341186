export enum RecommendationCategoryEnum {
  ALL = 'All',
  SYSTEM = 'System',
  TASK = 'Task',
  PROCESS = 'Process',
}

export enum RecommendationSortOptionsEnum {
  IMPROVEMENT_POTENTIAL = 'Improvement potential',
  IMPACT_SCORE = 'Impact score',
  THROUGHPUT_TIME = 'Duration',
  EVENT_COUNT = 'Event count',
}

export enum RecommendationTypeEnum {
  COPY_PASTE = 'copy_paste',
  NAVIGATION = 'navigation',
  APP_USAGE_LEAD = 'app_usage_lead',
  APP_ACTIVITY_LEAD = 'app_activity_lead',
  PROCESS_ACTIVITES = 'process_activities',
}

export enum SelectRecommendationTrendVariableEnum {
  ANNUAL_COST = 'Active work annual cost',
  IMPACT_SCORE = 'Impact score',
  THROUGHPUT = 'Duration',
  EVENT_COUNT = 'Event count',
}

