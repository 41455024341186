import { TaskFilterLabelEnum, TaskFilterNameEnum } from './types'

export const filterLabelToKeyMapper = {
  [TaskFilterLabelEnum.MIN_TASK_STEP_AMOUNT]: TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT,
  [TaskFilterLabelEnum.START_APP]: TaskFilterNameEnum.START_APP,
  [TaskFilterLabelEnum.START_WINDOW]: TaskFilterNameEnum.START_WINDOW,
  [TaskFilterLabelEnum.END_APP]: TaskFilterNameEnum.END_APP,
  [TaskFilterLabelEnum.END_WINDOW]: TaskFilterNameEnum.END_WINDOW,
  [TaskFilterLabelEnum.APPS_IN_TASK]: TaskFilterNameEnum.APPS_IN_TASK,
  [TaskFilterLabelEnum.WINDOWS_IN_TASK]: TaskFilterNameEnum.WINDOWS_IN_TASK,
  [TaskFilterLabelEnum.MAIN_APP]: TaskFilterNameEnum.MAIN_APP,
  [TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_APP]: TaskFilterNameEnum.DATA_TRANSFER_SOURCE_APP,
  [TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_WINDOW]: TaskFilterNameEnum.DATA_TRANSFER_SOURCE_WINDOW,
  [TaskFilterLabelEnum.DATA_TRANSFER_TARGET_APP]: TaskFilterNameEnum.DATA_TRANSFER_TARGET_APP,
  [TaskFilterLabelEnum.DATA_TRANSFER_TARGET_WINDOW]: TaskFilterNameEnum.DATA_TRANSFER_TARGET_WINDOW,
  [TaskFilterLabelEnum.PROCESS_NAME]: TaskFilterNameEnum.PROCESS_NAME,
  [TaskFilterLabelEnum.PROCESS_STEP_NAME]: TaskFilterNameEnum.PROCESS_STEP_NAME,
}

export const filterKeyToLabelMapper = {
  [TaskFilterNameEnum.MIN_TASK_STEP_AMOUNT]: TaskFilterLabelEnum.MIN_TASK_STEP_AMOUNT,
  [TaskFilterNameEnum.START_APP]: TaskFilterLabelEnum.START_APP,
  [TaskFilterNameEnum.START_WINDOW]: TaskFilterLabelEnum.START_WINDOW,
  [TaskFilterNameEnum.END_APP]: TaskFilterLabelEnum.END_APP,
  [TaskFilterNameEnum.END_WINDOW]: TaskFilterLabelEnum.END_WINDOW,
  [TaskFilterNameEnum.APPS_IN_TASK]: TaskFilterLabelEnum.APPS_IN_TASK,
  [TaskFilterNameEnum.WINDOWS_IN_TASK]: TaskFilterLabelEnum.WINDOWS_IN_TASK,
  [TaskFilterNameEnum.MAIN_APP]: TaskFilterLabelEnum.MAIN_APP,
  [TaskFilterNameEnum.DATA_TRANSFER_SOURCE_APP]: TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_APP,
  [TaskFilterNameEnum.DATA_TRANSFER_SOURCE_WINDOW]: TaskFilterLabelEnum.DATA_TRANSFER_SOURCE_WINDOW,
  [TaskFilterNameEnum.DATA_TRANSFER_TARGET_APP]: TaskFilterLabelEnum.DATA_TRANSFER_TARGET_APP,
  [TaskFilterNameEnum.DATA_TRANSFER_TARGET_WINDOW]: TaskFilterLabelEnum.DATA_TRANSFER_TARGET_WINDOW,
  [TaskFilterNameEnum.PROCESS_NAME]: TaskFilterLabelEnum.PROCESS_NAME,
  [TaskFilterNameEnum.PROCESS_STEP_NAME]: TaskFilterLabelEnum.PROCESS_STEP_NAME,
}
