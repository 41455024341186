import { PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { useGetProcessCustomGroupByDimensionsQuery } from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import { Button, Link, Modal, Table } from '../../common/designs'
import type { CustomDimensionData } from '../Processes/ProcessMining/Analysis/types'
import type { ProcessGeneralInfo } from '../Processes/types'
import DimensionForm from './DimensionForm'

interface Props {
  isOpen: boolean
  onClose: () => void
  process: ProcessGeneralInfo
}

const CustomiseDimensionsModal = ({ isOpen, onClose, process }: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [dimension, setDimension] = useState<CustomDimensionData | null>(null)

  const { data } = useGetProcessCustomGroupByDimensionsQuery(process.id)

  return (
    <Modal label='Custom Dimensions' open={isOpen} onClose={onClose} size='xl'>
      {!data ? (
        <InlineLoader />
      ) : (
        <Table
          label='Dimensions'
          action={
            <Button iconStart={<PlusIcon />} onClick={() => setIsAddModalOpen(true)}>
              Add a custom dimension
            </Button>
          }
          head={['Name', 'Key', 'Type', 'Edit']}
          body={
            data.map((d) => [
              d.dimension_name,
              d.dimension_key,
              d.tag_filtering_logic,
              <Link
                key={d.id}
                onClick={() => {
                  setDimension(d)
                  setIsEditModalOpen(true)
                }}
              >
                Edit
              </Link>,
            ]) ?? []
          }
        />
      )}

      <Modal
        label='Add a custom dimension'
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      >
        <DimensionForm process={process} onClose={() => setIsAddModalOpen(false)} />
      </Modal>

      <Modal
        label='Edit a custom dimension'
        open={isEditModalOpen && Boolean(dimension)}
        onClose={() => setIsEditModalOpen(false)}
      >
        <DimensionForm
          process={process}
          onClose={() => setIsEditModalOpen(false)}
          editedData={dimension}
        />
      </Modal>
    </Modal>
  )
}

export default CustomiseDimensionsModal
