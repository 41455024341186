import { LinkIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { Button, Modal, Text } from '../../../common/designs'
import { LINK_SUPPORT, LINK_USER_DOCUMENTATION } from '../../constants'

const HelpButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant='ghost'
        size='s'
        iconStart={<QuestionMarkCircleIcon />}
        onClick={() => setIsModalOpen(true)}
        rounded
      >
        Help
      </Button>

      <Modal label='Need help?' open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Text>If you need help, please check the documentation or contact our support team.</Text>

        <div className='mt-4 flex gap-4'>
          <Button
            variant='secondary'
            onClick={() => window.open(LINK_USER_DOCUMENTATION, '_blank')}
            iconStart={<LinkIcon />}
            wFull
          >
            User documentation
          </Button>

          <Button
            onClick={() => window.open(LINK_SUPPORT, '_blank')}
            iconStart={<QuestionMarkCircleIcon />}
            wFull
          >
            Contact support
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default HelpButton
