import { useState } from 'react'

import { Divider, Select, Text } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import ConnectivityGraph from './ConnectivityGraph'

const AgentMonitoring = () => {
  const { company, teams } = useDashboard()

  const [daysSelected, setDaysSelected] = useState<number>(7)

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center gap-4'>
        <Text variant='h4' size='l'>
          Computer Connectivity
        </Text>

        <Select
          value={daysSelected}
          options={[
            { label: 'Last 3 Days', value: 3 },
            { label: 'Last 7 Days', value: 7 },
            { label: 'Last 14 Days', value: 14 },
            { label: 'Last 30 Days', value: 30 },
            { label: 'Last 60 Days', value: 60 },
            { label: 'Last 90 Days', value: 90 },
          ]}
          onChange={(days: number) => setDaysSelected(days)}
        />
      </div>

      <div className='flex w-full flex-wrap'>
        <div className='m-2 w-full'>
          <ConnectivityGraph
            title='All Computers In The Company'
            daysSelected={daysSelected}
            expectedAgentAmount={company.agentInstallationTarget}
          />
        </div>

        <Divider className='my-8' />

        {Object.values(teams).map((team) => (
          <div key={team.id} className='w-full p-2 2xl:w-1/2 5xl:w-1/3 '>
            <ConnectivityGraph
              title={team.name}
              team={team}
              daysSelected={daysSelected}
              expectedAgentAmount={team.expectedComputerCount ?? null}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgentMonitoring
