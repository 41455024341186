import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import enGB from 'date-fns/locale/en-GB'
import DatePicker from 'react-datepicker'
import type { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { useDashboard } from '../hooks'
import { GLOBAL_DATE_FORMAT } from '../types/datetime'
import { IconButton } from './'

const variantStyle = 'cursor-pointer rounded-md border-gray-300 font-inter text-gray-900'

interface Props extends ReactDatePickerProps {
  overrideWidth?: string
  onClear?: (val: Date | null) => void
}

const InputCalendar = (props: Props) => {
  const { selected, onChange, minDate, maxDate, className, onClear, ...rest } = props
  const { dataDate } = useDashboard()

  const overrideWidth = 'w-[144px]'

  return (
    <div className={clsx('flex', overrideWidth)}>
      <DatePicker
        selected={selected ?? undefined}
        minDate={minDate ?? new Date(dataDate.min)}
        maxDate={maxDate ?? new Date(dataDate.max)}
        onChange={onChange}
        className={clsx(variantStyle, overrideWidth, className)}
        dateFormat={GLOBAL_DATE_FORMAT}
        locale={enGB}
        {...rest}
      />

      {onClear && (
        <IconButton
          variant='white'
          size='xs'
          icon={<XMarkIcon />}
          onClick={onClear as any}
          className='relative right-[36px] top-[6px]'
        />
      )}
    </div>
  )
}

export default InputCalendar
