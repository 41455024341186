import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, InputField, Modal } from '../../../designs'

const formSchema = yup
  .object({ customName: yup.string().max(400, 'Name cannot be over 400 characters long') })
  .required()

export interface EditFavoriteChartModalInputs {
  customName?: string | undefined
}

interface Props {
  open: boolean
  onClose: () => void
  onUpdate: (data: EditFavoriteChartModalInputs) => void
  initialName: string
}

const EditFavoriteChartModal = ({ open, onClose, onUpdate, initialName }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<EditFavoriteChartModalInputs>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      customName: initialName,
    },
  })

  const onSubmit: SubmitHandler<EditFavoriteChartModalInputs> = (
    data: EditFavoriteChartModalInputs,
  ) => {
    onUpdate(data)
    onClose()
  }

  return (
    <Modal label='Edit favorite' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        <Controller
          name='customName'
          control={control}
          render={({ field }) => (
            <InputField label='Name tag' error={errors.customName?.message} {...field} />
          )}
        />

        <div className='flex justify-end gap-4'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button type='submit'>Update</Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditFavoriteChartModal
