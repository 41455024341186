import { sub } from 'date-fns'
import { useEffect, useState } from 'react'

import { useGetPerformanceReadingsQuery } from '../../app/apiSlice'
import { Select, Text } from '../../common/designs'
import UsageDistribution from './UsageDistribution'
import UsageHistogram from './UsageHistogram'
import { PerformanceReadingTypeEnum } from './types'

const PerformanceMonitoring = () => {
  const [daysSelected, setDaysSelected] = useState<number>(7)
  const [timePeriod, setTimePeriod] = useState<{
    start_timestamp: string
    end_timestamp: string
  } | null>(null)

  const { data: cpuData, isFetching: isLoadingCpuData } = useGetPerformanceReadingsQuery(
    {
      start_timestamp: timePeriod?.start_timestamp ?? '',
      end_timestamp: timePeriod?.end_timestamp ?? '',
      reading_type: PerformanceReadingTypeEnum.CPU,
    },
    { skip: !timePeriod },
  )
  const { data: memoryData, isFetching: isLoadingMemoryData } = useGetPerformanceReadingsQuery(
    {
      start_timestamp: timePeriod?.start_timestamp ?? '',
      end_timestamp: timePeriod?.end_timestamp ?? '',
      reading_type: PerformanceReadingTypeEnum.MEMORY,
    },
    { skip: !timePeriod },
  )

  useEffect(() => {
    const today = new Date() // Defaults time to host computer!

    setTimePeriod({
      start_timestamp: sub(today, { days: daysSelected - 1 }).toISOString(),
      end_timestamp: today.toISOString(),
    })
  }, [daysSelected])

  return (
    <div>
      <div className='flex items-center gap-4'>
        <Text variant='h4' size='l'>
          Performance Readings
        </Text>

        <Select
          value={daysSelected}
          options={[
            { label: 'Last 3 Days', value: 3 },
            { label: 'Last 7 Days', value: 7 },
            { label: 'Last 14 Days', value: 14 },
            { label: 'Last 30 Days', value: 30 },
            { label: 'Last 60 Days', value: 60 },
            { label: 'Last 90 Days', value: 90 },
          ]}
          onChange={(days: number) => setDaysSelected(days)}
        />
      </div>

      <div className='flex w-full flex-wrap '>
        <div className='w-full space-y-4 p-2 3xl:w-1/2'>
          <UsageHistogram
            unit={PerformanceReadingTypeEnum.CPU}
            data={cpuData}
            isLoading={isLoadingCpuData}
          />

          <UsageDistribution
            unit={PerformanceReadingTypeEnum.CPU}
            data={cpuData}
            isLoading={isLoadingCpuData}
          />
        </div>

        <div className='w-full space-y-4 p-2 3xl:w-1/2'>
          <UsageHistogram
            unit={PerformanceReadingTypeEnum.MEMORY}
            data={memoryData}
            isLoading={isLoadingMemoryData}
          />

          <UsageDistribution
            unit={PerformanceReadingTypeEnum.MEMORY}
            data={memoryData}
            isLoading={isLoadingMemoryData}
          />
        </div>
      </div>
    </div>
  )
}

export default PerformanceMonitoring
