import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { createRef, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { formatNumber } from '../../utils/numberFormatting'
import ChartLegend from './ChartLegend'
import { getBarchartOptions } from './barChartOptions'
import { groupedDataToGroupedChartData } from './dataToChartData'
import type { GroupedBarChartDataEntry, LegendData } from './types'

interface Props {
  data: GroupedBarChartDataEntry[]
  unit: string
  visibleDataAmount?: number
  height?: string
  width?: string
  onClick?: ((groupName: string, label: string) => void) | undefined
  tooltipCallback?: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => string | string[]
  yTicksCallback?: (value: string | number) => string
  borderRadius?: number
  tickAmount?: number
  hideGrid?: boolean
}

const GroupedBarChart = (props: Props) => {
  const {
    data,
    unit,
    visibleDataAmount,
    height = '400px',
    width = '100%',
    onClick,
    tooltipCallback,
    yTicksCallback,
    borderRadius = 4,
    tickAmount,
    hideGrid,
  } = props

  const [chartHeight, setChartHeight] = useState<string>('0px')

  const legendRef = createRef<HTMLDivElement>()
  const dataSelectorRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const extraSpace =
      (legendRef.current?.clientHeight || 0) + (dataSelectorRef.current?.clientHeight || 0)
    setChartHeight(`calc(${height} - ${extraSpace}px)`)
  }, [legendRef, dataSelectorRef, height, setChartHeight])

  const yMaxValue = useMemo(() => {
    const allValues = data.map((d) => d.data).flat(1)
    return Math.round(Math.max(...allValues.map((d) => d.value)) * 1.1)
  }, [data])

  const { chartData, legendData } = useMemo(() => {
    const chartData = groupedDataToGroupedChartData(data, borderRadius, visibleDataAmount)

    const legendData: LegendData[] = []
    chartData.datasets.forEach(({ label, backgroundColor }) =>
      legendData.push({
        label: label ?? '',
        color: (backgroundColor ?? '') as string,
      }),
    )

    return { chartData, legendData }
  }, [data, borderRadius, visibleDataAmount])

  const defaultTooltipCallback = (tooltipItem: any) => {
    return `${tooltipItem.dataset.label}: ${formatNumber(tooltipItem.raw, 10)} ${unit}`
  }

  const barChartOnClick = (el: any) => {
    const groupName = chartData.datasets[el.datasetIndex].label as string
    const label = (chartData.labels ? chartData.labels[el.index] : '') as string
    if (onClick) onClick(groupName, label)
  }

  const options = getBarchartOptions({
    unit,
    yMaxValue,
    onClick: onClick && barChartOnClick,
    tooltipCallback: tooltipCallback ?? defaultTooltipCallback,
    yTicksCallback,
    tickAmount,
    hideGrid,
    xStacked: false,
  })

  return (
    <div style={{ height, width }}>
      <div style={{ height: chartHeight, width }}>
        <Bar data={chartData} options={options} />
      </div>
      {legendData ? <ChartLegend ref={legendRef} data={legendData} /> : null}
    </div>
  )
}

export default GroupedBarChart
