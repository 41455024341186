import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetTaskVariantsQuery } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { Button, Divider } from '../../common/designs'
import { useAppSelector, useQueryFilters } from '../../common/hooks'
import { VariantVisualizationLevelEnum } from '../Processes/ProcessMining/Variants/types'
import { useSelectedVariantsIndicies } from '../Processes/ProcessMining/Variants/useSelectedVariantsIndicies'
import SortVariantsActions from '../Processes/VariantSelectionDrawers/SortVariantsActions'
import VariantAmountActions from '../Processes/VariantSelectionDrawers/VariantAmountActions'
import VariantSelectBox from '../Processes/VariantSelectionDrawers/VariantSelectBox'
import TransactionSelectionContent from '../Processes/VariantSelectionDrawers/VariantSelectionContent'
import {
  getTransactionFlowMetricLabel,
  getTransactionFlowMetricValue,
  windowFlowOrderByOptions,
} from '../Processes/VariantSelectionDrawers/sortValueMappers'
import { selectVariantOrder } from '../Processes/processesSlice'
import { useGetTaskFiltersFromSearch } from './useGetTaskFiltersFromSearch'

const TaskVariantFilter = () => {
  const { min_date, max_date } = useQueryFilters()
  const activeTaskFilters = useGetTaskFiltersFromSearch()
  const paramData = useParams()
  const variantOrder = useAppSelector(selectVariantOrder)
  const { windowLevelVariantIndices: selectedVariants, updateSelectedVariantIndicies } =
    useSelectedVariantsIndicies()

  const [shownVariants, setShownVariants] = useState<number>(0)

  const [selectedVariantsForUpdate, setSelectedVariantsForUpdate] = useState<number[]>([
    ...selectedVariants,
  ])

  const { data, isFetching } = useGetTaskVariantsQuery({
    filters: { ...activeTaskFilters, min_date, max_date },
    task_name: paramData['*']?.split('/')[1],
    selected_variants: selectedVariants,
    variant_order: variantOrder,
    is_task_path_visualization: selectedVariants.length === 1,
  })

  useEffect(() => {
    // Only set shown variants when drawer is opened for the first time.
    shownVariants < 50 && setShownVariants(50)
  }, [shownVariants])

  const variantMetricLabel = getTransactionFlowMetricLabel(variantOrder.variable)

  if (!data || isFetching) return <Loader />

  const variantList = data.variant_list
  const totalTransactionAmount = data.total_transactions

  return (
    <>
      <VariantAmountActions
        totalVariantAmount={variantList.length}
        setSelectedVariantsForUpdate={setSelectedVariantsForUpdate}
        onUpdate={() =>
          updateSelectedVariantIndicies(
            selectedVariantsForUpdate,
            VariantVisualizationLevelEnum.WINDOW,
          )
        }
      />

      <SortVariantsActions orderByOptions={windowFlowOrderByOptions} />

      <Divider className='my-4' />

      <div>
        {variantList?.slice(0, shownVariants).map((variant, i) => (
          <VariantSelectBox
            isActive={selectedVariantsForUpdate.includes(i)}
            key={i}
            variantSteps={variant.steps}
            variantIndex={i}
            selectedVariantsForUpdate={selectedVariantsForUpdate}
            setSelectedVariantsForUpdate={setSelectedVariantsForUpdate}
          >
            <TransactionSelectionContent
              index={i}
              variantTransactionAmount={variant.transactions}
              totalTransactionAmount={totalTransactionAmount}
              additionalMetricLabel={variantMetricLabel}
              additionalMetricValue={getTransactionFlowMetricValue(variantOrder.variable, variant)}
              isConformanceFlow={variant.is_conformance_flow}
            />
          </VariantSelectBox>
        ))}

        {shownVariants < variantList.length && (
          <Button variant='white' onClick={() => setShownVariants(shownVariants + 50)} wFull>
            Show more
          </Button>
        )}
      </div>
    </>
  )
}

export default TaskVariantFilter
