import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { format, isWithinInterval } from 'date-fns'
import { DropResult } from 'react-beautiful-dnd'
import { createSelector } from 'reselect'

import type { RootState } from '../../app/store'
import type { SimpleDate } from '../../common/types/datetime'
import { GLOBAL_DATE_FORMAT } from '../../common/types/datetime'
import { ensure } from '../../common/types/typeGuards'
import { ActiveDateButtonEnum, LaneTypeEnum } from './enums'
import type {
  Case,
  CaseEntities,
  Collaborator,
  Comment,
  EnhancedState,
  Kanban,
  Lane,
  LaneEntities,
  Like,
  State,
  TimePeriod,
  User,
} from './types'

// ! STATES
const initialState: State = {
  active: {
    caseId: null,
  },
  view: {
    kanban: {} as Kanban,
    lanes: {
      ids: [],
      entities: {} as LaneEntities,
    },
    cases: {
      ids: [],
      entities: {} as CaseEntities,
    },
    users: [],
  },
  filters: {
    timePeriod: null,
    collaboratorIds: [],
    categoryIds: [],
    statusIds: [],
  },
  utils: {
    isMetricOpen: false,
    isCommentsOpen: true,
    activeDateButton: ActiveDateButtonEnum.NONE,
  },
}

const enhancedInitialState: EnhancedState = {
  ...initialState,
  snapshot: initialState,
}

// ! SLICE
export const casesSlice = createSlice({
  name: 'cases',
  initialState: enhancedInitialState,
  reducers: {
    // ** Active ** //
    setActiveCaseId: (state, action: PayloadAction<number | null>) => {
      state.utils.isMetricOpen = false
      state.active.caseId = action.payload
    },

    // ** View ** //
    setupKanban: (state, action: PayloadAction<any>) => {
      state.view.kanban = action.payload.kanban
      state.view.lanes = action.payload.lanes
      state.view.cases = action.payload.cases
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.view.users = action.payload
    },
    updateCase: (state, action: PayloadAction<Case>) => {
      state.view.cases.entities[action.payload.id] = action.payload
    },
    addComment: (state, action: PayloadAction<Comment>) => {
      const activeCaseId = state.active.caseId
      state.view.cases.entities[Number(activeCaseId)].comments = [
        action.payload,
        ...state.view.cases.entities[Number(activeCaseId)].comments,
      ]
    },
    addLike: (state, action: PayloadAction<{ caseId: number; like: Like }>) => {
      const { caseId, like } = action.payload
      state.view.cases.entities[Number(caseId)].likes.push(like)
    },
    changeCaseOrder: (state, action: PayloadAction<DropResult>) => {
      const { source, destination } = action.payload
      if (!destination) return

      const sourceLane = [...state.view.kanban[source.droppableId]]
      const destinationLane = [...state.view.kanban[destination.droppableId]]
      const kanban = { ...state.view.kanban }

      if (source.droppableId === destination.droppableId) {
        // Reorder within same lane
        const [removed] = sourceLane.splice(source.index, 1)
        sourceLane.splice(destination.index, 0, removed)

        kanban[source.droppableId] = sourceLane
      } else {
        // Reorder with different lanes
        const [removed] = sourceLane.splice(source.index, 1)
        destinationLane.splice(destination.index, 0, removed)

        kanban[source.droppableId] = sourceLane
        kanban[destination.droppableId] = destinationLane

        // Update case isDiscarded status
        const isDiscardedLane =
          state.view.lanes.entities[destination.droppableId].type === LaneTypeEnum.DISCARD
        if (isDiscardedLane) {
          state.view.cases.entities[removed].is_discarded = true
        } else {
          state.view.cases.entities[removed].is_discarded = false
        }
      }
      // Set new kanban with case orders updated
      state.view.kanban = kanban
    },
    removeLike: (state, action: PayloadAction<{ caseId: number; likeId: number }>) => {
      const { caseId, likeId } = action.payload
      const likes = state.view.cases.entities[caseId].likes
      const likeIndex = likes.findIndex((l) => l.id === likeId)
      likes.splice(likeIndex, 1)
    },
    addCollaborator: (
      state,
      action: PayloadAction<{ caseId: number; collaborator: Collaborator }>,
    ) => {
      const { caseId, collaborator } = action.payload
      state.view.cases.entities[Number(caseId)].collaborators.push(collaborator)
    },
    removeCollaborator: (
      state,
      action: PayloadAction<{ caseId: number; collaboratorId: number }>,
    ) => {
      const { caseId, collaboratorId } = action.payload
      const collaborators = state.view.cases.entities[caseId].collaborators
      const likeIndex = collaborators.findIndex((l) => l.id === collaboratorId)
      collaborators.splice(likeIndex, 1)
    },
    deleteCase: (state, action: PayloadAction<number>) => {
      state.view.cases.ids = state.view.cases.ids.filter((id) => id !== action.payload)
      delete state.view.cases.entities[action.payload]
    },

    // ** Filters ** //
    setFilterTimePeriod: (state, action: PayloadAction<TimePeriod | null>) => {
      state.filters.timePeriod = action.payload
    },
    setFilterTimePeriodStart: (state, action: PayloadAction<SimpleDate>) => {
      if (state.filters.timePeriod) {
        state.filters.timePeriod.start = action.payload
      }
    },
    setFilterTimePeriodEnd: (state, action: PayloadAction<SimpleDate>) => {
      if (state.filters.timePeriod) {
        state.filters.timePeriod.end = action.payload
      }
    },
    setFilterCategoryIds: (state, action: PayloadAction<number[]>) => {
      state.filters.categoryIds = action.payload
    },
    setFilterCollaboratorIds: (state, action: PayloadAction<number[]>) => {
      state.filters.collaboratorIds = action.payload
    },
    setFilterStatusIds: (state, action: PayloadAction<number[]>) => {
      state.filters.statusIds = action.payload
    },
    setActiveDateButton: (state, action: PayloadAction<ActiveDateButtonEnum>) => {
      state.utils.activeDateButton = action.payload
    },

    // ** Utils ** //
    toggleIsMetricOpen: (state) => {
      state.utils.isMetricOpen = !state.utils.isMetricOpen
    },
    toggleIsCommentsOpen: (state) => {
      state.utils.isCommentsOpen = !state.utils.isCommentsOpen
    },

    // ** Snapshot ** //
    saveSnapshot: (state) => {
      state.snapshot = { ...state }
    },
    loadSnapshot: (state) => {
      state.active = state.snapshot.active
      state.view = state.snapshot.view
      state.filters = state.snapshot.filters
      state.utils = state.snapshot.utils
    },
  },
})

// ! REDUCER FUNCTION EXPORTS
export const {
  // ** Active ** //
  setActiveCaseId,
  updateCase,

  // ** View ** //
  setupKanban,
  setUsers,
  deleteCase,
  addComment,
  addCollaborator,
  removeCollaborator,
  addLike,
  removeLike,
  changeCaseOrder,

  // ** Filters ** //
  setFilterTimePeriod,
  setFilterTimePeriodStart,
  setFilterTimePeriodEnd,
  setFilterCategoryIds,
  setFilterCollaboratorIds,
  setFilterStatusIds,
  setActiveDateButton,

  // ** Utils ** //
  toggleIsMetricOpen,
  toggleIsCommentsOpen,

  // ** Snapshot ** //
  saveSnapshot,
  loadSnapshot,
} = casesSlice.actions

// ! SELECTORS
// ** Basic selectors *** //
export const selectActiveCaseId = (state: RootState) => state.cases.active.caseId
export const selectKanban = (state: RootState) => state.cases.view.kanban
export const selectCases = (state: RootState) => state.cases.view.cases
export const selectLanes = (state: RootState) => state.cases.view.lanes
export const selectUsers = (state: RootState) => state.cases.view.users
export const selectIsMetricOpen = (state: RootState) => state.cases.utils.isMetricOpen
export const selectIsCommentsOpen = (state: RootState) => state.cases.utils.isCommentsOpen
export const selectActiveDateButton = (state: RootState) => state.cases.utils.activeDateButton
export const selectFilters = (state: RootState) => state.cases.filters

// ** createSelectors ** //
export const selectLaneEntities = createSelector(selectLanes, (lanes) => lanes.entities)
export const selectLaneIds = createSelector(selectLanes, (lanes) => lanes.ids)
export const selectFilterTimePeriod = createSelector(selectFilters, (filters) => filters.timePeriod)
export const selectFilterCategoryIds = createSelector(
  selectFilters,
  (filters) => filters.categoryIds,
)
export const selectFilterStatusIds = createSelector(selectFilters, (filters) => filters.statusIds)
export const selectFilterCollaboratorIds = createSelector(
  selectFilters,
  (filters) => filters.collaboratorIds,
)
export const selectLaneEntitiesNonDisqualified = createSelector(selectLanes, (lanes) => {
  return Object.values(lanes.entities).filter((lane) => lane.type !== LaneTypeEnum.DISCARD)
})

export const selectActiveCase = createSelector(
  (state: RootState) => state.cases.active.caseId,
  selectCases,
  (caseId, cases) => ensure(cases.entities[Number(caseId)]),
)

export const selectActiveCaseLane = createSelector(
  (state: RootState) => state.cases.active.caseId,
  selectLanes,
  selectKanban,
  (caseId, lanes, kanban) => {
    let laneId = 0
    Object.entries(kanban).forEach(([key, value]) => {
      if (value.find((id) => id === caseId)) laneId = Number(key)
    })
    return ensure(lanes.entities[Number(laneId)].title)
  },
)

export const selectNonDiscardedCaseIds = createSelector(selectCases, (cases) => {
  const nonDiscardedCaseIds: number[] = []
  Object.keys(cases.entities).forEach((key) => {
    if (!cases.entities[Number(key)].is_discarded) {
      nonDiscardedCaseIds.push(Number(key))
    }
  })
  return nonDiscardedCaseIds
})

export const selectRoadmapIds = createSelector(selectNonDiscardedCaseIds, (nonDiscardedCases) => {
  return [...nonDiscardedCases]
})

export const selectKanbanFiltered = createSelector(
  selectKanban,
  selectCases,
  selectFilters,
  (kanban, cases, filters) => {
    if (!filters.categoryIds.length && !filters.collaboratorIds.length && !filters.timePeriod) {
      return kanban
    }

    const entities = cases.entities
    const idsToRemove = [...cases.ids]

    Object.values(entities).forEach((_case) => {
      const { id, category_id, assignee_id, collaborators, latest_update_date_time } = _case
      let shouldFilterId = false

      // Filter by type
      if (filters.categoryIds.length) {
        if (!filters.categoryIds.includes(category_id)) shouldFilterId = true
      }

      // Filter by collaborator and/or assignee
      if (filters.collaboratorIds.length) {
        if (
          !filters.collaboratorIds.includes(assignee_id) &&
          collaborators
            .map(({ user_id }) => user_id)
            .some((id) => filters.collaboratorIds.includes(id))
        ) {
          shouldFilterId = true
        }
      }

      // Filter by timePeriod
      if (filters.timePeriod) {
        const isUpdatedWithinTimePeriod = isWithinInterval(
          new Date(format(new Date(latest_update_date_time), GLOBAL_DATE_FORMAT)),
          {
            start: new Date(filters.timePeriod.start),
            end: new Date(filters.timePeriod.end),
          },
        )
        if (!isUpdatedWithinTimePeriod) shouldFilterId = true
      }

      if (!shouldFilterId) {
        idsToRemove.splice(idsToRemove.indexOf(id), 1)
      }
    })

    const filteredKanban: Kanban = { ...kanban }
    Object.entries(filteredKanban).forEach(([laneId, caseIds]) => {
      idsToRemove.forEach((removeId) => {
        if (caseIds.includes(removeId)) {
          filteredKanban[laneId] = filteredKanban[laneId].filter((c) => c !== removeId)
        }
      })
    })

    return filteredKanban
  },
)

export const selectCasesFilteredForRoadmap = createSelector(
  selectCases,
  selectKanban,
  selectLaneEntities,
  selectFilters,
  (cases, kanban, laneEntities, filters) => {
    if (
      !filters.categoryIds.length &&
      !filters.collaboratorIds.length &&
      !filters.statusIds.length &&
      !filters.timePeriod
    ) {
      return cases
    }

    const entities = cases.entities
    const idsToRemove = [...cases.ids]

    Object.values(entities).forEach((c) => {
      const { id, category_id, assignee_id, collaborators, latest_update_date_time } = c
      let shouldFilterId = false

      // Filter by type
      if (filters.categoryIds.length) {
        if (!filters.categoryIds.includes(category_id)) shouldFilterId = true
      }

      // Filter by collaborator and/or assignee
      if (filters.collaboratorIds.length) {
        if (
          !filters.collaboratorIds.includes(assignee_id) &&
          collaborators
            .map(({ user_id }) => user_id)
            .some((id) => filters.collaboratorIds.includes(id))
        ) {
          shouldFilterId = true
        }
      }

      // Filter by timePeriod
      if (filters.timePeriod) {
        const isUpdatedWithinTimePeriod = isWithinInterval(new Date(latest_update_date_time), {
          start: new Date(filters.timePeriod.start),
          end: new Date(filters.timePeriod.end),
        })
        if (!isUpdatedWithinTimePeriod) shouldFilterId = true
      }

      // Filter by status
      if (filters.statusIds.length) {
        Object.entries(kanban).forEach(([laneId, caseIds]) => {
          if (caseIds.includes(id)) {
            const lane = laneEntities[laneId]
            if (!filters.statusIds.includes(lane.id)) {
              shouldFilterId = true
            }
          }
        })
      }

      if (!shouldFilterId) idsToRemove.splice(idsToRemove.indexOf(id), 1)
    })

    let filteredCaseIds = [...cases.ids]
    const filteredCaseEntities = { ...cases.entities }

    idsToRemove.forEach((id) => {
      filteredCaseIds = filteredCaseIds.filter((filteredId) => filteredId !== id)
      delete filteredCaseEntities[id]
    })

    return {
      ids: filteredCaseIds,
      entities: filteredCaseEntities,
    }
  },
)

export const selectRoadmapEntitiesAsArrayFiltered = createSelector(
  selectCasesFilteredForRoadmap,
  (cases) => {
    const roadmapEntities: Case[] = []

    Object.values(cases.entities).forEach((value) => {
      if (!value.is_discarded) roadmapEntities.push(value)
    })

    return roadmapEntities
  },
)

export const selectRoadmapEntitiesAsArray = createSelector(selectCases, (cases) => {
  const roadmapEntities: Case[] = []
  Object.values({ ...cases.entities }).forEach((c: Case) => {
    if (!c.is_discarded) roadmapEntities.push(c)
  })

  return roadmapEntities
})

// ** Selectors w/ PARAMS ** //
// NOTE: TS goes full retard when selectors are given params so need to use ts-ignore:next-line on the selector when used
export const selectCaseById = createSelector(
  [(state: RootState) => state.cases.view.cases.entities, (_, id) => id],
  (entities, id): Case | null => entities[id],
)

export const selectLaneCost = createSelector(
  [selectKanbanFiltered, (state: RootState) => state.cases.view.cases, (_, laneId) => laneId],
  (kanban, cases, laneId): number => {
    const caseIds = kanban[laneId]
    return caseIds.reduce((acc: number, curr: number) => {
      const caseObj = cases.entities[curr]
      const sum = caseObj?.business_case_cost - caseObj?.implementation_cost
      return acc + sum
    }, 0)
  },
)

export const selectLaneById = createSelector(
  [(state: RootState) => state.cases.view.lanes.entities, (_, id) => id],
  (entities, id): Lane | null => entities[id],
)

export const selectIsLaneEmptyById = createSelector(
  [selectKanbanFiltered, (_, id) => id],
  (kanban, id): boolean => (kanban[id].length === 0 ? true : false),
)

export const selectLaneCaseIdsByLaneId = createSelector(
  [selectKanbanFiltered, (_, id) => id],
  (kanban, id): number[] => kanban[id],
)
