import { ArrowPathIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import { useGetUniqueTagsQuery } from '../../app/apiSlice'
import { Button, Divider, InputField, SelectMultiple } from '../../common/designs'
import { useEnhancedSearchParams, useQueryFilters } from '../../common/hooks'
import { SubSearchParamEnum } from '../../common/types/searchParams'
import { compressAndEncodeJSON } from '../../common/utils/jsonCompression'
import useOptions from './useOptions'

const NODE_AMOUNT_DEFAULT = 25
const NODE_AMOUNT_MIN = 1

const formSchema = yup.object().shape({
  nodeAmount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .min(NODE_AMOUNT_MIN, 'Min must be a positive integer or empty')
    .integer('Min must be an integer or empty'),
  appList: yup.array().of(yup.string()),
})

interface Inputs {
  nodeAmount: number
  appList: string[]
}

const FlowFilters = () => {
  const { tags_of_interest } = useQueryFilters()
  const { nodeAmount, tagsOfInterest, appList } = useOptions()
  const { bulkUpdateSearchParams } = useEnhancedSearchParams()

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema) as Resolver<Inputs>,
    defaultValues: { nodeAmount, appList },
  })

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const updatedNodeAmount =
      data.nodeAmount === NODE_AMOUNT_DEFAULT ? null : String(data.nodeAmount)
    const updatedEncodedAppList = data.appList.length ? compressAndEncodeJSON(data.appList) : null

    bulkUpdateSearchParams([
      [SubSearchParamEnum.NODE_AMOUNT, updatedNodeAmount],
      [SubSearchParamEnum.APP_LIST, updatedEncodedAppList],
    ])
  }

  const onReset = () => {
    reset({ nodeAmount: NODE_AMOUNT_DEFAULT, appList: [] })

    bulkUpdateSearchParams([
      [SubSearchParamEnum.NODE_AMOUNT, null],
      [SubSearchParamEnum.APP_LIST, null],
    ])
  }

  const { data: uniqueAppOrWindowTags } = useGetUniqueTagsQuery({
    tags_of_interest: tagsOfInterest,
  })
  const { data: uniqueAppTags } = useGetUniqueTagsQuery({
    tags_of_interest: tags_of_interest.application,
  })

  const nodeAmountMax = uniqueAppOrWindowTags?.length ?? 1

  return (
    <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
      <Controller
        name='nodeAmount'
        control={control}
        render={({ field }) => (
          <InputField
            label='Node amount'
            type='number'
            min={NODE_AMOUNT_MIN}
            max={nodeAmountMax < NODE_AMOUNT_DEFAULT ? NODE_AMOUNT_DEFAULT : nodeAmountMax}
            description={`Select value between ${NODE_AMOUNT_MIN} and ${nodeAmountMax}`}
            error={errors.nodeAmount?.message}
            {...field}
          />
        )}
      />

      <Controller
        name='appList'
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectMultiple
            label='Application'
            options={
              uniqueAppTags
                ?.flatMap((t) => t)
                .map(({ value }) => {
                  return { label: value, value }
                }) ?? []
            }
            value={value}
            onChange={(val: string[]) => onChange(val)}
            selectedConfiguration={{ max: 3, label: 'Selected' }}
            error={errors.appList?.message}
          />
        )}
      />

      <Divider className='-mx-6 w-128' />

      <div className='flex gap-4'>
        <Button variant='white' iconEnd={<XCircleIcon />} onClick={onReset} className='w-1/2'>
          Reset filters
        </Button>

        <Button iconEnd={<ArrowPathIcon />} type='submit' wFull>
          Apply filters to the view
        </Button>
      </div>
    </form>
  )
}

export default FlowFilters
