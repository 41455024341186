import { useEffect, useState } from 'react'

import { GroupedChartView } from '.'
import { Loader } from '../../../common/components'
import { GroupedBarChartDataEntry } from '../../../common/components/Charts/types'
import { useCreateTrendDates, useFilteredWindowUsageQuery } from '../../../common/hooks'
import { TagOfInterestSource } from '../../../common/hooks/useFilteredWindowUsageQuery'
import type { Tag } from '../../../common/types/common'
import type { GroupedWindowUsageData } from '../types'
import {
  convertTrendDefaultDataToRelativeData,
  convertTrendServerDataToChartData,
} from './serverDataToViewData'

interface Props {
  tagsOfInterestSource: TagOfInterestSource
  tagFilters?: Tag[] | undefined
  chartTitle: string
  relativeChartTitle: string
  onChartClick: (compressedAndEncodedTags: string) => void
  useExactTagFilterMatch?: boolean
}

const TrendsChartView = (props: Props) => {
  const {
    tagsOfInterestSource,
    tagFilters,
    chartTitle,
    relativeChartTitle,
    onChartClick,
    useExactTagFilterMatch = false,
  } = props

  const [chartDataDefault, setChartDataDefault] = useState<GroupedBarChartDataEntry[]>([])
  const [chartDataRelative, setChartDataRelative] = useState<GroupedBarChartDataEntry[]>([])

  const dateComparisonData = useCreateTrendDates()
  const { data, isLoading } = useFilteredWindowUsageQuery({
    tagsOfInterestSource,
    variables: {
      date_comparison_data: dateComparisonData,
      tag_filters: tagFilters,
      // Disable active time period from changing query
      max_date: null,
      min_date: null,
      use_exact_tag_filter_match: useExactTagFilterMatch,
    },
  })

  // Set chart data after it is fetched from the server
  useEffect(() => {
    const defaultData = convertTrendServerDataToChartData(data as GroupedWindowUsageData)
    setChartDataDefault(defaultData)
    setChartDataRelative(convertTrendDefaultDataToRelativeData(defaultData))
  }, [data])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <GroupedChartView
          data={data as GroupedWindowUsageData}
          chartDataDefault={chartDataDefault}
          chartDataRelative={chartDataRelative}
          chartTitle={chartTitle}
          relativeChartTitle={relativeChartTitle}
          onChartClick={onChartClick}
        />
      )}
    </>
  )
}

export default TrendsChartView
