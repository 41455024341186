import { teamColorMap } from '../../../../app/theme'
import { isStringArray } from '../../../../common/types/typeGuards'
import type { Teams } from '../../../../common/utils/UtilityWrapper/types'
import { getBestTimeFormatTransformationForSeconds } from '../../../../common/utils/numberFormatting'
import { TIME_UNIT } from '../../constants'
import { GroupByFieldTypeEnum, MetricNamesEnum } from '../../types'
import type {
  GroupResultData,
  MultipleLabelsGroupResult,
  TransactionGroupResultMultiple,
} from '../../types'
import { metricNameExtraDataMapper } from './constants'

export const setUpChartDataAndMetric = (
  dataArray: GroupResultData[],
  selectedMetric: MetricNamesEnum,
  groupByField: string,
  teams: Teams,
) => {
  const teamNameToIdMapper: { [key: string]: string } = {}
  const teamNamesToIdArrayMapper: { [key: string]: string[] } = {}
  let chartData
  let unit = metricNameExtraDataMapper[selectedMetric].metric_unit
  let divider = 1
  // If time metric => get best time format for seconds and update unit
  if (unit === TIME_UNIT) {
    ;({ divider, unit } = getBestTimeFormatTransformationForSeconds(
      dataArray.length ? dataArray[0].value : 0,
    ))
  }
  // If team id grouping => Change team ids to team names and colors.
  if (groupByField === GroupByFieldTypeEnum.TEAM_ID) {
    chartData = dataArray.map(({ label, value }) => {
      const typedLabel = label as string
      const teamObj = teams[typedLabel]
      teamNameToIdMapper[teamObj?.name || typedLabel] = typedLabel
      return {
        label: teamObj?.name || typedLabel,
        value: value / divider,
        color: teamColorMap[teamObj?.colorIndex || 0],
      }
    })
  }
  // If team id list grouping => Convert team id list to string and add mapping from
  // the string to original team id list.
  else if (groupByField === GroupByFieldTypeEnum.TEAM_IDS) {
    chartData = dataArray.map(({ label, value }) => {
      if (isStringArray(label)) {
        const teamIdArrayLabel = label
        const usedLabel = teamIdArrayLabel
          .map((teamId) => {
            return teams[teamId]?.name || teamId
          })
          .join(', ')
        teamNamesToIdArrayMapper[usedLabel] = teamIdArrayLabel
        return {
          label: usedLabel,
          value: value / divider,
        }
      } else {
        return {
          label: label as string,
          value: value / divider,
        }
      }
    })
  }
  // Else use activeData as is
  else {
    chartData = dataArray.map(({ label, value }) => ({
      label: label as string,
      value: value / divider,
    }))
  }

  return { chartData, unit, teamNameToIdMapper, teamNamesToIdArrayMapper }
}

export const setUpChartDataAndMetricForMultipleDimensions = (
  data: TransactionGroupResultMultiple,
  selectedMetric: MetricNamesEnum,
  teams: Teams,
  selectedTimeData: { divider: number; unit: string },
) => {
  const dataArray = data[selectedMetric]
  const teamNameToIdMapper: { [key: string]: string } = {}
  const teamNamesToIdArrayMapper: { [key: string]: string[] } = {}
  let unit = metricNameExtraDataMapper[selectedMetric].metric_unit
  let divider = 1
  // If time metric => get best time format for seconds and update unit
  if (unit === TIME_UNIT) {
    ;({ divider, unit } = selectedTimeData)
  }

  const chartData: MultipleLabelsGroupResult[] = dataArray.map(({ labels, value }) => {
    let teamColor = null
    const mappedLabels = labels.map(({ label_type, label_value }) => {
      // If team id grouping => Change team ids to team names and colors.
      if (label_type === GroupByFieldTypeEnum.TEAM_ID) {
        const labelValue = label_value as string
        const teamObj = teams[labelValue]
        teamNameToIdMapper[teamObj?.name || labelValue] = labelValue
        teamColor = teamColorMap[teamObj?.colorIndex || 0]
        return {
          label_type,
          label_value: teamObj?.name || labelValue,
        }
      }

      // If team id list grouping => Convert team id list to string and add mapping from
      // the string to original team id list.
      else if (label_type === GroupByFieldTypeEnum.TEAM_IDS) {
        const teamIdArrayLabel = label_value as string[]
        const usedLabel = teamIdArrayLabel
          .map((teamId) => {
            return teams[teamId]?.name || teamId
          })
          .join(', ')
        teamNamesToIdArrayMapper[usedLabel] = teamIdArrayLabel
        return {
          label_type,
          label_value: usedLabel,
        }
      } else {
        return {
          label_type,
          label_value: label_value as string,
        }
      }
    })

    return {
      labels: mappedLabels,
      value: value / divider,
      color: teamColor,
    }
  })

  return { chartData, unit, teamNameToIdMapper, teamNamesToIdArrayMapper }
}

export const getFilterValueForChartClick = (
  label: string,
  groupByField: string,
  teamNameToIdMapper: {
    [key: string]: string
  },
  teamNamesToIdArrayMapper: {
    [key: string]: string[]
  },
) => {
  let filterValue: string | string[] = label
  // If team grouping => Change team name back to team id
  if (groupByField === GroupByFieldTypeEnum.TEAM_ID) {
    filterValue = teamNameToIdMapper[label as keyof typeof teamNameToIdMapper]
  }
  // If team id list grouping => Change string back to array of team ids.
  if (groupByField === GroupByFieldTypeEnum.TEAM_IDS) {
    filterValue = teamNamesToIdArrayMapper[label as keyof typeof teamNamesToIdArrayMapper]
  }

  return filterValue
}
