import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useDeleteTeamConfigMutation,
  useGetCompanyConfigMetadataQuery,
  useGetTeamConfigMetadataQuery,
  useLazyDownloadTrainingDataQuery,
  useLazyGetCompanyConfigFileRawQuery,
  useLazyGetTeamConfigDownloadUrlQuery,
  useUpdateConfigSourceMutation,
  useUploadCompanyConfigMutation,
} from '../../app/apiSlice'
import { ActionModal, Button, Divider, Table, Text, Toggle } from '../../common/designs'
import type { Team } from '../../common/types/common'
import { downloadFile } from '../../common/utils/utils'
import useManageServerTestCasesAndConfigs from '../ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import ConfigFileDownloadButton from './ConfigFileDownloadButton'
import ConfigFileUploadModal from './ConfigFileUploadModal'
import ShadowContainer from './ShadowContainer'

interface Props {
  title: string
  allApplications: Array<{ name: string; hasTrainingData: boolean }>
  allProcesses: string[]
  team?: Team
}

const ConfigFileManagementSection = ({ title, allApplications, allProcesses, team }: Props) => {
  const [isUploadConfigModalOpen, setIsUploadConfigModalOpen] = useState<boolean>(false)
  const [hasDeleteConfigConfirmationModalOpen, setHasDeleteConfigConfirmationModalOpen] =
    useState<boolean>(false)
  const [isDeletionOngoing, setIsDeletionOngoing] = useState<boolean>(false)
  const [hasMainConfigConfirmationModalOpen, setHasMainConfigConfirmationModalOpen] =
    useState<boolean>(false)
  const [isTrainingDataLoading, setIsTrainingDataLoading] = useState<boolean>(false)

  const [getCompanyConfigFileRaw] = useLazyGetCompanyConfigFileRawQuery()
  const [getTeamConfigDownloadUrl] = useLazyGetTeamConfigDownloadUrlQuery()
  const [deleteTeamConfig] = useDeleteTeamConfigMutation()
  const [updateConfigSource] = useUpdateConfigSourceMutation()
  const { data: teamConfigMetadata } = useGetTeamConfigMetadataQuery(
    { invitationToken: team?.invitation_token ?? '' },
    { skip: !team },
  )
  const { data: companyConfigMetadata } = useGetCompanyConfigMetadataQuery(undefined, {
    skip: Boolean(team),
  })
  const [downloadTrainingData] = useLazyDownloadTrainingDataQuery()
  const [uploadCompanyConfig] = useUploadCompanyConfigMutation()
  const { clearTestCasesAndRules } = useManageServerTestCasesAndConfigs()

  const downloadConfig = () => {
    if (teamConfigMetadata || companyConfigMetadata) {
      const getConfigDownloadUrlPromise = team
        ? getTeamConfigDownloadUrl({ invitationToken: team.invitation_token })
        : getCompanyConfigFileRaw()
      getConfigDownloadUrlPromise.unwrap().then((configJson) => {
        downloadFile(
          window.URL.createObjectURL(new Blob(configJson)),
          teamConfigMetadata?.name ?? companyConfigMetadata?.name ?? '',
        )
      })
    }
  }

  const updateConfigSourceToCompany = () => {
    if (team) {
      updateConfigSource({
        invitationToken: team.invitation_token,
        body: { configuration_source: 'Company' },
      })
    }
  }

  const deleteConfig = async () => {
    setIsDeletionOngoing(true)
    if (team) {
      deleteTeamConfig({
        invitationToken: team.invitation_token,
      })
    } else {
      await clearTestCasesAndRules()
      await uploadCompanyConfig()
    }
    setIsDeletionOngoing(false)
    setHasDeleteConfigConfirmationModalOpen(false)
  }

  const handleTrainingDataDownload = async (applicationName: string) => {
    toast.info(`Started application data loading for ${applicationName}`)
    setIsTrainingDataLoading(true)
    const trainingCsv = await downloadTrainingData(applicationName).unwrap()
    setIsTrainingDataLoading(false)
    downloadFile(window.URL.createObjectURL(new Blob([trainingCsv])), `${applicationName}.csv`)
  }
  const onClickDownloadTrainingData = useCallback(handleTrainingDataDownload, [
    downloadTrainingData,
  ])

  return (
    <>
      <ShadowContainer className='mb-5'>
        <div>
          <div className='px-10 pb-4 pt-6'>
            <Text size='2xl' weight={700} className='mb-2'>
              {title}
            </Text>
            <div className='flex justify-end gap-6'>
              {team && (
                <Toggle
                  size='l'
                  label='Use main config'
                  value={team.configuration_source === 'Company'}
                  onChange={() => setHasMainConfigConfirmationModalOpen(true)}
                />
              )}
              <Button
                size='s'
                iconStart={<ArrowUpTrayIcon />}
                onClick={() => setIsUploadConfigModalOpen(true)}
              >
                Upload Config File
              </Button>

              <ConfigFileDownloadButton
                lastUpdateDate={
                  teamConfigMetadata?.lastUpdateDate ??
                  companyConfigMetadata?.lastUpdateDate ??
                  null
                }
                configName={
                  teamConfigMetadata?.name || companyConfigMetadata?.name || 'No configuration'
                }
                onClick={downloadConfig}
              />
              <Button
                variant='destructive'
                onClick={() => setHasDeleteConfigConfirmationModalOpen(true)}
              >
                Delete
              </Button>
            </div>
          </div>
          <Divider />
          <div className='px-10 pb-4 pt-6'>
            <div className='mb-3'>
              <Text size='l' weight={700} className='mb-2'>
                Applications
              </Text>
              <Table
                className='-mx-4 my-0'
                head={['Application', 'Training data']}
                body={allApplications.map((application) => [
                  application.name,
                  application.hasTrainingData ? (
                    <Button
                      key={application.name}
                      variant='white'
                      iconStart={<ArrowDownTrayIcon />}
                      loading={isTrainingDataLoading}
                      onClick={() => onClickDownloadTrainingData(application.name)}
                    >
                      Download .csv
                    </Button>
                  ) : (
                    '  -'
                  ),
                ])}
              />
            </div>

            <div>
              <Text size='l' weight={700} className='mb-2'>
                Processes
              </Text>
              <Table
                className='-mx-4 my-0'
                head={['Process name', 'Training data']}
                body={allProcesses.map((p) => [p, '  -'])}
              />
            </div>
          </div>
        </div>
      </ShadowContainer>
      <ActionModal
        variant='destructive'
        label='Changing to main config deletes the old team config, are you sure you want to delete the team config?'
        actionLabel='Delete'
        open={hasMainConfigConfirmationModalOpen}
        onClose={() => setHasMainConfigConfirmationModalOpen(false)}
        onAction={updateConfigSourceToCompany}
      />

      <ActionModal
        variant='destructive'
        label='Are you sure you want to delete this configuration?'
        actionLabel='Delete'
        open={hasDeleteConfigConfirmationModalOpen}
        onClose={() => setHasDeleteConfigConfirmationModalOpen(false)}
        onAction={deleteConfig}
        loading={isDeletionOngoing}
      />

      <ConfigFileUploadModal
        isOpen={isUploadConfigModalOpen}
        onClose={() => setIsUploadConfigModalOpen(false)}
        team={team}
      />
    </>
  )
}

export default ConfigFileManagementSection
