import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { useMemo, useRef, useState } from 'react'

import { PRIMARY_600_HEX } from '../../../app/theme'
import { Modal, Select, Text } from '../../../common/designs'
import { useCostUtils } from '../../../common/hooks'
import type { ChartData } from '../../../common/types/common'
import { getBestTimeFormatTransformationForSeconds } from '../../../common/utils/numberFormatting'
import { SelectRecommendationTrendVariableEnum } from '../enums'
import { getAnnualCostForRecommendation } from '../recommendationFieldUtils'
import type { RecommendationData } from '../types'
import LineChart from './LineChart'
import RecommendationMetricGrid from './RecommendationMetricGrid'

interface Props {
  recommendationName: string | undefined
  onClose: () => void
  recommendations: RecommendationData[] | undefined
}

const selectTrendVariable = [
  {
    label: SelectRecommendationTrendVariableEnum.ANNUAL_COST,
    value: SelectRecommendationTrendVariableEnum.ANNUAL_COST,
  },
  {
    label: SelectRecommendationTrendVariableEnum.IMPACT_SCORE,
    value: SelectRecommendationTrendVariableEnum.IMPACT_SCORE,
  },
  {
    label: SelectRecommendationTrendVariableEnum.THROUGHPUT,
    value: SelectRecommendationTrendVariableEnum.THROUGHPUT,
  },
  {
    label: SelectRecommendationTrendVariableEnum.EVENT_COUNT,
    value: SelectRecommendationTrendVariableEnum.EVENT_COUNT,
  },
]

const RecommendationModal = ({ recommendationName, onClose, recommendations }: Props) => {
  const { currency } = useCostUtils()
  const [activeTrendValue, setActiveTrendValue] = useState<SelectRecommendationTrendVariableEnum>(
    selectTrendVariable[0].value,
  )
  const throughputUnit = useRef<string>('')

  const recommendation = useMemo(() => {
    return recommendations?.find((r) => r.recommendation_name === recommendationName)
  }, [recommendations, recommendationName])

  const trendsData = useMemo(() => {
    const dataObj: ChartData = {
      labels: [],
      datasets: [
        {
          label: activeTrendValue,
          data: [],
          backgroundColor: PRIMARY_600_HEX,
          borderColor: PRIMARY_600_HEX,
        },
      ],
    }

    if (!recommendation) return dataObj

    let divider = 0
    let unit = 'sec'
    if (activeTrendValue === SelectRecommendationTrendVariableEnum.THROUGHPUT) {
      const mappedData = recommendation.trend_data.map((t) => t.weekly_throughput_time_sec)
      const maxSeconds = Math.max(...mappedData)
      ;({ divider, unit } = getBestTimeFormatTransformationForSeconds(maxSeconds))
    }

    recommendation.trend_data.forEach((trend) => {
      const {
        weekly_time_sec,
        weekly_impact_score,
        weekly_throughput_time_sec,
        weekly_event_count,
        week_number,
      } = trend
      dataObj.labels.unshift(`Week ${week_number}`)
      let value = 0
      if (activeTrendValue === SelectRecommendationTrendVariableEnum.ANNUAL_COST) {
        value = getAnnualCostForRecommendation(weekly_time_sec / 60 / 60, currency.costPerHour ?? 0)
      } else if (activeTrendValue === SelectRecommendationTrendVariableEnum.IMPACT_SCORE) {
        value = weekly_impact_score
      } else if (activeTrendValue === SelectRecommendationTrendVariableEnum.THROUGHPUT) {
        value = weekly_throughput_time_sec / divider
        throughputUnit.current = unit
      } else {
        value = weekly_event_count
      }

      dataObj.datasets[0].data.unshift(value)
    })

    return dataObj
  }, [recommendation, currency.costPerHour, activeTrendValue])

  const tooltipCallback = (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
    let value = `${tooltipItem.label}: ${tooltipItem.formattedValue}`
    if (activeTrendValue === SelectRecommendationTrendVariableEnum.ANNUAL_COST) {
      value += ` ${currency.symbol}`
    } else if (activeTrendValue === SelectRecommendationTrendVariableEnum.THROUGHPUT) {
      value += ` ${throughputUnit.current}`
    }
    return value
  }

  if (!recommendation) return null

  return (
    <Modal
      size='4xl'
      label='Recommendation insights'
      open={Boolean(recommendationName)}
      onClose={onClose}
    >
      <div className='w-full bg-background-main p-2'>
        <RecommendationMetricGrid recommendation={recommendation} />

        <div className='mt-2 w-full bg-white  p-2 shadow-md'>
          <div className='mb-2 flex w-full justify-between'>
            <Text weight={600} size='xl'>
              Recommendation trends
            </Text>
            <div className='w-[250px]'>
              <Select
                size='xs'
                options={selectTrendVariable}
                value={activeTrendValue}
                onChange={setActiveTrendValue as React.Dispatch<React.SetStateAction<string>>}
              />
            </div>
          </div>
          <LineChart data={trendsData} tooltipCallback={tooltipCallback} />
        </div>
      </div>
    </Modal>
  )
}

export default RecommendationModal
