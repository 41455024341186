import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Text } from '../../common/designs'

interface Props {
  application: AppTestCaseGroup
}

const ApplicationTableTitle = ({ application }: Props) => {
  let textColor
  let text

  if (application.hasFailingTests) {
    textColor = 'text-red-700'
    text = 'Failing Tests'
  } else if (application.hasNotRunTests) {
    textColor = 'text-yellow-700'
    text = 'Untested Changes'
  } else if (application.hasNoDataCollectionRules) {
    textColor = 'text-gray-700'
    text = 'Data Collection Disabled'
  } else {
    textColor = 'bg-green-600'
    text = null
  }

  return (
    <div className='flex items-center gap-4'>
      <Text>{application.appName}</Text>
      {text ? (
        <div className='flex gap-2'>
          <ExclamationTriangleIcon className={clsx('size-5', textColor)} />
          <Text className={clsx('text-sm', textColor)}>{text}</Text>
        </div>
      ) : (
        <div className={clsx('size-3 rounded-full', textColor)}></div>
      )}
    </div>
  )
}

export default ApplicationTableTitle
