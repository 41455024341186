import { PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { useGetTrackedProcessesListQuery } from '../../app/apiSlice'
import { Button, Link, Modal, Table } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { ProcessGeneralInfo } from '../Processes/types'
import CustomiseDimensionsModal from './CustomiseDimensionsModal'
import ProcessForm from './ProcessForm'
import { ProcessSubPathEnum } from './types'

const Process = () => {
  const { data } = useGetTrackedProcessesListQuery()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isCustomiseDimensionsModalOpen, setIsCustomiseDimensionsModalOpen] =
    useState<boolean>(false)
  const [instance, setInstance] = useState<ProcessGeneralInfo | null>(null)

  return (
    <>
      <Table
        label='Processes'
        description='Turn business objects into process flows here. Select the main process identifiers and slice & dice dimensions for each.'
        head={['Name', 'Process Identifier Name', 'Dimensions', 'Edit', 'Data Integrations']}
        body={(data ?? []).map((p) => [
          p.name,
          p.process_identifier_name,
          <Link
            onClick={() => {
              setInstance(p)
              setIsCustomiseDimensionsModalOpen(true)
            }}
            key={p.process_identifier_name}
          >
            Dimensions
          </Link>,
          <Link
            onClick={() => {
              setInstance(p)
              setIsEditModalOpen(true)
            }}
            key={p.process_identifier_name}
          >
            Edit
          </Link>,
          <Link
            key={p.process_identifier_name}
            onClick={() => navigate(`${p.id}/${ProcessSubPathEnum.DATA_INTEGRATION}`)}
          >
            Data Integrations
          </Link>,
        ])}
        action={
          <Button iconStart={<PlusIcon />} onClick={() => setIsCreateModalOpen(true)}>
            Add a new process
          </Button>
        }
      />

      <Modal
        label='Create a new process'
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      >
        <ProcessForm onClose={() => setIsCreateModalOpen(false)} />
      </Modal>

      <Modal
        label='Edit the process'
        open={isEditModalOpen && Boolean(instance)}
        onClose={() => {
          setInstance(null)
          setIsEditModalOpen(false)
        }}
        size='xl'
      >
        <ProcessForm
          editedData={instance}
          onClose={() => {
            setInstance(null)
            setIsEditModalOpen(false)
          }}
        />
      </Modal>

      {isCustomiseDimensionsModalOpen && instance && (
        <CustomiseDimensionsModal
          isOpen={isCustomiseDimensionsModalOpen}
          onClose={() => {
            setInstance(null)
            setIsCustomiseDimensionsModalOpen(false)
          }}
          process={instance}
        />
      )}
    </>
  )
}

export default Process
