import { ChevronLeftIcon } from '@heroicons/react/24/outline'

import { useGetCaseTrendsQuery } from '../../../../app/dataTrendsApiSlice'
import FavoritesDisplay, {
  FavoriteTypeEnum,
} from '../../../../common/components/displays/FavoritesDisplay/FavoritesDisplay'
import { Button, Divider } from '../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import { selectActiveCase, toggleIsMetricOpen } from '../../casesSlice'
import { Title } from './components'

const MetricsView = () => {
  const dispatch = useAppDispatch()
  const activeCase = useAppSelector(selectActiveCase)

  const { data } = useGetCaseTrendsQuery(activeCase.id)

  return (
    <div className='mb-2'>
      <div className='mb-2 flex justify-between'>
        <Title title={activeCase.title} hideEdit />
        <Button
          variant='white'
          iconStart={<ChevronLeftIcon />}
          className='ml-3 w-[190px] min-w-[190px] max-w-[190px]'
          onClick={() => dispatch(toggleIsMetricOpen())}
        >
          Back to card details
        </Button>
      </div>

      <Divider />

      <div className='mt-5'>
        <FavoritesDisplay type={FavoriteTypeEnum.OPPORTUNITY} queries={data} />
      </div>
    </div>
  )
}

export default MetricsView

