import { useMemo, useState } from 'react'

import {
  useCreateDataCollectionsConfigApplicationMutation,
  useCreateTrackedProcessMutation,
  useGetTemplateDashboardAppDataQuery,
  useGetTemplateDataCollectionConfigsQuery,
  useGetTemplateProcessDataQuery,
  useGetTemplateTestCasesQuery,
} from '../../app/apiSlice'
import { Loader } from '../../common/components'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Breadcrumbs, Button, Divider, Table } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { AdminMainPathEnum } from '../../common/types/routing'
import type { ProcessGeneralInfo } from '../Processes/types'
import AppTemplateModal from './AppTemplateModal'
import { getAppListFromTestCases } from './dataHelpers'
import useManageServerTestCasesAndConfigs from './useManageServerTestCasesAndConfigs'

const AppTemplates = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const [selectedAppTemplate, setSelectedAppTemplate] = useState<AppTestCaseGroup | null>(null)

  const { data: requirementsData, isLoading: isLoadingRequirementsData } =
    useGetTemplateTestCasesQuery()
  const { data: applicationMetaData, isLoading: isLoadingApplicationMetaData } =
    useGetTemplateDashboardAppDataQuery()
  const { data: ruleData, isLoading: isLoadingRuleData } =
    useGetTemplateDataCollectionConfigsQuery()
  const [createAppCommonInfo, { isLoading: isLoadingCreateAppCommonInfo }] =
    useCreateDataCollectionsConfigApplicationMutation()
  const { data: processList, isLoading: isLoadingProcessData } = useGetTemplateProcessDataQuery()
  const [createTrackedProcess, { isLoading: isLoadingCreateProcess }] =
    useCreateTrackedProcessMutation()

  const applicationList = useMemo(() => {
    const { appData } = getAppListFromTestCases(
      requirementsData?.test_cases ?? [],
      applicationMetaData ?? [],
      ruleData?.rules ?? [],
      undefined,
    )
    return appData.sort((a, b) => a.appName.localeCompare(b.appName))
  }, [requirementsData, applicationMetaData, ruleData])

  const {
    createTestCases,
    createRules,
    isRunningConfigTests,
    isUploadingTestCases,
    isUploadingRules,
  } = useManageServerTestCasesAndConfigs()

  const onAddTemplate = async (catalogApp: AppTestCaseGroup) => {
    const testCases = [
      ...catalogApp.testCases,
      ...catalogApp.windowLevelData.map((window) => window.testCases).flat(1),
    ]
    const rules = [
      ...catalogApp.rules,
      ...catalogApp.processRules,
      ...catalogApp.windowLevelData.map((window) => window.rulesForTestCases).flat(1),
    ]
    await createTestCases(testCases)
    await createRules(rules)
    await createAppCommonInfo(catalogApp.appDashboardConfigs)
    await createProcessConfigs(catalogApp)
    navigate(AdminMainPathEnum.DATA_COLLECTION)
  }

  const createProcessConfigs = async (catalogApp: AppTestCaseGroup) => {
    const processObjects = [] as ProcessGeneralInfo[]

    catalogApp.processRules.forEach(({ dashboard_context }) => {
      const identifierName = dashboard_context?.process_name
      const matchingProcessObj = processList?.find(
        (processObj) => processObj.process_identifier_name === identifierName,
      )
      if (matchingProcessObj) processObjects.push(matchingProcessObj)
    })

    await createTrackedProcess(processObjects)
  }

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('..'),
          },
          {
            label: 'Application Templates',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      {isRunningConfigTests ||
      isUploadingTestCases ||
      isLoadingRequirementsData ||
      isLoadingApplicationMetaData ||
      isLoadingRuleData ||
      isRunningConfigTests ||
      isLoadingCreateAppCommonInfo ||
      isLoadingProcessData ||
      isLoadingCreateProcess ||
      isUploadingRules ? (
        <Loader />
      ) : (
        <Table
          key='Application Templates'
          head={['Application', '']}
          body={applicationList.map((application) => {
            return [
              application.appName,
              <div className='space-x-4' key={application.appName}>
                <Button onClick={() => onAddTemplate(application)}>Add Application</Button>
                <Button
                  onClick={() => {
                    setSelectedAppTemplate(application)
                  }}
                >
                  Details
                </Button>
              </div>,
            ]
          })}
        />
      )}

      <AppTemplateModal
        onClose={() => setSelectedAppTemplate(null)}
        application={selectedAppTemplate}
      />
    </>
  )
}

export default AppTemplates
