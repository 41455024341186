import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { createRef, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { cssCalcPixels } from '../../utils/stringFormatting'
import ChartLegend from './ChartLegend'
import { getBarchartOptions } from './barChartOptions'
import { dataToChartData } from './dataToChartData'
import type { ChartDataEntry, LegendData } from './types'

interface Props {
  data: ChartDataEntry[]
  unit: string
  visibleDataAmount?: number
  legend?: LegendData[] | null
  height?: string
  width?: string
  onClick?: ((el: any) => void) | undefined
  tooltipCallback?: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => string | string[]
  yTicksCallback?: (value: string | number) => string
  chartColor?: string
  borderRadius?: number
  tickAmount?: number
  hideGrid?: boolean
  maxXLabelLength?: number
  useReversedDataOrder?: boolean
}

const BarChart = ({
  data,
  unit,
  visibleDataAmount,
  legend,
  height = '400px',
  width = '100%',
  onClick,
  tooltipCallback,
  yTicksCallback,
  chartColor,
  borderRadius,
  tickAmount,
  hideGrid,
  maxXLabelLength,
  useReversedDataOrder,
}: Props) => {
  const [chartHeight, setChartHeight] = useState<string>('0px')
  const legendRef = createRef<HTMLDivElement>()
  const dataSelectorRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const extraSpace =
      (legendRef.current?.clientHeight || 0) + (dataSelectorRef.current?.clientHeight || 0)
    setChartHeight(cssCalcPixels(height, extraSpace, '-'))
  }, [legendRef, dataSelectorRef, height, setChartHeight])

  const yMaxValue = useMemo(() => {
    const noDataLimit = !visibleDataAmount
    const visibleData = noDataLimit
      ? data
      : useReversedDataOrder
        ? data.slice(-visibleDataAmount)
        : data.slice(0, visibleDataAmount)
    return Math.round(Math.max(...visibleData.map((d) => d.value)) * 1.1)
  }, [data, useReversedDataOrder, visibleDataAmount])

  const chartData = useMemo(
    () => dataToChartData(data, chartColor, borderRadius, visibleDataAmount, useReversedDataOrder),
    [data, chartColor, borderRadius, visibleDataAmount, useReversedDataOrder],
  )

  const options = getBarchartOptions({
    unit,
    yMaxValue,
    onClick,
    tooltipCallback,
    yTicksCallback,
    tickAmount,
    hideGrid,
    maxXLabelLength,
  })

  return (
    <>
      {chartHeight !== '0px' ? (
        <div style={{ height, width }}>
          <div style={{ height: chartHeight, width }}>
            <Bar data={chartData} options={options} height={chartHeight} />
          </div>
          {legend ? <ChartLegend ref={legendRef} data={legend} /> : null}
        </div>
      ) : null}
    </>
  )
}

export default BarChart
