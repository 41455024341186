import clsx from 'clsx'

import { Text, Tooltip } from '../../designs'
import {
  formatNumber,
  getBestTimeFormatTransformationForSeconds,
} from '../../utils/numberFormatting'

interface TaskFlowItem {
  durationSec: number
  label: string
  colorClass: string // e.g. 'bg-indigo-600'
}

interface Props {
  data: TaskFlowItem[]
  barHeight?: string
}

const TaskFlowChart = ({ data, barHeight = 'h-8' }: Props) => {
  const totalTime = data.reduce((total, taskItem) => total + taskItem.durationSec, 0)
  const { divider, unit } = getBestTimeFormatTransformationForSeconds(
    Math.max(...data.map(({ durationSec }) => durationSec)),
  )
  const visualizationData = data.map((item) => ({
    ...item,
    value: item.durationSec / divider,
    relativeValue: Math.max(Math.round((item.durationSec / totalTime) * 100), 1),
  }))

  return (
    <div className='flex w-full align-middle'>
      {visualizationData.map(({ colorClass, relativeValue, value, label }, i) => {
        const roundedStart = i === 0 ? 'rounded-l' : ''
        const roundedEnd = i === visualizationData.length - 1 ? 'rounded-r' : ''
        const padding = i === visualizationData.length - 1 ? '' : 'pr-2'

        return (
          <div
            className={clsx('flex h-full flex-col', padding)}
            style={{ width: `${relativeValue}%` }}
            key={`${label}-${value}`}
          >
            <Tooltip label={label} text={`${formatNumber(value, 10)} ${unit}`}>
              <div className={`${colorClass} ${barHeight} ${roundedStart} ${roundedEnd}`} />
            </Tooltip>
            <Text size='xs' className='w-full truncate text-center'>
              {label}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default TaskFlowChart
