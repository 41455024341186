import { useLocation } from 'react-router-dom'

import { Tabs, Tooltip } from '../../../../common/designs'
import { useAppSelector, useEnhancedNavigate } from '../../../../common/hooks'
import { selectVariantsLayoutData } from '../../processesSlice'
import { getVariantViewRoutesEnumFromLocation } from '../../utils'
import { VariantViewNamesEnum, VariantViewRoutesEnum } from './enums'

const navTabs = [
  { label: VariantViewNamesEnum.STEP_FLOWS, value: VariantViewRoutesEnum.STEP_FLOWS },
  { label: VariantViewNamesEnum.STATE_FLOWS, value: VariantViewRoutesEnum.STATE_FLOWS },
  { label: VariantViewNamesEnum.TEAM_FLOWS, value: VariantViewRoutesEnum.TEAM_FLOWS },
]

const VariantsTabSelector = () => {
  const location = useLocation()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { viewDescriptionText } = useAppSelector(selectVariantsLayoutData)

  const onTabSelected = (val: string) => navigate(`./${val}`)
  const label = viewDescriptionText.split(' ').slice(0, 2).join(' ')
  const activeRoute = getVariantViewRoutesEnumFromLocation(location)

  return (
    <div className='pointer-events-auto inline-flex shrink-0 grow-0 items-center gap-2'>
      <Tooltip
        variant='question'
        size='xl'
        label={label}
        text={viewDescriptionText}
        placement='ls'
      />
      <Tabs
        size='xs'
        variant='secondary'
        options={navTabs}
        value={activeRoute ?? ''}
        onChange={onTabSelected}
      />
    </div>
  )
}

export default VariantsTabSelector
