export enum ActivityLabelEnum {
  QUICK_PROCESSING = 'Quick processing',
  FOCUSED_WORK = 'Focused work',
  MULTI_SYSTEM_WORKFLOWS = 'Multi-system workflows',
}

export const activityExplanationMapper = {
  [ActivityLabelEnum.QUICK_PROCESSING]:
    'Quick processing activities are short repetitive activities moving business process cases to the next stage. Consider automation due to the duration improvements.',
  [ActivityLabelEnum.FOCUSED_WORK]:
    'Focused work is activities done within one process task that requires more long-term activities without a need to jump to other business applications. Consider applying decision intelligence for duration improvements and reducing manual efforts.',
  [ActivityLabelEnum.MULTI_SYSTEM_WORKFLOWS]:
    'Multi-system workflows are repetitive work performed in other systems to execute the process tasks. Consider automation due to the duration improvements and reducing manual efforts.',
}

