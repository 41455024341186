import { Text } from '../../../../../common/designs'
import { useAppSelector } from '../../../../../common/hooks'
import { changeHslaColorAlpha } from '../../../../../common/utils/stringFormatting'
import { selectLaneById } from '../../../casesSlice'
import { LaneTypeEnum } from '../../../enums'
import { colorAlpha } from '../utils'

interface Props {
  id?: number
}

const ColorExplanation = ({ id }: Props) => {
  const lane = useAppSelector((state) => selectLaneById(state, id)) // eslint-disable-line react-redux/useSelector-prefer-selectors

  if (lane?.type === LaneTypeEnum.DISCARD || !lane?.color) return null

  return (
    <div className='m-1 flex items-center'>
      <div
        className='mr-4 size-6 rounded-full border'
        style={{ backgroundColor: changeHslaColorAlpha(lane.color, colorAlpha) }}
      />

      <Text variant='h6'>{lane?.title}</Text>
    </div>
  )
}

export default ColorExplanation
