import { NormalDistributionPoint } from '../../types'

export const getLineAnnotiation = (
  value: number,
  label: string,
  points: NormalDistributionPoint[],
  yValues: number[],
) => {
  return {
    type: 'line',
    borderColor: 'rgb(255, 99, 132)',
    borderWidth: 2,
    borderDash: [6, 6],
    borderDashOffset: 0,
    label: {
      display: true,
      backgroundColor: 'rgb(100, 149, 237)',
      content: label,
      position: 'end',
      yAdjust: -20,
    },
    xMin: getPosition(value, points),
    xMax: getPosition(value, points),
    yMin: 0,
    yMax: yValues[Math.round(getPosition(value, points))],
  }
}

const getPosition = (value: number, points: NormalDistributionPoint[]) => {
  const stepSize = (points[points.length - 1].x_sec - points[0].x_sec) / (points.length - 1)
  const distanceToValue = value - points[0].x_sec
  const stepsNeeded = distanceToValue / stepSize
  return stepsNeeded
}
