import { useGetProcessStepCopyPasteAnalysisQuery } from '../../../../../app/apiSlice'
import { useQueryFilters } from '../../../../../common/hooks'
import { UserActivitiesCopyPastesView } from '../../UserActivitiesCopyPastes'

const StepUserActivitiesCopyPastes = () => {
  const { processFiltersWithZooming: filters, processId } = useQueryFilters()
  const { data, isFetching } = useGetProcessStepCopyPasteAnalysisQuery({ filters, processId })

  return <UserActivitiesCopyPastesView data={data} isFetching={isFetching} />
}

export default StepUserActivitiesCopyPastes
