import { useState } from 'react'

import { useCreateDataDeletionRequestMutation } from '../../app/apiSlice'
import { ActionModal, Button, Modal, Select, Tabs } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import { DeletionScopeEnum } from './types'

interface Props {
  open: boolean
  onClose: () => void
}

const RequestDataDeletion = ({ open, onClose }: Props) => {
  const { teams } = useDashboard()

  const [deletionScope, setDeletionScope] = useState<DeletionScopeEnum>(DeletionScopeEnum.COMPANY)
  const [teamSelected, setTeamSelectted] = useState<null | string>(null)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)

  const [createDataDeletionRequest, { isLoading }] = useCreateDataDeletionRequestMutation()

  return (
    <Modal label='Request Data Deletion' open={open} onClose={onClose}>
      <div className='space-y-4'>
        <Tabs
          options={[
            { label: 'Company', value: DeletionScopeEnum.COMPANY },
            { label: 'Team', value: DeletionScopeEnum.TEAM },
          ]}
          value={deletionScope}
          onChange={(val: string) => setDeletionScope(val as DeletionScopeEnum)}
        />

        {deletionScope === DeletionScopeEnum.TEAM ? (
          <Select
            label='Choose a team'
            options={[
              {
                label: 'None',
                value: null,
              },
              ...Object.values(teams).map(({ name, id }) => {
                return { label: name, value: id }
              }),
            ]}
            value={teamSelected}
            onChange={(val: string) => setTeamSelectted(val)}
          />
        ) : null}

        <div className='flex justify-end gap-4'>
          <Button variant='white'>Cancel</Button>
          <Button
            variant='primary'
            onClick={() => setIsConfirmationOpen(true)}
            disabled={
              deletionScope === DeletionScopeEnum.TEAM
                ? teamSelected === null ?? Boolean(teamSelected)
                : false
            }
          >
            Request
          </Button>
        </div>
      </div>

      <ActionModal
        variant='destructive'
        label='Are you sure you want to make a data deletion request?'
        actionLabel='Request'
        open={isConfirmationOpen}
        onAction={() => {
          createDataDeletionRequest({ deletion_scope: deletionScope, team_id: teamSelected })
            .unwrap()
            .then(() => {
              setIsConfirmationOpen(false)
              onClose()
            })
        }}
        onClose={() => {
          setIsConfirmationOpen(false)
        }}
        loading={isLoading}
      />
    </Modal>
  )
}

export default RequestDataDeletion
