// import { useParams } from 'react-router-dom'
import { Button, Text } from '../../common/designs'

const CSVExport = () => {
  // const { processId } = useParams()

  return (
    <div className='space-y-2 p-4'>
      <Text size='l' weight={500}>
        CSV Export
      </Text>
      <Text>Export event log for the process in CSV format</Text>

      <Button disabled>Download CSV</Button>
    </div>
  )
}

export default CSVExport
