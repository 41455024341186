import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import {
  useDeleteCaseCategorySettingsMutation,
  useUpdateCaseCategorySettingsMutation,
} from '../../app/apiSlice'
import type { BasicColorIndex } from '../../app/theme'
import { Button, ColorPicker, InputField, Modal } from '../../common/designs'
import { caseValidator } from '../../common/types/validators'
import type { CaseCategory } from '../Cases/types'

const formSchema = yup
  .object({
    case_category_name: caseValidator.case_category_name,
    color_index: caseValidator.color_index,
  })
  .required()

type Inputs = {
  case_category_name: string
  color_index: BasicColorIndex
}

interface Props {
  activeCaseCategory: CaseCategory
  open: boolean
  onClose: () => void
}

const EditCaseCategoryModal = ({ activeCaseCategory, open, onClose }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(formSchema) as Resolver<Inputs>,
    defaultValues: {
      case_category_name: activeCaseCategory.name,
      color_index: activeCaseCategory.color_index,
    },
  })

  const [updateCaseCategorySettings, { isLoading: isUpdateLoading }] =
    useUpdateCaseCategorySettingsMutation()
  const [deleteCaseCategorySettings, { isLoading: isDeleteLoading }] =
    useDeleteCaseCategorySettingsMutation()

  const onEdit: SubmitHandler<Inputs> = (data: Inputs) => {
    const body = {
      name: data.case_category_name,
      color_index: data.color_index,
    }

    updateCaseCategorySettings({ categoryId: activeCaseCategory.id, body })
      .unwrap()
      .then(() => onClose())
  }

  const onDelete = () => {
    deleteCaseCategorySettings(activeCaseCategory.id)
      .unwrap()
      .then(() => onClose())
  }

  return (
    <Modal label='Edit an opportunity category' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onEdit)} className='flex flex-col gap-3'>
        <Controller
          name='case_category_name'
          control={control}
          render={({ field }) => (
            <InputField
              autoFocus
              label='Category name'
              autoComplete='off'
              error={errors.case_category_name?.message}
              {...field}
            />
          )}
        />

        <ColorPicker
          size='s'
          label='Color'
          value={watch('color_index')}
          onChange={(i) => setValue('color_index', i)}
        />

        <div className='mt-2 flex justify-end gap-3'>
          <Button variant='white' onClick={onClose}>
            Cancel
          </Button>

          <Button variant='destructive' onClick={onDelete} loading={isDeleteLoading}>
            Delete
          </Button>

          <Button type='submit' loading={isUpdateLoading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditCaseCategoryModal

