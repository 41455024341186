import clsx from 'clsx'
import { Draggable } from 'react-beautiful-dnd'

import { Text } from '../../../../../../common/designs'
import {
  useAppDispatch,
  useAppSelector,
  useCostUtils,
  useDashboard,
} from '../../../../../../common/hooks'
import { selectCaseById, setActiveCaseId } from '../../../../casesSlice'
import {
  CategoryBox,
  CollaboratorRow,
  CommentBubble,
  LikeButton,
} from '../../../CaseDetails/components'

interface Props {
  id: number
  index: number
}

const LaneItem = ({ id, index }: Props) => {
  const dispatch = useAppDispatch()
  const { user } = useDashboard()
  const { formatCost } = useCostUtils()
  const item = useAppSelector((state) => selectCaseById(state, id)) // eslint-disable-line react-redux/useSelector-prefer-selectors

  const onCaseClick = () => dispatch(setActiveCaseId(id))

  if (!item) return null

  const renderItem = () => (
    <div id={`case-${id}-index-order`}>
      <div className='flex flex-col items-start'>
        <div className='mb-2 flex h-full w-[200px] justify-between text-left'>
          <Text
            variant='h6'
            size='m'
            weight={500}
            className='max-w-[216px] overflow-hidden text-ellipsis'
          >
            {item.title}
          </Text>

          <Text size='xs' className='ml-1.5'>
            {id}
          </Text>
        </div>

        <div className='flex w-full justify-between'>
          <Text size='s' color='lightGray' className='block'>
            {formatCost(item.business_case_cost - item.implementation_cost)}
          </Text>

          <CategoryBox id={item.category_id} />
        </div>

        <div className='mt-2 flex w-[200px] content-end items-center justify-between'>
          <div className='flex'>
            <CollaboratorRow assigneeId={item.assignee_id} collaborators={item.collaborators} />
          </div>

          <div className='flex gap-1'>
            <CommentBubble amount={item.comments.length} />
            <LikeButton caseId={item.id} likes={item.likes} />
          </div>
        </div>
      </div>
    </div>
  )

  const rootStyle = 'select-none w-[232px] h-full rounded-md mb-4 p-4 shadow-md'

  // Only NON-data readers can drag and drop items in kanban
  if (!user.isDataReader) {
    return (
      <Draggable key={`draggable-${id}`} draggableId={id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            className={rootStyle}
            {...{ ref: provided.innerRef }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: snapshot.isDragging ? 'hsla(0, 0%, 97%, 1)' : 'white',
            }}
            onClick={onCaseClick}
            key={`lane-item-${id}`}
          >
            {renderItem()}
          </div>
        )}
      </Draggable>
    )
  }

  return (
    <div className={clsx(rootStyle, 'bg-white')} onClick={onCaseClick} key={`lane-item-${id}`}>
      {renderItem()}
    </div>
  )
}

export default LaneItem
