import { AnalysisRouteTitles, AnalysisRoutes } from './constants'

export enum TagFilteringLogicEnum {
  LATEST_VALUE = 'LATEST_VALUE',
  VALUE_HISTORY = 'VALUE_HISTORY',
}
export interface CustomDimensionData {
  id: number
  tag_filtering_logic: TagFilteringLogicEnum
  dimension_key: string
  dimension_name: string
  is_tag_dimension: boolean
}

export interface AnalysisNavigationLink {
  label: AnalysisRouteTitles
  route: AnalysisRoutes
}

export enum StepSpecificGroupByFieldTypeEnum {
  TEAM_ID = 'team_id',
  STEP_NAMES = 'step_names',
  TAG_NAME = 'tag_name',
  STEP_WORK_TYPE = 'step_work_type',
}

export interface StepSpecificGroupByField {
  field_type: StepSpecificGroupByFieldTypeEnum
  tag_name?: string
}

export enum ActiveTransactionAnalysisViewEnum {
  METRICS_ANALYSIS = 'METRICS_ANALYSIS',
  TIME_SERIES_ANALYSIS = 'TIME_SERIES_ANALYSIS',
  NORMAL_DISTRIBUTION_ANALYSIS = 'NORMAL_DISTRIBUTION_ANALYSIS',
}
