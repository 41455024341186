import { PreloadedState, StateFromReducersMapObject, configureStore } from '@reduxjs/toolkit'

import { casesSlice } from '../features/Cases/casesSlice'
import { processesSlice } from '../features/Processes/processesSlice'
import { apiSlice } from './apiSlice'
import { errorLogger } from './middlewares'

const rootReducer = {
  [casesSlice.name]: casesSlice.reducer,
  [processesSlice.name]: processesSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
}

export type RootState = StateFromReducersMapObject<typeof rootReducer>

export const configureAppStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    // @ts-expect-error: Redux Toolkit typing issue with exactOptionalPropertyTypes
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(errorLogger, apiSlice.middleware),
  })

const store = configureAppStore()

export default store

// Infer the `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch
