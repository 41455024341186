import { formatISO } from 'date-fns'
import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { useGetIdentifierCountQuery } from '../../app/apiSlice'
import { teamColorMap } from '../../app/theme'
import { InlineLoader, Loader } from '../../common/components'
import { Select, type SelectOption, Text } from '../../common/designs'
import { useDashboard } from '../../common/hooks'
import type { DateRange, IdentifierCount } from '../../common/types/monitoring'
import { GranularityEnum, OrganizationLevelEnum } from '../../common/types/monitoring'
import { generateUCTTimestamps } from '../../common/utils/dateTimeUtils'

const defaultSelectOption: SelectOption = {
  label: 'All Unique Identifier Counts',
  value: null,
}

interface Props {
  selectedDates: DateRange
}

const IdentifierOccurancesBarchart = ({ selectedDates }: Props) => {
  const { teams } = useDashboard()

  const [selectedIdentifierName, setSelectedIdentifierName] = useState<string | null>(null)

  const { data: rawData, isFetching } = useGetIdentifierCountQuery(
    {
      start_timestamp: formatISO(selectedDates.start ?? new Date()),
      end_timestamp: formatISO(selectedDates.end ?? new Date()),
      organization_level: OrganizationLevelEnum.TEAM,
    },
    { skip: selectedDates.isSelecting },
  )

  const { chartData, selectOptions } = useMemo(() => {
    if (!rawData) return { chartData: null, selectOptions: [defaultSelectOption] }

    // Generate all possible timestamps by granularity in order to show 0s in graphs as well
    const uniqueTimestamps = generateUCTTimestamps(selectedDates, GranularityEnum.DAY)
    // Collecting unique identifier names for the dropdown select options
    const uniqueIdentifierNames = new Set(rawData.map((item) => item.identifier_name))

    // Creating a Map to organize data by timestamp and team
    const dataPointsMap: Map<string, Record<string, IdentifierCount[]>> = new Map()
    uniqueTimestamps.forEach((timestamp) => dataPointsMap.set(timestamp, {}))
    rawData.forEach((item) => {
      if (!item.team_id) return
      const dataAtTimestamp = dataPointsMap.get(item.grouping_timestamp) ?? {}
      const teamData = dataAtTimestamp[item.team_id] ?? []
      dataPointsMap.set(item.grouping_timestamp, {
        ...dataAtTimestamp,
        [item.team_id]: [...teamData, item],
      })
    })

    // Generate chart data
    const chartData = {
      labels: uniqueTimestamps.map((timestamp) => timestamp.split('T')[0]),
      datasets: Object.values(teams).map((team) => {
        // Aggregate counts for each team and timestamp
        const teamData = uniqueTimestamps.map((timestamp) => {
          const dataForTimestamp = dataPointsMap.get(timestamp)?.[team.id] ?? []
          if (selectedIdentifierName) {
            const matchingDataPoint = dataForTimestamp.find(
              (item) => item.identifier_name === selectedIdentifierName,
            )
            return matchingDataPoint ? matchingDataPoint.unique_identifier_count : 0
          }

          return dataForTimestamp.reduce(
            (total, current) => total + current.unique_identifier_count,
            0,
          )
        })

        return {
          label: team.name,
          data: teamData,
          backgroundColor: teamColorMap[team.colorIndex],
        }
      }),
    }

    // Creating options for the select dropdown
    const selectOptions = [
      defaultSelectOption,
      ...[...uniqueIdentifierNames]
        .map((name) => ({ label: name, value: name }) as SelectOption)
        .sort((a, b) => a.label.localeCompare(b.label)),
    ]

    return { chartData, selectOptions }
  }, [rawData, teams, selectedIdentifierName, selectedDates])

  const uniqueIdentifierTotals = useMemo(() => {
    const totals = new Map<string, number>()

    rawData?.forEach((item) => {
      const currentTotal = totals.get(item.identifier_name) || 0
      totals.set(item.identifier_name, currentTotal + item.unique_identifier_count)
    })

    return [...totals]
      .map(([identifierName, totalValue]) => ({ name: identifierName, totalValue }))
      .sort((a, b) => b.totalValue - a.totalValue)
  }, [rawData])

  if (!chartData) return <Loader />

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <div className='mb-2 mr-2 flex justify-between gap-4'>
        <Text>Unique Identifiers Counts</Text>

        {isFetching ? <InlineLoader /> : null}

        <Select
          options={selectOptions}
          value={selectedIdentifierName}
          onChange={(val: string) => setSelectedIdentifierName(val)}
          className='w-80'
        />
      </div>

      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                font: {
                  family: 'Inter',
                },
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
        height='100px'
      />

      <div className='mt-8 flex flex-col'>
        <Text>Unique Identifier Count Totals</Text>

        <div className='grid grid-cols-1 gap-2 p-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6'>
          {uniqueIdentifierTotals.map((identifier) => {
            return (
              <div className='flex gap-2' key={identifier.name}>
                <Text size='s' weight={400} className='w-60'>
                  {identifier.name}
                </Text>

                <Text
                  size='s'
                  weight={500}
                  color={identifier.totalValue === 0 ? 'error' : 'primary'}
                >
                  {identifier.totalValue}
                </Text>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default IdentifierOccurancesBarchart
