import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { InputField } from '../../../common/designs'
import CompanySelect from './CompanySelect'
import CreateActionButton from './CreateActionButton'
import HelpButton from './HelpButton'
import ProfilePopover from './ProfilePopover'

const NavigationHeader = () => {
  return (
    <div className='flex h-15 items-center justify-between bg-background-header px-8'>
      <div className='flex items-center'>
        <img className='size-6' alt='Process Intelligence logo' src='/images/pi-logo-color.png' />

        <CompanySelect />
      </div>

      <div>
        <InputField
          size='s'
          iconStart={<MagnifyingGlassIcon />}
          rounded
          placeholder='Search'
          className='lg:w-60 xl:w-72 2xl:w-96'
          disabled
        />
      </div>

      <div className='flex items-center gap-2'>
        <div className='inline-flex rounded-md'>
          <CreateActionButton />
        </div>

        <HelpButton />

        <ProfilePopover />
      </div>
    </div>
  )
}

export default NavigationHeader

