import clsx from 'clsx'

import { teamBgColorMap } from '../../../../app/theme'
import { Divider, Text, Tooltip } from '../../../../common/designs'
import { formatNumber } from '../../../../common/utils/numberFormatting'
import type { NodeDistributionData } from './types'

interface Props {
  distributionData: NodeDistributionData[]
  title: string
}

const NodeDistribution = ({ distributionData, title }: Props) => {
  return (
    <>
      <Divider label={title} color='darkGray' className='my-3' />
      <div className='space-y-2'>
        {distributionData.map((t) => {
          return (
            <div key={t.name}>
              <Text>{t.name}</Text>
              <div className='flex h-5 w-full rounded-xl bg-gray-300'>
                <Tooltip
                  label={t.name}
                  text={`${formatNumber(t.percentage, 1)}% (${t.total})`}
                  placement='b'
                  size='l'
                  style={{ width: `${Math.max(t.percentage, 7)}%` }}
                  key={`${t.name}-${t.percentage}`}
                >
                  <div
                    style={{ backgroundColor: t.color }}
                    className={clsx(
                      'h-full rounded-xl',
                      t.colorIndex && teamBgColorMap[t.colorIndex],
                    )}
                  />
                </Tooltip>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NodeDistribution
