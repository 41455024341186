import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { Text } from '../../common/designs'
import { useEnhancedNavigate, useGetWindowNamesForApps } from '../../common/hooks'
import type { Tag } from '../../common/types/common'
import { MainPathEnum, SubPathEnum } from '../../common/types/routing'
import { compressAndEncodeJSON, decompressAndDecodeJSON } from '../../common/utils/jsonCompression'
import { getAppNameFromContentTags, getWindowNameFromContentTags } from './utils'

type LayerLevel = 'FIRST' | 'SECOND' | 'THIRD'

const { TRENDS, TEAMS, ACTIVITIES, NAVIGATIONS, NAVIGATIONS_SANKEY, COPY_PASTES } = SubPathEnum

const layerPaths = {
  FIRST: [TRENDS, TEAMS, ACTIVITIES, NAVIGATIONS],
  SECOND: [TRENDS, TEAMS, ACTIVITIES, NAVIGATIONS],
  THIRD: [TRENDS, NAVIGATIONS_SANKEY, COPY_PASTES, NAVIGATIONS],
}

const BusinessAppsLayout = () => {
  const { encodedAppTags, encodedWindowTags } = useParams()
  const location = useLocation()
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { data } = useGetWindowNamesForApps()

  const [tagFilters, setTagFilters] = useState<Tag[]>([])

  useEffect(() => {
    setTagFilters(
      encodedWindowTags
        ? decompressAndDecodeJSON<Tag[]>(encodedWindowTags as string)
        : encodedAppTags
          ? decompressAndDecodeJSON<Tag[]>(encodedAppTags as string)
          : [],
    )
  }, [encodedAppTags, encodedWindowTags])

  const appName = tagFilters && getAppNameFromContentTags(tagFilters)
  const windowName = tagFilters && getWindowNameFromContentTags(tagFilters)

  const getSubPath = (layer: LayerLevel) => {
    const foundPath = layerPaths[layer].find((path) => {
      return location.pathname.includes(path)
    })
    return foundPath ? `/${foundPath}` : ''
  }

  if (!data) return null

  return (
    <div className='flex h-[calc(100vh-228px)] gap-3'>
      <div className='w-60'>
        <Text weight={700} className='pl-3'>
          Business apps
        </Text>

        <nav className='mt-4 h-full overflow-y-auto overflow-x-hidden pr-2'>
          <div
            key='Show all'
            onClick={() => {
              navigate(`/${MainPathEnum.BUSINESS_APPS}${getSubPath('FIRST')}`)
              setTagFilters([])
            }}
            className='cursor-pointer'
          >
            <Text
              className={clsx(
                tagFilters.length ? 'border-gray-200' : 'border-primary-600 bg-primary-100',
                'rounded-r-md border-l-2 py-1.5 pl-3 ',
              )}
              weight={tagFilters.length ? 400 : 600}
              color={tagFilters.length ? 'gray' : 'primary'}
            >
              Show all
            </Text>
          </div>

          {data.map(({ application_name, windows }) => {
            const isAppSelected = application_name === appName

            return (
              <div key={application_name}>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    navigate(
                      `/${MainPathEnum.BUSINESS_APPS}/${compressAndEncodeJSON([
                        { key: 'appname', value: application_name },
                      ])}${getSubPath('SECOND')}`,
                    )
                  }}
                >
                  <Text
                    className={clsx(
                      'rounded-r-md border-l-2 border-gray-200 py-1.5 pl-3 text-gray-700',
                      isAppSelected && 'border-primary-600 bg-primary-100 text-primary-600',
                    )}
                    weight={isAppSelected ? 600 : 400}
                  >
                    {application_name}
                  </Text>
                </div>

                {isAppSelected &&
                  windows.map(({ window_name }) => {
                    const contentCategories = window_name
                      .split(' | ')
                      .map((c) => ({ key: 'content-category', value: c }))
                    const isWindowSelected =
                      getWindowNameFromContentTags(contentCategories) === windowName

                    if (!window_name) return null // Demo data seems to have some ghost windows.

                    return (
                      <div
                        key={window_name}
                        className={clsx(
                          'ml-4 cursor-pointer rounded-r-md border-l-2 border-gray-200 py-1 pl-3 text-gray-700 last:mb-5',
                          isWindowSelected &&
                            'border-primary-200 bg-primary-50 font-semibold text-primary-600',
                        )}
                        onClick={() => {
                          navigate(
                            `/${MainPathEnum.BUSINESS_APPS}/window/${compressAndEncodeJSON([
                              { key: 'appname', value: application_name },
                              ...contentCategories,
                            ])}${getSubPath('THIRD')}`,
                          )
                        }}
                      >
                        {window_name}
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </nav>
      </div>

      <div className='-my-2 w-[calc(100vw-305px)] overflow-y-auto p-2'>
        <Outlet context={{ tagFilters, appName }} />
      </div>
    </div>
  )
}

export default BusinessAppsLayout
