import type { NodeProps } from 'reactflow'

import { useAppSelector } from '../../../../common/hooks'
import { getBestTimeFormatForSeconds } from '../../../../common/utils/numberFormatting'
import { selectProcessProperties } from '../../processesSlice'
import NodeActionButtons from './NodeActionButtons'
import { StateDataModal } from './NodeDataModal'
import NodeLayout from './NodeLayout'
import type { ServerStateFlowNode } from './types'
import { useModalConfigurations } from './useModalConfigurations'
import { useZoomingFilters } from './useZoomingFilters'

const StateFlowNode = ({ data }: NodeProps<ServerStateFlowNode>) => {
  const { updateZoomingFilters, setStateModalFilters, clearStateModalFilters, zoomingFilters } =
    useZoomingFilters()
  const { selectedStateName, setStateModalStateName, closeStateModal } = useModalConfigurations()
  const { state_tag_key: stateTagKey } = useAppSelector(selectProcessProperties) || {}

  const {
    display_name: displayName,

    transactions,
    is_conformance_flow_node: isConformanceFlowNode,

    top_ports: topPorts,
    right_ports: rightPorts,
    bottom_ports: bottomPorts,
    left_ports: leftPorts,

    relative_share_of_transactions: relativeData,
    avg_duration_sec: avgDurationSec,
  } = data

  const isDataModalOpen = selectedStateName === displayName
  const timeString = getBestTimeFormatForSeconds(avgDurationSec)

  const onZoom = () => {
    updateZoomingFilters({
      stateName: displayName,
    })
  }

  const onOpenDataModal = () => {
    setStateModalStateName(displayName)
    setStateModalFilters(displayName)
  }

  const onCloseDataModal = () => {
    clearStateModalFilters()
    closeStateModal()
  }

  return (
    <>
      <NodeLayout
        ports={{ topPorts, rightPorts, bottomPorts, leftPorts }}
        relativeSize={relativeData}
        nodeName={displayName}
        timeText={timeString}
        transactionCount={transactions}
        isConformanceFlowNode={isConformanceFlowNode}
        actionButtons={
          <NodeActionButtons
            onZoom={stateTagKey ? onZoom : null}
            onShowMetrics={stateTagKey ? onOpenDataModal : null}
          />
        }
        isHighlightingConformance
      />

      <StateDataModal
        isOpen={Boolean(zoomingFilters?.state_name) && isDataModalOpen}
        onClose={onCloseDataModal}
        stateName={displayName}
      />
    </>
  )
}

export default StateFlowNode
