import clsx from 'clsx'

type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

const sizeStyles: { [key in Size]: string } = {
  xs: 'file:px-3 file:py-1 text-sm file:text-sm',
  s: 'file:px-3 file:py-1.5 text-sm file:text-sm',
  m: 'file:px-4 file:py-2 text-sm file:text-sm',
  l: 'file:px-4 file:py-2.5 text-sm file:text-sm',
  xl: 'file:px-4 file:py-3 text-base file:text-base',
}

interface Props {
  onChange: (files: File[]) => void
  // Props is missing value prop because inserting value for file input is prohibited due to security reasons.

  size?: Size
  className?: string
  label?: string
  description?: string
  error?: string | undefined
  disabled?: boolean
  rounded?: boolean
  multiple?: boolean
  accept?: string // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
}

const FileSelector = (props: Props) => {
  const {
    onChange,

    size = 'm',
    className,
    label,
    description,
    error,
    disabled,
    rounded,
    multiple,
    accept,
  } = props

  return (
    <div className={className}>
      {label && (
        <label className='mb-1 block text-sm font-medium text-gray-900' htmlFor='input-field'>
          {label}
        </label>
      )}

      <input
        className={clsx(
          sizeStyles[size],
          rounded ? 'rounded-full file:rounded-l-full' : 'rounded-md file:rounded-l-md',
          'file:bg-primary-600 hover:file:bg-primary-700 focus:border-primary-500 focus:ring-primary-500 file:disabled:bg-primary-400',
          'block w-full cursor-pointer border border-gray-300 bg-gray-50 text-gray-900 file:mr-4 file:border-none file:font-semibold file:text-white hover:file:cursor-pointer focus:outline-none focus:ring-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 file:disabled:cursor-not-allowed',
        )}
        id='input-field'
        aria-describedby='file-input-help'
        type='file'
        accept={accept}
        onChange={({ target }: any) => onChange(target.files)}
        multiple={multiple}
        disabled={disabled}
      />

      {(description || error) && (
        <p
          className={clsx(error ? 'text-red-600' : 'text-gray-500', 'mt-2 text-sm')}
          id={`${label}-file-input-help`}
        >
          {error ?? description}
        </p>
      )}
    </div>
  )
}

export default FileSelector
