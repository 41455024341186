import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { intlFormat } from 'date-fns'
import { Fragment, useState } from 'react'

import {
  useDeleteUserInvititationMutation,
  useDeleteUserMutation,
  useGetUserInvitationsQuery,
  useGetUserManagementListQuery,
} from '../../app/apiSlice'
import { ActionModal, Button, LinkButton, Table } from '../../common/designs'
import { UserRoleMappingEnum } from '../../common/types/common'
import { capitalizeFirstLetter } from '../../common/utils/stringFormatting'
import AddNewUserModal from './AddNewUserModal'
import EditUserModal from './EditUserModal'
import type { UserManagementUser } from './types'

const ManageUsers = () => {
  const [activeUser, setActiveUser] = useState<UserManagementUser | null>(null)
  const [activeUserToDelete, setActiveUserToDelete] = useState<UserManagementUser | null>(null)
  const [isAddNewUserModalOpen, setIsAddNewUserModalOpen] = useState<boolean>(false)

  const users = useGetUserManagementListQuery()
  const userInvitations = useGetUserInvitationsQuery()
  const [deleteUserInvitation] = useDeleteUserInvititationMutation()
  const [deleteUser] = useDeleteUserMutation()

  const convertRoleIdToLabel = (id: number) => {
    return capitalizeFirstLetter(UserRoleMappingEnum[id].toLowerCase()).replace('_', ' ')
  }

  const onDeleteUser = async () => {
    if (!activeUserToDelete) return
    await deleteUser(activeUserToDelete?.userId)
    setActiveUserToDelete(null)
  }

  return (
    <>
      <Table
        label='Pending User Invites'
        head={['Email', 'Role', 'Expiration time', 'Invited by', 'Action']}
        action={
          <Button
            disabled={userInvitations.isLoading}
            onClick={() => setIsAddNewUserModalOpen(true)}
            iconStart={<EnvelopeIcon />}
          >
            Invite a new user
          </Button>
        }
        body={
          userInvitations?.data?.map((invitation) => {
            return [
              invitation.email,
              convertRoleIdToLabel(invitation.role),
              intlFormat(new Date(invitation.expiration_date_time), {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }),
              users?.data?.userList.find((u) => u.userId === invitation.inviting_user)?.email,
              <LinkButton
                className='text-red-600'
                onClick={() => deleteUserInvitation(invitation.id)}
                key={invitation.email}
              >
                Delete
              </LinkButton>,
            ]
          }) ?? []
        }
      />

      <Table
        label='Active Users'
        head={['Email', 'Name', 'Role', 'Actions']}
        body={
          users?.data?.userList.map((u: UserManagementUser, i: number) => {
            return [
              u.email,
              u.username,
              u.roleName,
              <Fragment key={u.email}>
                <LinkButton onClick={() => setActiveUser(users?.data?.userList?.[i] || null)}>
                  Edit
                </LinkButton>
                <LinkButton
                  className='ml-3 text-red-600'
                  onClick={() => setActiveUserToDelete(users?.data?.userList?.[i] || null)}
                >
                  Delete
                </LinkButton>
              </Fragment>,
            ]
          }) ?? []
        }
      />

      {activeUser && (
        <EditUserModal
          activeUser={activeUser}
          onClose={() => setActiveUser(null)}
          onRoleChange={(roleIndex: number) => setActiveUser({ ...activeUser, role: roleIndex })}
        />
      )}

      <ActionModal
        variant='destructive'
        label='Are you sure you want to delete the user?'
        actionLabel='Delete'
        open={Boolean(activeUserToDelete)}
        onClose={() => setActiveUserToDelete(null)}
        onAction={onDeleteUser}
      />

      <AddNewUserModal
        open={isAddNewUserModalOpen}
        onClose={() => setIsAddNewUserModalOpen(false)}
      />
    </>
  )
}

export default ManageUsers
