import { getWeekInterval } from '../utils/dateTimeUtils'
import { useDashboard } from './'

const useCreateTrendDates = () => {
  const { dataDate } = useDashboard()
  const lastDataDate = new Date(dataDate.max)

  return [
    getWeekInterval(0, lastDataDate),
    getWeekInterval(-1, lastDataDate),
    getWeekInterval(-2, lastDataDate),
    getWeekInterval(-3, lastDataDate),
  ].map(({ start, end }) => ({
    min_date: start,
    max_date: end,
  }))
}

export default useCreateTrendDates
