import { TrashIcon } from '@heroicons/react/20/solid'

import { IconButton, SelectMultiple, Toggle } from '../../../../common/designs'
import { useDashboard } from '../../../../common/hooks'
import { isNumber, isStringArray } from '../../../../common/types/typeGuards'
import type { Teams } from '../../../../common/utils/UtilityWrapper/types'
import MatchAllInfoTooltip from './MatchAllInfoTooltip'
import type { DimensionFilterOption, FilterConfigData } from './types'

const filterOptionValueToString = (option: DimensionFilterOption, teams: Teams | null) => {
  // If the value is team id array => map the ids to team name and create a string out of them.
  if (teams && isStringArray(option)) {
    const teamNames = option.map((o) => teams[o].name || o)
    return teamNames.join(', ')
  }
  if (isStringArray(option)) return option.join(', ') // Create a string from the values.
  if (isNumber(option)) return option.toString() // Convert numeric values to strings.

  return option
}

interface Props {
  filter: FilterConfigData
  onRemoveFilter: (filterKey: string) => void
  onSetFilterValues: (filterKey: string, selectedOptions: DimensionFilterOption[]) => void
  shouldUseMatchAllEnabled: boolean
  shouldUseMatchAll: boolean
  onSetShouldUseMatchAll: (filterKey: string, shouldUseMatchAll: boolean) => void
  shouldMapTeamIds: boolean
}

const numberArrayFilterKeys = new Set([
  'Touch amount',
  'Process tasks',
  'Self loop amount',
  'Task amount',
  'Task amount',
  'Team amount',
])

const DimensionFilterInput = (props: Props) => {
  const {
    filter,
    onRemoveFilter,
    onSetFilterValues,
    shouldUseMatchAllEnabled,
    shouldUseMatchAll,
    onSetShouldUseMatchAll,
    shouldMapTeamIds,
  } = props
  const { key: filterKey, options = [], selectedOptions: value = [], isEditable } = filter
  const { teams } = useDashboard()
  const teamIdMapperInUse = shouldMapTeamIds ? teams : null

  // Create mapping from the original values to stringified values used with the select component.
  const isSortingByNumbers = numberArrayFilterKeys.has(filterKey)
  const optionMap: { [key: string]: DimensionFilterOption } = {}
  options.forEach((o) => (optionMap[filterOptionValueToString(o, teamIdMapperInUse)] = o))
  const stringValues = value.map((v) => filterOptionValueToString(v, teamIdMapperInUse))

  const onToggleChange = (val: boolean) => onSetShouldUseMatchAll(filterKey, val)
  const onDelete = () => onRemoveFilter(filterKey)
  const onChange = (val: string[]) => {
    onSetFilterValues(
      filterKey,
      val.map((o) => optionMap[o]),
    )
  }

  let selectOptions = Object.keys(optionMap)
    .map((o) => ({ label: o, value: o }))
    .sort((a, b) => {
      if (isSortingByNumbers) {
        return parseInt(a.value) - parseInt(b.value)
      }

      return a.label.localeCompare(b.label)
    })

  if (!selectOptions.length) {
    const val = stringValues[0]
    selectOptions = [{ label: val, value: val }]
  }

  return (
    <div className='mb-2 flex flex-col'>
      <div className='flex w-full items-end gap-3'>
        <SelectMultiple
          className='w-full'
          label={filterKey}
          options={selectOptions}
          value={stringValues}
          onChange={onChange}
          disabled={!isEditable}
        />

        <IconButton variant='secondary' icon={<TrashIcon />} onClick={onDelete} />
      </div>

      {shouldUseMatchAllEnabled ? (
        <div className='mt-2 flex items-center gap-2'>
          <Toggle
            value={shouldUseMatchAll}
            onChange={onToggleChange}
            size='s'
            label='Match all values'
          />
          <MatchAllInfoTooltip />
        </div>
      ) : null}
    </div>
  )
}

export default DimensionFilterInput

