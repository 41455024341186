import { useOutletContext } from 'react-router-dom'

import type { Tag } from '../../../common/types/common'
import TrendsChartView from '../trendAndTeamViews/TrendsChartView'
import { getWindowNameFromContentTags } from '../utils'

const Trends = () => {
  const { tagFilters, appName } = useOutletContext<{
    tagFilters: Tag[]
    appName: string
    contentName: string
    label: string
  }>()

  const contentName = getWindowNameFromContentTags(tagFilters)

  return (
    <div className='flex h-full flex-col'>
      <TrendsChartView
        tagsOfInterestSource='window'
        tagFilters={tagFilters}
        chartTitle={`Trends in ${appName} Window: ${contentName}`}
        relativeChartTitle={`Trends in ${appName} Window: ${contentName} - Relative`}
        onChartClick={() => null}
        useExactTagFilterMatch
      />
    </div>
  )
}

export default Trends
