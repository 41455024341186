import { GroupByFieldTypeEnum } from '../../types'
import ConstantDimensionAnalysis from './ConstantDimensionAnalysis'

export const StepAnalysis = () => {
  return (
    <ConstantDimensionAnalysis
      groupByField={GroupByFieldTypeEnum.STEP_NAMES}
      groupFilterKey='step_name_filters'
      label='Task analysis'
      isIndexPage
    />
  )
}

export default StepAnalysis

