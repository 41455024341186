import { BarChartAndTableDisplay, Loader } from '../../../../common/components'
import type { AnalysisChartConfigurationData } from '../../types'

interface Props {
  configurations: AnalysisChartConfigurationData
  isLoading: boolean
}

export const AnalysisChart = ({ configurations, isLoading }: Props) => {
  const { label, chartData, unit, onBarClick, description } = configurations

  if (isLoading) return <Loader />

  return (
    <BarChartAndTableDisplay
      label={label}
      description={description}
      data={chartData}
      unit={unit}
      tableHeaderValues={['Label', 'Value']}
      onClick={onBarClick}
      isExportable
      maxXLabelLength={40}
      height='350px'
    />
  )
}

export default AnalysisChart
