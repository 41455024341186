const AppError = () => {
  return (
    <div className='grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]'>
      <header className='mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8'>
        <span className='sr-only'>Your Company</span>
        <img
          className='h-10 w-auto sm:h-12'
          alt='Process Intelligence logo'
          src='/images/pi-logo-color.png'
        />
      </header>
      <main className='mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8'>
        <div className='max-w-lg'>
          <p className='text-base font-semibold leading-8 text-indigo-600'>500</p>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            Oops, something went wrong!
          </h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>
            Sorry, there might be something wrong with the application right now. Try again a bit
            later.
          </p>
          <div className='mt-10'>
            <a href='/sign-in' className='text-sm font-semibold leading-7 text-indigo-600'>
              <span aria-hidden='true'>&larr;</span> Back to Sign In
            </a>
          </div>
        </div>
      </main>

      <div className='hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block'>
        <img
          src='/images/sign-in.jpg'
          alt='Process Intelligence dashboard'
          className='absolute inset-0 object-cover'
        />
      </div>
    </div>
  )
}

export default AppError
