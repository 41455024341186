import NodeDistribution from './NodeDistribution'
import { useNodeHighlightData } from './useNodeHighlightData'

interface Props {
  stepName: string
}

const NodeWorkTypeDistribution = ({ stepName }: Props) => {
  const { isWorkTypeDistributionOpen, workTypeData } = useNodeHighlightData()

  if (!isWorkTypeDistributionOpen || !workTypeData || !workTypeData[stepName]) return null

  return <NodeDistribution distributionData={workTypeData[stepName]} title='Task work-type' />
}

export default NodeWorkTypeDistribution

