import { useCallback, useMemo, useState } from 'react'

import { useGetStepSystemDistributionQuery } from '../../../../../app/apiSlice'
import { BarChartAndTableDisplay, InlineLoader } from '../../../../../common/components'
import { Tabs, Text } from '../../../../../common/designs'
import { useQueryFilters } from '../../../../../common/hooks'
import { getBestTimeFormatTransformationForSeconds } from '../../../../../common/utils/numberFormatting'
import { WindowsRelationToStepEnum } from './types'

const windowDistributionTabs = [
  { label: 'Windows before the task', value: WindowsRelationToStepEnum.PREVIOUS_WINDOW },
  { label: 'Windows in multi-system flows', value: WindowsRelationToStepEnum.MULTI_SYSTEM_WINDOW },
  { label: 'Windows after the task', value: WindowsRelationToStepEnum.NEXT_WINDOW },
]

const metricTabs = [
  { label: 'Total time', value: 'total_duration_sec' },
  { label: 'Total visits', value: 'total_count' },
]

const desctiptions = {
  [WindowsRelationToStepEnum.PREVIOUS_WINDOW]:
    'Windows before the task analyzes the 5 most recent windows visited before the process task, giving you insights to the user journey.',
  [WindowsRelationToStepEnum.MULTI_SYSTEM_WINDOW]:
    'Windows in multi-system flows allows you to delve into the intricacies of your multisystem workfloww by examining the windows that occur during the multi-system process tasks. The tool excludes the main process window, so you can focus on the supporting windows and gain a deeper understanding of how they impact the overall process.',
  [WindowsRelationToStepEnum.NEXT_WINDOW]:
    'Windows after the task analyzes the 5 windows visited after the process task, giving you insights to the user journey.',
}

const RelatedWindows = () => {
  const { processFiltersWithZooming, processId } = useQueryFilters()
  const [selectedMetric, setSelectedMetric] = useState<'total_duration_sec' | 'total_count'>(
    'total_duration_sec',
  )
  const [selectedDistribution, setSelectedDistribution] = useState<WindowsRelationToStepEnum>(
    WindowsRelationToStepEnum.PREVIOUS_WINDOW,
  )

  const { data: windowDistribution, isFetching: isFetchingwWindows } =
    useGetStepSystemDistributionQuery({
      processId,
      filters: processFiltersWithZooming,
      window_relation_to_step: selectedDistribution,
    })

  const onTabSelected = useCallback(
    (val: string) => setSelectedDistribution(val as WindowsRelationToStepEnum),
    [],
  )

  const { unit, divider } = useMemo(() => {
    if (selectedMetric === 'total_count') {
      return { unit: 'visits', divider: 1 }
    }
    if (!windowDistribution || !windowDistribution[0]) {
      return { unit: 'seconds', divider: 1 }
    }

    return getBestTimeFormatTransformationForSeconds(windowDistribution[0].total_duration_sec)
  }, [selectedMetric, windowDistribution])

  return (
    <div className='flex flex-col gap-2  py-4'>
      <Text size='l' weight={600} className='ml-1'>
        Windows related to task
      </Text>
      <Tabs
        size='s'
        options={windowDistributionTabs}
        value={selectedDistribution}
        onChange={onTabSelected}
      />
      {isFetchingwWindows ? (
        <InlineLoader />
      ) : (
        <BarChartAndTableDisplay
          label='Window usage'
          description={desctiptions[selectedDistribution]}
          data={
            windowDistribution?.map((window) => ({
              label: window.window_name,
              value: window[selectedMetric] / divider,
            })) || []
          }
          unit={unit}
          tableHeaderValues={[
            'Window',
            selectedMetric === 'total_duration_sec' ? 'Time' : 'Visits',
          ]}
          height='300px'
          otherActions={[
            <Tabs
              size='xs'
              key='metric'
              options={metricTabs}
              value={selectedMetric}
              onChange={(val: string) =>
                setSelectedMetric(val as 'total_duration_sec' | 'total_count')
              }
            />,
          ]}
        />
      )}
    </div>
  )
}

export default RelatedWindows

