import { MagnifyingGlassIcon, PlusIcon, QueueListIcon } from '@heroicons/react/20/solid'
import { formatDistanceToNow } from 'date-fns'
import { useEffect, useState } from 'react'

import {
  useGetCompanyConfigFileRawQuery,
  useGetCompanyConfigMetadataQuery,
  useGetConfigTestReportQuery,
  useGetDashboardAppDataQuery,
  useGetDataCollectionConfigsQuery,
  useGetTestCasesQuery,
  useUploadCompanyConfigMutation,
} from '../../app/apiSlice'
import { Loader } from '../../common/components'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Breadcrumbs, Button, Divider, Link, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import type { RuleIn } from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import ApplicationTable from './ApplicationTable'
import RulesWithoutTestCases from './RulesWithoutTestCases'
import SystemDiscoveryModal from './SystemDiscoveryModal'
import { getAppListFromTestCases } from './dataHelpers'
import { ConfigureDataCollectionSubPathEnum } from './types'
import useManageServerTestCasesAndConfigs from './useManageServerTestCasesAndConfigs'
import { parseRawConfigFileToObject } from './utils'

const ConfigureDataCollection = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const [applicationList, setApplicationList] = useState<AppTestCaseGroup[]>([])
  const [rulesWithoutAppInfo, setRulesWithoutAppInfo] = useState<RuleIn[]>([])
  const [isSystemDiscoveryModalOpen, setIsSystemDiscoveryModalOpen] = useState<boolean>(false)
  const [isTestSectionOpen, setIsTestSectionOpen] = useState<boolean>(false)

  const { data: requirementsData, isLoading: isLoadingRequirementsData } = useGetTestCasesQuery()
  const { data: applicationMetaData, isLoading: isLoadingApplicationMetaData } =
    useGetDashboardAppDataQuery()
  const { data: ruleData, isLoading: isLoadingRuleData } = useGetDataCollectionConfigsQuery()
  const { data: configFileReport, isLoading: isLoadingConfigFileReport } =
    useGetConfigTestReportQuery()
  const { data: configFileRaw, isLoading: isLoadingConfigFileRaw } =
    useGetCompanyConfigFileRawQuery()
  const { data: companyConfigMetadata, isLoading: isLoadingCompanyConfigMetadata } =
    useGetCompanyConfigMetadataQuery()

  const [uploadCompanyConfig, uploadCompanyConfigRes] = useUploadCompanyConfigMutation()
  const { runConfigTests, isRunningConfigTests } = useManageServerTestCasesAndConfigs()

  // Update selectedAppTestCases if user modifies them.
  useEffect(() => {
    const { appData, rulesWithoutAppInfo } = getAppListFromTestCases(
      requirementsData?.test_cases ?? [],
      applicationMetaData ?? [],
      ruleData?.rules ?? [],
      configFileReport,
    )

    setApplicationList(appData.sort((a, b) => a.appName.localeCompare(b.appName)))
    setRulesWithoutAppInfo(rulesWithoutAppInfo)
  }, [requirementsData, applicationMetaData, ruleData, configFileReport])

  const updateText = companyConfigMetadata?.lastUpdateDate
    ? `${formatDistanceToNow(new Date(companyConfigMetadata?.lastUpdateDate))} ago`
    : 'never'

  const lastTestRunText = configFileReport?.saved_datetime_utc
    ? `${formatDistanceToNow(new Date(configFileReport.saved_datetime_utc + 'Z'))} ago`
    : 'never'

  const configFileObject = parseRawConfigFileToObject(configFileRaw)
  const deployedConfigVersion: string | undefined = configFileObject?.settings_properties?.version
  const configFileUpToDate = parseInt(deployedConfigVersion ?? '0') === ruleData?.version
  const testsUpToDate =
    parseInt(configFileReport?.test_case_version ?? '0') === requirementsData?.version &&
    parseInt(configFileReport?.settings_version ?? '0') === ruleData?.version
  const testsPass =
    (configFileReport?.is_passed ?? true) || (configFileReport?.failing_tests ?? []).length === 0
  const noTestsDefined =
    !(configFileReport?.is_passed ?? true) && (configFileReport?.failing_tests ?? [1]).length === 0

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      {isLoadingRequirementsData ||
      isLoadingApplicationMetaData ||
      isLoadingRuleData ||
      isLoadingConfigFileReport ||
      isLoadingCompanyConfigMetadata ||
      isLoadingConfigFileRaw ||
      isRunningConfigTests ||
      uploadCompanyConfigRes.isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='px-4'>
            <Text size='2xl' weight={700} className='mb-3'>
              Configure Data Collection
            </Text>

            <div className='flex gap-2'>
              <Button
                size='s'
                onClick={() => setIsSystemDiscoveryModalOpen(true)}
                iconStart={<MagnifyingGlassIcon />}
              >
                Start System Discovery
              </Button>

              <Button
                size='s'
                iconStart={<PlusIcon />}
                onClick={() => navigate(ConfigureDataCollectionSubPathEnum.ADD_CUSTOM_APP)}
              >
                Track New Application
              </Button>
              <Button
                size='s'
                iconStart={<QueueListIcon />}
                onClick={() => navigate(ConfigureDataCollectionSubPathEnum.APP_CATALOG)}
              >
                View Application Catalog
              </Button>

              <Button
                size='s'
                variant='white'
                onClick={() => navigate(ConfigureDataCollectionSubPathEnum.CONFIG_PORTAL)}
              >
                Advanced Setup
              </Button>
            </div>

            <div className='my-4 bg-white shadow-md ring-2 ring-black/5 md:rounded-lg'>
              <div className='grid grid-cols-2'>
                <div className='relative px-6 py-4'>
                  <Text size='l' weight={700} className='mb-2'>
                    Data Collection Settings
                  </Text>

                  {configFileUpToDate ? (
                    <Text size='l' className='mb-1 text-green-600'>
                      All up to date
                    </Text>
                  ) : (
                    <Text size='xl' className='mb-2 text-yellow-600'>
                      Unpublished Changes!
                    </Text>
                  )}

                  <Text size='s' className='mb-2'>
                    Last deployed <strong>{updateText}</strong>
                  </Text>

                  {!configFileUpToDate && (
                    <Button size='s' onClick={() => uploadCompanyConfig()}>
                      Publish Current Changes
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {isTestSectionOpen ? (
              <div className='my-4 bg-white shadow-md ring-2 ring-black/5 md:rounded-lg'>
                <div className='border-r border-r-gray-300 px-6 py-4'>
                  <Text size='l' weight={700} className='mb-2'>
                    Testing
                  </Text>
                  <Text size='s' className='mb-2'>
                    Last test run <strong>{lastTestRunText}</strong>
                  </Text>
                  <Text size='s' className='mb-2'>
                    Status:{' '}
                    <Text
                      size='s'
                      variant='span'
                      className={testsUpToDate ? 'text-green-600' : 'text-yellow-600'}
                    >
                      {testsUpToDate
                        ? 'Tests run on current config version'
                        : 'Changes to configs since last test run'}
                    </Text>
                  </Text>
                  <Text size='s' className='mb-2'>
                    Result:{' '}
                    <Text
                      size='s'
                      variant='span'
                      className={
                        noTestsDefined
                          ? 'text-yellow-600'
                          : testsPass
                            ? 'text-green-600'
                            : 'text-red-600'
                      }
                    >
                      {noTestsDefined
                        ? 'No tests defined'
                        : testsPass
                          ? 'All Tests Passed'
                          : 'Failed Tests'}
                    </Text>
                  </Text>
                  <Button variant='white' size='s' onClick={() => runConfigTests()}>
                    Run Tests
                  </Button>
                </div>
              </div>
            ) : (
              <Link onClick={() => setIsTestSectionOpen(true)}>Show Tests</Link>
            )}

            <ApplicationTable applicationList={applicationList} />
            <RulesWithoutTestCases rulesWithoutAppInfo={rulesWithoutAppInfo} />
          </div>
        </div>
      )}
      <SystemDiscoveryModal
        open={isSystemDiscoveryModalOpen}
        onClose={() => setIsSystemDiscoveryModalOpen(false)}
      />
    </>
  )
}

export default ConfigureDataCollection
