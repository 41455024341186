// No idea why linting complains of duplicate date-fns imports when they don't exist in the same module...
// eslint-disable-next-line import/no-duplicates
import { isSameDay, isToday } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import enGB from 'date-fns/locale/en-GB'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { GLOBAL_DATE_FORMAT } from '../../common/types/datetime'
import { DateRange } from '../../common/types/monitoring'
import { roundToNextHour } from '../../common/utils/dateTimeUtils'

interface Props {
  selectedDates: DateRange
  setSelectedDates: React.Dispatch<React.SetStateAction<DateRange>>
}

const DateSelector = ({ selectedDates, setSelectedDates }: Props) => {
  return (
    <DatePicker
      selected={selectedDates.start}
      maxDate={new Date()}
      onChange={([start, end]) => {
        // Prevert user from selecting same date as start and end because it
        // messes up the charts and we don't want that.
        if (start && end && isSameDay(start, end)) return

        const startDefaultTimestamp = new Date(new Date(start ?? '').setHours(0, 0, 0, 0))
        let endDefaultTimestamp: Date | string | null = end
        if (end) {
          const isEndDateToday = isToday(end)
          endDefaultTimestamp = isEndDateToday
            ? roundToNextHour(new Date())
            : new Date(new Date(end).setHours(23, 59, 59))
        }

        setSelectedDates({
          start: startDefaultTimestamp,
          end: endDefaultTimestamp,
          isSelecting: Boolean(!start || !end),
        })
      }}
      startDate={selectedDates.start}
      endDate={selectedDates.end}
      dateFormat={GLOBAL_DATE_FORMAT}
      locale={enGB}
      selectsRange
      className='cursor-pointer rounded-md border-gray-300 font-inter text-sm text-gray-900'
    />
  )
}

export default DateSelector
