import * as yup from 'yup'

import { maxInteger } from '../../app/constants'

const validator = {
  title: yup
    .string()
    .max(200, 'Title cannot be more than 200 characters long')
    .required('Title is a required field'),
  description: yup.string().max(9999, 'Description cannot be more than 9999 characters long'),
  assignee_id: yup.number().required('Assignee is a required field'),
  category_id: yup.number().required('Category is a required field'),
  businessCaseCost: yup
    .number()
    .typeError('Annualised saving must be a number')
    .min(0, 'Annualised saving cannot be lower than 0')
    .max(maxInteger)
    .required('Annualised saving is a required field'),
  implementationCost: yup
    .number()
    .typeError('Implementation cost must be a number')
    .min(0, 'Implementation cost cannot be lower than 0')
    .max(maxInteger)
    .required('Implementation cost is a required field'),
  priority: yup
    .string()
    .max(200, 'Priority cannot be more than 200 characters long')
    .required('Priority is a required field'),
  complexity: yup
    .string()
    .max(200, 'Complexity cannot be more than 200 characters long')
    .required('Complexity is a required field'),
  collaborators: yup.array(),
}

export default validator
