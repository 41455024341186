import { Dialog, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Fragment, ReactElement, useRef } from 'react'

import { Button } from './'

const IconMapping: { [key in Variant]: ReactElement } = {
  primary: (
    <div className='mx-auto flex size-12 items-center justify-center rounded-full bg-blue-100'>
      <InformationCircleIcon className='size-6 text-blue-600' aria-hidden='true' />
    </div>
  ),
  success: (
    <div className='mx-auto flex size-12 items-center justify-center rounded-full bg-green-100'>
      <CheckIcon className='size-6 text-green-600' aria-hidden='true' />
    </div>
  ),
  destructive: (
    <div className='mx-auto flex size-12 items-center justify-center rounded-full bg-red-100'>
      <ExclamationTriangleIcon className='size-6 text-red-600' aria-hidden='true' />
    </div>
  ),
}

type Variant = 'primary' | 'destructive' | 'success'

interface Props {
  open: boolean
  label: string
  onAction: () => void
  actionLabel: string

  variant?: Variant
  className?: string
  onClose?: () => void
  description?: string
  loading?: boolean
}

const ActionModal = (props: Props) => {
  const {
    open,
    label,
    onAction = () => null,
    actionLabel,

    variant = 'primary',
    className,
    onClose,
    description,
    loading,
  } = props

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className={clsx('relative z-50', className)}
        initialFocus={cancelButtonRef}
        onClose={onClose ?? (() => null)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500/60 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  {IconMapping[variant]}

                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                      {label}
                    </Dialog.Title>

                    {description && (
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={clsx(
                    'mt-5 sm:mt-6',
                    onClose && 'sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3',
                  )}
                >
                  <Button
                    variant={variant}
                    className='inline-flex w-full justify-center sm:col-start-2'
                    onClick={onAction}
                    loading={Boolean(loading)}
                  >
                    {actionLabel}
                  </Button>

                  {onClose && (
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm'
                      onClick={onClose}
                      ref={cancelButtonRef} // Couldn't get design system button's reference to work.
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ActionModal
