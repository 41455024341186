import clsx from 'clsx'
import { ReactElement } from 'react'

interface Props {
  children: ReactElement | ReactElement[]
  className?: string
}

const ContentDisplay = ({ children, className }: Props) => {
  return (
    <div className={clsx('rounded-lg bg-white shadow-md ring-2 ring-black/5', className)}>
      {children}
    </div>
  )
}

export default ContentDisplay
