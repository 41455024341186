import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  BuildingOffice2Icon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Checkboxes, Divider, Link, Popover, Tabs, Text } from '../../../common/designs'
import { useDashboard, useEnhancedSearchParams } from '../../../common/hooks'
import { AdminMainPathEnum } from '../../../common/types/routing'
import { GlobalSearchParamEnum } from '../../../common/types/searchParams'
import {
  compressAndEncodeJSON,
  decompressAndDecodeJSON,
} from '../../../common/utils/jsonCompression'
import { useGetTeamGroupQuery } from '../../apiSlice'
import { teamBgColorMap } from '../../theme'

enum TabIndexEnum {
  ALL = 'all',
  TEAMS = 'teams',
  GROUPS = 'groups',
}

const tabOptions = [
  {
    label: 'All teams',
    value: TabIndexEnum.ALL,
    prefix: <BuildingOffice2Icon className='size-4' />,
  },
  {
    label: 'Teams',
    value: TabIndexEnum.TEAMS,
    prefix: <UsersIcon className='size-4' />,
  },
  {
    label: 'Team Groups',
    value: TabIndexEnum.GROUPS,
    prefix: <UserGroupIcon className='size-4' />,
  },
]

const getDefaultTabActive = (searchParams: URLSearchParams) => {
  if (searchParams.has(GlobalSearchParamEnum.TEAM_GROUP)) return TabIndexEnum.GROUPS
  if (searchParams.has(GlobalSearchParamEnum.TEAM_IDS)) return TabIndexEnum.TEAMS
  return TabIndexEnum.ALL
}

const TeamSelect = () => {
  const [searchParams] = useSearchParams()
  const { bulkUpdateSearchParams } = useEnhancedSearchParams()
  const { teams, user } = useDashboard()

  const [tabActive, setTabActive] = useState<TabIndexEnum>(getDefaultTabActive(searchParams))
  const [teamsActive, setTeamsActive] = useState<string[]>([])
  const [teamGroupActive, setTeamGroupActive] = useState<string | null>(null)
  const [checkboxSelected, setCheckboxSelected] = useState<string[]>([])

  const { data: teamGroups } = useGetTeamGroupQuery()
  useEffect(() => {
    if (teamGroups && searchParams.has(GlobalSearchParamEnum.TEAM_GROUP)) {
      setTeamGroupActive(searchParams.get(GlobalSearchParamEnum.TEAM_GROUP))
    } else {
      setTeamGroupActive(null)
    }
  }, [teamGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!searchParams.has(GlobalSearchParamEnum.TEAM_IDS)) {
      // All teams are shown.
      setTeamsActive([])
      setCheckboxSelected([])
    } else {
      if (
        searchParams.has(GlobalSearchParamEnum.TEAM_IDS) &&
        !searchParams.has(GlobalSearchParamEnum.TEAM_GROUP)
      ) {
        // Initialize correct checkbox state if Teams tab had selections.
        const encodedTeamIds = searchParams.get(GlobalSearchParamEnum.TEAM_IDS)
        const teamIds = decompressAndDecodeJSON<string[]>(encodedTeamIds as string)
        setCheckboxSelected(teamIds.filter((id) => id))
      }

      // Set active teams visible from search params.
      const encodedTeamIds = searchParams.get(GlobalSearchParamEnum.TEAM_IDS)
      const teamIds = decompressAndDecodeJSON<string[]>(encodedTeamIds as string)
      setTeamsActive(teamIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.get(GlobalSearchParamEnum.TEAM_IDS), // eslint-disable-line react-hooks/exhaustive-deps
    searchParams.get(GlobalSearchParamEnum.TEAM_GROUP), // eslint-disable-line react-hooks/exhaustive-deps
  ])

  const teamOptions = useMemo(() => {
    return Object.values(teams).map((team) => {
      return {
        label: team.name,
        value: team.id,
        prefix: <div className={clsx('size-3 rounded', teamBgColorMap[team.colorIndex])}></div>,
      }
    })
  }, [teams])

  const teamGroupOptions = useMemo(() => {
    return (
      teamGroups?.map((teamGroup) => {
        return {
          label: teamGroup.group_name,
          value: teamGroup.group_id,
          prefix: <>({teamGroup.group_members.length})</>,
        }
      }) ?? []
    )
  }, [teamGroups])

  const activeTeamGroupName = useMemo(() => {
    return teamGroups?.find(
      (teamGroup) => teamGroup.group_id === searchParams.get(GlobalSearchParamEnum.TEAM_GROUP),
    )?.group_name
  }, [teamGroups, searchParams.get(GlobalSearchParamEnum.TEAM_GROUP)]) // eslint-disable-line react-hooks/exhaustive-deps

  const teamGroupTeamsVisualized = useMemo(() => {
    const teamGroup = teamGroups?.find((group) => group.group_id === teamGroupActive)

    return (
      teamGroup?.group_members.map((teamId) => {
        const team = Object.values(teams).find((team) => team.id === teamId)
        if (!team) return null

        return (
          <div className='mt-2 flex items-baseline gap-2' key={teamId}>
            <div className={clsx('size-3 rounded', teamBgColorMap[team.colorIndex])} />

            <Text size='s'>{team.name}</Text>
          </div>
        )
      }) ?? []
    )
  }, [teamGroupActive, teamGroups, teams])

  const onApply = () => {
    let teamIds: string[] = []

    switch (tabActive) {
      case TabIndexEnum.ALL:
        return bulkUpdateSearchParams([
          [GlobalSearchParamEnum.TEAM_IDS, null],
          [GlobalSearchParamEnum.TEAM_GROUP, null],
        ])

      case TabIndexEnum.TEAMS:
        teamIds = checkboxSelected
        const teamIdsParamValue = teamIds.length ? compressAndEncodeJSON(teamIds) : null

        return bulkUpdateSearchParams([
          [GlobalSearchParamEnum.TEAM_IDS, teamIdsParamValue],
          [GlobalSearchParamEnum.TEAM_GROUP, null],
        ])

      case TabIndexEnum.GROUPS:
        if (!teamGroupActive) return

        const teamGroupMembers = teamGroups?.find((group) => group.group_id === teamGroupActive)
        teamIds = teamGroupMembers?.group_members ?? []
        const encodedTeamIds = compressAndEncodeJSON(teamIds)

        return bulkUpdateSearchParams([
          [GlobalSearchParamEnum.TEAM_IDS, encodedTeamIds],
          [GlobalSearchParamEnum.TEAM_GROUP, teamGroupActive],
        ])
    }
  }

  return (
    <>
      <Popover
        action={
          <div className='flex h-[30px] items-center justify-between p-2 text-sm'>
            <div className='flex items-center gap-3'>
              <>
                {teamsActive.length ? (
                  <>
                    <Text size='s'>
                      ({teamsActive.length}) {activeTeamGroupName ?? 'Teams'}
                    </Text>

                    {teamsActive.map((teamId) => {
                      const team = Object.values(teams).find((team) => team.id === teamId)
                      return team ? (
                        <div
                          className={clsx('size-3 rounded', teamBgColorMap[team.colorIndex])}
                          key={team.id}
                        />
                      ) : null
                    })}
                  </>
                ) : (
                  <>
                    <BuildingOffice2Icon className='size-4' />
                    <p>All teams</p>
                  </>
                )}
              </>
            </div>

            <div>
              <ChevronDownIcon className='size-5 text-gray-400' />
            </div>
          </div>
        }
        btnClassName='h-[30px] w-[300px] rounded-md border border-gray-300 bg-white focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500'
      >
        <div className='flex w-[432px] flex-col'>
          <div className='p-4'>
            <Tabs
              options={tabOptions}
              value={tabActive}
              onChange={setTabActive as React.Dispatch<React.SetStateAction<string>>}
            />

            <div className='mt-4'>
              {tabActive === TabIndexEnum.ALL && (
                <Text size='s' weight={500}>
                  All teams have been selected as a filter.
                </Text>
              )}

              {tabActive === TabIndexEnum.TEAMS && (
                <>
                  <Text size='s' weight={500} className='mb-2'>
                    Select the teams you want to filter by.
                  </Text>

                  <Checkboxes
                    options={teamOptions}
                    values={checkboxSelected}
                    onChange={setCheckboxSelected}
                    className='!space-y-2'
                  />
                </>
              )}

              {tabActive === TabIndexEnum.GROUPS && (
                <>
                  <Text size='s' weight={500} className='mb-2'>
                    Select the team group want to filter by.
                  </Text>

                  <Tabs
                    options={teamGroupOptions}
                    value={teamGroupActive}
                    onChange={setTeamGroupActive}
                    maxRowLength={1}
                  />

                  {teamGroupTeamsVisualized.length ? (
                    <>{teamGroupTeamsVisualized}</>
                  ) : (
                    <Text size='xs' color='gray' weight={500} className='mt-2'>
                      Tip: Admin users can add team groups from the{' '}
                      {user.isAdmin ? (
                        <Link href={AdminMainPathEnum.TEAM_GROUPS} underline>
                          admin panel.
                        </Link>
                      ) : (
                        ' admin panel.'
                      )}
                    </Text>
                  )}
                </>
              )}
            </div>
          </div>

          <Divider />
          <div className='flex justify-end gap-4 p-4'>
            <Button iconEnd={<ArrowPathIcon />} onClick={onApply}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default TeamSelect

