import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'

import type { BasicColorIndex } from '../app/theme'
import type { HTTPException, WindowNamesForApps, WindowNamesForAppsBody } from '../common/types/api'
import type {
  BaseCompany,
  Company,
  JsonObject,
  NewTeam,
  Tag,
  Team,
  TeamGroup,
} from '../common/types/common'
import type {
  Application,
  ApplicationCreateInput,
  ApplicationUpdateInput,
} from '../common/types/dataCollectionsConfigs'
import type {
  IdentifierCountParams,
  IdentifierCountPayload,
  PerformanceReadingsParams,
  PerformanceReadingsPercentilesParams,
  PerformanceReadingsPercentilesPayload,
  PluginPingsParams,
  PluginPingsPayload,
} from '../common/types/monitoring'
import type { ProcessFiltersStorageContent } from '../common/types/storage'
import type { DashboardInitialization, FileStore } from '../common/utils/UtilityWrapper/types'
import type {
  ConvertSocialTokenToAccessTokenPayload,
  ConvertedToken,
} from '../features/Authentication/types'
import { getAccessToken } from '../features/Authentication/useAuthentication'
import { WindowNavigationEvent } from '../features/BusinessApps/ThirdLayer/types'
import type {
  ActivityByApplicationData,
  ActivityData,
  ActivityInsightsBody,
  ApplicationCategoriesResult,
  ComputerCountBody,
  DataflowData,
  GroupedWindowUsageData,
  UserInteractionEvents,
  UserInteractionEventsBody,
  WindowDataflowBody,
  WindowUsageData,
} from '../features/BusinessApps/types'
import type {
  Activity,
  Attachment,
  CaseBase,
  CaseCategory,
  Collaborator,
  CreateAttachmentPayload,
  CreateCollaboratorPayload,
  CreateCommentPayload,
  DeleteAttachmentPayload,
  KanbanRes,
  Like,
  StrippedCase,
  UpdateAttachmentPayload,
  UpdateCaseOrderPayload,
  UpdateCasePayload,
  User,
} from '../features/Cases/types'
import type {
  CatalogApp,
  ConfigMetadata,
  ConfigTestReport,
  RequirementUploadParams,
  RequirementVersion,
  Requirements,
  Rules,
  RunTestsParams,
} from '../features/ConfigureDataCollectionAdminPanelPage/types'
import type { RuleIn } from '../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type {
  DataDeletionRequest,
  DeletionScopeEnum,
} from '../features/DataDeletionAdminPanelPage/types'
import type {
  UserInvitation,
  UserManagementUser,
} from '../features/ManageUsersAdminPanelPage/types'
import type {
  NetworkDataFlowsParams,
  NetworkDataFlowsResponse,
  NetworkNavigationsResponse,
} from '../features/Network/types'
import type { ProcessIdentifierMapper, RecommendationData } from '../features/Overview/types'
import type {
  PerformanceReadingCpu,
  PerformanceReadingMemory,
} from '../features/PerformanceMonitoringAdminPanelPage/types'
import type { CustomDimensionData } from '../features/Processes/ProcessMining/Analysis/types'
import type {
  FilterDimemsionOptionData,
  FilterDimemsionOptionParams,
  FilterUserActionOptionData,
} from '../features/Processes/ProcessMining/Filters/types'
import type {
  StateThroughputTimeData,
  StateThroughputTimeParams,
  StepAnalysisResult,
  StepGroupQueryParams,
  StepMetricQueryParams,
  StepMetricResult,
  SystemDistributionData,
  TaskFlowParams,
} from '../features/Processes/ProcessMining/Variants/NodeDataModal/types'
import type {
  AutomationInsight,
  ServerMetricsInStep,
  ServerStateVariantData,
  ServerStepInsightsActivities,
  ServerStepVariantData,
  ServerTeamVariantData,
  StepActivityQueryOptions,
  StepProcessMiningOptions,
  StepVariantHighlightingOptions,
  TaskMiningOptions,
  TeamAndStateProcessMiningOptions,
  UserTask,
  WindowProcessMiningOptions,
} from '../features/Processes/ProcessMining/Variants/types'
import { ProcessProperties } from '../features/Processes/ProcessMining/types'
import type {
  ActiveWorkTrendData,
  AppUsageTrendData,
  ConformanceByVariantMetrics,
  ConformanceFlowsData,
  ConformanceMetrics,
  ConformanceQueryOptions,
  ConformanceTrendData,
  CopyPasteAnalysisQueryOptions,
  CreateConformanceFlowParams,
  DeleteConformanceFlowParams,
  EditConformanceFlowParams,
  GetConformanceFlowsParams,
  GroupBucketQueryParams,
  GroupQueryParams,
  InsightQueryOptions,
  MetricQueryParams,
  NewProcessGeneralInfo,
  NormalDistributionData,
  NormalDistributionQueryOptions,
  ProcessDataflowsQueryOptions,
  ProcessGeneralInfo,
  ProcessIdQueryParamsBase,
  ServerInsightsData,
  StateNameQueryParams,
  TransactionGroupResult,
  TransactionGroupResultMultiple,
  TransactionLogData,
  TransactionLogQueryOptions,
  TransactionMetricResult,
  TransactionSearchQueryParams,
  TrendData,
  TrendQueryParams,
  UploadAttachmentConformanceFlowParams,
  UserActionQueryOptions,
  UserActivitiesData,
} from '../features/Processes/types'
import type {
  PredefinedTaskAnalysisQueryOptions,
  PredefinedTaskAppUsageData,
  PredefinedTaskMetricData,
  PredefinedTaskSessionOrderData,
  TaskDiscoveryData,
  TaskFilterOptionParams,
  TaskListQueryOptions,
  TaskVariantData,
  TaskVariantOptions,
} from '../features/TaskDiscovery/types'
import { WEB_APP_URL_DASHBOARD_BACKEND } from './configs'

interface GetInsightsArgs {
  types?: string[]
}

export interface InsightType {
  id: string
  label: string
  color?: string
}

export interface Insight {
  id: string
  title: string
  description: string
  solution: string
  image_url?: string
  image_alt?: string
  types: InsightType[]
}

const baseQuery = fetchBaseQuery({
  baseUrl: WEB_APP_URL_DASHBOARD_BACKEND,
  prepareHeaders: (headers) => {
    const token = getAccessToken()
    if (token) headers.set('Authorization', `Bearer ${token}`)

    return headers
  },
})

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  keepUnusedDataFor: 60 * 30, // in seconds -- https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#reducing-subscription-time-with-keepunuseddatafor
  // TAGS >> https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#definitions
  tagTypes: [
    'DasboardSetup',
    'CaseCreated',
    'CaseActivity',
    'CaseAttachment',
    'CaseCategory',
    'User',
    'UserInvitation',
    'Company',
    'Team',
    'TeamUsernameList',
    'Application',
    'MyProfile',
    'TrackedProcess',
    'ConformanceFlows',
    'CompanyConfigFile',
    'CompanyConfigFileDB',
    'CompanyConfigFileTestReport',
    'TeamConfigFile',
    'Company',
    'TeamGroup',
    'CustomDimension',
    'UserTrend',
    'CompanyTrend',
    'CaseTrend',
    'DataDeletionRequest',
    'CatalogApps',
    'TemplateAppList',
    'ActiveRequirementFile',
    'xRayZipFile',
  ],
  endpoints: (build) => ({
    // ** Authentication ** //
    validateUserInvitation: build.query<void | { errMsg: string }, { email: string }>({
      query: (body) => ({
        url: '/accounts/validate-user-invitation',
        method: 'POST',
        body,
      }),
    }),
    getFileStoreToken: build.query<FileStore, void>({
      query: () => '/accounts/file-store-token',
    }),
    convertSocialTokenToAccessToken: build.mutation<
      ConvertedToken,
      ConvertSocialTokenToAccessTokenPayload
    >({
      query: (body) => ({
        url: '/auth/convert-token',
        method: 'POST',
        body,
      }),
    }),
    automaticSignIn: build.mutation<any, any>({
      query: (body) => ({
        url: '/auth/automatic-sign-in',
        method: 'POST',
        body,
      }),
    }),

    // ** Settings ** //
    getInitializeDashboard: build.query<DashboardInitialization, null>({
      query: () => '/configuration/initialize-dashboard',
      providesTags: ['DasboardSetup'],
    }),
    getWindowNamesForApps: build.query<WindowNamesForApps[], WindowNamesForAppsBody>({
      query: (body) => ({
        url: '/data-settings/app-window-names',
        method: 'POST',
        body,
      }),
    }),
    getUserCompanies: build.query<BaseCompany[], void>({
      query: () => '/accounts/company-list',
      providesTags: ['Company'],
    }),
    createCompany: build.mutation<
      void,
      { company_name: string; customer_status: string; agent_installation_target: number }
    >({
      query: (body) => ({
        url: '/company/companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    changeUserCompany: build.mutation<any, string | number>({
      query: (companyId) => ({
        url: '/accounts/change-company',
        method: 'POST',
        body: { company_id: companyId },
      }),
      invalidatesTags: ['DasboardSetup'],
    }),
    deleteUser: build.mutation<void, number>({
      query: (userId) => ({
        url: `/accounts/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),

    // ** Opportunities ** //
    // GET
    getKanban: build.query<KanbanRes, void>({
      query: () => '/cases/kanban',
      providesTags: ['CaseCreated'],
    }),
    getCaseActivity: build.query<Activity[], number>({
      query: (caseId) => `cases/${caseId}/activities`,
      providesTags: ['CaseCreated', 'CaseActivity'],
    }),
    getCaseAttachments: build.query<Attachment[], number>({
      query: (caseId) => `cases/${caseId}/attachments`,
      providesTags: ['CaseAttachment'],
    }),
    getUsers: build.query<User[], void | null>({
      query: () => '/cases/users',
      providesTags: ['User'],
    }),
    getCasesForTrendsDropdown: build.query<StrippedCase[], void>({
      query: () => '/cases/stripped',
      providesTags: ['CaseCreated'],
    }),

    // POST
    createCase: build.mutation<{ caseId: number }, { body: CaseBase }>({
      query: ({ body }) => ({
        url: `/cases/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CaseCreated'],
    }),
    createCaseTrend: build.mutation<any, { caseId: number; body: any }>({
      query: ({ caseId, body }) => ({
        url: `/data-trends/case-trends/${caseId}`,
        method: 'POST',
        body,
      }),
    }),
    createComment: build.mutation<null, CreateCommentPayload>({
      query: ({ caseId, body }) => ({
        url: `/cases/${caseId}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CaseActivity'],
    }),
    createLike: build.mutation<Like, { caseId: number }>({
      query: ({ caseId }) => ({
        url: `/cases/${caseId}/likes`,
        method: 'POST',
      }),
      invalidatesTags: ['CaseActivity'],
    }),
    createCollaborator: build.mutation<Collaborator, CreateCollaboratorPayload>({
      query: ({ caseId, body }) => ({
        url: `/cases/${caseId}/collaborators`,
        method: 'POST',
        body,
      }),
    }),
    createAttachment: build.mutation<Attachment[], CreateAttachmentPayload>({
      query: ({ caseId, body }) => ({
        url: `/cases/${caseId}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CaseAttachment', 'CaseActivity'],
    }),

    // PUT
    updateCase: build.mutation<null, UpdateCasePayload>({
      query: ({ caseId, body }) => ({
        url: `/cases/${caseId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CaseActivity'],
    }),
    updateAttachment: build.mutation<Attachment[], UpdateAttachmentPayload>({
      query: ({ caseId, attachmentId, body }) => ({
        url: `/cases/${caseId}/attachments/${attachmentId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CaseAttachment', 'CaseActivity'],
    }),
    updateKanbanOrder: build.mutation<null, UpdateCaseOrderPayload>({
      query: ({ caseId, body }) => ({
        url: `cases/${caseId}/order/update`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CaseActivity'],
    }),

    // DELETE
    deleteCase: build.mutation<null, number>({
      query: (caseId) => ({
        url: `/cases/${caseId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CaseCreated'],
    }),
    deleteAttachment: build.mutation<Attachment[], DeleteAttachmentPayload>({
      query: ({ caseId, attachmentId, body }) => ({
        url: `/cases/${caseId}/attachments/${attachmentId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['CaseAttachment', 'CaseActivity'],
    }),
    deleteLike: build.mutation<null, { caseId: number; likeId: number }>({
      query: ({ caseId, likeId }) => ({
        url: `/cases/${caseId}/likes/${likeId}`,
        method: 'DELETE',
      }),
    }),
    deleteCollaborator: build.mutation<null, { caseId: number; collaboratorId: number }>({
      query: ({ caseId, collaboratorId }) => ({
        url: `/cases/${caseId}/collaborators/${collaboratorId}`,
        method: 'DELETE',
      }),
    }),

    // ** Start ** //
    getRecommendations: build.query<RecommendationData[], void>({
      query: () => ({
        url: '/recommendations',
        method: 'GET',
      }),
    }),
    getProcessIdentifierMapper: build.query<ProcessIdentifierMapper, void>({
      query: () => `/process/tracked/identifier-mapper`,
      providesTags: [],
    }),

    // ** Business Apps ** //
    getWindowUsage: build.query<WindowUsageData[] | GroupedWindowUsageData, any>({
      query: ({ body }) => ({
        url: '/business-apps/window-usage',
        method: 'POST',
        body,
      }),
    }),
    getActivityInsightTimes: build.query<ActivityData[], ActivityInsightsBody>({
      query: (body) => ({
        url: '/business-apps/activity-insights',
        method: 'POST',
        body,
      }),
    }),
    getActivityInsightTimesByApplication: build.query<
      ActivityByApplicationData[],
      ActivityInsightsBody
    >({
      query: (body) => ({
        url: '/business-apps/activity-insights/application',
        method: 'POST',
        body,
      }),
    }),
    getWindowNavigations: build.query<
      { navigationsTo: WindowNavigationEvent[]; navigationsFrom: WindowNavigationEvent[] },
      any
    >({
      query: ({ body }) => ({
        url: '/business-apps/window-navigations',
        method: 'POST',
        body,
      }),
    }),
    getWindowDataflowsSource: build.query<DataflowData[], WindowDataflowBody>({
      query: (body) => ({
        url: '/business-apps/window-dataflows/source',
        method: 'POST',
        body,
      }),
    }),
    getWindowDataflowsDestination: build.query<DataflowData[], WindowDataflowBody>({
      query: (body) => ({
        url: '/business-apps/window-dataflows/destination',
        method: 'POST',
        body,
      }),
    }),
    getUserInteractionEvents: build.query<UserInteractionEvents, UserInteractionEventsBody>({
      query: (body) => ({
        url: '/business-apps/user-interaction-events',
        method: 'POST',
        body,
      }),
    }),
    getComputerCountData: build.query<number, ComputerCountBody>({
      query: (body) => ({
        url: '/monitoring/computer-id-count',
        method: 'POST',
        body,
      }),
    }),

    // ** NETWORK ** //
    getNetworkDataFlows: build.query<NetworkDataFlowsResponse, NetworkDataFlowsParams>({
      query: (body) => ({
        url: '/network/data-flows',
        method: 'POST',
        body,
      }),
    }),
    getNetworkNavigations: build.query<NetworkNavigationsResponse, NetworkDataFlowsParams>({
      query: (body) => ({
        url: '/network/navigations',
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - TASK DISCOVERY ** //
    getUniqueTags: build.query<Array<Tag[]>, void | { tags_of_interest: string[] }>({
      query: (body) => ({
        url: '/process/unique-tags',
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - VARIANTS ** //
    getStepFlowChart: build.query<ServerStepVariantData, StepProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-variants`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantTeamData: build.query<
      { [key: string]: ServerMetricsInStep[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-variants/step-teams`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantWorkTypeData: build.query<
      { [key: string]: ServerMetricsInStep[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-variants/step-work-types`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantAutomationInsightsData: build.query<
      { [key: string]: AutomationInsight[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-variants/automation-insights`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),

    getTeamFlowChart: build.query<ServerTeamVariantData, TeamAndStateProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/session-variants`,
        method: 'POST',
        body,
      }),
    }),
    getStateFlowChart: build.query<ServerStateVariantData, TeamAndStateProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/state-change-variants`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getWindowFlowChart: build.query<TaskVariantData, WindowProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/variants`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - CONFORMANCE ** //
    getProcessConformanceMetrics: build.query<ConformanceMetrics, ConformanceQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/conformance`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getProcessConformanceTrends: build.query<ConformanceTrendData[], ConformanceQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/conformance-trends`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getProcessConformanceMetricsByVariant: build.query<
      ConformanceByVariantMetrics[],
      ConformanceQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/conformance-by-variant`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),

    // ** PROCESSES - STEP WORK TYPE INSIGHTS ** //
    getProcessMiningStepActivities: build.query<
      ServerStepInsightsActivities,
      StepActivityQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-activities`,
        method: 'POST',
        body,
      }),
    }),
    getProcessInsights: build.query<ServerInsightsData[], InsightQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/variants/step-opportunities`,
        method: 'POST',
        body,
      }),
    }),
    getMultiSystemFlowTaskData: build.query<UserTask[], TaskMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/task-mining/multi-system-tasks`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - CONFIGS ** //
    getTrackedProcessesList: build.query<ProcessGeneralInfo[], void>({
      query: () => ({
        url: '/process/tracked',
      }),
      providesTags: ['TrackedProcess'],
    }),
    createTrackedProcess: build.mutation<null, NewProcessGeneralInfo | NewProcessGeneralInfo[]>({
      query: (body) => ({
        url: '/process/tracked',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TrackedProcess'],
    }),
    updateTrackedProcess: build.mutation<null, { processId: string; body: NewProcessGeneralInfo }>({
      query: ({ processId, body }) => ({
        url: `/process/tracked/${processId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['TrackedProcess'],
    }),
    deleteTrackedProcess: build.mutation<null, string>({
      query: (processId) => ({
        url: `/process/tracked/${processId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TrackedProcess'],
    }),
    getTrackedProcessProperties: build.query<ProcessProperties, string>({
      query: (processId) => ({
        url: `/process/tracked/${processId}`,
        method: 'GET',
      }),
      providesTags: ['TrackedProcess'],
    }),
    getConformanceFlows: build.query<ConformanceFlowsData[], GetConformanceFlowsParams>({
      query: ({ processId, conformanceType }) => ({
        url: `/process/tracked/${processId}/defined-conformance`,
        method: 'GET',
        params: { conformanceType },
      }),
      providesTags: ['ConformanceFlows'],
    }),
    createConformanceFlow: build.mutation<null, CreateConformanceFlowParams>({
      query: ({ processId, body }) => ({
        url: `/process/tracked/${processId}/defined-conformance`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ConformanceFlows'],
    }),
    editConformanceFlow: build.mutation<null, EditConformanceFlowParams>({
      query: ({ processId, conformanceId, body }) => ({
        url: `/process/tracked/${processId}/defined-conformance/${conformanceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ConformanceFlows'],
    }),
    deleteConformanceFlow: build.mutation<null, DeleteConformanceFlowParams>({
      query: ({ processId, conformanceId }) => ({
        url: `/process/tracked/${processId}/defined-conformance/${conformanceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ConformanceFlows'],
    }),
    uploadConformanceAttachment: build.mutation<void, UploadAttachmentConformanceFlowParams>({
      query: ({ body, processId, conformanceId }) => ({
        url: `/process/tracked/${processId}/defined-conformance/${conformanceId}/attachment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ConformanceFlows'],
    }),

    // ** PROCESSES - FILTER ** //
    getProcessFiltersUrl: build.query<
      { id: string; filters: ProcessFiltersStorageContent },
      string
    >({
      query: (filter_id) => ({
        url: `/process/tracked/filters/${filter_id}`,
        method: 'GET',
      }),
    }),
    createProcessFiltersUrl: build.mutation<
      { id: string; filters: ProcessFiltersStorageContent },
      { process_id: string; filters: ProcessFiltersStorageContent }
    >({
      query: (body) => ({
        url: `/process/tracked/filters`,
        method: 'POST',
        body,
      }),
    }),
    getFilterDimensionOptions: build.query<
      FilterDimemsionOptionData[],
      FilterDimemsionOptionParams
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/dimension-options`,
        method: 'POST',
        body,
      }),
    }),
    getStateNames: build.query<string[], StateNameQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/state-names`,
        method: 'POST',
        body,
      }),
    }),
    getFilterUserActionOptions: build.query<FilterUserActionOptionData, ProcessIdQueryParamsBase>({
      query: ({ processId }) => ({
        url: `/process/tracked/${processId}/transactions/user-action-options`,
        method: 'POST',
        body: { filters: {} },
      }),
    }),

    // ** PROCESSES - TRANSACTIONS ** //
    getTransactionMetrics: build.query<TransactionMetricResult, MetricQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/metrics`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/group`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionBucketGroup: build.query<TransactionGroupResult, GroupBucketQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/group/bucket`,
        method: 'POST',
        body,
      }),
    }),
    getSearchedTransactionId: build.query<string | null, TransactionSearchQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/transaction-by-name`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionStepGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/group/step`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionStepGroupMultiple: build.query<TransactionGroupResultMultiple, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/group/step/multiple`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionRelatedSystemGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/group/related-system`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - TRENDS ** /
    getTransactionTrends: build.query<TrendData[], TrendQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/trends`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionActiveWorkTrend: build.query<ActiveWorkTrendData[], TrendQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/active-work-trend`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - CUSTOM DIMENSIONS ** /
    getProcessCustomGroupByDimensions: build.query<CustomDimensionData[], string>({
      query: (processId) => ({
        url: `/process/tracked/${processId}/transactions/dimensions`,
        method: 'GET',
      }),
      providesTags: ['CustomDimension'],
    }),
    createCustomDimension: build.mutation<
      void,
      { processId: string; body: Omit<CustomDimensionData, 'id' | 'is_tag_dimension'> }
    >({
      query: ({ processId, body }) => ({
        url: `/process/tracked/${processId}/transactions/dimensions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CustomDimension'],
    }),
    updateCustomDimension: build.mutation<
      void,
      { processId: string; dimensionId: number; body: CustomDimensionData }
    >({
      query: ({ processId, dimensionId, body }) => ({
        url: `/process/tracked/${processId}/transactions/dimensions/${dimensionId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['CustomDimension'],
    }),
    deleteCustomDimension: build.mutation<void, { processId: string; dimensionId: number }>({
      query: ({ processId, dimensionId }) => ({
        url: `/process/tracked/${processId}/transactions/dimensions/${dimensionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomDimension'],
    }),
    getTotalAppUsageTrend: build.query<AppUsageTrendData[], void>({
      query: () => ({
        url: `/process/tracked/total-app-usage-trend`,
        method: 'POST',
      }),
    }),

    // ** PROCESSES - User Activities** //
    getProcessCopyPasteAnalysis: build.query<UserActivitiesData[], CopyPasteAnalysisQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/copy-pastes`,
        method: 'POST',
        body,
      }),
    }),
    getProcessUserActionAnalysis: build.query<UserActivitiesData[], UserActionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/user-actions`,
        method: 'POST',
        body,
      }),
    }),
    getProcessDataflows: build.query<NetworkDataFlowsResponse, ProcessDataflowsQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/network/${processId}/process-data-flows`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - TRANSACTION LOG** //
    getTransactionLog: build.query<TransactionLogData[], TransactionLogQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/transaction-log`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - NORMAL DISTRIBUTION** //
    getNormalDistributionData: build.query<NormalDistributionData, NormalDistributionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/transactions/normal-distribution`,
        method: 'POST',
        body,
      }),
    }),

    // ** PROCESSES - STEP ANALYSIS ** //
    getStepMetrics: build.query<StepMetricResult, StepMetricQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/metrics`,
        method: 'POST',
        body,
      }),
    }),
    getStepAnalysis: build.query<StepAnalysisResult, StepGroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/analysis`,
        method: 'POST',
        body,
      }),
    }),
    getStepSystemDistribution: build.query<SystemDistributionData[], TaskFlowParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/system-distribution`,
        method: 'POST',
        body,
      }),
    }),
    getStateThroughputTime: build.query<StateThroughputTimeData, StateThroughputTimeParams>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/state-throughput-time`,
        method: 'POST',
        body,
      }),
    }),
    getProcessStepCopyPasteAnalysis: build.query<
      UserActivitiesData[],
      CopyPasteAnalysisQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/copy-pastes`,
        method: 'POST',
        body,
      }),
    }),
    getProcessStepUserActionAnalysis: build.query<UserActivitiesData[], UserActionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `/process/tracked/${processId}/step-analysis/user-actions`,
        method: 'POST',
        body,
      }),
    }),

    // ** TASK DISCOVERY ** //
    getTaskDiscoveryList: build.query<TaskDiscoveryData[], TaskListQueryOptions>({
      query: (body) => ({
        url: `/task-discovery/task-list`,
        method: 'POST',
        body,
      }),
    }),
    getTaskFilterOptions: build.query<string[], TaskFilterOptionParams>({
      query: (body) => ({
        url: `/task-discovery/task-filter-options`,
        method: 'POST',
        body,
      }),
    }),
    getTaskVariants: build.query<TaskVariantData, TaskVariantOptions>({
      query: (body) => ({
        url: `/task-discovery/task-variants`,
        method: 'POST',
        body,
      }),
    }),

    // ** TASK DISCOVERY - PREDEFINED TASKS ** //
    getPredefinedTaskMetrics: build.query<
      PredefinedTaskMetricData,
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `/task-discovery/predefined-tasks/metrics`,
        method: 'POST',
        body,
      }),
    }),

    getPredefinedTaskAppUsage: build.query<
      PredefinedTaskAppUsageData[],
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `/task-discovery/predefined-tasks/app-usage`,
        method: 'POST',
        body,
      }),
    }),

    getPredefinedTaskSessionOrdering: build.query<
      PredefinedTaskSessionOrderData[],
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `/task-discovery/predefined-tasks/session-ordering`,
        method: 'POST',
        body,
      }),
    }),

    // ** Admin panel ** //
    getUserManagementList: build.query<
      { roleOptions: { [key: string]: string }; userList: UserManagementUser[] },
      void
    >({
      query: () => '/accounts/user-list',
      providesTags: ['User'],
    }),
    getUserInvitations: build.query<UserInvitation[], void>({
      query: () => '/accounts/user-invitations',
      providesTags: ['UserInvitation'],
    }),
    sendUserIntivation: build.mutation<UserInvitation, Pick<UserInvitation, 'email' | 'role'>>({
      query: (body) => ({
        url: '/accounts/user-invitations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserInvitation', 'User'],
    }),
    deleteUserInvititation: build.mutation<void, number>({
      query: (invitationId) => ({
        url: `/accounts/user-invitations/${invitationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserInvitation'],
    }),
    getTeams: build.query<Team[], void>({
      query: () => '/company/teams',
      providesTags: ['Team'],
    }),
    createTeams: build.mutation<Team, NewTeam>({
      query: (body) => ({
        url: `/company/teams`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Team', 'DasboardSetup'],
    }),
    updateTeams: build.mutation<Team, { teamId: number; body: NewTeam }>({
      query: ({ teamId, body }) => ({
        url: `/company/teams/${teamId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Team', 'DasboardSetup'],
    }),
    getTeamUsernameList: build.query<string[], string>({
      query: (invitationToken) => ({
        url: '/company/teams/username-list',
        params: { 'invitation-token': invitationToken },
      }),
      providesTags: ['TeamUsernameList'],
    }),
    updateTeamUsernameList: build.mutation<void, { invitationToken: string; usernameList: string }>(
      {
        query: ({ invitationToken, usernameList }) => ({
          url: `/company/teams/username-list`,
          method: 'PATCH',
          params: { 'invitation-token': invitationToken },
          body: { 'username-list': usernameList },
        }),
        invalidatesTags: ['TeamUsernameList'],
      },
    ),
    getCompanySettings: build.query<
      Pick<
        Company,
        'cost_per_hour' | 'currency' | 'currency_symbol_UTF8' | 'agent_installation_target'
      >,
      null
    >({
      query: () => '/company/company-settings',
      providesTags: ['Company'],
    }),
    getCaseCategories: build.query<CaseCategory[], void>({
      query: () => '/cases/categories',
      providesTags: ['CaseCategory'],
    }),
    updateUserRole: build.mutation<any, any>({
      query: ({ body }) => ({
        url: '/accounts/user-list',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    updateCompanySettings: build.mutation<
      any,
      {
        body: {
          cost_per_hour: number
          currency: string
          currency_symbol_UTF8: string
          agent_installation_target: number
        }
      }
    >({
      query: ({ body }) => ({
        url: '/company/company-settings',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Company', 'DasboardSetup'],
    }),
    updateCaseCategorySettings: build.mutation<
      any,
      { categoryId: number; body: { name: string; color_index: BasicColorIndex } }
    >({
      query: ({ categoryId, body }) => ({
        url: `/cases/categories/${categoryId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CaseCategory'],
    }),
    createCaseCategory: build.mutation<
      null,
      { body: { name: string; color_index: BasicColorIndex } }
    >({
      query: ({ body }) => ({
        url: `/cases/categories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CaseCategory'],
    }),
    deleteCaseCategorySettings: build.mutation<any, number>({
      query: (categoryId) => ({
        url: `/cases/categories/${categoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CaseCategory'],
    }),

    // ** Team Groups ** //
    getTeamGroup: build.query<TeamGroup[], void>({
      query: () => '/configuration/team-group',
      providesTags: ['TeamGroup'],
    }),
    createTeamGroup: build.mutation<null, { body: Omit<TeamGroup, 'group_id'> }>({
      query: ({ body }) => ({
        url: '/configuration/team-group',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TeamGroup'],
    }),
    updateTeamGroup: build.mutation<null, { id: string; body: Omit<TeamGroup, 'group_id'> }>({
      query: ({ id, body }) => ({
        url: `/configuration/team-group/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TeamGroup'],
    }),
    deleteTeamGroup: build.mutation<null, { id: string }>({
      query: ({ id }) => ({
        url: `/configuration/team-group/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeamGroup'],
    }),

    // ** Config Studio ** //
    // Db Configs: What is deployed to DB (waiting for prod deployment).
    getDataCollectionConfigs: build.query<Rules, void>({
      query: () => ({
        url: '/config-studio/db-configs',
      }),
      providesTags: ['CompanyConfigFileDB'],
    }),
    uploadCompanyDbConfigs: build.mutation<RequirementVersion, { rules: RuleIn[] }>({
      query: (body) => ({
        url: '/config-studio/db-configs',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyConfigFileDB'],
    }),

    // Configs: What is deployed in prod.
    getCompanyConfigFileRaw: build.query<string[], void>({
      query: () => ({
        url: '/config-studio/configs',
      }),
      providesTags: ['CompanyConfigFile'],
    }),
    getCompanyConfigMetadata: build.query<ConfigMetadata, void>({
      query: () => ({
        url: '/config-studio/configs/metadata',
      }),
      providesTags: ['CompanyConfigFile'],
    }),

    uploadCompanyConfig: build.mutation<void, void>({
      query: () => ({
        url: '/config-studio/configs',
        method: 'POST',
      }),
      invalidatesTags: ['CompanyConfigFile'],
    }),
    deleteCompanyConfig: build.mutation<void, void>({
      query: () => ({
        url: '/config-studio/configs',
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyConfigFile'],
    }),

    getTeamConfigDownloadUrl: build.query<string[], { invitationToken: string }>({
      query: ({ invitationToken }) => ({
        url: `/config-studio/configs/${invitationToken}`,
      }),
      providesTags: ['TeamConfigFile'],
    }),
    getTeamConfigMetadata: build.query<ConfigMetadata, { invitationToken: string }>({
      query: ({ invitationToken }) => ({
        url: `/config-studio/configs/${invitationToken}/metadata`,
      }),
      providesTags: ['TeamConfigFile'],
    }),
    uploadTeamConfig: build.mutation<void, { invitationToken: string; body: JsonObject }>({
      query: ({ body, invitationToken }) => ({
        url: `/config-studio/configs/${invitationToken}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TeamConfigFile'],
    }),
    deleteTeamConfig: build.mutation<void, { invitationToken: string }>({
      query: ({ invitationToken }) => ({
        url: `/config-studio/configs/${invitationToken}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TeamConfigFile'],
    }),

    updateConfigSource: build.mutation<
      null,
      { invitationToken: string; body: { configuration_source: string } }
    >({
      query: ({ invitationToken, body }) => ({
        url: `/config-studio/configs/${invitationToken}/configuration-source`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['TeamConfigFile', 'CompanyConfigFile', 'Team'],
    }),
    downloadTrainingData: build.query<string, string>({
      query: (applicationName) => ({
        url: '/config-studio/download-training-data',
        params: {
          application_name: applicationName,
        },
      }),
    }),
    getToolingLink: build.query<string, void>({
      query: () => '/config-studio/tooling-link',
    }),

    // ** Catalog - OLD ** //
    getCatalogAppList: build.query<CatalogApp[], void>({
      query: () => ({
        url: '/config-studio/catalog-apps',
      }),
      providesTags: ['CatalogApps'],
    }),

    // ** Data Collection Templates ** //
    getTemplateDashboardAppData: build.query<Application[], void>({
      query: () => '/config-studio/templates/applications',
    }),
    getTemplateProcessData: build.query<ProcessGeneralInfo[], void>({
      query: () => '/config-studio/templates/processes',
    }),
    getTemplateDataCollectionConfigs: build.query<Rules, void>({
      query: () => ({
        url: '/config-studio/templates/configs',
      }),
    }),
    getTemplateTestCases: build.query<Requirements, void>({
      query: () => ({
        url: '/config-studio/templates/test-cases',
      }),
    }),

    // ** X-ray file management ** //
    getXRayZipFileDownloadLink: build.query<string, { zip_name: string }>({
      query: (params) => ({ url: '/config-studio/x-ray/zip', params }),
    }),
    uploadXrayZipFile: build.mutation<ProcessGeneralInfo[], FormData>({
      query: (body) => ({
        url: '/config-studio/x-ray/zip',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['xRayZipFile'],
    }),
    getXrayZipFileList: build.query<string[], void>({
      query: () => ({
        url: '/config-studio/x-ray/zip/list',
      }),
      providesTags: ['xRayZipFile'],
    }),

    // ** Data collection configs ** //
    // Applications
    getDashboardAppData: build.query<Application[], void>({
      query: () => '/data-collection-configs/applications',
      providesTags: ['Application'],
    }),
    createDataCollectionsConfigApplication: build.mutation<
      Application,
      ApplicationCreateInput | ApplicationCreateInput[]
    >({
      query: (body) => ({
        url: '/data-collection-configs/applications/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Application'],
    }),
    updateDataCollectionsConfigApplication: build.mutation<Application, ApplicationUpdateInput>({
      query: ({ id, ...body }) => ({
        url: `/data-collection-configs/applications/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Application'],
    }),
    deleteDataCollectionsConfigApplication: build.mutation<Application, string>({
      query: (id) => ({
        url: `/data-collection-configs/applications/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Application'],
    }),

    getInstallerLink: build.query<string | null, void>({
      query: () => '/data-collection-configs/installer-link',
    }),
    getApplicationCategories: build.query<ApplicationCategoriesResult, void>({
      query: () => '/data-collection-configs/application-categories',
      providesTags: ['Application'],
    }),

    // Insights
    getInsights: build.query<Insight[], void | GetInsightsArgs>({
      query: (params) => {
        const url = '/insights'
        return params ? { url, params } : url
      },
    }),
    getInsightTypes: build.query<InsightType[], void>({
      query: () => '/insights/types',
    }),

    // ** Data collection configs ** //
    getConfigTestReport: build.query<ConfigTestReport, void>({
      query: () => '/config-studio/config-management/config-file-upload-report',
      providesTags: ['CompanyConfigFileTestReport'],
    }),
    runConfigTests: build.mutation<ConfigTestReport, RunTestsParams>({
      query: (body) => ({
        url: '/config-studio/config-management/run-tests',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyConfigFileTestReport'],
    }),
    validateDataCollectionRules: build.mutation<null | HTTPException, { rules: RuleIn[] }>({
      query: (body) => ({
        url: '/config-studio/config-management/validate-rules',
        method: 'POST',
        body,
      }),
    }),
    getTestCases: build.query<Requirements, void>({
      query: () => ({
        url: '/config-studio/requirement-management/download-requirements',
      }),
      providesTags: ['ActiveRequirementFile'],
    }),
    uploadRequirements: build.mutation<RequirementVersion | HTTPException, RequirementUploadParams>(
      {
        query: (body) => ({
          url: '/config-studio/requirement-management/upload-requirements',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['ActiveRequirementFile'],
      },
    ),

    // ** Monitoring ** //
    getAppMonitorStatus: build.query<any, any>({
      query: ({ body }) => ({
        url: '/monitoring/app',
        method: 'POST',
        body,
      }),
    }),
    getProcessMonitorStatus: build.query<
      Array<{ process_name: string; total_duration_sec: number }>,
      void
    >({
      query: () => '/monitoring/process',
    }),

    getPluginPingsStatus: build.query<PluginPingsPayload, PluginPingsParams>({
      query: (params) => ({
        url: '/monitoring/plugin-pings',
        params,
      }),
    }),
    getIdentifierCount: build.query<IdentifierCountPayload, IdentifierCountParams>({
      query: (params) => ({
        url: '/monitoring/identifier-count',
        params,
      }),
    }),
    getPerformanceReadings: build.query<
      PerformanceReadingCpu[] | PerformanceReadingMemory[],
      PerformanceReadingsParams
    >({
      query: (params) => ({
        url: '/monitoring/performance-readings',
        params,
      }),
    }),
    getPerformanceReadingsPercentiles: build.query<
      PerformanceReadingsPercentilesPayload,
      PerformanceReadingsPercentilesParams
    >({
      query: (params) => ({
        url: '/monitoring/performance-readings/percentiles',
        params,
      }),
    }),

    // ** Data Deletion ** //
    getDataDeletionRequests: build.query<DataDeletionRequest[], void>({
      query: () => ({
        url: '/utils/data-deletion-requests',
      }),
      providesTags: ['DataDeletionRequest'],
    }),
    createDataDeletionRequest: build.mutation<
      void,
      { deletion_scope: DeletionScopeEnum; team_id: null | string }
    >({
      query: (body) => ({
        url: '/utils/data-deletion-requests',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DataDeletionRequest'],
    }),
    updateDataDeletionRequest: build.mutation<void, number>({
      query: (id) => ({
        url: `/utils/data-deletion-requests/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['DataDeletionRequest'],
    }),

    // ** Utils ** //
    getDynamicPost: build.query<any, { url: string; body: object }>({
      query: ({ url, body }) => ({
        url,
        method: 'POST',
        body,
      }),
    }),
  }),
})

// Naming rules for hooks below:
// 1) use, the normal prefix for any React hook
// 2) The name of the endpoint, capitalized
// 3) The type of the endpoint, Query OR Mutation
// use<endpoint_name_capitalized><Query|Mutation>
export const {
  // ** Auth ** //
  useLazyValidateUserInvitationQuery,
  useLazyGetFileStoreTokenQuery,
  useConvertSocialTokenToAccessTokenMutation,
  useAutomaticSignInMutation,

  // ** Settings ** //
  useGetInitializeDashboardQuery,
  useGetWindowNamesForAppsQuery,
  useGetUserCompaniesQuery,
  useCreateCompanyMutation,
  useChangeUserCompanyMutation,
  useDeleteUserMutation,

  // ** Cases ** //
  useGetKanbanQuery,
  useGetCaseActivityQuery,
  useGetUsersQuery,
  useGetCasesForTrendsDropdownQuery,
  useGetCaseAttachmentsQuery,

  useCreateCaseMutation,
  useCreateCommentMutation,
  useCreateCaseTrendMutation,
  useCreateAttachmentMutation,
  useCreateLikeMutation,
  useCreateCollaboratorMutation,

  useUpdateCaseMutation,
  useUpdateAttachmentMutation,
  useUpdateKanbanOrderMutation,

  useDeleteCaseMutation,
  useDeleteAttachmentMutation,
  useDeleteLikeMutation,
  useDeleteCollaboratorMutation,

  // ** Start ** //
  useGetRecommendationsQuery,
  useGetProcessIdentifierMapperQuery,

  // ** Business Apps ** //
  useGetWindowUsageQuery,
  useGetActivityInsightTimesQuery,
  useGetActivityInsightTimesByApplicationQuery,
  useGetWindowNavigationsQuery,

  useGetWindowDataflowsSourceQuery,
  useGetWindowDataflowsDestinationQuery,
  useGetUserInteractionEventsQuery,
  useGetComputerCountDataQuery,

  // ** Work ** //
  useGetNetworkDataFlowsQuery,
  useGetNetworkNavigationsQuery,

  // ** Processes ** //
  useGetUniqueTagsQuery,
  useGetTrackedProcessesListQuery,
  useCreateTrackedProcessMutation,
  useUpdateTrackedProcessMutation,
  useDeleteTrackedProcessMutation,
  useGetStepFlowChartQuery,
  useGetStepVariantTeamDataQuery,
  useGetStepVariantWorkTypeDataQuery,
  useGetStepVariantAutomationInsightsDataQuery,
  useGetTeamFlowChartQuery,
  useGetStateFlowChartQuery,
  useGetWindowFlowChartQuery,
  useGetProcessMiningStepActivitiesQuery,
  useGetMultiSystemFlowTaskDataQuery,
  useGetProcessConformanceMetricsQuery,
  useGetProcessConformanceTrendsQuery,
  useGetProcessConformanceMetricsByVariantQuery,
  useGetProcessInsightsQuery,
  useGetConformanceFlowsQuery,
  useGetTrackedProcessPropertiesQuery,
  useCreateConformanceFlowMutation,
  useEditConformanceFlowMutation,
  useDeleteConformanceFlowMutation,
  useUploadConformanceAttachmentMutation,

  useGetTransactionMetricsQuery,
  useGetTransactionGroupQuery,
  useGetTransactionBucketGroupQuery,
  useLazyGetSearchedTransactionIdQuery,
  useGetTransactionStepGroupQuery,
  useGetTransactionStepGroupMultipleQuery,
  useLazyGetTransactionStepGroupMultipleQuery,
  useGetTransactionRelatedSystemGroupQuery,
  useGetTransactionTrendsQuery,
  useGetTransactionActiveWorkTrendQuery,
  useGetProcessCustomGroupByDimensionsQuery,
  useCreateCustomDimensionMutation,
  useUpdateCustomDimensionMutation,
  useDeleteCustomDimensionMutation,
  useGetFilterDimensionOptionsQuery,
  useGetStateNamesQuery,
  useGetFilterUserActionOptionsQuery,
  useGetTotalAppUsageTrendQuery,

  // ** PROCESSES - FILTERS ** //
  useLazyGetProcessFiltersUrlQuery,
  useCreateProcessFiltersUrlMutation,

  // ** PROCESSES - User Activities** //
  useGetProcessCopyPasteAnalysisQuery,
  useGetProcessUserActionAnalysisQuery,
  useGetProcessDataflowsQuery,

  // ** PROCESSES - TRANSACTION LOG** //
  useGetTransactionLogQuery,
  useLazyGetTransactionLogQuery,

  // ** PROCESSES - NORMAL DISTRIBUTION** //
  useGetNormalDistributionDataQuery,

  // ** PROCESSES - STEP ANALYSIS ** //
  useGetStepMetricsQuery,
  useGetStepAnalysisQuery,
  useGetStepSystemDistributionQuery,
  useGetStateThroughputTimeQuery,
  useGetProcessStepCopyPasteAnalysisQuery,
  useGetProcessStepUserActionAnalysisQuery,

  // ** TASK DISCOVERY ** //
  useGetTaskDiscoveryListQuery,
  useLazyGetTaskFilterOptionsQuery,
  useGetTaskVariantsQuery,

  // ** TASK DISCOVERY - PREDEFINED TASKS ** //
  useGetPredefinedTaskMetricsQuery,
  useGetPredefinedTaskAppUsageQuery,
  useGetPredefinedTaskSessionOrderingQuery,

  // ** Admin panel ** //
  useGetUserManagementListQuery,
  useGetUserInvitationsQuery,
  useSendUserIntivationMutation,
  useDeleteUserInvititationMutation,
  useGetTeamsQuery,
  useCreateTeamsMutation,
  useUpdateTeamsMutation,
  useGetTeamUsernameListQuery,
  useUpdateTeamUsernameListMutation,
  useGetCompanySettingsQuery,
  useGetCaseCategoriesQuery,

  useUpdateCompanySettingsMutation,
  useUpdateCaseCategorySettingsMutation,
  useUpdateUserRoleMutation,
  useCreateCaseCategoryMutation,
  useDeleteCaseCategorySettingsMutation,

  // ** Team Groups ** //
  useGetTeamGroupQuery,
  useCreateTeamGroupMutation,
  useUpdateTeamGroupMutation,
  useDeleteTeamGroupMutation,

  // ** Config Studio ** //
  useGetDataCollectionConfigsQuery,
  useUploadCompanyDbConfigsMutation,
  useLazyGetCompanyConfigFileRawQuery,
  useGetCompanyConfigFileRawQuery,
  useLazyGetTeamConfigDownloadUrlQuery,
  useGetCompanyConfigMetadataQuery,
  useGetTeamConfigMetadataQuery,
  useUpdateConfigSourceMutation,
  useUploadCompanyConfigMutation,
  useUploadTeamConfigMutation,
  useDeleteCompanyConfigMutation,
  useDeleteTeamConfigMutation,
  useLazyDownloadTrainingDataQuery,
  useGetToolingLinkQuery,
  useGetCatalogAppListQuery,

  // ** Data Collection Templates ** //
  useGetTemplateDashboardAppDataQuery,
  useGetTemplateProcessDataQuery,
  useGetTemplateDataCollectionConfigsQuery,
  useGetTemplateTestCasesQuery,

  // ** X-ray file management ** //
  useLazyGetXRayZipFileDownloadLinkQuery,
  useUploadXrayZipFileMutation,
  useGetXrayZipFileListQuery,

  // ** Tracked apps and categories ** //
  useGetDashboardAppDataQuery,
  useGetApplicationCategoriesQuery,

  // Installer link
  useGetInstallerLinkQuery,

  // Insights
  useGetInsightsQuery,
  useGetInsightTypesQuery,

  // ** Data collection configs ** //
  useGetConfigTestReportQuery,
  useValidateDataCollectionRulesMutation,
  useGetTestCasesQuery,
  useUploadRequirementsMutation,
  useRunConfigTestsMutation,
  useCreateDataCollectionsConfigApplicationMutation,
  useUpdateDataCollectionsConfigApplicationMutation,
  useDeleteDataCollectionsConfigApplicationMutation,

  // ** Monitoring ** //
  useGetProcessMonitorStatusQuery,
  useGetPluginPingsStatusQuery,
  useGetIdentifierCountQuery,
  useGetPerformanceReadingsQuery,
  useGetPerformanceReadingsPercentilesQuery,
  useGetAppMonitorStatusQuery,

  // ** Data Deletion ** //
  useGetDataDeletionRequestsQuery,
  useCreateDataDeletionRequestMutation,
  useUpdateDataDeletionRequestMutation,

  // ** Utils ** //
  usePrefetch, // https://redux-toolkit.js.org/rtk-query/usage/prefetching
  useGetDynamicPostQuery,
} = apiSlice
