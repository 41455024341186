import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetProcessMiningStepActivitiesQuery } from '../../../app/apiSlice'
import { Loader } from '../../../common/components'
import { Modal } from '../../../common/designs'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import StepActivityPotential from './StepActivityPotential'
import { ActivityLabelEnum } from './stepActivityConstants'

interface Props {
  onClose: () => void
  isOpen: boolean
  stepName: string
  stepActivity: string
  insightName: string
}

const InsightsModal = ({ onClose, isOpen, stepName, stepActivity, insightName }: Props) => {
  const { trackedProcessId = '' } = useParams()

  const { data } = useGetProcessMiningStepActivitiesQuery(
    {
      filters: {},
      processId: trackedProcessId,
      step_name: stepName,
    },
    {
      skip: !stepName,
    },
  )

  const { processPotential, workPotential } = useMemo(() => {
    const dataMapper = {
      [ActivityLabelEnum.QUICK_PROCESSING]: data?.quick_processing.improvement_potential,
      [ActivityLabelEnum.FOCUSED_WORK]: data?.focused_work.improvement_potential,
      [ActivityLabelEnum.MULTI_SYSTEM_WORKFLOWS]:
        data?.multi_system_workflows.improvement_potential,
    }

    const improvementPotential = dataMapper[stepActivity as ActivityLabelEnum]
    if (!data || !improvementPotential) return { processPotential: null, workPotential: null }

    return {
      processPotential: [
        {
          label: 'Lead time to task (avg)',
          value: getBestTimeFormatForSeconds(improvementPotential.leadtime_to_step_sec_avg),
        },
      ],
      workPotential: [
        {
          label: 'Annualized work time in task (total)',
          value: getBestTimeFormatForSeconds(improvementPotential.annual_worktime_sec),
        },
        {
          label: 'Work time in task (avg)',
          value: getBestTimeFormatForSeconds(
            improvementPotential.worktime_sec / improvementPotential.total_activities,
          ),
        },
        {
          label: `Total ${stepActivity} in task`,
          value: formatNumber(improvementPotential.total_activities),
        },
      ],
    }
  }, [data, stepActivity])

  return (
    <Modal size='2xl' label={insightName} open={isOpen} onClose={onClose}>
      {!(processPotential && workPotential) ? (
        <Loader />
      ) : (
        <StepActivityPotential
          stepActivity={stepActivity}
          stepName={stepName}
          processPotential={processPotential}
          workPotential={workPotential}
          transactionIds={[]}
        />
      )}
    </Modal>
  )
}

export default InsightsModal

