import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import {
  useCreateTrackedProcessMutation,
  useDeleteTrackedProcessMutation,
  useUpdateTrackedProcessMutation,
} from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import { ActionModal, Button, InputField, Select } from '../../common/designs'
import type { NewProcessGeneralInfo as FormProps, ProcessGeneralInfo } from '../Processes/types'
import validator from '../Processes/validator'
import useGetIdentifierOptions from './useIdentifierOptions'

const formSchema = yup
  .object({
    name: validator.trackedProcessName,
    process_identifier_name: validator.trackedProcessIdentifierName,
    process_category: validator.trackedProcessCategory,
    initial_visible_variant_amount: validator.trackedProcessInitialVisibleVariantAmount,
    state_tag_key: validator.trackedProcessStateTagKey,
  })
  .required()

interface Props {
  editedData?: ProcessGeneralInfo | null
  onClose: () => void
}

const ProcessForm = ({ editedData, onClose }: Props) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const { data: dimensionOptions, isLoading: isLoadingDimensionOptions } = useGetIdentifierOptions()
  const [createTrackedProcess, { isLoading: isLoadingCreate }] = useCreateTrackedProcessMutation()
  const [updateTrackedProcess, { isLoading: isLoadingEdit }] = useUpdateTrackedProcessMutation()
  const [deleteTrackedProcess, { isLoading: isLoadingDelete }] = useDeleteTrackedProcessMutation()

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormProps>({ resolver: yupResolver(formSchema) as Resolver<FormProps> })

  useEffect(() => {
    const initialData = editedData
      ? {
          name: editedData.name,
          process_identifier_name: editedData.process_identifier_name,
          process_category: editedData.process_category,
          initial_visible_variant_amount: editedData.initial_visible_variant_amount,
          state_tag_key: editedData.state_tag_key,
        }
      : {
          name: '',
          process_identifier_name: '',
          process_category: '',
          initial_visible_variant_amount: 5,
          state_tag_key: '',
        }
    reset(initialData)
  }, [editedData, reset])

  const onSave = (formProps: FormProps) => {
    createTrackedProcess({
      name: formProps.name,
      process_identifier_name: formProps.process_identifier_name,
      process_category: formProps.process_category,
      initial_visible_variant_amount: formProps.initial_visible_variant_amount,
      state_tag_key: formProps.state_tag_key,
    })
      .unwrap()
      .then(() => onClose())
  }

  const onDelete = () => {
    if (!editedData) return

    deleteTrackedProcess(editedData.id)
      .unwrap()
      .then(() => onClose())
  }

  const onEdit = (formProps: FormProps) => {
    if (!editedData) return

    updateTrackedProcess({
      processId: editedData.id,
      body: {
        name: formProps.name,
        process_identifier_name: formProps.process_identifier_name,
        process_category: formProps.process_category,
        initial_visible_variant_amount: formProps.initial_visible_variant_amount,
        state_tag_key: formProps.state_tag_key,
      },
    })
      .unwrap()
      .then(() => onClose())
  }

  const onSubmitForm = (formProps: FormProps) => {
    editedData ? onEdit(formProps) : onSave(formProps)
  }

  return (
    <>
      {isLoadingDimensionOptions ? (
        <InlineLoader />
      ) : (
        <form
          className='flex flex-col gap-3'
          autoComplete='off'
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <InputField label='Process name' error={errors.name?.message} {...field} autoFocus />
            )}
          />

          <Controller
            name='process_identifier_name'
            control={control}
            render={({ field }) => (
              <Select
                label='Process identifier name'
                error={errors.process_identifier_name?.message}
                options={dimensionOptions}
                {...field}
              />
            )}
          />

          <Controller
            name='process_category'
            control={control}
            render={({ field }) => (
              <InputField
                label='Process category name'
                error={errors.process_category?.message}
                {...field}
              />
            )}
          />

          <Controller
            name='state_tag_key'
            control={control}
            render={({ field }) => (
              <Select
                label='State tag key'
                error={errors.state_tag_key?.message}
                options={dimensionOptions}
                {...field}
              />
            )}
          />

          <Controller
            name='initial_visible_variant_amount'
            control={control}
            render={({ field }) => (
              <InputField
                label='Initial visible variant amount'
                type='number'
                min={1}
                error={errors.initial_visible_variant_amount?.message}
                description={`Default amount of variants selected in the process' "Variants" view`}
                {...field}
              />
            )}
          />

          <div className='mt-4 flex justify-end gap-4'>
            <Button variant='white' onClick={onClose} type='button'>
              Cancel
            </Button>

            {editedData && (
              <Button
                variant='destructive'
                type='button'
                onClick={() => setIsConfirmationModalOpen(true)}
              >
                Delete
              </Button>
            )}

            <Button type='submit' disabled={isLoadingCreate || isLoadingEdit}>
              Save
            </Button>
          </div>
        </form>
      )}

      <ActionModal
        variant='destructive'
        label='Are you sure you want to delete the tracking?'
        actionLabel='Delete'
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onAction={() => (onDelete ? onDelete() : null)}
        loading={isLoadingDelete}
      />
    </>
  )
}

export default ProcessForm
