import { Text } from '../../../common/designs'
import AgentStatus from './AgentStatus'
import AppStatus from './AppStatus'
import InsightsSection from './InsightsSection'
import ProcessStatus from './ProcessStatus'
import TeamStatus from './TeamStatus'

const GetStarted = () => {
  return (
    <div className='flex flex-col gap-4'>
      <div>
        <Text variant='h4' size='l' weight={600} className='mb-1'>
          Platform status
        </Text>

        <Text>This is how your company is connected to the service.</Text>

        <div className='mt-4 flex flex-wrap gap-4'>
          <AgentStatus />
          <TeamStatus />
          <AppStatus />
          <ProcessStatus />
        </div>
      </div>

      <InsightsSection />
    </div>
  )
}

export default GetStarted
