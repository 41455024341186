import { useUpdateConfigSourceMutation } from '../../app/apiSlice'
import { Text, Toggle } from '../../common/designs'
import type { Team } from '../../common/types/common'
import ShadowContainer from './ShadowContainer'

interface Props {
  team: Team
}

const ConfigFileManagementUseMainDisplay = ({ team }: Props) => {
  const [updateConfigSource] = useUpdateConfigSourceMutation()

  return (
    <ShadowContainer className='mb-5'>
      <div className='flex items-center justify-between px-10 py-6'>
        <Text size='2xl' weight={700}>
          {team.team_name}
        </Text>
        <Toggle
          size='l'
          label='Use main config'
          value={team.configuration_source === 'Company'}
          onChange={(value: boolean) => {
            updateConfigSource({
              invitationToken: team.invitation_token,
              body: { configuration_source: value ? 'Company' : 'Team' },
            })
          }}
        />
      </div>
    </ShadowContainer>
  )
}

export default ConfigFileManagementUseMainDisplay
