import { useState } from 'react'

import { Select } from '../../../../common/designs'
import TransactionMetricsAnalysis from '././TransactionMetricsAnalysis'
import TransactionNormalDistributionAnalysis from './TransactionNormalDistributionAnalysis'
import TransactionTimeSeriesAnalysis from './TransactionTimeSeriesAnalysis'
import { ActiveTransactionAnalysisViewEnum } from './types'

const ACTIVE_VIEW_OPTIONS = [
  { label: 'Metric Analysis', value: ActiveTransactionAnalysisViewEnum.METRICS_ANALYSIS },
  { label: 'Time-Series Analysis', value: ActiveTransactionAnalysisViewEnum.TIME_SERIES_ANALYSIS },
  {
    label: 'Normal Distribution Analysis',
    value: ActiveTransactionAnalysisViewEnum.NORMAL_DISTRIBUTION_ANALYSIS,
  },
]

export const TransactionAnalysis = () => {
  const [activeView, setActiveView] = useState<ActiveTransactionAnalysisViewEnum>(
    ActiveTransactionAnalysisViewEnum.METRICS_ANALYSIS,
  )

  return (
    <>
      <div className='mb-3 flex flex-col gap-2'>
        <Select
          options={ACTIVE_VIEW_OPTIONS}
          value={activeView}
          onChange={(val: string) => setActiveView(val as ActiveTransactionAnalysisViewEnum)}
          label='Select Visualization'
        />
        {activeView === ActiveTransactionAnalysisViewEnum.METRICS_ANALYSIS ? (
          <TransactionMetricsAnalysis />
        ) : activeView === ActiveTransactionAnalysisViewEnum.TIME_SERIES_ANALYSIS ? (
          <TransactionTimeSeriesAnalysis />
        ) : (
          <TransactionNormalDistributionAnalysis />
        )}
      </div>
    </>
  )
}

export default TransactionAnalysis
