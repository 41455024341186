import { type ConfigTestCase } from '../ConfigureDataCollectionAdminPanelPage/types'
import {
  PROCESS_ID_REGEX_TITLE_END,
  PROCESS_ID_REGEX_TITLE_START,
  PROCESS_ID_REGEX_URL_PATH_END,
  PROCESS_ID_REGEX_URL_PATH_START,
} from './constants'
import type { DataCollectionRuleFormData, ExtractIdentifierRule, RuleIn } from './types'

export const updateRuleFromTestCase = (rule: RuleIn, testCase: ConfigTestCase): RuleIn => {
  return {
    ...rule,
    tags: testCase.expected_processed_event.tags ?? [],
    salvage_fields: testCase?.expected_salvage_fields ?? [],
  }
}

export const generateFormDataFromRule = (rule: RuleIn): DataCollectionRuleFormData => {
  const ruleFormData: DataCollectionRuleFormData = {}
  if (!rule.matching_criteria) {
    return ruleFormData
  }

  ruleFormData.inUrl = (rule.matching_criteria.context.in_url ?? ['']).map((value) => ({ value }))

  ruleFormData.inTitle = (rule.matching_criteria.context.in_title ?? ['']).map((value) => ({
    value,
  }))

  if (rule.matching_criteria.context.in_process_name) {
    ruleFormData.inProcessName = rule.matching_criteria.context.in_process_name[0]
  }

  if (rule.extract_identifiers) {
    parseIdentifierData(rule.extract_identifiers, ruleFormData)
  }

  return ruleFormData
}

const parseIdentifierData = (
  identifiers: ExtractIdentifierRule[],
  ruleFormData: DataCollectionRuleFormData,
) => {
  identifiers.forEach((idRule) => {
    if (
      idRule.regex_pattern &&
      (idRule.from_fields ?? []).length === 1 &&
      idRule.from_fields?.includes('url')
    ) {
      parseDashboardUrlRegexPath(idRule.regex_pattern, idRule.identifier_name, ruleFormData)
    } else if (
      idRule.regex_pattern &&
      (idRule.from_fields ?? []).length === 1 &&
      idRule.from_fields?.includes('title')
    ) {
      parseDashboardTitleRegexPath(idRule.regex_pattern, idRule.identifier_name, ruleFormData)
    } else if (idRule.key) {
      ruleFormData.processName = idRule.identifier_name
      ruleFormData.processIdParamKey = idRule.key
    }
  })
}

const parseDashboardUrlRegexPath = (
  regex: string,
  processName: string,
  ruleFormData: DataCollectionRuleFormData,
) => {
  if (
    regex.startsWith(PROCESS_ID_REGEX_URL_PATH_START) &&
    regex.endsWith(PROCESS_ID_REGEX_URL_PATH_END)
  ) {
    const pathName = regex.substring(
      PROCESS_ID_REGEX_URL_PATH_START.length,
      regex.lastIndexOf(PROCESS_ID_REGEX_URL_PATH_END),
    )
    ruleFormData.processName = processName
    ruleFormData.processIdPathBefore = pathName
  }
}

const parseDashboardTitleRegexPath = (
  regex: string,
  processName: string,
  ruleFormData: DataCollectionRuleFormData,
) => {
  if (
    regex.startsWith(PROCESS_ID_REGEX_TITLE_START) &&
    regex.endsWith(PROCESS_ID_REGEX_TITLE_END)
  ) {
    const pathName = regex.substring(
      PROCESS_ID_REGEX_TITLE_START.length,
      regex.lastIndexOf(PROCESS_ID_REGEX_TITLE_END),
    )
    ruleFormData.processName = processName
    ruleFormData.processIdTitleBefore = pathName
  }
}
