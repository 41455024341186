import { Checkboxes } from '../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import {
  selectFilterStatusIds,
  selectLaneEntitiesNonDisqualified,
  setFilterStatusIds,
} from '../../casesSlice'

const StatusFilter = () => {
  const dispatch = useAppDispatch()
  const filterIds = useAppSelector(selectFilterStatusIds)
  const lanes = useAppSelector(selectLaneEntitiesNonDisqualified)

  return (
    <div>
      <Checkboxes
        label='Status'
        values={filterIds}
        options={[
          ...Object.values(lanes).map((l) => ({
            label: l.title,
            value: l.id,
          })),
        ]}
        onChange={(arr: number[]) => dispatch(setFilterStatusIds(arr))}
      />
    </div>
  )
}

export default StatusFilter
