export const applicationCategories = {
  coreBusinessSystem: 'Core business system',
  document: 'Document',
  communication: 'Communication',
  other: 'Other',
} as const
export type ApplicationCategory = (typeof applicationCategories)[keyof typeof applicationCategories]

export const applicationDataCollectionModes = {
  keywords: 'Keywords',
  timeOnly: 'Time only',
  trainingUrl: 'Training: URL',
  trainingUrlTitle: 'Training: URL + title',
  trainingTitle: 'Training: title',
} as const
export type ApplicationDataCollectionMode =
  (typeof applicationDataCollectionModes)[keyof typeof applicationDataCollectionModes]

export interface Application {
  id?: string
  name: string
  category: ApplicationCategory
  is_pdf_application: boolean
}

export interface ApplicationCreateInput {
  name: string
  category?: ApplicationCategory
  is_pdf_application?: boolean
}

export type ApplicationUpdateInput = Partial<ApplicationCreateInput> & {
  id: string
}
