import { useGetCaseCategoriesQuery } from '../../../../app/apiSlice'
import { Checkboxes } from '../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import { selectFilterCategoryIds, setFilterCategoryIds } from '../../casesSlice'

const CategoryFilter = () => {
  const dispatch = useAppDispatch()
  const filterIds = useAppSelector(selectFilterCategoryIds)

  const { data: caseCategories = [] } = useGetCaseCategoriesQuery()

  return (
    <div>
      <Checkboxes
        label='Category'
        values={filterIds}
        options={[
          ...Object.values(caseCategories).map((c) => ({
            label: c.name,
            value: c.id,
          })),
        ]}
        onChange={(arr: number[]) => dispatch(setFilterCategoryIds(arr))}
      />
    </div>
  )
}

export default CategoryFilter
