import { DimensionExtraDataMapper, GroupByDimensionTypeEnum } from '../../../types'
import { StepGroupByField, StepGroupByFieldTypeEnum } from './types'

export const generateGroupByField = (
  selectedGroupByField: string,
  groupByDimensionsExtraDataMapper: DimensionExtraDataMapper,
) => {
  let groupBy = { field_type: selectedGroupByField as StepGroupByFieldTypeEnum } as StepGroupByField
  const groupData =
    groupByDimensionsExtraDataMapper[
      selectedGroupByField as keyof typeof groupByDimensionsExtraDataMapper
    ]
  if (groupData.is_tag_dimension) {
    const groupByTagType =
      groupData.group_by_dimension_type === GroupByDimensionTypeEnum.STEP_SPECIFIC
        ? StepGroupByFieldTypeEnum.STEP_TAG
        : StepGroupByFieldTypeEnum.TRANSACTION_TAG
    groupBy = {
      field_type: groupByTagType,
      tag_name: selectedGroupByField,
    }
  }
  return groupBy
}
