import { StarIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { IconButton, Text, Tooltip } from '../../../common/designs'
import { formatNumber } from '../../../common/utils/numberFormatting'
import ConformanceFlowAddEditModal from '../ProcessMining/ConformanceFlowAddEditModal'
import { VariantVisualizationLevelEnum } from '../ProcessMining/Variants/types'
import { type ConformanceModalData, ConformanceTypeEnum } from '../types'

interface TransactionAmountPros {
  index: number
  variantTransactionAmount: number
  totalTransactionAmount: number
  additionalMetricLabel: string
  additionalMetricValue: string
  variantSteps?: string[]
  isConformanceFlow: boolean
  visualizationLevel?: VariantVisualizationLevelEnum
  onSaveConformanceFlow?: (data: ConformanceModalData) => Promise<void>
}

const VariantSelectionContent = (props: TransactionAmountPros) => {
  const {
    index,
    variantTransactionAmount,
    totalTransactionAmount,
    additionalMetricLabel,
    additionalMetricValue,
    variantSteps,
    isConformanceFlow,
    visualizationLevel,
    onSaveConformanceFlow,
  } = props
  const [selectedStepsForConformance, setSelectedStepsForConformance] = useState<string[]>([])

  const onSaveConformance = async (data: ConformanceModalData) => {
    if (!onSaveConformanceFlow) return
    await onSaveConformanceFlow(data)
    setSelectedStepsForConformance([])
  }

  const isConformanceSupported =
    visualizationLevel &&
    [VariantVisualizationLevelEnum.STATE, VariantVisualizationLevelEnum.STEP].includes(
      visualizationLevel,
    )

  const selectedConformanceType =
    visualizationLevel === VariantVisualizationLevelEnum.STEP
      ? ConformanceTypeEnum.STEP_FLOW
      : ConformanceTypeEnum.STATE_FLOW

  return (
    <div className='flex items-start justify-between'>
      <div className='flex gap-3'>
        <span className='shrink-0'>
          <span className='flex size-8 items-center justify-center rounded-full border-2 border-indigo-600'>
            <Text size='s' className='text-indigo-600'>
              {index + 1}
            </Text>
          </span>
        </span>

        <div>
          <div className='flex w-[224px] items-center'>
            <Text className='mr-1' size='xs'>
              Cases:
            </Text>
            <Text weight={600} size='xs'>
              {formatNumber(variantTransactionAmount, 1)} (
              {formatNumber((variantTransactionAmount / totalTransactionAmount) * 100, 1)}% of all)
            </Text>
          </div>

          <div className='flex items-center'>
            <Text className='mr-1' size='xs'>
              {additionalMetricLabel}:{' '}
            </Text>
            <Text weight={500} size='xs'>
              {additionalMetricValue}
            </Text>
          </div>
        </div>
      </div>

      {isConformanceFlow && (
        <Tooltip text='Conformance variant' placement='be' size='l'>
          <IconButton
            className='cursor-default'
            size='xs'
            icon={<StarIcon className='fill-indigo-600' />}
          />
        </Tooltip>
      )}

      {isConformanceSupported && !isConformanceFlow && variantSteps && (
        <Tooltip text='Save as conformance variant' placement='be' size='l'>
          <IconButton
            size='xs'
            variant='white'
            onClick={(e) => {
              e.stopPropagation()
              setSelectedStepsForConformance(variantSteps)
            }}
            icon={<StarIcon />}
          />
        </Tooltip>
      )}

      {isConformanceSupported && (
        <ConformanceFlowAddEditModal
          isOpen={Boolean(selectedStepsForConformance.length)}
          onClose={() => setSelectedStepsForConformance([])}
          onSave={onSaveConformance}
          addDataSteps={selectedStepsForConformance}
          selectedConformanceType={selectedConformanceType}
        />
      )}
    </div>
  )
}

export default VariantSelectionContent
