import { CategoryFilter, CollaboratorFilter } from '../../Filter'

const FilterMenu = () => {
  return (
    <div className='space-y-4'>
      <CategoryFilter />
      <CollaboratorFilter />
    </div>
  )
}

export default FilterMenu
